import React from "react";
import PropTypes from "prop-types";

import {FormGroup, HTMLSelect} from "@blueprintjs/core";

function FormFieldScenario(props) {
  const options = props.scenarios.map((period) => ({label: period.name, value: period.id}));

  function onChange(evt) {
    props.onChange({
      target: {
        name: "scenario_id",
        value: evt.target.value,
      },
    });
  }

  const actualSelect = (
    <HTMLSelect
      fill
      name="scenario_id"
      onChange={onChange}
      options={options}
      value={props.value}
    />
  );

  return props.label ? (
    <FormGroup
      className={props.formGroupClass}
      label={props.label}
      labelFor="scenario_id"
      labelInfo="*"
    >
      {actualSelect}
    </FormGroup>
  ) : actualSelect;
}

FormFieldScenario.propTypes = {
  formGroupClass: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  scenarios: PropTypes.array.isRequired,
  value: PropTypes.string,
};

export default FormFieldScenario;
