import React from "react";
import PropTypes from "prop-types";

import {Icon} from "@blueprintjs/core";

import {RowType} from "../utilities";

import styles from "./Autocomplete.module.scss";


function Item({
  entity: reference,
}) {
  return (
    <div className={reference.type === "METRIC" ? styles.isMetric : undefined}>
      {reference.name}
      {reference.type === RowType.METRIC ? <Icon className={styles.icon} icon="link" iconSize={9} /> : null}
    </div>
  );
};

Item.propTypes = {
  entity: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
};


export const autocompleteTrigger = (references) => ({
  "{": {
    dataProvider: (token) => {
      const matches = references.filter((ref) => ref.slug?.includes(token));
      return matches;
    },
    component: Item,
    output: (ref) => ({
      text: `{${ref.slug}`,
      caretPosition: "end",
    }),
  },
});
