import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

export default class RowErrorBoundary extends React.PureComponent {
  static getDerivedStateFromError() {
    return {hasRenderError: true};
  }

  static getDerivedStateFromProps(props, state) {
    const stateMutation = {formulas: props.formulas};
    if(state.hasRenderError) {
      //console.log(props.formulas, state.formulas);
      if(props.formulas !== state.formulas) stateMutation.hasRenderError = false;
    }
    return stateMutation;
  }

  static propTypes = {
    formulas: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  state = {
    hasRenderError: false,
  }

  resetRenderError() {
    this.setState({hasRenderError: false});
  }

  render() {
    if(!this.state.hasRenderError) {
      return ({...this.props.children});
    } else {
      return (
        <td className={styles.erroredRow} colSpan="12" onClick={this.props.onClick}>There was an error trying to display values for this row. Please check formula or recreate the row.</td>
      );
    }
  }
}
