import React from "react";
import PropTypes from "prop-types";

import {FormGroup, HTMLSelect} from "@blueprintjs/core";

const reportPeriods = [
  {value: "CUSTOM", displayName: "Custom"},
  {value: "LAST_MONTH", displayName: "Last Month"},
  {value: "THIS_YEAR_TO_DATE", displayName: "This Year-to-date"},
  {value: "THIS_YEAR", displayName: "This Year"},
  {value: "MONTHS_FORECAST", displayName: "X Months Ago, Y Months Ahead"},
];

function FormFieldReportPeriod(props) {
  return (
    <FormGroup
      className={props.formGroupClass}
      label="Report Period"
      labelFor="report_period"
      labelInfo="*"
    >
      <HTMLSelect
        fill
        name="report_period"
        onChange={props.onChange}
        options={reportPeriods.map((period) => ({label: period.displayName, value: period.value}))}
        value={props.value}
      />
    </FormGroup>
  );
}

FormFieldReportPeriod.propTypes = {
  formGroupClass: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default FormFieldReportPeriod;
export {reportPeriods};
