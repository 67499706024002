import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import { withHooks } from "../../../shared/hooks/hoc";

import Card from "components/Card";
import Button from "components/Button";
import { FormGroup, Icon, Classes as BP } from "@blueprintjs/core";

import { INITIAL_LOAD_QUERY } from "../../../graphql";
import { UPDATE_REVENUE_SETTINGS } from "./graphql";

import styles from "./styles.module.scss";

function reducer(state, action) {
  switch (action.type) {
    case "UPDATE_EXPANSION": {
      return {
        ...state,
        expansion: action.value,
      };
    }
    case "UPDATE_CHURN": {
      return {
        ...state,
        churn: action.value,
      };
    }
    case "UPDATE_CONTRACTION": {
      return {
        ...state,
        contraction: action.value,
      };
    }
    case "TOGGLE_SAVING_SUCCESS": {
      return {
        ...state,
        successIconShown: !state.successIconShown,
      };
    }
    default:
      return state;
  }
}

const options = [
  { value: "ABSOLUTE", label: "Count" },
  { value: "TOTAL_CUSTOMERS", label: "% of Total Customers" },
  { value: "PLAN_CUSTOMERS", label: "% of Product Interval Customers" },
  { value: "TOTAL_MRR", label: "% of MRR" },
  { value: "PLAN_MRR", label: "% of Product Interval MRR" },
];

const defaultSettings = {
  expansion: "TOTAL_CUSTOMERS",
  churn: "TOTAL_CUSTOMERS",
  contraction: "TOTAL_CUSTOMERS",
};

function RevenueSettings(props) {
  const revenueSettings = user.tenant.revenue || defaultSettings;
  const initialState = {
    expansion: revenueSettings.expansion,
    churn: revenueSettings.churn,
    contraction: revenueSettings.contraction,
    successIconShown: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const [updateRevenueSettings] = useMutation(UPDATE_REVENUE_SETTINGS, {
    refetchQueries: [{ query: INITIAL_LOAD_QUERY }],
    update: (store) => {
      const queryData = store.readQuery({
        query: INITIAL_LOAD_QUERY,
      });

      const data = {
        ...queryData,
        currentUser: {
          ...queryData.currentUser,
          tenant: {
            ...queryData.currentUser.tenant,
            revenue: {
              ...queryData.currentUser.tenant.revenue,
              expansion: state.expansion,
              churn: state.churn,
              contraction: state.contraction,
            },
          },
        },
      };

      store.writeQuery({ query: INITIAL_LOAD_QUERY, data });
    },
    optimisticResponse: {
      updateRevenueSettings: true,
    },
  });

  const update = () => {
    const settings = {
      expansion: state.expansion,
      churn: state.churn,
      contraction: state.contraction,
    };

    updateRevenueSettings({ variables: { settings } }).then(() => {
      dispatch({ type: "TOGGLE_SAVING_SUCCESS" });
      setTimeout(() => {
        dispatch({ type: "TOGGLE_SAVING_SUCCESS" });
      }, 3000);
    }).catch((error) => {
      console.log("There was an error updating settings...", error);
    });
  };

  const changeField = (type, value) => dispatch({ type: `UPDATE_${type}`, value });

  return (
    <div className={styles.mainContainer}>
      <Card className={styles.productCard}>
        <h3 className={styles.teamName}>Forecast Methods</h3>
        <FormGroup
          className="m-t"
          inline
          label="Expansion"
          labelFor="expansion"
        >
          <div className={[BP.HTML_SELECT, BP.MINIMAL].join(" ")}>
            <select
              onChange={(event) => changeField("EXPANSION", event.target.value)}
              value={state.expansion}
            >
              {options.map((revenueOption, index) => (
                <option key={index} value={revenueOption.value}>{revenueOption.label}</option>
              ))}
            </select>
          </div>
        </FormGroup>
        <FormGroup
          className="m-t"
          inline
          label="Churn"
          labelFor="churn"
        >
          <div className={[BP.HTML_SELECT, BP.MINIMAL].join(" ")}>
            <select
              onChange={(event) => changeField("CHURN", event.target.value)}
              value={state.churn}
            >
              {options.map((revenueOption, index) => (
                <option key={index} value={revenueOption.value}>{revenueOption.label}</option>
              ))}
            </select>
          </div>
        </FormGroup>
        <FormGroup
          className="m-t"
          inline
          label="Contraction"
          labelFor="contraction"
        >
          <div className={[BP.HTML_SELECT, BP.MINIMAL].join(" ")}>
            <select
              onChange={(event) => changeField("CONTRACTION", event.target.value)}
              value={state.contraction}
            >
              {options.map((revenueOption, index) => (
                <option key={index} value={revenueOption.value}>{revenueOption.label}</option>
              ))}
            </select>
          </div>
        </FormGroup>
        <div className={styles.buttonRow}>
          <Button intent="success" onClick={update}>Save</Button>
          <Icon className={[styles.successIcon, state.successIconShown ? styles.shown : null].join(" ")} icon="tick-circle" iconSize={32} />
        </div>
      </Card>
    </div>
  );
}

export default withHooks(RevenueSettings);
