export const FLIGHTPATH = {
  PNL_TYPES: [
    "Revenue", "Cost of Revenue", "Gross Profit", "Expenses", "Net Operating Income", "Other Income", "Other Expenses",
    "Net Other Income", "Net Income",
  ],
  BS_TYPES: [
    "Assets", "Liabilities & Equity",
  ],
  CFS_TYPES: [
    "Operating Activities", "Investing Activities", "Financing Activities", "Net cash increase for period",
  ],
  REV_TYPES: [
    "New Customers", "New Customer ARPC", "New Customer MRR", "Reactivations", "Reactivation ARPC", "Reactivation MRR", "Expansion", "Expansion %", "Expansion ARPC", "Expansion MRR",
    "Churn", "Churn %", "Churn ARPC", "Churn MRR", "Contraction", "Contraction %", "Contraction ARPC", "Contraction MRR", "Net New Customers", "Total Customers", "Net New MRR", "MRR",
  ],
};

export const FLIGHTPATH_REDUCED = {
  PNL_TYPES: [
    "Revenue", "Cost of Revenue", "Expenses", "Other Income", "Other Expenses",
  ],
  BS_TYPES: [
    "Assets", "Liabilities", "Equity",
  ],
};

export const FLIGHTPATH_ROOT = {
  PNL_TYPES: [
    "Revenue", "Cost of Revenue", "Expenses", "Other Income", "Other Expenses",
  ],
  BS_TYPES: [
    "Assets", "Liabilities & Equity", "Current Assets", "Accounts Receivable",
    "Bank Accounts", "Other Current Assets", "Fixed Assets",
    "Other Assets", "Equity", "Net Income", "Liabilities", "Current Liabilities", "Accounts Payable",
    "Credit Cards", "Other Current Liabilities", "Long-Term Liabilities",
  ],
};

export const FLIGHTPATH_CHOOSABLE = [
  "Accounts Receivable", "Bank Accounts", "Other Current Assets",
  "Fixed Assets", "Other Assets", "Accounts Payable", "Credit Cards", "Current Liabilities", "Other Current Liabilities",
  "Long-Term Liabilities", "Equity", "Revenue", "Other Income", "Cost of Revenue",
  "Expenses", "Other Expenses",
];

export const FLIGHTPATH_PAYROLL_CHOOSABLE = [
  "Cost of Revenue", "Expenses", "Other Expenses",
];

export const FLIGHTPATH_DETAIL_TYPES = ["None", "Bank Account", "Payroll Account"];

export const QUICKBOOKS = {
  PNL_TYPES: [
    "Income", "COGS", "GrossProfit", "Expenses", "NetOperatingIncome", "OtherIncome", "OtherExpenses",
    "NetOtherIncome", "NetIncome",
  ],
  BS_TYPES: [
    "TotalAssets", "TotalLiabilitiesAndEquity",
  ],
  CFS_TYPES: [
    "OperatingActivities", "InvestingActivities", "FinancingActivities", "CashIncrease",
  ],
};

export const qbToFFTypesHash = {
  "Income": "Revenue",
  "COGS": "Cost of Revenue",
  "Expenses": "Expenses",
  "OtherIncome": "Other Income",
  "OtherExpenses": "Other Expenses",
  "NetOperatingIncome": "Net Operating Income",
  "NetIncome": "Net Income",
  "NetOtherIncome": "Net Other Income",
  "TotalAssets": "Assets",
  "CurrentAssets": "Current Assets",
  "OtherCurrentAssets": "Other Current Assets",
  "OtherAssets": "Other Assets",
  "FixedAssets": "Fixed Assets",
  "AP": "Accounts Payable",
  "CreditCards": "Credit Cards",
  "GrossProfit": "Gross Profit",
  "BankAccounts": "Bank Accounts",
  "TotalLiabilitiesAndEquity": "Liabilities & Equity",
  "Liabilities": "Liabilities",
  "Equity": "Equity",
  "CurrentLiabilities": "Current Liabilities",
  "OtherCurrentLiabilities": "Other Current Liabilities",
  "LongTermLiabilities": "Long-Term Liabilities",
  "AR": "Accounts Receivable",
  "OperatingActivities": "Operating Activities",
  "OperatingAdjustments": "Adjustments to Net Income",
  "InvestingActivities": "Investing Activities",
  "FinancingActivities": "Financing Activities",
  "RetainedEarnings": "Retained Earnings",
  "CashIncrease": "Net cash increase for period",
};

export const mrrMap = {
  "New Customer ARPC": "New Customer MRR",
  "Reactivation ARPC": "Reactivation MRR",
  "Expansion ARPC": "Expansion MRR",
  "Churn ARPC": "Churn MRR",
  "Contraction ARPC": "Contraction MRR",
};

export const metricMap = {
  "New Customer ARPC": "New Customers",
  "Reactivation ARPC": "Reactivation",
  "Expansion ARPC": "Expansion",
  "Churn ARPC": "Churn",
  "Contraction ARPC": "Contraction",
};

export const percMetricMap = {
  "Expansion %": "Expansion",
  "Churn %": "Churn",
  "Contraction %": "Contraction",
};

export const percTotalMrrMap = {
  "Expansion %": "Expansion MRR",
  "Churn %": "Churn MRR",
  "Contraction %": "Contraction MRR",
};

export const RevenueTypeToSettingMap = {
  new_customers: ["New Customers", "New Customer ARPC", "New Customer MRR"],
  reactivation: ["Reactivation", "Reactivation ARPC", "Reactivation MRR"],
  expansion: ["Expansion", "Expansion %", "Expansion ARPC", "Expansion MRR"],
  churn: ["Churn", "Churn %", "Churn ARPC", "Churn MRR"],
  contraction: ["Contraction", "Contraction %", "Contraction ARPC", "Contraction MRR"],
};

export const weightedRevenueTypes = ["New Customer ARPC", "Reactivation ARPC", "Expansion ARPC", "Churn ARPC", "Contraction ARPC", "Expansion %", "Churn %", "Contraction %"];

export const revenuePercentTypes = ["Expansion %", "Churn %", "Contraction %"];

export const weightedAvgARPCMap = {
  "New Customer ARPC": "New Customer MRR / New Customers",
  "Reactivation ARPC": "Reactivation MRR / Reactivations",
  "Expansion ARPC": "Expansion MRR / Expansions",
  "Churn ARPC": "Expansion MRR / Expansions",
  "Contraction ARPC": "Contraction MRR / Contractions",
};

export const weightedAvgCustomersMap = {
  "Expansion %": "Expansions / Total Customers",
  "Churn %": "Churn / Total Customers",
  "Contraction %": "Contraction / Total Customers",
};

export const weightedAvgMRRMap = {
  "Expansion %": "Expansion MRR / Total MRR",
  "Churn %": "Churn MRR / Total MRR",
  "Contraction %": "Contraction MRR / Total MRR",
};
