import { cloneElement } from "react";
import { parsePath } from "history";

import { createBrowserHistory } from "history";
const history = createBrowserHistory();

const ServerLink = (props) => {
  const { children, to } = props;

  const handleClick = () => {
    history.block();
    window.location.replace(to);
  };

  const location =
    typeof to === "string"
      ? parsePath(to)
      : to;

  history.createHref(location);

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
    </div>
  );
};

export default ServerLink;
