import React, {useState} from "react";
import PropTypes from "prop-types";

import {useMutation} from "@apollo/client";

import Errors from "components/Errors";
import TypedConfirmation from "components/TypedConfirmation";

import {REMOVE_PRODUCT, ALL_PRODUCTS_QUERY} from "./graphql";
import {RM_ACCOUNTS_QUERY, ALL_PLAN_GROUPS_QUERY} from "../forecast/graphql";

export default function ProductDelete({onCancel: onCancelProp, product}) {
  const [deleting, setDeleting] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [errors, setErrors] = useState([]);

  const onCancel = () => {
    setIsOpen(false);
    setTimeout(onCancelProp, 300);
  };

  const [removeProduct] = useMutation(REMOVE_PRODUCT, {
    refetchQueries: [{query: RM_ACCOUNTS_QUERY}, {query: ALL_PLAN_GROUPS_QUERY}],
    update: (store, {data: {removeProduct}}) => {
      const queryData = store.readQuery({
        query: ALL_PRODUCTS_QUERY,
        variables: {includePlans: true},
      });

      const data = {
        ...queryData,
        products: queryData.products.filter((queryProduct) => queryProduct.id !== removeProduct.id),
      };

      // If the product had plans, they should now be added to the "unassigned" product
      if(product.plans.length) {
        const unassignedProductIndex = data.products.findIndex((item) => item.id === 0);
        const unassignedProduct = unassignedProductIndex === -1 ? {__typename: "Product", id: 0, name: "Unassigned", plans: []} : {...data.products[unassignedProductIndex]};

        unassignedProduct.plans = [...unassignedProduct.plans, ...product.plans.map((plan) => ({...plan, product_id: 0}))];

        if(unassignedProductIndex === -1) {
          data.products.push(unassignedProduct);
        } else {
          data.products[unassignedProductIndex] = unassignedProduct;
        }
      }

      store.writeQuery({query: ALL_PRODUCTS_QUERY, variables: {includePlans: true}, data});
    },
    optimisticResponse: {
      removeProduct: {
        id: product.id,
        __typename: "Product",
      },
    },
  });

  const onConfirm = () => {
    setDeleting(false);

    removeProduct({variables: {id: product.id}}).catch((error) => {
      console.log("There was an error sending the query", error);
      setErrors(["The product could not be removed. All plans belonging to this product must be moved or removed first."]);
    });
  };

  return (
    <TypedConfirmation
      deleting={deleting}
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <Errors messages={errors}/>
      <p>
        Are you sure that you want to remove product <strong>{product.name}</strong>?
      </p>
      <p>
        All plans within this product need to moved to another product or removed before this product can be deleted. This action is irreversible.
      </p>
      <p>
        Please type "delete" in the input field then click <strong>Delete</strong> below if you are sure
        that you would like this product removed.
      </p>
    </TypedConfirmation>
  );
}

ProductDelete.propTypes = {
  onCancel: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};
