export default {
  expansion: [{
    type: "account",
    accountType: "Expansion",
  }, {
    type: "account",
    accountType: "Expansion ARPC",
    options: {useTotalOverFormula: false},
  }],
  churn: [{
    type: "account",
    accountType: "Churn",
  }, {
    type: "account",
    accountType: "Churn ARPC",
    options: {useTotalOverFormula: false},
  }],
  contraction: [{
    type: "account",
    accountType: "Contraction",
  }, {
    type: "account",
    accountType: "Contraction ARPC",
    options: {useTotalOverFormula: false},
  }],
};
