import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import {Position, Popover, Menu, MenuDivider, MenuItem, Intent} from "@blueprintjs/core";
import SanityCheckErrors from "./SanityCheckErrors";

import {AppContext} from "../../AppContext";

import styles from "./SanityChecks.module.scss";
function findScenarioById(scenarios, scenarioId) {
  return scenarios.find((scenario) => (scenario.id === scenarioId));
}
export const ErrorType = {
  ACCOUNTS_LINKED: "accountsLinked",
  HISTORICAL_ASSETS: "historicalAssets",
  HISTORICAL_BS: "historicalBS",
  NET_INCOME: "netIncome",
  HISTORICAL_LE: "historicalLE",
  NET_CASH: "netCashIncrease",
  BANK_NET_CASH: "bankNetCash",
  CYCLE: "CYCLE",
};

const checksMap = {
  "All Flightpath accounts are mapped to accounting accounts": "accountsLinked",
  "futureBS": "futureBS",
  "Difference in the bank account balance equals the net cash increase / decrease": "bankNetCash",
  "Actuals": "Actuals",
  "Assets equal accounting assets": "historicalAssets",
  "Liabilities & equity equal accounting liabilities & equity": "historicalLE",
  "Net income in Flightpath matches the net income in accounting.": "netIncome",
  "Net cash increase equals accounting net cash increase": "netCashIncrease",
  "Actual assets equal actual liabilities & equity": "historicalBS",
};

class SanityCheck extends React.Component {
  static propTypes = {
    check: PropTypes.object.isRequired,
    openErrorLog: PropTypes.func.isRequired,
  }

  state = {};

  render() {
    const {check} = this.props;
    const icon = (check.passed) ? "tick" : "cross";
    const intent = (check.passed) ? Intent.SUCCESS : Intent.DANGER;
    return (
      <MenuItem
        icon={icon}
        intent={intent}
        onClick={() => this.props.openErrorLog(check)}
        shouldDismissPopover={false}
        text={check.name}
      />
    );
  }
}

export class SanityChecks extends React.Component {
  static propTypes = {
    scenarioId: PropTypes.string,
    scenarios: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props);
    this.state = this.createSanityChecksState(context);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.user !== this.props.user || prevProps.scenarioId !== this.props.scenarioId) {
      const state = this.createSanityChecksState(this.context);
      this.setState(state);
    }
  }

  static contextType = AppContext

  createChecksArray = (context) => {
    const {scenarioId, scenarios, user} = this.props;
    const selectedScenario = findScenarioById(scenarios, scenarioId);
    const scenarioName = (selectedScenario) ? selectedScenario.name : "";
    const tenantChecks = user.tenant?.options?.checks || {};
    const checksPassed = [];

    // TODO: Remove the following block once Xero has been debugged...
    const { companies } = context;
    let provider = "";
    for (const company of companies) {
      if (company.provider === "XERO" || company.provider === "QUICKBOOKS") {
        provider = company.provider;
        break;
      }
    }

    for(const key of Object.keys(checksMap)) {
      const name = (checksMap[key] === "futureBS") ? `Assets equal liabilities & equity in ${scenarioName} scenario` : key;
      const id = checksMap[key];
      let passed = (checksMap[key] === "futureBS") ? tenantChecks.futureBS?.[scenarioId] ?? false : tenantChecks[checksMap[key]];
      if(name === "Actuals") passed = true;


      // TODO: Remove the following block once Xero has been debugged...
      if(provider === "XERO" && ["bankNetCash", "netCashIncrease", "historicalLE"].includes(checksMap[key])) {
        continue;
      }

      checksPassed.push({id, name, passed});
    }
    return checksPassed;
  }

  allChecksPassed = (checks) => {
    let checksPassed = true;
    for(let i = 0; i < checks.length; i++) {
      const check = checks[i];
      if(!check.passed) {
        checksPassed = false;
      }
    }
    return checksPassed;
  }

  createSanityChecksState = (context) => {
    const checks = this.createChecksArray(context);
    const allChecksPassed = this.allChecksPassed(checks);
    return {
      allChecksPassed,
      checks,
      isOpen: false,
    };
  }

  onClose = () => this.setState({isOpen: false, selectedCheck: null});

  openErrorLog = (check) => this.setState({isOpen: true, selectedCheck: check});

  render() {
    const allChecksIcon = (this.state.allChecksPassed) ? "tick" : "cross";
    const allChecksIntent = (this.state.allChecksPassed) ? "success" : "danger";
    return (
      <Popover
        content={
          <Menu className={styles.sanityChecksMenu}>
            <MenuDivider title="Actuals & Forecast" />
            {this.state.checks.map((check, index) => (
              (check.name !== "Actuals") ? <SanityCheck check={check} key={index} openErrorLog={this.openErrorLog} /> : <MenuDivider key={index} title="Actuals" />
            ))}
            <SanityCheckErrors
              isOpen={this.state.isOpen}
              onClose={this.onClose}
              scenarios={this.props.scenarios}
              selectedCheck={this.state.selectedCheck}
            />
          </Menu>
        }
        enforceFocus={false}
        minimal
        position={Position.BOTTOM_RIGHT}
      >
        <Button icon={allChecksIcon} intent={allChecksIntent} width={140}>Checks</Button>
      </Popover>
    );
  }
}

export default SanityChecks;
