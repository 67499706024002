import React from "react";
import PropTypes from "prop-types";

import {useNavigate} from "react-router-dom";

export default function Row({worksheet}) {
  const navigate = useNavigate();

  const handleWorksheetEdit = () => navigate(`/worksheets/${worksheet.slug}/edit`);

  const metricsInWorksheet = worksheet.rows.filter((row) => row.type === "METRIC").map((row) => row.name).join(", ");

  return (
    <tr onClick={handleWorksheetEdit}>
      <td>{worksheet.name || ""}</td>
      <td>{metricsInWorksheet || ""}</td>
    </tr>
  );
}

Row.propTypes = {
  worksheet: PropTypes.object.isRequired,
};
