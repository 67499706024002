import {gql} from "@apollo/client";


export const PENDING_CHANGES_QUERY = gql`
  query pendingChangesQuery {
    pendingChanges {
      id
      status
      reason
    }
  }
`;

export const PENDING_CHANGES_SUBSCRIPTION = gql`
  subscription pendingChangesSubscription {
    pendingChangesUpdated {
      id
      status
      reason
    }
  }
`;
