const analyticsInstance = window.analyticsInstance = window.analyticsInstance || [];

function loadSegment() {
  if(!analyticsInstance.initialize && analyticsInstance.invoked) {
    console.error("Segment snippet included twice.");
  } else {
    analyticsInstance.invoked = true;
    analyticsInstance.methods = [
      "trackSubmit",
      "trackClick",
      "trackLink",
      "trackForm",
      "pageview",
      "identify",
      "reset",
      "group",
      "track",
      "ready",
      "alias",
      "debug",
      "page",
      "once",
      "off",
      "on",
    ];

    analyticsInstance.factory = function(t) {
      return function(...args) {
        const e = Array.prototype.slice.call(args);
        e.unshift(t);
        analyticsInstance.push(e);
        return analyticsInstance;
      };
    };

    for(let i = 0; i < analyticsInstance.methods.length; i++) {
      const e = analyticsInstance.methods[i];
      analyticsInstance[e] = analyticsInstance.factory(e);
    }

    analyticsInstance.load = function(key, e) {
      const n = document.createElement("script");
      n.type = "text/javascript";
      n.async = true;
      n.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

      const a = document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(n, a);
      analyticsInstance._loadOptions = e;
    };

    analyticsInstance.SNIPPET_VERSION = "4.1.0";
    analyticsInstance.load("uMUNwnNivusXSQUy8p3pm8UF6x6NYKk4");
  }
}

function callFn(fn) {
  return function(...args) {
    if(window && window.analytics) {
      window.analytics[fn](...args);
    }
  };
}

export default {
  load: loadSegment,
  page: callFn("page"),
  track: callFn("track"),
  identify: callFn("identify"),
};
