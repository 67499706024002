import React, { useEffect } from "react";

import { useQuery } from "@apollo/client";

import Button from "components/Button";
import Loading from "components/Loading";
import { Icon, Popover, Position, Spinner } from "@blueprintjs/core";

import { PENDING_CHANGES_QUERY, PENDING_CHANGES_SUBSCRIPTION } from "./graphql";

import styles from "./PendingChanges.module.scss";

export default function PendingChanges() {
  const { loadingQuery, data: pendingChangesQuery, refetch, subscribeToMore } = useQuery(PENDING_CHANGES_QUERY, { fetchPolicy: "network-only" });

  const pendingChanges = pendingChangesQuery?.pendingChanges ? pendingChangesQuery.pendingChanges : null;

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: PENDING_CHANGES_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        return Object.assign({}, prev, {
          pendingChanges: subscriptionData.data.pendingChangesUpdated
        });
      },
    });
    return () => unsubscribe();
  }, [subscribeToMore]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (pendingChanges?.length) refetch();
    }, 2000);

    return () => clearInterval(interval);
  }, [refetch, pendingChanges]);

  if (loadingQuery) return <Loading />;

  return (
    <div className={styles.pendingChanges}>
      {!!pendingChanges ? (
        <Popover
          content={(
            <div className={[styles.pendingChangesPopoverContent, styles[pendingChanges.length ? "recalculating" : "upToDate"]].join(" ")}>
              <div className={styles.currentStatus}>{pendingChanges.length ? "Changes are pending..." : "No changes are currently pending."}</div>

              {pendingChanges.length ? pendingChanges.map((pendingChange) => (
                <div className={[styles.pendingChange, styles[pendingChange.status]].join(" ")} key={pendingChange.id}>
                  <div className={styles.status}>
                    {pendingChange.status === "active" ? (<Spinner size={20} />) : (<Icon className={styles.infoIcon} icon="time" />)}
                  </div>
                  <div className={styles.reason}>{pendingChange.reason}</div>
                </div>
              )) : null}
            </div>
          )}
          enforceFocus={false}
          minimal
          position={Position.BOTTOM_RIGHT}
        >
          <Button
            icon={!pendingChanges.length ? "tick" : <Spinner size={20} />}
            intent={pendingChanges.length ? "warning" : "success"}
            width={140}
          >
            {pendingChanges.length ? (<span>Updating...</span>) : (<span>Up to date</span>)}
          </Button>
        </Popover>
      ) : (
        <Button
          disabled
          intent="success"
          loading
          width={140}
        />
      )}
    </div>
  );
}
