import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

export default function Checkbox({
  checked,
  className,
  label,
  name = "checkbox",
  onChange,
  disabled,
}) {
  const classes = [styles.main];
  if(className) classes.push(className);

  return (
    <div className={classes.join(" ")}>
      <input
        checked={checked}
        disabled={disabled}
        id={`input-${name}`}
        name={name}
        onChange={onChange}
        type="checkbox"
      />
      {label ? (
        <label htmlFor={`input-${name}`}>{label}</label>
      ) : null}
    </div>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
