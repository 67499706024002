export const newCustomers = [{
  type: "header",
  title: "New Customers",
}, {
  type: "account",
  accountType: "New Customers",
  options: {numberFormat: "none"},
}, {
  type: "account",
  accountType: "New Customer ARPC",
  options: {useTotalOverFormula: false},
}, {
  type: "account",
  accountType: "New Customer MRR",
  options: {accountIsEditable: false},
}];

export const reactivation = [{
  type: "header",
  title: "Reactivation",
}, {
  type: "account",
  accountType: "Reactivation",
  options: {numberFormat: "none"},
}, {
  type: "account",
  accountType: "Reactivation ARPC",
  options: {useTotalOverFormula: false},
}, {
  type: "account",
  accountType: "Reactivation MRR",
  options: {accountIsEditable: false},
}];

export const expansion = [{
  type: "header",
  title: "Expansion",
}, {
  type: "account",
  accountType: "Expansion MRR",
  options: {accountIsEditable: false},
}];

export const churn = [{
  type: "header",
  title: "Churn",
}, {
  type: "account",
  accountType: "Churn MRR",
  options: {accountIsEditable: false},
}];

export const contraction = [{
  type: "header",
  title: "Contraction",
}, {
  type: "account",
  accountType: "Contraction MRR",
  options: {accountIsEditable: false},
}];

export const customers = [{
  type: "account",
  accountType: "Net New Customers",
  options: {accountIsEditable: false},
}, {
  type: "account",
  accountType: "Total Customers",
  options: {
    bold: true,
    accountIsEditable: false,
  },
}];

export const mrr = [{
  type: "account",
  accountType: "Net New MRR",
  options: {accountIsEditable: false},
}, {
  type: "account",
  accountType: "MRR",
  options: {
    bold: true,
    borderTop: true,
    isTotal: true,
  },
}];

export const spacer = {
  type: "space",
};

export default {
  newCustomers,
  reactivation,
  expansion,
  churn,
  contraction,
  customers,
  mrr,
  spacer,
};
