import {gql} from "@apollo/client";

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountProps on Account {
    id
    name
    type
    statement_type
    integration_id
    parent
    children
    detail_type
    notes
    description
    company_id
    plan_group_id
    product_id
    snapshot_id
    reference_id
    team_id
    negative
    ignore
    company_id
    options
    origin
  }
`;

export const TABLE_ROW_FRAGMENT = gql`
  fragment TableRowProps on TableRow {
    id
    name
    formatting
    account_id
    account_ids
    reference_scenario_id
    reference_snapshot_id
    reference_snapshot {
      name
      forecast_start_date
    }
    type
  }
`;

export const TABLE_COLUMN_FRAGMENT = gql`
  fragment TableColumnProps on TableColumn {
    id
    name
    formatting
    daterange
    report_period
    scenario_id
    reference_snapshot_id
    reference_snapshot {
      name
      forecast_start_date
    }
  }
`;
