import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

import { NavLink } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import { Checkbox, Alignment, FormGroup } from "@blueprintjs/core";
import Button from "components/Button";
import RichText from "components/RichTextEditor";
import Loading from "components/Loading";
import RenderError from "components/RenderError";

import ForecastType from "./ForecastType";
import Autopilot from "./Autopilot";
import GoogleSheet from "./GoogleSheet";
import Manual from "./Manual";

import { ALL_SCENARIOS_QUERY } from "../../graphql";
import {
  commitLocalEntriesCache,
  updateLocalEntriesCacheForAccount,
  resetLocalEntriesCacheForAccount,
} from "apollo-config/local-state/entries";
import {
  commitLocalModelsCache,
  updateLocalModelsCacheForAccount,
  resetLocalModelsCacheForAccount,
} from "apollo-config/local-state/models";
import { REVENUE_MRR_ACCOUNTS, UPDATE_ACCOUNT_FORECAST } from "./graphql";
import { RM_ACCOUNTS_QUERY } from "../revenue-model/forecast/graphql";
import { withHooks } from "shared/hooks/hoc";

import {
  findForecastType,
  validateGoogleSheetsModels,
} from "./forecast-sidebar-utilities";
import {
  updateAutopilotValues,
  setDefaultTargetId,
} from "shared/utilities/auto-pilot-utilities";
import { start, end } from "../../lib/client-performance";
import { revenuePercentTypes } from "shared/utilities/types";

import { AppContext } from "../../AppContext";

import styles from "./ForecastSidebar.module.scss";

const GOOGLE_SHEETS_ERROR =
  "Verify your active Google Sheets models. All fields are required. Also cell ranges must be sequential and the same row.";

const ForecastSidebar = (props) => {
  const {
    account,
    allFlightpathAccounts,
    changeGoogleSheet,
    entriesCache,
    modelsCache,
    onClose,
    scenario,
    templateSection,
    years,
  } = props;

  const { user, forecastStartDate } = useContext(AppContext);
  const scenarioString = scenario?.toString() ?? "";

  const [updateAccountForecast] = useMutation(UPDATE_ACCOUNT_FORECAST);
  const allScenariosQuery = useQuery(ALL_SCENARIOS_QUERY);
  const revenueMRRAccountsQuery = useQuery(REVENUE_MRR_ACCOUNTS);
  const revenueModelAccountsQuery = useQuery(RM_ACCOUNTS_QUERY, {
    skip: () => {
      return !window.location?.pathname?.includes("revenue-model");
    },
  });

  const [appliedToAll, setAppliedToAll] = useState(false);
  const [errors, setErrors] = useState([]);
  const [forecastType, setForecastType] = useState(
    account?.models ? findForecastType(modelsCache, scenarioString) : "manual",
  );
  const [currentForecastType, setCurrentForecastType] = useState(forecastType);
  const [hasRenderError, setHasRenderError] = useState(false);
  const [isOpen, setIsOpen] = useState(!!account);
  const [modelScenario, setModelScenario] = useState(scenario);
  const [modelScenarioString, setModelScenarioString] =
    useState(scenarioString);
  const [notes, setNotes] = useState(account?.notes ?? []);

  useEffect(() => {
    const ft = account?.models ? findForecastType(modelsCache, scenarioString) : "manual";
    setErrors([]);
    setAppliedToAll(false);
    setForecastType(ft);
    setCurrentForecastType(ft);
    setHasRenderError(false);
    setIsOpen(!!account);
    setModelScenario(scenario);
    setModelScenarioString(scenarioString);
    setNotes(account?.notes ?? "");
  }, [account, scenario]);

  const scenarios = allScenariosQuery?.data?.scenarios ?? [];
  const revenueModelAccounts = revenueModelAccountsQuery?.data?.revenueModelAccounts ?? [];
  const revenueMRRAccounts = revenueMRRAccountsQuery?.data?.revenueMRRAccounts ?? [];

  const changeModelField = (modelType, modelField, changeType, change) => {

    let value = changeType === "event" ? change.target.value : change;

    const revenueAccounts =
      modelType === "autopilot"
      ? revenueModelAccounts ?? revenueMRRAccounts ?? []
      : [];

    if (modelField === "growAmount" && (isNaN(value) || value === ""))
      value = 0;
    if (modelField === "growAmount" && value[value.length - 1] !== ".")
      value = parseFloat(value);
    if (modelField === "numMonthsAvg") value = parseInt(value, 10);
    if (modelField === "percentile") value = parseFloat(value);

    const newModel =
      modelType === "autopilot"
        ? {
            ...modelsCache[modelScenarioString][modelType],
            options: {
              ...modelsCache[modelScenarioString][modelType].options,
              [modelField]: value,
            },
          }
        : {
            ...modelsCache[modelScenarioString][modelType],
            [modelField]: value,
          };

    // Set correct defaults if type is changed
    if (
      modelField === "autopilotType" &&
      (value === "AVERAGE" || value === "WEIGHTED_AVERAGE")
    )
      newModel.options["growAmount"] = 0;
    if (modelField === "autopilotType" && value === "PERCENTILE")
      newModel.options["percentile"] = 50;
    if (
      modelField === "autopilotType" &&
      (value === "GROW_SHRINK" || value === "WEIGHTED_GROW_SHRINK") &&
      revenuePercentTypes.includes(account?.type)
    )
      newModel.options["growType"] = "$";
    if (
      modelField === "autopilotType" &&
      (value === "REVENUE_FORECAST" || value === "METRIC_FORECAST")
    )
      newModel.options["target_id"] = setDefaultTargetId(
        value,
        allFlightpathAccounts,
        revenueAccounts,
      );

    const newModels = {
      ...modelsCache,
      [modelScenarioString]: {
        ...modelsCache[modelScenarioString],
        [modelType]: newModel,
      },
    };

    if (modelType === "autopilot") {
      const newEntriesCache = updateAutopilotValues(
        user,
        allFlightpathAccounts,
        entriesCache,
        revenueAccounts,
        account,
        scenarios,
        forecastStartDate,
        newModels,
      );
      updateLocalEntriesCacheForAccount({
        id: account.id,
        entriesCache: newEntriesCache,
      });
    }

    updateLocalModelsCacheForAccount({
      id: account.id,
      modelsCache: newModels,
    });

    setAppliedToAll(false);
  };

  const changeForecastType = ({ target: { value: forecastType } }) => {
    const newModels = {
      ...modelsCache,
      [modelScenarioString]: {
        ...(modelsCache?.[modelScenarioString] ?? {}),
        autopilot: {
          ...(modelsCache?.[modelScenarioString]?.["autopilot"] ?? {}),
          active: forecastType === "autopilot",
        },
        google: {
          ...(modelsCache?.[modelScenarioString]?.["google"] ?? {}),
          active: forecastType === "google",
        },
      },
    };

    if (forecastType === "autopilot") {
      const revenueAccounts = revenueModelAccounts ?? revenueMRRAccounts ?? [];
      const updatedEntriesCache = updateAutopilotValues(
        user,
        allFlightpathAccounts,
        entriesCache,
        revenueAccounts,
        account,
        scenarios,
        forecastStartDate,
        newModels,
      );
      updateLocalEntriesCacheForAccount({
        id: account.id,
        entriesCache: updatedEntriesCache,
      });
    }

    if (forecastType !== "google") changeGoogleSheet(null);

    updateLocalModelsCacheForAccount({
      id: account.id,
      modelsCache: newModels,
    });

    setForecastType(forecastType);
    setAppliedToAll(false);
  };

  const closeSidebar = () => {
    onClose();
    setHasRenderError(false);
    setIsOpen(!isOpen);
    setErrors([]);
    resetLocalEntriesCacheForAccount({ id: account.id });
    resetLocalModelsCacheForAccount({ id: account.id });
  };

  const toggleAppliedToAll = () => {
    setAppliedToAll(!appliedToAll);
  };

  const createForecastEntriesCache = (entriesCache) => {
    const newCache = {};
    for (const key of Object.keys(entriesCache)) {
      if (key !== "null") newCache[key] = cloneDeep(entriesCache[key]);
    }
    return newCache;
  };

  const saveAccount = (closeModal) => {
    const account = {
      id: props.account.id,
      name: props.account.name,
      type: props.account.type,
      statement_type: props.account.statement_type,
      entriesCache,
    };

    // TODO: use new localEntriesCache mutation
    let models = modelsCache;
    if (appliedToAll) {
      const { models: newModels, entriesCache: newEntriesCache } = applyToAll(
        account,
        modelsCache,
        entriesCache,
      );
      models = newModels;
      account.entriesCache = newEntriesCache;
      updateLocalEntriesCacheForAccount({
        id: account.id,
        entriesCache: newEntriesCache,
      });
    }
    const scenarioId = appliedToAll ? null : modelScenario;

    // Check if notes is different from initial value
    if (validateGoogleSheetsModels(models)) {
      start("Model table");

      if (closeModal) {
        setIsOpen(false);
        onClose();
      }

      const variables = { account, models, scenarioId };
      if (notes) variables.notes = notes;

      commitLocalEntriesCache();
      commitLocalModelsCache();

      updateAccountForecast({ variables })
        .then(() => {
          end();
          setErrors([]);
        })
        .catch((error) => {
          end();
          const errorMsg = error.message.replace("GraphQL error: ", "");
          setErrors([errorMsg]);
        });
    } else {
      setErrors([GOOGLE_SHEETS_ERROR]);
    }
  };

  const applyToAll = (account, models, entriesCache) => {
    let newEntriesCache = null;
    if (forecastType === "manual") newEntriesCache = cloneDeep(entriesCache);
    const newModels = cloneDeep(models);
    for (const scenario of Object.keys(models)) {
      if (scenario && scenario !== "null" && scenario !== modelScenarioString) {
        const newModel = cloneDeep(models[modelScenarioString]);
        newModel["autopilot"].id = models[scenario]["autopilot"].id;
        newModel["autopilot"].scenario_id =
          models[scenario]["autopilot"].scenario_id;
        newModel["google"].id = models[scenario]["google"].id;
        newModel["google"].scenario_id = models[scenario]["google"].scenario_id;
        newModels[scenario] = newModel;

        if (forecastType === "manual") {
          if (!newEntriesCache[scenario]) {
            // In case the entries cache doesn't exist for this scenario, copy it but remove entry IDs and fix scenario_ids
            newEntriesCache[scenario] = cloneDeep(
              newEntriesCache[modelScenarioString],
            );
            for (const date of Object.keys(newEntriesCache[scenario])) {
              newEntriesCache[scenario][date].FORMULA = {
                ...newEntriesCache[scenario][date].FORMULA,
                scenario_id: scenario,
              };
              delete newEntriesCache[scenario][date].FORMULA.id;
            }
          } else {
            for (const date of Object.keys(newEntriesCache[scenario])) {
              newEntriesCache[scenario][date].FORMULA = {
                ...newEntriesCache[scenario][date].FORMULA,
                value: newEntriesCache[modelScenarioString][date].FORMULA.value,
              };
            }
          }
        }
      }
    }
    return { models: newModels, entriesCache: newEntriesCache };
  };

  return (
    <div
      className={`${styles.forecastSidebarContainer} ${!isOpen ? styles.closed : ""}`}
    >
      <h2 className="m-t-0">{account ? account.name : ""}</h2>
      {hasRenderError ? (
        <RenderError
          buttonCallback={closeSidebar}
          buttonText="Close"
          message={`Chances are we are working on fixing this, but if it keeps happening, please don't hesitate to <a href="mailto:support@flightpathfinance.com">contact support</a>.`}
          title="An error occurred"
        />
      ) : account?.detail_type !== "Payroll Account" ? (
        <>
          <ForecastType
            forecastType={forecastType}
            hiddenOptions={["formula"]}
            onChange={changeForecastType}
          />

          {isOpen && forecastType === "autopilot" ? (
            <Autopilot
              account={account}
              accounts={allFlightpathAccounts}
              changeModelField={changeModelField}
              entriesCache={entriesCache}
              errors={errors}
              models={modelsCache}
              revenueAccounts={revenueModelAccounts}
              revenueMRRAccounts={revenueMRRAccounts}
              scenario={modelScenario}
              scenarioFormatted={modelScenarioString}
              scenarios={scenarios}
              templateSection={templateSection}
              years={years}
            />
          ) : isOpen && forecastType === "google" ? (
            <GoogleSheet
              changeGoogleSheet={changeGoogleSheet}
              changeModelField={changeModelField}
              errors={errors}
              models={modelsCache}
              scenario={modelScenario}
              scenarioFormatted={modelScenarioString}
            />
          ) : isOpen && forecastType === "manual" ? (
            <Manual
              account={account}
              changeModelField={changeModelField}
              entriesCache={entriesCache}
              errors={errors}
              models={modelsCache}
              scenario={modelScenario}
              scenarioFormatted={modelScenarioString}
              scenarios={scenarios}
            />
          ) : null}

          <FormGroup
            className={`${styles.sidebarFormGroup} ${styles.accountNotes} m-t m-b`}
            label="Account Notes"
            labelFor="account-notes"
          >
            <RichText
              changeTextValue={(notes) => {
                setNotes(notes);
              }}
              initialValue={notes}
              placeholder="Leave a note..."
            />
          </FormGroup>
          <div className={`${styles.forecastSidebarFooter} m-t-0 m-b-0`}>
            <div className={`${styles.forecastSidebarFooterActions}`}>
              <Checkbox
                alignIndicator={Alignment.RIGHT}
                checked={appliedToAll}
                className="m-t m-b-0"
                label="Apply To All Scenarios"
                large
                onChange={toggleAppliedToAll}
              />
            </div>
          </div>
          <div className={`${styles.forecastSidebarFooter} m-t`}>
            <div className={`${styles.forecastSidebarFooterActions}`}>
              <Button className="m-r" onClick={closeSidebar} text="Close" />
              <Button
                intent="success"
                onClick={() => saveAccount(true)}
                text="Save and Close"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="m-t">
            This account is managed by your Hiring Plan.&nbsp;
            {user.isAdmin ? (
              <>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.active : styles.link
                  }
                  to="/company/hiring-plan"
                >
                  Click here
                </NavLink>{" "}
                to add or remove employees from the forecast.
              </>
            ) : null}
          </div>
          <div className={`${styles.forecastSidebarFooter}`}>
            <div className={`${styles.forecastSidebarFooterActions}`}>
              <Button className="m-r" onClick={closeSidebar} text="Close" />
              {/* <Button intent="success" onClick={() => this.saveAccount(true)} text="Save and Close" /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withHooks(ForecastSidebar, ["useAllFlightpathAccounts"]);
