import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Loading from "components/Loading";
import FormattingOptions from "components/FormattingOptions";

import FormFieldAccount from "../../shared/fields/account";
import FormFieldName from "../../shared/fields/name";
import FormFieldSwitch from "../../shared/fields/switch";
import FormFieldSnapshot from "../../shared/fields/snapshot";
import SnapshotInfosPopover from "./SnapshotInfosPopover";

import {withHooks} from "shared/hooks/hoc";
import {ALL_SCENARIOS_QUERY} from "../../../../graphql";
import {ALL_PLAN_GROUPS_QUERY} from "views/revenue-model/forecast/graphql";
import {ALL_PRODUCTS_QUERY} from "views/revenue-model/chart-of-plans/graphql";
import {ALL_TEAMS_QUERY} from "views/company-settings/payroll/graphql";

import {AppContext} from "../../../../AppContext";

import styles from "./styles.module.scss";

class RowReference extends React.Component {
  static propTypes = {
    allFlightpathAccounts: PropTypes.array.isRequired,
    allPlanGroupsQuery: PropTypes.object.isRequired,
    allProductsQuery: PropTypes.object.isRequired,
    allTeamsQuery: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    row: PropTypes.object.isRequired,
    snapshots: PropTypes.array.isRequired,
    snapshotsLoading: PropTypes.bool.isRequired,
    table: PropTypes.object.isRequired,
  }

  static contextType = AppContext

  onChangeLocal = (evt) => {
    const {
      allFlightpathAccounts: accounts,
      onChange,
      row,
      snapshots,
    } = this.props;

    let selectedAccounts;

    if(!!accounts) {
      if(!row.reference_snapshot_id || (row.reference_snapshot_id && !snapshots.length)) {
        selectedAccounts = accounts.filter((account) => !account.snapshot_id);
      } else {
        selectedAccounts = snapshots.find((snapshot) => snapshot.id === row.reference_snapshot_id).accounts;
      }
    }

    let value;
    if(evt.target.value === "0") {
      value = null;
    } else {
      value = evt.target.value;
    }

    const rowClone = {...row};

    const changes = [{
      key: evt.target.name,
      value,
    }];

    if(evt.target.name === "account_id") {
      value = evt.target.value;
      let newName = evt.target.text;
      if(evt.target.label && !["PNL", "BS", "CFS", "REV", "WORK"].includes(evt.target.label)) newName += ` (${evt.target.label})`;
      // If the name of the line is the same as the name of the account, let's assume they didn't change it,
      // so it's safe to replace it with the name of the new account
      changes.push({
        key: "name",
        value: newName,
      });
    } else if(evt.target.name === "reference_snapshot_id") {
      if(changes[0].value) changes[0].value = changes[0].value;
      // The snapshot that has just been selected, and its accounts
      const selectedSnapshot = !!value ? snapshots.find((snapshot) => snapshot.id === evt.target.value) : null;
      const newSnapshotAccounts = selectedSnapshot ? selectedSnapshot.accounts : accounts.filter((account) => !account.snapshot_id);

      // The accounts belonging to the previously selected snapshot (or current forecast if it's the case)
      const previouslySelectedSnapshot = rowClone.reference_snapshot_id ? snapshots.find((snapshot) => snapshot.id === rowClone.reference_snapshot_id) : null;
      const previousAccounts = previouslySelectedSnapshot ? previouslySelectedSnapshot.accounts : selectedAccounts;

      // The previously selected account
      const previouslySelectedAccount = previousAccounts.find((account) => account.id === rowClone.account_id);
      let previouslySelectedAccountId = "1";
      if(previouslySelectedAccount) previouslySelectedAccountId = previouslySelectedSnapshot ? previouslySelectedAccount.reference_id : previouslySelectedAccount.id;

      // Newly selected account id
      const newlySelectedAccount = newSnapshotAccounts.find((account) => account.reference_id === previouslySelectedAccountId || account.id === previouslySelectedAccountId);
      const account_id = newlySelectedAccount ? newlySelectedAccount.id : selectedAccounts[0].id;
      changes.push({
        key: "account_id",
        value: account_id,
      });
    }

    onChange(changes);
  }

  onChangeRowType = () => {
    const {
      allFlightpathAccounts: accounts,
      row,
      onChange,
    } = this.props;

    const changes = [{
      key: "account_id",
      value: null,
    }];

    if(row.account_id === null) changes[0].value = accounts[0].id;

    onChange(changes);
  }

  onChangePctOfRevenue = () => {
    const {
      row,
      onChange,
    } = this.props;

    const changes = [{
      key: "formatting",
      value: {
        ...row.formatting,
        pctOfRevenue: !row.formatting.pctOfRevenue,
        percentage: !row.formatting.pctOfRevenue,
      },
    }];

    onChange(changes);
  }

  render() {
    const {
      allFlightpathAccounts: accounts,
      allProductsQuery: {products},
      allTeamsQuery: {teams},
      allPlanGroupsQuery: {plan_groups: planGroups},
      row,
      snapshots,
      snapshotsLoading,
      table,
    } = this.props;

    const {currency} = this.context;

    let selectedAccounts;
    let selectedTeams = teams;
    let selectedProducts = products;
    let selectedPlanGroups = planGroups;

    if(!!accounts) {
      if(!row.reference_snapshot_id || (row.reference_snapshot_id && !snapshots.length)) {
        selectedAccounts = accounts.filter((account) => !account.snapshot_id);
      } else {
        selectedAccounts = snapshots.find((snapshot) => snapshot.id === row.reference_snapshot_id).accounts;
        selectedTeams = snapshots.find((snapshot) => snapshot.id === row.reference_snapshot_id).teams;
        selectedProducts = snapshots.find((snapshot) => snapshot.id === row.reference_snapshot_id).products;
        selectedPlanGroups = snapshots.find((snapshot) => snapshot.id === row.reference_snapshot_id).plan_groups;
      }
    }

    // Following code takes care is disabling snapshots in which the currently selected account does not exist
    const snapshotsItems = [...snapshots];
    for(let i = 0; i < (snapshots || []).length; i++) {
      const referenceIdToLookFor = !!row.reference_snapshot_id ? selectedAccounts.find((acct) => acct.id === row.account_id).reference_id : row.account_id;
      if(!snapshots[i].accounts.find((acct) => acct.reference_id === referenceIdToLookFor)) {
        snapshotsItems[i] = {
          ...snapshotsItems[i],
          disabled: true,
        };
      }
    }

    if(!products?.length) selectedAccounts = selectedAccounts.filter((account) => account.statement_type !== "REV");

    if(!accounts) return (<Loading size={100} />);

    return (
      <form className={styles.sidebarForm}>
        {row.account_id !== null ? (
          <div className={[styles.formGroupWithInfosWrapper, styles.hidden].join(" ")}>
            <FormFieldAccount
              accounts={selectedAccounts}
              label="Account Name"
              loading={snapshotsLoading}
              onChange={this.onChangeLocal}
              planGroups={selectedPlanGroups}
              products={selectedProducts}
              selectedAccountId={row.account_id}
              teams={selectedTeams}
            />

            <SnapshotInfosPopover disabled snapshotId={row.reference_snapshot_id} />
          </div>
        ) : null}
        <div className={[styles.formGroupWithInfosWrapper, styles.hidden].join(" ")}>
          <FormFieldName
            formGroupClass={styles.formGroup}
            label="Row Name"
            onChange={this.onChangeLocal}
            value={row.name}
          />
          <SnapshotInfosPopover disabled snapshotId={row.reference_snapshot_id} />
        </div>
        <FormFieldSwitch
          checked={row.account_id === null}
          label="Empty Row"
          name="empty_row"
          onChange={this.onChangeRowType}
        />
        <FormFieldSwitch
          checked={!!row.formatting.pctOfRevenue}
          label="Show as % of Revenue"
          name="pctOfRevenue"
          onChange={this.onChangePctOfRevenue}
        />
        {row.account_id !== null && (table.type === "SIMPLE" || row.reference_snapshot_id) ? (
          <div className={styles.formGroupWithInfosWrapper}>
            <FormFieldSnapshot
              label="Snapshot"
              loading={snapshotsLoading}
              onChange={this.onChangeLocal}
              snapshots={snapshotsItems}
              value={row.reference_snapshot_id === null ? "0" : row.reference_snapshot_id}
            />
            <SnapshotInfosPopover snapshotId={row.reference_snapshot_id} />
          </div>
        ) : null}
        <FormattingOptions
          currency={currency}
          label="Formatting Options"
          name="formatting"
          onChange={this.onChangeLocal}
          type="row"
          value={row.formatting}
        />
      </form>
    );
  }
}

const rowReferenceWithHooks = withHooks(RowReference, ["useAllFlightpathAccounts"]);

export default compose(
  graphql(ALL_SCENARIOS_QUERY, {name: "allScenariosQuery"}),
  graphql(ALL_PLAN_GROUPS_QUERY, {name: "allPlanGroupsQuery"}),
  graphql(ALL_PRODUCTS_QUERY, {name: "allProductsQuery"}),
  graphql(ALL_TEAMS_QUERY, {name: "allTeamsQuery"}),
)(rowReferenceWithHooks);
