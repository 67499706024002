import React from "react";
import PropTypes from "prop-types";

import {Button, ButtonGroup} from "@blueprintjs/core";

export default function ForecastFormatting({onChange, value}) {
  const {stacked} = value;
  const generateOnChangeEvent = (stacked) => () => {
    onChange({
      target: {
        name: "options",
        value: {
          ...value,
          stacked,
        },
      },
    });
  };

  return (
    <ButtonGroup>
      <Button
        active={!stacked}
        onClick={generateOnChangeEvent(false)}
        text="Side by side"
      />
      <Button
        active={!!stacked}
        onClick={generateOnChangeEvent(true)}
        text="Stacked"
      />
    </ButtonGroup>
  );
}

ForecastFormatting.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};
