import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Button from "components/Button";
import SettingsHeader from "components/SettingsHeader";

import {Icon} from "@blueprintjs/core";

import {generateMfaSecret, disableMfa} from "./graphql";

import SetupMFA from "./SetupMFA";
import MFAConfirmation from "./MFAConfirmation";

class MFA extends React.Component {
  static propTypes = {
    disableMfa: PropTypes.func.isRequired,
    generateMfaSecret: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  }

  state = {
    loading: false,
    mfaModalOpen: false,
    showDisableDialog: false,
  }

  onCloseDisableDialog = () => {
    this.setState({showDisableDialog: false});
  }

  toggleMfaModalOpen = () => {
    this.setState({mfaModalOpen: !this.state.mfaModalOpen});
  }

  onDisableMfa = (code) => {
    this.setState({loading: true, showDisableDialog: false});
    this.props.disableMfa({variables: {code}}).then(() => {
      this.setState({loading: false});
    });
  }

  toggleMfa = () => {
    const {user} = this.props;
    if(!user.mfa.enabled) {
      this.setState({loading: true});
      this.props.generateMfaSecret().then(() => {
        this.setState({loading: false});
        this.toggleMfaModalOpen();
      });
    } else {
      this.setState({showDisableDialog: true});
    }
  }

  render() {
    const {mfaModalOpen, showDisableDialog} = this.state;
    const {user} = this.props;

    return (
      <>
        <SettingsHeader
          subtitle
          text="Multi-factor Authentication"
        />
        <p>
          <Icon icon={user.mfa.enabled ? "tick" : "cross"} iconSize={24} />Multi-factor authentication is {!user.mfa.enabled ? "not " : null}currently enabled for your account.
        </p>
        <Button
          disabled={mfaModalOpen}
          intent={user.mfa.enabled ? "danger" : "success"}
          loading = {this.state.loading}
          onClick={this.toggleMfa}
          text={user.mfa.enabled ? "Disable" : "Enable"}
        />

        {mfaModalOpen ? <SetupMFA onClose={this.toggleMfaModalOpen} user={user} /> : null}
        <MFAConfirmation
          isOpen={showDisableDialog}
          onCancel={this.onCloseDisableDialog}
          onConfirm={this.onDisableMfa}
        />
      </>
    );
  }
}

export default compose(
  graphql(generateMfaSecret, {name: "generateMfaSecret"}),
  graphql(disableMfa, {name: "disableMfa"}),
)(MFA);
