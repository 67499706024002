import React, { useState } from "react";
import { useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Classes as BP } from "@blueprintjs/core";

import { USER_FROM_INVITE_TOKEN_QUERY } from "./graphql";

import PasswordChange, { passwordRequirementsText } from "views/shared/PasswordChange";

import styles from "./styles/Login.module.scss";

const EmailSignup = (props) => {
  const navigate = useNavigate();
  const { inviteToken, setStep } = useOutletContext();

  const {
    loading: userLoading,
    data: { getUserFromInviteToken: user } = { getUserFromInviteToken: null },
    error: userError,
  } = useQuery(
    USER_FROM_INVITE_TOKEN_QUERY,
    { variables: { inviteToken } }
  );
  const [done, setDone] = useState(false);

  const goBack = () => {
    setStep("signin");
  }

  const onSuccess = () => {
    setDone(true);
  }

  if (!user) return null;

  return (
    <div className={[inviteToken ? styles.hasToken : undefined].join(" ")}>
      <h3 className={BP.HEADING}>Email Sign Up</h3>

      <p className={[BP.TEXT_MUTED, BP.RUNNING_TEXT, styles.mfaText].join(" ")}>
        <span>
          Set a password for your account below.<br />
          {passwordRequirementsText}
        </span>
      </p>
      <div className={styles.passwordChange}>
        {!!user ? (
          <div className={styles.baseInfo}>
            <div><strong>Name</strong>: {user.name}</div>
            <div><strong>Email</strong>: {user.email}</div>
          </div>
        ) : null}
        <PasswordChange
          askForCurrentPassword={false}
          inviteToken={inviteToken}
          loginLink
          onBack={goBack}
          onSuccess={onSuccess}
          successMessage="Your password has been successfully set!"
        />
      </div>
    </div>
  );
}

export default EmailSignup;
