import {Component} from "react";
import PropTypes from "prop-types";

//import isEqual from "react-fast-compare";

import {generateRowValues} from "../shared/data-utilities";
import {getAllAccountIdsFromRow, getFormattedTableCellValue} from "./table-utilities";
import {AppContext} from "../../../AppContext";

import styles from "./TableBuilder.module.scss";

import TableRowComparisonColumns from "./TableRowComparisonColumns";

export default class TableRowValues extends Component {
  static propTypes = {
    accountsMatchingIds: PropTypes.array,
    categories: PropTypes.array.isRequired,
    columns: PropTypes.array,
    currentlyEditing: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    row: PropTypes.object.isRequired,
    rows: PropTypes.array.isRequired,
    snapshots: PropTypes.array.isRequired,
    table: PropTypes.object.isRequired,
  }

  /*shouldComponentUpdate(prevProps) {
    return (
      !isEqual(this.props.table.daterange, prevProps.table.daterange) ||
      this.props.table.report_period !== prevProps.table.report_period ||
      this.props.table.column_type !== prevProps.table.column_type ||
      this.props.table.scenario_id !== prevProps.table.scenario_id ||
      this.props.table.type !== prevProps.table.type ||
      this.props.table.options.showComparison !== prevProps.table.options.showComparison ||
      this.props.table.options.comparisonType !== prevProps.table.options.comparisonType ||
      this.props.table.options.percentageDeltaColumn !== prevProps.table.options.percentageDeltaColumn ||
      this.props.table.options.valueDeltaColumn !== prevProps.table.options.valueDeltaColumn ||
      this.props.table.options.valueDeltaFormattingOptions !== prevProps.table.options.valueDeltaFormattingOptions ||
      this.props.table.options.percentageDeltaFormattingOptions !== prevProps.table.options.percentageDeltaFormattingOptions ||
      this.props.loading !== prevProps.loading ||
      this.props.currentlyEditing.index !== prevProps.currentlyEditing.index ||
      this.props.currentlyEditing.type !== prevProps.currentlyEditing.type ||
      !isEqual(this.props.row, prevProps.row) ||
      !isEqual(this.props.columns, prevProps.columns)
    );
  }*/

  static contextType = AppContext

  render() {
    const {
      categories,
      columns,
      currentlyEditing,
      loading,
      row,
      rows,
      snapshots,
      table,
    } = this.props;

    const {forecastStartDate, currency} = this.context;

    let values = null;
    if(row.account_id && !loading) {
      const rowFormatted = {...row};
      const tableCopy = {...table};

      rowFormatted.account_ids = getAllAccountIdsFromRow({table, row, columns, snapshots});

      // If the account was already loaded, the query won't run, but it's possible that
      // we still don't have account_ids (because it's a fresh row). Make sure we do
      if(!rowFormatted.account_ids) {
        rowFormatted.account_ids = rows.find((existingRow) => existingRow.account_id === row.account_id).account_ids;
      }

      // If the row is a snapshot, provide a scenarios <-> snapsnots mapping (that we already have here)

      if(rowFormatted.reference_snapshot_id) {
        if(table.type === "SIMPLE" && !rowFormatted.reference_snapshot) {
          rowFormatted.reference_snapshot = snapshots.find((snapshot) => snapshot.id === rowFormatted.reference_snapshot_id);
        }

        const mapping = [];
        for(const snapshot of snapshots) {
          mapping.push(...snapshot.scenarios.map((scenario) => ({snapshot_id: snapshot.id, id: scenario.id, reference_id: scenario.reference_id})));
        }
        tableCopy.scenarios = mapping;
      }

      if(table.type === "ADVANCED") {
        for(const column of columns) {
          if(column.reference_snapshot_id && !column.reference_snapshot) {
            column.reference_snapshot = snapshots.find((snapshot) => snapshot.id === column.reference_snapshot_id);
          }
        }
      }

      values = generateRowValues({
        columns,
        forecastStartDate,
        row: rowFormatted,
        table: tableCopy,
      });
    } else {
      values = {};
    }

    const hasColumns = table.type === "ADVANCED" && columns?.length;
    const comparisonColumns = table.type === "ADVANCED" && columns.length === 2 && table.options?.showComparison;

    const deltas = {};
    if(comparisonColumns && !loading) {
      if(table.options.valueDeltaColumn) deltas.value = !row.account_id ? null : values.data[1] - values.data[0];
      if(table.options.percentageDeltaColumn) deltas.percentage = !row.account_id ? null : (!values.data[0] || (!values.data[0] && !values.data[1])) ? null : ((values.data[1] - values.data[0]) / Math.abs(values.data[0]));
    }

    // Keep track of the index of the delta columns
    let deltaIndex = 0;

    return (
      <>
        {categories.map((category, i) => {
          const decimals = (hasColumns && !!columns[i].formatting.decimals ? columns[i].formatting.decimals : row.formatting.decimals) || 0;
          const currencySymbol = row.formatting.dollar || (hasColumns && columns[i].formatting.dollar) ? currency : null;
          const percentage = row.formatting.percentage || (hasColumns && columns[i].formatting.percentage);
          const borderLeft = row.formatting.borderLeft || (hasColumns && columns[i].formatting.borderLeft);
          const value = !loading && !!row.account_id ? values.data[i] : null;

          return (
            <td
              className={[
                styles[loading ? "loading" : "loaded"],
                hasColumns && columns[i].formatting.bold ? styles.bold : "",
                borderLeft ? styles.borderLeft : "",
                currentlyEditing?.type === "column" && currentlyEditing?.index === i ? styles.active : "",
              ].join(" ")}
              key={category}
              onClick={loading ? null : this.editRow}
            >
              <span>
                {getFormattedTableCellValue(value, {
                  decimals,
                  currencySymbol,
                  percentage,
                  emptyRow: !row.account_id,
                  pctOfRevenue: row.formatting.pctOfRevenue,
                })}
              </span>
            </td>
          );
        })}
        {comparisonColumns ? ["value", "percentage"].map((type, i) => {
          if(!table.options[`${type}DeltaColumn`]) return null;
          deltaIndex++;
          const active = currentlyEditing?.type === `${type}DeltaColumn` && currentlyEditing?.index === (deltaIndex + categories.length - 1);

          return (
            <TableRowComparisonColumns
              active={active}
              deltas={deltas}
              isEmptyRow={!row.account_id}
              isPercentageOfRevenue={!!row.formatting.pctOfRevenue}
              key={`${i}${type}`}
              styles={styles}
              table={table}
              type={type}
            />
          );
        }) : null}
      </>
    );
  }
}
