import React, {useContext} from "react";
import PropTypes from "prop-types";

import {useQuery} from "@apollo/client";

import FormFieldName from "../shared/fields/name";
import FormFieldType from "./fields/type";
import FormFieldComparisonColumn from "./fields/comparisonColumn";
import FormFieldComparisonType from "./fields/comparisonType";
import FormFieldReportPeriod from "../shared/fields/reportPeriod";
import FormFieldDateRange from "../shared/fields/dateRange";
import FormFieldDateRangeType from "../shared/fields/displayColumns";
import FormFieldScenario from "../shared/fields/scenario";

import {AppContext} from "../../../AppContext";

import {ALL_SCENARIOS_QUERY} from "../../../graphql";

import styles from "./TableBuilder.module.scss";

export default function Header(props) {
  const {
    columns,
    onChange,
    table: {
      column_type,
      daterange,
      name,
      options,
      report_period,
      scenario_id,
      type,
    },
  } = props;

  const {years} = useContext(AppContext);
  const {data} = useQuery(ALL_SCENARIOS_QUERY);

  const onChangeLocal = (evt) => onChange(evt.target.name, evt.target.value);
  const changeDateRange = (daterange) => onChange("daterange", daterange);

  return (
    <div className={styles.tableBuilderHeader}>
      <form autoComplete="off" onSubmit={(e) => { e.preventDefault() }}>
        <div className={styles.formRow}>
          <FormFieldName
            formGroupClass={styles.formGroup}
            label="Table Name"
            onChange={onChangeLocal}
            value={name}
          />
          <FormFieldType
            formGroupClass={styles.formGroup}
            onChange={onChangeLocal}
            value={type}
          />
          {data.scenarios && data.scenarios.length && type === "SIMPLE" ? (
            <FormFieldScenario
              formGroupClass={styles.formGroup}
              label="Scenario"
              onChange={onChangeLocal}
              scenarios={data.scenarios}
              value={scenario_id}
            />
          ) : null}
        </div>
        {type === "SIMPLE" ? (
          <div className={styles.formRow}>
            <FormFieldReportPeriod
              formGroupClass={styles.formGroup}
              onChange={onChangeLocal}
              value={report_period}
            />
            <FormFieldDateRange
              changeDateRange={changeDateRange}
              column_type={column_type}
              daterange={daterange}
              formGroupClass={styles.formGroupAutoWidth}
              onChange={onChangeLocal}
              report_period={report_period}
              years={years}
            />
            <FormFieldDateRangeType
              cardType="TABLE"
              formGroupClass={styles.formGroupAutoWidth}
              onChange={onChangeLocal}
              report_period={report_period}
              value={column_type}
            />
          </div>
        ) : columns.length === 2 ? (
          <div className={styles.formRow}>
            <FormFieldComparisonColumn
              formGroupClass={styles.formGroup}
              onChange={onChangeLocal}
              switchClass={styles.showComparisonSwitch}
              value={options}
            />
            {options.showComparison ? (
              <FormFieldComparisonType
                formGroupClass={`${styles.formGroup} ${styles.comparisonType}`}
                onChange={onChangeLocal}
                value={options}
              />
            ) : null}
          </div>
        ) : null}
      </form>
    </div>
  );
}

Header.propTypes = {
  columns: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  table: PropTypes.object.isRequired,
};
