import React from "react";
import PropTypes from "prop-types";

import Errors from "components/Errors";
import TypedConfirmation from "components/TypedConfirmation";

import {ALL_EMPLOYEES_QUERY, ARCHIVED_EMPLOYEES_QUERY} from "./graphql";

class CompanySettingsEmployeesDelete extends React.Component {

  static propTypes = {
    deleteEmployee: PropTypes.bool,
    employee: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {isOpen: false, deleteButtonDisabled: true, deleteText: ""};
  }

  componentDidUpdate(prevProps) {
    if(prevProps.employee === null &&
      this.props.employee !== null &&
      this.props.deleteEmployee
    ) {
      this.setState({isOpen: true});
    }
  }

  delete = () => {
    this.props.onRemove({
      variables: {id: this.props.employee.id},
    })
    .then(() => {
      this.setState({errors: [], isOpen: !this.state.isOpen, deleteText: "", deleteButtonDisabled: true});
      this.props.onSuccess();
    }).catch((error) => {
      // eslint-disable-next-line
      console.log("There was an error sending the query", error);
      this.setState({errors: [
        "The employee could not be removed, please check your connection and try again."],
      deleteText: ""});
    });
  }

  toggleDialog = () => {
    this.props.onCancel();
    this.setState({isOpen: false, deleteButtonDisabled: true, deleteText: "", errors: []});
  }

  render() {
    const employee = (this.props.employee) ? this.props.employee : {};
    return (
      <TypedConfirmation
        isOpen={this.state.isOpen}
        onCancel={this.toggleDialog}
        onConfirm={this.delete}
      >
        <Errors messages={this.state.errors}/>
        <p>
          Are you sure that you want to remove employee <strong>{employee.title} {(employee.name) ? `(${employee.name})` : ""}</strong>?
        </p>
        <p>
          All payroll information for this employee will be lost. This action is irreversible.
        </p>

        <p>
          Please type "delete" in the input field then click <strong>Delete</strong> below if you are sure
          that you would like this employee removed.
        </p>
      </TypedConfirmation>
    );
  }
}

export default CompanySettingsEmployeesDelete;
