import classNames from "classnames";

import {
  Intent,
  Position,
  ProgressBar,
  OverlayToaster,
  Toast2,
} from "@blueprintjs/core";

export default function AppToaster({ toasts = {} }) {
  return (
    <OverlayToaster position={Position.TOP_RIGHT}>
      {Object.keys(toasts).map((name) => (
        <Toast2
          icon={toasts[name].icon}
          intent={toasts[name].intent}
          key={name}
          message={
            <div>
              <span>{toasts[name].message}</span>
              {Object.hasOwn(toasts[name], "progress") && (
                <ProgressBar
                  className={classNames("docs-toast-progress", {
                    "bp5-no-stripes": toasts[name].progress >= 100,
                  })}
                  intent={
                    toasts[name].progress < 100
                      ? Intent.PRIMARY
                      : Intent.SUCCESS
                  }
                  value={toasts[name].progress / 100}
                />
              )}
            </div>
          }
        />
      ))}
    </OverlayToaster>
  );
}
