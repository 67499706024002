import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Errors from "components/Errors";
import TypedConfirmation from "components/TypedConfirmation";

import {removePlan, ALL_PRODUCTS_QUERY} from "./graphql";
import {RM_ACCOUNTS_QUERY, ALL_PLAN_GROUPS_QUERY} from "../forecast/graphql";

class PlanDelete extends React.Component {
  static propTypes = {
    closeDialog: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    plan: PropTypes.object.isRequired,
    removePlan: PropTypes.func.isRequired,
  }

  state = {
    deleting: false,
    isOpen: true,
  }

  onCancel = () => {
    this.setState({isOpen: false});
    setTimeout(this.props.onCancel, 300);
  }

  onConfirm = () => {
    this.setState({deleting: true});
    this.props.removePlan({
      variables: {id: this.props.plan.id},
      refetchQueries: [
        {query: RM_ACCOUNTS_QUERY},
        {query: ALL_PLAN_GROUPS_QUERY},
      ],
      optimisticResponse: {
        removePlan: {
          id: this.props.plan.id,
          __typename: "Plan",
        },
      },
      update: (cache) => {
        const queryData = cache.readQuery({
          query: ALL_PRODUCTS_QUERY,
          variables: {includePlans: true},
        });

        const data = {
          ...queryData,
          products: [...queryData.products],
        };

        const product_id = this.props.plan.product_id ? this.props.plan.product_id : "0";
        const productIndex = data.products.findIndex((item) => item.id === product_id);
        data.products[productIndex] = {...data.products[productIndex]};
        if(data.products[productIndex]?.plans?.length) {
          data.products[productIndex].plans = data.products[productIndex].plans.filter((plan) => plan.id !== this.props.plan.id);

          // If it was the last plan in "unassigned", remove it.
          if(product_id === 0 && !data.products[productIndex].plans.length) {
            data.products = data.products.filter((item) => item.id !== product_id);
          }
        }
        cache.writeQuery({query: ALL_PRODUCTS_QUERY, variables: {includePlans: true}, data});
      },
    }).catch((error) => {
      console.log("There was an error sending the query", error);
      this.setState({errors: ["The plan could not be removed. All plans belonging to this plan must be moved or removed first."]});
    });
  }

  render() {
    const {plan} = this.props;

    return (
      <TypedConfirmation
        deleting={this.state.deleting}
        isOpen={this.state.isOpen}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
      >
        <Errors messages={this.state.errors}/>
        <p>Are you sure that you want to remove plan <strong>{plan.name}</strong>?</p>
        <p>
          Please type "delete" in the input field then click <strong>Delete</strong> below if you are sure
          that you would like this plan removed.
        </p>
      </TypedConfirmation>
    );
  }
}

export default compose(
  graphql(removePlan, {name: "removePlan"}),
)(PlanDelete);
