import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { useQuery } from "@apollo/client";
import dayjs from "dayjs";

import { AnchorButton as Button } from "@blueprintjs/core";

import { ALL_SCENARIOS_QUERY } from "../../graphql";

import { formatValue, isCurrentMonth, isAfterCurrentMonth, isAfter } from "shared/utilities/forecast-utilities";
import { applyManualChange, grabEntryValueFromCache } from "shared/utilities/entry-utilities";
import { updateLocalEntriesCacheForAccount } from "apollo-config/local-state/entries";
import { useEntriesCacheForAccount } from "shared/hooks/entry-hooks";
import { useModelsCacheForAccount } from "shared/hooks/model-hooks";
import { getRowIcon } from "./utilities";

import ManualCell from "components/ManualCell";

import { AppContext } from "../../AppContext";

import styles from "./AccountRow.module.scss";
import sharedStyles from "./styles.module.scss";

export default function AccountRow(props) {
  const {
    account,
    active,
    onEditAccount,
    scenario,
    year,
    templateSection,
    extended,
    cacheKeys,
    toggleCollapsed,
    collapsed,
    hidden
  } = props;

  const { currency, forecastStartDate, forecastEndDate, user } = useContext(AppContext);
  const accountEntriesCache = useEntriesCacheForAccount(account?.id);
  const modelsCache = useModelsCacheForAccount(account?.id);
  const { data: { scenarios } } = useQuery(ALL_SCENARIOS_QUERY);

  const handleCollapse = (ev) => {
    ev.stopPropagation();
    toggleCollapsed(account.id);
  }

  return useMemo(() => {
    const handleManualChange = (month, year) => (values) => {
      const updatedEntriesCache = applyManualChange({
        entriesCache: accountEntriesCache,
        forecastEndDate,
        forecastStartDate,
        month,
        scenarioId: scenario.id,
        scenarios,
        values,
        year,
      });

      updateLocalEntriesCacheForAccount({ id: account.id, entriesCache: updatedEntriesCache });
    };

    const userCanEdit = user?.tenant && ["ADMIN", "SUPER", "EDIT"].includes(user.role);
    const sectionOptions = templateSection.options || {};
    const isTotal = sectionOptions?.isTotal;
    const rowEditEnabled = userCanEdit && sectionOptions.accountIsEditable;

    // Determine if that row should have its cells manually editable
    let activeModel = null;
    if (modelsCache?.[scenario.id]) {
      for (const { 1: model } of Object.entries(modelsCache[scenario.id])) {
        if (model?.active) activeModel = model;
      }
    }
    const contentEditable = (rowEditEnabled && !activeModel && active && account.detail_type !== "Payroll Account");

    const modelIcon = getRowIcon(account, modelsCache, scenario.id);

    const rowStyles = [styles.statementRow];
    if (active) rowStyles.push(styles.editableRow);
    if (contentEditable) rowStyles.push(styles.manualRow);
    if (isTotal) rowStyles.push(styles.totalAccount);
    if (rowEditEnabled) rowStyles.push(styles.modelEnabled);
    if (sectionOptions.bold) rowStyles.push(styles.bold);
    if (sectionOptions.uppercase) rowStyles.push(styles.uppercase);
    if (sectionOptions.borderTop) rowStyles.push(styles.borderTop);
    if (hidden) rowStyles.push(styles.hidden);

    const indentation = sectionOptions.indentation || 0;

    const formattedValues = [];
    const values = [];
    //console.time("Getting values for row");

    for (const key of cacheKeys) {
      const isHistoricalMonth = !isAfterCurrentMonth({ forecastStartDate, date: key });

      const value = grabEntryValueFromCache(
        accountEntriesCache,
        (scenario && !isHistoricalMonth) ? scenario.id : null,
        key,
        sectionOptions.useTotalOverFormula ? "TOTAL" : account.entryType,
        forecastStartDate,
        false,
      );

      values.push(value);
      formattedValues.push(formatValue({
        value, // value
        currencySymbol: templateSection.options?.isTotal || templateSection.options?.indentation === 0 ? currency : null,
        isPercentage: !!templateSection.options.percentage, // isPercentage
        decimals: templateSection.options.percentage ? 1 : 0, // decimals
        shorthand: extended,
      }));
    }

    if (!accountEntriesCache) return null;
    return (
      <tr className={rowStyles.join(" ")} onClick={rowEditEnabled ? onEditAccount(account, templateSection) : null}>
        <td className={[styles[`indent${indentation}`], "line-item-title"].join(" ")}>
          <div
            className={styles.titleWrapper}
            style={sectionOptions.collapsible ? { cursor: "pointer" } : null}
          >
            <span className={!indentation && !isTotal ? styles.uppercase : null}>{templateSection.name ? templateSection.name : account.name}</span>
            {
              sectionOptions.collapsible
                ? (collapsed
                  ? <Button minimal icon="chevron-right" onClick={handleCollapse} />
                  : <Button minimal icon="chevron-down" onClick={handleCollapse} />)
                : null
            }
            {(rowEditEnabled || contentEditable) ? (
              <>
                {modelIcon !== "google" ? (
                  <span className={`${styles.modelIcon} ${(active) ? styles.editMode : ""} bp5-icon bp5-icon-${modelIcon}`} onClick={onEditAccount(account)} />
                ) : (
                  <span className={`${styles.modelIcon} ${(active) ? styles.editMode : ""} ${styles.googleSheetIcon}`} onClick={onEditAccount(account)}>
                    <svg version="1.1" viewBox="0 0 219.376 219.376"><path d="M40.63,219.376h138.117c6.617,0,12-5.383,12-12V59.227c0-3.204-1.248-6.217-3.513-8.484L135.869,3.382 C133.618,1.199,130.66,0,127.518,0H40.63c-6.617,0-12,5.383-12,12v195.376C28.63,213.993,34.013,219.376,40.63,219.376z M130.397,23.656l34.968,32.693h-33.863c-0.609,0-1.105-0.5-1.105-1.114V23.656z M43.63,15h71.768v40.236 c0,8.885,7.225,16.114,16.106,16.114h44.244v133.027H43.63V15z M56.891,178.626h106.717v-87H56.891V178.626z M117.749,106.627 h30.858v8.667h-30.858V106.627z M117.749,130.294h30.858v9.666h-30.858V130.294z M117.749,154.96h30.858v8.667h-30.858V154.96z M71.891,106.627h30.858v8.667H71.891V106.627z M71.891,130.294h30.858v9.666H71.891V130.294z M71.891,154.96h30.858v8.667H71.891 V154.96z" /></svg>
                  </span>
                )}
              </>
            ) : null}
          </div>
        </td>
        {cacheKeys.map((key, index) => {
          const isHistoricalMonth = !isAfterCurrentMonth({ forecastStartDate, date: key });
          const isAfterForecast = isAfter(key, forecastEndDate);
          const cellIsCurrentMonth = isCurrentMonth(forecastStartDate, key);
          const [year, month] = key.split("-");
          return (
            <td
              className={`${cellIsCurrentMonth ? sharedStyles.forecastStart : ""} ${(contentEditable && !isHistoricalMonth ? styles.manualCell : "")} ${isAfterForecast ? styles.disabled : ""}`}
              key={index}
            >
              {(contentEditable && !isHistoricalMonth && !isAfterForecast) ? (
                <ManualCell
                  formattedValue={formattedValues[index]}
                  onChange={handleManualChange(month, year)}
                  value={values[index]}
                />
              ) : formattedValues[index]}
            </td>
          );
        })}
      </tr>
    );
  }, [
    account,
    accountEntriesCache,
    active,
    currency,
    forecastEndDate,
    forecastStartDate,
    modelsCache,
    onEditAccount,
    scenario,
    scenarios,
    templateSection,
    user,
    year,
    extended,
    cacheKeys,
  ]);
}

AccountRow.propTypes = {
  account: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onEditAccount: PropTypes.func.isRequired,
  scenario: PropTypes.object,
  templateSection: PropTypes.object.isRequired,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  extended: PropTypes.bool,
  cacheKeys: PropTypes.array.isRequired,
};
