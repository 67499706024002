import base, {spacer} from "./base";
import totalCustomersOverrides from "./totalCustomers";
import totalMrrOverrides from "./totalMrr";
import absoluteOverrides from "./absolute";

const overridesMap = {
  TOTAL_CUSTOMERS: totalCustomersOverrides,
  PLAN_CUSTOMERS: totalCustomersOverrides,
  PLAN_MRR: totalMrrOverrides,
  TOTAL_MRR: totalMrrOverrides,
  ABSOLUTE: absoluteOverrides,
};

export function getTemplate(revenueOptions) {
  const resolved = {};

  for(const type of ["expansion", "churn", "contraction"]) {
    resolved[type] = [
      base[type][0],
      ...overridesMap[revenueOptions[type] || "TOTAL_CUSTOMERS"][type],
      base[type][1],
    ];
  }

  const sections = [];

  sections.push(
    ...base.newCustomers,
    spacer,
    ...base.reactivation,
    spacer,
    ...resolved.expansion,
    spacer,
    ...resolved.churn,
    spacer,
    ...resolved.contraction,
    spacer,
    ...base.customers,
    spacer,
    ...base.mrr,
  );

  return {sections};
}
