import {gql} from "@apollo/client";

export const ALL_ERRORS_QUERY = gql`
  query AllErrorsQuery {
    errors {
      id
      account_id
      account_id2
      date
      description
      name
      account2_name
      scenario_id
      statement_type
      value1
      value2
      formula
      company_id
    }
  }
`;
