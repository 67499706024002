import React from "react";
import PropTypes from "prop-types";

function Errors(props) {
  if(!props.messages || props.messages.length === 0) {
    return null;
  }

  return (
    <div>
      {props.messages.map((m) => (
        <div className="bp5-callout bp5-intent-danger m-b" key={m}>
          {m}
        </div>
      ))}
    </div>
  );
}

Errors.propTypes = {
  messages: PropTypes.array,
};

export default Errors;
