import React from "react";
import PropTypes from "prop-types";

import { FormGroup, InputGroup } from "@blueprintjs/core";

export default function Precision({ onChange, value }) {
  const { precision } = value;

  const generateChangeEvent = (key, newValue) => {
    onChange({
      target: {
        name: "options",
        value: {
          ...value,
          [key]: newValue,
        },
      },
    });
  };

  return (
    <FormGroup
      label="Precision"
    >
      <InputGroup
        autoComplete="off"
        id="precision"
        name="precision"
        onChange={({ target: { value } }) => generateChangeEvent("precision", value)}
        placeholder={`eg. "2" => 0.00`}
        value={precision || ""}
      />
    </FormGroup>
  );
}

Precision.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};
