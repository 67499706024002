export function evictAllDashboardsFromCache(cache) {
  Object.keys(cache.data.data).forEach((cacheKey) => {
    if(cacheKey.includes("Dashboard:")) {
      cache.evict({id: cacheKey});
    }
  });
  Object.keys(cache.data.data.ROOT_QUERY).forEach((cacheKey) => {
    if(cacheKey.includes("dashboards")) {
      cache.evict({id: "ROOT_QUERY", fieldName: cacheKey});
    }
  });
  cache.gc();
};
