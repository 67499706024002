import {gql} from "@apollo/client";
import {ACCOUNT_FRAGMENT, TABLE_ROW_FRAGMENT, TABLE_COLUMN_FRAGMENT} from "../../../shared/graphql/fragments";

export const CREATE_TABLE_MUTATION = gql`
  mutation createTable($table: JSON!, $dashboardId: String!) {
    createTable(table: $table, dashboardId: $dashboardId) {
      layout
      table {
        id
        name
        daterange
        column_type
        options
        type
        report_period
        scenario_id
        scenarios {
          id
          reference_id
          snapshot_id
        }
        accounts {
          ...AccountProps
        }
        rows {
          ...TableRowProps
        }
        columns {
          ...TableColumnProps
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${TABLE_ROW_FRAGMENT}
  ${TABLE_COLUMN_FRAGMENT}
`;

export const COPY_TABLE_MUTATION = gql`
  mutation copyTable($id: String!, $dashboardId: String!) {
    copyTable(id: $id, dashboardId: $dashboardId) {
      layout
      table {
        id
        name
        daterange
        column_type
        options
        type
        report_period
        scenario_id
        scenarios {
          id
          reference_id
          snapshot_id
        }
        accounts {
          ...AccountProps
        }
        rows {
          ...TableRowProps
        }
        columns {
          ...TableColumnProps
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${TABLE_ROW_FRAGMENT}
  ${TABLE_COLUMN_FRAGMENT}
`;

export const UPDATE_TABLE_MUTATION = gql`
  mutation updateTable($table: JSON!) {
    updateTable(table: $table) {
      id
      name
      daterange
      column_type
      options
      type
      report_period
      scenario_id
      scenarios {
        id
        reference_id
        snapshot_id
      }
      accounts {
        ...AccountProps
      }
      rows {
        ...TableRowProps
      }
      columns {
        ...TableColumnProps
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${TABLE_ROW_FRAGMENT}
  ${TABLE_COLUMN_FRAGMENT}
`;

export const REMOVE_TABLE_MUTATION = gql`
  mutation removeTable($id: String!) {
    removeTable(id: $id)
  }
`;

export const TABLE_QUERY = gql`
  query tableQuery($id: String!) {
    table(id: $id) {
      id
      name
      daterange
      column_type
      options
      type
      report_period
      scenario_id
      accounts {
        ...AccountProps
      }
      scenarios {
        id
        reference_id
        snapshot_id
      }
      rows {
        ...TableRowProps
      }
      columns {
        ...TableColumnProps
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${TABLE_ROW_FRAGMENT}
  ${TABLE_COLUMN_FRAGMENT}
`;

export const ACCOUNTS_MATCHING_IDS = gql`
  query accountsMatchingIdsQuery($ids: JSON!) {
    accountsMatchingIds(ids: $ids) {
      ...AccountProps
      entries
    }
  }
  ${ACCOUNT_FRAGMENT}
`;
