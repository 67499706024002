import React, {useContext} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import Iframe from "react-iframe";

import {Callout, Intent} from "@blueprintjs/core";

import styles from "./styles.module.scss";

import {AppContext} from "../../AppContext";

export default function CustomGoogleSheet() {
  const {user} = useContext(AppContext);
  const params = useParams();
  const index = parseInt(params.index);

  const googleSheetsUrl = user.tenant?.options?.headerLinks?.[index]?.url;

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.container}>
        {!googleSheetsUrl ? (
          <Callout intent={Intent.PRIMARY}>
            Set the Google Sheets URL in your company customization settings to view your workbook.
          </Callout>
        ) : (
          <Iframe position="inherited" url={googleSheetsUrl}/>
        )}
      </div>
    </div>
  );

}
