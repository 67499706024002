import React from "react";
import PropTypes from "prop-types";

import moment from "moment";

import { createYearsArrays } from "./views/company-settings/snapshots/forecast-settings-utilities";

export const AppContext = React.createContext({
  name: "AppContext",
});

function getDates({ user }) {
  return {
    forecastStartDate: user.tenant.options.forecastStartDate ? moment(user.tenant.options.forecastStartDate, "YYYY-MM-DD").format("YYYY-MM") : moment().format("YYYY-MM"),
    forecastEndDate: user.tenant.options.forecastEndDate ? user.tenant.options.forecastEndDate : moment().format("YYYY-MM-DD"),
    historicalsStartDate: user.tenant.options.historicalsStartDate || moment().subtract(1, "year").format("YYYY-MM-DD"),
    fiscalYearStart: user.tenant.options.fiscalYearStartDate || moment().month(0).format("YYYY-MM-DD"),
  };
}

function getSetupStatus({ companies, user }) {
  let accountingConnected = true;
  let accountingConfirmed = true;
  for (const company of companies) {
    if (!company.connected && company.enabled) {
      accountingConnected = false;
    }
    if (company.connected && !company.confirmed) {
      accountingConfirmed = false;
    }
  }
  const obj = {
    accountingConnected,
    accountingConfirmed,
    autopilotDone: !!user.tenant.onboarding?.autopilotDone,
    forecastStartDateSet: !!user.tenant.options?.forecastStartDate,
  };

  obj.setupComplete = obj.autopilotDone && obj.forecastStartDateSet;

  return obj;
}

class AppContextProvider extends React.Component {
  static propTypes = {
    addToast: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    companies: PropTypes.array.isRequired,
    onTemporarilyhideTopBars: PropTypes.func.isRequired,
    removeToast: PropTypes.func.isRequired,
    scrollbarWidth: PropTypes.number,
    user: PropTypes.object,
    years: PropTypes.arrayOf(PropTypes.string),
  }

  constructor(props) {
    const { allYearsArray: years } = createYearsArrays(props.user);

    super(props);
    this.state = {
      ...getDates(props),
      ...getSetupStatus(props),
      ...props,
      currency: props.user.tenant.options?.currency || "dollar",
      years,
      isWindows: window && window.navigator && window.navigator.platform.includes("Win"),
      isAdmin: props.user && (props.user.role === "ADMIN" || props.user.role === "SUPER"),
      isSuper: props.user && props.user.role === "SUPER",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user || !nextProps.companies !== prevState.companies) {
      return {
        ...getDates(nextProps),
        ...getSetupStatus(nextProps),
        currency: nextProps.user.tenant.options?.currency || "dollar",
        user: nextProps.user,
        companies: nextProps.companies,
        years: createYearsArrays(nextProps.user).allYearsArray,
      };
    } else if (nextProps.scrollbarWidth !== prevState.scrollbarWidth) {
      return { scrollbarWidth: nextProps.scrollbarWidth };
    } else {
      return null;
    }
  }

  render() {
    return (
      <AppContext.Provider
        value={{ ...this.state }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppContextProvider;
