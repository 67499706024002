import React from "react";
import PropTypes from "prop-types";

import numeral from "numeral";
import moment from "moment";

import {Callout, Classes, Intent} from "@blueprintjs/core";

import styles from "./SanityChecks.module.scss";

function findScenarioById(scenarios, scenarioId) {
  return scenarios.find((scenario) => scenario.id === scenarioId);
}

export default function SanityChecksErrorsTable({errors, scenarios}) {
  const calculateDifference = (value1, value2) => (value2 - value1).toFixed(2);
  const formatErrorDate = (date) => moment(date, "YYYY-MM-DD").format("MMMM YYYY");

  const generateDescription = (error) => {
    switch(error.description) {
      case "historicalBS":
      case "futureBS":
        return `${error.name} mismatches ${error.account2_name} on ${formatErrorDate(error.date)}`;
      case "bankNetCash":
        return `Bank Account Delta does not match Net Cash Delta on ${formatErrorDate(error.date)}`;
      default:
        return `${error.name} mismatches accounting on ${formatErrorDate(error.date)}`;
    }
  };

  const formatErrorValue = (value) => {
    let newValue;
    const decimalsStr = "0,0";
    value = parseFloat(value);
    if(value < 0) {
      newValue = `(${numeral(Math.abs(value)).format(decimalsStr)})`;
    } else if(value === 0) {
      newValue = " ";
    } else newValue = `${numeral(Math.abs(value)).format(decimalsStr)}`;
    return newValue;
  };

  const formatScenario = (scenarios, scenarioId) => {
    const scenario = (scenarioId) ? findScenarioById(scenarios, scenarioId) : null;
    return (scenario) ? scenario.name : "Historicals";
  };

  return (errors && errors.length > 0) ? (
    <table className={[Classes.HTML_TABLE, Classes.INTERACTIVE, styles.sanityChecksTable].join(" ")}>
      <thead>
        <tr>
          <th>Type</th>
          <th>Statement</th>
          <th>Date</th>
          <th>Scenario</th>
          <th>Compare From</th>
          <th>Compare To</th>
          <th>Difference</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {errors.map((error, index) => (
          <tr key={index}>
            <td>{error.name}</td>
            <td>{error.statement_type}</td>
            <td>{formatErrorDate(error.date)}</td>
            <td>{formatScenario(scenarios, error.scenario_id)}</td>
            <td>{formatErrorValue(error.value1)}</td>
            <td>{formatErrorValue(error.value2)}</td>
            <td>{formatErrorValue(calculateDifference(error.value1, error.value2))}</td>
            <td>{generateDescription(error)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <Callout intent={Intent.SUCCESS}>
      No errors found.
    </Callout>
  );
}

SanityChecksErrorsTable.propTypes = {
  errors: PropTypes.array.isRequired,
  scenarios: PropTypes.array.isRequired,
};
