import React from "react";
import PropTypes from "prop-types";

import {Switch} from "@blueprintjs/core";

const createSyntheticEvent = (cb) => (evt) => {
  const syntheticEvent = {
    target: {
      name: evt.target.name,
      value: evt.target.checked,
    },
  };

  cb(syntheticEvent);
};

function FormFieldSwitch(props) {
  return (
    <Switch
      checked={props.checked}
      className={props.className}
      id={props.name}
      label={props.label}
      name={props.name}
      onChange={createSyntheticEvent(props.onChange)}
    />
  );
}

FormFieldSwitch.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default FormFieldSwitch;
