import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";

import {useQuery} from "@apollo/client";

import {ALL_SCENARIOS_QUERY} from "../../../graphql";
import {useAllFlightpathAccounts} from "shared/hooks/account-hooks";

import {applyManualChange, getEntriesCacheForAccount, getAbsoluteReferences, getEntryForMonth, getEntryFromFormulaForMonth} from "shared/utilities/entry-utilities";
import {isCurrentMonth} from "shared/utilities/forecast-utilities";
import {getActiveModelForDate, isCellHighlighted, ModelPeriod, months} from "./utilities";
import {AppliesToOptions} from "./Sidebar/utilities";
import {getFormattedTableCellValue} from "../../dashboard/table-builder/table-utilities"; // Could be extrated to a generic shared function at some point

import ManualCell from "components/ManualCell";

import {AppContext} from "../../../AppContext";

import styles from "./styles.module.scss";

let rowOutsideCopy = null;

export default function RowMetricValues({
  account = null,
  highlightedCells,
  onCellClick,
  onRowChange,
  onUpdateHighlightedCells,
  row,
  scenarioId,
  worksheet,
  year,
}) {
  // Hack to fix closure issue with handleManualChange
  const {currency, forecastStartDate, forecastEndDate, historicalsStartDate} = useContext(AppContext);
  const {data: {scenarios}} = useQuery(ALL_SCENARIOS_QUERY);
  const accounts = useAllFlightpathAccounts();

  // Hack
  useEffect(() => {
    rowOutsideCopy = row;
  }, [row]);

  const handleCellHover = (year, month) => () => {
    const {modelIndex} = getActiveModelForDate(row.models, month, year, scenarioId, forecastStartDate);

    if(modelIndex > -1 && row.models[modelIndex].formulaValid !== false) {
      const absoluteReferences = getAbsoluteReferences({
        variables: row.models[modelIndex].variables,
        month,
        year,
      });

      onUpdateHighlightedCells(absoluteReferences);
    }
  };

  const handleManualChange = (month, year) => (values) => {
    // Hack to fix closure issue with onBlur
    const usedRowObj = row.id === rowOutsideCopy?.id ? rowOutsideCopy : row;
    row = usedRowObj;

    const selectedEntriesCache = row.entriesCache ? row.entriesCache : !!account ? getEntriesCacheForAccount(account) : {};

    const updatedEntriesCache = applyManualChange({
      applyTo: row.forecastAppliesTo,
      entriesCache: selectedEntriesCache,
      forecastEndDate,
      forecastStartDate,
      month,
      scenarioId,
      scenarios,
      values,
      year,
    });

    const rowMutation = {
      ...row,
      entriesCache: updatedEntriesCache,
    };

    // Update the row
    onRowChange(rowMutation);

    // Hack
    rowOutsideCopy = rowMutation;
  };

  const entries = {};
  const notDynamicMonths = {};
  const activeModelForMonths = {};
  for(const month of months) {
    const {model: activeModel} = getActiveModelForDate(row.models, month, year, scenarioId, forecastStartDate);
    activeModelForMonths[month] = activeModel;
    if(activeModel?.formulaValid === false) {
      entries[month] = null;
    } else {
      if(activeModel?.formula?.length) {
        entries[month] = getEntryFromFormulaForMonth(
          accounts,
          activeModel,
          month,
          year,
          forecastStartDate,
          historicalsStartDate,
          scenarioId,
          worksheet.rows,
        );
      } else {
        entries[month] = account || row.entriesCache ? getEntryForMonth({
          account,
          entriesCache: row.entriesCache,
          forecastStartDate,
          month,
          scenarioId,
          year,
        }) : 0;
        notDynamicMonths[month] = true;
      }
    }
  }

  const decimals = row.formatting?.decimals || 0;
  const currencySymbol = row.formatting?.dollar ? currency : null;
  const percentage = row.formatting?.percentage;

  const formattedEntries = {};
  const cellClasses = {};
  for(const [month, value] of Object.entries(entries)) {
    formattedEntries[month] = getFormattedTableCellValue(value, {
      decimals,
      currencySymbol,
      percentage,
    });

    const cellIsHighlighted = isCellHighlighted({month, year, highlightedCells, row});
    const formattedMonth = `${year}-${month.toString().padStart(2, "0")}`;
    const isCurrentMonthColumn = isCurrentMonth(forecastStartDate, formattedMonth);

    const monthCellClasses = [];
    if(cellIsHighlighted) monthCellClasses.push(styles.highlighted);
    if(isCurrentMonthColumn) monthCellClasses.push(styles.currentMonth);
    if(!activeModelForMonths[month]) monthCellClasses.push(styles.manualCell);
    if(notDynamicMonths[month]) monthCellClasses.push(styles.notDynamic);
    if(activeModelForMonths[month]?.formulaValid === false) {
      monthCellClasses.push(styles.invalid);
      if(month === 1 || (formattedMonth === forecastStartDate && ![ModelPeriod.ACTUALS, ModelPeriod.ALL].includes(activeModelForMonths[month].period))) monthCellClasses.push(styles.first);
      if(month === 12 || (activeModelForMonths[month].period === ModelPeriod.ACTUALS && isCurrentMonthColumn)) monthCellClasses.push(styles.last);
    }

    cellClasses[month] = monthCellClasses;
  }

  return months.map((month) => (
    <td
      className={cellClasses[month].join(" ")}
      key={`${row.slug}::${year}::${month}`}
      onClick={onCellClick(row, row.forecastAppliesTo && row.forecastAppliesTo !== AppliesToOptions.ALL && forecastStartDate > `${year}-${month.toString().padStart(2, "0")}`)}
      onMouseEnter={!!activeModelForMonths[month] ? handleCellHover(year, month) : null}
      onMouseLeave={!!activeModelForMonths[month] ? () => onUpdateHighlightedCells({}) : null}
    >
      {!activeModelForMonths[month] ? (
        <ManualCell
          formattedValue={formattedEntries[month]}
          onChange={handleManualChange(month, year)}
          value={entries[month]}
        />
      ) : formattedEntries[month]}
      {/*month === 12 ? deleteRowButton : null*/}
    </td>
  ));
}

RowMetricValues.propTypes = {
  account: PropTypes.object,
  deleteRowButton: PropTypes.node.isRequired,
  highlightedCells: PropTypes.object,
  onCellClick: PropTypes.func.isRequired,
  onRowChange: PropTypes.func.isRequired,
  onUpdateHighlightedCells: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  scenarioId: PropTypes.string.isRequired,
  worksheet: PropTypes.object.isRequired,
  year: PropTypes.string.isRequired,
};
