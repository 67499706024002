import React from "react";
import PropTypes from "prop-types";

import {get} from "lodash";

import styles from "./styles.module.scss";

export default function TableHeader({sortKey, sortAsc, id, children, className, ...otherProps}) {
  return (
    <th
      className={[className].join(" ")}
      id={id}
      {...otherProps}
    >
      <div className={styles.main}>
        {children}
        {sortKey === id ? (
          <span className={[styles.sort, sortAsc ? styles.asc : styles.desc].join(" ")} />
        ) : null}
      </div>
    </th>
  );
}

TableHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  sortAsc: PropTypes.bool,
  sortKey: PropTypes.string,
};

export const sortFn = (sortAsc, sortKey) => (a, b) => {
  const valueA = get(a, sortKey);
  const valueB = get(b, sortKey);
  if(sortAsc) {
    return valueA > valueB ? 1 : -1;
  } else {
    return valueA <= valueB ? 1 : -1;
  }
};
