import React, {useRef, useState} from "react";
import PropTypes from "prop-types";

import ContentEditable from "react-contenteditable";
import numeral from "numeral";

import styles from "./styles.module.scss";

import {currencyToSymbolMapping} from "shared/utilities/forecast-utilities";

const ALLOWED_SPECIAL_CHARS = ["-", ".", ",", "(", ")", "%"];

export default function ManualCell({formattedValue, onChange, value}) {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const string = String.fromCharCode(keyCode).trim();

    if(keyCode === 13) {
      event.returnValue = true;
      if(event.preventDefault) event.preventDefault();
      event.target.blur();
    } else if(keyCode === 9) {
      event.returnValue = true;
      if(event.preventDefault) event.preventDefault();
      event.target.blur();
      focusNextElement();
    } else if(isNaN(string) && !ALLOWED_SPECIAL_CHARS.includes(string)) {
      event.returnValue = false;
      if(event.preventDefault) event.preventDefault();
    }
  };

  const handlePaste = (event) => {
    const regex = /^([-(]?[0-9,]+(\.\d*)?){1}[)]?\%?$/;
    event.preventDefault();
    const currencyReplaceRegex = new RegExp(Object.values(currencyToSymbolMapping).map((char) => `\\${char}`).join("|"), "gi");
    const pasteArray = event.clipboardData.getData("text/plain")
    .split(/\t/g) // Split on tabs
    .map((item) => item.trim().replace(currencyReplaceRegex, "").replace(" ", "")) // Remove dollar signs and spaces
    .map((item) => item === "" || !regex.test(item) ? 0 : numeral(item).value()); // Test value and parse to number

    if(pasteArray.length) {
      onChange(pasteArray, "onPaste");
    }
  };

  const handleMouseDown = (evt) => {
    if(!isFocused) evt.preventDefault();
  };

  const handleMouseUp = () => {
    if(!isFocused) {
      // TODO: figure out how to properly clear this setTimeout when unmounting the component
      setTimeout(() => {
        if(inputRef.current) {
          const rangeObj = document.createRange();
          const selectObj = window.getSelection();
          if(inputRef?.current?.childNodes[0]) rangeObj.setStart(inputRef.current.childNodes[0], inputRef.current.childNodes[0].length);
          rangeObj.collapse(true);
          selectObj.removeAllRanges();
          selectObj.addRange(rangeObj);
          if(inputRef?.current) inputRef.current.scrollLeft = inputRef.current.scrollWidth;
          if(inputRef?.current) inputRef.current.focus();
          setIsFocused(true);
        }
      }, 0);
    }
  };

  const handleFocus = () => {
    if(!isFocused) handleMouseUp();
  };

  const handleChange = ({currentTarget: {innerText: value}}) => onChange([value], "onChange");

  const handleBlur = ({currentTarget: {innerText: value}}) => {
    onChange([numeral(value === "" ? 0 : value).value()], "onBlur");
    setIsFocused(false);
  };

  const classes = [styles.main];
  if(isFocused) classes.push(styles.manualCellFocused);

  return (
    <ContentEditable
      className={classes.join(" ")}
      html={isFocused ? (value || "").toString() : formattedValue}
      innerRef={inputRef}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyPress={handleKeyPress}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onPaste={handlePaste}
    />
  );
}

ManualCell.propTypes = {
  formattedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

function focusNextElement() {
  //add all elements we want to include in our selection
  const focussableElements = `a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"]), div[contenteditable]:not([disabled])`;
  if(document.activeElement && document.activeElement.form) {
    //check for visibility while always include the current activeElement
    const focussable = Array.prototype.filter.call(document.activeElement.form.querySelectorAll(focussableElements), (element) => element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement);

    const index = focussable.indexOf(document.activeElement);
    if(index > -1) {
      const nextElement = focussable[index + 1] || focussable[0];
      nextElement.focus();
    }
  }
};
