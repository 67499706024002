import React from "react";

import { Outlet } from "react-router-dom";

import styles from "./styles.module.scss";

export default function Worksheets() {
  return (
    <div className={styles.mainWrapper}>
      <Outlet />
    </div>
  );
}
