import PropTypes from "prop-types";

export default {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    formatting: PropTypes.shape({
      bold: PropTypes.bool,
      borderTop: PropTypes.bool,
      dollar: PropTypes.bool,
      percentage: PropTypes.bool,
      indentation: PropTypes.number,
      decimals: PropTypes.number,
    }),
  }),
};
