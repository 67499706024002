import { useEffect, useRef } from "react";
import memoize from "memoizee/weak";
import moment from "moment";

import getUniqueId from "shared/unique-id";
import { isAfterCurrentMonth } from "shared/utilities/forecast-utilities";
import { getEntriesCacheForAccount, getEntryForMonth } from "shared/utilities/entry-utilities";

import { WORKSHEETS_QUERY } from "../List/graphql";
import { WORKSHEET_QUERY } from "./graphql";

export const RowType = {
  REFERENCE: "REFERENCE",
  METRIC: "METRIC",
  EMPTY: "EMPTY",
};

export const ModelPeriod = {
  ACTUALS: "ACTUALS",
  ALL: "ALL",
  FORECASTS: "FORECASTS",
};

export const months = [];
for (let i = 1; i <= 12; i++) {
  months.push(i);
}

const baseModel = {
  formula: "",
  active: true,
  variables: {},
  period: null,
  scenario_id: null,
  frontendId: getUniqueId(),
};

export const updateStore = (action) => (cache, { data: { createWorksheet, removeWorksheet, updateWorksheet } }) => {
  if (action === "create" || action === "delete") {
    try {
      const worksheetsQueryData = cache.readQuery({ query: WORKSHEETS_QUERY });
      if (!worksheetsQueryData || !worksheetsQueryData.worksheets) return;

      const worksheetsData = {
        ...worksheetsQueryData,
        worksheets: [...worksheetsQueryData.worksheets],
      };

      if (action === "create") {
        worksheetsData.worksheets.push(createWorksheet);
      } else if (action === "delete") {
        const index = worksheetsData.worksheets.findIndex((worksheet) => worksheet.id === removeWorksheet);
        worksheetsData.worksheets.splice(index, 1);
      }
      cache.writeQuery({ query: WORKSHEETS_QUERY, data: worksheetsData });
    } catch (e) { }
  }

  if (action === "update") {
    // Replace the models with the backend-created models
    const worksheetQueryData = cache.readQuery({ query: WORKSHEET_QUERY, variables: { slug: updateWorksheet.slug } });
    const worksheetData = {
      ...worksheetQueryData,
      worksheet: {
        ...worksheetQueryData.worksheet,
        rows: [...worksheetQueryData.worksheet.rows],
      },
    };
    for (let i = 0; i < worksheetQueryData.worksheet.rows.length; i++) {
      worksheetData.worksheet.rows[i] = {
        ...worksheetData.worksheet.rows[i],
        models: updateWorksheet.rows.find((item) => item.id === worksheetData.worksheet.rows[i].id).models,
      };
    }
    cache.writeQuery({ query: WORKSHEET_QUERY, variables: { slug: updateWorksheet.slug }, data: worksheetData });
  }
};

export function isCellHighlighted({ month, year, highlightedCells, row }) {
  let cellIsHighlighted = false;
  const currentMonthStr = `${year}${month.toString().padStart(2, "0")}`;
  for (const [varName, attributes] of Object.entries(highlightedCells)) {
    let varNameWithoutNumber = varName;
    if (varName.includes(`${row.slug}_`)) {
      const varNameChunks = varName.split("_");
      if (/$[0-9]+^/.test(varNameChunks[varNameChunks.length - 1])) {
        varNameWithoutNumber = varNameChunks.slice(0, -1).join("_");
      }
    }
    if (row.slug === varNameWithoutNumber) {
      const isEqualOrAfterStart = currentMonthStr >= `${attributes.startYear}${attributes.startMonth.toString().padStart(2, "0")}`;
      const isEqualOrBeforeEnd = currentMonthStr <= `${attributes.endYear}${attributes.endMonth.toString().padStart(2, "0")}`;
      cellIsHighlighted = isEqualOrAfterStart && isEqualOrBeforeEnd;
    }
    if (cellIsHighlighted) break;
  }

  return cellIsHighlighted;
};

export const getActiveModelForDate = memoize((
  models,
  month,
  year,
  scenarioId,
  forecastStartDate,
  isActual,
) => {
  isActual = typeof isActual === "undefined" ? null : isActual;
  let model = null;
  const matchingModels = [];
  let modelIndex = null;

  if (isActual === null) isActual = !isAfterCurrentMonth({ forecastStartDate, year, month });
  if (isActual) {
    for (let i = 0; i < models.length; i++) {
      if (models[i].active !== false && [ModelPeriod.ALL, ModelPeriod.ACTUALS].includes(models[i].period)) {
        matchingModels.push(models[i]);
        if (modelIndex === null) modelIndex = i;
      }
    }
  } else {
    for (let i = 0; i < models.length; i++) {
      if (models[i].active !== false) {
        if (models[i].period === ModelPeriod.ALL) {
          matchingModels.push(models[i]);
          if (modelIndex === null) modelIndex = i;
        } else if (models[i].period === null || models[i].period === ModelPeriod.FORECASTS) {
          if (!models[i].scenario_id || models[i].scenario_id === scenarioId) {
            matchingModels.push(models[i]);
            if (modelIndex === null) modelIndex = i;
          }
        }
      }
    }
  }

  if (matchingModels.length > 1) console.log("WARNING: more than one matching model was found for", year, month, matchingModels);
  model = matchingModels[0];

  return { model, modelIndex };
});

export function generateModelsCacheForRow({ accounts, row, scenarios, historicalsStartDate, forecastStartDate, forecastEndDate }) {
  const modelsCacheForRow = {
    [null]: {},
  };

  for (const scenario of scenarios) {
    modelsCacheForRow[scenario.id] = {};
  }

  // For actuals
  let i = 0;
  let cursor = moment(historicalsStartDate, "YYYY-MM-DD");
  let end = moment(forecastStartDate, "YYYY-MM");

  while (cursor.isBefore(end)) {
    if (i++ > 500) break; // Defensive check to stop infinite loops while developing
    const cacheDateKey = cursor.format("YYYY-MM");
    const month = cursor.month() + 1;
    const year = cursor.year();
    modelsCacheForRow[null][cacheDateKey] = { ...getActiveModelForDate(row.models || [], month, year, null, null, true)?.model ?? { ...baseModel } };
    modelsCacheForRow[null][cacheDateKey].period = null;
    modelsCacheForRow[null][cacheDateKey].scenarioId = null;

    cursor.add(1, "month");
  }

  // For forecasts
  i = 0;
  cursor = moment(forecastStartDate, "YYYY-MM");
  end = moment(forecastEndDate, "YYYY-MM-DD");
  while (cursor.isBefore(end)) {
    if (i++ > 500) break; // Defensive check to stop infinite loops while developing
    const cacheDateKey = cursor.format("YYYY-MM");
    const month = cursor.month() + 1;
    const year = cursor.year();

    for (const scenario of scenarios) {
      let model = getActiveModelForDate(row.models || [], month, year, scenario.id, forecastStartDate)?.model;
      if (!model) {
        model = {
          ...baseModel,
        };

        const account = accounts.find((acct) => acct.id === row.account_id);
        const entryFromCache = getEntryForMonth({ account, forecastStartDate, scenarioId: scenario.id, month, year });
        if (entryFromCache !== null) model.formula = `${entryFromCache}`;
      }
      modelsCacheForRow[scenario.id][cacheDateKey] = { ...model };
      modelsCacheForRow[scenario.id][cacheDateKey].period = null;
      modelsCacheForRow[scenario.id][cacheDateKey].scenarioId = null;
    }

    cursor.add(1, "month");
  }

  return modelsCacheForRow;
}

// If rowId is set, it means we only want to generate the cache for that specific row
export function generateModelsCache({ accounts, worksheet, scenarios, historicalsStartDate, forecastStartDate, forecastEndDate }) {
  const modelsCache = {};
  if (!worksheet || !worksheet.rows) return {};

  for (const row of worksheet.rows) {
    if (row.type === RowType.REFERENCE) continue;
    modelsCache[row.id] = generateModelsCacheForRow({ accounts, row, scenarios, historicalsStartDate, forecastStartDate, forecastEndDate });
  }

  return modelsCache;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const getModelForCell = memoize(({ rowId, forecastStartDate, month, year, scenarioId, modelsCache }) => {
  const isActual = !isAfterCurrentMonth({ forecastStartDate, month, year });
  const scenarioKey = isActual ? "null" : scenarioId;
  const dateKey = `${year}-${month.toString().padStart(2, "0")}`;

  return {
    model: modelsCache[rowId][scenarioKey][dateKey],
    rowKey: rowId,
    scenarioKey,
    dateKey,
    isActual,
  };
});

export function arrayMove(arr, oldIndex, newIndex) {
  var element = arr[oldIndex];
  arr.splice(oldIndex, 1);
  arr.splice(newIndex, 0, element);
  return arr;
};

export function cleanWorksheetFromGhostModels(worksheet) {
  if (!worksheet) return null;

  const cleanedWorksheet = {
    ...worksheet,
    rows: [],
  };

  for (const row of worksheet.rows) {
    const cleanedRow = { ...row };
    const foundModels = {};
    if (row.models?.length) {
      cleanedRow.models = [];
      for (const model of row.models) {
        const modelScenarioKey = !model.scenario_id ? "null" : model.scenario_id.toString();
        const modelPeriodsToCheck = model.period === "ALL" ? [ModelPeriod.ALL, ModelPeriod.FORECASTS, ModelPeriod.ACTUALS] : [model.period, ModelPeriod.ALL];
        for (const modelPeriodToCheck of modelPeriodsToCheck) {
          if (foundModels[modelPeriodToCheck]?.[modelScenarioKey]) {
            // If they're exactly the same, ignore the duplicates
            if (!(model.period === "ALL" && modelPeriodToCheck !== ModelPeriod.ALL) && foundModels[modelPeriodToCheck][modelScenarioKey].formula === model.formula && JSON.stringify(foundModels[modelPeriodToCheck][modelScenarioKey].variables) === JSON.stringify(model.variables)) continue;

            // If they're not, find out which one is used by entries
            if (model.account_id) {
              const entriesCache = getEntriesCacheForAccount(model.account_id);
              const accountScenarios = Object.keys(entriesCache);
              const scenarioKey = modelPeriodToCheck === ModelPeriod.ACTUALS ? "null" : !model.scenario_id ? accountScenarios.filter((scenario) => scenario !== "null" && scenario !== null)[0] : model.scenario_id;
              const dateKeys = Object.keys(entriesCache[scenarioKey]);
              const entryModelId = entriesCache[scenarioKey][dateKeys[0]].FORMULA.model_id;

              // If model_ids match, replace the chosen model for that period with this one
              if (entryModelId === model.id) {
                if (model.period === ModelPeriod.ALL && modelPeriodToCheck !== ModelPeriod.ALL) {
                  delete foundModels[modelPeriodToCheck][modelScenarioKey];
                } else if (model.period !== ModelPeriod.ALL) {
                  if (foundModels[ModelPeriod.ALL]) delete foundModels[ModelPeriod.ALL];
                }
                foundModels[modelPeriodToCheck] = {
                  ...(foundModels[modelPeriodToCheck] || {}),
                  [modelScenarioKey]: model,
                };
                continue;
              }
            }
          } else {
            if (model.period !== ModelPeriod.ALL && modelPeriodToCheck !== ModelPeriod.ALL) continue;
            foundModels[model.period] = {
              ...(foundModels[model.period] || {}),
              [modelScenarioKey]: model,
            };
          }
        }
      }

      for (const periodKey of Object.keys(foundModels)) {
        for (const scenarioKey of Object.keys(foundModels[periodKey])) {
          cleanedRow.models.push(foundModels[periodKey][scenarioKey]);
        }
      }
    }
    cleanedWorksheet.rows.push(cleanedRow);
  }

  return cleanedWorksheet;
}

export function addVarNameToSlugMappingToWorksheet(worksheet) {
  if (!worksheet) return null;

  const updatedWorksheet = { ...worksheet, rows: [] };
  for (let i = 0; i < worksheet.rows.length; i++) {
    const row = { ...worksheet.rows[i], models: [] };

    if (row.type === RowType.METRIC) {
      // For each model on the row that we're checking
      for (let j = 0; j < worksheet.rows[i].models.length; j++) {
        const model = { ...worksheet.rows[i].models[j] };
        // If there's no formula, do nothing
        if (!!model.variables && Object.keys(model.variables).length) {
          //console.log(Object.keys(model.variables));

        }
        row.models.push(model);
      }
    }
    updatedWorksheet.rows.push(row);
  }

  return updatedWorksheet;
}

export function transformWorksheetOnLoad(worksheet) {
  let newWorksheet = cleanWorksheetFromGhostModels(worksheet);
  newWorksheet = addVarNameToSlugMappingToWorksheet(newWorksheet);
  return newWorksheet;
}

export function mergeNewWorksheetWithWorkingCopy(newWorksheet, oldWorksheet) {
  for (const row of (oldWorksheet.rows || [])) {
    if (row.entriesCache) {
      const matchingRowInNewWorksheet = (newWorksheet.rows ?? []).find((newRow) => newRow.id === row.id);
      if (matchingRowInNewWorksheet) {
        matchingRowInNewWorksheet.entriesCache = row.entriesCache;
      }
    }
  }

  return newWorksheet;
}

export function findInvalidModels(worksheet) {
  const invalidModels = [];

  for (const row of worksheet.rows) {
    if (row.type !== RowType.METRIC || !row.models || !row.models.length) continue;
    for (const model of row.models) {
      if (model.formulaValid === false) invalidModels.push(model.frontendId ? model.frontendId : model.id);
    }
  }

  return invalidModels;
}

export function getNewAccount({ worksheet, row }) {
  return {
    id: getUniqueId(),
    statement_type: "MET",
    children: [],
    name: "New Metric",
    origin: "FLIGHTPATH_FINANCE",
    parent: null,
    options: {
      worksheetId: worksheet.id,
      worksheetRowId: row.id,
    },
  };
}
