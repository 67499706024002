import { useRef } from "react";
import PropTypes from "prop-types";
import SettingsHeader from "components/SettingsHeader";
import { H4, Icon, InputGroup, Button } from "@blueprintjs/core";

const appSumoTiers = {
  1: "Basic",
  2: "Professional",
  3: "Enterprise",
}

const PasswordSettings = (props) => {
  const { appsumo: { license_key, tier, license_status } = {} } = props.user;

  const isActive = license_status === "active";

  const handleCopy = (value) => {
    value && navigator.clipboard.writeText(value);
  };

  return (
    <>
      <SettingsHeader subtitle text="AppSumo" />
      <H4>Status: </H4>
      <p
        style={{
          textTransform: "capitalize",
        }}
      >
        <Icon
          icon={isActive ? "tick" : "cross"}
          style={{
            color: isActive ? "#48b888" : "#e9174e",
            marginLeft: "8px",
          }}
        />
        {license_status}
      </p>
      <H4>Tier: </H4>
      <p>{`"${appSumoTiers[tier] || "Unknown"}" (Tier ${tier})`}</p>
      <H4>License Key:</H4>
      <InputGroup
        readOnly
        value={license_key}
        rightElement={
          <Button
            minimal
            icon="clipboard"
            onClick={handleCopy.bind(null, [license_key])}
          />
        }
      />
    </>
  );
};

export default PasswordSettings;
