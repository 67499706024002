import React from "react";
import PropTypes from "prop-types";

import Errors from "components/Errors";
import Button from "components/Button";

import styles from "./styles.module.scss";

class AddUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.reset();

    this.create = this.create.bind(this);
  }

  reset() {
    return {
      id: null,
      email: "",
      role: "ADMIN",
      errors: [],
    };
  }

  create = () => {
    const user = {
      id: this.state.id,
      email: this.state.email,
      role: this.state.role,
    };

    this.props.onCreate(user).then(() => {
      this.setState(this.reset());
    }).catch((error) => {
      console.log(error);

      if(error.graphQLErrors) {
        // const errors = error.graphQLErrors.map(e => e.message);
        // this.setState({errors});
        this.setState({errors: ["A proper email is required."]});
      } else {
        this.setState({errors: ["The team member could not be added."]});
      }
    });

  }

  updateField(fieldName, event) {
    const state = {};
    state[fieldName] = event.target.value.trim();

    this.setState(state);
  }

  render() {
    return (
      <div>
        <Errors messages={this.state.errors}/>
        <div className={["m-t", "inline-form", styles.addUserRow].join(" ")}>
          <label className="bp5-label bp5-inline">Email</label>
          <input
            autoComplete="off"
            className="bp5-input"
            onChange={(event) => this.updateField("email", event)}
            type="text"
            value={this.state.email}
          />
          <label className="bp5-label bp5-inline">Role</label>
          <select className="bp5-input bp5-inline" onChange={(event) => this.updateField("role", event)} value={this.state.role}>
            <option>ADMIN</option>
            <option>EDIT</option>
            <option>VIEW</option>
          </select>
          <Button
            icon="new-person"
            intent="success"
            onClick={this.create}
            size="small"
          >
            {this.props.buttonLabel}
          </Button>
        </div>
      </div>
    );
  }


}

AddUserForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  error: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
};

export default AddUserForm;
