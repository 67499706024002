import React, { useContext, useEffect } from "react";
import {
  useLocation,
  useSearchParams,
  Navigate,
  Outlet,
} from "react-router-dom";

import HorizontalNav from "components/HorizontalNav";

import { AppContext } from "../../AppContext";

import "../../styles/CompanySettings.css";

const menuItems = [
  { link: "/company/accounts", text: "Chart of Accounts" },
  { link: "/company/forecast-start-date", text: "Forecast Start Date" },
  { link: "/company/scenarios", text: "Scenarios" },
  { link: "/company/snapshots", text: "Snapshots" },
  { link: "/company/integrations", text: "Integrations" },
  { link: "/company/customization", text: "Customization" },
  { link: "/company/users", text: "Users" },
];

export default function CompanySettings() {
  const { user, addToast } = useContext(AppContext);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("connected")) {
      const message = `Connected ${searchParams.get("connected")}!`;
      addToast("integrationConnected", {
        message,
        icon: "tick-circle",
        intent: "success",
      });
    }

    if (searchParams.has("error")) {
      const message = `Sorry, there was an issue connecting to ${searchParams.get("error")}.`;
      addToast("integrationError", {
        message,
        icon: "error",
        intent: "danger",
      });
    }
  }, []);

  if (!user || !(user.role === "SUPER" || user.role === "ADMIN")) {
    return <Navigate to="/" />;
  }

  return (
    <div className="company-settings">
      <div className="main-content">
        {!location.pathname.includes("hiring-plan") ? (
          <div className="top-nav">
            <HorizontalNav menuItems={menuItems} userRole={user.role} />
          </div>
        ) : null}

        <Outlet />
      </div>
    </div>
  );
}
