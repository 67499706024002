import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Card from "components/Card";
import SettingsHeader from "components/SettingsHeader";

import ColorsTable from "./ColorsTable";
import Modal from "./CreateUpdate";

import styles from "./styles.module.scss";

import {ALL_COLORS_QUERY} from "./graphql";

class Colors extends React.Component {
  static propTypes = {
    allColorsQuery: PropTypes.object.isRequired,
  }

  state = {
    modalOpen: false,
  }

  createColor = () => {
    this.setState({modalOpen: true});
  }

  onCloseModal = () => {
    this.setState({modalOpen: false});
  }

  render() {
    const {modalOpen} = this.state;
    const {colors} = this.props.allColorsQuery;
    const createIndex = (colors && colors.length > 0) ? colors[colors.length - 1].index + 1 : 0;

    return (
      <>
        <Card className={styles.colorsContainer}>
          <SettingsHeader
            buttonAction={this.createColor}
            buttonText="New"
            subtitle
            text="Company Colors"
          />
          <ColorsTable colors={colors} />
        </Card>
        {modalOpen && <Modal index={createIndex} onClose={this.onCloseModal} />}
      </>
    );
  }
}

export default compose(
  graphql(ALL_COLORS_QUERY, {name: "allColorsQuery"}),
)(Colors);
