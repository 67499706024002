import {gql} from "@apollo/client";
import {ACCOUNT_FRAGMENT} from "./shared/graphql/fragments";

export const INITIAL_LOAD_QUERY = gql`
  query initialLoadQuery {
    currentUser {
      id
      name
      email
      displayName
      role
      super
      mfa {
        enabled
        otp
        secret
      }
      tenant {
        id
        name
        options
        onboarding
        revenue {
          expansion
          churn
          contraction
        }
        hiring {
          amount
          date
        }
      }
      options {
        sidebarOpen
      }
      appsumo {
        license_key
        license_status
        tier
      }
    }

    tenantAuthority {
      appsumo {
        tier
        license_status
      }
    }

    companies {
      id
      provider
      name
      enabled
      connected
      confirmed
      accounting_method
    }

    allAccounts {
      ...AccountProps
    }

    allEntries {
      entries
    }

    allModels {
      id
      name
      account_id
      scenario_id
      formula
      variables
      active
      options
    }

    allGoogleSheetsModels {
      id
      account_id
      scenario_id
      range_start
      range_start_date
      range_end
      workbook
      workbook_id
      sheet_id
      active
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const ALL_SCENARIOS_QUERY = gql`
  query AllScenariosQuery {
    scenarios {
      id
      name
      description: description_rich
    }
  }
`;

export const UNIVERSAL_UPDATES_SUBSCRIPTION = gql`
  subscription universalUpdates {
    universalUpdates {
      overwrite
      accounts {
        create {
          ...AccountProps
        }
        update {
          ...AccountProps
        }
        delete {
          id
        }
      }
      entries {
        create
        update
        delete
      }
      models {
        create {
          id
          name
          account_id
          scenario_id
          formula
          variables
          active
          options
        }
        update {
          id
          name
          account_id
          scenario_id
          formula
          variables
          active
          options
        }
        delete {
          id
        }
      }
      google_sheets_models {
        create {
          id
          account_id
          scenario_id
          range_start
          range_start_date
          range_end
          workbook
          workbook_id
          sheet_id
          active
        }
        update {
          id
          account_id
          scenario_id
          range_start
          range_start_date
          range_end
          workbook
          workbook_id
          sheet_id
          active
        }
        delete {
          id
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const ACCOUNTS_SUBSCRIPTION_LIGHT = gql`
  subscription accountsSubscriptionLight {
    accountsUpdatedLight  {
      id
      name
      type
      statement_type
      integration_id
      company_id
      children
      detail_type
      notes
      description
      plan_group_id
      product_id
      snapshot_id
      reference_id
      team_id
      parent
      negative
      ignore
      options
    }
  }
`;


export const TENANT_UPDATED_SUBSCRIPTION = gql`
  subscription tenantUpdated {
    tenantUpdated {
      id
      name
      options
    }
  }
`;

export const updateTenant = gql`
  mutation updateTenant($tenant: JSON!) {
    updateTenant(tenant: $tenant) {
      name
      options
      hiring {
        amount
        date
      }
    }
  }
`;
