import React from "react";
import PropTypes from "prop-types";
import PapaParse from "papaparse";

import {Icon} from "@blueprintjs/core";

import Input from "components/Input";
import {getClassesFromProps} from "components/Button";

import styles from "./styles.module.scss";

export default class CSVReader extends React.Component {
  static propTypes = {
    configOptions: PropTypes.object,
    inputRef: PropTypes.object,
    intent: PropTypes.string,
    onError: PropTypes.func,
    onFileLoaded: PropTypes.func.isRequired,
    width: PropTypes.string,
  }

  state = {
    fileLoaded: false,
  }

  handleChangeFile = (e) => {
    const {
      onFileLoaded,
      onError,
      configOptions = {},
    } = this.props;

    const reader = new window.FileReader();
    const filename = e.target.files[0].name;

    reader.onload = (event) => {
      PapaParse.parse(
        event.target.result,
        Object.assign(configOptions, {
          error: onError,
          complete: (results) => {
            this.setState({fileLoaded: true});
            onFileLoaded(results, filename);
          },
        }),
      );
    };

    reader.readAsText(e.target.files[0]);
  }

  render() {
    const {inputRef, intent, width} = this.props;
    const {fileLoaded} = this.state;

    const {classes, style} = getClassesFromProps({
      intent: (intent) ? intent : "success",
      width,
    });

    classes.push(styles.csvReader);

    return (
      <label
        className={classes.join(" ")}
        style={style}
      >
        <Input
          accept="text/csv"
          onChange={this.handleChangeFile}
          ref={inputRef}
          type="file"
        />
        <Icon icon="upload" />
        <span>Upload a {fileLoaded ? "different " : ""}CSV</span>
      </label>
    );
  }
}
