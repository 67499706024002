import {cloneDeep} from "lodash";

export const intervalMapping = {
  YEAR: "Yearly",
  MONTH: "Monthly",
  QUARTER: "Quarterly",
};

export function getPlanGroupName(planGroup) {
  return `${planGroup.product.name} - ${intervalMapping[planGroup.interval]}`;
}

export function getTemplateForView({viewType, viewValue, template, products, planGroups}) {
  const actualTemplate = cloneDeep(template);
  const extraAccounts = [];

  if(viewType === "planGroup") {
    const planGroupName = getPlanGroupName(planGroups.find((planGroup) => planGroup.id === viewValue));

    renameTotalSections({sections: actualTemplate.sections, name: planGroupName});
  } else if(viewType === "productSummary") {
    actualTemplate.options = {
      ...(actualTemplate.options || {}),
      useTotalOverFormula: true,
      readOnly: true,
    };

    const productGroupName = products.find((product) => product.id === viewValue).name;

    renameTotalSections({sections: actualTemplate.sections, name: productGroupName});
  } else {
    // This will alter the template so it looks for root accounts
    actualTemplate.sections = [];
    actualTemplate.options = {
      ...(actualTemplate.options || {}),
      useTotalOverFormula: true,
      readOnly: true,
    };

    for(const section of template.sections) {
      if(section.type === "account") {
        actualTemplate.sections.push({
          ...section,
          detailType: "root",
        });
      } else {
        actualTemplate.sections.push(section);
      }
    }
  }
  return {template: actualTemplate, extraAccounts};
}

function renameTotalSections({sections, name}) {
  const addedAccounts = [];

  const totalCustomersSectionIndex = sections.findIndex((section) => section.accountType === "Total Customers");
  sections[totalCustomersSectionIndex].name = `Customers (${name})`;
  /*const totalCustomersGlobalAccount = accounts.find((account) => account.type === "Total Customers" && account.detail_type === "root");
  addedAccounts.push(totalCustomersGlobalAccount);

  const totalCustomersGlobalSection = {
    type: "account",
    account: totalCustomersGlobalAccount,
    options: {
      bold: true,
      accountIsEditable: false,
      useTotalOverFormula: true,
    },
  };
  sections.splice(totalCustomersSectionIndex + 1, 0, totalCustomersGlobalSection);*/

  const mrrSectionIndex = sections.findIndex((section) => section.accountType === "MRR");
  sections[mrrSectionIndex].name = `MRR (${name})`;
  /*const mrrGlobalAccount = accounts.find((account) => account.type === "MRR" && account.detail_type === "root");
  addedAccounts.push(mrrGlobalAccount);

  const mrrGlobalSection = {
    type: "account",
    account: mrrGlobalAccount,
    name: "Total MRR",
    options: {
      bold: true,
      borderTop: true,
      isTotal: true,
      accountIsEditable: false,
      useTotalOverFormula: true,
    },
  };
  sections.splice(mrrSectionIndex + 1, 0, mrrGlobalSection);*/

  return {addedAccounts, sections};
}
