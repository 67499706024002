import React from "react";
import PropTypes from "prop-types";

import {useQuery} from "@apollo/client";

import moment from "moment";

import Card from "components/Card";
import Loading from "components/Loading";

import {SNAPSHOT_INFOS_QUERY} from "./graphql";

import styles from "./styles.module.scss";

export default function SnapshotInfos({snapshotId}) {
  const {loading, data} = useQuery(SNAPSHOT_INFOS_QUERY, {variables: {id: snapshotId}});

  if(loading) {
    return (
      <Card className={[styles.main, styles.loading].join(" ")}>
        <Loading size={80} />
      </Card>
    );
  }

  const {snapshot} = data;

  return (
    <Card className={styles.main}>
      <div className={styles.header}>
        {snapshot.name}
      </div>
      <div className={styles.body}>
        <div className={styles.item}>
          <div className={styles.label}>Forecast starts</div>
          <div className={styles.value}>{moment(snapshot.forecast_start_date).format("MMMM YYYY")}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Date created</div>
          <div className={styles.value}>{moment(snapshot.created_date).format("YYYY-MM-DD HH:ss")}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Scenarios in snapshot</div>
          <div className={styles.value}>
            <ul>
              {snapshot.infos.scenarios.map((scenario) => (
                <li className={styles.scenario} key={scenario.id}>{scenario.name}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Forecast ends</div>
          <div className={styles.value}>{moment(snapshot.forecast_end_date).format("MMMM YYYY")}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Accounts in snapshot</div>
          <div className={styles.value}>{snapshot.infos.accounts.length}</div>
        </div>
      </div>
    </Card>
  );
}

SnapshotInfos.propTypes = {
  snapshotId: PropTypes.string,
};
