import React, { useContext } from "react";

import { Link, NavLink, useNavigate } from "react-router-dom";

import { useMutation, useReactiveVar } from "@apollo/client";

import { Icon, Menu, MenuItem, MenuDivider, Popover, Position, Tooltip } from "@blueprintjs/core";

import Client from "../../Client";

import styles from "./Sidebar.module.scss";
import { logoWithText, logoWithoutText } from "./logos";

import { TOGGLE_SIDEBAR } from "./graphql";
import { currentUserVar } from "apollo-config/local-state/user";

import { AppContext } from "../../AppContext";
import { isSupport } from "../../privilege-hooks";

const isActive = (item, customUrl) => {
  if (item === "dashboard") {
    return location.pathname.includes("dashboard") && !location.pathname.includes("operating-model");
  } else if (item === "operating-model") {
    return location.pathname.includes("operating-model");
  } else if (item === "revenue-model") {
    return location.pathname.includes("revenue-model");
  } else if (item === "customLink") {
    // Hack for worksheets custom links
    if (customUrl.includes("/worksheets")) {
      if (customUrl === "/worksheets" && location.pathname === "/worksheets") return true;
      if (customUrl.includes("/edit") && location.pathname.includes("/edit") && customUrl.split("/edit")[0] === location.pathname.split("/edit")[0]) return true;
      return false;
    }
  }
};

function cleanFlightpathUrl(url) {
  // eslint-disable-next-line
  const pathNameRegex = /http[s]*:\/\/[^\/]+(\/.+)/;
  const matches = url.match(pathNameRegex);
  return matches[1];
}

function NavSidebar() {
  const navigate = useNavigate();
  const { setupComplete, isAdmin, isSuper, tenantAuthority } = useContext(AppContext);
  const [toggleSidebarMutation] = useMutation(TOGGLE_SIDEBAR);
  const user = useReactiveVar(currentUserVar);

  // AppSumo Tiers
  // TODO: Centralize tier info so that keeping this in sync with router isn't required.
  let revenueModelEnabled = true;
  let hiringPlanEnabled = true;
  const tier = tenantAuthority?.appsumo?.tier;
  if (!!tier) {
    revenueModelEnabled = tier >= 2;
    hiringPlanEnabled = tier >= 2;
  }

  // If we're in onboarding, don't show the sidebar
  if (!setupComplete) return null;

  const toggleSidebar = () => {
    const sidebarOpen = !user.options?.sidebarOpen;

    currentUserVar({
      ...user,
      options: {
        ...user.options,
        sidebarOpen,
      },
    });

    toggleSidebarMutation();
  };

  const logout = () => {
    Client.logout(() => {
      const protocol = window.location.protocol;
      window.location = `${protocol}//${import.meta.env.VITE_LOGIN_HOST}`;
    });
  };

  const canEdit = user?.tenant && (user.role === "ADMIN" || user.role === "SUPER" || user.role === "EDIT");
  const homeUrl = `${window.location.protocol}//${import.meta.env.VITE_LOGIN_HOST}`;
  const customLinks = user.tenant.options && user.tenant.options.headerLinks ? user.tenant.options.headerLinks : [];
  const containerClasses = [styles.sidebarContainer];

  const isOpen = (user.options?.sidebarOpen);
  if (!isOpen) {
    containerClasses.push(styles.collapsed);
  }

  let logoSrc = !isOpen ? logoWithoutText : logoWithText;

  // Hack for shufflrr logo
  if (user.tenant.name === "Shufflrr") {
    logoSrc = !isOpen ? "/shufflrr-logo.png" : "/shufflrr-logo-with-text.png";
  }

  return (
    <div className={containerClasses.join(" ")}>
      <div className={styles.logoContainer}>
        <img alt="Flightpath Finance Logo" src={logoSrc} title="Flightpath Finance Logo" />
      </div>
      <ul className={styles.menu}>
        <li>
          <NavLink
            className={() =>
              isActive("dashboard") ? `${styles.link} ${styles.active}` : styles.link
            }
            caseSensitive
            to="/dashboard"
          >
            {(isOpen) ? <Icon className={styles.icon} icon="chart" size={24} /> : <Tooltip content="Dashboard" position={Position.RIGHT}><Icon className={styles.icon} icon="chart" size={24} /></Tooltip>}
            <span className={styles.text}>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={() =>
              isActive("operating-model") ? `${styles.link} ${styles.active}` : styles.link
            }
            to="/operating-model/profit-and-loss"
          >
            {(isOpen) ? <Icon className={styles.icon} icon="airplane" size={24} /> : <Tooltip content="Operating Model" position={Position.RIGHT}><Icon className={styles.icon} icon="airplane" size={24} /></Tooltip>}
            <span className={styles.text}>Operating Model</span>
          </NavLink>
        </li>
        {(isAdmin) ? (
          <>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? `${styles.link} ${styles.active}` : styles.link
                }
                to="/worksheets"
              >
                {(isOpen) ? <Icon className={styles.icon} icon="function" size={24} /> : <Tooltip content="Worksheets" position={Position.RIGHT}><Icon className={styles.icon} icon="function" size={24} /></Tooltip>}
                <span className={styles.text}>Worksheets</span>
              </NavLink>
            </li>
            {hiringPlanEnabled &&
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? `${styles.link} ${styles.active}` : styles.link
                  }
                  to="/company/hiring-plan"
                >
                  {(isOpen) ? <Icon className={styles.icon} icon="people" size={24} /> : <Tooltip content="Hiring Plan" position={Position.RIGHT}><Icon className={styles.icon} icon="people" size={24} /></Tooltip>}
                  <span className={styles.text}>Hiring Plan</span>
                </NavLink>
              </li>
            }
          </>
        ) : null}
        {(isSuper && revenueModelEnabled) ? (
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.link} ${styles.active}` : styles.link
              }
              to="/revenue-model/summary"
            >
              {(isOpen) ? <Icon className={styles.icon} icon="bank-account" size={24} /> : <Tooltip content="Revenue Model" position={Position.RIGHT}><Icon className={styles.icon} icon="bank-account" size={24} /></Tooltip>}
              <span className={styles.text}>Revenue Model</span>
            </NavLink>
          </li>
        ) : null}
        {(canEdit) ? customLinks.map((link, index) => {
          if (link.title && link.url) {
            const actualLink = (link.url.includes("flightpathfinance.com")) ? cleanFlightpathUrl(link.url) : `/google-sheets/${index}`;
            return (
              <li key={link.url + link.title}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? `${styles.link} ${styles.active}` : styles.link
                  }
                  to={actualLink}
                >
                  {(isOpen) ? <Icon className={styles.icon} icon="wrench" size={24} /> : <Tooltip content={link.title} position={Position.RIGHT}><Icon className={styles.icon} icon="wrench" size={24} /></Tooltip>}
                  <span className={styles.text}>{link.title}</span>
                </NavLink>
              </li>
            );
          } else {
            return null;
          }
        }) : null}
        <li>
          <a
            className={styles.link}
            href="https://help.baremetrics.com/en/collections/3193980-flightpath-finance"
            rel="noopener noreferrer"
            target="_blank"
          >
            {(isOpen) ? <Icon className={styles.icon} icon="lifesaver" size={24} /> : <Tooltip content="Support" position={Position.RIGHT}><Icon className={styles.icon} icon="lifesaver" size={24} /></Tooltip>}
            <span className={styles.text}>Support</span>
          </a>
        </li>
      </ul>

      <div className={styles.companySidebarItemContainer}>
        <div className={[styles.companySidebarItem, isAdmin ? styles.includeSettingsIcon : null].join(" ")}>
          <Link className={styles.text} to="/company">{user.tenant.name}</Link>
          {(isSupport(user)) ? <Link className={styles.cogIcon} to="/admin"><Icon className={`${styles.icon}`} icon="console" size={24} /></Link> : ""}
          {(isAdmin) ? <Link className={styles.cogIcon} to="/company"><Icon className={`${styles.icon}`} icon="cog" size={24} /></Link> : ""}
          <div className={`${styles.logOutIcon} ${styles.companySidebarItemIcon}`}>
            <Popover
              content={
                <Menu className={`${styles.sidebarPopover}`}>
                  {(isAdmin) ? (
                    <>
                      <MenuItem href={homeUrl} text="Companies List" />
                      <MenuDivider />
                    </>
                  ) : null}
                  <MenuItem onClick={() => { navigate("/user-settings") }} text="User Settings" />
                  <MenuItem
                    onClick={logout}
                    target="_self"
                    text="Logout"
                  />
                </Menu>
              }
              position={Position.TOP}
            >
              <Icon className={`${styles.icon} ${styles.logOutIcon}`} icon="lock" size={24} />
            </Popover>
          </div>
        </div>
        <div className={styles.appVersion}>
          v{__APP_VERSION__}
          <div style={{ display: "none" }}>asdf</div>
        </div>
      </div>

      <div className={styles.toggleSidebar} onClick={toggleSidebar} />
    </div>
  );
}

export default NavSidebar;
