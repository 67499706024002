import React from "react";

import {useNavigate} from "react-router-dom";

import Button from "components/Button";

import styles from "./styles.module.scss";

export default function Header() {
  const navigate = useNavigate();

  const handleNewBtnClick = () => navigate("/worksheets/new");

  return (
    <div className={styles.header}>
      <Button
        icon="symbol-cross"
        intent="success"
        onClick={handleNewBtnClick}
        text="New Worksheet"
      />
    </div>
  );
}
