import React, {useContext} from "react";
import PropTypes from "prop-types";

import moment from "moment";

import {AppContext} from "../../../AppContext";

import Checkbox from "components/Checkbox";
import Input from "components/Input";
import {Position} from "@blueprintjs/core";
import {DateInput3 as DateInput} from "@blueprintjs/datetime2";

import {calculateRaisesFromSchedule} from "./employee-utilities";
import {currencyToSymbolMapping} from "shared/utilities/forecast-utilities";

import styles from "./PayrollModelCompanyWideRaises.module.scss";

export default function PayrollModelRaises({
  companyRaiseDate,
  customRaises,
  hireDate,
  onChange,
  salary,
  terminationDate,
}) {
  const {user, currency} = useContext(AppContext);

  const raises = user.tenant.hiring || [];

  const raisesWithAmounts = calculateRaisesFromSchedule({salary: salary || 0, companyWideRaises: raises, customRaises, companyRaiseDate});

  const lastCustomRaiseMoment = customRaises.length && !!customRaises[customRaises.length - 1].date ? moment(customRaises[customRaises.length - 1].date) : null;

  const hireDateMoment = moment(hireDate);

  const companyRaiseDateMoment = moment(companyRaiseDate, "YYYY-MM-DD");

  if(companyRaiseDateMoment.isSameOrBefore(hireDateMoment)) onChange(hireDateMoment.clone().add(1, "day").format("YYYY-MM-DD"));
  else if(companyRaiseDateMoment.isSameOrBefore(lastCustomRaiseMoment)) onChange(lastCustomRaiseMoment.clone().add(1, "day").format("YYYY-MM-DD"));

  //const firstCompanyWideRaiseMoment = raises.length ? moment(raises[0].date) : null;
  //const minDateInputDate = !firstCompanyWideRaiseMoment ? moment(hireDate) : moment.max([moment(hireDate), firstCompanyWideRaiseMoment]);

  return (
    <div>
      <div className={styles.companyWideRaisesSettings}>
        <Checkbox
          checked={!!companyRaiseDate}
          label="Follow Company Raise Schedule From:"
          name="companeRaiseDateChecked"
          onChange={() => onChange(!!companyRaiseDate ? null : (lastCustomRaiseMoment || hireDateMoment).clone().add(1, "day").toDate())}
        />
        <DateInput
          disabled={companyRaiseDate === null}
          formatDate={(date) => date ? moment(date).format("MM/DD/YYYY") : date}
          maxDate={terminationDate}
          minDate={lastCustomRaiseMoment ? lastCustomRaiseMoment.clone().add(1, "day").toDate() : moment(hireDate).add(1, "day").toDate()}
          onChange={onChange}
          parseDate={(str) => moment(str, "MM/DD/YYYY").toDate()}
          placeholder={"mm/dd/yyyy"}
          popoverProps={{position: Position.BOTTOM}}
          showActionsBar
          value={companyRaiseDate ? companyRaiseDateMoment.format("MM/DD/YYYY") : null}
        />
      </div>
      {!!companyRaiseDate && raises.length ? (
        <div className={styles.raises}>
          <table className={styles.raisesList}>
            <thead>
              <tr>
                <th>Raise %</th>
                <th>New Salary</th>
                <th>Raise Date</th>
              </tr>
            </thead>
            <tbody>
              {raisesWithAmounts.filter((raise) => moment(raise.date, "YYYY-MM-DD").isSameOrAfter(companyRaiseDateMoment)).map((raise) => (
                <tr className={styles.raise} key={raise.date}>
                  <td><Input disabled value={`${raise.amount}%`} /></td>
                  <td><Input disabled value={`${currencyToSymbolMapping[currency]}${raise.salary.toFixed(2)}`} /></td>
                  <td><Input disabled value={moment(raise.date).format("MM/DD/YYYY")} /></td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className={styles.info}>Raises are added automatically from the company-wide schedule</p>
        </div>
      ) : null}
    </div>
  );
}

PayrollModelRaises.propTypes = {
  companyRaiseDate: PropTypes.string,
  customRaises: PropTypes.array,
  hireDate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  salary: PropTypes.number,
  terminationDate: PropTypes.object.isRequired,
};
