import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import moment from "moment";
import _ from "lodash";

import Card from "components/Card";
import Button from "components/Button";

import Errors from "components/Errors";
import styles from "./CompanySettingsEmployees.module.scss";

import { Icon, Tooltip, Classes as BP} from "@blueprintjs/core";

function TaxRateRow({ taxRate, editTaxRate }) {
  return (
    <tr onClick={editTaxRate(taxRate)}>
      <td className="name">{taxRate.name || ""}</td>
      <td className="value">{taxRate.value || ""} %</td>
    </tr>
  );
}

TaxRateRow.propTypes = {
  editTaxRate: PropTypes.func,
  taxRate: PropTypes.object.isRequired,
};

function TableHeader({ id, children, className, ...otherProps }) {
  return (
    <th
      // className={sort}
      id={id}
      {...otherProps}
    >
      {children}
    </th>
  );
}

TableHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  // sortAsc: PropTypes.bool,
  // sortKey: PropTypes.string,
};

class CompanySettingsTaxRatesAll extends React.Component {
  static propTypes = {
    editTaxRate: PropTypes.func,
    taxRates: PropTypes.array.isRequired,
    removeTaxRate: PropTypes.func,
  }

  state = {
    operationActive: false,
  }

  toggleOperationActive = () => this.setState({ operationActive: !this.state.operationActive })

  render() {
    const { editTaxRate, taxRates } = this.props;

    return (
      <div>
        <Errors messages={this.state.errors} />
        <Card className={styles.teamContainer}>
          <span style={{ position: "absolute", top: "8px", right: "8px" }}>
            <Tooltip content="Create tax rates that can be shared between employees (eg. On a per-state basis)">
              <Icon icon="help" />
            </Tooltip>
          </span>
          <h3 className={`${BP.HEADING} ${styles.teamName}`}>Tax Rates
            {editTaxRate ? (
              <span className={styles.teamButtons}>
                <Button
                  className={styles.employeesCount}
                  size="small"
                >
                  {taxRates?.length || "0"}
                </Button>
                <Button
                  intent="success"
                  onClick={editTaxRate(null)}
                  size="small"
                >
                  Add Tax Rate
        </Button>
              </span>
            ) : null}
          </h3>

          {
            (taxRates.length > 0) ? (
              <table className={`${BP.HTML_TABLE} ${BP.INTERACTIVE} ${styles.employeesTable}`}>
                <thead>
                  <tr onClick={this.changeSort}>
                    <TableHeader
                      className={`${styles.name}`}
                      id="name"
                    >
                      Name
                          </TableHeader>
                    <TableHeader
                      className={`${styles.title}`}
                      id="value"
                    >
                      Payroll Tax
                          </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {taxRates.map((taxRate) => (
                    <TaxRateRow
                      editTaxRate={editTaxRate}
                      taxRate={taxRate}
                      key={taxRate.id}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
                <h4 className={BP.HEADING}>No tax rates added yet.</h4>
              )
          }
        </Card>
      </div>
    );
  }
}

export default CompanySettingsTaxRatesAll;
