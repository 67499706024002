import React from "react";
import PropTypes from "prop-types";

import SettingsHeader from "components/SettingsHeader";

import {Icon} from "@blueprintjs/core";

import PasswordChange, {passwordRequirementsText} from "views/shared/PasswordChange";

class PasswordSettings extends React.Component {
  static propTypes = {
    isEmailLoginEnabled: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
  }


  render() {
    const {isEmailLoginEnabled, user} = this.props;

    return (
      <>
        <SettingsHeader
          subtitle
          text={isEmailLoginEnabled ? "Change Password" : "Password Login"}
        />
        {isEmailLoginEnabled ? (
          <p>
            Change your password by typing your current password. Then, simply type your new password and confirm it. Finally, click "Change Password".<br />
            {passwordRequirementsText}
          </p>
        ) : (
          <p>
            <Icon icon="cross" iconSize={24} />Password login is {!user.mfa.enabled ? "not " : null}currently enabled for your account. You can enable it by setting one below and clicking "Enable".<br />
            {passwordRequirementsText}
          </p>
        )}
        <PasswordChange askForCurrentPassword={isEmailLoginEnabled} successMessage="Your password has been successfully changed!" />
      </>
    );
  }
}

export default PasswordSettings;
