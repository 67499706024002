import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

function Input({children, label, ...props}) {
  const classes = [styles.main];

  return (
    <>
      {label ? (<span className={styles.label}>{label}</span>) : null}
      <input className={classes.join(" ")} {...props}>
        {children}
      </input>
    </>
  );
}

Input.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
};

export default Input;
