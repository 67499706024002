import memoize from "memoizee";
import * as mathjs from "mathjs";

export const resolveFormula = memoize(({formula, references}) => {
  const variables = {};
  const chunks = [];
  let allResolved = true;
  const varNameToSlugMapping = {};

  let resolvedFormula = formula;
  const matches = formula.match(/[^{}]+(?=})/g);

  if(matches?.length) {
    for(const match of matches) {
      const {attributes, refName} = getVariableAttributesFromMatch({match, references});
      if(!attributes) {
        allResolved = false;
      } else {
        const varName = getVariableName({name: refName, variables});
        resolvedFormula = resolvedFormula.replace(`{${match}}`, varName);
        variables[varName] = attributes;
        varNameToSlugMapping[varName] = refName;
      }
    }
  }

  return {formula: resolvedFormula.toLowerCase(), variables, chunks, allResolved, varNameToSlugMapping};
});

// Adapted from this: https://stackoverflow.com/questions/14480345/how-to-get-the-nth-occurrence-in-a-string
function nthIndex(sourceStr, regexToLookFor, number) {
  const L = sourceStr.length;
  let i = -1;
  while(number-- && i++ < L) {
    //i = sourceStr.indexOf(strToLookFor, i);
    i = sourceStr.slice(i).search(regexToLookFor);
    if(i < 0) break;
  }
  return i;
}

function getRefSlugFromVarName(references, varName) {
  let ref = null;
  while(!ref) {
    for(const item of references) {
      if(varName === item.slug) {
        ref = item;
        break;
      }
    }

    if(!ref && varName.includes("___")) {
      varName = varName.split("___").slice(0, -1)[0];
    } else {
      break;
    }
  }

  return ref?.slug ?? null;
}

export function convertFormulaToEditMode({formula, references, variables}) {
  if(!formula) return {formula: "", chunks: []};
  let reconstructedFormula = formula;
  const chunks = [];
  const varNames = [];
  const varAttributes = [];
  // Find all variables in reference and replace them in the formula with the ref slugs
  for(const [varName, attributes] of Object.entries(variables)) {
    varNames.push(varName);
    varAttributes.push(attributes);
    const ref = attributes.id ? references.find((row) => row.account_id === attributes.id) : references.find((row) => row.slug === getRefSlugFromVarName(references, varName));
    let newStr = "";
    if(!ref) {
      newStr = "{account_not_found}";
    } else {
      newStr = `${ref.slug}`;
      if(attributes.start !== attributes.end || (attributes.start !== 0 || attributes.start !== 0)) {
        newStr += `, ${attributes.start}`;
        if(attributes.end !== attributes.start) {
          newStr += `, ${attributes.end}`;
        }
      }
    }
    /*if(varName.includes("::")) {
      console.log(reconstructedFormula, varName);
      debugger;
    }*/
    // This regex makes sure we are actually replacing a word and not just part of a word
    // Example: a variable named "fees" should not match the fees in "subscription_fees"
    const replaceRegex = new RegExp(`\\b(?:${varName})\\b`, "g");
    const matches = reconstructedFormula.match(replaceRegex);

    // For each match in the formula (there can be multiple matches)
    for(let i = 0; i < (matches?.length ?? 0); i++) {
      // Find the preceding char. If it's an opening curly brace, we need to treat this differently
      const precedingChar = reconstructedFormula[nthIndex(reconstructedFormula, replaceRegex, i + 1) - 1];

      let matchIndexInReplace = -1; // Init to -1 to first occurence in replace fn is 0
      reconstructedFormula = reconstructedFormula.replace(replaceRegex, (match) => {
        matchIndexInReplace++;
        // If this the nth occurence, corresponding to where we are in the loop currently
        if(matchIndexInReplace === i) {
          // If the preceding char is not {, replace with the varName surrended by curly braces.
          // If preceding character is {, do not surround it as we are currently writing our formula
          return precedingChar !== "{" ? `{${newStr}}` : newStr;
        }
        return match;
      });
    }
  }

  // Return the chunks for display
  if(Object.keys(variables).length) {
    const splitRegex = new RegExp(`\\b(?:${Object.keys(variables).join("|")})\\b`, "g");
    const staticChunks = formula.split(splitRegex);

    for(let i = 0; i < staticChunks.length; i++) {
      chunks.push({type: "static", value: staticChunks[i]});
      if(varNames[i]) {
        chunks.push({type: "dynamic", value: {...varAttributes[i], refName: varNames[i]}});
      } else {
        break;
      }
    }
  } else {
    // If we have no variables, it means the whole formula is just a big static chunk
    chunks.push({type: "static", value: formula});
  }

  return {formula: reconstructedFormula, chunks: chunks.filter((chunk) => chunk.value !== "")};
}

function getVariableName({name, variables}) {
  let chosenName = name;
  let i = 1;
  while(variables[chosenName]) {
    chosenName = `${chosenName}___${i++}`;
  }
  return chosenName;
}

function getVariableAttributesFromMatch({match, references}) {
  const matchElements = match.split(",").map((item) => item.trim());
  const refName = matchElements[0];
  let start = 0;
  let end = 0;
  if(matchElements.length >= 2) {
    start = parseInt(matchElements[1], 10);
    end = start;
  }
  if(matchElements.length >= 3) end = parseInt(matchElements[2], 10);

  const matchingReference = references.find((row) => row.slug === refName);

  if(!matchingReference || isNaN(start) || isNaN(end)) return false;

  const returnObj = {
    refName,
    attributes: {
      id: matchingReference.account_id,
      start,
      end,
    },
  };

  if(matchingReference.useParentValues) returnObj.attributes.formulaType = "mixed";

  return returnObj;
}

export function isFormulaValid({formula, variables}) {
  const scope = {};
  for(const [varName, attributes] of Object.entries(variables)) {
    if(attributes.end < attributes.start) return false;
    scope[varName] = 1; // Feed dumb value just to see if it resolves with MathJs
  }

  try {
    const compiledFormula = mathjs.compile(formula);
    const result = compiledFormula.evaluate(scope);
    return typeof result !== "function";
  } catch(e) {
    return false;
  }
}
