import {cloneDeep, get} from "lodash";
import moment from "moment";
import memoize from "memoizee";
import numeral from "numeral";
import * as mathjs from "mathjs";

import {entriesCacheVar, localEntriesCacheVar} from "apollo-config/local-state/entries";

import {getActiveModelForDate, ModelPeriod, RowType} from "../../views/worksheets/Worksheet/utilities";
import {AppliesToOptions} from "../../views/worksheets/Worksheet/Sidebar/utilities";

/*
Cache structure
account: {
	scenario: {
		date: {
			type (TOTAL, FORMULA): entry
		}
	}
}
*/

export const grabEntryValueFromCache = (entriesCache, scenarioId, date, type, forecastStartDate, isSnapshot) => {
  let scenarioIdFormatted = "";
  const formattedForecastStartDate = moment.isMoment(forecastStartDate) ? forecastStartDate.format("YYYY-MM") : forecastStartDate;

  // If we only have entries for one scenario, return entries for that scenario in case scenario_id is not provided
  const numberOfScenariosInEntriesCache = Object.keys(entriesCache).reduce((total, key) => key === "null" || key === null ? total : total + 1, 0);
  if(scenarioId === null && numberOfScenariosInEntriesCache === 1) {
    scenarioId = Object.keys(entriesCache).filter((key) => key !== "null" && key !== null)[0];
  }

  if(!isSnapshot) {
    scenarioIdFormatted = (scenarioId && formattedForecastStartDate <= date) ? scenarioId.toString() : "null";
  } else {
    if(formattedForecastStartDate > date) return null;

    scenarioIdFormatted = scenarioId.toString();
  }

  const types = ["TOTAL", "FORMULA"];
  let value = null;
  // If no entry type has been specified, try the above types in order (fixes the total value always being 0 in charts when values with type formula existed)
  if(!type) {
    let i = 0;
    while(i < types.length && value === null) {
      const key = `${scenarioIdFormatted}.${date}.${types[i]}.value`;
      value = get(entriesCache, key, null);
      i++;
    }
  } else {
    const key = `${scenarioIdFormatted}.${date}.${type}.value`;
    value = get(entriesCache, key, null);
    if(value === null) value = 0;
  }

  return value;
};

// parses references and returns direct references to account entry for specific date
export function getAbsoluteReferences({variables, month, year}) {
  if(!variables) return {};
  const absoluteReferences = {};

  for(const [variable, properties] of Object.entries(variables)) {
    const resolved = {
      months: {
        start: month,
        end: month,
      },
      years: {
        start: year,
        end: year,
      },
    };

    for(const type of ["start", "end"]) {
      if(properties[type] !== 0) {
        resolved.months[type] = month + properties[type];
        if(resolved.months[type] < 1 || resolved.months[type] > 12) {
          const newDate = moment(`${year}-${month}`, "YYYY-M").add(properties[type], "months"); // For now we are only precessing months, not other time periods
          resolved.months[type] = newDate.get("month") + 1; // Returns the months as indexes and not real numbered months
          resolved.years[type] = newDate.get("year");
        }
      }
    }

    absoluteReferences[variable] = {
      id: properties.id,
      startMonth: resolved.months.start,
      startYear: parseInt(resolved.years.start),
      endMonth: resolved.months.end,
      endYear: parseInt(resolved.years.end),
      formulaType: properties.formulaType,
    };
  }

  return absoluteReferences;
}

// This takes a formula and returns the result for a given month
export const getEntryFromFormulaForMonth = memoize((
  accounts,
  model,
  month, // Month as a number from 1 to 12
  year, // Year
  forecastStartDate,
  historicalsStartDate,
  scenarioId,
  references,
) => {
  // Get the references as absolute months and years instead of offsets
  const absoluteReferences = getAbsoluteReferences({variables: model.variables, month, year});

  const scope = {};
  for(const [varName, ref] of Object.entries(absoluteReferences)) {
    const dateStart = moment(`${ref.startYear}-${ref.startMonth}`, "YYYY-M");
    const dateEnd = moment(`${ref.endYear}-${ref.endMonth}`, "YYYY-M");
    const interim = dateStart.clone();

    const values = [];
    const account = accounts.find((item) => item.id === ref.id);

    // Loop through the months and sum the values
    while(dateEnd > interim || interim.format("M") === dateEnd.format("M")) {
      // If we need to resolve the formula, do a recursive call with the new formula to calculate
      let shouldResolveFormula = false;
      let activeRefModel = null;
      let resolvedRef = null;
      // If references are passed, check if the varName references a metric in the same worksheet
      // If it does, we will resolve its formula dynamically on the frontend
      if(references && !dateStart.isBefore(moment(historicalsStartDate).startOf("month"))) {
        resolvedRef = references.find((ref) => ref.slug === varName.split("__")[0]);
        if(resolvedRef?.type === RowType.METRIC) {
          activeRefModel = getActiveModelForDate(resolvedRef.models, interim.month() + 1, interim.year(), scenarioId, forecastStartDate).model;
          if(activeRefModel?.formulaValid !== false && activeRefModel?.formula?.length) {
            shouldResolveFormula = true;
          }
        }
      }

      if(shouldResolveFormula) {
        values.push(getEntryFromFormulaForMonth(
          accounts,
          activeRefModel,
          interim.get("month") + 1,
          interim.get("year"),
          forecastStartDate,
          historicalsStartDate,
          scenarioId,
          references,
        ));
      } else {
        const entryForMonth = getEntryForMonth({
          account,
          entriesCache: resolvedRef?.entriesCache ?? null,
          forecastStartDate,
          formulaType: ref.formulaType === "mixed" ? "mixed" : "regular",
          scenarioId,
          month: interim.get("month") + 1,
          year: interim.get("year"),
        });
        const entry = !entryForMonth || isNaN(entryForMonth) ? 0 : entryForMonth;
        values.push(entry);
      }

      interim.add(1, "month");
    }

    scope[varName] = values.length === 1 ? values[0] : values;
  }

  // Calculate the final result of the formula with resolved values
  const compiledFormula = mathjs.compile(model.formula.trim());
  const result = compiledFormula.evaluate(scope);

  if(result === Infinity || isNaN(result)) return 0;

  return result;
});

// This is an updated and reworked version of grabEntryValueFromCache, more generic and easy to use
export function getEntryForMonth({
  account,
  entriesCache = null, // entriesCache override if we want to use a local one
  forecastStartDate,
  formulaType,
  scenarioId = null,
  month, // Month as a number from 1 to 12
  year, // Year
  type = null,
}) {
  const resolvedEntriesCache = entriesCache || getEntriesCacheForAccount(account);
  if(!validateArguments({account, entriesCache: resolvedEntriesCache, forecastStartDate, month, year})) return null;

  const requestedMonth = `${year}-${month.toString().padStart(2, "0")}`;
  const isSnapshot = !!account?.reference_id;

  if(isSnapshot && forecastStartDate > requestedMonth) return null;
  let scenarioKey = "null";

  if(scenarioId && forecastStartDate <= requestedMonth) scenarioKey = scenarioId.toString();

  const types = formulaType === "mixed" ? ["FORMULA", "TOTAL"] : formulaType === "parent" ? ["FORMULA"] : ["TOTAL", "FORMULA"];
  let value = null;

  // If no entry type has been specified, try the above types in order (fixes the total value always being 0 in charts when values with type formula existed)
  if(!type) {
    let i = 0;
    while(i < types.length && value === null) {
      const key = `${scenarioKey}.${requestedMonth}.${types[i]}.value`;
      value = get(entriesCache || resolvedEntriesCache, key, null);
      i++;
    }
  } else {
    const key = `${scenarioKey}.${requestedMonth}.${type}.value`;
    value = get(entriesCache || resolvedEntriesCache, key, null);
    if(value === null) value = 0;
  }

  return value;
}

function validateArguments({account, entriesCache, forecastStartDate, month, year}) {
  if(!forecastStartDate) {
    console.error(`Called "getEntryForMonth" without providing a forecastStartDate.`);
    return false;
  } else if(!account && !entriesCache) {
    console.error(`Called "getEntryForMonth" without providing an account or an entriesCache.`);
    return false;
  } else if(!month) {
    console.error(`Called "getEntryForMonth" without providing a month.`);
    return false;
  } else if(!year) {
    console.error(`Called "getEntryForMonth" without providing a year.`);
    return false;
  }

  return true;
}

export const updateValueInEntriesCache = (entriesCache, scenarioId, date, forecastStartDate, newValue) => {
  const updatedEntriesCache = {...entriesCache};
  const formattedForecastStartDate = moment.isMoment(forecastStartDate) ? forecastStartDate.format("YYYY-MM") : forecastStartDate;
  const scenarioIdFormatted = (scenarioId && (formattedForecastStartDate <= date)) ? scenarioId.toString() : "null";
  const keyFormula = `${scenarioIdFormatted}.${date}.FORMULA.value`;
  const valueFormula = get(entriesCache, keyFormula, null);
  if(typeof valueFormula === "number") {
    updatedEntriesCache[scenarioIdFormatted] = {
      ...updatedEntriesCache[scenarioIdFormatted],
      [date]: {
        ...updatedEntriesCache[scenarioIdFormatted][date],
        FORMULA: {
          ...updatedEntriesCache[scenarioIdFormatted][date].FORMULA,
          value: newValue,
        },
      },
    };
  }

  return updatedEntriesCache;
};

// Edits the cache in place but avoids editing values in references (makes a copy on each level)
function fillForPeriod({entriesCache, start, end, scenarioKey, value, grow, interval}) {
  entriesCache[scenarioKey] = entriesCache[scenarioKey] ? {...entriesCache[scenarioKey]} : {};
  const cursor = start.clone();

  let i = 0;
  while(cursor.isBefore(end)) {
    if(i++ > 500) break; // Defensive check to stop infinite loops while developing
    const cacheDateKey = cursor.format("YYYY-MM");

    if(entriesCache[scenarioKey][cacheDateKey]) {
      entriesCache[scenarioKey][cacheDateKey] = {...entriesCache[scenarioKey][cacheDateKey]};
      if(entriesCache[scenarioKey][cacheDateKey].FORMULA) {
        entriesCache[scenarioKey][cacheDateKey].FORMULA = {
          ...entriesCache[scenarioKey][cacheDateKey].FORMULA,
          value,
        };
      }
    } else {
      entriesCache[scenarioKey][cacheDateKey] = {
        FORMULA: {
          value,
        },
      };
    }

    value += grow;
    if(!numeral(value).value()) value = 0;

    cursor.add(interval, "month");
  }

  return value;
}

export const fillManualEntriesCache = ({
  entriesCache,
  fill,
  forecastEndDate,
  forecastStartDate,
  grow,
  historicalsStartDate,
  period = ModelPeriod.FORECASTS,
  scenarioId,
  scenarios,
  interval,
}) => {
  // Initial value, will be updated if a grow factor has been provided
  let value = fill;

  const entriesCacheCopy = {...entriesCache};

  // Fill for different periods based on period provided
  if(period === ModelPeriod.ACTUALS || period === ModelPeriod.ALL) {
    const start = moment(historicalsStartDate, "YYYY-MM-DD");
    const end = moment(forecastStartDate, "YYYY-MM");

    value = fillForPeriod({entriesCache: entriesCacheCopy, start, end, scenarioKey: "null", value, grow, interval});
  }

  if(period === ModelPeriod.FORECASTS || period === ModelPeriod.ALL) {
    const start = moment(forecastStartDate, "YYYY-MM-DD");
    const end = moment(forecastEndDate, "YYYY-MM-DD");

    const allScenarioIds = scenarios.map((scenario) => scenario.id);
    for(const currentScenarioId of allScenarioIds) {
      const scenarioKey = currentScenarioId;
      if(scenarioId !== null && currentScenarioId !== scenarioId) continue;
      fillForPeriod({entriesCache: entriesCacheCopy, start, end, scenarioKey, value, grow, interval});
    }
  }

  return entriesCacheCopy;
};

export const convertForecastStartDate = (forecastStartDate) => {
  const convertedDate = moment(forecastStartDate, "YYYY-MM-DD");
  const year = convertedDate.format("YYYY");
  const month = convertedDate.month();
  return {year, month};
};

export function createEntriesCache(entries, entriesById, buildFromEntriesById = false) {
  const entriesCache = {};
  const builtEntriesById = entriesById || {};
  for(const {1: entry} of Object.entries(buildFromEntriesById ? entriesById : entries)) {
    if(!entriesById) builtEntriesById[entry.id] = {...entry};
    const [year, month] = entry.date.split("-");
    const entryDateFormatted = `${year}-${month}`;
    const scenarioKey = entry.scenario_id || "null";
    if(!entriesCache[entry.account_id]) entriesCache[entry.account_id] = {};
    if(!entriesCache[entry.account_id][scenarioKey]) entriesCache[entry.account_id][scenarioKey] = {};
    if(!entriesCache[entry.account_id][scenarioKey][entryDateFormatted]) entriesCache[entry.account_id][scenarioKey][entryDateFormatted] = {};
    entriesCache[entry.account_id][scenarioKey][entryDateFormatted][entry.type] = {...entry};
    entriesCache[entry.account_id][scenarioKey][entryDateFormatted][entry.type].value = parseFloat(entriesCache[entry.account_id][scenarioKey][entryDateFormatted][entry.type].value);
  }
  return {entriesCache, entriesById: builtEntriesById};
}

export function createEntriesCacheForAccount(entries) {
  const entriesCache = {};

  for(const entry of entries) {
    const [year, month] = entry.date.split("-");
    const entryDateFormatted = `${year}-${month}`;
    const scenarioKey = entry.scenario_id || "null";
    if(!entriesCache[scenarioKey]) entriesCache[scenarioKey] = {};
    if(!entriesCache[scenarioKey][entryDateFormatted]) entriesCache[scenarioKey][entryDateFormatted] = {};
    entriesCache[scenarioKey][entryDateFormatted][entry.type] = {...entry};
    entriesCache[scenarioKey][entryDateFormatted][entry.type].value = parseFloat(entriesCache[scenarioKey][entryDateFormatted][entry.type].value);
  }

  return entriesCache;
}

export function getEntriesCacheForAccount(account) {
  if(!account) return {};
  const id = typeof account === "string" ? account : account.id;

  const localEntriesCache = localEntriesCacheVar();
  if(localEntriesCache[id]) {
    //console.log(`Local entriesCache has been found for account ${id}. Returning it.`);
    return localEntriesCache[id];
  }


  const entriesCache = entriesCacheVar();

  return entriesCache[id] || {};
}

export function applyManualChange({
  applyTo = AppliesToOptions.CURRENT_SCENARIO, // default for forecast sidebar
  entriesCache,
  forecastEndDate,
  forecastStartDate,
  month,
  scenarioId,
  scenarios,
  values,
  year,
}) {
  if(!values || (values.length === 1 && values[0] === null)) return entriesCache;

  const [lastForecastYear, lastForecastMonth] = forecastEndDate.split("-");
  const scenarioIdStr = scenarioId.toString();
  let entryCacheKey = `${year}-${month.toString().padStart(2, "0")}`;

  const updatedEntriesCache = cloneDeep(entriesCache);
  const isActual = forecastStartDate > entryCacheKey;
  let keysToUpdate = null;
  if(isActual) {
    keysToUpdate = ["null"];
  } else {
    keysToUpdate = scenarios.map((scenario) => scenario.id.toString()).filter((scenarioKey) => !applyTo || applyTo === AppliesToOptions.ALL || applyTo === AppliesToOptions.FORECAST || (applyTo === AppliesToOptions.CURRENT_SCENARIO && scenarioKey === scenarioIdStr));
  }

  for(const scenarioKey of keysToUpdate) {
    // If current scenario only is selected, only apply the change to that scenario
    if(applyTo === AppliesToOptions.CURRENT_SCENARIO && scenarioKey !== scenarioIdStr && scenarioKey !== "null") continue;

    updatedEntriesCache[scenarioKey] = {...(updatedEntriesCache[scenarioKey] || {})};
    if(!updatedEntriesCache[scenarioKey]) updatedEntriesCache[scenarioKey] = {};

    let monthIterator = parseInt(month);
    let yearIterator = parseInt(year);
    for(const value of values) {
      entryCacheKey = `${yearIterator}-${monthIterator.toString().padStart(2, "0")}`;
      if(isActual && !(forecastStartDate > entryCacheKey)) { // If we were treating actuals and we are spilling over to forecast values, break
        break;
      }

      // If there's no value for that particular date and scenarioKey, create an empty one. If there's one, clone it
      if(!updatedEntriesCache[scenarioKey][entryCacheKey]) {
        updatedEntriesCache[scenarioKey][entryCacheKey] = {FORMULA: {}};
      } else {
        updatedEntriesCache[scenarioKey][entryCacheKey] = {...updatedEntriesCache[scenarioKey][entryCacheKey]};
      }

      // Set the new value
      updatedEntriesCache[scenarioKey][entryCacheKey].FORMULA = {
        ...updatedEntriesCache[scenarioKey][entryCacheKey].FORMULA,
        value,
      };

      if(parseInt(lastForecastYear) === yearIterator && parseInt(lastForecastMonth) === monthIterator) break; // If we spill over beyond the forecastEndDate, stop

      monthIterator = monthIterator === 12 ? 1 : monthIterator + 1;
      if(monthIterator === 1) yearIterator += 1;
    }
  }

  return updatedEntriesCache;
}
