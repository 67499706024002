import React from "react";
import { Classes as BP } from "@blueprintjs/core";

export const indentDecrease = (color, size) => (
  <svg
    fill={color || "black"}
    height={size}
    viewBox="0 0 24 24"
    width={size}
  >
    <path d="M11 17h10v-2H11v2zm-8-5l4 4V8l-4 4zm0 9h18v-2H3v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z"/><path d="M0 0h24v24H0z" fill="none"/>
  </svg>
);

export const indentIncrease = (color, size) => (
  <svg
    fill={color || "black"}
    height={size}
    viewBox="0 0 24 24"
    width={size}
  >
    <path d="M3 21h18v-2H3v2zM3 8v8l4-4-4-4zm8 9h10v-2H11v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z"/><path d="M0 0h24v24H0z" fill="none"/>
  </svg>
);

export const decimalsDecrease = (color, size) => (
  <svg
    fill={color || "black"}
    height={size}
    version="1.1"
    viewBox="0 0 24 24"
    width={size}
  >
    <path d="M12,17L15,20V18H21V16H15V14L12,17M9,5A3,3 0 0,1 12,8V11A3,3 0 0,1 9,14A3,3 0 0,1 6,11V8A3,3 0 0,1 9,5M9,7A1,1 0 0,0 8,8V11A1,1 0 0,0 9,12A1,1 0 0,0 10,11V8A1,1 0 0,0 9,7M4,12A1,1 0 0,1 5,13A1,1 0 0,1 4,14A1,1 0 0,1 3,13A1,1 0 0,1 4,12Z" />
  </svg>
);

export const decimalsIncrease = (color, size) => (
  <svg
    fill={color || "black"}
    height={size}
    version="1.1"
    viewBox="0 0 24 24"
    width={size}
  >
    <path d="M22,17L19,20V18H13V16H19V14L22,17M9,5A3,3 0 0,1 12,8V11A3,3 0 0,1 9,14A3,3 0 0,1 6,11V8A3,3 0 0,1 9,5M9,7A1,1 0 0,0 8,8V11A1,1 0 0,0 9,12A1,1 0 0,0 10,11V8A1,1 0 0,0 9,7M16,5A3,3 0 0,1 19,8V11A3,3 0 0,1 16,14A3,3 0 0,1 13,11V8A3,3 0 0,1 16,5M16,7A1,1 0 0,0 15,8V11A1,1 0 0,0 16,12A1,1 0 0,0 17,11V8A1,1 0 0,0 16,7M4,12A1,1 0 0,1 5,13A1,1 0 0,1 4,14A1,1 0 0,1 3,13A1,1 0 0,1 4,12Z" />
  </svg>
);

export const poundSterling = () => (
  <span className={[BP.ICON, BP.ICON_DOLLAR].join(" ")} style={{fontSize: "20px", fontWeight: "bold"}}>£</span>
);
