import React from "react";
import PropTypes from "prop-types";

import {InputGroup, ControlGroup, FormGroup} from "@blueprintjs/core";

import styles from "views/dashboard/chart-builder/ChartCreationModal.module.scss";

export default function YAxisType({onChange, value}) {
  const {yAxisMin, yAxisMax, yAxisInterval} = value;

  const generateOnChangeEvent = (type) => (e) => {
    onChange({
      target: {
        name: "options",
        value: {
          ...value,
          [type]: e.target.value,
        },
      },
    });
  };

  return (
    <ControlGroup className={styles.yAxisRange}>
      <FormGroup
        label="Min"
      >
        <InputGroup
          autoComplete="off"
          id="yAxisMin"
          name="yAxisMin"
          onChange={generateOnChangeEvent("yAxisMin")}
          placeholder="Minimum value"
          size={15}
          value={yAxisMin || ""}
        />
      </FormGroup>
      <FormGroup
        label="Max"
      >
        <InputGroup
          autoComplete="off"
          id="yAxisMax"
          name="yAxisMax"
          onChange={generateOnChangeEvent("yAxisMax")}
          placeholder="Maximum value"
          size={15}
          value={yAxisMax || ""}
        />
      </FormGroup>
      <FormGroup
        label="Interval"
      >
        <InputGroup
          autoComplete="off"
          id="yAxisInterval"
          name="yAxisInterval"
          onChange={generateOnChangeEvent("yAxisInterval")}
          placeholder="Interval"
          size={15}
          value={yAxisInterval || ""}
        />
      </FormGroup>
    </ControlGroup>
  );
}

YAxisType.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};
