import {gql} from "@apollo/client";
import {ACCOUNT_FRAGMENT} from "../../../shared/graphql/fragments";

export const ACCOUNT_QUERY = gql`
  query RowAccountQuery($id: String!) {
    accountFromCache(id: $id) {
      ...AccountProps
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const WORKSHEET_QUERY = gql`
  query WorksheetQuery($slug: String!) {
    worksheet(slug: $slug) {
      id
      name
      slug
      rows {
        actualsAccountId
        id
        type
        name
        useParentValues
        forecastAppliesTo
        formatting
        slug
        account_id
        isPercentage
        models
        balance
      }
    }
  }
`;

export const CREATE_WORKSHEET = gql`
  mutation createWorksheet($worksheet: JSON!) {
    createWorksheet(worksheet: $worksheet) {
      id
      name
      slug
      rows {
        actualsAccountId
        id
        type
        name
        useParentValues
        forecastAppliesTo
        formatting
        slug
        account_id
        isPercentage
        models
        balance
      }
    }
  }
`;


export const UPDATE_WORKSHEET = gql`
  mutation updateWorksheet($worksheet: JSON!) {
    updateWorksheet(worksheet: $worksheet) {
      id
      name
      slug
      rows {
        actualsAccountId
        id
        type
        name
        useParentValues
        forecastAppliesTo
        formatting
        slug
        account_id
        isPercentage
        models
        balance
      }
    }
  }
`;

export const DELETE_WORKSHEET = gql`
  mutation removeWorksheet($id: String!) {
    removeWorksheet(id: $id)
  }
`;
