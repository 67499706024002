import React from "react";
import PropTypes from "prop-types";

import styles from "./styles/Login.module.scss";

const digits = [0, 1, 2, 3, 4, 5];

class MfaCodeInput extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    onCodeChange: PropTypes.func.isRequired,
    onEnterKeyPress: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      digits: digits.map(() => null),
    };

    for(const digit of digits) {
      this[`digit${digit}input`] = React.createRef();
    }
  }

  onKeyPress = (evt) => {
    if(evt.key === "Enter" && this.state.digits.every((digit) => digit !== null && digit.length === 1)) {
      this.props.onEnterKeyPress();
    }
  }

  onChangeDigit = (evt) => {
    const value = evt.target.value.length === 2 ? evt.target.value[1] : evt.target.value;
    const digits = Array.from(this.state.digits);
    const index = parseInt(evt.target.name.split("digit")[1], 10);
    digits[index] = value;
    if(value.length === 1 && index !== 5) this[`digit${index + 1}input`].current.focus();
    this.setState({error: "", digits});

    this.props.onCodeChange(digits.every((digit) => digit !== null && digit.length === 1) ? digits.join("") : null);
  }

  reset = () => {
    this.setState({digits: digits.map(() => null)});
    setTimeout(() => this.digit0input.current.focus(), 10);
  }

  render() {
    const {loading} = this.props;
    const {digits} = this.state;

    return (
      <div className={styles.codeInputWrapper}>
        <form className={styles.form}>
          {digits.map((digit, idx) => (
            <input
              autoComplete="off"
              className={styles.code}
              disabled={loading}
              id={`digit${idx}`}
              key={`digit${idx}`}
              name={`digit${idx}`}
              onChange={this.onChangeDigit}
              onKeyPress={this.onKeyPress}
              ref={this[`digit${idx}input`]}
              type="number"
              value={digits[idx] || ""}
            />
          ))}
        </form>
      </div>
    );
  }
}

export default MfaCodeInput;
