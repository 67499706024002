import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";
import analytics from "segment";

import {Intent, Callout} from "@blueprintjs/core";
import Loading from "components/Loading";
import AccountsTable from "components/AccountsTable";

import {resolveAccounts} from "shared/utilities/account-utilities";

import Header from "./Header";
import {AppContext} from "../../../AppContext";

import {withHooks} from "shared/hooks/hoc";
import {ALL_PLAN_GROUPS_QUERY} from "./graphql";
import {ALL_PRODUCTS_QUERY} from "../chart-of-plans/graphql";
import {ALL_SCENARIOS_QUERY} from "../../../graphql";

import styles from "./styles.module.scss";

import {getTemplateForView} from "../utilities";

import {getTemplate} from "../templates";

class RevenueModel extends React.Component {
  static propTypes = {
    allFlightpathAccounts: PropTypes.array.isRequired,
    allPlanGroupsQuery: PropTypes.object.isRequired,
    allProductsQuery: PropTypes.object.isRequired,
    allScenariosQuery: PropTypes.object.isRequired,
  }

  static getDerivedStateFromProps(props, state) {
    if(!state.scenarioId && !!props.allScenariosQuery.scenarios) {
      return {scenarioId: (props.allScenariosQuery.scenarios[0]) ? props.allScenariosQuery.scenarios[0].id : null};
    } else if(!state.scenarioId && !!props.allPlanGroupsQuery.scenarios) {
      return {planGroupId: (props.allPlanGroupsQuery.plan_groups[0]) ? props.allPlanGroupsQuery.plan_groups[0].id : null};;
    } else {
      return null;
    }
  }

  state = {
    planGroupId: null,
    scenarioId: null,
    sidebarOpen: false,
    verticalScrollbar: false,
    year: (new Date().getFullYear()).toString(),
  }

  componentDidMount() {
    analytics.page("Revenue Model");
  }

  static contextType = AppContext

  handleYearChange = (year) => this.setState({year});

  handleScenarioChange = (scenarioId) => this.setState({scenarioId});

  handleViewChange = ({type, value}) => {
    switch(type) {
      case "productSummary":
      case "planGroup": {
        this.setState({viewType: type, viewValue: value});
        break;
      }
      case "summary": {
        this.setState({viewType: type});
        break;
      }
      default:
    }
  }

  setSidebarOpen = (open) => this.setState({sidebarOpen: open});

  handleVerticalScrollbarChange = (shown) => this.setState({verticalScrollbar: shown});

  render() {
    const {
      allFlightpathAccounts,
      allPlanGroupsQuery,
      allProductsQuery,
      allScenariosQuery,
      routerParams
    } = this.props;

    const {
      scenarioId,
      sidebarOpen,
      verticalScrollbar,
      year,
    } = this.state;

    const {user} = this.context;

    if(!allFlightpathAccounts || !allScenariosQuery.scenarios || allPlanGroupsQuery.loading || !allPlanGroupsQuery.plan_groups || !allProductsQuery.products || allProductsQuery.loading) {
      return <Loading />;
    }

    const {viewType, viewValue} = getGetViewTypeValue(routerParams.view);

    const template = getTemplate(user.tenant.revenue);

    const {template: actualTemplate, extraAccounts} = getTemplateForView({
      viewType,
      viewValue,
      template,
      products: allProductsQuery.products,
      planGroups: allPlanGroupsQuery.plan_groups,
    });

    let accounts = [];
    if(viewType === "planGroup") {
      accounts = allFlightpathAccounts.filter((account) => account.plan_group_id === viewValue);
    } else if(viewType === "productSummary") {
      accounts = allFlightpathAccounts.filter((account) => account.product_id === viewValue && !account.plan_group_id);
    } else {
      accounts = allFlightpathAccounts.filter((account) => account.statement_type === "REV" && !account.plan_group_id && !account.product_id);
    }
    accounts.push(...extraAccounts);

    const {scenarios} = allScenariosQuery;

    const resolvedTemplate = resolveAccounts(accounts, actualTemplate, scenarios);

    const selectedScenario = scenarios.find((s) => s.id === scenarioId);

    return (
      <div className={[styles.revenueModel, sidebarOpen ? styles.sidebarOpen : styles.sidebarClosed].join(" ")}>
        {!scenarios.length ? (
          <Callout className={styles.noScenario} intent={Intent.PRIMARY}>
            Please connect your accounting and create a forecast scenario in company settings before you start forecasting.
          </Callout>
        ) : (
          <>
            <Header
              additionalPaddingOnWindows={verticalScrollbar}
              onScenarioChange={this.handleScenarioChange}
              onViewChange={this.handleViewChange}
              onYearChange={this.handleYearChange}
              scenarioId={scenarioId}
              view={routerParams.view}
              year={year}
            />
            <AccountsTable
              onVerticalScrollbarChange={this.handleVerticalScrollbarChange}
              resolvedTemplate={resolvedTemplate}
              selectedScenario={selectedScenario}
              setSidebarOpen={this.setSidebarOpen}
              year={year}
              extended={false}
            />
          </>
        )}
      </div>
    );
  }
}

const RevenueModelWithData = compose(
  graphql(ALL_PRODUCTS_QUERY, {name: "allProductsQuery", options: {variables: {includePlans: true}}}),
  graphql(ALL_SCENARIOS_QUERY, {name: "allScenariosQuery"}),
  graphql(ALL_PLAN_GROUPS_QUERY, {name: "allPlanGroupsQuery"}),
)(RevenueModel);

export default withHooks(RevenueModelWithData, ["useAllFlightpathAccounts"]);

function getGetViewTypeValue(viewName) {
  let viewType = null;
  let viewValue = null;

  if(viewName === "summary") {
    viewType = "summary";
  } else {
    if(viewName.includes("summary")) {
      const productId = viewName.split("-")[1].split("-")[0];
      viewType = "productSummary";
      viewValue = productId;
    } else {
      const planGroupId = viewName.split("-")[0];
      viewType = "planGroup";
      viewValue = planGroupId;
    }
  }

  return {viewType, viewValue};
}
