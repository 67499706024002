import React from "react";
import PropTypes from "prop-types";

import {AddATableButton} from "../table-builder";
import AddAChartButton from "../chart-builder";

import styles from "./Grid.module.scss";

class BottomButtons extends React.Component {
  static propTypes = {
    onCreateChart: PropTypes.func.isRequired,
    onCreateTable: PropTypes.func.isRequired,
  }

  render() {
    const {onCreateChart, onCreateTable} = this.props;
    return (
      <div className={styles.gridAddItemButtons}>
        <AddAChartButton onCreateChart={onCreateChart} />
        <AddATableButton onCreateTable={onCreateTable} />
      </div>
    );
  }
}

export default BottomButtons;
