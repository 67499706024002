import React, {useContext, useState} from "react";

import memoize from "memoize-one";

import {useQuery} from "@apollo/client";
import {useAllFlightpathAccounts} from "shared/hooks/account-hooks";

import {Callout, Intent} from "@blueprintjs/core";
import Card from "components/Card";
import Table from "components/Table";
import Loading from "components/Loading";
import SettingsHeader from "components/SettingsHeader";

import {AppContext} from "../../../AppContext";
import CreateUpdate from "./CreateUpdate";
import {reformatCOAAccounts} from "./coa-utilities";

import {ALL_ACCOUNTING_ACCOUNTS_QUERY} from "./graphql";

import styles from "./styles.module.scss";

const reformatAllAccounts = memoize((accounts, accountingAccounts) => reformatCOAAccounts(accounts, accountingAccounts));

export default function ChartOfAccounts() {
  const [operation, setOperation] = useState(null);
  const [operationActive, setOperationActive] = useState(false);
  const [activeAccount, setActiveAccount] = useState(null);

  const {data: {accountingAccounts} = {}, loading} = useQuery(ALL_ACCOUNTING_ACCOUNTS_QUERY);
  const accounts = useAllFlightpathAccounts();
  const {companies} = useContext(AppContext);


  const editAccount = (accountToEdit) => () => {
    setActiveAccount((accountToEdit) ? accountToEdit : {});
    setOperation((accountToEdit) ? "update" : "create");
  };

  const onClose = () => {
    setOperation(null);
    setActiveAccount(null);
  };

  const toggleOperationActive = () => setOperationActive(!operationActive);

  if(loading || !accountingAccounts || !accounts) return (<Loading />);

  const formattedAccounts = reformatAllAccounts(accounts, accountingAccounts);

  const companiesById = {};
  for(const company of companies) {
    companiesById[company.id] = company;
  }

  return (formattedAccounts.formattedFFAccounts.length > 0) ? (
    <Card>
      <SettingsHeader
        buttonAction={editAccount(null)}
        buttonDisabled={operationActive}
        buttonText="New"
        subtitle
        text="Chart of Accounts"
      />
      <Table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Statement</th>
            <th>Type</th>
            <th>Accounting Mapping</th>
            {companies.length > 1 ? (
              <th>Company Name</th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {formattedAccounts.formattedFFAccounts.map((account, index) => (
            <tr
              className={(operationActive || account.detail_type === "root") ? "" : styles.editableRow}
              key={account.id + account.statement_type + index}
              onClick={(operationActive || account.detail_type === "root") ? null : editAccount(account)}
            >
              <td className={styles[`indent${account.displayIndentation}`]}>{account.name}</td>
              <td>{account.statement_type}</td>
              <td className={styles.nowrap}>{account.type}</td>
              <td>{(account.accounting_account) ? account.accounting_account.name : ""}</td>
              {companies.length > 1 ? (
                <td>{account.accounting_account ? companiesById[account.accounting_account.company_id].name : ""}</td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </Table>
      <CreateUpdate
        account={activeAccount}
        accountingAccounts={formattedAccounts.formattedAccountingAccounts}
        onClose={onClose}
        operation={operation}
        toggleOperationActive={toggleOperationActive}
      />
    </Card>
  ) : (
    <Callout intent={Intent.PRIMARY}>
      Add accounts to begin forecasting. Please connect with an <a href="/company/integrations">accounting system</a> to pull your actuals.
    </Callout>
  );
}
