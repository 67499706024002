import React, { useState } from "react";
import PropTypes from "prop-types";

import FFTable from "components/Table";

import Row from "./Row";

import styles from "./styles.module.scss";

import { useDragLayer } from "react-dnd";
import { Card } from "@blueprintjs/core";

// Custom Drag Preview
const RowDragLayer = () => {
  const { isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  if (!isDragging) return null;

  const getStyles = () => {
    if (!initialOffset || !currentOffset) return { "display": "none" };
    let { x } = initialOffset;
    let { y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      position: "fixed",
      pointerEvents: "none",
      zIndex: 100,
      left: "2rem",
      top: 0,
      transform,
      WebkitTransform: transform,
      padding: "0.25rem",
    };
  };

  return (
    <Card style={getStyles()}>
      {item.rowName || "Row"}
    </Card>
  );
};

export default function Rows({ autocompleteHighlight, editingRow, worksheet, ...props }) {
  const [highlightedCells, setHighlightedCells] = useState({});

  return (
    <div className={styles.tableBodyContainer}>
      <RowDragLayer />
      <FFTable>
        <tbody>
          {worksheet.rows.map((row, i) => (
            <Row
              active={!!(editingRow?.id === row.id)}
              autocompleteHighlighted={autocompleteHighlight === row.slug}
              highlightedCells={highlightedCells}
              key={`${row.type}::${row.id || row.account_id}`}
              onUpdateHighlightedCells={(cells = {}) => setHighlightedCells(cells)}
              row={row}
              rowIndex={i}
              worksheet={worksheet}
              {...props}
            />
          ))}
        </tbody>
      </FFTable>
    </div>
  );
}

Rows.propTypes = {
  autocompleteHighlight: PropTypes.string,
  editingRow: PropTypes.object,
  onRowChange: PropTypes.func.isRequired,
  scenarioId: PropTypes.string.isRequired,
  worksheet: PropTypes.object.isRequired,
  year: PropTypes.string.isRequired,
};
