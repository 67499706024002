/**
 * Pull out the Google sheets ID from the pull URL.
 *
 * @param url
 * @returns sheet ID
 */
export const createGoogleSheetsUrl = (url, workbookId) => {
  // Extract the ID from the URL
  let newUrl = {...url};
  if(url[url.length - 1] === "/") newUrl = url.slice(0, -1);
  // eslint-disable-next-line
  const regexp = new RegExp("https:\/\/docs.google.com\/spreadsheets\/d\/(.*[^\/])\/?.*", "g");
  const sheetMatch = regexp.exec(newUrl);
  let sheetId = null;
  if(sheetMatch) {
    // sheetId = sheetMatch[1];
    sheetId = newUrl.split("/d/")[1].split("/")[0];
  }

  newUrl = `https://docs.google.com/spreadsheets/d/${sheetId}/edit#gid=${workbookId}`;

  return newUrl;
};
