import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const Card = React.forwardRef(({children, className, style, onScroll = null, noTop = false, noShade = false}, ref) => {
  const classes = [styles.card];
  if(className) classes.push(className);
  if(noTop) classes.push(styles.noTop);
  if(noShade) classes.push(styles.noShade);

  return (
    <div className={classes.join(" ")} ref={ref} style={style} onScroll={onScroll}>
      {children}
    </div>
  );
});

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noShade: PropTypes.bool,
  noTop: PropTypes.bool,
};

export default Card;
