import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

export default function Select({
  label = null,
  name,
  onChange,
  options,
  value,
}) {
  return (
    <div className={styles.main}>
      {label ? (
        <label className={styles.label} htmlFor={name}>{label}</label>
      ) : null}
      <select
        className={styles.select}
        name={name}
        onChange={onChange}
        value={value}
      >
        {options.map((option) => (
          <option disabled={option.disabled} key={option.value} value={option.value}>{option.text}</option>
        ))}
      </select>
    </div>
  );
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
