import React, {useState} from "react";
import PropTypes from "prop-types";

import numeral from "numeral";

import {FormGroup, Slider, Tooltip, Position, Icon} from "@blueprintjs/core";
import Button from "components/Button";
import TextInput from "components/TextInput";

import styles from "./styles.module.scss";

export default function ManualOptions({
  onCopyAcross,
}) {
  const [fill, setFill] = useState(0);
  const [grow, setGrow] = useState(0);
  const [interval, setInterval] = useState(1);

  const handleChange = (eventType) => ({target: {name, value}}) => {
    let sanitizedValue = value;
    const numeralValue = numeral(value).value();

    if(eventType === "blur") {
      sanitizedValue = numeralValue || 0;
    }

    if(!isNaN(numeralValue) || value === "-" || value === ".") {
      if(name === "fill") {
        setFill(sanitizedValue);
      } else if (name === "grow") {
        setGrow(sanitizedValue);
      } else if (name === "interval") {
        setInterval(sanitizedValue);
      }
    }
  };

  return (
    <div className={styles.manualOptions}>
      <TextInput
        highlightOnFocus
        label="Fill"
        name="fill"
        onBlur={handleChange("blur")}
        onChange={handleChange("change")}
        tooltip="Copies over the forecast value across the account."
        value={fill}
      />
      <TextInput
        highlightOnFocus
        label="Grow"
        name="grow"
        onBlur={handleChange("blur")}
        onChange={handleChange("change")}
        tooltip="Grows the forecast by this amount each month."
        value={grow}
      />
    <FormGroup label="Copy Interval (Months)" labelFor="interval">
      <Slider
      min={1}
      max={12}
      stepSize={1}
      labelStepSize={1}
      value={interval}
      onChange={(newValue) => setInterval(newValue)}
      name="interval"
      />
    </FormGroup>
      <Button
        onClick={() => onCopyAcross({fill, grow, interval})}
        size="small"
        text="Copy Across"
      />
    </div>
  );
}

ManualOptions.propTypes = {
  onCopyAcross: PropTypes.func.isRequired,
};
