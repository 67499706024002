import React from "react";
import PropTypes from "prop-types";

import Checkbox from "components/Checkbox";

import styles from "./styles.module.scss";

export default function BalanceOptions({
  onChange,
  row,
}) {
  const handleChangeLocal = () => onChange({target: {name: "balance", value: !row.balance}});

  return (
    <Checkbox
      checked={row.balance === true}
      className={styles.checkbox}
      label="Balance Account"
      name="balance"
      onChange={handleChangeLocal}
    />
  );
}

BalanceOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};
