import {gql} from "@apollo/client";

export const ALL_SCENARIOS_QUERY = gql`
  query AllScenariosQuery {
    scenariosWithChartLinesAndTables {
      id
      name
      description: description_rich
      tables {
        id
        name
      }
      chart_lines {
        id
        chart {
          id
          name
        }
      }
    }
  }
`;

export const updateScenario = gql`
  mutation updateScenario($id: String!, $name: String!, $description: JSON) {
    updateScenario(id: $id, name: $name, description: $description) {
      name
      description
    }
  }
`;

export const removeScenario = gql`
  mutation removeScenario($id: String!) {
    removeScenario(id: $id)
  }
`;

export const createScenario = gql`
  mutation createScenario($name: String!, $description: JSON) {
    createScenario(name: $name, description: $description) {
      name
      description
    }
  }
`;

export const copyScenario = gql`
  mutation copyScenario($id: String!, $name: String!) {
    copyScenario(id: $id, name: $name) {
      name
    }
  }
`;
