import {cloneDeep} from "lodash";
import {weightedRevenueTypes} from "./types";

import {modelsCacheVar, localModelsCacheVar} from "apollo-config/local-state/models";

/*
models {
  scenarioId: {
    modelType("autopilot" | "google" etc): model
  }
}
*/

const blankAutopilotModel = {
  active: false,
  options: {
    autopilotType: "AVERAGE",
    numMonthsAvg: 3,
    growType: "%",
    growAmount: 0,
    percentile: 50,
  },
};

const blankGoogleSheetsModel = {
  active: false,
  workbook: "",
  range_start: "",
  range_start_date: null,
  range_end: "",
};

export function getModelsCacheForAccount(account) {
  if(!account) return {};
  const localModelsCache = localModelsCacheVar();
  const localModelsCacheForAccount = localModelsCache[account.id];
  if(localModelsCacheForAccount) {
    //console.log(`Local modelsCache has been found for account ${account.id}. Returning it.`);
    return localModelsCacheForAccount;
  }

  const modelsCache = modelsCacheVar();
  const modelsCacheForAccount = modelsCache[account.id];

  return modelsCacheForAccount || {};
}

export const createModelsCache = ({account, scenarios, models, googleSheetsModels}) => {
  const isWeightedRevenueType = weightedRevenueTypes.includes(account.type);
  const modelsCache = {};
  // Add existing models to the cache
  if(models) {
    for(let i = 0; i < models.length; i++) {
      const model = models[i];
      const modelType = (model.options?.autopilotType) ? "autopilot" : "other";
      const scenarioIdFormatted = (model.scenario_id) ? model.scenario_id.toString() : "null";
      if(!modelsCache[scenarioIdFormatted]) modelsCache[scenarioIdFormatted] = {};
      modelsCache[scenarioIdFormatted][modelType] = cloneDeep(model);
    }
  }
  if(googleSheetsModels) {
    // Add google sheets models to the cache
    for(let i = 0; i < googleSheetsModels.length; i++) {
      const model = googleSheetsModels[i];
      const scenarioIdFormatted = (model.scenario_id) ? model.scenario_id.toString() : "null";
      if(!modelsCache[scenarioIdFormatted]) modelsCache[scenarioIdFormatted] = {};
      modelsCache[scenarioIdFormatted]["google"] = cloneDeep(model);
    }
  }
  // Add default autopilot models if none exist yet
  for(let j = 0; j < scenarios.length; j++) {
    const scenarioIdFormatted = scenarios[j].id.toString();
    if(!modelsCache[scenarioIdFormatted]) modelsCache[scenarioIdFormatted] = {};
    if(!modelsCache[scenarioIdFormatted]["autopilot"]) {
      modelsCache[scenarioIdFormatted]["autopilot"] = cloneDeep(blankAutopilotModel);
      if(isWeightedRevenueType) modelsCache[scenarioIdFormatted]["autopilot"].options.autopilotType = "WEIGHTED_AVERAGE";
    }
    if(!modelsCache[scenarioIdFormatted]["google"]) {
      modelsCache[scenarioIdFormatted]["google"] = cloneDeep(blankGoogleSheetsModel);
    }
  }
  return modelsCache;
};

export function createGlobalModelsCache(accounts, models, scenarios, googleSheetsModels = null, modelsById, googleSheetsModelsById, buildFromIdMap = false) {
  //console.log("Creating global models cache");
  const modelsCache = {};
  const builtModelsById = modelsById || {};
  const builtGoogleSheetsModelsById = googleSheetsModelsById || {};

  // Models
  const modelsByAccountId = {};
  for(const {1: model} of Object.entries(buildFromIdMap ? modelsById : models)) {
    builtModelsById[model.id] = model;
    if(!modelsByAccountId[model.account_id]) modelsByAccountId[model.account_id] = [];
    modelsByAccountId[model.account_id].push(model);
  }

  // Google Sheets Models
  const googleSheetsModelsByAccountId = {};
  for(const {1: model} of Object.entries(buildFromIdMap ? googleSheetsModelsById : googleSheetsModels)) {
    builtGoogleSheetsModelsById[model.id] = model;
    if(!googleSheetsModelsByAccountId[model.account_id]) googleSheetsModelsByAccountId[model.account_id] = [];
    googleSheetsModelsByAccountId[model.account_id].push(model);
  }

  for(const account of accounts) {
    if(account && (modelsByAccountId[account.id]?.length || googleSheetsModelsByAccountId[account.id]?.length)) modelsCache[account.id] = createModelsCache({account, scenarios, models: modelsByAccountId[account.id], googleSheetsModels: googleSheetsModelsByAccountId[account.id]});
  }

  return {modelsCache, modelsById: builtModelsById, googleSheetsModelsById: builtGoogleSheetsModelsById, googleSheetsModelsByAccountId};
}
