import React from "react";
import { Card, AnchorButton, Classes as BP } from "@blueprintjs/core";
import styles from "../auth/styles/Login.module.scss";

class UserNotFound extends React.Component {
  render() {
    return (
      <div className={styles.login}>
        <Card className={styles.card}>
          <div className={styles.wrapper}>
            <div className={styles.signin}>
              <h3 className={BP.HEADING}>User Not Found</h3>
              <AnchorButton
                className="m-t-md"
                fill
                href="/"
                icon="log-in"
                intent="primary"
                large
              >
                Return to Login
              </AnchorButton>
              <p className={`${BP.TEXT_MUTED} ${BP.RUNNING_TEXT} m-t-md m-b-0`}>
                We were unable to authorize or find the user requested. Please try again or contact support if you require further assistance at <a href="mailto:support@flightpathfinance.com">support@flightpathfinance.com</a>.
              </p>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default UserNotFound;
