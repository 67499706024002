import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

function Table({children, className, hover}) {
  const classes = [styles.table];
  if(className) classes.push(className);
  if(hover) classes.push(styles.hover);

  return (
    <table className={classes.join(" ")}>
      {children}
    </table>
  );
}

Table.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hover: PropTypes.bool,
};

export default Table;
