import React from "react";
import PropTypes from "prop-types";

import {FormGroup, HTMLSelect} from "@blueprintjs/core";

import FormFieldStacked from "./stacked";

import styles from "../ChartCreationModal.module.scss";

const chartTypes = [
  {value: "LINE", displayName: "Line"},
  {value: "BAR", displayName: "Bar"},
  {value: "AREA", displayName: "Area"},
  {value: "PIE", displayName: "Donut"},
  {value: "COMBO", displayName: "Combo"},
  {value: "AREARANGE", displayName: "Area range"},
];

function FormFieldChartType(props) {
  return (
    <FormGroup
      className={props.value === "BAR" ? styles.bar : null}
      label="Chart Type"
      labelFor="type"
      labelInfo="*"
    >
      <HTMLSelect
        fill
        name="type"
        onChange={props.onChange}
        options={chartTypes.map((type) => ({label: type.displayName, value: type.value}))}
        value={props.value}
      />
      {props.value === "BAR" ? <FormFieldStacked onChange={props.onChange} value={props.options} /> : null}
    </FormGroup>
  );
}

FormFieldChartType.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.object,
  value: PropTypes.string,
};

export default FormFieldChartType;
export {chartTypes};
