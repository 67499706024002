import {gql} from "@apollo/client";

export const WORKSHEETS_QUERY = gql`
  query WorksheetsQuery {
    worksheets {
      id
      name
      slug
      rows {
        actualsAccountId
        id
        type
        name
        useParentValues
        forecastAppliesTo
        formatting
        slug
        account_id
        isPercentage
        models
        balance
      }
    }
  }
`;
