import React from "react";
import PropTypes from "prop-types";

import CSVReader from "components/CSVReader";

export default function RevenueCSV({addCSVToState}) {
  const csvOptions = {
    dynamicTyping: true,
    header: true,
    skipEmptyLines: "greedy",
  };

  return (
    <CSVReader
      configOptions={csvOptions}
      onFileLoaded={addCSVToState}
    />
  );
}

RevenueCSV.propTypes = {
  addCSVToState: PropTypes.func.isRequired,
  plan: PropTypes.object.isRequired,
};
