import axios from "axios";

function logout(cb) {
  return fetch(`/api/logout`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    credentials: "include",
  }).then(checkStatus)
  .then(cb);
}

function signInWithGoogle(cb) {
  const serverUrl = `/api/auth/google`;
  // var xhr = new XMLHttpRequest();
  // xhr.open("GET", serverUrl, true);
  // xhr.withCredentials = true;
  // xhr.send();
  return axios({
    url: serverUrl,
    method: "get",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    origin: "*",
    withCredentials: true,
  }).then(checkStatus)
  .then(cb);
}


function checkStatus(response) {
  if(response.status >= 200 && response.status < 300) {
    return response;
  } else {
    console.log(response);
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    console.log(error); // eslint-disable-line no-console
    throw error;
  }
}

const Client = {logout, signInWithGoogle};
export default Client;
