import moment from "moment";

export const months = [];
for(let i = 1; i <= 12; i++) {
  months.push(i.toString().padStart(2, "0"));
}

// startDate and endDate are both moments
export function createYearsArray(startDate, endDate) {
  const yearsArray = [];
  let cursorDate = (typeof startDate === "string") ? moment(startDate, "YYYY-MM-DD") : startDate.clone();
  const momentEndDate = (typeof endDate === "string") ? moment(endDate, "YYYY-MM-DD") : endDate.clone();
  const diff = momentEndDate.diff(cursorDate, "year");
  for(let i = 0; i <= diff; i++) {
    const year = cursorDate.format("YYYY");
    yearsArray.push(year);
    cursorDate = cursorDate.add(1, "year");
  }
  return yearsArray;
};

const quarterToMonthMap = {
  start: {
    1: 0,
    2: 3,
    3: 6,
    4: 9,
  },
  end: {
    1: 2,
    2: 5,
    3: 8,
    4: 11,
  },
};

const monthToQuarterMap = {
  0: 1,
  1: 1,
  2: 1,
  3: 2,
  4: 2,
  5: 2,
  6: 3,
  7: 3,
  8: 3,
  9: 4,
  10: 4,
  11: 4,
};

/*
  Accepts a given "Report Period" and produces the given date range start/end dates given the current time.
  The datepicker accepts a daterange in the form of [startDate, endDate].
*/

export function createInitialDateRange(
  forecastStartDate,
  reportPeriod,
  monthsAgo,
  monthsAhead,
) {
  const now = (forecastStartDate) ? moment(forecastStartDate, "YYYY-MM").subtract(1, "month") : moment();
  const nowObject = {month: now.month(), quarter: now.quarter(), year: now.year()};
  const startOfYear = {month: 0, quarter: 1, year: now.year()};
  const endOfYear = {month: 11, quarter: 4, year: now.year()};
  switch(reportPeriod) {
    case "LAST_MONTH":
      return {start: nowObject, end: nowObject};
    case "THIS_YEAR_TO_DATE":
      return {start: startOfYear, end: nowObject};
    case "MONTHS_FORECAST":
      return createMonthsForecastDateRange(now, monthsAgo, monthsAhead);
    case "CUSTOM":
    case "THIS_YEAR":
    default:
      return {start: startOfYear, end: endOfYear};
  }
}

function createMonthsForecastDateRange(now, monthsAgo, monthsAhead) {
  const monthWeAreLivingIn = now.clone().add(1, "month").endOf("month"); // We shouldn't add a month since we want to include the current month
  const startDate = monthWeAreLivingIn.clone().subtract(monthsAgo, "months");
  const endDate = monthWeAreLivingIn.clone().add(monthsAhead - 1, "months");
  const startObject = {month: startDate.month(), quarter: startDate.quarter(), year: startDate.year()};
  const endObject = {month: endDate.month(), quarter: endDate.quarter(), year: endDate.year()};
  return {start: startObject, end: endObject};
}

export function createDateObject(
  column_type,
  originalDate, // original date object
  type, // start or end
  unit, // year
  value, // 2018
) {
  if(column_type === "YEARS") {
    if(type === "start") return {month: 0, quarter: 1, year: value};
    else return {month: 11, quarter: 4, year: value};
  }
  if(column_type === "QUARTERS") {
    return {
      ...originalDate,
      month: (unit === "quarter") ? quarterToMonthMap[type][value] : originalDate.month,
      [unit]: value,
    };
  }
  if(column_type === "MONTHS") {
    return {
      ...originalDate,
      quarter: (unit === "month") ? monthToQuarterMap[value] : originalDate.quarter,
      [unit]: value,
    };
  }
}
