import numeral from "numeral";
import moment from "moment";
import {filter} from "lodash";
import * as mathjs from "mathjs";
import {cloneDeep} from "lodash";
import {grabEntryValueFromCache, getEntriesCacheForAccount} from "./entry-utilities";
import {formatValue} from "./forecast-utilities";
import {getAccountFromCache, findAccountById, findAccountByType, findRevenueAccount} from "./account-utilities";
import {mrrMap, metricMap, percMetricMap, percTotalMrrMap, RevenueTypeToSettingMap, weightedRevenueTypes} from "shared/utilities/types";

export const setDefaultTargetId = (type, accounts, revenueAccounts) => {
  if(type === "METRIC_FORECAST") {
    const metricAccounts = filter(accounts, (account) => account.statement_type === "MET");
    return metricAccounts?.[0]?.id;
  } else return revenueAccounts?.[0]?.id;
};

const formatMonthsAvgVal = (value, type, currency) => {
  if(type === "dollar") return formatValue({value, currencySymbol: currency, decimals: 2});
  else if(type === "perc") return numeral(value).format("%0,[.]00000");
  else return numeral(value).format("0,0[.]00");
};

const calcMonthsSum = (account, entriesCache, scenario, forecastStartDate, monthsAvg, referenceOffset) => {
  let sum = 0;
  const refOffset = (referenceOffset) ? referenceOffset : 0;
  const entryType = (account.statement_type === "REV" && account.children?.length > 0) ? "TOTAL" : "FORMULA";
  if(account) {
    for(let i = 1; i <= monthsAvg; i++) {
      const date = moment(forecastStartDate, "YYYY-MM").subtract(i + refOffset, "month").format("YYYY-MM");
      sum += grabEntryValueFromCache(entriesCache, scenario, date, entryType, forecastStartDate, false);
    }
  }
  return sum;
};

const calcMonthsAvgVal = (account, entriesCache, scenario, forecastStartDate, monthsAvg) => {
  const sum = calcMonthsSum(account, entriesCache, scenario, forecastStartDate, monthsAvg);
  return (monthsAvg > 0) ? (sum / monthsAvg) : 0;
};

const calcAveragePercRevenue = (accounts, account, entriesCache, forecastStartDate, options, scenarios, revAccount) => {
  let revenueSum = 0;
  let accountSum = 0;
  const revenueAccount = (revAccount) ? revAccount : getAccountFromCache(1);
  const revenueAccountEntriesCache = getEntriesCacheForAccount(revenueAccount);
  for(let i = 1; i <= options.numMonthsAvg; i++) {
    const date = moment(forecastStartDate, "YYYY-MM").subtract(i, "month").format("YYYY-MM");
    accountSum += grabEntryValueFromCache(entriesCache, null, date, "FORMULA", forecastStartDate, false);
    revenueSum += grabEntryValueFromCache(revenueAccountEntriesCache, null, date, "TOTAL", forecastStartDate, false);
  }
  return parseFloat((accountSum / revenueSum).toFixed(7));
};

const createAutopilotEntry = (model, entry, dateKey, forecastStartDate, forecastStartDateCursor, monthsAvgVal) => {
  const diff = (forecastStartDateCursor.diff(moment(dateKey, "YYYY-MM"), "months") * -1) + 1;
  if(model.options.growType === "%") entry.value = monthsAvgVal * Math.pow((1 + (model.options.growAmount / 100)), diff);
  else entry.value = monthsAvgVal + (diff * model.options.growAmount);
  entry.value = parseFloat(entry.value);
  entry.type = "FORMULA";
  return entry;
};

const createPercOfEntry = (revenueEntry, entry, monthsAvg) => {
  entry.value = parseFloat((revenueEntry.value * monthsAvg));
  entry.type = "FORMULA";
  return entry;
};

const findRevenueSetting = (settings, account) => {
  //console.log(settings);
  let setting = "TOTAL_CUSTOMERS";
  //console.log(settings, account);
  if(settings) {
    for(const key of Object.keys(RevenueTypeToSettingMap)) {
      if(RevenueTypeToSettingMap[key].includes(account.type)) setting = settings[key];
    }
  }

  return setting;
};

const createRevenueExceptionAccountAvg = (revenueSettings, revenueAccounts, account, scenarios, scenario, forecastStartDate, model) => {
  let target1 = null;
  let target2 = null;
  let target1EntriesCache = null;
  let target2EntriesCache = null;
  const setting = findRevenueSetting(revenueSettings, account);
  if(metricMap[account.type]) {
    target1 = findRevenueAccount(revenueAccounts, mrrMap[account.type], account.product_id, account.plan_group_id);
    target2 = findRevenueAccount(revenueAccounts, metricMap[account.type], account.product_id, account.plan_group_id);
    target1EntriesCache = getEntriesCacheForAccount(target1);
    target2EntriesCache = getEntriesCacheForAccount(target2);
    const sum1 = calcMonthsSum(target1, target1EntriesCache, scenario, forecastStartDate, model.options.numMonthsAvg);
    const sum2 = calcMonthsSum(target2, target2EntriesCache, scenario, forecastStartDate, model.options.numMonthsAvg);
    return (sum1 !== 0 && sum2 !== 0) ? sum1 / sum2 : 0;
  } else if(percMetricMap[account.type]) {
    const type1 = (setting === "TOTAL_CUSTOMERS" || setting === "PLAN_CUSTOMERS") ? percMetricMap[account.type] : percTotalMrrMap[account.type];
    const type2 = (setting === "TOTAL_CUSTOMERS" || setting === "PLAN_CUSTOMERS") ? "Total Customers" : "MRR";
    const type2Account = (setting === "TOTAL_CUSTOMERS" || setting === "TOTAL_MRR") ? findAccountByType(revenueAccounts, type2) : findRevenueAccount(revenueAccounts, type2, account.product_id, account.plan_group_id);
    target1 = findRevenueAccount(revenueAccounts, type1, account.product_id, account.plan_group_id);
    target2 = type2Account;
    target1EntriesCache = getEntriesCacheForAccount(target1);
    target2EntriesCache = getEntriesCacheForAccount(target2);
    const sum1 = calcMonthsSum(target1, target1EntriesCache, scenario, forecastStartDate, model.options.numMonthsAvg);
    const sum2 = calcMonthsSum(target2, target2EntriesCache, scenario, forecastStartDate, model.options.numMonthsAvg, 1);
    return (sum1 !== 0 && sum2 !== 0) ? sum1 / sum2 : 0;
  }
};

export const createMonthsAvgDisplay = (user, accounts, entriesCache, revenueAccounts, account, scenarios, scenario, forecastStartDate, model, templateSection) => {
  if(!account) return 0;
  const revenueSettings = user?.tenant?.revenue;
  const isWeightedRevenueType = weightedRevenueTypes.includes(account.type);
  let monthsAvgVal = 0;
  let numberFormat = templateSection.options.numberFormat ? templateSection.options.numberFormat : (model.options.autopilotType !== "PERCENT_OF_REVENUE") ? "dollar" : "perc";
  if(templateSection && templateSection.options.percentage) numberFormat = "perc";
  if(isWeightedRevenueType && (model.options.autopilotType === "WEIGHTED_AVERAGE" || model.options.autopilotType === "WEIGHTED_GROW_SHRINK")) monthsAvgVal = createRevenueExceptionAccountAvg(revenueSettings, revenueAccounts, account, scenarios, scenario, forecastStartDate, model);
  else if(model.options.autopilotType === "PERCENT_OF_REVENUE") monthsAvgVal = calcAveragePercRevenue(accounts, account, entriesCache, forecastStartDate, model.options, scenarios);
  else monthsAvgVal = calcMonthsAvgVal(account, entriesCache, scenario, forecastStartDate, model.options.numMonthsAvg);
  return formatMonthsAvgVal(monthsAvgVal, numberFormat, user.tenant.options.currency || "dollar");
};

// Updates average and grow/shrink models when account is a revenue ARPC account
const updateRevenueExceptionAutopilotValues = (revenueSettings, revenueAccounts, account, entriesCache, scenarios, scenario, scenarioFormatted, forecastStartDate, model) => {
  const monthsAvg = createRevenueExceptionAccountAvg(revenueSettings, revenueAccounts, account, scenarios, scenario, forecastStartDate, model);
  // console.log(`Exception autopilot: ${monthsAvg}`);
  const forecastStartDateCursor = moment(forecastStartDate, "YYYY-MM");
  for(const dateKey in entriesCache[scenarioFormatted]) {
    if(forecastStartDate <= dateKey) {
      const entry = entriesCache[scenarioFormatted][dateKey]["FORMULA"];
      if(entry) {
        entriesCache[scenarioFormatted][dateKey]["FORMULA"] = createAutopilotEntry(model, entry, dateKey, forecastStartDate, forecastStartDateCursor, monthsAvg);
      }
    }
  }
};

const updatePercentOfRevenueAutopilot = (accounts, account, entriesCache, scenarios, scenarioFormatted, forecastStartDate, model) => {
  const revenueAccount = findAccountByType(accounts, "Revenue");
  const revenueAccountEntriesCache = getEntriesCacheForAccount(revenueAccount);
  const monthsAvg = calcAveragePercRevenue(accounts, account, entriesCache, forecastStartDate, model.options, scenarios, revenueAccount);
  for(const dateKey in entriesCache[scenarioFormatted]) {
    if(forecastStartDate <= dateKey) {
      const entry = entriesCache[scenarioFormatted][dateKey]["FORMULA"];
      if(entry) {
        const revenueEntry = revenueAccountEntriesCache[scenarioFormatted][dateKey]?.["TOTAL"] ?? 0;
        entriesCache[scenarioFormatted][dateKey]["FORMULA"] = createPercOfEntry(revenueEntry, entry, monthsAvg);
      }
    }
  }
};

const updateRegularAutopilot = (account, entriesCache, scenario, scenarioFormatted, forecastStartDate, model) => {
  const monthsAvg = calcMonthsAvgVal(account, entriesCache, scenario, forecastStartDate, model.options.numMonthsAvg);
  // console.log(`Regular autopilot: ${monthsAvg}`);
  const forecastStartDateCursor = moment(forecastStartDate, "YYYY-MM");
  for(const dateKey in entriesCache[scenarioFormatted]) {
    if(forecastStartDate <= dateKey) {
      const entry = entriesCache[scenarioFormatted][dateKey]["FORMULA"];
      if(entry) {
        entriesCache[scenarioFormatted][dateKey]["FORMULA"] = createAutopilotEntry(model, entry, dateKey, forecastStartDate, forecastStartDateCursor, monthsAvg);
      }
    }
  }
};

const updateRevenueForecastAutopilot = (revenueAccounts, account, entriesCache, scenarios, scenarioFormatted, forecastStartDate, model) => {
  const revenueAccount = (model.options.target_id) ? findAccountById(revenueAccounts, model.options.target_id) : revenueAccounts[0];
  const revenueAccountEntriesCache = getEntriesCacheForAccount(revenueAccount);
  for(const dateKey in entriesCache[scenarioFormatted]) {
    if(forecastStartDate <= dateKey && revenueAccount) {
      const entry = entriesCache[scenarioFormatted][dateKey]["FORMULA"];
      if(entry) {
        const entryType = (revenueAccount.plan_group_id) ? "FORMULA" : "TOTAL";
        const revenueEntry = revenueAccountEntriesCache[scenarioFormatted][dateKey][entryType];
        entriesCache[scenarioFormatted][dateKey]["FORMULA"].value = revenueEntry.value;
      }
    }
  }
};


const updateMetricForecastAutopilot = (accounts, account, entriesCache, scenarioFormatted, forecastStartDate, model) => {
  const targetAccount = (model.options.target_id) ? findAccountById(accounts, model.options.target_id) : accounts[0];

  if(targetAccount) {
    const targetAccountEntriesCache = getEntriesCacheForAccount(targetAccount);
    for(const dateKey in entriesCache[scenarioFormatted]) {
      if(forecastStartDate <= dateKey) {
        const entry = entriesCache[scenarioFormatted][dateKey]["FORMULA"];
        if(entry) {
          const targetEntryValue = (targetAccountEntriesCache?.[scenarioFormatted]?.[dateKey]?.["FORMULA"]?.["value"]) ? targetAccountEntriesCache[scenarioFormatted][dateKey]["FORMULA"]["value"] : 0;
          entriesCache[scenarioFormatted][dateKey]["FORMULA"].value = targetEntryValue;
        }
      }
    }
  }
};

const updatePercentileAutopilot = (account, entriesCache, scenario, scenarioFormatted, forecastStartDate, model) => {
  const entryValues = [];
  if(account) {
    for(let i = 1; i <= model.options.numMonthsAvg; i++) {
      const date = moment(forecastStartDate, "YYYY-MM").subtract(i, "month").format("YYYY-MM");
      const value = grabEntryValueFromCache(entriesCache, scenario, date, "FORMULA", forecastStartDate, false);
      entryValues.push(value);
    }
  }
  const percentileOption = (model.options.percentile > 100 || model.options.percentile < 0) ? 0 : model.options.percentile;
  const percentileValue = (model.options.percentile > -1 && entryValues.length > 0) ? mathjs.compile(model.options.numMonthsAvg === 1 ? "a" : `quantileSeq(a, ${percentileOption / 100})`).evaluate({a: entryValues}) : 0;
  for(const dateKey in entriesCache[scenarioFormatted]) {
    if(forecastStartDate <= dateKey) {
      const entry = entriesCache[scenarioFormatted][dateKey]["FORMULA"];
      if(entry) {
        entriesCache[scenarioFormatted][dateKey]["FORMULA"].value = percentileValue;
      }
    }
  }
};

// Account variable is already a clone of the account, this way can control how often account is cloned
export const updateAutopilotValues = (user, accounts, entriesCache, revenueAccounts, account, scenarios, forecastStartDate, models) => {
  // Take each model, and update the values inside the account"s entryCache
  const revenueSettings = user?.tenant?.revenue;
  const isWeightedRevenueType = weightedRevenueTypes.includes(account.type);
  const entriesCacheClone = cloneDeep(entriesCache);
  for(const scenario of scenarios) {
    const scenarioFormatted = scenario.id.toString();
    const model = models?.[scenarioFormatted]?.autopilot;
    if(model?.active && model?.options) {
      if(isWeightedRevenueType && (model.options.autopilotType === "WEIGHTED_AVERAGE" || model.options.autopilotType === "WEIGHTED_GROW_SHRINK")) updateRevenueExceptionAutopilotValues(revenueSettings, revenueAccounts, account, entriesCacheClone, scenarios, scenario, scenarioFormatted, forecastStartDate, model);
      else if(model.options.autopilotType === "PERCENT_OF_REVENUE") updatePercentOfRevenueAutopilot(accounts, account, entriesCacheClone, scenarios, scenarioFormatted, forecastStartDate, model);
      else if(model.options.autopilotType === "REVENUE_FORECAST") updateRevenueForecastAutopilot(revenueAccounts, account, entriesCacheClone, scenarios, scenarioFormatted, forecastStartDate, model);
      else if(model.options.autopilotType === "METRIC_FORECAST") updateMetricForecastAutopilot(accounts, account, entriesCacheClone, scenarioFormatted, forecastStartDate, model);
      else if(model.options.autopilotType === "PERCENTILE") updatePercentileAutopilot(account, entriesCacheClone, scenario, scenarioFormatted, forecastStartDate, model);
      else updateRegularAutopilot(account, entriesCacheClone, scenario, scenarioFormatted, forecastStartDate, model);
    }
  }

  return entriesCacheClone;
};


