import { useState } from "react";
import PropTypes from "prop-types";

import Errors from "components/Errors";
import TypedConfirmation from "components/TypedConfirmation";

import { ALL_TAX_RATES_QUERY } from "./graphql";

const CompanySettingsTaxRatesDelete = (props) => {
  const { onCancel, onRemove, onSuccess, taxRate } = props;

  const [deleting, setDeleting] = useState(false);
  const [errors, setErrors] = useState([]);

  const deleteTaxRate = () => {
    setDeleting(true);
    onRemove({
      variables: { id: taxRate.id },
    })
      .then(() => {
        setDeleting(false);
        onSuccess();
      })
      .catch((error) => {
        console.error("There was an error sending the query", error.message);
        setErrors([
          "All employees using this tax rate must first be updated.",
        ]);
      });
  };

  if (!taxRate) return null;

  return (
    <TypedConfirmation
      isOpen={true}
      onCancel={onCancel}
      onConfirm={deleteTaxRate}
      deleting={deleting}
    >
      <Errors messages={errors} />
      <p>
        Are you sure that you want to remove tax rate{" "}
        <strong>{taxRate.name}</strong>?
      </p>
      <p>
        All employees with this tax rate need to move to another tax rate or be
        removed before this tax rate can be deleted. This action is
        irreversible.
      </p>
      <p>
        Please type "delete" in the input field then click{" "}
        <strong>Delete</strong> below if you are sure that you would like this
        tax rate removed.
      </p>
    </TypedConfirmation>
  );
};

CompanySettingsTaxRatesDelete.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  taxRate: PropTypes.object,
};

export default CompanySettingsTaxRatesDelete;
