import React from "react";
import PropTypes from "prop-types";

import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";

import {getSlug, updateOtherModelsIfNecessary} from "./utilities";
import CustomPropTypes from "../../propTypes";

import AccountSelector from "./AccountSelector";

import styles from "./styles.module.scss";

export default function ReferenceOptions({
  onRowChange,
  row,
  worksheet,
}) {
  const handleRowPropertyChange = ({target: {name, value, text = null, label = null}}) => {
    let rowName = name === "name" ? value : row.name;
    let newSlug = row.slug;

    if(name === "useParentValues") value = !row.useParentValues;
    if(name === "name") newSlug = getSlug({accountName: rowName, worksheet, currentRowId: row.id});
    if(name === "account_id") {
      rowName = `${text}`;
      if(label && !["PNL", "BS", "CFS", "REV", "WORK"].includes(label)) rowName += ` (${label})`;
      newSlug = getSlug({accountName: rowName, worksheet, currentRowId: row.id});
    }
    let modelsForCurrentRow = row.models;
    if(["account_id", "useParentValues", "name"].includes(name)) {
      const updatedModels = updateOtherModelsIfNecessary(worksheet, row, name, value, newSlug);
      if(updatedModels.length) {
        // If multiple models are updated for the same row, make sure we don't overwrite our first changes in subsequent iterations
        const modelsByRowIndex = {};
        for(const {rowIndex, modelIndex, model} of updatedModels) {
          const rowClone = {
            ...worksheet.rows[rowIndex],
            models: [...(modelsByRowIndex[rowIndex] || worksheet.rows[rowIndex].models)],
          };
          rowClone.models[modelIndex] = model;
          onRowChange(rowClone);

          // Set modelsByRowIndex for that row to the last version of the models that we know of for that row
          modelsByRowIndex[rowIndex] = rowClone.models;

          // If the updated row is the same as the current one, make sure the new models are used in the save function below or else changes are overwritten
          if(rowClone.id === row.id) modelsForCurrentRow = rowClone.models;
        }
      }
    }

    onRowChange({
      ...row,
      models: modelsForCurrentRow,
      [name]: value,
      name: rowName,
      slug: newSlug,
    });
  };

  return (
    <>
      <AccountSelector
        accountId={row.account_id}
        onChange={handleRowPropertyChange}
      />
      <TextInput
        label="Row Name"
        name="name"
        onChange={handleRowPropertyChange}
        value={row.name}
      />
      <div className={styles.slugText}>{row.slug}</div>
      <Checkbox
        checked={row.useParentValues === true}
        className={styles.checkbox}
        label="Use parent values when available"
        name="useParentValues"
        onChange={handleRowPropertyChange}
      />
    </>
  );
}

ReferenceOptions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onRowChange: PropTypes.func.isRequired,
  row: CustomPropTypes.row.isRequired,
  worksheet: PropTypes.object.isRequired,
};
