import React, {useContext} from "react";
import PropTypes from "prop-types";

import {Icon} from "@blueprintjs/core";

import {isCurrentMonth} from "shared/utilities/forecast-utilities";

import styles from "./AccountRow.module.scss";
import sharedStyles from "./styles.module.scss";

import {AppContext} from "../../AppContext";

const months = [];
for(let i = 1; i <= 12; i++) {
  months.push(i.toString().padStart(2, "0"));
}

function TitleRow({
  templateSection,
  year,
  extended,
}) {
  const {forecastStartDate} = useContext(AppContext);

  const sectionOptions = templateSection.options || {};
  const indentation = sectionOptions.indentation ? sectionOptions.indentation : 0;
  const rowStyles = [styles.statementRow];
  if(sectionOptions.uppercase) rowStyles.push(styles.uppercase);
  if(sectionOptions.bold) rowStyles.push(styles.bold);

  return (
    <tr className={rowStyles.join(" ")}>
      <td className={[styles[`indent${indentation}`], "line-item-title"].join(" ")}>
        <div className={styles.titleWrapper}>
          <span>{templateSection.title || ""}</span>
          {sectionOptions.collapsible ? <Icon icon="chevron-down" /> : null}
        </div>
      </td>
      {months.map((month, index) => (
        <td
          className={isCurrentMonth(forecastStartDate, `${year}-${month}`) ? sharedStyles.forecastStart : undefined}
          key={index}
        />
      ))}
      {extended && months.map((month, index) => (
        <td
          className={isCurrentMonth(forecastStartDate, `${parseInt(year) + 1}-${month}`) ? sharedStyles.forecastStart : undefined}
          key={index}
        />
      ))}
    </tr>
  );
}

TitleRow.propTypes = {
  templateSection: PropTypes.object.isRequired,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  extended: PropTypes.bool,
};

export default TitleRow;
