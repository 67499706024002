import React, {useContext, useReducer} from "react";
import PropTypes from "prop-types";

import Card from "components/Card";
import Button from "components/Button";

import ProductTable from "./ProductTable";
import ProductCreateUpdate from "./ProductCreateUpdate";
import PlanCreateUpdate from "./PlanCreateUpdate";
import {AppContext} from "../../../AppContext";

import styles from "./styles.module.scss";

function reducer(state, action) {
  switch(action.type) {
    case "TOGGLE_PRODUCT_DIALOG": {
      return {
        ...state,
        productDialogOpen: !state.productDialogOpen,
      };
    }
    case "TOGGLE_PLAN_DIALOG": {
      return {
        ...state,
        planDialogOpen: !state.planDialogOpen,
      };
    }
    default:
      return state;
  }
}

const initialState = {
  productDialogOpen: false,
  planDialogOpen: false,
};

export default function ProductCard({product}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {user} = useContext(AppContext);

  const toggleProductDialog = () => dispatch({type: "TOGGLE_PRODUCT_DIALOG"});
  const togglePlanDialog = () => dispatch({type: "TOGGLE_PLAN_DIALOG"});

  return (
    <Card className={styles.productCard}>
      {state.productDialogOpen ? <ProductCreateUpdate onClose={toggleProductDialog} product={product} /> : null}
      {state.planDialogOpen ? <PlanCreateUpdate onClose={togglePlanDialog} preselectedProductId={product.id || 0} /> : null}
      <h3 className={styles.teamName}>
        {product.name}
        <span className={styles.cardButtons}>
          <Button
            className={styles.productsCount}
            icon="box"
            size="small"
          >
            {product.plans && product.plans.length ? product.plans.length : "0"}
          </Button>
          {!!product.id && user.role !== "VIEW" ? (
            <Button
              intent="success"
              onClick={toggleProductDialog}
              size="small"
            >
              Edit Product
            </Button>
          ) : null}

          {user.role !== "VIEW" ? (
            <Button
              intent="success"
              onClick={togglePlanDialog}
              size="small"
            >
              Add Plan
            </Button>
          ) : null}
        </span>
      </h3>
      <ProductTable product={product} />
    </Card>
  );
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};
