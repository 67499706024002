import React from "react";
import PropTypes from "prop-types";

const errorMapping = {
  REMOVE_SCENARIO_MODELS_CONFLICT: {
    message: `Scenario cannot be removed because models are still attached to it`,
  },
  REMOVE_SCENARIO_UNKNOWN_ERROR: {
    message: `An error occurred while removing this scenario`,
  },
};

class ErrorMessage extends React.Component {
  // At some point an addional argument could be passed to substitute values in the string
  static resolve(name) {
    return errorMapping[name].message || this.props.name;
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
  }

  render() {
    const errorMessage = errorMapping[this.props.name].message || this.props.name;
    return (
      <span>
        {errorMessage}
      </span>
    );
  }
}

export default ErrorMessage;
