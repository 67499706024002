import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Card from "components/Card";
import Loading from "components/Loading";
import SettingsHeader from "components/SettingsHeader";

import AddUserForm from "./AddUserForm";
import TeamTable from "./TeamTable";

import {createUser, updateUser, removeUser} from "./graphql";
import {ALL_USERS_QUERY} from "./graphql";

class CompanySettingsTeam extends React.Component {
  createUser = (user) => this.props.createUser({
    variables: {user},
    refetchQueries: [{query: ALL_USERS_QUERY}],
  });

  updateUser = (user) => this.props.updateUser({
    variables: {id: user.id, user},
    refetchQueries: [{query: ALL_USERS_QUERY}],
  });

  removeUser = (id) => this.props.removeUser({
    variables: {id},
    refetchQueries: [{query: ALL_USERS_QUERY}],
  });

  render() {
    const {allUsersQuery} = this.props;

    return (
      <Card>
        {allUsersQuery.loading ? (
          <Loading />
        ) : (
          <>
            <SettingsHeader subtitle text="Users" />
            <AddUserForm buttonLabel="Add" onCreate={this.createUser} />
            <TeamTable
              onRemove={this.removeUser}
              onSave={this.updateUser}
              users={allUsersQuery.allCompanyUsers}
            />
          </>
        )}
      </Card>
    );
  }
}

CompanySettingsTeam.propTypes = {
  allUsersQuery: PropTypes.object.isRequired,
  createUser: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

const CompanySettingsTeamWithData = compose(
  graphql(ALL_USERS_QUERY, {name: "allUsersQuery"}),
  graphql(createUser, {name: "createUser"}),
  graphql(updateUser, {name: "updateUser"}),
  graphql(removeUser, {name: "removeUser"}),
)(CompanySettingsTeam);

export default CompanySettingsTeamWithData;
