import {gql} from "@apollo/client";

export const updateOnboarding = gql`
  mutation updateOnboarding($onboarding: JSON!) {
    updateOnboarding(onboarding: $onboarding) {
      onboarding
    }
  }
`;

export const updateBankAccount = gql`
  mutation updateBankAccount($onboarding: JSON!, $account: JSON!) {
    updateOnboarding(onboarding: $onboarding) {
      onboarding
    }
    updateAccount(account: $account) {
      id
    }
  }
`;

export const updateAutoPilot = gql`
  mutation updateAutoPilot($onboarding: JSON!, $forecastData:OnboardingForecastParams!) {
    updateOnboarding(onboarding: $onboarding) {
      onboarding
    }
    updateOnboardingForecasts(data: $forecastData)
  }
`;
