import React from "react";
import {Card} from "@blueprintjs/core";

import styles from "../auth/styles/Login.module.scss";

import appStyles from "../App.module.scss";

import { Classes as BP } from "@blueprintjs/core";

class Maintenance extends React.Component {
  render() {
    return (
      <div className={appStyles.appBody}>
        <div className={styles.login}>
          <Card className={styles.card}>
            <div className={styles.wrapper}>
              <div className={styles.signin}>
                <h3 className={BP.HEADING}>Maintenance Ongoing</h3>
                <p className={`${BP.TEXT_MUTED} ${BP.RUNNING_TEXT} m-t-md m-b-0`}>
                  Flightpath Finance is undergoing maintenance to provide you a better experience. Your Flightpath will be available again within the hour.<br /><br />Please contact support if you require further assistance at <a href="mailto:support@flightpathfinance.com">support@flightpathfinance.com</a>.
                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default Maintenance;
