import moment from "moment";
import {createYearsArray} from "shared/utilities/date-utilities";
import {union} from "lodash";

export const createYearsArrays = (user, provider) => {
  const yearsArrays = {
    forecastStartYearArray: [],
    forecastEndYearArray: [],
    allYearsArray: [],
  };

  if(user?.tenant) {
    const rightNow = moment();
    const historicalsLimit = (provider === "XERO") ? 1 : 5;

    const {historicalsStartDate, forecastEndDate} = user.tenant.options || {};

    const possibleForecastStartYears = {
      from: historicalsStartDate ? moment(historicalsStartDate).startOf("year") : rightNow.clone().startOf("year").subtract(3, "year"),
      to: forecastEndDate ? moment(forecastEndDate).endOf("year") : rightNow.clone().add(1, "year"),
    };

    yearsArrays.forecastStartYearArray = createYearsArray(possibleForecastStartYears.from, possibleForecastStartYears.to);
    yearsArrays.modelSettingsYearArray = createYearsArray(moment().subtract(historicalsLimit, "years").startOf("year"), rightNow.clone().add(1, "year"));
    yearsArrays.forecastEndYearArray = createYearsArray(rightNow, rightNow.clone().add(5, "years"));
    const yearsUntilForecastEnd = createYearsArray(rightNow, (forecastEndDate) ? forecastEndDate : moment().add(1, "year").endOf("year"));
    yearsArrays.allYearsArray = union(yearsArrays.forecastStartYearArray, yearsUntilForecastEnd);
  }

  return yearsArrays;
};
