import React from "react";
import PropTypes from "prop-types";
import {cloneDeep} from "lodash";

import {Dialog} from "@blueprintjs/core";

import Button from "components/Button";
import Errors from "components/Errors";
import RichText from "components/RichTextEditor";

import {ALL_EMPLOYEES_QUERY} from "../payroll/graphql";
import {ALL_SCENARIOS_QUERY} from "./graphql";
import {ALL_SCENARIOS_QUERY as APP_SCENARIOS_QUERY, INITIAL_LOAD_QUERY} from "../../../graphql";

class CompanySettingsScenariosCreateUpdate extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    operation: PropTypes.string,
    scenario: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = this.createScenarioState(false, props.scenario);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.scenario === null &&
      this.props.scenario !== null &&
      this.props.operation !== "delete" &&
      this.props.operation !== "copy"
    ) {
      const state = this.createScenarioState(true, this.props.scenario);
      this.setState(state);
    }
  }

  createScenarioState = (isOpen, scenario) => {
    const state = {
      isOpen,
      id: (scenario && scenario.id) ? scenario.id : null,
      name: (scenario && scenario.name) ? scenario.name : "",
      description: (scenario && scenario.description) ? scenario.description : "",
      saving: false,
      errors: [],
    };
    this.description = (scenario && scenario.description && scenario.description.document && scenario.description.document.nodes && scenario.description.document.nodes.length > 0) ? cloneDeep(scenario.description) : null;
    return state;
  }

  toggleDialog = () => {
    this.props.onClose();
    this.setState({isOpen: !this.state.isOpen});
  }

  changeName = (event) => {
    this.setState({name: event.target.value});
  }

  changeDescription = (value) => {
    this.description = value;
  };

  onOperation = () => {
    this.setState({saving: true});
    const variables = {
      name: this.state.name,
    };
    if(this.description) variables.description = (this.description["object"]) ? this.description : this.description.toJSON();
    if(this.state.id) variables.id = this.state.id;
    const operation = (this.props.operation === "create") ? this.props.onCreate : this.props.onUpdate;
    operation({
      variables,
      refetchQueries: [
        {query: ALL_SCENARIOS_QUERY},
        {query: APP_SCENARIOS_QUERY},
        {query: ALL_EMPLOYEES_QUERY},
        {query: INITIAL_LOAD_QUERY},
      ],
    })
    .then(() => {
      const state = this.createScenarioState(!this.state.isOpen, this.props.scenario);
      this.setState(state);
      this.props.onClose();
    }).catch((error) => {
      console.log("There was an error with the query", error);
      this.setState({errors: ["Scenario saving failed. Check required fields."]});
    });
  }

  render() {
    const title = (this.props.operation === "create") ? "Create Scenario" : "Update Scenario";
    const icon = (this.props.operation === "create") ? "add" : "annotation";
    return (
      <span className="ScenariosCreate">
        <Dialog
          canOutsideClickClose={false}
          className="bp5-large"
          icon={icon}
          inline
          isOpen={this.state.isOpen}
          onClose={this.toggleDialog}
          title={title}
        >
          <div className="bp5-dialog-body">
            <Errors messages={this.state.errors}/>
            <div className="bp5-form-group">
              <h4 className="bp5-heading">Name <span className="bp5-text-muted">(required)</span></h4>
              <input
                autoComplete="off"
                className="bp5-input bp5-large"
                onChange={this.changeName}
                placeholder="Scenario Name"
                type="text"
                value={this.state.name}
              />
            </div>
            <div className="bp5-form-group m-t">
              <h4 className="bp5-heading">Description</h4>
              <RichText changeTextValue={this.changeDescription} initialValue={this.description} placeholder="Scenario Description" />
            </div>
          </div>
          <div className="bp5-dialog-footer">
            <div className="bp5-dialog-footer-actions">
              <Button onClick={this.toggleDialog} text="Cancel" />
              <Button
                disabled={this.state.saving}
                intent="success"
                loading={this.state.saving}
                onClick={this.onOperation}
                text="Save"
              />
            </div>
          </div>
        </Dialog>
      </span>
    );
  }
}

export default CompanySettingsScenariosCreateUpdate;
