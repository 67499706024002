// eslint-disable-next-line
import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { ApolloProvider } from "@apollo/client";
import { IntercomProvider } from 'react-use-intercom';

import { client } from "apollo-config";
import "./styles/index.css";
import { unregister } from "./registerServiceWorker";

import Auth from "./auth";
import ForgotPassword from "./auth/ForgotPassword";
import EmailSignup from "./auth/EmailSignup";

import AppSumoLanding from "./views/appsumo";
import UserSettings from "./views/user-settings";
import NoMatch from "./views/NoMatch";
import QuickBooksDisconnected from "./views/QuickBooksDisconnected";
import UserNotFound from "./views/UserNotFound";

import createAppRouter from "./router";

// Polyfill for toBlob on canvas
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
    value(callback, type, quality) {
      const canvas = this;
      setTimeout(() => {
        const binStr = atob(canvas.toDataURL(type, quality).split(",")[1]);
        const len = binStr.length;
        const arr = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        callback(new Blob([arr], { type: type || "image/png" }));
      });
    },
  });
}

let router = createAppRouter();

if (window.location.host === import.meta.env.VITE_LOGIN_HOST) {
  router = createBrowserRouter([
    {
      path: "/",
      element: <Auth />,
    },
    {
      path: "appsumo",
      element: <AppSumoLanding />,
    },
    {
      path: "unauthorized",
      element: <UserNotFound />,
    },
    {
      path: "quickbooks-disconnected",
      element: <QuickBooksDisconnected />,
    },
    {
      path: "login",
      element: <Auth />,
      children: [
        {
          path: "accept-invite",
        },
        {
          path: "reset-password",
          Component: ForgotPassword,
        },
        {
          path: "accept-invite/email",
          Component: EmailSignup,
        },
      ],
    },
    {
      path: "*",
      element: <NoMatch />,
    }
  ]);
}

const INTERCOM_APP_ID = "tq0xuv22";

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <RouterProvider router={router} future={{ v7_startTransition: true }} />
      </IntercomProvider>
    </ApolloProvider>
  </React.StrictMode>
);

unregister();
