import React, {useContext, useState} from "react";
import PropTypes from "prop-types";

import {useMutation} from "@apollo/client";

import {useNavigate} from "react-router-dom";

import Button from "components/Button";
import TypedConfirmation from "components/TypedConfirmation";

import {AppContext} from "../../../AppContext";

import {actionTypes} from "./reducer";
import {cleanWorksheetFromGhostModels, RowType, updateStore, transformWorksheetOnLoad, mergeNewWorksheetWithWorkingCopy} from "./utilities";

import {CREATE_WORKSHEET, UPDATE_WORKSHEET, DELETE_WORKSHEET} from "./graphql";

import styles from "./styles.module.scss";

export default function ActionsFooter({
  dispatch,
  modelsInvalid,
  worksheet,
}) {
  const [pendingAction, setPendingAction] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const {user} = useContext(AppContext);
  const navigate = useNavigate();

  const [createWorksheet] = useMutation(CREATE_WORKSHEET, {update: updateStore("create")});
  const [updateWorksheet] = useMutation(UPDATE_WORKSHEET, {update: updateStore("update")});
  const [deleteWorksheet] = useMutation(DELETE_WORKSHEET, {update: updateStore("delete")});

  const handleCancel = () => navigate(`/worksheets`);

  const showDeleteConfirmationToggle = () => {
    setShowDeleteConfirmation(!showDeleteConfirmation);
  };

  const handleDeleteWorksheet = () => {
    setPendingAction("delete");
    setShowDeleteConfirmation(false);
    deleteWorksheet({variables: {id: worksheet.id}}).then(() => {
      setPendingAction(null);
      handleCancel();
    });
  };

  const handleSave = ({close = false} = {}) => () => {
    setPendingAction(close ? "saveAndClose" : "save");
    const cleanedWorksheet = cleanWorksheetFromGhostModels(worksheet);
    if(!worksheet.id) {
      createWorksheet({variables: {worksheet: cleanedWorksheet}}).then(({data: {createWorksheet}}) => {
        const newWorksheet = transformWorksheetOnLoad(createWorksheet);
        const mergedWorksheet = mergeNewWorksheetWithWorkingCopy(newWorksheet, worksheet);
        dispatch({type: actionTypes.updateWorksheet, payload: {worksheet: mergedWorksheet}})
        if(!close) {
          navigate(`/worksheets/${createWorksheet.slug}/edit`);
        } else {
          handleCancel();
        }
        setPendingAction(null);
      });
    } else {
      updateWorksheet({variables: {worksheet: cleanedWorksheet}}).then(({data: {updateWorksheet}}) => {
        const newWorksheet = transformWorksheetOnLoad(updateWorksheet);
        const mergedWorksheet = mergeNewWorksheetWithWorkingCopy(newWorksheet, worksheet);
        dispatch({type: actionTypes.updateWorksheet, payload: {worksheet: mergedWorksheet}})

        setPendingAction(null);
        if(close) handleCancel();
      });
    }
  };

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.leftSection}>
          {user.role !== "VIEW" ? (
            <>
              <Button
                disabled={pendingAction !== null}
                intent="success"
                onClick={() => dispatch({type: actionTypes.addRow, payload: {type: RowType.REFERENCE}})}
                text="Add Reference"
              />
              <Button
                disabled={pendingAction !== null}
                intent="success"
                onClick={() => dispatch({type: actionTypes.addRow, payload: {type: RowType.METRIC}})}
                text="Add Metric"
              />
              <Button
                border
                disabled={pendingAction !== null}
                minimal
                onClick={() => dispatch({type: actionTypes.addRow, payload: {type: RowType.EMPTY}})}
                text="Add Empty Row"
              />
              {worksheet.id ? (
                <Button
                  disabled={pendingAction !== null}
                  intent="danger"
                  loading={pendingAction === "delete"}
                  onClick={showDeleteConfirmationToggle}
                  text="Delete Worksheet"
                />
              ) : null}
            </>
          ) : null}
        </div>
        <div className={styles.rightSection}>
          <Button
            disabled={pendingAction !== null}
            onClick={handleCancel}
            text={user.role !== "VIEW" ? "Cancel" : "Close"}
          />
          {user.role !== "VIEW" ? (
            <>
              <Button
                disabled={modelsInvalid || pendingAction !== null || !worksheet.name?.length}
                loading={pendingAction === "save"}
                onClick={handleSave()}
                text="Save"
              />
              <Button
                disabled={modelsInvalid || pendingAction !== null || !worksheet.name?.length}
                loading={pendingAction === "saveAndClose"}
                onClick={handleSave({close: true})}
                text="Save & Close"
              />
            </>
          ) : null}
        </div>
      </div>
      <TypedConfirmation
        deleting={pendingAction === "delete"}
        isOpen={showDeleteConfirmation}
        onCancel={showDeleteConfirmationToggle}
        onConfirm={handleDeleteWorksheet}
      >
        <p>Are you sure that you want to delete this worksheet? This is irreversible and will delete all the metrics created in this worksheet.</p>
        <p>
          Please type 'delete' in the input field then click <strong>Delete</strong> below if you are sure
          that you would like this worksheet to be deleted.
        </p>
      </TypedConfirmation>
    </>
  );
}

ActionsFooter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modelsInvalid: PropTypes.bool.isRequired,
  worksheet: PropTypes.object.isRequired,
};
