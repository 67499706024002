import React, {useContext} from "react";
import PropTypes from "prop-types";

import {isCurrentMonth} from "shared/utilities/forecast-utilities";

import {AppContext} from "../../../AppContext";

import {months} from "./utilities";

import styles from "./styles.module.scss";

export default function EmptyRow({onClick, year}) {
  const {forecastStartDate} = useContext(AppContext);

  return months.map((month) => {
    const cellClasses = [];
    const isForecastStart = isCurrentMonth(forecastStartDate, `${year}-${month.toString().padStart(2, "0")}`);
    if(isForecastStart) cellClasses.push(styles.forecastStart);

    return (
      <td className={cellClasses.join(" ")} key={month} onClick={onClick} />
    );
  });
}

EmptyRow.propTypes = {
  onClick: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired,
};
