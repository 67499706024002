import React, { useCallback } from "react";
import PropTypes from "prop-types";

import dayjs from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

import Checkbox from "components/Checkbox";
import Switcher from "components/Switcher";
import { Tag, FormGroup, ControlGroup, InputGroup, Position, Classes as BP } from "@blueprintjs/core";
import { DateInput3 as DateInput } from "@blueprintjs/datetime2";

import PayrollModelArray from "./PayrollModelArray";
import PayrollModelCompanyWideRaises from "./PayrollModelCompanyWideRaises";

import { AppContext } from "../../../AppContext";

import getCurrencyIcon from "views/shared/currency-icon";

import styles from "./CompanySettingsEmployees.module.scss";

const periods = [
  { value: "YEAR", name: "per year" },
  { value: "MONTH", name: "per month" },
];

class CompanySettingsPayrollModel extends React.Component {
  static propTypes = {
    changeScenario: PropTypes.func.isRequired,
    employee: PropTypes.object,
    payrollModels: PropTypes.object.isRequired,
    scenario: PropTypes.string,
    scenarios: PropTypes.array.isRequired,
    taxRates: PropTypes.array.isRequired,
    updateModel: PropTypes.func.isRequired,
    updateModelArray: PropTypes.func.isRequired,
    updateModelFields: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    const currentYear = new Date().getFullYear();
    const fromMonth = new Date(currentYear - 10, 0);
    const toMonth = new Date(currentYear + 5, 11);
    const currentModel = props.payrollModels[props.scenario];
    const taxRate = props.taxRates.find(tr => tr.id == currentModel.tax_rate_id);
    const manualTax = taxRate === undefined || taxRate === null;
    this.state = {
      fromMonth,
      toMonth,
      manualTax,
    };
  }

  static contextType = AppContext;

  highlightAll = () => {
    setTimeout(() => document.execCommand("selectAll", false, null), 0);
  }

  changeHireDate = (model, newDate) => {
    const hireDate = (newDate) ? dayjs(newDate) : null;
    // Company wide raise date needs to move with hire date
    const changes = { "hire_date": (hireDate) ? hireDate.format("YYYY-MM-DD") : null };
    if (model.company_raise_date) changes["company_raise_date"] = (hireDate) ? hireDate.clone().add(1, "day").format("YYYY-MM-DD") : null;
    this.props.updateModelFields(changes);
  }

  updateTax = ({ target: { value } }) => {
    const taxRate = this.props.taxRates.find(tr => tr.id == value);
    this.props.updateModelFields({
      tax_rate_id: taxRate.id,
      payroll_tax: taxRate.value
    });
  }

  updateTaxManual = (type) => ({ target: { value } }) => {
    this.props.updateModel("payroll_tax", value, "number", type);
  }

  toggleManual = () => {
    if (this.state.manualTax) {
      // Switching to mapped, so...
      const taxRate = this.props.taxRates[0];
      this.props.updateModelFields({
        tax_rate_id: taxRate.id,
        payroll_tax: taxRate.value
      });
    } else {
      // Switching to manual, so...
      this.props.updateModel("tax_rate_id", null);
    }
    this.setState({ manualTax: !this.state.manualTax })
  }

  formatDate(date) {
    return date ? dayjs(date).format("MM/DD/YYYY") : undefined;
  }

  parseDate(str) {
    return dayjs(str, "MM/DD/YYYY").toDate();
  }

  render() {
    const { fromMonth, toMonth } = this.state;
    const { currency } = this.context;

    const modelFmt = "YYYY-MM-DD";

    const model = (this.props.payrollModels[this.props.scenario]) ? this.props.payrollModels[this.props.scenario] : {};
    const forecastEndDate = (this.context.forecastEndDate) ? dayjs(this.context.forecastEndDate, modelFmt).toDate() : toMonth;

    const hireDate = (model.hire_date) ? dayjs(model.hire_date, modelFmt).toDate() : fromMonth;
    const hireDatePicker = (model.hire_date) ? dayjs(model.hire_date, modelFmt).format("MM/DD/YYYY") : null;

    const terminationDate = (model.termination_date) ? dayjs(model.termination_date, modelFmt).toDate() : toMonth;
    const terminationDatePicker = (model.termination_date) ? dayjs(model.termination_date, modelFmt).format("MM/DD/YYYY") : null;

    return (
      <div className={`${styles.payrollModelSection}`}>
        <div className={`${styles.payrollGrid}`}>
          <div className={`${styles.payrollGridCol}`}>
            <FormGroup
              className={`${styles.payrollFormGroup}`}
              labelFor="scenario"
            >
              <Switcher
                label="Scenario"
                onChange={this.props.changeScenario}
                options={this.props.scenarios.map((item) => ({ name: item.name, value: item.id }))}
                selectedValue={this.props.scenario}
              />
            </FormGroup>

            <FormGroup
              className={`${styles.payrollFormGroup}`}
              label="Salary"
              labelFor="compensation"
              labelInfo="*"
            >
              <ControlGroup fill vertical={false}>
                <InputGroup
                  autoComplete="off"
                  fill
                  id="compensation"
                  leftElement={getCurrencyIcon(currency)}
                  onBlur={(event) => this.props.updateModel("compensation", event.target.value, "number", "blur")}
                  onChange={(event) => this.props.updateModel("compensation", event.target.value, "number", "focus")}
                  onFocus={this.highlightAll}
                  placeholder="Starting Salary"
                  value={model.compensation}
                />
                <div className={[BP.HTML_SELECT, BP.FILL].join(" ")}>
                  <select
                    onChange={(event) => this.props.updateModel("compensation_period", event.target.value)}
                    value={model.compensation_period}
                  >
                    {periods.map((period, index) => (
                      <option key={index} value={period.value}>
                        {period.name}
                      </option>
                    ))}
                  </select>
                </div>
              </ControlGroup>
            </FormGroup>
            <PayrollModelArray
              array={model.raises}
              compensationType={"raises"}
              forecastEndDate={forecastEndDate}
              fromMonth={hireDate}
              terminationDate={terminationDate}
              toMonth={toMonth}
              updateModelArray={this.props.updateModelArray}
            />
            <PayrollModelCompanyWideRaises
              companyRaiseDate={model.company_raise_date}
              customRaises={model.raises}
              hireDate={hireDate}
              onChange={(newDate) => this.props.updateModel("company_raise_date", (newDate) ? dayjs(newDate).format("YYYY-MM-DD") : null)}
              salary={model.compensation}
              terminationDate={terminationDate}
            />
          </div>

          <div className={`${styles.payrollGridCol}`}>
            <FormGroup
              className={`${styles.payrollFormGroup}`}
              label="Hire Date"
              labelFor="hire-date"
              labelInfo="*"
            >
              <DateInput
                formatDate={this.formatDate}
                parseDate={this.parseDate}
                maxDate={terminationDate}
                minDate={fromMonth}
                onChange={(newDate) => this.changeHireDate(model, newDate)}
                placeholder={"mm/dd/yyyy"}
                popoverProps={{ position: Position.BOTTOM }}
                value={hireDatePicker}
                showActionsBar
              />
            </FormGroup>

            <FormGroup
              className={`${styles.payrollFormGroup}`}
              label="Benefits"
              labelFor="benefits"
            >
              <ControlGroup fill vertical={false}>
                <InputGroup
                  autoComplete="off"
                  fill
                  id="benefits"
                  leftElement={getCurrencyIcon(currency)}
                  onBlur={(event) => this.props.updateModel("benefits", event.target.value, "number", "blur")}
                  onChange={(event) => this.props.updateModel("benefits", event.target.value, "number", "focus")}
                  onFocus={this.highlightAll}
                  placeholder="Benefits"
                  value={model.benefits}
                />
                <div className={[BP.HTML_SELECT, BP.FILL].join(" ")}>
                  <select
                    onChange={(event) => this.props.updateModel("benefits_period", event.target.value)}
                    value={model.benefits_period}
                  >
                    {periods.map((period, index) => (
                      <option key={index} value={period.value}>
                        {period.name}
                      </option>
                    ))}
                  </select>
                </div>
              </ControlGroup>
            </FormGroup>

            <PayrollModelArray
              array={model.benefits_changes}
              compensationType={"benefits_changes"}
              forecastEndDate={forecastEndDate}
              fromMonth={hireDate}
              terminationDate={terminationDate}
              toMonth={toMonth}
              updateModelArray={this.props.updateModelArray}
            />

          </div>
          <div className={`${styles.payrollGridCol}`}>
            <FormGroup
              className={`${styles.payrollFormGroup}`}
              label="Termination Date"
              labelFor="termination-date"
            >
              <DateInput
                formatDate={this.formatDate}
                parseDate={this.parseDate}
                maxDate={forecastEndDate}
                minDate={hireDate}
                onChange={(newDate) => this.props.updateModel("termination_date", (newDate) ? dayjs(newDate).format("YYYY-MM-DD") : null)}
                placeholder={"mm/dd/yyyy"}
                popoverProps={{ position: Position.BOTTOM }}
                showActionsBar
                value={terminationDatePicker}
              />
            </FormGroup>

            <FormGroup
              className={`${styles.payrollFormGroup}`}
              label="Payroll Taxes"
              labelFor="payroll-tax"
            >
              {this.state.manualTax ? (
                <ControlGroup fill vertical={false}>
                  <InputGroup
                    autoComplete="off"
                    fill
                    id="payroll-tax"
                    onBlur={this.updateTaxManual("blur")}
                    onChange={this.updateTaxManual("focus")}
                    onFocus={this.highlightAll}
                    placeholder="Payroll Tax"
                    value={model.payroll_tax}
                  />
                  <Tag
                    icon="percentage"
                    minimal
                  />
                </ControlGroup>
              ) : (
                <div className={[BP.HTML_SELECT, BP.FILL].join(" ")}>
                  <select
                    onChange={this.updateTax}
                    onLoad={this.updateTax}
                  >
                    {this.props.taxRates.map((taxRate) => (
                      <option key={taxRate.id} value={taxRate.id}>
                        {taxRate.name} - {taxRate.value}%
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <Checkbox
                disabled={!this.props.taxRates || this.props.taxRates.length === 0}
                checked={this.state.manualTax}
                label="Manual"
                name="manualTax"
                onChange={this.toggleManual}
              />
            </FormGroup>

            <PayrollModelArray
              array={model.single_payments}
              compensationType={"single_payments"}
              forecastEndDate={forecastEndDate}
              fromMonth={fromMonth}
              terminationDate={terminationDate}
              toMonth={toMonth}
              updateModelArray={this.props.updateModelArray}
            />
          </div>
        </div>

      </div>
    );
  }
}

export default CompanySettingsPayrollModel;
