import React from "react";
import PropTypes from "prop-types";

import _, { cloneDeep } from "lodash";
import Iframe from "react-iframe";

import { Classes } from "@blueprintjs/core";
import Card from "components/Card";

import { confirm } from "components/Confirmation";

import TableHeader from "./TableHeader";
import ActualTable from "./ActualTable";
import ForecastSidebar from "views/forecast-sidebar";

import { getModelsCacheForAccount } from "shared/utilities/model-utilities";
import { getEntriesCacheForAccount } from "shared/utilities/entry-utilities";

import styles from "./styles.module.scss";

export default class AccountsTable extends React.Component {
  static propTypes = {
    onVerticalScrollbarChange: PropTypes.func.isRequired,
    resolvedTemplate: PropTypes.object.isRequired,
    selectedScenario: PropTypes.object.isRequired,
    setSidebarOpen: PropTypes.func.isRequired,
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    extended: PropTypes.bool,
  }

  state = {
    accountsTableDivRef: null,
    activeAccount: null,
    activeEntriesCache: null,
    activeTemplateSection: null,
    mounted: false,
    stickyHeader: false,
  }

  componentDidMount() {
    setTimeout(() => this.setState({ mounted: true }), 50);
  }

  accountsTableDivDidMount = (node) => {
    if (node) {
      const stateMutation = { accountsTableDivRef: node };
      node.addEventListener("scroll", this.handleScroll);
      // This means we have a vertical scrollbar
      if (node.firstChild.offsetHeight > node.offsetHeight) {
        this.props.onVerticalScrollbarChange(true);
      }
      this.setState(stateMutation);
    } else if (this.state.accountsTableDivRef) {
      this.setState({ accountsTableDivRef: null });
    }
  }

  handleScroll = () => {
    const { accountsTableDivRef: node } = this.state;
    if (node.scrollTop > 1 && !this.state.stickyHeader) {
      this.setState({ stickyHeader: true });
    }
    if (node.scrollTop <= 1 && this.state.stickyHeader) {
      this.setState({ stickyHeader: false });
    }
  }

  handleEditAccount = (accountToEdit, templateSection = null) => async () => {

    const changeAccount = () => {
      this.setState({
        activeAccount: (accountToEdit) ? cloneDeep(accountToEdit) : {},
        activeEntriesCache: cloneDeep(getEntriesCacheForAccount(accountToEdit)),
        activeTemplateSection: templateSection,
      });
      this.props.setSidebarOpen(true);
    }

    if (!this.state.activeAccount) {
      changeAccount();
    } else if (accountToEdit.id !== this.state.activeAccount.id) {
      const eql = _.isEqual(this.state.activeEntriesCache, getEntriesCacheForAccount(this.state.activeAccount));
      if (!eql && !await confirm('Are you sure?', <>There are unsaved entries in <strong>{this.state.activeAccount?.name || 'this account'}</strong>, switch accounts anyways?</>)) {
        return
      }
      changeAccount();
    }
  }

  changeGoogleSheet = (iframe) => {
    this.setState({ iframe });
  }

  handleClose = () => {
    this.setState({
      activeTemplateSection: null,
      activeAccount: null,
    });
    this.props.setSidebarOpen(false);
  }

  render() {
    const { resolvedTemplate, selectedScenario, year, extended, collapseAll } = this.props;
    const { activeAccount, iframe, mounted } = this.state;

    let activeModelType = null;
    //let activeModel = null;
    if (activeAccount) {
      const models = getModelsCacheForAccount(activeAccount);
      if (models?.[selectedScenario.id]) {
        for (const type of Object.keys(models[selectedScenario.id])) {
          if (models[selectedScenario.id][type]?.active) {
            activeModelType = type;
            //activeModel = models[selectedScenario.id][type];
          }
        }
      }
    }

    return (
      <>
        <div className={styles.overflowWrapper} ref={this.accountsTableDivDidMount}>
          {(activeModelType === "google") ? (
            <Iframe
              className={styles.iframe}
              position="inherited"
              url={iframe}
            />
          ) : (
            <div className={styles.mainView}>
              <TableHeader selectedScenario={selectedScenario} sticky={this.state.stickyHeader} year={year} extended={extended} />
              <Card className={styles.tableContainer}>
                {!resolvedTemplate ? (
                  <h3 className={Classes.HEADING}>Accounting is not connected</h3>
                ) : (
                  <ActualTable
                    activeAccount={activeAccount}
                    entriesCacheToEdit={this.state.activeEntriesCache}
                    onEditAccount={this.handleEditAccount}
                    resolvedTemplate={resolvedTemplate}
                    scenario={selectedScenario}
                    stickyHeaderVisible={this.state.stickyHeader}
                    year={year}
                    extended={extended}
                    collapseAll={collapseAll}
                  />
                )}
              </Card>
            </div>
          )}
        </div>
        {mounted ? (
          <ForecastSidebar
            account={activeAccount}
            changeGoogleSheet={this.changeGoogleSheet}
            onClose={this.handleClose}
            scenario={selectedScenario.id}
            templateSection={this.state.activeTemplateSection}
          />
        ) : null}
      </>
    );
  }
}
