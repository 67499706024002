import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

function RoundCharacter({children}) {
  const classes = [styles.main];
  return (
    <span className={classes.join(" ")}>
      {children}
    </span>
  );
}

RoundCharacter.propTypes = {
  children: PropTypes.node,
};

export default RoundCharacter;
