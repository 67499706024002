import {pick} from "lodash";

import {reportPeriods} from "../shared/fields/reportPeriod";
import {columnTypes} from "../shared/fields/displayColumns";
import {tableTypes} from "./fields/type";
import {createInitialDateRange} from "shared/utilities/date-utilities";
import {formatValue} from "shared/utilities/forecast-utilities";

const properties = {
  table: [
    "id",
    "name",
    "type",
    "report_period",
    "column_type",
    "daterange",
    "scenario_id",
    "options",
  ],
  row: [
    "id",
    "name",
    "account_id",
    "reference_snapshot_id",
    "formatting",
  ],
  column: [
    "id",
    "name",
    "formatting",
    "reference_snapshot_id",
    "daterange",
    "scenario_id",
    "report_period",
  ],
};

export function cleanTableData(operation, table) {
  const tableData = pick(table, properties.table);
  const rowsColumns = {
    rows: [],
    columns: [],
  };

  for(const type of ["row", "column"]) {
    const pluralType = `${type}s`;

    if(!table[pluralType]) continue;

    for(const item of table[pluralType]) {
      const cleanedItem = pick(item, properties[type]);
      if(operation === "create") delete cleanedItem.id;
      rowsColumns[pluralType].push(cleanedItem);
    }
  }

  return {
    ...tableData,
    ...rowsColumns,
  };
}

export const getDefaultData = (type) => ({
  account,
  forecastStartDate,
  indentation,
  index,
  scenarioId,
}) => {
  switch(type) {
    case "table": {
      return {
        column_type: columnTypes[0].value,
        daterange: {dates: createInitialDateRange(forecastStartDate, reportPeriods[3].value), monthsAgo: 3, monthsAhead: 12},
        name: "",
        report_period: reportPeriods[0].value,
        scenario_id: scenarioId,
        type: tableTypes[0].value,
        accounts: [],
        options: {},
      };
    }

    case "row": {
      return {
        name: account.name,
        account_id: account.id,
        formatting: {
          indentation: indentation !== null ? indentation : undefined,
        },
        reference_snapshot_id: null,
      };
    }

    case "column": {
      return {
        daterange: {dates: createInitialDateRange(forecastStartDate, reportPeriods[3].value), monthsAgo: 3, monthsAhead: 12},
        formatting: {},
        name: `Column ${index}`,
        scenario_id: scenarioId,
        reference_snapshot_id: null,
        report_period: reportPeriods[0].value,
      };
    }

    default: {
      return {};
    }
  }
};

export function getAllAccountIdsFromRow({table, row, columns, snapshots}) {
  const accountIdsInRow = [];
  if(table.type === "ADVANCED") {
    for(const column of columns) {
      if(column.reference_snapshot_id) {
        let account_id = row.account_id;
        if(row.reference_snapshot_id) {
          const matchingRowSnapshot = snapshots.find((snapshot) => snapshot.id === row.reference_snapshot_id);
          const matchingRowAccount = matchingRowSnapshot.accounts.find((account) => account.id === row.account_id);
          account_id = matchingRowAccount.reference_id;
        }
        const matchingSnapshot = snapshots.find((snapshot) => snapshot.id === column.reference_snapshot_id);
        const matchingAccount = (matchingSnapshot) ? matchingSnapshot.accounts.find((account) => account.reference_id === account_id) : null;
        if(matchingAccount?.id) accountIdsInRow.push(matchingAccount.id);
        if(row.formatting.pctOfRevenue) {
          const revenueAccountInSnapshot = matchingSnapshot.accounts.find((account) => account.reference_id === "1"); // Revenue account for that snapshot
          accountIdsInRow.push(revenueAccountInSnapshot.id);
        }
      } else {
        accountIdsInRow.push(row.account_id);
        if(row.formatting.pctOfRevenue) accountIdsInRow.push("1");
      }
    }
  } else {
    accountIdsInRow.push(row.account_id);
    if(row.reference_snapshot_id && row.formatting.pctOfRevenue) {
      const matchingRowSnapshot = snapshots.find((snapshot) => snapshot.id === row.reference_snapshot_id);
      const revenueAccountInSnapshot = matchingRowSnapshot.accounts.find((account) => account.reference_id === "1"); // Revenue account for that snapshot
      accountIdsInRow.push(revenueAccountInSnapshot.id);
    }
  }
  const uniqueAccountIds = [...new Set(accountIdsInRow)];

  return uniqueAccountIds;
}

export function getFormattedTableCellValue(value, {
  decimals,
  deltaType = null,
  currencySymbol,
  percentage,
  emptyRow,
  pctOfRevenue,
}) {
  if(isNaN(value) || value === Infinity) value = null;

  if(
    emptyRow ||
    ((deltaType === "percentage" || percentage) && !value) ||
    (pctOfRevenue && !!deltaType)
  ) {
    return "";
  }

  let str = "";

  if((percentage || deltaType === "deltaType") && value !== null && typeof value !== "undefined") {
    str += `${(value * 100).toFixed(decimals > 0 ? decimals : 0)}%`;
  } else {
    str += formatValue({
      value,
      currencySymbol: currencySymbol && value ? currencySymbol : null,
      isPercentage: false,
      decimals,
      noZero: true,
    });
  }

  return str;
}
