import slugify from "slugify";
import memoize from "memoizee";

import {RowType} from "../utilities";
import {ModelPeriod} from "../utilities";

const disallowedVarNames = ["bind"];

function findRowsMatchingSlug({worksheet, slugNumber, slug, currentRowId}) {
  return worksheet.rows.find((row) => row.id !== currentRowId && row.slug === (slugNumber ? `${slug}_${slugNumber}` : slug));
}

export function getSlug({worksheet, accountName, currentRowId}) {
  if(accountName === "") accountName = "empty_name";
  let slug = slugify(accountName.substring(0, 100).replace(/&/g, ""), {
    lower: true,
    replacement: "_",
    strict: true,
  }).replace("__", "_"); // Limit the number of characters for account name to 100 (generated slug might be slightly more or less once generated)
  if(["true", "false"].includes(slug) || disallowedVarNames.includes(slug) || !isNaN(slug[0])) slug = `_${slug}`; // We don't want true or false as slug names, and we don't want slug to start with a number
  let slugNumber = 0;
  while(findRowsMatchingSlug({worksheet, slug, slugNumber, currentRowId})) {
    slugNumber++;
  }
  if(slugNumber) {
    if(slug[slug.length - 1] !== "_") slug += "_";
    slug += slugNumber;
  }

  return slug;
}

export function getModelInSidebar({row, scenarioId, tab, lookForInactive = false}) {
  let modelIndex = null;
  //console.log(row.models.map((model) => model.formula), scenarioId, tab, lookForInactive = false);
  if(tab === "forecast") {
    modelIndex = row.models.findIndex((item) => {
      if(!lookForInactive && !item.active) return false; // lookForInactive will usually be false (we want active models)
      if([ModelPeriod.ALL, ModelPeriod.FORECASTS].includes(item.period) && !item.scenario_id) return true;
      if(item.period === ModelPeriod.FORECASTS && item.scenario_id === scenarioId) return true;
      return false;
    });
  } else {
    modelIndex = row.models.findIndex((item) => {
      if(!lookForInactive && !item.active) return false;
      if(item.period === ModelPeriod.ACTUALS) return true;
      return false;
    });
  }
  const model = row.models[modelIndex];

  return {model, modelIndex};
}

export const AppliesToOptions = {
  FORECAST: "FORECAST",
  CURRENT_SCENARIO: "CURRENT_SCENARIO",
  ALL: "ALL",
};

export function getCurrentModelAppliesToValue({scenarioId, period}) {
  if(period === ModelPeriod.FORECASTS) {
    if(scenarioId) {
      return AppliesToOptions.CURRENT_SCENARIO;
    } else {
      return AppliesToOptions.FORECAST;
    }
  } else {
    return AppliesToOptions.ALL;
  }
}

export function clearEntriesCacheForPeriod({entriesCache, appliesTo, period, scenarioId}) {
  const updatedEntriesCache = {...entriesCache};
  if(period === ModelPeriod.ACTUALS || period === ModelPeriod.ALL) {
    updatedEntriesCache["null"] = {};
  }
  if(period === ModelPeriod.ALL || period === ModelPeriod.FORECASTS) {
    if(appliesTo === AppliesToOptions.CURRENT_SCENARIO) {
      updatedEntriesCache[scenarioId.toString()] = {};
    } else {
      const scenarioKeys = Object.keys(entriesCache).filter((key) => key !== "null");
      for(const scenarioKey of scenarioKeys) {
        updatedEntriesCache[scenarioKey] = {};
      }
    }
  }
  return updatedEntriesCache;
}

export function updateOtherModelsIfNecessary(worksheet, row, fieldName, value, newSlug) {
  const updatedModels = [];
  let j = -1;
  for(const worksheetRow of worksheet.rows) {
    j++;
    // If it's not a metric, or it's the current row, there's nothing to do
    if(worksheetRow.type !== RowType.METRIC) continue;
    // For each model on the row that we're checking
    for(let i = 0; i < worksheetRow.models.length; i++) {
      const model = worksheetRow.models[i];
      // If there's no formula, do nothing
      if(!model.variables || !Object.keys(model.variables).length) continue;

      // Get the list of the slugs referenced by that model based on the variables
      const varNamesWithSlugs = Object.keys(model.variables).map((varName) => ({varName, slug: varName.split("__")[0]}));

      for(const {varName, slug} of varNamesWithSlugs) {
        // If the slug matches
        if(slug === row.slug) {
          switch(fieldName) {
            case "useParentValues": {
              let modelToBeUpdated = updatedModels.find((item) => item.modelIndex === i && item.rowIndex === j)?.model;
              if(!modelToBeUpdated) {
                modelToBeUpdated = {...model, variables: {...model.variables}};
                updatedModels.push({rowIndex: j, modelIndex: i, model: modelToBeUpdated});
              }

              modelToBeUpdated.variables[varName] = {...modelToBeUpdated.variables[varName]};
              if(value) {
                modelToBeUpdated.variables[varName].formulaType = "mixed";
              } else if(modelToBeUpdated.variables[varName].formulaType) {
                delete modelToBeUpdated.variables[varName].formulaType;
              }
              break;
            }
            case "account_id":
            case "name": {
              const oldSuffix = varName.split("__")[1];
              let newVarName = `${newSlug}`;
              if(oldSuffix) newVarName += `__${oldSuffix}`;
              // Find out if this model already has uncommitted changes. If so, use it, if not, create one in the list of models to be updated
              let modelToBeUpdated = updatedModels.find((item) => item.modelIndex === i && item.rowIndex === j)?.model;
              if(!modelToBeUpdated) {
                modelToBeUpdated = {...model, variables: {...model.variables}};
                updatedModels.push({rowIndex: j, modelIndex: i, model: modelToBeUpdated});
              }

              // Update formula and variables
              modelToBeUpdated.variables[newVarName] = {...modelToBeUpdated.variables[varName]};
              if(modelToBeUpdated.varNameToSlugMapping) modelToBeUpdated.varNameToSlugMapping[newVarName] = newSlug;
              modelToBeUpdated.formula = modelToBeUpdated.formula.replace(new RegExp(`\\b(?:${varName})\\b`, "g"), newVarName);
              // Delete old variable
              if(newVarName !== varName) delete modelToBeUpdated.variables[varName];
              // Update the account_id in the variables
              if(fieldName === "account_id") {
                modelToBeUpdated.variables[newVarName].id = value;
              }

              break;
            }
            default: {}
          }
        }
      }
    }
  }

  return updatedModels;
}

export const isActualsTabEnabled = memoize((models) => !!(models.find((model) => model.period === ModelPeriod.ACTUALS && model.active !== false) || !models.find((model) => model.period === ModelPeriod.ALL)));
