import React, { useContext } from "react";
import { AppContext } from "../../../AppContext";
import PropTypes from "prop-types";

import { get } from "lodash";

import { graphql } from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Errors from "components/Errors";
import Button from "components/Button";
import Card from "components/Card";
import SettingsHeader from "components/SettingsHeader";

import { isValidGoogleSheetsUrl } from "../integrations/integrations-utilities";
import { updateTenant } from "../../../graphql";
import CompanySettingsColors from "./colors";

import styles from "./styles.module.scss";

const GOOGLE_SHEETS_URL_ERROR = "Please enter valid Google Sheets URLs that are of the form https://docs.google.com/spreadsheets/d/somesheetid";

const NUM_LINKS = 5;

const currencyOptions = [{
  name: "Dollar ($)",
  value: "dollar",
}, {
  name: "Pound sterling (£)",
  value: "poundSterling",
}, {
  name: "Euro (€)",
  value: "euro",
}];

class CompanySettingsCustomization extends React.Component {
  static propTypes = {
    updateTenant: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    let headerLinks = [];
    if (get(props, "user.tenant.options.headerLinks", null)) {
      headerLinks = props.user.tenant.options.headerLinks;
    }

    // verify the 3 links are present
    if (headerLinks.length < NUM_LINKS) {
      const diff = NUM_LINKS - headerLinks.length;
      for (let i = 0; i < diff; i++) {
        headerLinks.push({});
      }
    }

    this.state = {
      name: props.user.tenant.name,
      headerLinks,
      currency: props.user.tenant.options?.currency || "dollar",
      saving: false,
    };
  }

  saveTenant = () => {
    let areValidLinks = true;
    for (const link of this.state.headerLinks) {
      if (link.url && !isValidGoogleSheetsUrl(link.url)) areValidLinks = false;
    }
    if (areValidLinks) {
      this.setState({ saving: true });
      this.props.updateTenant({
        variables: {
          tenant: {
            name: this.state.name,
            options: {
              headerLinks: this.state.headerLinks,
              currency: this.state.currency,
            },
          },
        },
      }).then(() => {
        this.setState({ errors: [], saving: false });
        window.location.reload();
      }).catch((error) => {
        this.setState({
          errors: ["The name must be between 3-15 characters."],
          saving: false,
        });
        // eslint-disable-next-line
        console.log("There was an error sending the query", error);
      });
    } else {
      this.setState({ errors: [GOOGLE_SHEETS_URL_ERROR] });
    }
  }

  updateField = (fieldName, event) => {
    const state = {};
    state[fieldName] = event.target.value;

    this.setState(state);
  }

  updateHeaderLink = (index, fieldName) => (event) => {
    const headerLinks = this.state.headerLinks;
    const newHeaderLink = {
      ...headerLinks[index],
      [fieldName]: event.target.value,
    };

    const newHeaderLinks = [];
    for (let i = 0; i < headerLinks.length; i++) {
      newHeaderLinks.push((index === i) ? newHeaderLink : headerLinks[i]);
    }

    this.setState({ headerLinks: newHeaderLinks });
  }

  render() {
    return (
      <>
        <Errors messages={this.state.errors} />
        <Card className={styles.generalContainer}>
          <SettingsHeader
            subtitle
            text="General"
          />
          <div className="bp5-form-group">
            <h3 className="bp5-heading">Company Name</h3>
            <input
              autoComplete="off"
              className="bp5-input bp5-large"
              dir="auto"
              maxLength="255"
              onChange={(event) => this.updateField("name", event)}
              placeholder="Company Name"
              style={{ width: "350px" }}
              type="text"
              value={this.state.name}
            />
          </div>
          <div className="bp5-form-group">
            <h3 className="bp5-heading">Currency Symbol</h3>
            <div className="bp5-select bp5-large" style={{ width: "350px" }}>
              <select defaultValue={this.state.currency} onChange={(event) => this.updateField("currency", event)}>
                {currencyOptions.map(({ name, value }) => (
                  <option key={value} value={value}>{name}</option>
                ))}
              </select>
            </div>
          </div>
          {[...Array(NUM_LINKS)].map((e, i) => (
            <div className={styles.menuSheetContainer} key={i}>
              <h3 className="bp5-heading">Sidebar Bookmark {i + 1}</h3>
              <div className={styles.fields}>
                <input
                  autoComplete="off"
                  className="bp5-input bp5-large"
                  dir="auto"
                  onChange={this.updateHeaderLink(i, "title")}
                  placeholder="Title"
                  style={{ width: "350px", marginRight: "1em" }}
                  type="text"
                  value={this.state.headerLinks[i].title || ""}
                />
                <input
                  autoComplete="off"
                  className="bp5-input bp5-large"
                  dir="auto"
                  onChange={this.updateHeaderLink(i, "url")}
                  placeholder="URL"
                  style={{ width: "350px" }}
                  type="text"
                  value={this.state.headerLinks[i].url || ""}
                />
              </div>
            </div>
          ))}
          <Button
            disabled={this.state.saving}
            intent="success"
            onClick={this.saveTenant}
          >
            Save
          </Button>
        </Card>
        <CompanySettingsColors />
      </>
    );
  }
}

const CompanySettingsCustomizationWithData = compose(
  graphql(updateTenant, { name: "updateTenant" }),
)(CompanySettingsCustomization);

export default () => {
  const { user } = useContext(AppContext);
  return <CompanySettingsCustomizationWithData user={user} />;
}
