import React from "react";
import PropTypes from "prop-types";

import styles from "./TableBuilder.module.scss";

function EmptyTableMessage({elementClass, type, numCols, numRows}) {
  let message = "Add ";

  if(type === "ADVANCED" && !numCols && numRows) {
    message += "columns ";
  } else if((type === "ADVANCED" && numCols && !numRows) || type === "SIMPLE") {
    message += "rows ";
  } else if(type === "ADVANCED" && !numCols && !numRows) {
    message += "columns and rows ";
  }

  message += "to start building your table";

  return (
    <div className={elementClass}>
      <h3 className={styles.message}>{message}</h3>
    </div>
  );
}

EmptyTableMessage.propTypes = {
  elementClass: PropTypes.string,
  numCols: PropTypes.number.isRequired,
  numRows: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default EmptyTableMessage;
