import React from "react";
import PropTypes from "prop-types";

import Loading from "components/Loading";
import Table from "components/Table";

import ColorRow from "./ColorsRow";

import styles from "./styles.module.scss";

class CompanySettingsColorsTable extends React.Component {
  static propTypes = {
    colors: PropTypes.array,
  }

  render() {
    const {colors} = this.props;

    if(!colors) return <Loading />;

    return (
      <>
        {(colors.length) ? (
          <Table className={styles.colorsTable} hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Color</th>
              </tr>
            </thead>
            <tbody>
              {colors.map((color, index) => (
                <ColorRow
                  color={color}
                  key={index}
                  toggleRemoveModal={this.toggleRemoveModal}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <h4 className="bp5-heading">No available custom colors.</h4>
        )}

      </>
    );
  }
}

export default CompanySettingsColorsTable;
