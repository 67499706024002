let startTime, endTime, functionName;

export function start(name) {
  startTime = new Date();
  functionName = name;
};

export function end() {
  endTime = new Date();
  const timeDiff = endTime - startTime; //in ms
  // get seconds
  const miliSeconds = Math.round(timeDiff);
  const seconds = Math.round(timeDiff / 1000);
  console.log(`Server call: ${functionName} took ${seconds} seconds, and ${miliSeconds} miliseconds to return to client.`);
}
