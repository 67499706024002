export default {
  "profit-and-loss": {
    options: {
      uppercaseTopLevel: true,
    },
    sections: [{
      type: "accountSection",
      accountType: "Revenue",
    }, {
      type: "accountSection",
      accountType: "Cost of Revenue",
    }, {
      type: "account",
      accountType: "Gross Profit",
      detailType: "root",
    }, {
      type: "accountSection",
      accountType: "Expenses",
    }, {
      type: "account",
      accountType: "Net Operating Income",
      detailType: "root",
    }, {
      type: "accountSection",
      accountType: "Other Income",
    }, {
      type: "accountSection",
      accountType: "Other Expenses",
    }, {
      type: "account",
      accountType: "Net Other Income",
      detailType: "root",
    }, {
      type: "account",
      accountType: "Net Income",
      detailType: "root",
    }],
  },
  "balance-sheet": {
    options: {
      uppercaseTopLevel: true,
    },
    sections: [{
      type: "accountSection",
      accountType: "Assets",
    }, {
      type: "accountSection",
      accountType: "Liabilities & Equity",
    }],
  },
  "cash-flow": {
    options: {
      uppercaseTopLevel: true,
    },
    sections: [{
      type: "accountSection",
      accountType: "Operating Activities",
    }, {
      type: "accountSection",
      accountType: "Investing Activities",
    }, {
      type: "accountSection",
      accountType: "Financing Activities",
    }, {
      type: "account",
      accountType: "Net cash increase for period",
      detailType: "root",
    }],
  },
};
