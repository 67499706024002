import {FLIGHTPATH_REDUCED, FLIGHTPATH_ROOT} from "shared/utilities/types";
import {filterAccounts, findAccountById, findAccountByType} from "shared/utilities/account-utilities";

export const findAccountByIntegrationId = (accounts, integration_id, company_id) => accounts.find((account) => account.integration_id === integration_id && account.company_id === company_id);

export const createDetailTypesArray = (type) => {
  switch(type) {
    case ("Expenses"):
    case ("Cost of Revenue"):
      return ["None", "Payroll Account"];
    case ("Bank Accounts"):
      return ["None", "Bank Account"];
    default:
      return ["None"];
  }
};

export const getAllChildrenOfAccount = (accounts, referenceAccount, accountId, childrenArray, level = 0) => {
  const account = findAccountById(accounts, accountId);
  if(!account) return childrenArray;
  if(referenceAccount && account) {
    // Prevent adding an accounts own children to be options in parent select
    if(account.id === referenceAccount.id) return childrenArray;
  }
  if(account.type !== "Net Income") {
    childrenArray.push({...account, level});
  }
  if(account.children.length > 0) {
    // Process each child recursively
    for(let i = 0; i < account.children.length; i++) {
      getAllChildrenOfAccount(accounts, referenceAccount, account.children[i].id, childrenArray, level + 1);
    }
  }
  return childrenArray;
};

const isRootType = (statement_type, account) => {
  // TO:DO This is a temporary fix for BS Net Income type to not show up in COA
  if(account.detail_type === "BSNetIncome") return true;
  if(account.detail_type === "root") {
    if(!FLIGHTPATH_ROOT[`${statement_type}_TYPES`].find((type) => account.type === type)) return true;
  }
  return false;
};

const formatAccounts = (statement_type, accountId, accountingAccounts, accounts, formattedAccounts, displayIndentation) => {
  const account = findAccountById(accounts, accountId);
  if(account) {
    const formattedAccount = {
      id: account.id,
      name: account.name,
      displayIndentation,
      statement_type,
      integration_id: account.integration_id,
      parent: account.parent,
      detail_type: account.detail_type,
      children: account.children,
      type: account.type,
      description: account.description,
      negative: account.negative,
      ignore: account.ignore,
    };

    formattedAccount.account_type = (account.children?.length) ? "section" : "regular";
    if(account.integration_id) {
      const matchingAccountingAccount = findAccountByIntegrationId(accountingAccounts, account.integration_id, account.company_id);
      if(matchingAccountingAccount) formattedAccount.accounting_account = matchingAccountingAccount;
    }

    const isRoot = isRootType(statement_type, account);
    if(!isRoot) {
      formattedAccounts.push(formattedAccount);
      displayIndentation++;
    }
    // Process each child recursively
    for(const childAccount of account.children || []) {
      formatAccounts(statement_type, childAccount.id, accountingAccounts, accounts, formattedAccounts, displayIndentation);
    }
  } else {
    console.log(`Account for id ${accountId} not found`);
  }
  return formattedAccounts;
};

const formatStatement = (statement_type, accountingAccounts, accounts, types) => {
  let formattedAccounts = [];
  for(const type of types) {
    const sectionAccount = findAccountByType(accounts, type);
    if(sectionAccount) formattedAccounts = formatAccounts(statement_type, sectionAccount.id, accountingAccounts, accounts, formattedAccounts, 0);
  }
  return formattedAccounts;
};

const formatAccountingAccounts = (accounts, accountingAccounts) => {
  const formattedAccountingAccounts = [];
  // Go through each accounting account and if it has an integration_id, check if there is a matching FF account otherwise add to array
  for(const account of accountingAccounts) {
    if(account.integration_id && account.statement_type !== "CFS") {
      const matchingFFAccount = findAccountByIntegrationId(accounts, account.integration_id, account.company_id);
      if(!matchingFFAccount) formattedAccountingAccounts.push(account);
    }
  }
  return formattedAccountingAccounts;
};

export const reformatCOAAccounts = (accounts, accountingAccounts) => {
  // Order PNL then BS and order by types
  const formattedAccounts = {};
  const pnlAccounts = filterAccounts(accounts, "PNL"),
        bsAccounts = filterAccounts(accounts, "BS");
  const formattedPNLAccounts = formatStatement("PNL", accountingAccounts, pnlAccounts, FLIGHTPATH_REDUCED.PNL_TYPES);
  const formattedBSAccounts = formatStatement("BS", accountingAccounts, bsAccounts, FLIGHTPATH_REDUCED.BS_TYPES);
  const pnlAndBsAccounts = [...pnlAccounts, ...bsAccounts];
  const formattedAccountingAccounts = formatAccountingAccounts(pnlAndBsAccounts, accountingAccounts);
  formattedAccounts.formattedFFAccounts = formattedPNLAccounts.concat(formattedBSAccounts);
  formattedAccounts.formattedAccountingAccounts = formattedAccountingAccounts;
  return formattedAccounts;
};
