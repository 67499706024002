import {useContext} from "react";
import PropTypes from "prop-types";

import {useMutation} from "@apollo/client";

import CSVReader from "components/CSVReader";

import {INITIAL_LOAD_QUERY} from "../../../graphql";
import {UPDATE_CSV_COMPANY} from "./graphql";

import {CSV_ERROR} from "./index";

import {AppContext} from "../../../AppContext";

const csvOptions = {
  dynamicTyping: true,
  header: true,
  skipEmptyLines: "greedy",
};

export default function CSVButton({
  company,
  hideNewCompany,
  saving,
  setErrors,
  setSaving,
}) {
  const {addToast, removeToast} = useContext(AppContext);

  const [updateCSVCompany] = useMutation(UPDATE_CSV_COMPANY);

  const uploadCSV = (results) => {
    if(results?.errors?.length === 0) {
      addToast("refreshAccounting", {
        icon: "airplane",
        message: "Processing data from your accounting...",
        progress: 99,
      });

      setSaving(true);

      updateCSVCompany({
        variables: {id: company?.id ?? null, csv: results},
        refetchQueries: [{query: INITIAL_LOAD_QUERY}],
      }).then(() => {
        addToast("refreshAccounting", {
          icon: "airplane",
          message: "Done processing data from your accounting!",
          progress: 100,
        });
        hideNewCompany();
        setErrors([]);
        setSaving(false);
      }).catch((error) => {
        // eslint-disable-next-line
        console.log("There was an error processing your CSV file.", error);
        removeToast("refreshAccounting");
        setErrors([CSV_ERROR]);
        setSaving(false);
      });
    } else {
      setErrors([CSV_ERROR]);
    }
  };

  return (
    <div>
      <CSVReader
        configOptions={csvOptions}
        disabled={saving}
        intent="success"
        onFileLoaded={uploadCSV}
        width="200px"
      />
    </div>
  );
}

CSVButton.propTypes = {
  company: PropTypes.object,
  hideNewCompany: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  setErrors: PropTypes.func.isRequired,
  setSaving: PropTypes.func.isRequired,
};
