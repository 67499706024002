import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";

import {useMutation} from "@apollo/client";

import Switcher from "components/Switcher";

import {INITIAL_LOAD_QUERY} from "../../graphql";
import {UPDATE_ACCOUNTING, REFRESH_GOOGLE_SHEETS_MODELS} from "views/company-settings/integrations/graphql";

import {QUICKBOOKS_AUTH_ERROR} from "views/company-settings/integrations";

import {AppContext} from "../../AppContext";

export default function RefreshOptions({setErrors}) {
  const {addToast, removeToast} = useContext(AppContext);

  const [currentlyRefreshing, setCurrentlyRefreshing] = useState("disabled");

  // Use effect with array of dependencies parameter behaves like componentDidMount
  // If a function is returned in that handler, it will be run on unmount (behaves like componentWillUnmount)
  useEffect(() => () => {
    removeToast("refreshAccounting");
    removeToast("refreshGoogle");
  }, [removeToast]);

  const [refreshGoogleSheetsModelsMutation] = useMutation(REFRESH_GOOGLE_SHEETS_MODELS, {refetchQueries: [{query: INITIAL_LOAD_QUERY}]});
  const [updateAccountingMutation] = useMutation(UPDATE_ACCOUNTING, {refetchQueries: [{query: INITIAL_LOAD_QUERY}]});

  const refreshGoogleSheetsModels = () => {
    addToast("refreshGoogle", {
      icon: "airplane",
      message: "Pulling data from your Google Sheets...",
      progress: 99,
    });

    setCurrentlyRefreshing("google");
    refreshGoogleSheetsModelsMutation().then(() => {
      setCurrentlyRefreshing("disabled");

      addToast("refreshGoogle", {
        icon: "airplane",
        message: "Done pulling data from your Google Sheets!",
        progress: 100,
      });

      setErrors(null);
    }).catch((error) => {
      setCurrentlyRefreshing("disabled");
      removeToast("refreshGoogle");
      // eslint-disable-next-line
      console.log(error);
      setErrors(null);
    });
  };

  const refreshAccountingReport = () => {
    setCurrentlyRefreshing("accounting");

    addToast("refreshAccounting", {
      icon: "airplane",
      message: "Pulling fresh data from your accounting...",
      progress: 99,
    });

    updateAccountingMutation().then(() => {
      setCurrentlyRefreshing("disabled");
      addToast("refreshAccounting", {
        icon: "airplane",
        message: "Done pulling fresh data from your accounting!",
        progress: 100,
      });
      setErrors(null);
    }).catch((error) => {
      setCurrentlyRefreshing("disabled");
      // eslint-disable-next-line
      console.log("There was an error pulling from accounting.", error);
      removeToast("refreshAccounting");
      if(error.message.includes("DISCONNECTED")) setErrors([QUICKBOOKS_AUTH_ERROR]);
    });
  };

  const handleChangeRefreshSelect = ({target: {value: type}}) => {
    if(type === "google") {
      refreshGoogleSheetsModels();
    } else if(type === "accounting") {
      refreshAccountingReport();
    } else if(type === "flightpath") {
      reloadDashboard();
    }
  };

  return (
    <Switcher
      disabled={currentlyRefreshing !== "disabled"}
      onChange={handleChangeRefreshSelect}
      options={[
        {name: `Refresh Options...`, value: `disabled`},
        {name: `Refresh Flightpath`, value: `flightpath`},
        {name: `Refresh${currentlyRefreshing !== "disabled" ? "ing" : ""} Google Sheets`, value: `google`},
        {name: `Refresh${currentlyRefreshing !== "disabled" ? "ing" : ""} Accounting`, value: `accounting`},
      ]}
      selectedValue={currentlyRefreshing}
    />
  );
}

RefreshOptions.propTypes = {
  setErrors: PropTypes.func.isRequired,
};

function reloadDashboard() {
  window.location.reload();
}
