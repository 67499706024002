import React from "react";
import { createRoot } from "react-dom/client";

import PropTypes from "prop-types";

import { Classes as BP, Intent, Dialog } from "@blueprintjs/core";

import Button from "components/Button";

export default class Confirmation extends React.Component {
  static propTypes = {
    cancelButtonText: PropTypes.string,
    children: PropTypes.node.isRequired,
    confirmButtonText: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    operationActive: PropTypes.bool,
    title: PropTypes.string,
  }

  state = {}

  handleConfirm = () => {
    this.props.onConfirm();
  }

  handleCancel = () => {
    this.props.onCancel();
  }

  render() {
    const { cancelButtonText, children, confirmButtonText, isOpen, operationActive, title } = this.props;
    const buttonActive = (operationActive) ? operationActive : false;

    return (
      <Dialog
        canOutsideClickClose={false}
        className={BP.LARGE}
        icon="confirm"
        isOpen={isOpen}
        onClose={this.handleCancel}
        title={(title) ? title : "Confirmation"}
      >
        <div className={BP.DIALOG_BODY}>
          {children}
        </div>
        <div className={BP.DIALOG_FOOTER}>
          <div className={BP.DIALOG_FOOTER_ACTIONS}>
            <Button disabled={buttonActive} onClick={this.handleCancel} text={(cancelButtonText) ? cancelButtonText : "Cancel"} />
            <Button
              disabled={buttonActive}
              intent={Intent.SUCCESS}
              onClick={this.handleConfirm}
              text={(confirmButtonText) ? confirmButtonText : "Confirm"}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export function confirm(title, message) {
  const confirmRoot = document.createElement('div');
  document.body.append(confirmRoot);

  const root = createRoot(confirmRoot)

  return new Promise(res => {

    const handleConfirm = () => {
      root.unmount();
      res(true);
    }

    const handleCancel = () => {
      root.unmount();
      res(false);
    }

    root.render(
      <Confirmation
        title={title}
        isOpen={true}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        children={message} />
    );
  })
}
