import {useReactiveVar} from "@apollo/client";

import {flightpathAccountsByIdVar} from "apollo-config/local-state/accounts";

export function useAllFlightpathAccounts() {
  const accountsVar = useReactiveVar(flightpathAccountsByIdVar);
  const accounts = Object.values(accountsVar);

  return accounts;
}
