import React, { useContext, useEffect, useRef } from "react";

import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import PropTypes from "prop-types";

import { Icon } from "@blueprintjs/core";

import RowErrorBoundary from "./RowErrorBoundary";
import RowMetricValues from "./RowMetricValues";
import EmptyRow from "./EmptyRow";
import RowReferenceValues from "./RowReferenceValues";

import { RowType } from "./utilities";
import { getAccountFromCache } from "shared/utilities/account-utilities";
import { actionTypes } from "./reducer";

import { AppContext } from "../../../AppContext";

import styles from "./styles.module.scss";
import formattingStyles from "components/FormattingOptions/formattingStyles.module.scss";

export default function Row(props) {
  const { active, autocompleteHighlighted, row, rowIndex } = props;
  const { user } = useContext(AppContext);

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: "ROW",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { rowIndex, rowName: row?.name },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult && !(item.rowIndex === dropResult.rowIndex)) {
        props.onMoveRow(item.rowIndex, dropResult.rowIndex + 1);
      }
    },
  }), [rowIndex]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "ROW",
    drop: () => ({ rowIndex, rowName: props.row?.name }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), [rowIndex]);

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const handleDeleteRow = (evt) => {
    props.dispatch({ type: actionTypes.deleteRow, payload: { row } });
    evt.stopPropagation();
  };

  const account = row.account_id ? getAccountFromCache(row.account_id) : null;

  const formatting = row.formatting || {};

  const rowClasses = [];

  if (active) rowClasses.push(styles.active);
  if (row.type !== RowType.EMPTY && autocompleteHighlighted) rowClasses.push(styles.autocompleteHighlighted);
  if (row.formulaValid === false && !active) rowClasses.push(styles.formulaInvalid);
  if (formatting.bold) rowClasses.push(formattingStyles.bold);
  if (formatting.borderTop) rowClasses.push(formattingStyles.borderTop);
  if (formatting.percentage) rowClasses.push(formattingStyles.percentage);
  if (formatting.dollar) rowClasses.push(formattingStyles.dollar);
  if (formatting.indentation > 0) rowClasses.push(formattingStyles[`indent${formatting.indentation}`]);
  if (row.type === RowType.EMPTY) rowClasses.push(styles.emptyRow);
  if (user.role === "VIEW") rowClasses.push(styles.noEdit);

  if (isOver && !isDragging) rowClasses.push(styles.isOver);
  if (isDragging) rowClasses.push(styles.isDragging);

  const deleteRowButton = (
    <div className={styles.deleteRowButtonWrapper} onClick={handleDeleteRow}>
      <div className={styles.icons}>
        <div className={styles.icon}>
          <Icon icon="delete" />
        </div>
      </div>
    </div>
  );

  return (
    <tr className={rowClasses.join(" ")} ref={drop}>
      <td onClick={props.onCellClick(props.row, true)}>
        <div className={styles.moveRowButtons}>
          <Icon icon={canDrop ? "locate" : "drag-handle-vertical"} ref={drag} />
        </div>
        {false && row.formulaValid === false ? (
          <div className={styles.formulaInvalidWarningWrapper}>
            <Icon className={styles.formulaInvalidWarningIcon} icon="warning-sign" iconSize={17} />
          </div>
        ) : null}
        <div className={styles.cellContent}>
          {row.name}
          {row.type === RowType.METRIC ? <Icon className={styles.metricIcon} icon="link" iconSize={12} /> : null}
        </div>
      </td>
      <RowErrorBoundary formulas={props.worksheet.rows.filter((worksheetRow) => worksheetRow.models?.length).map((worksheetRow) => worksheetRow.models.map((model) => model.formula).join("::")).join("::")} onClick={props.onCellClick(props.row, true)}>
        {row.type === RowType.REFERENCE ? (
          <RowReferenceValues
            account={account}
            deleteRowButton={deleteRowButton}
            {...props}
          />
        ) : row.type === RowType.METRIC ? (
          <RowMetricValues
            account={account}
            deleteRowButton={deleteRowButton}
            {...props}
          />
        ) : <EmptyRow onClick={props.onCellClick(props.row, true)} year={props.year} />}
      </RowErrorBoundary>
    </tr>
  );
}

Row.propTypes = {
  active: PropTypes.bool.isRequired,
  autocompleteHighlighted: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  highlightedCells: PropTypes.object.isRequired,
  onCellClick: PropTypes.func.isRequired,
  onMoveRow: PropTypes.func.isRequired,
  onUpdateHighlightedCells: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  scenarioId: PropTypes.string.isRequired,
  worksheet: PropTypes.object.isRequired,
  year: PropTypes.string.isRequired,
};
