import React, {useContext} from "react";
import PropTypes from "prop-types";

import slugify from "slugify";

import {useParams} from "react-router-dom";

import TextInput from "components/TextInput";

import {AppContext} from "../../../AppContext";

import styles from "./Properties.module.scss";

export default function WorksheetProperties({onChange, worksheet}) {
  const {slug} = useParams();
  const {user} = useContext(AppContext);

  const onChangeLocal = ({target: {name, value}}) => {
    let newSlug = null;
    if(!slug && name === "name") newSlug = slugify(value.toLowerCase(), "_");
    onChange({
      [name]: value,
      slug: newSlug || worksheet.slug,
    });
  };

  return (
    <form autoComplete="off" className={styles.main}>
      <div className={styles.formRow}>
        <TextInput
          disabled={user.role === "VIEW"}
          formGroupClass={styles.formGroup}
          label="Worksheet Name"
          name="name"
          onChange={onChangeLocal}
          value={worksheet.name}
        />
      </div>
    </form>
  );
}

WorksheetProperties.propTypes = {
  onChange: PropTypes.func.isRequired,
  worksheet: PropTypes.object.isRequired,
};
