import {pick} from "lodash";

const chartProperties = [
  "column_type",
  "daterange",
  "id",
  "name",
  "options",
  "report_period",
  "type",
];

const lineProperties = [
  "account_id",
  "formatting",
  "color_id",
  "id",
  "name",
  "reference_snapshot_id",
  "scenario_id",
  "type",
];

export function cleanChartData(chart) {
  const chartData = pick(chart, chartProperties);
  const lines = [];
  for(const line of chart.lines) {
    if(typeof line.type === "undefined") line.type = null;
    lines.push(pick(line, lineProperties));
  }

  return {
    ...chartData,
    lines,
  };
}
