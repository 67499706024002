import React from "react";
import PropTypes from "prop-types";

import numeral from "numeral";
import {filter} from "lodash";

import {Callout, ControlGroup, FormGroup, InputGroup, Intent, Tag, Classes as BP} from "@blueprintjs/core";
import FancySelect from "components/FancySelect";

import {createMonthsAvgDisplay} from "shared/utilities/auto-pilot-utilities";
import {revenuePercentTypes, weightedRevenueTypes} from "shared/utilities/types";

import {AppContext} from "../../AppContext";

import styles from "./ForecastSidebar.module.scss";

class Autopilot extends React.Component {
  static propTypes = {
    account: PropTypes.object,
    accounts: PropTypes.array,
    changeModelField: PropTypes.func.isRequired,
    entriesCache: PropTypes.object.isRequired,
    errors: PropTypes.array,
    models: PropTypes.object,
    revenueAccounts: PropTypes.array,
    revenueMRRAccounts: PropTypes.array,
    scenario: PropTypes.string,
    scenarioFormatted: PropTypes.string,
    scenarios: PropTypes.array,
    templateSection: PropTypes.object,
  }

  constructor(props) {
    super(props);
    const {models, scenarioFormatted} = this.props;
    const model = models?.[scenarioFormatted]?.autopilot ?? {options: {}};
    this.state = {
      modelId: model.id,
      autopilotType: model.options.autopilotType,
      growAmount: model.options.growAmount,
      percentile: model.options.percentile,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {models, scenarioFormatted} = nextProps;
    const model = models?.[scenarioFormatted]?.autopilot ?? {options: {}};
    if(model && (model.id !== prevState.modelId || model.options.autopilotType !== prevState.autopilotType)) {
      return {growAmount: model.options.growAmount, modelId: model.id, autopilotType: model.options.autopilotType, percentile: model.options.percentile};
    } else return prevState;
  }

  static contextType = AppContext;

  editInputAmount = (modelField, value, eventType) => {
    if(eventType === "blur" && !numeral(value).value()) value = 0;
    if(isNaN(value) && value !== "-" && value !== ".") {
      value = "";
    } else {
      this.setState({
        [modelField]: value,
      });
    }

    if(numeral(value).value() || numeral(value).value() === 0) {
      this.props.changeModelField("autopilot", modelField, "change", value);
    }
  }

  highlightAll = () => {
    setTimeout(() => {
      document.execCommand("selectAll", false, null);
    }, 0);
  }

  render() {
    const {account, accounts, changeModelField, entriesCache, models, revenueAccounts, revenueMRRAccounts, scenario, scenarios, scenarioFormatted, templateSection} = this.props;
    const {forecastStartDate, user} = this.context;

    const isWeightedRevenueType = (account?.type) ? weightedRevenueTypes.includes(account.type) : false;
    const isRevenuePercentType = (account?.type) ? revenuePercentTypes.includes(account.type) : false;
    const clientHasRevenueModel = (revenueMRRAccounts?.length > 1);
    const model = models?.[scenarioFormatted]?.autopilot ?? {options: {}};
    const metricAccounts = filter(accounts, (account) => account.statement_type === "MET");
    /*Live calculate entries and monthly avg for display*/
    const monthsAvg = account ? createMonthsAvgDisplay(user, accounts, entriesCache, revenueAccounts, account, scenarios, scenario, forecastStartDate, model, templateSection) : 0;
    const notGrowShrink = model?.options?.autopilotType !== "GROW_SHRINK" && model?.options?.autopilotType !== "WEIGHTED_GROW_SHRINK";
    return (
      <div>
        {(this.props.errors?.length) ? (
          <Callout intent={Intent.WARNING} style={{marginBottom: "1em"}}>
            {this.props.errors.map((e) => `${e} `)}
          </Callout>
        ) : null}
        <FormGroup
          className={`m-t ${styles.sidebarFormGroup}`}
          label="Autopilot Type"
          labelFor="autopilot-type"
        >
          <div className={`${BP.HTML_SELECT} ${BP.MINIMAL} ${styles.sidebarSelect}`}>
            <select
              onChange={(event) => changeModelField("autopilot", "autopilotType", "event", event)}
              value={model.options.autopilotType}
            >
              {(isWeightedRevenueType) ? <option value={"WEIGHTED_AVERAGE"}>Weighted Average</option> : null}
              <option value={"AVERAGE"}>Average</option>
              {(isWeightedRevenueType) ? <option value={"WEIGHTED_GROW_SHRINK"}>Weighted Average + Grow</option> : null}
              <option value={"GROW_SHRINK"}>Average + Grow</option>
              {(account?.type !== "Revenue" && account?.type !== "Bank Accounts" && account?.statement_type !== "REV") ? <option value={"PERCENT_OF_REVENUE"}>% of Revenue</option> : null}
              {(user?.super && (account?.type === "Revenue" || account?.type === "Other Income") && clientHasRevenueModel) ? <option value={"REVENUE_FORECAST"}>Revenue Forecast</option> : null}
              <option value={"METRIC_FORECAST"}>Worksheet</option>
              <option value={"PERCENTILE"}>Percentile</option>
            </select>
          </div>
        </FormGroup>

        {(model.options.autopilotType === "REVENUE_FORECAST" && clientHasRevenueModel) ? (
          <FormGroup
            className={`m-t ${styles.sidebarFormGroup}`}
            label="Select Line Item"
            labelFor="revenue-forecast"
          >
            <FancySelect
              fill
              items={revenueMRRAccounts.map((a) => ({key: a.id, text: a.display_name}))}
              onSelect={(item) => changeModelField("autopilot", "target_id", "change", item.key)}
              value={`${model.options.target_id}`}
            />
          </FormGroup>
        ) : null}

        {(model.options.autopilotType === "METRIC_FORECAST") ? (
          <FormGroup
            className={`m-t ${styles.sidebarFormGroup}`}
            label="Select Line Item"
            labelFor="revenue-forecast"
          >
            <FancySelect
              fill
              items={metricAccounts.map((a) => ({key: a.id, text: a.name}))}
              onSelect={(item) => changeModelField("autopilot", "target_id", "change", item.key)}
              value={`${model.options.target_id}`}
            />
          </FormGroup>
        ) : null}

        {(model.options.autopilotType === "PERCENTILE") ? (
          <FormGroup
            className={`m-t ${styles.sidebarFormGroup}`}
            label="Percentile"
            labelFor="percentile"
          >
            <ControlGroup
              vertical={false}
            >
              <InputGroup
                autoComplete="off"
                className={styles.growInput}
                minimal
                onBlur={(event) => this.editInputAmount("percentile", event.target.value, "blur")}
                onChange={(event) => this.editInputAmount("percentile", event.target.value, "focus")}
                onFocus={this.highlightAll}
                placeholder="Percentile"
                value={this.state.percentile}
              />
              <Tag className={`${styles.modelTag}`} large minimal><em>%</em></Tag>
            </ControlGroup>
          </FormGroup>
        ) : null}

        {(model.options.autopilotType !== "REVENUE_FORECAST" && model.options.autopilotType !== "METRIC_FORECAST") ? (
          <>
            {/* {(isWeightedRevenueType && isWeightedAvg) ?
              <FormGroup
                className={`m-t ${styles.sidebarFormGroup}`}
                label={`Weighted Average of`}
                labelFor="weighted-average-of"
              >
                <p>Expansion MRR/Expansions</p>
              </FormGroup> : ""
            } */}
            <FormGroup
              className={`m-t ${(notGrowShrink) ? "m-b-0" : ""} ${styles.sidebarFormGroup}`}
              label="Apply to Trailing"
              labelFor="months-avg"
            >
              <ControlGroup
                vertical={false}
              >
                <div className={`${BP.HTML_SELECT} ${BP.MINIMAL} ${styles.sidebarSelect}`}>
                  <select
                    onChange={(event) => changeModelField("autopilot", "numMonthsAvg", "event", event)}
                    value={model.options.numMonthsAvg}
                  >
                    <option value={1}>1 month</option>
                    <option value={2}>2 months</option>
                    <option value={3}>3 months</option>
                    <option value={4}>4 months</option>
                    <option value={5}>5 months</option>
                    <option value={6}>6 months</option>
                    <option value={7}>7 months</option>
                    <option value={8}>8 months</option>
                    <option value={9}>9 months</option>
                    <option value={10}>10 months</option>
                    <option value={11}>11 months</option>
                    <option value={12}>12 months</option>
                  </select>
                </div>
                <Tag className={`${styles.modelTag}`} large minimal>{(model.options.autopilotType !== "PERCENTILE") ? <>= <strong>{monthsAvg}</strong></> : ""}</Tag>
              </ControlGroup>
            </FormGroup>
          </>
        ) : null}

        {(model.options.autopilotType === "GROW_SHRINK" || model.options.autopilotType === "WEIGHTED_GROW_SHRINK") ? (
          <>
            <FormGroup
              className={`m-t m-b-0 ${styles.sidebarFormGroup}`}
              label="Grow"
              labelFor="grow"
            >
              <ControlGroup
                vertical={false}
              >
                <div className={`${BP.HTML_SELECT} ${BP.MINIMAL} ${styles.sidebarSelect}`}>
                  <select
                    onChange={(event) => changeModelField("autopilot", "growType", "event", event)}
                    value={model.options.growType}
                  >
                    {(!isRevenuePercentType) ? <option value={"%"}>percentage</option> : ""}
                    <option value={"$"}>value</option>
                  </select>
                </div>
                <InputGroup
                  autoComplete="off"
                  className={styles.growInput}
                  minimal
                  onBlur={(event) => this.editInputAmount("growAmount", event.target.value, "blur")}
                  onChange={(event) => this.editInputAmount("growAmount", event.target.value, "focus")}
                  onFocus={this.highlightAll}
                  placeholder="Grow Amount"
                  value={this.state.growAmount}
                />
                <Tag className={`${styles.modelTag}`} large minimal>/ month</Tag>
              </ControlGroup>
            </FormGroup>
          </>
        ) : null}
      </div>
    );
  }
}

export default Autopilot;
