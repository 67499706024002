import {cloneDeep, orderBy} from "lodash";
import moment from "moment-timezone";
import memoize from "memoize-one";

import {formatValue} from "shared/utilities/forecast-utilities";

export const blankModel = {
  hire_date: null,
  termination_date: null,
  compensation: 0,
  compensation_period: "YEAR",
  benefits: 0,
  benefits_period: "MONTH",
  payroll_tax: 0,
  raises: [{
    date: null,
    amount: 0,
    reason: "",
  }],
  benefits_changes: [{
    date: null,
    amount: 0,
    reason: "",
  }],
  single_payments: [{
    date: null,
    amount: 0,
    reason: "",
  }],
  delete: false,
};

export const blankChangeComponent = {
  date: null,
  amount: 0,
  reason: "",
};

export const findPayrollModel = (payrollModels, scenarioId) => payrollModels.find((payrollModel) => (payrollModel.scenario_id === scenarioId));

export const convertStringToDate = (dateString) => {
  if(typeof dateString === "object") return dateString;
  if(dateString && typeof dateString === "string") return moment(dateString, "YYYY-MM-DD").toDate();
  else return null;
};

const createPayrollModelCopy = (payrollModel) => {
  const copy = {};
  for(const key in payrollModel) {
    copy[key] = cloneDeep(payrollModel[key]);
  }
  return copy;
};

const yearlyCompensation = (value, period) => {
  switch(period) {
    case ("HOUR"):
      // Based on an avg of monthly working hours
      // To:do will need to be updated with tenant setting
      value = value * 173;
      break;
    case ("WEEK"):
      value = value * 4;
      break;
    case ("MONTH"):
      value = value * 12;
      break;
    default:
    case ("YEAR"):
      break;
  }
  return value;
};

const getLatestBenefits = (model) => {
  let compensation = model["benefits"];
  const currentDate = moment();
  for(const change of model["benefits_changes"]) {
    if(change.date && change.amount) {
      const changeDate = moment(change.date, "YYYY-MM-DD");
      if(changeDate.isBefore(currentDate)) compensation = change.amount;
    }
  }
  return yearlyCompensation(compensation, model["benefits_period"]);
};

const getLatestCompensation = (model, raises) => {
  let compensation = yearlyCompensation(model["compensation"], model["compensation_period"]);
  const currentDate = moment();
  for(const raise of raises) {
    if(raise.date && raise.amount) {
      const raiseDate = moment(raise.date);
      if(raiseDate.isBefore(currentDate)) compensation = raise.salary ? raise.salary : yearlyCompensation(raise.amount, model["compensation_period"]);
    }
  }
  return compensation;
};

const getNextRaise = (model, raises) => {
  const next_raise = {
    amount: 0,
    date: "",
  };
  const currentDate = moment();
  for(const raise of raises) {
    const raiseDate = moment(raise.date);
    if(raiseDate.isAfter(currentDate)) {
      next_raise.amount = raise.salary ? raise.salary : yearlyCompensation(raise.amount, model["compensation_period"]);;
      next_raise.date = raiseDate.format("MM/DD/YYYY");
      break;
    }
  }
  return next_raise;
};

const addDisplay = (model, companyWideRaises, currency = "dollar") => {
  const customRaises = model["raises"];
  const companyRaises = (model.company_raise_date) ? calculateRaisesFromSchedule({salary: model["compensation"], companyWideRaises, customRaises: model["raises"], companyRaiseDate: model["company_raise_date"]}) : [];
  const allRaises = customRaises.concat(companyRaises);

  const compensation = getLatestCompensation(model, allRaises);
  const benefits = getLatestBenefits(model);
  const payroll_tax = (model.payroll_tax / 100) * compensation;
  const fully_loaded = compensation + benefits + payroll_tax;
  const next_raise = getNextRaise(model, allRaises);
  return {
    compensation: formatValue({value: compensation, currencySymbol: currency}),
    benefits: formatValue({value: benefits, currencySymbol: currency}),
    payroll_tax: formatValue({value: payroll_tax, currencySymbol: currency}),
    fully_loaded: formatValue({value: fully_loaded, currencySymbol: currency}),
    next_raise: formatValue({value: next_raise.amount, currencySymbol: currency}),
    next_raise_date: next_raise.date,
  };
};

export const structurePayrollModels = (tenant, team, employee, scenarios) => {
  const modelObj = {};
  for(let i = 0; i < scenarios.length; i++) {
    const scenarioId = scenarios[i].id;
    const payrollModel = (employee) ? findPayrollModel(employee.payroll_models, scenarioId) : null;
    if(payrollModel) {
      modelObj[scenarioId] = createPayrollModelCopy(payrollModel);
      modelObj[scenarioId].hire_date = payrollModel.hire_date;
      modelObj[scenarioId].termination_date = payrollModel.termination_date;
      modelObj[scenarioId].raises = (payrollModel.raises && payrollModel.raises.length > 0) ? payrollModel.raises : [cloneDeep(blankChangeComponent)];
      modelObj[scenarioId].benefits_changes = (payrollModel.benefits_changes && payrollModel.benefits_changes.length > 0) ? payrollModel.benefits_changes : [cloneDeep(blankChangeComponent)];
      modelObj[scenarioId].single_payments = (payrollModel.single_payments && payrollModel.single_payments.length > 0) ? payrollModel.single_payments : [cloneDeep(blankChangeComponent)];
    } else {
      modelObj[scenarioId] = cloneDeep(blankModel);
      if(team && team.benefits) modelObj[scenarioId].benefits = team.benefits;
      if(team && team.benefits_period) modelObj[scenarioId].benefits_period = team.benefits_period;
      if(team && team.payroll_tax) modelObj[scenarioId].payroll_tax = team.payroll_tax;
    }
    // Add fully loaded column
    modelObj[scenarioId].display = addDisplay(modelObj[scenarioId], tenant?.hiring, tenant?.options?.currency);
  }
  return modelObj;
};

export const updateEmployees = memoize((tenant, teams, employees, scenarios) => {
  const updatedEmployees = [];
  if(teams && teams.length > 0 && employees && employees.length > 0 && scenarios && scenarios.length > 0) {
    for(let i = 0; i < employees.length; i++) {
      const employee = cloneDeep(employees[i]);
      const team = teams.find((team) => team.id === employee.team_id);
      employee.payrollModels = structurePayrollModels(tenant, team, employee, scenarios);
      updatedEmployees.push(employee);
    }
    return orderBy(
      updatedEmployees,
      [
        "team.name",
        (obj) => obj.name || "ZZZZ",
        (obj) => obj.title || "ZZZZ",
      ],
      ["asc", "asc", "asc"],
    );
  } else return updatedEmployees;
});

export const formatPayrollModelDate = (date) => (date) ? moment(date).format("MM/DD/YYYY") : "";

export const filterAccountsByPayroll = (accounts) => {
  const filteredAccounts = [{name: "Unassigned", id: null}];
  accounts = accounts.filter((account) => (account.type === "Cost of Revenue" || account.type === "Expenses") && account.detail_type !== "root");
  return filteredAccounts.concat(accounts);
};

export function calculateRaisesFromSchedule({salary, companyWideRaises, customRaises, companyRaiseDate}) {
  const raisesWithSalary = [];
  let startingSalary = salary;
  let lastCustomRaiseDate = null;
  if(customRaises && customRaises.length) {
    const idx = customRaises.length - 1;
    if(customRaises[idx].date) {
      startingSalary = customRaises[idx].amount;
      lastCustomRaiseDate = customRaises[idx].date;
    }
  }

  if(companyWideRaises && companyWideRaises.length) {
    const lastCustomRaiseDateMoment = moment(lastCustomRaiseDate);
    let lastCompanyRaiseIndex = 0;

    for(let i = 0; i < companyWideRaises.length; i++) {
      const raiseMoment = moment(companyWideRaises[i].date);
      if(raiseMoment.isSameOrBefore(lastCustomRaiseDateMoment) || raiseMoment.isBefore(moment(companyRaiseDate, "YYYY-MM-DD"))) continue;
      const raiseWithSalary = {...companyWideRaises[i]};
      const prevSalary = !raisesWithSalary.length ? startingSalary : raisesWithSalary[lastCompanyRaiseIndex - 1].salary;
      raiseWithSalary.salary = prevSalary * (1 + (raiseWithSalary.amount / 100));
      raisesWithSalary.push(raiseWithSalary);
      lastCompanyRaiseIndex++;
    }
  }

  return raisesWithSalary;
}
