import React, { useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { Switch, Tooltip, Button as BPButton, ButtonGroup } from "@blueprintjs/core";

import Button from "components/Button";
import Switcher from "components/Switcher";

import SanityChecks from "views/sanity-checks";
import PendingChanges from "./PendingChanges";
import { ALL_SCENARIOS_QUERY } from "../../graphql";

import RefreshOptions from "./RefreshOptions";
import { AppContext } from "../../AppContext";

import styles from "./OperatingModelHeader.module.scss";

export default function OperatingModelHeader({
  additionalPaddingOnWindows = false,
  className,
  leftSection,
  onScenarioChange,
  onYearChange,
  onExtendedChange,
  scenarioId,
  showChecks = true,
  showRefreshOptions = true,
  year,
  extended,
  collapseAll,
  setCollapseAll,
}) {
  const location = useLocation();
  const showExtraActions = useMemo(() => {
    return (/operating-model/g).test(location.pathname);
  }, [location])

  const [errors, setErrors] = useState(null);
  const { forecastStartDate, isAdmin, isWindows, scrollbarWidth, user, years } = useContext(AppContext);

  const { data } = useQuery(ALL_SCENARIOS_QUERY);

  const handleYearChange = ({ target: { value: year } }) => onYearChange(year);
  const handleScenarioChange = ({ target: { value: scenarioId } }) => onScenarioChange(scenarioId);

  const headerStyle = {};
  if (additionalPaddingOnWindows && isWindows) headerStyle["paddingRight"] = `calc(32px + ${scrollbarWidth}px)`;

  const classes = [styles.operatingModelHeader];
  if (className) classes.push(className);

  if (!scenarioId && data?.scenarios.length) scenarioId = data.scenarios[0].id;
  if (!year) year = forecastStartDate.split("-")[0];

  return (
    <nav className={classes.join(" ")} style={headerStyle}>
      {(data?.scenarios.length && scenarioId) ? (
        <>
          <div className={styles.leftSection}>
            {!!leftSection ? leftSection : null}
          </div>
          <div className={styles.rightSection}>
            <div className={styles.actionPrimary}>
              {(errors?.length) ? (
                <Button link="/company/integrations" size="small" text="Reconnect Accounting" />
              ) : null}
              <Switcher
                onChange={handleYearChange}
                options={years.map((year) => ({ name: year.toString(), value: year }))}
                selectedValue={year}
              />
              <Switcher
                onChange={handleScenarioChange}
                options={data.scenarios.map((scenario) => ({ name: scenario.name, value: scenario.id }))}
                selectedValue={scenarioId}
              />
              <div className={styles.adminOptions}>
                {isAdmin ? (
                  <>
                    {showRefreshOptions ? (<RefreshOptions setErrors={setErrors} />) : null}
                    <PendingChanges />
                    {showChecks && !errors ? (
                      <SanityChecks
                        scenarioId={scenarioId}
                        scenarios={data.scenarios}
                        user={user}
                      />
                    ) : null}
                  </>
                ) : (
                  <PendingChanges />
                )}
              </div>
            </div>
            {showExtraActions
              ? <div className={styles.actionSecondary}>
                <ButtonGroup>
                  <Tooltip content="Show an additional year of entries">
                    <BPButton
                      active={extended}
                      onClick={() => onExtendedChange(!extended)}
                      text="Extended"
                    />
                  </Tooltip>
                  <Tooltip content={`${collapseAll ? "Expand" : "Collapse"} all top-level accounts`}>
                    <BPButton
                      onClick={() => setCollapseAll(!collapseAll)}
                      icon={collapseAll ? "expand-all" : "collapse-all"}
                    />
                  </Tooltip>
                </ButtonGroup>
              </div>
              : null
            }
          </div>
        </>
      ) : null}
    </nav>
  );
}

OperatingModelHeader.propTypes = {
  additionalPaddingOnWindows: PropTypes.bool,
  className: PropTypes.string,
  leftSection: PropTypes.node,
  onScenarioChange: PropTypes.func.isRequired,
  onYearChange: PropTypes.func.isRequired,
  onExtendedChange: PropTypes.func,
  scenarioId: PropTypes.string,
  showChecks: PropTypes.bool,
  showRefreshOptions: PropTypes.bool,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  extended: PropTypes.bool,
};
