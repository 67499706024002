import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import numeral from "numeral";

import Errors from "components/Errors";
import Button from "components/Button";
import { ControlGroup, InputGroup, Tag, Dialog } from "@blueprintjs/core";

import { ALL_TAX_RATES_QUERY } from "./graphql";

import styles from "./CompanySettingsEmployees.module.scss";

import CompanySettingsTaxRatesDelete from "./CompanySettingsTaxRatesDelete";

const CompanySettingsTaxRatesCreateUpdate = (props) => {
  const { onClose, onCreate, onRemove, onUpdate, operation, taxRate } = props;

  const [name, setName] = useState(taxRate?.name ?? "");
  const [value, setValue] = useState(taxRate?.value ?? 8.5);
  const [operationActive, setOperationActive] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    if (!taxRate) return;
    setErrors([]);
    setShowDelete(false);
    setName(taxRate.name);
    setValue(taxRate.value ?? 8.5);
  }, [taxRate]);

  const doOperation = () => {
    const create = operation === "create";
    const fn = create ? onCreate : onUpdate;

    setOperationActive(true);

    fn({
      variables: {
        taxRate: {
          ...taxRate,
          name,
          value: numeral(value).value() ?? 8.5,
        },
      },
    })
      .then(() => {
        onClose();
      })
      .catch((e) => {
        setErrors([e.message]);
      })
      .finally(() => {
        setOperationActive(false);
      });
  };

  const title = operation === "create" ? "Create Tax Rate" : "Update Tax Rate";
  const icon = operation === "create" ? "add" : "annotation";

  if (!taxRate) return null;

  return (
    <>
      <Dialog
        canOutsideClickClose={false}
        className="bp5-large"
        icon={icon}
        inline
        isOpen={true}
        onClose={onClose}
        title={title}
      >
        <div className="bp5-dialog-body company-settings-dialog">
          <Errors messages={errors} />
          <div className="bp5-form-group">
            <h4 className="bp5-heading">
              Tax Rate Name <span className="bp5-text-muted" />
            </h4>
            <input
              autoComplete="off"
              className="bp5-input bp5-large .modifier"
              dir="auto"
              onChange={(event) => {
                setName(event.target.value);
              }}
              placeholder="Tax Rate Name"
              type="text"
              value={name}
            />
          </div>

          <div className="bp5-form-group m-t">
            <h4 className="bp5-heading">Tax %</h4>
            <div className="bp5-form-content">
              <ControlGroup fill vertical={false}>
                <InputGroup
                  autoComplete="off"
                  fill
                  id="value"
                  large
                  onBlur={(event) => {
                    setValue(event.target.value);
                  }}
                  onChange={(event) => {
                    setValue(event.target.value);
                  }}
                  onFocus={() => {
                    document.execCommand("selectAll", false, null);
                  }}
                  placeholder="Payroll Tax"
                  value={value}
                />
                <Tag
                  className={`${styles.taxRatePercentageTag}`}
                  icon="percentage"
                  minimal
                />
              </ControlGroup>
            </div>
          </div>
        </div>

        <div className="bp5-dialog-footer">
          <div className="bp5-dialog-footer-actions">
            {operation === "update" ? (
              <Button
                className={`${styles.footerDeleteButton} m-l-0`}
                disabled={operationActive}
                intent="danger"
                onClick={() => {
                  setShowDelete(true);
                }}
                text="Delete"
              />
            ) : null}
            <Button
              disabled={operationActive}
              onClick={onClose}
              text="Cancel"
            />
            <Button
              disabled={operationActive}
              intent="success"
              onClick={doOperation}
              text="Save"
            />
          </div>
        </div>
      </Dialog>
      {showDelete && (
        <CompanySettingsTaxRatesDelete
          onCancel={() => {
            setShowDelete(false);
          }}
          onRemove={onRemove}
          onSuccess={onClose}
          taxRate={taxRate}
        />
      )}
    </>
  );
};

CompanySettingsTaxRatesCreateUpdate.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  operation: PropTypes.string,
  taxRate: PropTypes.object,
};

export default CompanySettingsTaxRatesCreateUpdate;
