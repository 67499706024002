import numeral from "numeral";

import { currencyToSymbolMapping, formatValue } from "shared/utilities/forecast-utilities";

export function getConfig({
  currency,
  categories,
  colors,
  options,
  series,
  type,
}) {
  let config;

  function formatAffixed(value) {
    const usingCustomUnit = options.yAxisUnitsType === "CUSTOM";
    const precision = Math.min(Math.max(parseInt(options.precision) || 0, 0), 5);

    let finalValue = value;
    if (typeof value === "number") {
      finalValue = numeral(Math.abs(value)).format(`0,0.${"0".repeat(precision)}`);
      if (value < 0) {
        finalValue = `(${finalValue})`;
      }
    }
    return usingCustomUnit ? `${finalValue} ${options.yAxisUnits}` : `${currencyToSymbolMapping[currency]}${finalValue}`;
  }

  switch (type) {
    case "arearange":
    case "line": {
      const rangeSeries = {
        MIN: null,
        MAX: null,
      };


      const lineSeries = [];
      for (const serie of series) {
        if (serie.type === "MIN" || serie.type === "MAX") {
          rangeSeries[serie.type] = serie;
          continue;
        }

        const lineSerie = {
          ...serie,
          type: "spline",
          marker: {
            lineWidth: 0,
            lineColor: "#035089",
            fillColor: "#035089",
          },
        };

        if (lineSerie.firstForecastedIndex !== null) {
          lineSerie.zoneAxis = "x";
          lineSerie.zones = [{
            value: lineSerie.firstForecastedIndex - 1,
          }, {
            dashStyle: "dash",
          }];
        }

        lineSeries.push(lineSerie);
      }

      if (rangeSeries.MIN && rangeSeries.MAX) {
        const areaRangeSerie = {
          ...rangeSeries.MIN,
          color: rangeSeries.MAX.color,
          fillOpacity: 0.15,
          name: rangeSeries.MAX.name,
          lineWidth: 0,
          type: "arearange",
          marker: {
            enabled: false,
          },
        };

        for (let i = 0; i < rangeSeries.MIN.data.length; i++) {
          areaRangeSerie.data[i] = [areaRangeSerie.data[i], rangeSeries.MAX.data[i]];
        }

        lineSeries.push(areaRangeSerie);
      }

      config = {
        chart: {
          type,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories,
        },
        yAxis: {
          min: null,
          max: null,
          tickInterval: null,
          id: "y",
          softMin: 0,
        },
        legend: {
          enabled: lineSeries.length > 1,
        },
        series: lineSeries,
      };

      break;
    }
    case "area":
      config = {
        chart: {
          type: "area",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories,
        },
        yAxis: {
          min: null,
          max: null,
          tickInterval: null,
          id: "y",
          softMin: 0,
        },
        plotOptions: {
          area: {
            stacking: "normal",
            lineColor: "#3097D1",
            lineWidth: 0,
            marker: {
              lineWidth: 0,
              lineColor: "#035089",
            },
          },
        },
        series,
      };

      break;
    case "pie": {
      const sums = [];
      const pieColors = [];
      for (const serie of series) {
        sums.push({
          name: serie.name,
          y: serie.data.reduce((a, b) => a + b, 0),
        });
        pieColors.push(serie.color);
      }

      config = {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories,
        },
        plotOptions: {
          pie: {
            colors: pieColors,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          pointFormatter: function() { // eslint-disable-line object-shorthand
            return `${categories[0]} - ${categories[categories.length - 1]}: <b>${formatAffixed(this.y)}</b>`;
          },
        },
        series: [{
          innerSize: "35%",
          data: sums,
          tooltip: {
            headerFormat: "{point.x}",
          },
        }],
      };

      break;
    }
    case "bar": {
      const chartSeries = [];
      series.forEach((serie) => {
        const chartSerie = {
          ...serie,
        };

        if (chartSerie.firstForecastedIndex !== null) {
          chartSerie.zoneAxis = "x";
          chartSerie.zones = [{
            value: chartSerie.firstForecastedIndex,
          }, {
            tooltip: {
              pointFormat: `<span style="color:${serie.color}">●</span> {series.name}: <b>{point.y}</b><br/>`,
            },
            color: (options.forecastFormatting && options.forecastFormatting === "STRIPED") ? {
              pattern: {
                color: serie.color,
                path: {
                  d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                  strokeWidth: 2,
                },
                width: 10,
                height: 10,
                opacity: 1,
              },
            } : serie.color,
          }];
        }

        chartSeries.push(chartSerie);
      });

      config = {
        chart: {
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories,
        },
        yAxis: {
          min: null,
          max: null,
          tickInterval: null,
          id: "y",
          softMin: 0,
        },
        series: chartSeries,
        plotOptions: {
          column: {
            stacking: options.stacked ? "normal" : false,
          },
        },
        tooltip: {
          borderColor: colors[0],
        },
      };

      break;
    }
    case "combo": {
      const zIndexes = {
        column: 0,
        spline: 100,
      };
      const chartSeries = [];
      series.forEach((serie) => {
        const chartSerie = {
          ...serie,
          type: serie.type === "BAR" ? "column" : "spline",
        };

        // Add a z-index to the series, making sure the lines are always above the bars, while still keeping the order of the lines
        chartSerie.zIndex = zIndexes[chartSerie.type]++;

        if (chartSerie.type === "spline" && chartSerie.firstForecastedIndex !== null) {
          chartSerie.zoneAxis = "x";
          chartSerie.zones = [{
            value: chartSerie.firstForecastedIndex - 1,
          }, {
            dashStyle: "dash",
          }];
        } else if (chartSerie.type === "column" && chartSerie.firstForecastedIndex !== null) {
          chartSerie.zoneAxis = "x";
          chartSerie.zones = [{
            value: chartSerie.firstForecastedIndex,
          }, {
            tooltip: {
              pointFormat: `<span style="color:${serie.color}">●</span> {series.name}: <b>{point.y}</b><br/>`,
            },
            color: (options.forecastFormatting && options.forecastFormatting === "STRIPED") ? {
              pattern: {
                color: serie.color,
                path: {
                  d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                  strokeWidth: 2,
                },
                width: 10,
                height: 10,
                opacity: 1,
              },
            } : serie.color,
          }];
        }

        chartSeries.push(chartSerie);
      });

      config = {
        title: {
          text: "",
        },
        xAxis: {
          categories,
        },
        yAxis: {
          min: null,
          max: null,
          tickInterval: null,
          id: "y",
          softMin: 0,
        },
        series: chartSeries,
        tooltip: {
          borderColor: colors[0],
        },
      };
      break;
    }
    default: {
      break;
    }
  }

  if (config?.tooltip?.pointFormatter === undefined) {
    if (config.tooltip === undefined) {
      config.tooltip = {};
    }

    config.tooltip.pointFormatter = function() {
      const value = formatAffixed(this.y);
      return `<span style="color:${this.series.color}">●</span> ${this.series.name}: <b>${value}</b><br/>`;
    }
  }

  if (config && options?.yAxisType === "CUSTOM") {
    config.yAxis = {
      id: "y",
      min: options.yAxisMin,
      max: options.yAxisMax,
      tickInterval: options.yAxisInterval,
      softMin: null,
    };
  }

  if (!config.yAxis) {
    config.yAxis = {};
  }

  config.yAxis.labels = {
    // eslint-disable-next-line
    formatter: function() {
      return formatAffixed(`${formatValue({ value: this.value })}`);
    },
    style: {
      color: "#40586C",
      fontSize: "14px",
    },
  };

  return config;
}
