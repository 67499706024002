import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";

import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";

import {convertFormulaToEditMode, resolveFormula, isFormulaValid} from "./formula-utilities";
import {usePrevious} from "../utilities";
import {autocompleteTrigger} from "./Autocomplete";

import styles from "./styles.module.scss";

export default function FormulaBuilder({
  model,
  onAutocompleteHighlight,
  onChange,
  references,
}) {
  const textareaAutocompleteRef = useRef(null);
  const prevModel = usePrevious(model);
  const [localFormula, setLocalFormula] = useState(model.formulaValid !== false ? convertFormulaToEditMode({formula: model.formula, references, variables: model.variables}).formula : model.formula);

  useEffect(() => {
    const modelIdChanged = (prevModel?.frontendId ? prevModel.frontendId : prevModel?.id) !== (model.frontendId ? model.frontendId : model.id);
    // If the model formula has changed (like renaming another row), update it here.
    // Checking for formulaValid makes sure we compare real formulas together and not incomplete "edit mode" formulas
    const formulaChangedOnModelObject = (prevModel?.formulaValid !== false && model.formulaValid !== false && prevModel?.formula !== model.formula);

    if(modelIdChanged || formulaChangedOnModelObject) {
      setLocalFormula(model.formulaValid !== false ? convertFormulaToEditMode({formula: model.formula, references, variables: model.variables}).formula : model.formula);
    }
  }, [model, prevModel, references]);

  const handleFormulaChange = ({target: {value}}) => {
    if(value === localFormula) return;
    setLocalFormula(value);
    const {formula: resolvedNewFormula, variables: newVariables, allResolved, varNameToSlugMapping} = resolveFormula({formula: value, references});

    const formulaValid = allResolved ? isFormulaValid({formula: resolvedNewFormula, variables: newVariables}) : false;

    if(formulaValid) {
      onChange({formula: resolvedNewFormula, varNameToSlugMapping, formulaValid, variables: newVariables});
    } else {
      onChange({formula: value, formulaValid});
    }
  };

  const handleAutocompleteHighlight = ({item}) => {
    onAutocompleteHighlight(item?.slug ?? null);
  };

  return (
    <div className={styles.formulaBuilder}>
      <div className={styles.textareaWrapper}>
        <div className={styles.equalSign}>=</div>
        <ReactTextareaAutocomplete
          className={styles.textarea}
          loadingComponent={() => <span>Loading</span>}
          minChar={0}
          onChange={handleFormulaChange}
          onItemHighlighted={handleAutocompleteHighlight}
          ref={textareaAutocompleteRef}
          spellCheck={false}
          trigger={autocompleteTrigger(references)}
          value={localFormula}
        />
      </div>
    </div>
  );
}

FormulaBuilder.propTypes = {
  model: PropTypes.object.isRequired,
  onAutocompleteHighlight: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  references: PropTypes.array.isRequired,
};
