import React, {useContext} from "react";
import PropTypes from "prop-types";

import {useQuery} from "@apollo/client";
import {useNavigate} from "react-router-dom";

import Switcher from "components/Switcher";
import Button from "components/Button";

import {ALL_SCENARIOS_QUERY} from "../../../graphql";
import {ALL_PLAN_GROUPS_QUERY} from "./graphql";
import OperatingModelHeader from "../../operating-model/OperatingModelHeader";
import {AppContext} from "../../../AppContext";

import {getPlanGroupName} from "../utilities";

import styles from "./Header.module.scss";

export default function RevenueModelHeader(props) {
  const {isWindows, scrollbarWidth} = useContext(AppContext);
  const navigate = useNavigate();

  const {data: {scenarios}} = useQuery(ALL_SCENARIOS_QUERY);
  const {data: {plan_groups: planGroups}} = useQuery(ALL_PLAN_GROUPS_QUERY);

  const onEditChartOfPlansClick = () => navigate("/revenue-model/chart-of-plans");
  const onViewChange = (evt) => navigate(`/revenue-model/${evt.target.value}`);

  const {
    additionalPaddingOnWindows,
    onScenarioChange,
    onYearChange,
    scenarioId,
    view,
    year,
  } = props;

  const headerStyle = {};
  if(additionalPaddingOnWindows && isWindows) headerStyle["paddingRight"] = `calc(32px + ${scrollbarWidth}px)`;

  return (
    <nav className={styles.revenueModelHeader} style={headerStyle}>
      {(scenarios && scenarios.length > 0 && scenarioId) ? (
        <>
          <div className={styles.leftSection}>
            <Switcher
              onChange={onViewChange}
              options={generatePlanGroupsAndSummaryList(planGroups)}
              selectedValue={view}
            />
          </div>
          <div className={styles.middleSection} />
          <div className={styles.rightSection}>
            <OperatingModelHeader
              onScenarioChange={onScenarioChange}
              onYearChange={onYearChange}
              scenarioId={scenarioId}
              showChecks={false}
              showRefreshOptions={false}
              year={year}
            />
            <Button
              className={styles.editChartOfPlansButton}
              icon="diagram-tree"
              intent="success"
              onClick={onEditChartOfPlansClick}
              text="Edit Chart of Plans"
            />
          </div>
        </>
      ) : null}
    </nav>
  );
}

RevenueModelHeader.propTypes = {
  additionalPaddingOnWindows: PropTypes.bool.isRequired,
  onScenarioChange: PropTypes.func.isRequired,
  onYearChange: PropTypes.func.isRequired,
  scenarioId: PropTypes.string,
  view: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

function generatePlanGroupsAndSummaryList(planGroups = []) {
  // Compute all the options for plan groups and summary for each product
  const options = [];
  for(const planGroup of planGroups) {
    const summaryAlreadyPresent = options.find((option) => option.value === `summary-${planGroup.product.id}-${planGroup.product.name.toLowerCase()}`);
    if(!summaryAlreadyPresent && planGroups.filter((item) => item.product.id === planGroup.product.id).length > 1) {
      options.push({
        name: `${planGroup.product.name} - Summary`,
        value: `summary-${planGroup.product.id}-${planGroup.product.name.toLowerCase()}`,
      });
    }
    options.push({
      name: getPlanGroupName(planGroup),
      value: `${planGroup.id}-${planGroup.product.name.toLowerCase()}-${planGroup.interval.toLowerCase()}`,
    });
  }

  // Add global summary
  options.push({
    name: `Summary`,
    value: `summary`,
  });

  return options;
};
