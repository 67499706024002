import React from "react";
import PropTypes from "prop-types";

import Errors from "components/Errors";
import TypedConfirmation from "components/TypedConfirmation";

import {ALL_TEAMS_QUERY} from "./graphql";

class CompanySettingsTeamsDelete extends React.Component {
  static propTypes = {
    deleteTeam: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    team: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {isOpen: false, deleteButtonDisabled: true, deleteText: ""};
  }

  componentDidUpdate(prevProps) {
    if(prevProps.team === null &&
      this.props.team !== null &&
      this.props.deleteTeam
    ) {
      this.setState({isOpen: true});
    }
  }

  toggleDialog = () => {
    this.props.onCancel();
    this.setState({isOpen: false, deleteButtonDisabled: true, deleteText: "", errors: []});
  }

  delete = () => {
    this.props.onRemove({
      variables: {id: this.props.team.id},
    })
    .then(() => {
      this.setState({errors: [], isOpen: !this.state.isOpen, deleteText: "", deleteButtonDisabled: true});
      this.props.onSuccess();
    }).catch((error) => {
      console.log("There was an error sending the query", error);
      this.setState({errors: [
        "The team could not be removed. All employees belonging to this team must be removed first."], deleteText: ""});
    });
  }

  render() {
    const team = (this.props.team) ? this.props.team : {name: ""};
    return (
      <TypedConfirmation
        isOpen={this.state.isOpen}
        onCancel={this.toggleDialog}
        onConfirm={this.delete}
      >
        <Errors messages={this.state.errors}/>
        <p>
          Are you sure that you want to remove team <strong>{team.name}</strong>?
        </p>
        <p>
          All employees within this team need to moved to another team or removed before this team can be deleted. This action is irreversible.
        </p>
        <p>
          Please type "delete" in the input field then click <strong>Delete</strong> below if you are sure
          that you would like this team removed.
        </p>
      </TypedConfirmation>
    );
  }
}

export default CompanySettingsTeamsDelete;
