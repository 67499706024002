/**
* Pull out the Google sheets ID from the URL.
*
* @param url
* @returns sheet ID
*/
export const isValidGoogleSheetsUrl = (url) => {
  if(url === "") return true;
  else {
    // Extract the ID from the URL
    if(url[url.length - 1] === "/") url = url.slice(0, -1);
    if(url.includes("flightpathfinance.com")) return true;
    // eslint-disable-next-line
    const regexp = new RegExp("https:\/\/docs.google.com\/spreadsheets\/d\/(.*[^\/])\/?.*", "g");
    const sheetMatch = regexp.exec(url);
    let sheetId = null;
    if(sheetMatch) {
      sheetId = sheetMatch[1];
    }
    if(sheetId) return true;
    else return false;
  }
};