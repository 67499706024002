import React from "react";
import PropTypes from "prop-types";

import {Icon, Popover, Position} from "@blueprintjs/core";

import SnapshotInfos from "views/shared/SnapshotInfos";

import styles from "./styles.module.scss";

export default function SnapshotInfosPopover({snapshotId, disabled}) {
  return (
    <Popover
      content={(
        <SnapshotInfos snapshotId={snapshotId} />
      )}
      disabled={!snapshotId || disabled}
      position={Position.LEFT}
      target={<Icon className={[!snapshotId ? styles.disabled : null, styles.icon].join(" ")} icon="info-sign" iconSize={20} />}
    />
  );
}

SnapshotInfosPopover.propTypes = {
  disabled: PropTypes.bool,
  snapshotId: PropTypes.string,
};
