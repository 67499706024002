import React from "react";
import PropTypes from "prop-types";

import Sidebar from "components/Sidebar";
import Button from "components/Button";

import RowOptions from "./RowOptions";

import styles from "./styles.module.scss";

export default function SidebarRow({onCloseSidebar, onDelete, ...props}) {
  return (
    <Sidebar className={styles.rowSidebar} onClose={onCloseSidebar} title="Row Properties">
      <RowOptions {...props} />

      {/* Sidebar footer */}
      <div className={styles.sidebarButtons}>
        <Button
          intent="danger"
          onClick={onDelete}
          size="small"
          text="Delete Row"
        />
        <Button
          onClick={onCloseSidebar}
          size="small"
          text="Close"
        />
      </div>
    </Sidebar>
  );
}

SidebarRow.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCloseSidebar: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};
