import React, { useCallback, useMemo, useState } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from "slate";

import { Button, Icon, ButtonGroup as Toolbar } from "@blueprintjs/core";

import styles from "./RichTextEditor.module.scss";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const defaultValue = [
  {
    type: "paragraph",
    children: [
      { text: "" },
    ],
  },
];

const transformOldNoteFormat = (notes) => {
  if (!Object.hasOwn(notes.document, "nodes")) return defaultValue;

  const walkNode = (node) => {
    switch (node.object) {
      case "block":
        const result = {
          type: node.type,
          children: node.nodes.reduce((a, n) => {
            const next = walkNode(n);
            return Array.isArray(next)
              ? [...a, ...next]
              : [...a, next];
          }, []),
        };
        return result;
      case "text":
        return node.leaves.map(({text}) => ({ text }));
      default:
        return null;
    }
  };

  return notes.document.nodes.map(n => walkNode(n));
};

const RichTextEditor = (props) => {
  const {
    noFormatting,
    showOnly,
    placeholder,
    changeTextValue,
    initialValue,
  } = props;

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  const value =
    !!initialValue
      ? (Object.hasOwn(initialValue, "document")
        ? transformOldNoteFormat(initialValue)
        : initialValue)
      : defaultValue;

  editor.children = value;

  return (
    <Slate
      editor={editor}
      initialValue={value}
      onChange={change => {
        changeTextValue(change);
      }}
    >
      {(showOnly || noFormatting)
        ? null
        : (
            <Toolbar className={styles.richTextEditorButtons} minimal>
              <MarkButton format="bold" icon="bold" />
              <MarkButton format="italic" icon="italic" />
              <MarkButton format="underline" icon="underline" />
              <MarkButton format="code" icon="code" />
              <BlockButton format="heading-one" icon="header-one" />
              <BlockButton format="heading-two" icon="header-two" />
              <BlockButton format="block-quote" icon="citation" />
              <BlockButton format="numbered-list" icon="numbered-list" />
              <BlockButton format="bulleted-list" icon="properties" />
            </Toolbar>
        )
      }
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={placeholder}
        spellCheck
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
        className={styles.richTextEditorText}
      />
    </Slate>
  );
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
  );

  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    Transforms.wrapNodes(editor, { type: format, children: [] });
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
}

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: "left" };

  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
      )}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon icon={icon} />
    </Button>
  );
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon icon={icon} />
    </Button>
  );
}

export default RichTextEditor;
