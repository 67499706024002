import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import slug from "slugify";

import Button from "components/Button";

import {CREATE_DASHBOARD, DASHBOARDS_QUERY, UPDATE_DASHBOARD} from "../graphql";

import styles from "./styles.module.scss";

const handleMutationCb = (cb, navigate) => (proxy, {data: {createDashboard, updateDashboard}}) => {
  const queryData = proxy.readQuery({query: DASHBOARDS_QUERY});

  const data = {
    ...queryData,
    dashboards: [...queryData.dashboards],
  };

  if(createDashboard) {
    data.dashboards.push(createDashboard);
    navigate(`/dashboard/${createDashboard.id}-${slug(createDashboard.name.toLowerCase())}`);
  } else {
    data.dashboards[queryData.dashboards.findIndex((item) => item.id === updateDashboard.id)] = updateDashboard;
    navigate(`/dashboard/${updateDashboard.id}-${slug(updateDashboard.name.toLowerCase())}`);
  }

  proxy.writeQuery({query: DASHBOARDS_QUERY, data});

  cb();
};

export default function EditDashboardTab({closeEditMode, dashboard}) {
  const navigate = useNavigate();
  const [createDashboard, {loading: creatingDashboard}] = useMutation(CREATE_DASHBOARD, {update: handleMutationCb(closeEditMode, navigate)});
  const [updateDashboard, {loading: updatingDashboard}] = useMutation(UPDATE_DASHBOARD, {update: handleMutationCb(closeEditMode, navigate)});
  const [dashboardName, setDashboardName] = useState(dashboard.name);
  const inputRef = useRef(null);
  useEffect(() => inputRef.current.focus(), []);

  const save = () => {
    const mutationFn = dashboard.id === 0 ? createDashboard : updateDashboard;

    const variables = {
      dashboard: {
        name: dashboardName,
      },
    };

    if(dashboard.id !== 0) variables.dashboard.id = dashboard.id;

    mutationFn({variables});
  };

  const changeName = (evt) => {
    setDashboardName(evt.target.value);
  };

  return (
    <div className={styles.editWrapper}>
      <input
        onChange={changeName}
        ref={inputRef}
        type="text"
        value={dashboardName}
      />
      <div className={styles.buttons}>
        <Button onClick={closeEditMode} size="small" text="Cancel" />
        <Button
          intent="success"
          loading={creatingDashboard || updatingDashboard}
          onClick={save}
          size="small"
          text="Save"
        />
      </div>
    </div>
  );
}

EditDashboardTab.propTypes = {
  closeEditMode: PropTypes.func.isRequired,
  dashboard: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};
