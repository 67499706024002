import React from "react";
import PropTypes from "prop-types";

import {Icon} from "@blueprintjs/core";

import styles from "./styles.module.scss";

export default function GeneralMessage({onClose, position}) {
  return (
    <div className={[styles.generalMessage, styles[`position${position}`]].join(" ")}>
      <div className={styles.text}>
        <strong>{import.meta.env.VITE_GENERAL_MESSAGE_TEXT_BOLD}&nbsp;</strong>
        <span>{import.meta.env.VITE_GENERAL_MESSAGE_TEXT}</span>
      </div>

      <Icon
        className={styles.close}
        icon="small-cross"
        iconSize={20}
        onClick={onClose}
      />
    </div>
  );
}

GeneralMessage.propTypes = {
  onClose: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
};

export function shouldShowGeneralMessage() {
  return import.meta.env.VITE_GENERAL_MESSAGE_TEXT || import.meta.env.VITE_GENERAL_MESSAGE_TEXT_BOLD;
}
