import React from "react";
import PropTypes from "prop-types";

import {FormGroup, HTMLSelect} from "@blueprintjs/core";

const columnTypes = [
  {value: "MONTHS", displayName: "Months"},
  {value: "QUARTERS", displayName: "Quarters"},
  {value: "YEARS", displayName: "Years"},
];

function isColumnOptionDisabled(type, report_period) {
  if(report_period === "LAST_MONTH" && (type === "QUARTERS" || type === "YEARS")) return true;
  if(report_period === "MONTHS_FORECAST" && (type === "QUARTERS" || type === "YEARS")) return true;
  return false;
}

function FormFieldDisplayColumn(props) {
  return (
    <FormGroup
      className={props.formGroupClass}
      label={props.cardType === "TABLE" ? "Date Range Type" : "X-Axis Interval"}
      labelFor="column_type"
      labelInfo="*"
    >
      <HTMLSelect
        fill
        name="column_type"
        onChange={props.onChange}
        options={columnTypes.map((type) => ({
          disabled: isColumnOptionDisabled(type.value, props.report_period),
          label: type.displayName,
          value: type.value,
        }))}
        value={props.value}
      />
    </FormGroup>
  );
}

FormFieldDisplayColumn.propTypes = {
  cardType: PropTypes.string,
  formGroupClass: PropTypes.string,
  onChange: PropTypes.func,
  report_period: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default FormFieldDisplayColumn;
export {columnTypes};
