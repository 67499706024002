import { useContext, useMemo, useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

import { Outlet } from "react-router-dom";

import HorizontalNav from "components/HorizontalNav";
import { AppContext } from "../../AppContext";

import Card from "components/Card";

import { Classes as BP, Icon, FormGroup, ControlGroup, NumericInput, Button, Callout, Intent, Spinner } from "@blueprintjs/core";

import styles from "./Admin.module.scss";

const menuItems = [
  { link: "/admin", text: "Admin" },
];


const Admin = () => {
  const { user } = useContext(AppContext);
  return (
    <>
      <div className={["main-content", styles.mainContent].join(" ")}>
        <div className="top-nav">
          <HorizontalNav menuItems={menuItems} userRole={user.role} />
        </div>
      </div>
    </>
  );
};

export default Admin;
