import {gql} from "@apollo/client";
import {ACCOUNT_FRAGMENT, TABLE_COLUMN_FRAGMENT, TABLE_ROW_FRAGMENT} from "../../../shared/graphql/fragments";

export const DASHBOARD_LAYOUT_MUTATION = gql`
  mutation updateDashboardLayout($dashboard: JSON!) {
    updateDashboardLayout(dashboard: $dashboard)
  }
`;

export const DASHBOARD_QUERY = gql`
  query dashboardQuery($id: String!) {
    dashboard(id: $id) {
      id
      name
      layout
      colors {
        id
        color
      }
      tables {
        id
        name
        daterange
        column_type
        type
        options
        report_period
        scenario_id
        scenarios {
          id
          reference_id
          snapshot_id
        }
        accounts {
          ...AccountProps
        }
        entries
        rows {
          ...TableRowProps
        }
        columns {
          ...TableColumnProps
        }
      }
      charts {
        id
        name
        daterange
        column_type
        type
        options
        report_period
        lines {
          id
          name
          account_id
          color_id
          reference_snapshot_id
          scenario_id
          type
          reference_snapshot {
            id
            name
            forecast_start_date
          }
          account {
            ...AccountProps
          }
          entries
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${TABLE_ROW_FRAGMENT}
  ${TABLE_COLUMN_FRAGMENT}
`;

export const DASHBOARDS_QUERY = gql`
  query dashboardsQuery {
    dashboards {
      id
      name
    }
  }
`;

export const CREATE_DASHBOARD = gql`
  mutation createDashboard($dashboard: JSON!) {
    createDashboard(dashboard: $dashboard) {
      id
      name
    }
  }
`;

export const UPDATE_DASHBOARD = gql`
  mutation updateDashboard($dashboard: JSON!) {
    updateDashboard(dashboard: $dashboard) {
      id
      name
    }
  }
`;

export const REMOVE_DASHBOARD = gql`
  mutation removeDashboard($id: String!) {
    removeDashboard(id: $id)
  }
`;

export const MOVE_ITEM_TO_DASHBOARD = gql`
  mutation moveItemToDashboard($previousDashboardId: String!, $newDashboardId: String!, $itemType: String!, $itemId: String!) {
    moveItemToDashboard(previousDashboardId: $previousDashboardId, newDashboardId: $newDashboardId, itemType: $itemType, itemId: $itemId) {
      id
      layout
    }
  }
`;
