import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import TypedConfirmation from "components/TypedConfirmation";

import {removeAccount} from "./graphql";

import {removeAccountFromCache} from "apollo-config/local-state/accounts";
import {evictAllDashboardsFromCache} from "shared/graphql/apolloCacheEviction";

class CompanySettingsCOADelete extends React.Component {
  static propTypes = {
    account: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onRemoved: PropTypes.func.isRequired,
    removeAccount: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    toggleOperationActive: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  }

  deleteAccount = () => {
    // Check that they typed delete
    this.props.toggleOperationActive();

    this.setState({loading: true});
    this.props.removeAccount({
      variables: {id: this.props.account.id},
      update: evictAllDashboardsFromCache,
    }).then(() => {
      removeAccountFromCache(this.props.account);
      this.setState({loading: false});
      this.props.onClose();
      this.props.toggleOperationActive();
      this.props.onRemoved();
    }).catch((error) => {
      console.log(error);
      this.setState({loading: false});
      // eslint-disable-next-line
      let errorMessage = error.message.replace("GraphQL error: ", "");
      if(errorMessage !== "Cannot delete this payroll account because it is in use by a Team.") {
        errorMessage = "Account deletion failed.";
      }
      this.props.setErrorMessage(errorMessage);
      this.props.onClose();
      this.props.toggleOperationActive();
    });
  }

  render() {
    const account = (this.props.account) ? this.props.account : {};
    return (
      <TypedConfirmation
        deleting={this.state.loading}
        isOpen
        onCancel={this.props.onClose}
        onConfirm={this.deleteAccount}
      >
        <p>
          Are you sure that you want to delete account <strong>{account.name}</strong>?
        </p>
        <p>
          This will cause the removal of all its records and forecast models. This action is irreversible.
        </p>
        <p>
          Please type "delete" in the input field then click <strong>Delete</strong> below if you are sure
            that you would like this account removed.
        </p>
      </TypedConfirmation>
    );
  }
}

export default compose(
  graphql(removeAccount, {name: "removeAccount"}),
)(CompanySettingsCOADelete);
