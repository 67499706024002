import React from "react";
import PropTypes from "prop-types";

import {graphql, withApollo} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import QRCode from "qrcode.react";

import Button from "components/Button";

import {Classes, Dialog} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

import {enableMfa} from "./graphql";
import StepTitle from "./StepTitle";
import MfaCodeInput from "../../auth/MfaCodeInput";

import styles from "./styles.module.scss";

class SetupMFA extends React.Component {
  static propTypes = {
    enableMfa: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      error: "",
      modalOpen: true,
      validating: false,
    };

    this.mfaCodeInput = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => this.mfaCodeInput.current.reset(), 10);
  }

  onSubmit = () => {
    const {user} = this.props;

    this.setState({validating: true});
    this.props.enableMfa({
      variables: {
        otp: user.mfa.otp,
        secret: user.mfa.secret,
        code: this.state.code,
      },
    }).then(() => {
      this.setState({validating: false});
      this.props.onClose();
    }).catch((e) => {
      const error = e.message.replace("GraphQL error: ", "");
      this.setState({error, validating: false});
      this.mfaCodeInput.current.reset();
    });
  }

  onCodeChange = (code) => {
    this.setState({error: "", code});
  }

  onClose = () => {
    const {onClose} = this.props;
    this.setState({
      modalOpen: false,
    });
    setTimeout(onClose, 300); // Blueprint dialog transition duration seems to be 300ms
  }

  render() {
    const {
      code,
      error,
      modalOpen,
      validating,
    } = this.state;

    const {user} = this.props;

    return (
      <Dialog
        canOutsideClickClose
        className={styles.modal}
        icon={IconNames.LOCK}
        inline
        isOpen={modalOpen}
        onClose={this.onClose}
        title="Enable Multi-factor Authentication"
      >
        <div className={Classes.DIALOG_BODY}>
          <div className={styles.step}>
            <StepTitle
              number="1"
              subtext="Scan this QR code the authenticator app of your choice on your mobile device."
              title="Scan this QR code"
            />
            <div className={styles.qrcode}>
              <QRCode value={user.mfa.otp} />
              <Button
                text="Copy TOTP Code"
                onClick={() => { navigator.clipboard.writeText(user.mfa.otp) }}
              />
            </div>
          </div>
          <div className={styles.step}>
            <StepTitle
              number="2"
              subtext="Enter the code shown on your device below to confirm that everything is set up properly."
              title="Confirm your MFA setup"
            />
            {error.length ? <div className={styles.error}>{error}</div> : null}
            <MfaCodeInput
              loading={validating}
              onCodeChange={this.onCodeChange}
              onEnterKeyPress={this.onSubmit}
              ref={this.mfaCodeInput}
            />
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.onClose} text="Cancel" />
            <Button
              disabled={!code}
              intent="success"
              loading={validating}
              onClick={this.onSubmit}
              text="Confirm"
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default compose(
  graphql(enableMfa, {name: "enableMfa"}),
)(withApollo(SetupMFA));
