import React, {useState, useContext} from "react";
import PropTypes from "prop-types";

import { useQuery, useMutation } from "@apollo/client";
import compose from "lodash.flowright";
import html2canvas from "html2canvas";
import fileDownload from "js-file-download";
import slug from "slugify";
import jsPDF from "jspdf";

import {client} from "apollo-config";

import {IconNames} from "@blueprintjs/icons";
import {Icon, Menu, MenuDivider, MenuItem, Popover, Position, Spinner, Classes as BP} from "@blueprintjs/core";
import Card from "components/Card";
import RenderError from "components/RenderError";
import TypedConfirmation from "components/TypedConfirmation";

import {COPY_TABLE_MUTATION, REMOVE_TABLE_MUTATION} from "../table-builder/graphql";
import {COPY_CHART_MUTATION, REMOVE_CHART_MUTATION} from "../chart-builder/graphql";
import {DASHBOARD_QUERY, DASHBOARDS_QUERY, MOVE_ITEM_TO_DASHBOARD} from "./graphql";
import {AppContext} from "../../../AppContext";

import CustomTable from "./custom-tables/CustomTable";
import CustomChart from "./custom-charts/CustomChart";
import styles from "./Grid.module.scss";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const Grid = (props) => {
  const [copyChart] = useMutation(COPY_CHART_MUTATION);
  const [removeChart] = useMutation(REMOVE_CHART_MUTATION);
  const [copyTable] = useMutation(COPY_TABLE_MUTATION);
  const [removeTable] = useMutation(REMOVE_TABLE_MUTATION);
  const [moveItemToDashboard] = useMutation(MOVE_ITEM_TO_DASHBOARD);

  const dashboardsQuery = useQuery(DASHBOARDS_QUERY);

  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [exportingScreenshot, setExportingScreenshot] = useState(false);
  const [hasRenderError, setHasRenderError] = useState(false);

  const gridItemRef = React.createRef();

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  }

  const remove = () => {
    const {dashboardId, item: {id, entityType}} = props;

    setDeleting(true);

    const params = {
      variables: {id},
      update: (proxy) => {
        const variables = {id: dashboardId};
        const queryData = proxy.readQuery({query: DASHBOARD_QUERY, variables});
        const index = queryData.dashboard[`${entityType}s`].findIndex((item) => id === item.id);
        const layoutIndex = queryData.dashboard.layout.findIndex((item) => id === item.id && item.type === entityType);
        // All this trouble to avoid directly mutating the state, and make sure new references are passed
        const data = {
          ...queryData,
          dashboard: {
            ...queryData.dashboard,
            [`${entityType}s`]: [...queryData.dashboard[`${entityType}s`]],
            layout: [...queryData.dashboard.layout],
          },
        };

        data.dashboard[`${entityType}s`].splice(index, 1);
        data.dashboard.layout.splice(layoutIndex, 1);

        proxy.writeQuery({query: DASHBOARD_QUERY, variables, data});

        setDeleting(false);
        setDeleteModalOpen(false);
      },
    };

    (entityType === "chart") ? removeChart(params) : removeTable(params);
  }

  const copy = () => {
    const {dashboardId, item: {id, entityType}} = props;

    setLoading(true);

    const params = {
      variables: {id, dashboardId},
      update: (proxy, {data: {copyTable, copyChart}}) => {
        const variables = {id: dashboardId};
        const queryData = client.readQuery({query: DASHBOARD_QUERY, variables});
        // All this trouble to avoid directly mutating the state, and make sure new references are passed
        const data = {
          ...queryData,
        };

        if(copyTable) {
          data.dashboard = {
            ...queryData.dashboard,
            layout: [...copyTable.layout],
            tables: [
              ...queryData.dashboard.tables,
              copyTable.table,
            ],
          };
        } else {
          data.dashboard = {
            ...queryData.dashboard,
            layout: [...copyChart.layout],
            charts: [
              ...queryData.dashboard.charts,
              copyChart.chart,
            ],
          };
        }
        client.writeQuery({query: DASHBOARD_QUERY, variables, data});
        setLoading(false);
      },
    };

    (entityType === "chart") ? copyChart(params) : copyTable(params);
  }

  const exportScreenshot = (type = "png") => () => {
    document.querySelector(BP.POPOVER).style.display = "none";
    setExportingScreenshot(true);
    context.onTemporarilyhideTopBars();

    setTimeout(() => {
      window.scrollTo(0, 0);
      html2canvas(gridItemRef.current, {
        allowTaint: true,
        backgroundColor: "rgba(0,0,0,0)",
        ignoreElements: (node) => {
          if(!node || !node.className || !node.className.length) return false;
          return node.className.includes(BP.POPOVER) || node.className.includes(styles.dragHandle);
        },
        removeContainer: true,
        scale: 2.5,
        useCORS: true,
      }).then((canvas) => {
        if(type === "png") {
          canvas.toBlob((blob) => {
            fileDownload(blob, `${slug(props.item.name)}.png`, "image/png");
            this.setState({exportingScreenshot: false});
          });
        } else if(type === "pdf") {
          const imgData = canvas.toDataURL("image/png");
          const doc = new jsPDF("p");
          const imgProps = doc.getImageProperties(imgData);
          const docWidth = doc.internal.pageSize.getWidth();
          const docHeight = (imgProps.height * docWidth) / imgProps.width;

          doc.addImage(imgData, "PNG", 5, 5, docWidth - 10, docHeight - 10);
          doc.save(`${slug(props.item.name)}.pdf`);
          setExportingScreenshot(false);
        }
      });
    }, 700);
  }

  const moveToDashboard = (id) => () => {
    const {dashboardId, item} = props;
    setLoading(true);

    moveItemToDashboard({
      variables: {
        previousDashboardId: dashboardId,
        newDashboardId: id,
        itemType: item.entityType,
        itemId: item.id,
      },
      update: (proxy, {data: {moveItemToDashboard}}) => {
        const newDashboard = moveItemToDashboard.find((dashboard) => dashboard.id !== dashboardId);
        const variables = {id};
        let queryData = null;
        let previousDashboardQueryData = null;
        try {
          queryData = proxy.readQuery({query: DASHBOARD_QUERY, variables});
          previousDashboardQueryData = proxy.readQuery({query: DASHBOARD_QUERY, variables: {id: dashboardId}});
        } catch(e) {
          return;
        }

        const data = {
          ...queryData,
          dashboard: {
            ...queryData.dashboard,
            layout: [...newDashboard.layout],
            [`${item.entityType}s`]: [
              ...queryData.dashboard[`${item.entityType}s`],
              previousDashboardQueryData.dashboard[`${item.entityType}s`].find((entity) => entity.id === item.id),
            ],
            colors: [...previousDashboardQueryData.dashboard.colors],
          },
        };

        proxy.writeQuery({query: DASHBOARD_QUERY, variables, data});

        this.setState({loading: false});
      },
    });
  }

  const {
    customColors,
    dashboardId,
    item,
    mounted,
    onEdit,
  } = props;

  const context = useContext(AppContext);
  const {user} = context;
  const userCanEdit = user?.tenant && ["EDIT", "ADMIN", "SUPER"].includes(user.role);

  const cardStyles = [styles.gridItem];
  if(exportingScreenshot) cardStyles.push(styles.noInteraction);

  return (
    <span className={styles.gridItemCardWrapper} ref={gridItemRef}>
      <Card className={cardStyles.join(" ")} key={`${item.id}-${item.entityType}`}>
        {!mounted ? null : hasRenderError ? (
          <RenderError
            message={`Chances are we are working on fixing this, but if it keeps happening, please don't hesitate to <a href="mailto:support@flightpathfinance.com">contact support</a>.`}
            title={`There was an error trying to display this ${item.entityType}`}
          />
        ) : (
          <>
            {loading ? (
              <div className={styles.loadingOverlay}>
                <Spinner />
              </div>
            ) : null}
            <div className={styles.cardHeader}>
              <h3 className={styles.cardTitle}>{item.name}</h3>
              {(userCanEdit) ? <Icon className={styles.dragHandle} icon={IconNames.MOVE} /> : ""}
              <Popover
                autoFocus={false}
                content={(
                  <Menu>
                    {userCanEdit ? (
                      <>
                        <MenuItem icon={IconNames.EDIT} onClick={onEdit(item.entityType, item)} text={`Edit ${capitalize(item.entityType)}`} />
                        <MenuItem icon={IconNames.DUPLICATE} onClick={copy} text={`Duplicate ${capitalize(item.entityType)}`} />
                      </>
                    ) : null}
                    <MenuItem icon={IconNames.EXPORT} onClick={exportScreenshot("png")} text="Export PNG" />
                    <MenuItem icon={IconNames.EXPORT} onClick={exportScreenshot("pdf")} text="Export PDF" />
                    {userCanEdit ? <MenuItem icon={IconNames.TRASH} onClick={toggleDeleteModal} text={`Delete ${capitalize(item.entityType)}`}/> : "" }
                    {dashboardsQuery.data.dashboards?.length > 1 ? (
                      <>
                        <MenuDivider />
                        <MenuItem icon="exchange" text="Move to...">
                          {dashboardsQuery.data.dashboards.filter((dashboard) => dashboard.id !== dashboardId).map((dashboard) => (
                            <MenuItem key={dashboard.id} onClick={moveToDashboard(dashboard.id)} text={dashboard.name} />
                          ))}
                        </MenuItem>
                      </>
                    ) : null}
                  </Menu>
                )}
                id={`card-${item.id}`}
                lazy
                minimal
                position={Position.BOTTOM_RIGHT}
                usePortal={false}
              >
                <button className={styles.menuIcon}>
                  <Icon icon={IconNames.MORE} />
                </button>
              </Popover>
            </div>
            <div className={styles.cardBody}>
              {item.entityType === "table" ? (
                <CustomTable key={`${item.id}-${item.type}-actual`} table={item} />
              ) : (
                <CustomChart chart={item} customColors={customColors} key={`${item.id}-${item.type}`} />
              )}
            </div>
            <TypedConfirmation
              deleting={deleting}
              isOpen={deleteModalOpen}
              onCancel={toggleDeleteModal}
              onConfirm={remove}
            >
              <p>Are you sure that you want to remove this {item.entityType}?</p>
              <p>
                Please type 'delete' in the input field then click <strong>Delete</strong> below if you are sure
                that you would like this {item.entityType} to be removed.
              </p>
            </TypedConfirmation>
          </>
        )}
      </Card>
    </span>
  );
};

export default Grid;
