import React from "react";
import PropTypes from "prop-types";

import {Checkbox, FormGroup} from "@blueprintjs/core";

export default function ComparisonType({formGroupClass, onChange, value}) {
  let {valueDeltaColumn, percentageDeltaColumn} = value;
  if(typeof valueDeltaColumn === "undefined") valueDeltaColumn = true;
  if(typeof percentageDeltaColumn === "undefined") percentageDeltaColumn = true;

  const generateOnChangeEvent = (evt) => {
    onChange({
      target: {
        name: "options",
        value: {
          ...value,
          [evt.target.name]: evt.target.checked,
        },
      },
    });
  };

  return (
    <FormGroup
      className={formGroupClass}
      label="Show Delta"
      labelFor="comparisonType"
    >
      <Checkbox
        checked={valueDeltaColumn}
        inline
        label="As value ($)"
        name="valueDeltaColumn"
        onClick={generateOnChangeEvent}
      />
      <Checkbox
        checked={percentageDeltaColumn}
        inline
        label="As percentage (%)"
        name="percentageDeltaColumn"
        onClick={generateOnChangeEvent}
      />
    </FormGroup>
  );
}

ComparisonType.propTypes = {
  formGroupClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};
