import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Errors from "components/Errors";
import Button from "components/Button";

import {Classes, Dialog, FormGroup, Icon, HTMLSelect} from "@blueprintjs/core";

import {ALL_PRODUCTS_QUERY, createPlan, updatePlan} from "./graphql";
import {RM_ACCOUNTS_QUERY, ALL_PLAN_GROUPS_QUERY} from "../forecast/graphql";

import PlanDelete from "./PlanDelete";

import styles from "./styles.module.scss";

import RevenueCSV from "./RevenueCSV";

class PlanCreateUpdate extends React.Component {
  static propTypes = {
    allProductsQuery: PropTypes.object.isRequired,
    createPlan: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    plan: PropTypes.object,
    preselectedProductId: PropTypes.string,
    updatePlan: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      csv: null,
      filename: null,
      isOpen: true,
      plan: {
        id: props.plan ? props.plan.id : null,
        name: props.plan ? props.plan.name : "",
        identifier: props.plan ? props.plan.identifier : "",
        interval: props.plan ? props.plan.interval : "MONTH",
        interval_count: props.plan ? props.plan.interval_count : 1,
        amount: props.plan ? props.plan.amount : "",
        origin: "FLIGHTPATH_FINANCE",
        product_id: props.plan ? props.plan.product_id : typeof props.preselectedProductId !== "undefined" ? props.preselectedProductId : null,
      },
      saving: false,
      showDeleteDialog: false,
    };
  }

  closeDialog = () => {
    setTimeout(() => {
      this.props.onClose();
    }, 300);

    this.setState({
      errors: [],
      saving: false,
      isOpen: false,
    });
  }

  onFieldChange = (evt) => {
    this.setState({
      plan: {
        ...this.state.plan,
        [evt.target.name]: evt.target.value,
      },
    });
  }

  onSave = () => {
    const mutation = !this.state.plan.id ? this.props.createPlan : this.props.updatePlan;

    this.setState({saving: true});

    const plan = {...this.state.plan};

    if(!plan.identifier || plan.identifier === "") delete plan.identifier;
    if(plan.product_id === "") delete plan.product_id;
    if(plan.product_id === "0") plan.product_id = null;
    const variables = (this.state.csv) ? {plan, csv: this.state.csv} : {plan};

    mutation({
      variables,
      refetchQueries: [{query: RM_ACCOUNTS_QUERY}, {query: ALL_PLAN_GROUPS_QUERY}],
      update: (proxy, {data: {createPlan, updatePlan}}) => {
        const queryData = proxy.readQuery({
          query: ALL_PRODUCTS_QUERY,
          variables: {includePlans: true},
        });

        const data = {
          ...queryData,
          products: [
            ...queryData.products,
          ],
        };

        const product_id = plan.product_id ? plan.product_id : "0";
        const productIndex = queryData.products.findIndex((item) => item.id === product_id);
        const product = {
          ...queryData.products[productIndex],
          plans: [...queryData.products[productIndex].plans],
        };

        if(!plan.id) {
          product.plans.push(createPlan);
        } else {
          if(updatePlan.product_id !== this.props.plan.product_id) {
            product.plans.push(updatePlan);
            // This means the plan has been moved to another product
            const oldProductIndex = data.products.findIndex((item) => item.id === this.props.plan.product_id || "0");
            const oldProduct = {
              ...data.products[oldProductIndex],
              plans: [...data.products[oldProductIndex].plans],
            };
            const index = oldProduct.plans.findIndex((plan) => updatePlan.id === plan.id);
            oldProduct.plans.splice(index, 1);
            data.products[oldProductIndex] = oldProduct;
          } else {
            const index = product.plans.findIndex((plan) => updatePlan.id === plan.id);
            product.plans[index] = updatePlan;
          }
        }

        data.products[productIndex] = product;

        proxy.writeQuery({query: ALL_PRODUCTS_QUERY, variables: {includePlans: true}, data});
      },
    }).then(this.closeDialog).catch((error) => {
      console.log(error);
      this.setState({saving: false});
      this.setState({errors: ["The plan could not be saved, please check all required fields and try again."]});
    });
  }

  deletePlan = () => this.setState({showDeleteDialog: true});

  cancelDelete = () => this.setState({showDeleteDialog: false});

  addCSVToState = (results, filename) => this.setState({csv: results, filename});

  render() {
    const products = this.props.allProductsQuery.products || [];
    const operation = !this.state.plan.id ? "create" : "update";
    const title = operation === "create" ? "Create Plan" : "Update Plan";
    const icon = operation === "create" ? "add" : "annotation";

    return (
      <>
        <Dialog
          canOutsideClickClose={false}
          className={Classes.LARGE}
          icon={icon}
          inline
          isOpen={this.state.isOpen}
          onClose={this.props.onClose}
          title={title}
        >
          <div className={[Classes.DIALOG_BODY, styles.dialogBody].join(" ")}>
            <Errors messages={this.state.errors} />
            <FormGroup>
              <h4 className={Classes.HEADING}>Plan Name</h4>
              <input
                autoComplete="off"
                className={Classes.INPUT}
                name="name"
                onChange={this.onFieldChange}
                placeholder="Plan Name"
                type="text"
                value={this.state.plan.name || ""}
              />
            </FormGroup>

            <FormGroup>
              <h4 className={Classes.HEADING}>Plan ID</h4>
              <input
                autoComplete="off"
                className={Classes.INPUT}
                name="identifier"
                onChange={this.onFieldChange}
                placeholder="Plan ID"
                type="text"
                value={this.state.plan.identifier || ""}
              />
            </FormGroup>

            <FormGroup>
              <h4 className={Classes.HEADING}>Billing Period</h4>
              <HTMLSelect
                name="interval"
                onChange={this.onFieldChange}
                placeholder="Billing Period"
                type="text"
                value={this.state.plan.interval || ""}
              >
                <option value="MONTH">Monthly</option>
                <option value="QUARTER">Quarterly</option>
                <option value="YEAR">Yearly</option>
              </HTMLSelect>
            </FormGroup>

            <FormGroup>
              <h4 className={Classes.HEADING}>Product</h4>
              <HTMLSelect
                name="product_id"
                onChange={this.onFieldChange}
                placeholder="Product"
                type="text"
                value={this.state.plan.product_id || 0}
              >
                {products.map((product) => (
                  <option key={product.id} value={product.id}>{product.name}</option>
                ))}
              </HTMLSelect>
            </FormGroup>

            <FormGroup>
              <h4 className={Classes.HEADING}>Price</h4>
              <input
                autoComplete="off"
                className={Classes.INPUT}
                name="amount"
                onChange={this.onFieldChange}
                placeholder="Price"
                type="text"
                value={this.state.plan.amount || ""}
              />
            </FormGroup>
            {(operation === "update" && this.state.plan.product_id) ? (
              <FormGroup className={styles.csvFormGroup}>
                <h4 className={Classes.HEADING}>Upload CSV</h4>
                <span className={styles.subtext}>The historical data for this plan group will be overridden by the CSV data</span>
                <RevenueCSV
                  addCSVToState={this.addCSVToState}
                  plan={this.state.plan}
                />
                {this.state.filename ? (
                  <div className={styles.filenameContainer}>
                    <Icon icon="tick-circle" />
                    {this.state.filename}
                  </div>
                ) : null}
              </FormGroup>
            ) : null}
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              {operation === "update" ? (
                <Button
                  className={styles.removePlan}
                  disabled={this.state.saving}
                  intent="danger"
                  onClick={this.deletePlan}
                  text="Delete"
                />
              ) : null}
              <Button
                disabled={this.state.saving}
                onClick={this.closeDialog}
                text="Cancel"
              />
              <Button
                disabled={this.state.saving}
                intent="success"
                loading={this.state.saving}
                onClick={this.onSave}
                text="Save"
              />
            </div>
          </div>
        </Dialog>
        {this.state.showDeleteDialog ? (
          <PlanDelete
            closeDialog={this.closeDialog}
            onCancel={this.cancelDelete}
            plan={this.state.plan}
          />
        ) : null}
      </>
    );
  }
}

export default compose(
  graphql(createPlan, {name: "createPlan"}),
  graphql(updatePlan, {name: "updatePlan"}),
  graphql(ALL_PRODUCTS_QUERY, {name: "allProductsQuery", options: {variables: {includePlans: true}}}),
)(PlanCreateUpdate);
