import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import { useResizeDetector } from "react-resize-detector";

import styles from "./CustomChart.module.scss";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCmore from "highcharts/highcharts-more";
import patternFill from "highcharts/modules/pattern-fill";

patternFill(Highcharts);
HCmore(Highcharts);

export default function ActualChart({ config }) {
  const chartRef = useRef(null);
  const onResize = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.chart.reflow();
    }
  });

  const resizeDetector = useResizeDetector({
    handleHeight: false,
    onResize,
  });

  return (
    <div className={styles.actualChart} ref={resizeDetector.ref}>
      <HighchartsReact
        highcharts={Highcharts}
        options={config}
        ref={chartRef}
      />
    </div>
  );
}

ActualChart.propTypes = {
  chart: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};
