import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { Icon, Spinner } from "@blueprintjs/core";

import styles from "./styles.module.scss";

function Button(props) {
  const { classes, style } = getClassesFromProps(props);

  const {
    children,
    disabled,
    icon,
    link,
    loading,
    onClick,
    text,
  } = props;

  const actualButton = (
    <button
      className={classes.join(" ")}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {loading ? (
        <Spinner className={styles.spinner} size={33} />
      ) : null}

      {icon ? (
        <Icon className={styles.icon} icon={icon} />
      ) : null}
      {children || text}
    </button>
  );

  if (link) {
    return (
      <Link to={link}>{actualButton}</Link>
    );
  } else {
    return actualButton;
  }
}

Button.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fill: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  intent: PropTypes.string,
  link: PropTypes.string,
  loading: PropTypes.bool,
  minimal: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  text: PropTypes.string,
  width: PropTypes.number,
};

export default Button;

export function getClassesFromProps({
  border,
  className,
  disabled,
  fill,
  intent,
  loading,
  minimal,
  size,
  style = {},
  width,
}) {
  const classes = [styles.button];
  if (className) classes.push(className);
  if (loading) classes.push(styles.loading);
  if (disabled) classes.push(styles.disabled);

  if (size === "large") {
    classes.push(styles.large);
  } else if (size === "small") {
    classes.push(styles.small);
  }

  if (border) classes.push(styles.border);
  if (fill) classes.push(styles.fill);
  if (minimal) classes.push(styles.minimal);

  if (intent && intent === "primary") {
    classes.push(styles.primary);
  } else if (intent && intent === "success") {
    classes.push(styles.success);
  } else if (intent && intent === "danger") {
    classes.push(styles.danger);
  } else if (intent && intent === "warning") {
    classes.push(styles.warning);
  }

  if (width) style.width = width;

  return { style, classes };
}
