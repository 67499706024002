import React from "react";
import PropTypes from "prop-types";

import {graphql, withApollo} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import {Button, Classes, Dialog, FormGroup, Icon, InputGroup, Intent, Spinner} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

import Errors from "../components/Errors";
import {createTenant, CURRENT_USER_QUERY, CHECK_SUBDOMAIN_AVAILABILITY} from "./graphql";

import styles from "./styles/NewTenant.module.scss";

class NewTenantDialog extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    createTenant: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  state = {
    checkingSubdomainAvailability: false,
    checkTimeout: null,
    creating: false,
    modalOpen: true,
    subdomainAvailable: false,
    tenant: this.getDefaultData(),
    valid: true,
  }

  componentWillUnmount() {
    if(this.state.checkTimeout) clearTimeout(this.state.checkTimeout);
  };

  getDefaultData() {
    return {
      name: "",
      displayName: "",
    };
  }

  handleSubmit = () => {
    const {tenant} = this.state;

    this.setState({creating: true});

    this.props.createTenant({
      variables: tenant,
      optimisticResponse: {
        __typename: "Mutation",
        createTenant: true,
      },
      update: (proxy) => {
        const queryData = proxy.readQuery({query: CURRENT_USER_QUERY});
        const updatedData = {
          ...queryData,
          currentUser: {
            ...queryData.currentUser,
            tenants: [...queryData.currentUser.tenants],
          },
        };
        updatedData.currentUser.tenants.push({
          __typename: "UserTenant",
          tenant: {
            __typename: "Tenant",
            ...tenant,
          },
          role: "SUPER",
        });
        proxy.writeQuery({query: CURRENT_USER_QUERY, data: updatedData});
      },
    }).then(() => {
      this.onClose();
    });
  }

  handleFieldChange = (evt) => {
    const valid = evt.target.name !== "name" ? this.state.valid : RegExp("^([a-z0-9])+$").test(evt.target.value);

    let checkTimeout;
    if(evt.target.name === "name") {
      if(this.state.checkTimeout) clearTimeout(this.state.checkTimeout);
      checkTimeout = setTimeout(() => {
        this.checkSubdomainAvailability();

        this.setState({
          checkTimeout: null,
        });
      }, 350);
    }

    this.setState({
      checkTimeout,
      tenant: {
        ...this.state.tenant,
        [evt.target.name]: evt.target.value,
      },
      valid,
    });
  }

  checkSubdomainAvailability = () => {
    const {client} = this.props;
    const {name} = this.state.tenant;

    this.setState({
      checkingSubdomainAvailability: true,
    });

    client.query({
      query: CHECK_SUBDOMAIN_AVAILABILITY,
      variables: {name},
    }).then(({data: {subdomainAvailability}}) => {
      this.setState({
        checkingSubdomainAvailability: false,
        subdomainAvailable: subdomainAvailability,
      });
    });
  }

  onClose = () => {
    const {onClose} = this.props;
    this.setState({
      modalOpen: false,
    });
    setTimeout(onClose, 300); // Blueprint dialog transition duration seems to be 300ms
  }

  render() {
    const {
      checkingSubdomainAvailability,
      checkTimeout,
      creating,
      modalOpen,
      subdomainAvailable,
      tenant,
      valid,
    } = this.state;

    let textClass = "Loading";
    if(!checkTimeout && !checkingSubdomainAvailability) {
      textClass = subdomainAvailable ? "Available" : "Unavailable";
    }

    return (
      <Dialog
        canOutsideClickClose
        icon={IconNames.ADD}
        inline
        isOpen={modalOpen}
        onClose={this.onClose}
        title="New company"
      >
        <div className={Classes.DIALOG_BODY}>
          <Errors messages={this.state.errors} />
          <FormGroup>
            <h4 className={Classes.HEADING}>Display Name</h4>
            <InputGroup
              autoComplete="off"
              id="displayName"
              large
              name="displayName"
              onChange={this.handleFieldChange}
              placeholder="Display Name"
              value={tenant.displayName}
            />
          </FormGroup>
          <FormGroup>
            <div className={styles.nameHeadingWrapper}>
              <h4 className={Classes.HEADING}>
                Client URL
              </h4>
              {tenant.name.length ? (
                <>
                  {valid ? (
                    <>
                      <span className={styles.subtext}>{tenant.name}{import.meta.env.VITE_HOST_SUFFIX}</span>
                      <div className={styles.subdomainCheckerWrapper}>
                        {checkingSubdomainAvailability ? <Spinner className={styles.spinner} size={16} /> : null}
                        {!checkTimeout && !checkingSubdomainAvailability && subdomainAvailable ? (
                          <>
                            <Icon color="#0f9960" icon={IconNames.TICK_CIRCLE} size={16} />
                            <span className={styles[`status${textClass}`]}>Available</span>
                          </>
                        ) : null}
                        {!checkTimeout && !checkingSubdomainAvailability && !subdomainAvailable ? (
                          <>
                            <Icon color="#db3737" icon={IconNames.ERROR} size={14} />
                            <span className={styles[`status${textClass}`]}>Already taken</span>
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <div className={styles.subdomainCheckerWrapper}>
                      <Icon color="#db3737" icon={IconNames.ERROR} size={16} />
                      <span className={styles.errorMessage}>Client URL can only contain lowercase alphanumerical characters</span>
                    </div>
                  )}
                </>
              ) : null}
            </div>
            <InputGroup
              autoComplete="off"
              id="name"
              large
              name="name"
              onChange={this.handleFieldChange}
              placeholder="Client URL"
              value={tenant.name}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.onClose} text="Cancel" />
            <Button
              disabled={creating || !valid || !tenant.displayName.length || !subdomainAvailable || checkingSubdomainAvailability || !!checkTimeout}
              intent={Intent.PRIMARY}
              loading={creating}
              onClick={this.handleSubmit}
              text="Create"
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default compose(
  graphql(createTenant, {name: "createTenant"}),
)(withApollo(NewTenantDialog));
