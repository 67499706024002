import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { graphql } from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Button from "components/Button";
import Errors from "components/Errors";

import { isValidGoogleSheetsUrl } from "./integrations-utilities";

import { REFRESH_GOOGLE_SHEETS_MODELS } from "./graphql";
import { updateTenant } from "../../../graphql";

import styles from "./styles.module.scss";

const GOOGLE_SHEETS_URL_ERROR = "Please enter a valid Google Sheets URL that is of the form https://docs.google.com/spreadsheets/d/somesheetid";

class CompanySettingsGoogle extends React.Component {
  static propTypes = {
    refreshGoogleSheetsModels: PropTypes.func.isRequired,
    toggleLoader: PropTypes.func.isRequired,
    updateTenant: PropTypes.func.isRequired,
    user: PropTypes.object,
  }

  constructor(props) {
    super(props);
    let googleLinks = props.user.tenant.options?.googleSheetsUrl ?? [{ url: "", name: "" }, { url: "", name: "" }, { url: "", name: "" }, { url: "", name: "" }];
    // Backwards compatability check
    if (typeof googleLinks === "string") googleLinks = [{ url: googleLinks }, { url: "", name: "" }, { url: "", name: "" }, { url: "", name: "" }];
    // Add remaining links
    if (googleLinks.length < 3) {
      const diff = 3 - googleLinks.length;
      for (let i = 0; i < diff; i++) {
        googleLinks.push({ url: "" });
      }
    }

    this.state = {
      googleSheetsUrl: googleLinks,
      user: this.props.user,
      showAlert: false,
    };
  }

  changeGoogleSheets = (index, field, value) => {
    const googleSheetsUrl = this.state.googleSheetsUrl;
    googleSheetsUrl[index][field] = value;
    this.setState({ googleSheetsUrl });
  }

  refreshGoogleSheetsModels = () => {
    this.props.toggleLoader();
    this.setState({ saving: true });
    this.props.refreshGoogleSheetsModels()
      .then(() => {
        this.props.toggleLoader();
        this.setState({ errors: [], saving: false });
      }).catch((error) => {
        this.props.toggleLoader();
        console.log(error);
        this.setState({ errors: [], saving: false });
      });
  }

  saveGoogleSheetsUrl = () => {
    let areValidLinks = true;
    for (const link of this.state.googleSheetsUrl) {
      if (link.url && !isValidGoogleSheetsUrl(link.url)) areValidLinks = false;
    }
    if (areValidLinks) {
      this.setState({ saving: true });
      this.props.updateTenant({
        variables: {
          tenant: {
            options: {
              googleSheetsUrl: this.state.googleSheetsUrl,
            },
          },
        },
      }).then(() => {
        this.setState({ errors: [], saving: false });
        window.location.reload();
      }).catch((error) => {
        this.setState({ errors: [], saving: false });
        // eslint-disable-next-line
        console.log("Error refreshing Google.", error);
      });
    } else this.setState({ errors: [GOOGLE_SHEETS_URL_ERROR] });
  }

  render() {
    const officialGoogleSheetsUrl = get(this.state, "user.tenant.options.googleSheetsUrl", "");
    return (
      <span>
        <Errors messages={this.state.errors} />
        <div className={styles.integrationsRow}>
          <div className={styles.col1}>
            {(officialGoogleSheetsUrl !== "") ? (
              <span>
                <p>Connect Google Sheets to Flightpath by sharing your sheet with </p><p><strong>flightpathfinance@flightpath-finance.iam.gserviceaccount.com</strong></p>
                <p><strong>Refresh</strong> button pulls the latest values from the spreadsheet for each account that uses a Google Sheets forecast.</p>
                <p>If you clear the URL below and select Update, all Google Sheets models will be safely deactivated. The model will remain stored with the account and can be reactivated once a new URL is provided.</p>
              </span>
            ) : (
              <span>
                <p>Connect Google Sheets to Flightpath by sharing your sheet with </p><p><strong>flightpathfinance@flightpath-finance.iam.gserviceaccount.com</strong></p>
                <p>This will add your Flightpath account as a user of that spreadsheet. Next add the url for the spreadsheet (eg. https://docs.google.com/spreadsheets/d/somesheetid) below and click Save.</p>
              </span>
            )}
          </div>
          <div className={styles.col2}>
            {(officialGoogleSheetsUrl !== "") ? (
              <Button
                disabled={this.state.saving}
                icon="refresh"
                intent="success"
                onClick={this.refreshGoogleSheetsModels}
              >
                Refresh
              </Button>
            ) : (
              <Button
                disabled={this.state.saving}
                icon="add"
                onClick={this.saveGoogleSheetsUrl}
              >
                Save
              </Button>
            )}
            {(officialGoogleSheetsUrl !== "") ? (
              <Button
                className="m-t-md"
                icon="edit"
                onClick={this.saveGoogleSheetsUrl}
              >
                Update
              </Button>
            ) : null}
          </div>
        </div>
        <div className={styles.integrationsRow}>
          <div className={styles.col1}>
            {this.state.googleSheetsUrl.map((link, index) => (
              <div className="bp5-form-group" key={index}>
                <input
                  autoComplete="off"
                  className="bp5-input bp5-large"
                  dir="auto"
                  onChange={(event) => this.changeGoogleSheets(index, "url", event.target.value)}
                  placeholder="Google Sheets URL"
                  style={{ width: "350px" }}
                  type="text"
                  value={(this.state.googleSheetsUrl[index].url) ? this.state.googleSheetsUrl[index].url : ""}
                />
              </div>
            ))}
          </div>
          <div className={styles.col2}>
            {this.state.googleSheetsUrl.map((link, index) => (
              <div className="bp5-form-group" key={index}>
                <input
                  autoComplete="off"
                  className="bp5-input bp5-large"
                  dir="auto"
                  onChange={(event) => this.changeGoogleSheets(index, "name", event.target.value)}
                  placeholder="Workbook Name"
                  style={{ width: "350px" }}
                  type="text"
                  value={(this.state.googleSheetsUrl[index].name) ? this.state.googleSheetsUrl[index].name : ""}
                />
              </div>
            ))}
          </div>

        </div>
      </span>
    );
  }
}
const CompanySettingsGoogleWithData = compose(
  graphql(REFRESH_GOOGLE_SHEETS_MODELS, { name: "refreshGoogleSheetsModels" }),
  graphql(updateTenant, { name: "updateTenant" }),
)(CompanySettingsGoogle);


export default CompanySettingsGoogleWithData;
