import {useReactiveVar} from "@apollo/client";

import {modelsCacheVar, localModelsCacheVar} from "apollo-config/local-state/models";

export function useModelsCacheForAccount(account) {
  const modelsCache = useReactiveVar(modelsCacheVar);
  const localModelsCache = useReactiveVar(localModelsCacheVar);

  if(!account) return {};
  const id = typeof account === "string" ? account : account.id;

  const localModelsCacheForAccount = localModelsCache[id];
  if(localModelsCacheForAccount) {
    //console.log(`Local modelsCache has been found for account ${id}. Returning it.`);
    return localModelsCacheForAccount;
  }

  const modelsCacheForAccount = modelsCache[id];

  return modelsCacheForAccount || {};
}
