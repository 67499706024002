import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Errors from "components/Errors";
import Button from "components/Button";

import {Classes, Dialog, FormGroup} from "@blueprintjs/core";

import {ALL_PRODUCTS_QUERY, createProduct, updateProduct} from "./graphql";
import {ALL_PLAN_GROUPS_QUERY, RM_ACCOUNTS_QUERY} from "../forecast/graphql";

import ProductDelete from "./ProductDelete";

import styles from "./styles.module.scss";

class ProductCreateUpdate extends React.Component {
  static propTypes = {
    createProduct: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    product: PropTypes.object,
    updateProduct: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      product: {
        id: props.product ? props.product.id : null,
        name: props.product ? props.product.name : "",
        description: props.product ? props.product.description : "",
        plans: props.product ? props.product.plans : [],
      },
      saving: false,
      showDeleteDialog: false,
    };
  }

  closeDialog = () => {
    setTimeout(() => {
      this.props.onClose();
    }, 300);

    this.setState({
      errors: [],
      saving: false,
      isOpen: false,
    });
  }

  onFieldChange = (evt) => {
    this.setState({
      product: {
        ...this.state.product,
        [evt.target.name]: evt.target.value,
      },
    });
  }

  onSave = () => {
    const mutation = !this.state.product.id ? this.props.createProduct : this.props.updateProduct;

    this.setState({saving: true});

    mutation({
      variables: {
        product: {
          id: this.state.product.id,
          name: this.state.product.name,
          description: this.state.product.description,
        },
      },
      refetchQueries: [{query: RM_ACCOUNTS_QUERY}, {query: ALL_PLAN_GROUPS_QUERY}],
      update: (proxy, {data: {createProduct, updateProduct}}) => {
        const queryData = proxy.readQuery({query: ALL_PRODUCTS_QUERY, variables: {includePlans: true}});

        const data = {
          ...queryData,
          products: [...queryData.products],
        };

        if(!this.state.product.id) {
          createProduct.plans = [];
          data.products.push(createProduct);
        } else {
          const index = data.products.findIndex((product) => updateProduct.id === product.id);
          data.products[index] = updateProduct;
          queryData.products[index].plans = this.state.product.plans || [];
        }

        proxy.writeQuery({query: ALL_PRODUCTS_QUERY, variables: {includePlans: true}, data});
      },
    }).then(this.closeDialog).catch((error) => {
      console.log(error);
      this.setState({saving: false});
      this.setState({errors: ["The product could not be created, please check all required fields and try again."]});
    });
  }

  deleteProduct = () => this.setState({showDeleteDialog: true});

  cancelDelete = () => this.setState({showDeleteDialog: false});

  render() {
    const operation = !this.state.product.id ? "create" : "update";
    const title = operation === "create" ? "Create Product" : "Update Product";
    const icon = operation === "create" ? "add" : "annotation";

    return (
      <>
        <Dialog
          canOutsideClickClose={false}
          className={Classes.LARGE}
          icon={icon}
          inline
          isOpen={this.state.isOpen}
          onClose={this.closeDialog}
          title={title}
        >
          <div className={[Classes.DIALOG_BODY, styles.dialogBody].join(" ")}>
            <Errors messages={this.state.errors} />
            <FormGroup>
              <h4 className={Classes.HEADING}>Product Name</h4>
              <input
                autoComplete="off"
                className={Classes.INPUT}
                name="name"
                onChange={this.onFieldChange}
                placeholder="Product Name"
                type="text"
                value={this.state.product.name}
              />
            </FormGroup>

            <FormGroup>
              <h4 className={Classes.HEADING}>Description</h4>
              <textarea
                className={Classes.INPUT}
                name="description"
                onChange={this.onFieldChange}
                placeholder="Product Description"
                value={this.state.product.description}
              />
            </FormGroup>
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              {operation === "update" ? (
                <Button
                  className={styles.removeProduct}
                  disabled={this.state.saving}
                  intent="danger"
                  onClick={this.deleteProduct}
                  text="Delete"
                />
              ) : null}
              <Button
                disabled={this.state.saving}
                onClick={this.closeDialog}
                text="Cancel"
              />
              <Button
                disabled={this.state.saving}
                intent="success"
                loading={this.state.saving}
                onClick={this.onSave}
                text="Save"
              />
            </div>
          </div>
        </Dialog>
        {this.state.showDeleteDialog ? (
          <ProductDelete
            closeDialog={this.closeDialog}
            onCancel={this.cancelDelete}
            product={this.state.product}
          />
        ) : null}
      </>
    );
  }
}

export default compose(
  graphql(createProduct, {name: "createProduct"}),
  graphql(updateProduct, {name: "updateProduct"}),
)(ProductCreateUpdate);
