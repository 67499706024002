import React from "react";
import PropTypes from "prop-types";

import {Spinner} from "components/Spinner";
import Button from "components/Button";
import {Tag} from "@blueprintjs/core";

import ServerLink from "../../../ServerLink";
import CSVButton from "./CSVButton";

import {INTUIT_URL, XERO_URL, BAREMETRICS_URL} from "./index";

import styles from "./styles.module.scss";

export default function NewCompany({
  hideNewCompany,
  saving,
  setErrors,
  setSaving,
}) {
  return (
    <>
      <Spinner
        group="INTEGRATIONS"
        icon="refresh"
        message="Refreshing forecasts with latest data..."
        name="INTEGRATIONS"
      />
      <div className={styles.newCompany}>
        <div className={styles.title}>
          Connect New Company (advanced)
        </div>
        <div className={styles.providerRow}>
          <div className={styles.providerIcon}>
            <img alt="" src={`/baremetrics-icon.png`} />
          </div>
          <div className={styles.providerName}>
            Baremetrics <Tag intent="success">New</Tag>
          </div>
          <div className={styles.button}>
            <ServerLink to={BAREMETRICS_URL}>
              <Button
                disabled={saving}
                width={200}
              >
                Get Started
              </Button>
            </ServerLink>
          </div>
        </div>
        <div className={styles.providerRow}>
          <div className={styles.providerIcon}>
            <img alt="" src={`/quickbooks-icon.png`} />
          </div>
          <div className={styles.providerName}>QuickBooks Online</div>
          <div className={styles.button}>
            <ServerLink to={INTUIT_URL}>
              <Button
                disabled={saving}
                width={200}
              >
                Get Started
              </Button>
            </ServerLink>
          </div>
        </div>
        <div className={styles.providerRow}>
          <div className={styles.providerIcon}>
            <img alt="provider-icon" src={`/xero-icon.png`} />
          </div>
          <div className={styles.providerName}>
            Xero <Tag intent="primary">Beta</Tag>
          </div>
          <div className={styles.button}>
            <ServerLink to={XERO_URL}>
              <Button
                disabled={saving}
                width={200}
              >
                Get Started
              </Button>
            </ServerLink>
          </div>
        </div>
        <div className={styles.providerRow}>
          <div className={styles.providerIcon}>
            <img alt="provider-icon" src={`/csv-icon.png`} />
          </div>
          <div className={styles.providerName}>Upload CSV</div>
          <div className={styles.button}>
            <CSVButton
              hideNewCompany={hideNewCompany}
              saving={saving}
              setErrors={setErrors}
              setSaving={setSaving}
            />
          </div>
        </div>
      </div>
    </>
  );
}

NewCompany.propTypes = {
  hideNewCompany: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  setErrors: PropTypes.func.isRequired,
  setSaving: PropTypes.func.isRequired,
};
