import React from "react";
import { createRoot } from "react-dom/client";
import PropTypes from "prop-types";
import {
  Intent,
  Position,
  ProgressBar,
  OverlayToaster
} from "@blueprintjs/core";
import { spinnerService } from "./SpinnerService";
import classNames from "classnames";

const toaster = OverlayToaster.createAsync({
  className: "loading-toaster",
  position: Position.TOP_RIGHT,
  canEscapeKeyClear: false,
}, {
  domRenderer: (toaster, elementContainer) => createRoot(elementContainer).render(toaster),
});

class SpinnerComponent extends React.Component {
  static propTypes = {
    group: PropTypes.string,
    icon: PropTypes.string,
    message: PropTypes.string,
    name: PropTypes.string,
    show: PropTypes.bool,
    spinnerService: PropTypes.object,
    themeName: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: this.props.hasOwnProperty("show") ? this.props.show : false,
      showing: false,
    };

    if (this.props.hasOwnProperty("spinnerService")) {
      this.spinnerService = this.props.spinnerService;
    } else {
      this.spinnerService = spinnerService;
    }

    this.spinnerService._register(this);
  }

  shouldComponentUpdate(_nextProps, nextState) {
    if (!this.state.show && nextState.show) {
      this.handleProgress();
    }
    return true;
  }

  componentWillUnmount() {
    this.spinnerService._unregister(this);
  }

  get name() {
    return this.props.name;
  }

  get group() {
    return this.props.group;
  }

  get show() {
    return this.state.show;
  }

  set show(show) {
    this.setState({ show });
  }

  renderProgress(amount) {
    return {
      icon: this.props.icon,
      message: (<div>
        <span>{this.props.message}</span>
        <ProgressBar
          className={classNames("docs-toast-progress", { "bp5-no-stripes": amount >= 100 })}
          intent={amount < 100 ? Intent.PRIMARY : Intent.SUCCESS}
          value={amount / 100}
        />
      </div>
      ),
      timeout: amount < 100 ? 0 : 2000,
    };
  }

  async addToast(toast) {
    toast.className = this.props.themeName;
    toast.timeout = 5000;
    (await toaster).show(toast);
  }

  async handleProgress() {
    let progress = 0;
    const t = await toaster;
    const key = t.show(this.renderProgress(0));
    const interval = setInterval(() => {
      if (t == null || progress > 100) {
        clearInterval(interval);
      } else {
        if (progress < 80 && this.state.show) progress += 5 + Math.random() * 10;
        if (!this.state.show) progress = 120;
        t.show(this.renderProgress(progress), key);
      }
    }, 1000);
  }

  render() {
    return (<div />);
  }
}

export default SpinnerComponent;
