import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";
import moment from "moment";

import {Intent, Spinner} from "@blueprintjs/core";

import Modal from "./SnapshotsCreateUpdate";

import {ALL_SNAPSHOTS_QUERY, unarchiveSnapshot, updateSnapshot} from "./graphql";
import {ALL_SNAPSHOTS_QUERY as TABLE_SNAPSHOTS_QUERY} from "../../dashboard/chart-builder/graphql";

import styles from "./styles.module.scss";

class SnapshotRow extends React.Component {
  static propTypes = {
    snapshot: PropTypes.object.isRequired,
    toggleArchiveModal: PropTypes.func.isRequired,
    toggleRemoveModal: PropTypes.func.isRequired,
    unarchiveSnapshot: PropTypes.func.isRequired,
    updateSnapshot: PropTypes.func.isRequired,
  }

  state = {
    updateModalOpen: false,
  }

  toggleUpdateModal = () => {
    this.setState({updateModalOpen: true});
  }

  onCloseModal = () => {
    this.setState({
      updateModalOpen: false,
    });
  }

  unarchiveSnapshot = () => {
    const {snapshot} = this.props;
    this.props.unarchiveSnapshot({
      variables: {
        id: snapshot.id,
      },
      refetchQueries: [{query: ALL_SNAPSHOTS_QUERY}, {query: TABLE_SNAPSHOTS_QUERY}],
    });
  }


  render() {
    const {snapshot, toggleArchiveModal, toggleRemoveModal} = this.props;
    const {updateModalOpen} = this.state;

    return (
      <tr>
        <td className={!snapshot.id ? styles.snapshotNameCellWithSpinner : null}>
          {(snapshot.name) ? snapshot.name : ""}
          {!snapshot.id ? <Spinner className={styles.spinner} intent={Intent.PRIMARY} size={Spinner.SIZE_SMALL} /> : null}
        </td>
        <td className={[styles.snapshotsDescriptionColumn].join(" ")}>{snapshot.description}</td>
        <td>{moment(snapshot.created_date).format("YYYY/MM/DD H:mm:ss A")}</td>
        {!snapshot.archive ? (
          <>
            <td>
              <button
                className="bp5-button bp5-intent-primary bp5-minimal"
                disabled={!snapshot.id}
                onClick={this.toggleUpdateModal}
                type="button"
              >
                Edit Description
              </button>
              {updateModalOpen && <Modal onClose={this.onCloseModal} snapshot={snapshot} />}
            </td>
            <td>
              <button
                className={`bp5-button bp5-intent-primary bp5-minimal`}
                disabled={!snapshot.id}
                onClick={toggleArchiveModal(snapshot.id)}
                type="button"
              >
                Archive
              </button>
            </td>
          </>
        ) : (
          <>
            <td>
              <button
                className={`bp5-button bp5-intent-primary bp5-minimal`}
                disabled={!snapshot.id}
                onClick={this.unarchiveSnapshot}
                type="button"
              >
                Unarchive
              </button>
            </td>
            <td>
              <button
                className="bp5-button bp5-intent-danger bp5-minimal"
                onClick={toggleRemoveModal(snapshot.id)}
                type="button"
              >
                Delete
              </button>
            </td>
          </>
        )}
      </tr>
    );
  }
}

export default compose(
  graphql(updateSnapshot, {name: "updateSnapshot"}),
  graphql(unarchiveSnapshot, {name: "unarchiveSnapshot"}),
)(SnapshotRow);
