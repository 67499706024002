import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import SnapshotRow from "./SnapshotsTableRow";
import Loading from "components/Loading";
import Table from "components/Table";
import TypedConfirmation from "components/TypedConfirmation";

import {evictAllDashboardsFromCache} from "shared/graphql/apolloCacheEviction";

import {ALL_SNAPSHOTS_QUERY, archiveSnapshot, removeSnapshot} from "./graphql";
import {ALL_SNAPSHOTS_QUERY as TABLE_SNAPSHOTS_QUERY} from "../../dashboard/chart-builder/graphql";

import { Classes as BP } from "@blueprintjs/core";
import styles from "./styles.module.scss";

function unique(value, index, self) {
  return self.indexOf(value) === index;
}

class CompanySettingsSnapshotsTable extends React.Component {
  static propTypes = {
    allSnapshotsQuery: PropTypes.object.isRequired,
    archiveSnapshot: PropTypes.func.isRequired,
    archived: PropTypes.bool,
    removeSnapshot: PropTypes.func.isRequired,
  }

  state = {
    archiveSnapshotId: null,
    removeSnapshotId: null,
  }

  toggleArchiveModal = (id) => () => {
    this.setState({archiveSnapshotId: id});
  }

  toggleRemoveModal = (id) => () => {
    this.setState({removeSnapshotId: id});
  }

  archiveSnapshot = () => {
    const {archiveSnapshotId} = this.state;

    this.props.archiveSnapshot({
      variables: {id: archiveSnapshotId},
      refetchQueries: [{query: ALL_SNAPSHOTS_QUERY}, {query: TABLE_SNAPSHOTS_QUERY}],
      update: (cache) => {
        evictAllDashboardsFromCache(cache);
      },
    });
    this.toggleArchiveModal(null)();
  }

  removeSnapshot = () => {
    const {removeSnapshotId} = this.state;

    this.props.removeSnapshot({
      variables: {id: removeSnapshotId},
      update: (cache, {data: {removeSnapshot}}) => {
        evictAllDashboardsFromCache(cache);

        const queryData = cache.readQuery({
          query: ALL_SNAPSHOTS_QUERY,
          variables: {id: removeSnapshotId},
        });
        const data = {
          ...queryData,
          snapshots: queryData.snapshots.filter((snapshot) => snapshot.id !== removeSnapshot.id),
        };
        cache.writeQuery({query: ALL_SNAPSHOTS_QUERY, data});
      },
      optimisticResponse: {
        removeSnapshot: {
          id: removeSnapshotId,
          __typename: "removeSnapshotPayload",
        },
      },
      refetchQueries: [{query: TABLE_SNAPSHOTS_QUERY}],
    });

    this.toggleRemoveModal(null)();
  }

  render() {
    const {archived} = this.props;
    const {archiveSnapshotId, removeSnapshotId} = this.state;

    if(this.props.allSnapshotsQuery && this.props.allSnapshotsQuery.loading) return <Loading />;

    const {snapshots} = this.props.allSnapshotsQuery;
    if(!snapshots) return <Loading />;

    const filteredSnapshots = archived ? snapshots.filter((snapshot) => snapshot.archive === true) : snapshots.filter((snapshot) => snapshot.archive !== true);

    let archivedSnapshot = null;
    if(archiveSnapshotId) archivedSnapshot = snapshots.find((snapshot) => snapshot.id === archiveSnapshotId);

    return (
      <div className="compnay-settings-teams m-t-md">
        {(filteredSnapshots.length) ? (
          <Table className={styles.snapshotsTable}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Date Created</th>
              </tr>
            </thead>
            <tbody>
              {filteredSnapshots.map((snapshot, index) => (
                <SnapshotRow
                  key={index}
                  snapshot={snapshot}
                  toggleArchiveModal={this.toggleArchiveModal}
                  toggleRemoveModal={this.toggleRemoveModal}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <h4 className={BP.HEADING}>{archived ? `No archived snapshots found.` : `No available snapshots.`}</h4>
        )}
        <TypedConfirmation
          confirmButtonIntent="success"
          confirmButtonText="Archive"
          confirmText="archive"
          icon="box"
          isOpen={!!archiveSnapshotId}
          onCancel={this.toggleArchiveModal(null)}
          onConfirm={this.archiveSnapshot}
          title="Archive Confirmation"
        >
          <p>
            Are you sure?&nbsp;
          </p>
          {archivedSnapshot && (archivedSnapshot.chart_lines.length || archivedSnapshot.table_rows.length || archivedSnapshot.table_columns.length) ? (
            <p>
              Please note that archiving this snapshot will permanently remove the links to this snapshot from the following tables/charts on the dashboard:&nbsp;
              <ul>
                {archivedSnapshot.chart_lines.length ? (
                  <li>
                    Charts: <strong>{
                      archivedSnapshot.chart_lines
                      .map((line) => line.chart.name)
                      .filter(unique)
                      .join(", ")
                    }</strong>
                  </li>
                ) : null}
                {archivedSnapshot.table_rows.length ? (
                  <li>
                    Table Rows: <strong>{
                      archivedSnapshot.table_rows
                      .map((row) => row.table.name)
                      .filter(unique)
                      .join(", ")
                    }</strong>
                  </li>
                ) : null}
                {archivedSnapshot.table_columns.length ? (
                  <li>
                    Table Columns: <strong>{
                      archivedSnapshot.table_columns
                      .map((column) => column.table.name)
                      .filter(unique)
                      .join(", ")
                    }</strong>
                  </li>
                ) : null}
              </ul>
            </p>
          ) : null}
          <p>
            To confirm, please type <em>archive</em> below.
          </p>
        </TypedConfirmation>
        <TypedConfirmation
          isOpen={!!removeSnapshotId}
          onCancel={this.toggleRemoveModal(null)}
          onConfirm={this.removeSnapshot}
        >
          <p>
            Are you sure that you want to remove this snapshot?&nbsp;
          </p>
          <p>
            Please type 'delete' in the input field then click <strong>Delete</strong> below if you are sure
            that you would like this snapshot removed.
          </p>
        </TypedConfirmation>
      </div>
    );
  }
}

export default compose(
  graphql(archiveSnapshot, {name: "archiveSnapshot"}),
  graphql(removeSnapshot, {name: "removeSnapshot"}),
  graphql(ALL_SNAPSHOTS_QUERY, {name: "allSnapshotsQuery"}),
)(CompanySettingsSnapshotsTable);
