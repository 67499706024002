export default {
  expansion: [{
    type: "account",
    accountType: "Expansion",
    options: {accountIsEditable: false, numberFormat: "none"},
  }, {
    type: "account",
    accountType: "Expansion %",
    options: {
      percentage: true,
      useTotalOverFormula: false,
    },
  }, {
    type: "account",
    accountType: "Expansion ARPC",
    options: {useTotalOverFormula: false},
  }],
  churn: [{
    type: "account",
    accountType: "Churn",
    options: {accountIsEditable: false, numberFormat: "none"},
  }, {
    type: "account",
    accountType: "Churn %",
    options: {
      percentage: true,
      useTotalOverFormula: false,
    },
  }, {
    type: "account",
    accountType: "Churn ARPC",
    options: {useTotalOverFormula: false},
  }],
  contraction: [{
    type: "account",
    accountType: "Contraction",
    options: {accountIsEditable: false, numberFormat: "none"},
  }, {
    type: "account",
    accountType: "Contraction %",
    options: {
      percentage: true,
      useTotalOverFormula: false,
    },
  }, {
    type: "account",
    accountType: "Contraction ARPC",
    options: {useTotalOverFormula: false},
  }],
};
