import React, { useContext } from "react";
import PropTypes from "prop-types";

import Table from "components/Table";
import { Intent, Spinner } from "@blueprintjs/core";

import { getTableHeaders } from "../../shared/data-utilities";

import TableRowForDisplay from "./TableRowForDisplay";

import { AppContext } from "../../../../AppContext";

import styles from "./CustomTable.module.scss";

export default function CustomTable({ table }) {
  const { forecastStartDate } = useContext(AppContext);

  const categories = getTableHeaders({
    columns: table.columns,
    forecastStartDate,
    table,
  });

  const comparisonColumns = table.type === "ADVANCED" && table.columns.length === 2 && table.options && table.options.showComparison;

  return table.id === 0 ? (
    <Spinner className={styles.spinner} intent={Intent.PRIMARY} size={Spinner.SIZE_SMALL} />
  ) : (
    <Table className={styles.customTable}>
      <thead className={styles.tableHead}>
        <tr>
          <th />
          {categories.map((category, i) => (
            <th className={table.columns && table.columns.length && table.columns[i] && table.columns[i].formatting.borderLeft ? styles.borderLeft : undefined} key={category}>{category}</th>
          ))}
          {comparisonColumns ? ["value", "percentage"].filter((type) => !!table.options[`${type}DeltaColumn`]).map((type) => (
            <th className={table.options[`${type}DeltaFormattingOptions`] && table.options[`${type}DeltaFormattingOptions`].borderLeft ? styles.borderLeft : undefined} key={type}>{table.options[`${type}DeltaColumnName`]}</th>
          )) : null}
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {(table.rows || []).map((row) => (
          <TableRowForDisplay
            categories={categories}
            forecastStartDate={forecastStartDate}
            key={row.id}
            row={row}
            table={table}
          />
        ))}
      </tbody>
    </Table>
  );
}

CustomTable.propTypes = {
  table: PropTypes.object.isRequired,
};
