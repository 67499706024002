import React from "react";
import PropTypes from "prop-types";

import {Button, ButtonGroup, FormGroup} from "@blueprintjs/core";

export default function ForecastFormatting({onChange, value}) {
  const generateOnChangeEvent = (forecastFormatting) => () => {
    onChange({
      target: {
        name: "options",
        value: {
          ...value,
          forecastFormatting,
        },
      },
    });
  };

  const {forecastFormatting} = value;

  return (
    <FormGroup
      label="Forecasted columns formatting"
      labelInfo="*"
    >
      <ButtonGroup>
        <Button
          active={!forecastFormatting || forecastFormatting === "SOLID"}
          onClick={generateOnChangeEvent("SOLID")}
          text="Solid"
        />
        <Button
          active={forecastFormatting === "STRIPED"}
          onClick={generateOnChangeEvent("STRIPED")}
          text="Striped"
        />
      </ButtonGroup>
    </FormGroup>
  );
}

ForecastFormatting.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};
