import React from "react";
import PropTypes from "prop-types";

import {Spinner} from "@blueprintjs/core";

import styles from "./Loading.module.scss";

import {logoWithoutText} from "views/navigation/logos";

export default function Loading({initial = false, size = 200, text = null, unmounted = false}) {
  const classes = [styles.loading];

  if(initial) classes.push(styles.initial);
  if(unmounted) classes.push(styles.unmounted);

  return (
    <div className={classes.join(" ")}>
      <div className={styles.container}>
        {initial ? (
          <img alt="Flightpath Finance Logo" src={logoWithoutText} title="Flightpath Finance Logo" />
        ) : (
          <Spinner intent="primary" size={size} />
        )}
        {text ? (
          <div className={styles.text}>{text}</div>
        ) : null}
      </div>
    </div>
  );
}

Loading.propTypes = {
  initial: PropTypes.bool,
  size: PropTypes.number,
  text: PropTypes.string,
  unmounted: PropTypes.bool,
};
