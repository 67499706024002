import {makeVar} from "@apollo/client";

import {ALL_SCENARIOS_QUERY} from "../../graphql";

import {client} from "../index";

import {flightpathAccountsByIdVar} from "./accounts";
import {createGlobalModelsCache} from "shared/utilities/model-utilities";

let lastAllModelsQuery = null;
let lastModelsCache = null;

export const modelsCacheVar = makeVar({});
export const modelsByIdVar = makeVar({});
export const googleSheetsModelsByIdVar = makeVar({});
export const localModelsCacheVar = makeVar({});

export function updateLocalModelsCacheForAccount({id, modelsCache}) {
  localModelsCacheVar({
    ...localModelsCacheVar(),
    [id]: modelsCache,
  });
}

export function resetLocalModelsCacheForAccount({id}) {
  const localModelsCacheCopy = {...localModelsCacheVar()};
  if(localModelsCacheCopy[id]) {
    delete localModelsCacheCopy[id];
    localModelsCacheVar(localModelsCacheCopy);
  }
}

export function commitLocalModelsCache() {
  const newModelsCache = {
    ...modelsCacheVar(),
    ...localModelsCacheVar(),
  };
  modelsCacheVar(newModelsCache);

  const newGoogleSheetsModelsById = {...googleSheetsModelsByIdVar()};
  const newModelsById = {...modelsByIdVar()};
  for(const {1: scenarios} of Object.entries(newModelsCache)) {
    for(const {1: modelsCache} of Object.entries(scenarios)) {
      for(const [type, model] of Object.entries(modelsCache)) {
        if(type === "google") {
          newGoogleSheetsModelsById[model.id] = model;
        } else {
          newModelsById[model.id] = model;
        }
      }
    }
  };

  googleSheetsModelsByIdVar(newGoogleSheetsModelsById);
  modelsByIdVar(newModelsById);

  localModelsCacheVar({});
}

export function setFromInitialLoadQuery(initialLoadQuery) {
  const {scenarios} = client.readQuery({query: ALL_SCENARIOS_QUERY});

  // If we have a cached modelsCache AND the allModels query has not changed, return the cached modelsCache
  if(lastModelsCache && lastAllModelsQuery === initialLoadQuery.allModels.models) {
    //console.log("Returning cached modelsCache");
    return lastModelsCache;
  }

  //console.time("createGlobalModelsCache");
  const {modelsCache, modelsById, googleSheetsModelsById} = createGlobalModelsCache(initialLoadQuery.allAccounts, initialLoadQuery.allModels, scenarios, initialLoadQuery.allGoogleSheetsModels);
  lastAllModelsQuery = initialLoadQuery.allModels.models;
  lastModelsCache = modelsCache;
  modelsCacheVar(modelsCache);
  modelsByIdVar(modelsById);
  googleSheetsModelsByIdVar(googleSheetsModelsById);
  //console.timeEnd("createGlobalModelsCache");
}

export function mergeDeltaFromSubscription(deltas, overwrite = false) {
  const accounts = Object.values(flightpathAccountsByIdVar());
  const {scenarios} = client.readQuery({query: ALL_SCENARIOS_QUERY});

  if(overwrite) {
    //console.time("overwrite models");
    const modelsById = {};
    const googleSheetsModelsById = {};
    //console.log(`Overwriting models cache with received payload from subscription...`);
    for(const model of deltas.models.update) {
      modelsById[model.id] = model;
    }
    for(const googleSheetsModelsById of deltas.googleSheetsModels.update) {
      googleSheetsModelsById[googleSheetsModelsById.id] = googleSheetsModelsById;
    }

    modelsByIdVar(modelsById);
    googleSheetsModelsByIdVar(googleSheetsModelsById);
    modelsCacheVar(createGlobalModelsCache(accounts, null, scenarios, null, modelsById, googleSheetsModelsById, true));

    //console.timeEnd("overwrite models");

    return;
  }
  const modelsByIdMutation = {...modelsByIdVar()};
  const googleSheetsModelsByIdMutation = {...googleSheetsModelsByIdVar()};
  const modelsUpdated = deltas.models.update?.length || deltas.models.delete?.length;
  const googleSheetsModelsUpdated = deltas.googleSheetsModels.update?.length || deltas.googleSheetsModels.delete?.length;
  if(!modelsUpdated && !googleSheetsModelsUpdated) return; // If no changes are found, stop here

  //console.time(`Process changes to models`);

  for(const type of ["googleSheetsModels", "models"]) {
    const idMapToUpdate = type === "models" ? modelsByIdMutation : googleSheetsModelsByIdMutation;
    //console.log(`Processing ${deltas[type].update?.length ?? 0} created / updated ${type}...`);
    for(const item of (deltas[type].update || [])) {
      idMapToUpdate[item.id] = item;
    }

    //console.log(`Processing ${deltas[type].delete?.length ?? 0} deleted ${type}...`);
    for(const item of (deltas[type].delete || [])) {
      if(idMapToUpdate[item.id]) delete idMapToUpdate[item.id];
    }
  }

  if(modelsUpdated) modelsByIdVar(modelsByIdMutation);
  if(googleSheetsModelsUpdated) googleSheetsModelsByIdVar(googleSheetsModelsByIdMutation);

  const {modelsCache: modelsCacheMutation} = createGlobalModelsCache(accounts, null, scenarios, null, modelsByIdMutation, googleSheetsModelsByIdMutation, true);

  modelsCacheVar(modelsCacheMutation);

  //console.timeEnd(`Process changes to models`);
}
