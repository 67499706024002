import React from "react";
import PropTypes from "prop-types";

import {Icon} from "@blueprintjs/core";

import styles from "./styles.module.scss";

export default function Sidebar({
  children,
  className,
  onClose,
  title,
}) {
  const classes = [styles.sidebar];
  if(className) classes.push(className);

  return (
    <div className={classes.join(" ")}>
      <Icon
        className={styles.closeSidebar}
        icon="small-cross"
        size={20}
        onClick={onClose}
      />
      {title?.length ? (<h3>{title}</h3>) : null}
      {children}
    </div>
  );
}

Sidebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};
