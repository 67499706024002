import { useQuery, useMutation } from '@apollo/client';
import { useState, useEffect, useContext } from "react";
import { Outlet, useParams, useNavigate, Navigate } from "react-router-dom";

import slug from "slugify";

import RenderError from "components/RenderError";
import Button from "components/Button";

import { createYearsArrays } from "../company-settings/snapshots/forecast-settings-utilities";
import Onboarding from "./onboarding";

import { DASHBOARDS_QUERY, CREATE_DASHBOARD } from "./grid/graphql";
import { AppContext } from "../../AppContext";

import styles from "./Dashboard.module.scss";

const Dashboard = (props) => {
  const dashboardsQuery = useQuery(DASHBOARDS_QUERY);
  const [createDefaultDashboard, createCtx] = useMutation(CREATE_DASHBOARD, {
    variables: { dashboard: { name: "Default Dashboard" } },
    refetchQueries: [DASHBOARDS_QUERY],
    onCompleted: (data) => {
      const dashboard = data.createDashboard;
      if (!dashboard) return;
      navigate(`/dashboard/${dashboard.id}-${slug(dashboard.name.toLowerCase())}`);
    },
  });

  const context = useContext(AppContext);
  const params = useParams();
  const navigate = useNavigate();

  if (!context.initialLoadDone && context.setupComplete) context.setChildrenLoading(props.dashboardsQuery.loading);

  const { setupComplete, forecastStartDateSet } = context;

  if (dashboardsQuery.loading) return null;

  if (dashboardsQuery.error) {
    return <RenderError
      message={`Chances are we are working on fixing this, but if it keeps happening, please don't hesitate to <a href="mailto:support@flightpathfinance.com">contact support</a>.`}
      title="There was an error trying to display your dashboard"
    />;
  }

  if (!setupComplete) {
    return <Onboarding
      forecastStartDateSet={forecastStartDateSet}
      setupComplete={setupComplete}
    />;
  }

  if (dashboardsQuery.data.dashboards.length < 1) {
    return (
      <div className={styles.noDashboards}>
        <h3>You currently don't have any dashboards, create one to get started!</h3>
        <Button
          intent="success"
          text="Create Default Dashboard"
          loading={createCtx.loading}
          onClick={createDefaultDashboard}
          icon="add"
        />
      </div>
    );
  }

  if (!Object.hasOwn(params, "dashboard")) {
    const { id: defaultId, name: defaultName } = dashboardsQuery.data.dashboards[0];
    const defaultPath = `/dashboard/${defaultId}-${slug(defaultName.toLowerCase())}`;

    return <Navigate to={defaultPath} />;
  }

  return <Outlet />;
};

export default Dashboard;
