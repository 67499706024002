import React from "react";
import PropTypes from "prop-types";

import {Icon} from "@blueprintjs/core";

import styles from "./TableBuilder.module.scss";

export default function TableHeader({categories, columns, currentlyEditing, onEdit, numberOfColumns, table}) {
  const comparisonColumns = table.type === "ADVANCED" && numberOfColumns === 2 && table.options?.showComparison;
  let columnIndex = 0;

  return categories.length ? (
    <thead>
      <tr>
        <th />
        {categories.map((category, i) => (
          <th
            className={[
              currentlyEditing?.type === "column" && columnIndex === currentlyEditing.index ? styles.active : undefined,
              columns?.length && columns[i]?.formatting.borderLeft ? styles.borderLeft : undefined,
            ].join(" ")}
            key={`${category}${i}`}
            onClick={table.type === "ADVANCED" ? onEdit("column", columnIndex++) : null}
          >
            <div>
              {table.type === "ADVANCED" ? (<div className={styles.editIcon}><Icon icon="edit" /></div>) : null}
              <span>{category}</span>
            </div>
          </th>
        ))}
        {comparisonColumns ? ["value", "percentage"].filter((type) => !!table.options[`${type}DeltaColumn`]).map((type) => (
          <th
            className={[
              currentlyEditing?.type === `${type}DeltaColumn` && columnIndex === currentlyEditing.index ? styles.active : undefined,
              table.options[`${type}DeltaFormattingOptions`] && table.options[`${type}DeltaFormattingOptions`].borderLeft ? styles.borderLeft : undefined,
            ].join(" ")}
            key={type}
            onClick={onEdit(`${type}DeltaColumn`, columnIndex++)}
          >
            <div>
              <div className={styles.editIcon}><Icon icon="edit" /></div>
              <span>{table.options[`${type}DeltaColumnName`] || "Delta"}</span>
            </div>
          </th>
        )) : null}
      </tr>
    </thead>
  ) : null;
}

TableHeader.propTypes = {
  categories: PropTypes.array.isRequired,
  columns: PropTypes.array,
  currentlyEditing: PropTypes.object,
  numberOfColumns: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  table: PropTypes.object.isRequired,
};
