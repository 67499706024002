import React from "react";
import PropTypes from "prop-types";

import numeral from "numeral";

import {Callout, ControlGroup, FormGroup, Icon, Intent, InputGroup, Position, Slider, Tooltip} from "@blueprintjs/core";
import Button from "components/Button";

import {updateLocalEntriesCacheForAccount} from "apollo-config/local-state/entries";

import {fillManualEntriesCache} from "shared/utilities/entry-utilities";
import styles from "./ForecastSidebar.module.scss";

import {AppContext} from "../../AppContext";

class Manual extends React.Component {
  static propTypes = {
    account: PropTypes.object,
    entriesCache: PropTypes.object,
    errors: PropTypes.array,
    models: PropTypes.object,
    scenario: PropTypes.string,
    scenarioFormatted: PropTypes.string,
    scenarios: PropTypes.array,
  }

  state = {
    fill: 0,
    grow: 0,
    interval: 1,
  }

  static contextType = AppContext;

  editManualState = (field, eventType) => ({target: {value}}) => {
    const numeralValue = numeral(value).value();

    if(eventType === "blur") {
      value = numeralValue || 0;
    }

    if(!isNaN(numeralValue) || value === "-" || value === ".") {
      this.setState({
        [field]: value,
      });
    }
  }

  handleFillAccount = () => {
    const {account, entriesCache, scenario, scenarios} = this.props;
    const {forecastStartDate, forecastEndDate, historicalsStartDate} = this.context;

    // Edit all values in the cache
    const fill = (isNaN(this.state.fill)) ? 0 : numeral(this.state.fill).value();
    const grow = (isNaN(this.state.grow)) ? 0 : numeral(this.state.grow).value();
    const interval = (isNaN(this.state.interval)) ? 1 : numeral(this.state.interval).value();
    const updatedEntriesCache = fillManualEntriesCache({
      entriesCache,
      fill,
      forecastEndDate,
      forecastStartDate,
      grow,
      historicalsStartDate,
      scenarioId: scenario,
      scenarios,
      interval,
    });

    updateLocalEntriesCacheForAccount({id: account.id, entriesCache: updatedEntriesCache});
    this.setState({entriesCache: updatedEntriesCache});
  }

  highlightAll = () => {
    setTimeout(() => {
      document.execCommand("selectAll", false, null);
    }, 0);
  }

  render() {
    return (
      <div>
        {(this.props.errors?.length) ? (
          <Callout intent={Intent.WARNING} style={{marginBottom: "1em"}}>
            {this.props.errors.map((e) => `${e} `)}
          </Callout>
        ) : null}

        <FormGroup
          className={`${styles.manualFormGroup} m-t`}
          label="Fill"
          labelFor="fill"
        >
          <ControlGroup>
            <InputGroup
              autoComplete="off"
              onBlur={this.editManualState("fill", "blur")}
              onChange={this.editManualState("fill", "focus")}
              onFocus={this.highlightAll}
              placeholder="Fill Manual Forecast"
              value={this.state.fill}
            />
            <Tooltip content="Copies over the forecast value across the account." position={Position.TOP}><Icon className={styles.helpIcon} icon="help" iconSize={16} /></Tooltip>
          </ControlGroup>
        </FormGroup>

        <FormGroup
          className="m-t"
          label="Grow"
          labelFor="grow"
        >
          <ControlGroup>
            <InputGroup
              autoComplete="off"
              onBlur={this.editManualState("grow", "blur")}
              onChange={this.editManualState("grow", "focus")}
              onFocus={this.highlightAll}
              placeholder="Grow Amount"
              value={this.state.grow}
            />
            <Tooltip content="Grows the forecast by this amount each month." position={Position.TOP}><Icon className={styles.helpIcon} icon="help" iconSize={16} /></Tooltip>
          </ControlGroup>
        </FormGroup>
        <FormGroup
          className="m-t"
          label="Copy Interval (Months)"
          labelFor="interval"
        >
          <ControlGroup>
            <Slider
              onChange={(newValue) => this.setState({ interval: newValue })}
              onFocus={this.highlightAll}
              value={this.state.interval}
              min={1}
              max={12}
              stepSize={1}
              labelStepSize={1}
            />
          </ControlGroup>
        </FormGroup>
        <Button
          className="m-t"
          onClick={this.handleFillAccount}
          size="small"
          text="Copy Across"
        />
      </div>
    );
  }
}

export default Manual;
