import { makeVar } from "@apollo/client";

export const flightpathAccountsByIdVar = makeVar({});
export const snapshotAccountsByIdVar = makeVar({});

export function setFromInitialLoadQuery(initialLoadQuery) {
  const accountsById = {};
  for(const account of initialLoadQuery.allAccounts) {
    accountsById[account.id] = account;
  }

  flightpathAccountsByIdVar(accountsById);
}

export function addAccountToCache(account) {
  // Update parent children property if needed, which returns the modified caches
  const {accountsById: newFlightpathAccountsById} = updateParents("add", account, {...flightpathAccountsByIdVar()});

  flightpathAccountsByIdVar({
    ...newFlightpathAccountsById,
    [account.id]: account,
  });
}

export function updateAccountInCache(account) {
  // Update parent children property if needed, which returns the modified caches
  const {accountsById: newFlightpathAccountsById} = updateParents("update", account, {...flightpathAccountsByIdVar()});

  flightpathAccountsByIdVar({
    ...newFlightpathAccountsById,
    [account.id]: account,
  });
}

export function removeAccountFromCache(account) {
  // Update parent children property if needed, which returns the modified caches
  const {accountsById: newFlightpathAccountsById} = updateParents("remove", account, {...flightpathAccountsByIdVar()});

  if(newFlightpathAccountsById[account.id]) delete newFlightpathAccountsById[account.id];

  flightpathAccountsByIdVar(newFlightpathAccountsById);
}

function updateParents(type, account, accountsById) {
  // If the account has a parent, update that parent to account for its new child
  if(account.parent?.id) {
    const parent = accountsById[account.parent.id];
    const oldParentId = accountsById[account.id]?.parent?.id;

    // Make sure it's not already in the children of the parent
    if(["add", "update"].includes(type) && !parent.children?.find((child) => child.id === account.id)) {
      (import.meta.env.DEV) && console.debug(`[Cache] Add / Update: Adding ${account.id} to ${parent.id}...`);
      accountsById[parent.id] = {
        ...accountsById[parent.id],
        children: [
          ...accountsById[parent.id].children,
          {id: account.id},
        ],
      };
    }

    // For update, we also need to remove the id from the parents children list
    if(type === "update" && !!oldParentId && oldParentId !== parent.id) {
      (import.meta.env.DEV) && console.debug(`[Cache] Update: Removing child ${account.id} from old parent ${accountsById[oldParentId].id}...`);
      accountsById[oldParentId] = {
        ...accountsById[oldParentId],
        children: [
          ...(accountsById[oldParentId].children || []).filter((child) => child.id !== account.id),
        ],
      };
    }

    if(["remove"].includes(type) && parent.children?.find((child) => child.id === account.id)) {
      (import.meta.env.DEV) && console.debug(`[Cache] Remove: Removing child ${account.id} from ${parent.id}...`);
      accountsById[parent.id] = {
        ...accountsById[parent.id],
        children: [
          ...(parent.children || []).filter((child) => child.id !== account.id),
        ],
      };
    }
  }

  return { accountsById };
}
