import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

function Switcher({disabled, label, onChange, options, selectedValue}) {
  const classes = [styles.switcher];
  if(disabled) classes.push(styles.disabled);
  if(label) classes.push(styles.withLabel);

  return (
    <div className={classes.join(" ")}>
      {label ? (<label>{label}</label>) : null}
      <select
        disabled={disabled}
        onChange={!disabled ? onChange : null}
        value={selectedValue}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}

Switcher.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Switcher;
