import React from "react";
import PropTypes from "prop-types";

import {useQuery} from "@apollo/client";

import {ALL_PLAN_GROUPS_QUERY} from "views/revenue-model/forecast/graphql";
import {ALL_PRODUCTS_QUERY} from "views/revenue-model/chart-of-plans/graphql";
import {ALL_TEAMS_QUERY} from "views/company-settings/payroll/graphql";
import {useAllFlightpathAccounts} from "shared/hooks/account-hooks";

import FormFieldAccount from "../../../dashboard/shared/fields/account";

export default function AccountSelector({
  accountId = "1",
  onChange,
}) {
  const accounts = useAllFlightpathAccounts();
  const {data: {plan_groups: planGroups} = {}, loading: productsLoading} = useQuery(ALL_PLAN_GROUPS_QUERY);
  const {data: {products} = {}, loading: planGroupsLoading} = useQuery(ALL_PRODUCTS_QUERY);
  const {data: {teams} = {}, loading: teamsLoading} = useQuery(ALL_TEAMS_QUERY);

  return (
    <FormFieldAccount
      accounts={accounts.filter((account) => !account.ignore)}
      label="Account Name"
      loading={productsLoading || planGroupsLoading || teamsLoading}
      onChange={onChange}
      planGroups={planGroups}
      products={products}
      selectedAccountId={accountId}
      teams={teams}
    />
  );
}

AccountSelector.propTypes = {
  accountId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
