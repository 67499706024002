import React from "react";
import PropTypes from "prop-types";

import Table from "components/Table";
import {Alert, Intent} from "@blueprintjs/core";

import {AppContext} from "../../../AppContext";

class TeamTable extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;

    this.state = {
      user: {
        id: null,
        email: "",
        name: "",
        role: "",
      },
      alertId: null,
      showAlert: false,
    };

    this.edit = this.edit.bind(this);
    this.remove = this.remove.bind(this);
    this.save = this.save.bind(this);
    this.updateField = this.updateField.bind(this);
    this.toggleConfirmDeleteAlert = this.toggleConfirmDeleteAlert.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
  }

  static contextType = AppContext;

  edit(id, email, name, role) {
    this.setState({user: {id, name, email, role}});
  }

  remove(id) {
    this.toggleConfirmDeleteAlert(id);
  }

  save() {
    this.props.onSave(this.state.user);

    this.setState({
      user: {
        id: null,
        email: "",
        role: "",
        name: "",
      },
    });
  }

  toggleConfirmDeleteAlert(id) {
    const showAlert = !this.state.showAlert;
    this.setState({showAlert, alertId: id});
  }

  confirmRemove() {
    this.props.onRemove(this.state.alertId);
    this.toggleConfirmDeleteAlert(null);
  }

  actionButtons(id, email, name, role, google_id, user) {
    if(user.email === email) return (<div />);
    else {
      return (
        <div className="bp5-button-group bp5-minimal">
          {this.saveEditButtons(id, email, name, role)}
          <button
            className="bp5-button bp5-icon-delete"
            onClick={() => this.remove(id)}
            tabIndex="1"
          >
            Remove
          </button>
        </div>
      );
    }
  }

  saveEditButtons(id, email, name, role) {
    if(id === this.state.user.id) {
      return (
        <button
          className="bp5-button bp5-icon-tick"
          onClick={this.save}
          tabIndex="0"
        >
          Save
        </button>
      );
    }

    return (
      <button
        className="bp5-button bp5-icon-edit"
        onClick={() => this.edit(id, email, name, role)}
        tabIndex="0"
      >
        Edit
      </button>
    );
  }

  updateField(fieldName, event) {
    const state = {...this.state};
    state.user[fieldName] = event.target.value;

    this.setState(state);
  }

  render() {
    const {user} = this.context;

    return (
      <>
        <Alert
          canEscapeKeyCancel
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          icon="trash"
          intent={Intent.DANGER}
          isOpen={this.state.showAlert}
          onCancel={this.toggleConfirmDeleteAlert}
          onClose={this.toggleConfirmDeleteAlert}
          onConfirm={this.confirmRemove}
        >
          <p>Are you sure you want to delete this user?</p>
        </Alert>
        <Table className="m-t-md">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(!this.props.users || this.props.users.length === 0) ? (
              <tr>
                <td colSpan="6">No team members to show.</td>
              </tr>
            ) : this.props.users.map((u) => (
              <tr key={u.id}>
                {(u.id === this.state.user.id) ?
                  [
                    <td key={`${u.id}name`}>
                      {!u.google_id ? (
                        <input
                          autoComplete="off"
                          className="bp5-input"
                          onChange={(e) => this.updateField("name", e)}
                          type="text"
                          value={this.state.user.name || ""}
                        />
                      ) : u.name || u.displayName || ""}
                    </td>,
                    <td key={`${u.id}email`}>
                      {!u.google_id ? (
                        <input
                          autoComplete="off"
                          className="bp5-input"
                          onChange={(e) => this.updateField("email", e)}
                          type="text"
                          value={this.state.user.email}
                        />
                      ) : u.email}
                    </td>,
                    <td key={`${u.id}role`}>
                      <select
                        className="bp5-input bp5-inline"
                        onChange={(event) => this.updateField("role", event)}
                        value={this.state.user.role}
                      >
                        <option>ADMIN</option>
                        <option>EDIT</option>
                        <option>VIEW</option>
                      </select>
                    </td>,
                    <td key={`${u.id}gid`}>{ u.google_id ? "Active" : "Inactive" }</td>,
                  ] : [
                    <td key={`${u.id}name`}>{u.name || u.displayName}</td>,
                    <td key={`${u.id}email`}>{u.email}</td>,
                    <td key={`${u.id}role`}>{u.role === "SUPER" ? "ADMIN" : u.role}</td>,
                    <td key={`${u.id}gid`}>{(u.hasPassword || u.google_id) ? "Active" : "Inactive"}</td>,
                  ]
                }
                <td style={{"textAlign": "right"}}>
                  {this.actionButtons(u.id, u.email, u.name, u.role, u.google_id, user)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

TeamTable.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  users: PropTypes.array,
};

export default TeamTable;
