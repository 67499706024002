import { useState, useRef } from "react";
import { useOutletContext, useNavigate, useSearchParams, useLocation } from "react-router-dom";

import PropTypes from "prop-types";

import { Link, Route, Outlet } from "react-router-dom";

import Button from "components/Button";
import Card from "components/Card";
import Input from "components/Input";

import ServerLink from "../ServerLink";
import MfaCodeInput from "./MfaCodeInput";
import ForgotPassword from "./ForgotPassword";

import styles from "./styles/Login.module.scss";
import { Classes as BP } from "@blueprintjs/core";

const PATH_STATE_MAP = {
  "/": "signin",
  "/login/accept-invite": "signin",
  "/login/accept-invite/email": "emailSignup",
  "/login/reset-password": "forgotPassword",
}

const googleUrl = `/api/auth/google`;

export default function Login(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [step, setStep] = useState(() => (location.pathname) in PATH_STATE_MAP ? PATH_STATE_MAP[location.pathname] : "signin");

  const [code, setCode] = useState(null);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [password, setPassword] = useState("");

  const mfaCodeInput = useRef();

  const onChangeEmail = (evt) => {
    setError("");
    setEmail(evt.target.value);
  }

  const onChangePassword = (evt) => {
    setError("");
    setPassword(evt.target.value);
  }

  const onKeyPress = (evt) => {
    if (evt.key === "Enter" && !loading) {
      onLoginClick();
    }
  }

  const onCodeChange = (code) => {
    setError("");
    setCode(code);
  }

  const onEmailSignup = (inviteToken) => () => {
    setStep("emailSignup");
    navigate(`/login/accept-invite/email?inviteToken=${inviteToken}`);
  }

  const onLoginClick = () => {
    setLoading(true);
    fetch("/api/auth/local", {
      method: "POST",
      body: JSON.stringify({
        username: email,
        password: password,
      }),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const data = await response.json();
      if (data.error) {
        setError(data.error);
        setLoading(false);
      } else {
        if (data.mfa === true) {
          setLoading(false);
          setName(data.name);
          setStep("mfa");
        } else {
          window.location = "/";
        }
      }
    });
  }

  const onCodeVerify = () => {
    setLoading(true);
    fetch("/api/auth/local/mfa", {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
        code,
      }),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const data = await response.json();
      if (data.error) {
        setLoading(false);
        setError(data.error);
        mfaCodeInput.current.reset();
      } else {
        window.location = "/";
      }
    });
  }

  const onForgotPasswordClick = () => {
    setStep("forgotPassword");
  }

  const inviteToken = searchParams.get("inviteToken")
  const isInvite = !!inviteToken;

  return (
    <div className={styles.login}>
      <Card className={[styles.card, styles[step]].join(" ")}>
        <div className={styles.wrapper}>
          {step === "signin" ? (
            <div className={styles.signin}>
              <h3 className={BP.HEADING}>{!isInvite ? "Sign in to Continue" : "Activate Your Account"}</h3>

              <ServerLink target="_self" to={googleUrl}>
                <Button
                  disabled={loading}
                  fill
                  icon={(
                    <span className={styles.googleIcon}>
                      <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg"><path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" fill="#EA4335" id="Shape" /><path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" fill="#FBBC05" id="Shape" /><path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" fill="#4285F4" id="Shape" /><path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853" /></svg>
                    </span>
                  )}
                  intent="success"
                >
                  Sign {!isInvite ? "in" : "up"} with Google
                </Button>
              </ServerLink>

              <h3>OR</h3>

              {!isInvite ? (
                <>
                  {error.length ? <div className={styles.error}>{error}</div> : null}

                  <form className={[styles.form, styles.email].join(" ")}>
                    <Input
                      autoComplete="off"
                      disabled={loading}
                      id="email"
                      name="email"
                      onChange={onChangeEmail}
                      placeholder="Email"
                      type="text"
                    />

                    <Input
                      disabled={loading}
                      id="password"
                      name="password"
                      onChange={onChangePassword}
                      onKeyPress={onKeyPress}
                      placeholder="Password"
                      type="password"
                    />
                  </form>
                </>
              ) : null}
              <Button
                fill
                icon="lock"
                intent="success"
                loading={loading}
                onClick={!isInvite ? onLoginClick : onEmailSignup(inviteToken)}
              >
                Sign {!isInvite ? "in" : "up"} with Email
              </Button>

              <p className={[BP.TEXT_MUTED, BP.RUNNING_TEXT, styles.signinAgreement].join(" ")}>
                By clicking Sign in, you agree to Flightpath's <a href="https://flightpathfinance.com/terms-and-conditions/" target="blank">Terms of Use</a> and <a href="https://flightpathfinance.com/privacy-policy/" target="blank">Privacy Policy</a>. If you have questions or have problems signing in, please <a href="mailto:support@flightpathfinance.com">contact our support</a>.
              </p>
              {!isInvite ? (
                <p className={[BP.TEXT_MUTED, BP.RUNNING_TEXT, styles.signinAgreement].join(" ")}>
                  <Link onClick={onForgotPasswordClick} to="/login/reset-password">Reset Password</Link>
                </p>
              ) : null}
            </div>
          ) : step === "mfa" ? (
            <div className={styles.mfa}>
              <h3 className={BP.HEADING}>Multi-factor Authentication</h3>

              <p className={[BP.TEXT_MUTED, BP.RUNNING_TEXT, styles.mfaText].join(" ")}>
                Multi-factor Authentication is enabled for your account. Please verify your identity by entering the 6-digit code from your authenticator app on your mobile device.
              </p>

              {error.length ? <div className={styles.error}>{error}</div> : null}

              <MfaCodeInput
                loading={loading}
                onCodeChange={onCodeChange}
                onEnterKeyPress={onCodeVerify}
                ref={mfaCodeInput}
              />

              <div>
                <Button
                  disabled={!code}
                  fill
                  icon="lock"
                  intent="success"
                  loading={loading}
                  onClick={onCodeVerify}
                >
                  Verify
                </Button>
              </div>

              <p className={[BP.TEXT_MUTED, BP.RUNNING_TEXT, styles.signinAgreement].join(" ")}>
                If you don't have access to your authenticator app to retrieve your code, please <a href="mailto:support@flightpathfinance.com">contact our support</a>.
              </p>
            </div>
          ) : (
            <Outlet context={{
              inviteToken,
              email: email,
              setStep,
            }} />
          )}
        </div>
      </Card>
    </div>
  );
}

// }
//
// export default Login;
