import {createInitialDateRange} from "shared/utilities/date-utilities";
import {getAccountFromCache} from "shared/utilities/account-utilities";
import {generateDataSeries} from "../../shared/data-utilities";

export function createCustomChartSeries({
  account,
  account_id,
  column_type,
  daterange,
  forecastStartDate,
  forecastStartDateSnapshot,
  name,
  reference_snapshot_id,
  report_period,
  scenarioId,
  type,
}) {
  const defaultDateRange = {dates: createInitialDateRange(forecastStartDate, "THIS_YEAR"), monthsAgo: 0, monthsAhead: 12};
  daterange = (daterange?.dates?.start?.year) ? daterange : defaultDateRange;

  // Update the dates to be the latest for visuals because stored dates might not be correct if report period is dynamic
  if(report_period !== "CUSTOM") {
    daterange = {
      ...daterange,
      dates: createInitialDateRange(forecastStartDate, report_period, daterange.monthsAgo, daterange.monthsAhead),
    };
  }

  if(!account) account = getAccountFromCache(account_id);

  const {data, firstForecastedIndex} = generateDataSeries({
    account,
    column_type,
    daterange,
    forecastStartDate,
    forecastStartDateSnapshot,
    reference_snapshot_id,
    report_period,
    scenarioId,
  });

  return {
    dashStyle: !!reference_snapshot_id ? "dash" : "solid",
    data,
    firstForecastedIndex,
    name,
    type,
  };
}
