import { useAllFlightpathAccounts } from "./account-hooks";
/*import {useEntriesCacheForAccount} from "./entry-hooks";
import {useModelsCacheForAccount} from "./model-hooks";*/

import { useParams } from "react-router-dom";

export const withHooks = (Component, hooks = []) => (props) => {
  const componentAdditionalProps = {};
  const allFlightpathAccounts = useAllFlightpathAccounts();

  componentAdditionalProps.routerParams = useParams();

  if (hooks.includes("useAllFlightpathAccounts")) componentAdditionalProps.allFlightpathAccounts = allFlightpathAccounts;

  return <Component {...props} {...componentAdditionalProps} />;
};
