import {gql} from "@apollo/client";

export const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      name
      super
      email
      tenants {
        role
        tenant {
          name
          displayName
        }
      }
      appsumo {
        setup_complete
        license_key
        license_status
        tier
      }
    }
  }
`;

export const CHECK_SUBDOMAIN_AVAILABILITY = gql`
  query subdomainAvailability($name: String!) {
    subdomainAvailability(name: $name)
  }
`;

export const USER_FROM_INVITE_TOKEN_QUERY = gql`
  query getUserFromInviteToken($inviteToken: String!) {
    getUserFromInviteToken(inviteToken: $inviteToken) {
      name
      email
    }
  }
`;


export const createTenant = gql`
  mutation createTenant($name: String!, $displayName: String!) {
    createTenant(name: $name, displayName: $displayName)
  }
`;

export const resetTenant = gql`
  mutation resetTenant($name: String!) {
    resetTenant(name: $name)
  }
`;

export const removeTenant = gql`
  mutation removeTenant($name: String!) {
    removeTenant(name: $name)
  }
`;

export const resetPassword = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;
