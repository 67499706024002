import {makeVar} from "@apollo/client";

import {createEntriesCache} from "shared/utilities/entry-utilities";

let lastAllEntriesQuery = null;
let lastEntriesCache = null;

export const entriesCacheVar = makeVar({});
export const entriesByIdVar = makeVar({});
export const snapshotEntriesByIdVar = makeVar({});
export const localEntriesCacheVar = makeVar({});

export function updateLocalEntriesCacheForAccount({id, entriesCache}) {
  if(entriesCache) {
    localEntriesCacheVar({
      ...localEntriesCacheVar(),
      [id]: entriesCache,
    });
  }
}

export function resetLocalEntriesCacheForAccount({id}) {
  const localEntriesCacheCopy = {...localEntriesCacheVar()};
  if(localEntriesCacheCopy[id]) {
    delete localEntriesCacheCopy[id];
    localEntriesCacheVar(localEntriesCacheCopy);
  }
}

export function commitLocalEntriesCache() {
  const localEntriesCache = localEntriesCacheVar();

  //console.time("Updating entriesCacheVar");
  entriesCacheVar({
    ...entriesCacheVar(),
    ...localEntriesCacheVar(),
  });
  //console.timeEnd("Updating entriesCacheVar");

  //console.time("Updating localEntriesCacheVar");
  localEntriesCacheVar({});
  //console.timeEnd("Updating localEntriesCacheVar");

  // Also apply to entriesById cache
  //console.time("Updating entriesById");
  const updatesById = {};
  for(const accountId of Object.keys(localEntriesCache)) {
    for(const scenarioKey of Object.keys(localEntriesCache[accountId])) {
      for(const dateKey of Object.keys(localEntriesCache[accountId][scenarioKey])) {
        for(const type of Object.keys(localEntriesCache[accountId][scenarioKey][dateKey])) {
          updatesById[localEntriesCache[accountId][scenarioKey][dateKey][type].id] = localEntriesCache[accountId][scenarioKey][dateKey][type];
        }
      }
    }
  }
  entriesByIdVar({
    ...entriesByIdVar(),
    ...updatesById,
  });
  //console.timeEnd("Updating entriesById");
}

export function setFromInitialLoadQuery(initialLoadQuery) {
  // If we have a cached entriesCache AND the allEntries query has not changed, return the cached entriesCache
  if(lastEntriesCache && lastAllEntriesQuery === initialLoadQuery.allEntries.entries) {
    //console.log("Returning cached entriesCache");
    return lastEntriesCache;
  }

  /*console.log("Generating global entries cache...");

  console.time("createEntriesCache");
  console.log(`${initialLoadQuery.allEntries.entries.length} entries in the cache`);*/
  const {entriesCache, entriesById} = createEntriesCache(initialLoadQuery.allEntries.entries);
  lastEntriesCache = entriesCache;
  lastAllEntriesQuery = initialLoadQuery.allEntries.entries;
  //console.timeEnd("createEntriesCache");

  //console.time("write var entriesCache");
  entriesCacheVar(entriesCache);
  entriesByIdVar(entriesById);
  //console.timeEnd("write var entriesCache");
}

export function mergeDeltaFromSubscription(deltas, overwrite = false) {
  const entriesByIdMutation = {...entriesByIdVar()};

  if(overwrite) {
    //console.time("overwrite entries");
    const entriesById = {
      ...snapshotEntriesByIdVar(),
    };
    //console.log(`Overwriting entries cache with received payload from subscription...`);
    for(const entry of deltas.update) {
      entriesById[entry.id] = entry;
    }

    entriesCacheVar(createEntriesCache(null, entriesById, true).entriesCache);
    entriesByIdVar(entriesById);

    //console.timeEnd("overwrite entries");

    return;
  }

  
  let entriesCacheMutation = null;
  let recreateEntriesCache = false;
  let entriesCacheUpdated = false;
  //console.time(`Process changes to entries`);
  // When too many updates, skip updating the entriesCache in place, as recreating it from scratch will be faster
  if(deltas.update.length > 1000) {
    //console.log("Skipping entriesCache smart update (too many entries)");
    recreateEntriesCache = true;
  } else {
    entriesCacheMutation = {...entriesCacheVar()};
    //entriesCacheMutation = cloneDeep(entriesCacheVar());
  }

  //console.log(`Processing ${deltas.update?.length ?? 0} created / updated entries...`);
  for(const item of (deltas.update || [])) {
    // Update it in entriesById first (easiest and fastest)
    entriesByIdMutation[item.id] = item;
    // Set entriesCacheUpdated to true, to make sure we actually update the store at the end.
    // If no entries have been updated, created or deleted, it's safe (and more efficient) to skip the cache update completely
    entriesCacheUpdated = true;
    if(!recreateEntriesCache) { // That means we are "smart" updating the entriesCache without recreating it all
      const dateParts = item.date.split("-");
      const dateKey = `${dateParts[0]}-${dateParts[1]}`;
      if(entriesCacheMutation[item.account_id]?.[item.scenario_id || "null"]?.[dateKey]?.[item.type]) {
        entriesCacheMutation[item.account_id] = {
          ...entriesCacheMutation[item.account_id] || {},
          [item.scenario_id || "null"]: {
            ...entriesCacheMutation[item.account_id][item.scenario_id || "null"] || {},
            [dateKey]: {
              ...entriesCacheMutation[item.account_id][item.scenario_id || "null"][dateKey] || {},
              [item.type]: item,
            },
          },
        };
      }
    }
  }

  //console.log(`Processing ${deltas.delete?.length ?? 0} deleted entries...`);
  for(const item of (deltas.delete || [])) {
    let oldEntry = null;
    if(entriesByIdMutation[item.id]) {
      oldEntry = entriesByIdMutation[item.id];
      delete entriesByIdMutation[item.id];
    }
    entriesCacheUpdated = true;
    if(!recreateEntriesCache) {
      const dateParts = oldEntry.date.split("-");
      const dateKey = `${dateParts[0]}-${dateParts[1]}`;
      const scenarioKey = item.scenario_id || "null";
      if(entriesCacheMutation[oldEntry.account_id][scenarioKey][dateKey][oldEntry.type]) delete entriesCacheMutation[oldEntry.account_id][scenarioKey][dateKey][oldEntry.type];
    }
  }

  if(entriesCacheUpdated) {
    entriesCacheVar(recreateEntriesCache ? createEntriesCache(null, entriesByIdMutation, true).entriesCache : entriesCacheMutation);
    entriesByIdVar(entriesByIdMutation);
  }
  //console.timeEnd(`Process changes to entries`);
}

// Threaded merge delta function. Might come in handy later, but need to avoid calling createEntriesCache
/*
export function mergeDeltaFromSubscription(deltas) {

  const p = new Parallel({deltas, entriesCache: entriesCacheVar(), entryesById: entriesByIdVar()});
  p.spawn(({deltas, entriesCache, entryesById}) => {
    console.time(`Process changes to entries`);
    const entriesByIdMutation = {...entryesById};
    let entriesCacheMutation = null;
    let recreateEntriesCache = false;
    let entriesCacheUpdated = false;

    // When too many updates, skip updating the entriesCache in place, as recreating it from scratch will be faster
    if(deltas.update.length > 1000) {
      console.log("Skipping entriesCache smart update (too many entries)");
      recreateEntriesCache = true;
    } else {
      entriesCacheMutation = {...entriesCache};
      //entriesCacheMutation = cloneDeep(entriesCacheVar());
    }

    console.log(`Processing ${deltas.update?.length ?? 0} updated entries...`);
    for(const item of (deltas.update || [])) {
      // Update it in entriesById first (easiest and fastest)
      entriesByIdMutation[item.id] = item;
      // Set entriesCacheUpdated to true, to make sure we actually update the store at the end.
      // If no entries have been updated, created or deleted, it's safe (and more efficient) to skip the cache update completely
      entriesCacheUpdated = true;
      if(!recreateEntriesCache) { // That means we are "smart" updating the entriesCache without recreating it all
        const dateParts = item.date.split("-");
        const dateKey = `${dateParts[0]}-${dateParts[1]}`;
        if(entriesCacheMutation.[item.account_id]?.[item.scenario_id || "null"]?.[dateKey]?.[item.type]) {
          entriesCacheMutation[item.account_id] = {
            ...entriesCacheMutation[item.account_id],
            [item.scenario_id || "null"]: {
              ...entriesCacheMutation[item.account_id][item.scenario_id || "null"],
              [dateKey]: {
                ...entriesCacheMutation[item.account_id][item.scenario_id || "null"][dateKey],
                [item.type]: item,
              },
            },
          };
        }
      }
    }

    console.log(`Processing ${deltas.create?.length ?? 0} created entries...`);
    for(const item of (deltas.create || [])) {
      entriesByIdMutation[item.id] = item;
      entriesCacheUpdated = true;
      if(!recreateEntriesCache) {
        const dateParts = item.date.split("-");
        const dateKey = `${dateParts[0]}-${dateParts[1]}`;
        const scenarioKey = item.scenario_id || "null";
        if(!entriesCacheMutation[item.account_id]) entriesCacheMutation[item.account_id] = {};
        if(!entriesCacheMutation[item.account_id][scenarioKey]) entriesCacheMutation[item.account_id][scenarioKey] = {};
        if(!entriesCacheMutation[item.account_id][scenarioKey][dateKey]) entriesCacheMutation[item.account_id][scenarioKey][dateKey] = {};
        if(!entriesCacheMutation[item.account_id][scenarioKey][dateKey][item.type]) entriesCacheMutation[item.account_id][scenarioKey][dateKey][item.type] = {};
        entriesCacheMutation.entriesCache[item.account_id][scenarioKey][dateKey][item.type] = item;
      }
    }

    console.log(`Processing ${deltas.delete?.length ?? 0} deleted entries...`);
    for(const item of (deltas.delete || [])) {
      let oldEntry = null;
      if(entriesByIdMutation[item.id]) {
        oldEntry = entriesByIdMutation[item.id];
        delete entriesByIdMutation[item.id];
      }
      entriesCacheUpdated = true;
      if(!recreateEntriesCache) {
        const dateParts = oldEntry.date.split("-");
        const dateKey = `${dateParts[0]}-${dateParts[1]}`;
        const scenarioKey = item.scenario_id || "null";
        if(entriesCacheMutation[oldEntry.account_id][scenarioKey][dateKey][oldEntry.type]) delete entriesCacheMutation[oldEntry.account_id][scenarioKey][dateKey][oldEntry.type];
      }
    }

    if(entriesCacheUpdated) entriesCacheMutation = createEntriesCache(null, entriesByIdMutation, true).entriesCache;

    console.timeEnd(`Process changes to entries`);
    console.log({entriesCacheUpdated, entriesCacheMutation, entriesByIdMutation});
    return {entriesCacheUpdated, entriesCacheMutation, entriesByIdMutation};
  }).then(({entriesCacheUpdated, entriesCacheMutation, entriesByIdMutation}) => {
    console.log({entriesCacheUpdated, entriesCacheMutation, entriesByIdMutation});
    if(entriesCacheUpdated) {
      entriesCacheVar(entriesCacheMutation);
      entriesByIdVar(entriesByIdMutation);
    }
  });
}
*/
