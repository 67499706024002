import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { cloneDeep } from "lodash";
import numeral from "numeral";

import Button from "components/Button";
import { Dialog, FormGroup, InputGroup, Classes as BP } from "@blueprintjs/core";
import Errors from "components/Errors";

import CompanySettingsPayrollModel from "./CompanySettingsPayrollModel";

import CompanySettingsEmployeesDelete from "./CompanySettingsEmployeesDelete";
import { ALL_EMPLOYEES_QUERY, ARCHIVED_EMPLOYEES_QUERY } from "./graphql";

import { blankChangeComponent, blankModel, structurePayrollModels } from "./employee-utilities";
import styles from "./CompanySettingsEmployees.module.scss";

class CompanySettingsEmployeesCreateUpdate extends React.Component {
  static propTypes = {
    archiveEmployee: PropTypes.func,
    duplicateEmployee: PropTypes.func,
    employee: PropTypes.object,
    forecastStartDate: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    operation: PropTypes.string.isRequired,
    scenario: PropTypes.string,
    scenarios: PropTypes.array.isRequired,
    team: PropTypes.object,
    teams: PropTypes.array.isRequired,
    taxRates: PropTypes.array.isRequired,
    unarchiveEmployee: PropTypes.func,
    user: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = this.createEmployeeState(props.user, false, props.scenario, props.scenarios, props.employee, props.team);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.employee === null &&
      this.props.employee !== null &&
      this.props.operation !== "delete") {
      const state = this.createEmployeeState(
        this.props.user,
        true,
        this.props.scenario,
        this.props.scenarios,
        this.props.employee,
        this.props.team,
      );
      this.setState(state);
    }
  }

  createEmployeeState = (user, isOpen, scenario, scenarios, employee, team) => {
    const state = {
      appliedToAll: false,
      isOpen,
      scenario,
      employee: null,
      id: (employee && employee.id) ? employee.id : null,
      name: (employee && employee.name) ? employee.name : "",
      title: (employee && employee.title) ? employee.title : "",
      team: (team) ? team : "None",
      errors: [],
      operationActive: false,
    };

    state.payrollModels = (employee && employee.payrollModels) ? cloneDeep(employee.payrollModels) : structurePayrollModels(user?.tenant, state.team, null, scenarios);
    state.isArchivable = this.isArchivable(state.payrollModels);
    return state;
  }

  toggleDialog = () => {
    this.props.onClose();
    this.setState({ isOpen: !this.state.isOpen });
  }

  toggleAppliedToAll = () => this.setState({ appliedToAll: !this.state.appliedToAll });

  changeScenario = (event) => this.setState({ scenario: event.target.value });

  changeTeam = (event) => {
    const id = event.target.value;
    const team = this.props.teams.find((team) => (team.id === id));
    this.setState({ team });
  }

  changeName = (event) => this.setState({ name: event.target.value });

  changeTitle = (event) => this.setState({ title: event.target.value });

  updateModel = (modelField, value, valueType, eventType) => {
    if (valueType === "number") {
      const numeralValue = numeral(value).value();

      if (eventType === "blur") value = numeralValue || 0;

      if (!isNaN(numeralValue) || value === "-" || value === "." || value === "") {
        const payrollModels = {
          ...this.state.payrollModels,
          [this.state.scenario]: {
            ...this.state.payrollModels[this.state.scenario],
            [modelField]: value,
          },
        };
        this.setState({ payrollModels, appliedToAll: false });
      }
    } else {
      const payrollModels = {
        ...this.state.payrollModels,
        [this.state.scenario]: {
          ...this.state.payrollModels[this.state.scenario],
          [modelField]: value,
        },
      };
      this.setState({ payrollModels, appliedToAll: false });
    }
  }

  // Accepts an object of field and values aka {'field_name': value}
  updateModelFields = (changes) => {
    let payrollModels = { ...this.state.payrollModels };
    for (const modelField of Object.keys(changes)) {
      payrollModels = {
        ...payrollModels,
        [this.state.scenario]: {
          ...payrollModels[this.state.scenario],
          [modelField]: changes[modelField],
        },
      };
    }
    this.setState({ payrollModels, appliedToAll: false });
  }

  // Type is raises, benefit, or single_payment. Index is which one to edit. Field is field on object
  updateModelArray = (type, index, modelField, value, addChangeComponent, clearChangeComponent, valueType, eventType) => {
    // Example raises array: [{date: "2019-03-15", amount: 30000}, {date: "2019-06-15", amount: 40000}]
    const editedArray = this.state.payrollModels[this.state.scenario][type];
    let companyRaiseDate = this.state.payrollModels[this.state.scenario].company_raise_date;
    if (addChangeComponent) {
      editedArray.push(cloneDeep(blankChangeComponent));
    } else if (clearChangeComponent) {
      if (index !== 0) editedArray.splice(index, 1);
      else editedArray[index] = cloneDeep(blankChangeComponent);
    } else if (valueType === "number") {
      const numeralValue = numeral(value).value();

      if (eventType === "blur") value = numeralValue || 0;

      if (!isNaN(numeralValue) || ["-", ".", ""].includes(value)) {
        editedArray[index][modelField] = value;
      }
    } else {
      editedArray[index][modelField] = value;
      // If we update the last raise date, make sure the company-wide schedule starts the day after
      if (type === "raises" && valueType === "date" && index === editedArray.length - 1) {
        const editedRaiseDateMoment = moment(value);
        companyRaiseDate = !!companyRaiseDate ? editedRaiseDateMoment.add(1, "day").format("YYYY-MM-DD") : null;
      }
    }
    const payrollModels = {
      ...this.state.payrollModels,
      [this.state.scenario]: {
        ...this.state.payrollModels[this.state.scenario],
        company_raise_date: companyRaiseDate,
        [type]: editedArray,
      },
    };
    this.setState({ payrollModels, appliedToAll: false });
  }

  applyToAll = () => {
    const payrollModels = cloneDeep(this.state.payrollModels);
    const payrollModelToCopy = cloneDeep(this.state.payrollModels[this.state.scenario]);
    delete payrollModelToCopy.id;
    for (const scenario of Object.keys(this.state.payrollModels)) {
      if (scenario !== this.state.scenario) {
        const newModel = cloneDeep(payrollModelToCopy);
        if (payrollModels[scenario].id) newModel.id = payrollModels[scenario].id;
        payrollModels[scenario] = newModel;
      }
    }
    this.setState({ payrollModels, appliedToAll: true });
  }

  clearModel = () => {
    const payrollModels = cloneDeep(this.state.payrollModels);
    const clearModel = cloneDeep(blankModel);
    if (payrollModels[this.state.scenario].id) {
      clearModel.delete = true;
      clearModel.id = payrollModels[this.state.scenario].id;
    }
    payrollModels[this.state.scenario] = clearModel;
    this.setState({ payrollModels, appliedToAll: false });
  }

  onOperation = (closeModal, saveAndNew) => {
    const employee = {
      name: this.state.name,
      title: this.state.title,
      team_id: this.state.team.id,
    };
    if (this.state.id) employee.id = this.state.id;
    this.toggleOperationActive();
    const operation = (this.props.operation === "create") ? this.props.onCreate : this.props.onUpdate;
    operation({
      variables: { employee, payrollModels: this.state.payrollModels },
    })
      .then(() => {
        this.toggleOperationActive();
        if (closeModal || saveAndNew) this.props.onClose();
        const state = (closeModal || saveAndNew) ? this.createEmployeeState(this.props.user, !closeModal, this.props.scenario, this.props.scenarios, { team_id: employee.team_id }, this.props.team) : { errors: [] };
        state.isArchivable = this.isArchivable(this.state.payrollModels);
        this.setState(state);
      }).catch((error) => {
        this.toggleOperationActive();
        // eslint-disable-next-line
        console.log("There was an error with the query", error);
        this.setState({ errors: ["Employee saving failed. Check required fields."] });
      });
  }

  toggleOperationActive = () => this.setState({ operationActive: !this.state.operationActive });

  removeEmployee = (employeeToRemove) => () => { this.setState({ employee: employeeToRemove, deleteEmployee: true }); }

  onCancelDelete = () => { this.setState({ employee: null, deleteEmployee: false }); }

  onSuccessDelete = () => {
    this.props.onClose();
    const state = this.createEmployeeState(this.props.user, false, this.props.scenario, this.props.scenarios, this.props.employee, this.props.team);
    this.setState(state);
  }

  duplicateEmployeeOperation = () => {
    this.toggleOperationActive();
    return this.props.duplicateEmployee({
      variables: { id: this.state.id },
    })
      .then(() => {
        this.toggleOperationActive();
        this.props.onClose();
        const state = this.createEmployeeState(this.props.user, false, this.props.scenario, this.props.scenarios, this.props.employee, this.props.team);
        this.setState(state);
      }).catch((error) => {
        const errorMsg = error.message.replace("GraphQL error: ", "");
        this.setState({ errors: [errorMsg] });
        // eslint-disable-next-line
        console.log("There was an error duplicating this employee.", error);
        this.toggleOperationActive();
      });
  }

  isArchivable = (payrollModels) => {
    const { forecastStartDate } = this.props;
    const scenarios = Object.keys(payrollModels);
    const date = forecastStartDate ? moment(forecastStartDate, "YYYY-MM-DD") : moment();
    for (const scenario of scenarios) {
      const model = payrollModels[scenario];
      if (model.hire_date) {
        if (!model.termination_date) return false;
        else if (moment(model.termination_date, "YYYY-MM-DD").isAfter(date)) return false;
      }
    }
    return true;
  }

  archiveEmployeeOperation = () => {
    this.toggleOperationActive();
    return this.props.archiveEmployee({
      variables: { id: this.state.id },
    })
      .then(() => {
        this.toggleOperationActive();
        this.props.onClose();
        const state = this.createEmployeeState(this.props.user, false, this.props.scenario, this.props.scenarios, this.props.employee, this.props.team);
        this.setState(state);
      }).catch((error) => {
        const errorMsg = error.message.replace("GraphQL error: ", "");
        this.setState({ errors: [errorMsg] });
        this.toggleOperationActive();
      });
  }

  unarchiveEmployeeOperation = () => {
    this.toggleOperationActive();
    return this.props.unarchiveEmployee({
      variables: { id: this.state.id },
    })
      .then(() => {
        this.toggleOperationActive();
        this.props.onClose();
        const state = this.createEmployeeState(this.props.user, false, this.props.scenario, this.props.scenarios, this.props.employee, this.props.team);
        this.setState(state);
      }).catch((error) => {
        const errorMsg = error.message.replace("GraphQL error: ", "");
        this.setState({ errors: [errorMsg] });
        this.toggleOperationActive();
      });
  }

  render() {
    const { employee } = this.props;
    const { isArchivable } = this.state;
    const title = (this.props.operation === "create") ? "Create Employee" : "Update Employee";
    const icon = (this.props.operation === "create") ? "add" : "annotation";
    return (
      <span>
        <Dialog
          canOutsideClickClose={false}
          className={`${BP.LARGE} ${styles.employeesCreateUpdate}`}
          icon={icon}
          isOpen={this.state.isOpen}
          onClose={this.toggleDialog}
          title={title}
        >
          <div className={`${BP.DIALOG_BODY} ${styles.payrollModel}`}>
            <Errors messages={this.state.errors} />
            <FormGroup
              className={`${styles.payrollFormGroup}`}
              label="Team"
              labelFor="team"
              labelInfo="*"
            >
              <div className={[BP.HTML_SELECT, BP.FILL].join(" ")}>
                <select onChange={this.changeTeam} value={this.state.team.id}>
                  {this.props.teams.map((team, index) => (
                    <option key={index} value={team.id}>{team.name}</option>
                  ))}
                </select>
              </div>
            </FormGroup>

            <FormGroup
              className={`${styles.payrollFormGroup}`}
              label="Name"
              labelFor="name"
            >
              <InputGroup
                autoComplete="off"
                id="name"
                onChange={this.changeName}
                placeholder="Name"
                value={this.state.name}
              />
            </FormGroup>

            <FormGroup
              className={`${styles.payrollFormGroup} m-b-md`}
              label="Title"
              labelFor="title"
              labelInfo="*"
            >
              <InputGroup
                autoComplete="off"
                id="title"
                onChange={this.changeTitle}
                placeholder="Title"
                value={this.state.title}
              />
            </FormGroup>

            <CompanySettingsPayrollModel
              applyToAll={this.applyToAll}
              changeScenario={this.changeScenario}
              clearModel={this.clearModel}
              payrollModels={this.state.payrollModels}
              scenario={this.state.scenario}
              scenarios={this.props.scenarios}
              taxRates={this.props.taxRates}
              updateModel={this.updateModel}
              updateModelArray={this.updateModelArray}
              updateModelFields={this.updateModelFields}
              user={this.props.user}
            />
            <p className={`${styles.payrollHelperText} m-t`}>* required fields</p>
          </div>
          <div className={`${BP.DIALOG_FOOTER} ${styles.payrollModelFooter}`}>
            <div className={BP.DIALOG_FOOTER_ACTIONS}>
              <div className={styles.payrollFormGroup}>
                <Button
                  disabled={this.state.operationActive || (employee && employee.archived) || this.state.appliedToAll}
                  icon="duplicate"
                  onClick={this.applyToAll}
                  text="Copy To All Scenarios"
                />
              </div>
            </div>
            <div className={BP.DIALOG_FOOTER_ACTIONS}>
              {(this.props.operation === "update") ? (
                <div className={styles.leftFooter}>
                  <Button
                    className="m-l-0"
                    disabled={this.state.operationActive}
                    intent="danger"
                    onClick={this.removeEmployee({ id: this.state.id, name: this.state.name, title: this.state.title })}
                    text="Delete"
                  />
                  {(isArchivable) ? (
                    <Button
                      disabled={this.state.operationActive}
                      onClick={(employee && employee.archived) ? this.unarchiveEmployeeOperation : this.archiveEmployeeOperation}
                      text={`${(employee && employee.archived) ? "Unarchive" : "Archive"}`}
                    />
                  ) : null}
                  <Button
                    disabled={this.state.operationActive || (employee && employee.archived)}
                    icon="cross"
                    onClick={this.clearModel}
                    text="Clear Current Scenario"
                  />
                  <Button
                    disabled={this.state.operationActive || (employee && employee.archived)}
                    onClick={this.duplicateEmployeeOperation}
                    text="Duplicate"
                  />
                </div>
              ) : null}
              <Button
                disabled={this.state.operationActive}
                onClick={this.toggleDialog}
                text="Cancel"
              />
              {(this.props.operation === "update") ? (
                <Button
                  disabled={this.state.operationActive || (employee && employee.archived)}
                  onClick={() => this.onOperation(false, false)}
                  text="Save"
                />
              ) : null}
              <Button
                disabled={this.state.operationActive || (employee && employee.archived)}
                intent="success"
                onClick={() => this.onOperation(true, false)}
                text="Save and Close"
              />
            </div>
          </div>
        </Dialog>
        <CompanySettingsEmployeesDelete
          deleteEmployee={this.state.deleteEmployee}
          employee={this.state.employee}
          onCancel={this.onCancelDelete}
          onRemove={this.props.onRemove}
          onSuccess={this.onSuccessDelete}
        />
      </span>
    );
  }
}

export default CompanySettingsEmployeesCreateUpdate;
