import React from "react";

import Card from "components/Card";

import ModelVersions from "./ModelVersions";


export default function ForecastStartDate() {
  return (
    <Card>
      <ModelVersions />
    </Card>
  );
}
