import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";
import {SketchPicker} from "react-color";

import {Dialog} from "@blueprintjs/core";
import Button from "components/Button";
import TypedConfirmation from "components/TypedConfirmation";
import Errors from "components/Errors";

import {ALL_COLORS_QUERY, createColor, updateColor, removeColor} from "./graphql";

import {evictAllDashboardsFromCache} from "shared/graphql/apolloCacheEviction";

import styles from "./styles.module.scss";

class CompanySettingsColorsCreateUpdate extends React.Component {
  static propTypes = {
    allColorsQuery: PropTypes.object.isRequired,
    color: PropTypes.object,
    createColor: PropTypes.func.isRequired,
    index: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    removeColor: PropTypes.func.isRequired,
    updateColor: PropTypes.func.isRequired,
  }

  state = {
    color: this.props.color || this.getDefaultColorData(),
    colorPickerOpen: false,
    modalOpen: true,
    operation: this.props.color ? "update" : "create",
    removeColorId: null,
  }

  getDefaultColorData() {
    return {
      description: "",
      name: "",
      index: 0,
      color: "#ffffff",
    };
  }

  handleSubmit = () => {
    const {operation, color} = this.state;

    const variables = {
      color: {
        name: color.name,
        description: color.description,
        index: operation === "create" ? this.props.index : color.index,
        color: color.color,
      },
    };

    if(operation === "update") variables.color.id = color.id;

    const optimisticResponses = {
      createColor: {
        __typename: "Color",
        ...variables.color,
        id: 0,
      },
      updateColor: {
        __typename: "Color",
        ...variables.color,
      },
    };

    this.props[`${operation}Color`]({
      variables,
      optimisticResponse: {
        __typename: "Mutation",
        [`${operation}Color`]: optimisticResponses[`${operation}Color`],
      },
      update: (cache, {data: {createColor, updateColor}}) => {
        evictAllDashboardsFromCache(cache);
        if(operation === "create") {
          const queryData = cache.readQuery({query: ALL_COLORS_QUERY});
          const data = {
            ...queryData,
            colors: [
              ...queryData.colors,
              createColor,
            ],
          };
          cache.writeQuery({query: ALL_COLORS_QUERY, data});
        } else {
          const queryData = cache.readQuery({query: ALL_COLORS_QUERY});
          const index = queryData.colors.findIndex((color) => updateColor.id === color.id);
          const data = {
            ...queryData,
            colors: [...queryData.colors],
          };
          data.colors[index] = updateColor;
          cache.writeQuery({query: ALL_COLORS_QUERY, data});
        }
      },
    });

    this.onClose();
  }

  handleFieldChange = (evt) => {
    this.setState({
      color: {
        ...this.state.color,
        [evt.target.name]: evt.target.value,
      },
    });
  }

  handleColorChange = (color) => {
    this.handleFieldChange({
      target: {
        name: "color",
        value: color.hex,
      },
    });
  }

  onClose = () => {
    const {onClose} = this.props;
    this.setState({
      modalOpen: false,
    });
    setTimeout(onClose, 300); // Blueprint dialog transition duration seems to be 300ms
  }

  toggleColorPicker = (evt) => {
    if(!evt || ["colorPickerToggle", "overlay", "colorPickerInput"].includes(evt.target.id)) {
      this.setState({colorPickerOpen: !this.state.colorPickerOpen});
    }
  }

  toggleRemoveModal = (id) => () => {
    this.setState({removeColorId: id});
  }

  removeColor = () => {
    const {removeColorId} = this.state;

    this.props.removeColor({
      variables: {id: removeColorId},
      update: (cache, {data: {removeColor}}) => {
        evictAllDashboardsFromCache(cache);
        const queryData = cache.readQuery({
          query: ALL_COLORS_QUERY,
          variables: {id: removeColorId},
        });
        const data = {
          ...queryData,
          colors: queryData.colors.filter((color) => color.id !== removeColor.id),
        };
        cache.writeQuery({query: ALL_COLORS_QUERY, data});
      },
      optimisticResponse: {
        removeColor: {
          id: removeColorId,
          __typename: "removeColorPayload",
        },
      },
    });

    this.toggleRemoveModal(null)();
  }

  render() {
    const {allColorsQuery} = this.props;

    const {
      colorPickerOpen,
      modalOpen,
      operation,
      color,
      removeColorId,
    } = this.state;

    const title = (operation === "create") ? "Create Color" : "Update Color";
    const icon = (operation === "create") ? "add" : "annotation";

    let deletedColor = null;
    if(removeColorId) deletedColor = allColorsQuery.colors.find((color) => color.id === removeColorId);

    return (
      <Dialog
        canOutsideClickClose
        className="bp5-large"
        icon={icon}
        inline
        isOpen={modalOpen}
        onClose={this.onClose}
        title={title}
      >
        <div className="bp5-dialog-body">
          <Errors messages={this.state.errors} />
          <div className="bp5-form-group">
            <h4 className="bp5-heading">Name</h4>
            <input
              autoComplete="off"
              className="bp5-input bp5-large"
              name="name"
              onChange={this.handleFieldChange}
              placeholder="Color Name"
              type="text"
              value={color.name}
            />
          </div>
          <div className="bp5-form-group m-t">
            <h4 className="bp5-heading">Color</h4>
            <div className={styles.colorPickerGroup} onClick={this.toggleColorPicker}>
              <input
                autoComplete="off"
                className="bp5-input bp5-large"
                disabled
                id="colorPickerInput"
                name="color"
                onChange={this.handleFieldChange}
                type="text"
                value={color.color}
              />
              <div
                className={styles.colorPreview}
                id="colorPickerToggle"
                onClick={this.toggleColorPicker}
                style={{backgroundColor: color.color}}
              >
                {colorPickerOpen ? (
                  <>
                    <SketchPicker
                      className={styles.colorPicker}
                      color={color.color}
                      disableAlpha
                      onChangeComplete={this.handleColorChange}
                      presetColors={[]}
                    />
                    <div className={styles.overlay} id="overlay" onClick={this.toggleColorPicker} />
                  </>
                ) : null}
              </div>
            </div>

          </div>
        </div>
        <div className="bp5-dialog-footer">
          <div className={["bp5-dialog-footer-actions", styles.bottomButtons].join(" ")}>
            <div className={styles.left}>
              {(operation === "update") ? (
                <Button
                  intent="danger"
                  onClick={this.toggleRemoveModal(color.id)}
                  text="Delete Color"
                />
              ) : null}
            </div>
            <Button onClick={this.onClose} text="Cancel" />
            <Button
              intent="success"
              onClick={this.handleSubmit}
              text="Save"
            />
          </div>
        </div>
        {removeColorId ? (
          <TypedConfirmation
            isOpen={!!removeColorId}
            onCancel={this.toggleRemoveModal(null)}
            onConfirm={this.removeColor}
          >
            <p>
              Are you sure that you want to remove this color?&nbsp;
              {deletedColor ? (
                <span>
                  If this color is used in a custom chart, it will be replaced with either another of your custom colors or a default Flightpath Finance color.
                </span>
              ) : null}
            </p>
            <p>
              Please type 'delete' in the input field then click <strong>Delete</strong> below if you are sure
              that you would like this color to be removed.
            </p>
          </TypedConfirmation>
        ) : null}
      </Dialog>
    );
  }
}

export default compose(
  graphql(ALL_COLORS_QUERY, {name: "allColorsQuery"}),
  graphql(createColor, {name: "createColor"}),
  graphql(updateColor, {name: "updateColor"}),
  graphql(removeColor, {name: "removeColor"}),
)(CompanySettingsColorsCreateUpdate);
