import moment from "moment";
import {formatYear} from "shared/utilities/forecast-utilities";

const blankGoogleSheetsModel = {
  active: false,
  workbook: "",
  range_start: "",
  range_start_date: null,
  range_end: "",
};

export const createColHeaders = (years) => {
  let colHeaders = [""];
  for(let i = 0; i < years.length; i++) {
    const yearFormatted = formatYear(years[i]);
    // eslint-disable-next-line
    colHeaders = colHeaders.concat(["JAN " + yearFormatted, "FEB " + yearFormatted, "MAR " + yearFormatted, // eslint-disable-next-line
    "APR " + yearFormatted, "MAY " + yearFormatted, "JUN " + yearFormatted, "JUL " + yearFormatted, // eslint-disable-next-line
    "AUG " + yearFormatted, "SEP " + yearFormatted, "OCT " + yearFormatted, "NOV " + yearFormatted, // eslint-disable-next-line
    "DEC " + yearFormatted ]);
  }
  return colHeaders;
};

export const createColumns = (years, scenarioId, forecastType, forecastStartDate) => {
  const columns = [{data: "name", readOnly: true, type: "text", width: 200}];
  for(let i = 0; i < years.length; i++) {
    for(let j = 0; j < 12; j++) {
      const date = moment([years[i], j]).format("YYYY-MM");
      const scenarioIdFormatted = (forecastStartDate <= date) ? scenarioId.toString() : "null";
      const readOnly = (forecastStartDate > date);
      // eslint-disable-next-line
      columns.push({data: "entriesCache." + scenarioIdFormatted + "." + date + ".FORMULA." + "value", readOnly: readOnly, type: "numeric", width: 100, allowEmpty: true});
    }
  }
  return columns;
};

export const getForecastCol = (years, forecastStartDate) => {
  let col = 0;
  // Find index
  for(let i = 0; i < years.length; i++) { if(years[i] === forecastStartDate.year) col = i; }
  // Multiply by 12 & add number of months and plus 1 to avoid first name column
  col = (col * 12) + forecastStartDate.month + 1;
  return col;
};

export const findForecastType = (models, scenarioString) => {
  const isAutopilotActive = models?.[scenarioString]?.autopilot?.active ?? false;
  const isGoogleActive = models?.[scenarioString]?.google?.active ?? false;
  let forecastType = "manual";
  if(isAutopilotActive) forecastType = "autopilot";
  else if(isGoogleActive) forecastType = "google";
  return forecastType;
};

// const compareColumnStrings = (col1, col2) => {
//   for(let)
// }

const checkRanges = (range_start, range_end) => {
  // eslint-disable-next-line
  const regexp = /([aA-zZ][aA-zZ]?)(\d+)/;
  const endMatch = regexp.exec(range_end);
  const startMatch = regexp.exec(range_start);
  // console.log(range_start, range_end, startMatch, endMatch, startMatch[1].localeCompare(endMatch[1], "en", { sensitivity: "base" }));
  if(startMatch && endMatch && startMatch[2] === endMatch[2]) return true;
  else return false;
};

const checkAgainstBlankModel = (model) => {
  for(const field in blankGoogleSheetsModel) {
    if(model[field] !== blankGoogleSheetsModel[field]) return false;
  }
  return true;
};

export const validateGoogleSheetsModels = (models) => {
  for(const scenario in models) {
    const model = models[scenario]["google"];
    if(model && model.active) {
      if(checkAgainstBlankModel(model)) continue;
      else {
        if(!checkRanges(model.range_start, model.range_end)) return false;
      }
    }
  }
  return true;
};
