import React from "react";
import PropTypes from "prop-types";

import {client} from "apollo-config";

import Button from "components/Button";
import {Classes as BP, Dialog} from "@blueprintjs/core";

import {checkMfaCode} from "./graphql";
import MfaCodeInput from "../../auth/MfaCodeInput";

import styles from "./styles.module.scss";

export default class MFAConfirmation extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.mfaCodeInput = React.createRef();

    this.state = {
      code: "",
      error: "",
      loading: false,
    };
  }

  handleConfirm = () => {
    this.props.onConfirm();
  }

  onSubmit = () => {
    this.setState({loading: true});
    client.query({
      query: checkMfaCode,
      variables: {
        code: this.state.code,
      },
    }).then(({data: {checkMfaCode: isValid}}) => {
      if(isValid) {
        this.props.onConfirm(this.state.code);
      } else {
        this.setState({loading: false, error: "Provided code is invalid"});
        this.mfaCodeInput.current.reset();
      }
    }).catch((e) => {
      const error = e.message.replace("GraphQL error: ", "");
      this.setState({error, loading: false});
      this.mfaCodeInput.current.reset();
    });
  }

  handleCancel = () => {
    this.props.onCancel();
  }

  onCodeChange = (code) => {
    this.setState({error: "", code});
  }

  render() {
    const {
      isOpen,
    } = this.props;

    const {error, loading} = this.state;

    return (
      <Dialog
        canOutsideClickClose={false}
        className={[BP.LARGE, styles.modal].join(" ")}
        icon="delete"
        isOpen={isOpen}
        onClose={this.handleCancel}
        title="Code confirmation"
      >
        <div className={BP.DIALOG_BODY}>
          <p>In order to disable multi-factor authentication for your account, you need to confirm your current authorization code below.</p>
          {error.length ? <div className={styles.error}>{error}</div> : null}
          <MfaCodeInput
            onCodeChange={this.onCodeChange}
            onEnterKeyPress={this.onSubmit}
            ref={this.mfaCodeInput}
          />
        </div>
        <div className={BP.DIALOG_FOOTER}>
          <div className={BP.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCancel} text="Cancel" />
            <Button
              intent={"danger"}
              loading={loading}
              onClick={this.onSubmit}
              text="Disable multi-factor authentication"
            />
          </div>
        </div>
      </Dialog>
    );
  }
}
