import React, {useContext} from "react";
import PropTypes from "prop-types";

import {Button, ButtonGroup, FormGroup, InputGroup} from "@blueprintjs/core";

import {currencyToSymbolMapping} from "shared/utilities/forecast-utilities";

import {AppContext} from "../../../../AppContext";

export default function YAxisUnits({onChange, value}) {
  const {currency} = useContext(AppContext);
  const {yAxisUnitsType, yAxisUnits} = value;

  const generateChangeEvent = (key, newValue) => {
    onChange({
      target: {
        name: "options",
        value: {
          ...value,
          [key]: newValue,
        },
      },
    });
  };

  return (
    <FormGroup
      label="Y-Axis Units"
      labelInfo="*"
    >
      <ButtonGroup>
        <Button
          active={!yAxisUnitsType || yAxisUnitsType === "AUTO"}
          onClick={() => generateChangeEvent("yAxisUnitsType", "AUTO")}
          text={`Default (${currencyToSymbolMapping[currency]})`}
        />
        <Button
          active={yAxisUnitsType === "CUSTOM"}
          onClick={() => generateChangeEvent("yAxisUnitsType", "CUSTOM")}
          text="Manual"
        />
        {yAxisUnitsType === "CUSTOM" ? (
          <InputGroup
            autoComplete="off"
            id="yAxisUnits"
            name="yAxisUnits"
            onChange={({target: {value}}) => generateChangeEvent("yAxisUnits", value)}
            placeholder="Unit"
            size={15}
            value={typeof yAxisUnits === "undefined" ? currencyToSymbolMapping[currency] : yAxisUnits}
          />
        ) : null}
      </ButtonGroup>
    </FormGroup>
  );
}

YAxisUnits.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};
