import memoize from "memoizee";

import {getModelsCacheForAccount} from "./model-utilities";
import {FLIGHTPATH} from "shared/utilities/types";

import {flightpathAccountsByIdVar, snapshotAccountsByIdVar} from "apollo-config/local-state/accounts";

const defaultFormatOptions = {
  header: {
    bold: true,
    uppercase: true,
  },
};

const accountIsEditable = (account, models, sectionOptions = {}) => {
  if(account.statement_type === "REV") {
    let hasAllOrForecastPeriodModel = false;
    for(const scenarioId of Object.keys(models || [])) {
      for(const modelType of ["other", "autopilot"]) {
        if(models[scenarioId][modelType] && ["FORECASTS", "ALL"].includes(models[scenarioId][modelType].period)) hasAllOrForecastPeriodModel = true;
      }
    }
    return !sectionOptions.isTotal && !hasAllOrForecastPeriodModel;
  } else if(account.statement_type !== "CFS") {
    return account.detail_type !== "root" && !["BSNetIncome", "Bank Account"].includes(account.detail_type) && !sectionOptions.isTotal;
  } else {
    return false;
  }
};

export function shouldAddValues(account) {
  // If it's a balance sheet account
  if(account?.statement_type === "BS") return false;
  // If account options says it's a balance account
  if(account?.options?.balance) return false;

  return true;
}

// Account Filtering by Statement Type
export const filterAccounts = (accounts, statement_type) => accounts.filter((account) => (account.statement_type === statement_type));

export const findAccountById = (accounts, id) => {
  // Accepts either an array of accounts or an object with the accounts under either the BS, PNL or CFS key
  if(!Array.isArray(accounts)) {
    for(const type of Object.keys(accounts)) {
      const foundAccount = accounts[type].find((account) => account.id === id);
      if(foundAccount) return foundAccount;
    }
  } else {
    return accounts.find((account) => account.id === id);
  }
};

export const findAccountByType = (accounts, type, detail_type = "root") => accounts.find((account) => account.type === type && account.detail_type === detail_type);

export const findRevenueAccount = (accounts, type, product_id, plan_group_id) => accounts.find((account) => account.type === type && account.product_id === product_id && account.plan_group_id === plan_group_id);

const reorderChildren = (accounts, account, order) => {
  const newChildren = account.children.map((child) => findAccountById(accounts, child.id));
  const newOrder = [];
  for(const type of order) {
    const newChild = newChildren.filter((child) => (child.type === type));
    newOrder.push(...newChild);
  }
  return (newOrder.length > 0) ? newOrder : account.children;
};

// In order to account for areas of 3 statement model that are not alphabetical
const formatChildren = (accounts, account) => {
  switch(account.type) {
    case "Current Assets":
      return reorderChildren(accounts, account, ["Bank Accounts", "Accounts Receivable", "Current Assets", "Other Current Assets"]);
    case "Liabilities & Equity":
      return reorderChildren(accounts, account, ["Liabilities", "Equity"]);
    case "Operating Activities":
      return reorderChildren(accounts, account, ["CFSNetIncome", "Adjustments to Net Income"]);
    default:
      return account.children;
  }
};

export const formatSingleAccount = memoize((account, models, scenarios) => {
  const formattedAccount = (account && scenarios) ? {
    id: account.id,
    name: account.name,
    type: account.type,
    statement_type: account.statement_type,
    detail_type: account.detail_type,
    children: account.children,
    models: (models || account.googleSheetsModels) ? models || account.googleSheetsModels : null,
    entryType: "FORMULA",
    notes: account.notes,
    product_id: account.product_id,
    plan_group_id: account.plan_group_id,
    negative: account.negative,
  } : null;

  return {formattedAccount};
});

export const formatAccountWithChildren = (account, models, accounts, scenarios, templateOptions, indentation = 0, sections = []) => {
  if(account.ignore) return null;
  const {formattedAccount} = formatSingleAccount(account, models, scenarios);

  if(formattedAccount.children && formattedAccount.children.length) {
    sections.push({
      type: "account",
      account: formattedAccount,
      options: {
        indentation,
        collapsible: true,
        uppercase: templateOptions.uppercaseTopLevel && indentation === 0,
        accountIsEditable: accountIsEditable(formattedAccount, models),
      },
    });

    const reorderedChildren = formatChildren(accounts, account);

    for(const childAccount of reorderedChildren) {
      const resolvedChildAccount = childAccount.name ? childAccount : findAccountById(accounts, childAccount.id);
      if(resolvedChildAccount) {
        const models = getModelsCacheForAccount(resolvedChildAccount);
        formatAccountWithChildren(resolvedChildAccount, models, accounts, scenarios, templateOptions, indentation + 1, sections);
      }
    }
    // Add a totals account at end of section
    const totalsAccount = {
      id: account.id,
      name: `Total ${account.name}`,
      type: account.type,
      statement_type: account.statement_type,
      detail_type: account.detail_type,
      children: account.children,
      entryType: "TOTAL",
    };

    sections.push({
      type: "account",
      account: totalsAccount,
      options: {
        indentation,
        isTotal: true,
        accountIsEditable: false,
      },
    });
  } else {
    if(account.detail_type !== "root") {
      const {formattedAccount} = formatSingleAccount(account, models, scenarios);

      sections.push({
        type: "account",
        account: formattedAccount,
        options: {
          indentation,
          accountIsEditable: accountIsEditable(formattedAccount, models),
        },
      });
    }
  }

  return sections;
};

export function calculateAccountsDiff(oldAccounts, newAccounts) {
  const newArray = Array.from(oldAccounts);
  let changed = false;

  for(const account of newAccounts) {
    if(!oldAccounts.find((prevAccount) => prevAccount.id === account.id)) {
      changed = true;
      newArray.push({...account});
    }
  }

  for(let i = 0; i < oldAccounts.length; i++) {
    const matchingNewAccount = newAccounts.find((newAccount) => newAccount.id === oldAccounts[i].id);
    if(!matchingNewAccount) {
      changed = true;
      newArray.splice(i, 1);
    }
  }

  return {accounts: newArray, changed};
}

export const resolveAccounts = memoize((accounts, template, scenarios) => {
  const resolvedTemplate = {...template};
  resolvedTemplate.sections = [];

  const templateOptions = template.options || {};

  for(const section of template.sections) {
    const options = {
      ...defaultFormatOptions[section.type] || {},
      ...section.options || {},
    };

    switch(section.type) {
      case "account": {
        let resolvedAccount = section.account;

        if(!resolvedAccount) {
          if(section.accountId) {
            resolvedAccount = findAccountById(accounts, section.accountId);
          } else {
            resolvedAccount = findAccountByType(accounts, section.accountType, section.detailType || null);
          }
        }

        if(resolvedAccount) {
          const models = getModelsCacheForAccount(resolvedAccount);
          const {formattedAccount} = formatSingleAccount(resolvedAccount, models, scenarios);

          if(templateOptions.uppercaseTopLevel) options.uppercase = true;
          if(typeof options.useTotalOverFormula === "undefined" && templateOptions.useTotalOverFormula) options.useTotalOverFormula = true;
          if(typeof options.accountIsEditable === "undefined") {
            options.accountIsEditable = templateOptions.readOnly ? false : accountIsEditable(formattedAccount, models, options);
          }

          resolvedTemplate.sections.push({
            ...section,
            account: formattedAccount,
            options,
          });
        }
        break;
      }
      case "accountSection": {
        const sectionAccount = findAccountByType(accounts, section.accountType);

        if(sectionAccount) {
          const models = getModelsCacheForAccount(sectionAccount);
          const sections = formatAccountWithChildren(sectionAccount, models, accounts, scenarios, templateOptions);
          resolvedTemplate.sections.push(...sections);
        }
        break;
      }
      default: {
        resolvedTemplate.sections.push({...section, options});
      }
    }
  }

  return resolvedTemplate;
});

export function getAccountFromCache(id) {
  return flightpathAccountsByIdVar()[id];
}

export const orderTopLevelAccounts = (accounts) => {
  const PnlAccounts = [];
  const BsAccounts = [];
  const CfsAccounts = [];
  const RevAccounts = [];
  const MetAccounts = [];

  for(const account of accounts) {
    if(account.snapshot_id) continue;
    if(account.statement_type === "PNL" && FLIGHTPATH.PNL_TYPES.includes(account.type) && !account.parent) PnlAccounts.push(account);
    if(account.statement_type === "BS" && FLIGHTPATH.BS_TYPES.includes(account.type) && !account.parent) BsAccounts.push(account);
    if(account.statement_type === "CFS" && FLIGHTPATH.CFS_TYPES.includes(account.type) && !account.parent) CfsAccounts.push(account);
    if(account.statement_type === "REV" && !account.parent) RevAccounts.push(account);
    if(account.statement_type === "MET" && !account.parent) MetAccounts.push(account);
  }

  if(!PnlAccounts.length) return accounts;

  return [...PnlAccounts, ...BsAccounts, ...CfsAccounts, ...RevAccounts, ...MetAccounts];
};

export function mergeDeltaFromSubscription(deltas, overwrite = false) {
  const newVarDataById = {...flightpathAccountsByIdVar()};

  if(overwrite) {
    //console.time("overwrite accounts");
    const accountsById = {
      ...snapshotAccountsByIdVar(),
    };

    const asArray = [...deltas.update];

    for(const {1: account} of Object.entries(snapshotAccountsByIdVar)) {
      asArray.push(account);
    }

    (import.meta.env.DEV) && console.debug(`Overwriting accounts cache with received payload from subscription...`);
    for(const account of deltas.update) {
      accountsById[account.id] = account;
    }

    flightpathAccountsByIdVar(accountsById);

    //console.timeEnd("overwrite accounts");

    return;
  }

  //console.time(`Process changes to accounts`);
  //console.log(`Processing ${deltas.update?.length ?? 0} created / updated accounts...`);
  for(const item of (deltas.update || [])) {
    newVarDataById[item.id] = item;
  }

  console.debug("Foo:", newVarDataById);

  // Process parents
  for(const [_, item] of Object.entries(newVarDataById)) {
    // If the account has a parent, update that parent to account for its new child
    if(item.parent?.id) {
      const parentItem = newVarDataById[item.parent.id];
      // Make sure it's not already in the children
      if(parentItem.children && !parentItem.children.find((child) => child.id === item.id)) {
        newVarDataById[parentItem.id] = {
          ...newVarDataById[parentItem.id],
          children: [
            ...newVarDataById[parentItem.id].children,
            {id: item.id},
          ].filter(child => child != null),
        };
      }
    }
  }

  //console.log(`Processing ${deltas.delete?.length ?? 0} deleted accounts...`);
  for(const item of (deltas.delete || [])) {
    if(newVarDataById[item.id]) delete newVarDataById[item.id];
  }

  flightpathAccountsByIdVar(newVarDataById);
  //console.timeEnd(`Process changes to accounts`);
}
