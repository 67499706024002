import React from "react";
import PropTypes from "prop-types";

import {FormGroup, InputGroup} from "@blueprintjs/core";

function FormFieldName({formGroupClass, label, name, onChange, value}) {
  const actualInput = (
    <InputGroup
      autoComplete="off"
      name={name || "name"}
      onChange={onChange}
      placeholder={label}
      value={value}
    />
  );

  return (
    <>
      {label ? (
        <FormGroup
          className={formGroupClass}
          label={label}
          labelFor={name || "name"}
        >
          {actualInput}
        </FormGroup>
      ) : actualInput}
    </>
  );
}

FormFieldName.propTypes = {
  formGroupClass: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default FormFieldName;
