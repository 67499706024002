import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/Button";

import styles from "./styles.module.scss";

export default function Step({
  active,
  button,
  children,
  done,
  loading,
  next,
  sideBySide,
  text,
}) {
  let actualButton = button;

  if(done) {
    actualButton = (
      <Button
        disabled={loading || !active}
        intent="success"
        onClick={next ? next : () => {}}
      >
        Completed
      </Button>
    );
  } else if(!active) {
    actualButton = (
      <span className={styles.stepNextUp}>Next Up</span>
    );
  } else if(!button || loading) {
    actualButton = (
      <Button
        disabled={loading}
        intent="success"
        loading={loading}
        onClick={next ? next : () => {}}
      >
        Continue
      </Button>
    );
  }
  const className = classnames(styles.step, {
    [styles.active]: active,
    [styles.done]: done,
    [styles.sideBySide]: sideBySide,
  });

  return (
    <div className={className}>
      <div className={styles.leftSide}>
        <div className={styles.stepTitle}>
          <h3>{text}</h3>
          {!sideBySide ? actualButton : null}
        </div>
        {children && active ? (
          <div className={styles.stepBody}>
            {children}
          </div>
        ) : null}
      </div>
      <div className={styles.rightSide}>
        {sideBySide ? actualButton : null}
      </div>
    </div>
  );
}

Step.propTypes = {
  accounts: PropTypes.array,
  active: PropTypes.bool,
  button: PropTypes.node,
  children: PropTypes.node,
  done: PropTypes.bool,
  loading: PropTypes.bool,
  next: PropTypes.func,
  sideBySide: PropTypes.bool,
  text: PropTypes.string,
};
