import React from "react";
import PropTypes from "prop-types";

import Button from "components/Button";

function AddAChartButton({onCreateChart}) {
  return (
    <Button
      icon="add"
      intent="success"
      large
      onClick={onCreateChart}
    >
      Add a Chart
    </Button>
  );
}

AddAChartButton.propTypes = {
  onCreateChart: PropTypes.func.isRequired,
};

export default AddAChartButton;
