import React, {useRef} from "react";
import PropTypes from "prop-types";

import {FormGroup, InputGroup, Icon, Position, Tooltip} from "@blueprintjs/core";

import styles from "./styles.module.scss";

function TextInput({autoComplete = false, disabled = false, formGroupClass, highlightOnFocus = false, label, name, onChange, onBlur, tooltip, value}) {
  const inputRef = useRef(null);

  const onFocus = () => {
    if(highlightOnFocus && inputRef && inputRef.current) {
      setTimeout(() => {
        inputRef.current.select();
      }, 50);
    }
  };


  const actualInput = (
    <div className={styles.inputWrapper}>
      <InputGroup
        autoComplete={autoComplete ? "on" : "off"}
        disabled={disabled}
        inputRef={inputRef}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={label || ""}
        value={value}
      />
      {!!tooltip ? (
        <Tooltip content={tooltip} position={Position.TOP}><Icon className={styles.tooltipIcon} icon="help" iconSize={16} /></Tooltip>
      ) : null}
    </div>
  );

  return (
    <>
      {label ? (
        <FormGroup
          className={formGroupClass}
          label={label}
          labelFor={name}
        >
          {actualInput}
        </FormGroup>
      ) : actualInput}
    </>
  );
}

TextInput.propTypes = {
  autoComplete: PropTypes.bool,
  disabled: PropTypes.bool,
  formGroupClass: PropTypes.string,
  highlightOnFocus: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export default TextInput;
