import React from "react";
import PropTypes from "prop-types";

import Button from "components/Button";

import styles from "./styles.module.scss";

function RenderError({buttonCallback, buttonText, message, title}) {
  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{__html: message}} />
      <p>We apologize for the inconvenience.</p>
      {!!buttonCallback && !!buttonText ? (
        <Button
          className={styles.button}
          intent="success"
          onClick={buttonCallback}
          text={buttonText}
        />
      ) : null}
    </div>
  );
}

RenderError.propTypes = {
  buttonCallback: PropTypes.func,
  buttonText: PropTypes.string,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default RenderError;
