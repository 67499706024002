import React from "react";
import PropTypes from "prop-types";

import {FormGroup, HTMLSelect} from "@blueprintjs/core";

export const tableTypes = [{
  value: "SIMPLE",
  displayName: "Simple",
}, {
  value: "ADVANCED",
  displayName: "Advanced",
}];

function FormFieldType(props) {
  return (
    <FormGroup
      className={props.formGroupClass}
      label="Table Type"
      labelFor="name"
      labelInfo="*"
    >
      <HTMLSelect
        fill
        name="type"
        onChange={props.onChange}
        options={tableTypes.map((type) => ({label: type.displayName, value: type.value}))}
        value={props.value}
      />
    </FormGroup>
  );
}

FormFieldType.propTypes = {
  formGroupClass: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default FormFieldType;
