import React from "react";
import PropTypes from "prop-types";

import {Intent, Spinner} from "@blueprintjs/core";

import Modal from "./CreateUpdate";

import styles from "./styles.module.scss";

class ColorRow extends React.Component {
  static propTypes = {
    color: PropTypes.object.isRequired,
  }

  state = {
    updateModalOpen: false,
  }

  toggleUpdateModal = () => {
    this.setState({updateModalOpen: true});
  }

  onCloseModal = () => {
    this.setState({
      updateModalOpen: false,
    });
  }

  render() {
    const {color} = this.props;
    const {updateModalOpen} = this.state;

    return (
      <tr onClick={this.toggleUpdateModal}>
        <td className={!color.id ? styles.colorNameCellWithSpinner : null}>
          {(color.name) ? color.name : "-"}
          {!color.id ? <Spinner className={styles.spinner} intent={Intent.PRIMARY} size={Spinner.SIZE_SMALL} /> : null}
        </td>
        <td>
          <div className={styles.colorPreview} style={{backgroundColor: color.color}} />
        </td>
        {updateModalOpen && <Modal color={color} onClose={this.onCloseModal} />}
      </tr>
    );
  }
}

export default ColorRow;
