import {gql} from "@apollo/client";
import {ACCOUNT_FRAGMENT} from "../../../shared/graphql/fragments";

export const RM_ACCOUNTS_QUERY = gql`
  query RMAccountsQuery {
    revenueModelAccounts(includeParent: true, includeEntries: true)  {
      ...AccountProps
    }
  }
  ${ACCOUNT_FRAGMENT}
`;


export const ALL_PLAN_GROUPS_QUERY = gql`
  query AllPlanGroupsQuery {
    plan_groups {
      id
      product_id
      interval
      interval_count
      product {
        id
        name
      }
    }
  }
`;
