import React, {useState} from "react";
import PropTypes from "prop-types";

import {Button, MenuItem} from "@blueprintjs/core";
import {Select} from "@blueprintjs/select";

import highlightText from "../../lib/highlightText";

function ItemRenderer(item, {handleClick, modifiers, query}) {
  if(!modifiers.matchesPredicate) {
    return null;
  }
  const text = `   ${item.text}`;
  const level = item.level || 0;
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.key}
      label={item.label}
      onClick={handleClick}
      style={{marginLeft: level * 15}}
      text={highlightText(text, query)}
    />
  );
}

function filter(query, item) {
  const {text = "", label = ""} = item;
  return (
    text.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
    label.toLowerCase().indexOf(query.toLowerCase()) >= 0
  );
}

export default function FancySelect({disabled = false, fill = false, items = [], value, onSelect, ...otherProps}) {
  const [itemSelectedFromSearch, setItemSelectedFromSearch] = useState();
  const [query, setQuery] = useState("");
  let text = "None";
  let selectedItem = query !== "" && itemSelectedFromSearch ? itemSelectedFromSearch : items.find((i) => i.key === value);
  if(!selectedItem) {
    selectedItem = items[0] || {text: ""};
  }

  if(selectedItem) {
    text = selectedItem.text;
    if(selectedItem.label) {
      text = `${text} (${selectedItem.label})`;
    }
  }

  return (
    <Select
      activeItem={selectedItem}
      disabled={disabled}
      itemPredicate={filter}
      itemRenderer={ItemRenderer}
      items={items}
      noResults={<MenuItem disabled text="No results." />}
      onActiveItemChange={setItemSelectedFromSearch}
      onItemSelect={onSelect}
      onQueryChange={setQuery}
      popoverProps={{fill, minimal: true}}
      resetOnSelect
      {...otherProps}
    >
      <Button
        alignText="left"
        disabled={disabled}
        fill={fill}
        rightIcon="caret-down"
        text={text}
      />
    </Select>
  );
}

FancySelect.propTypes = {
  disabled: PropTypes.bool,
  fill: PropTypes.bool,
  items: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
