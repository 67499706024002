import React, { useReducer, useContext } from "react";
import { AppContext } from "../../../AppContext";

import PropTypes from "prop-types";

import { useQuery } from "@apollo/client";

import { Classes } from "@blueprintjs/core";

import Button from "components/Button";
import Loading from "components/Loading";

import ProductCard from "./ProductCard";
import ProductCreateUpdate from "./ProductCreateUpdate";

import { ALL_PRODUCTS_QUERY } from "./graphql";

import styles from "./styles.module.scss";

const reducer = (state) => ({ productDialogOpen: !state.productDialogOpen });
const initialState = { productDialogOpen: false };

const ChartOfPlans = () => {
  const { user } = useContext(AppContext);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading: productsLoading, data } = useQuery(ALL_PRODUCTS_QUERY, { variables: { includePlans: true } });

  return (
    <div className={styles.mainContainer}>
      {user.role !== "VIEW" ? (
        <header className={styles.header}>
          <Button
            icon="symbol-cross"
            intent="success"
            onClick={dispatch}
            text="New Product"
          />
        </header>
      ) : null}

      {productsLoading ? (
        <Loading />
      ) : !data.products.length ? (
        <h4 className={Classes.HEADING}>No products added yet.</h4>
      ) : data.products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
      {state.productDialogOpen ? <ProductCreateUpdate onClose={dispatch} /> : null}
    </div>
  );
}

export default ChartOfPlans;
