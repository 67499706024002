import React from "react";
import PropTypes from "prop-types";

import Button from "components/Button";

import {actionTypes} from "../reducer";
import CustomPropTypes from "../../propTypes";

import styles from "./styles.module.scss";

export default function Footer({
  dispatch,
  row,
}) {
  return (
    <div className={styles.footer}>
      <Button
        onClick={() => dispatch({type: actionTypes.closeSidebar, payload: {row: null}})}
        size="small"
        text="Close"
      />
      <Button
        intent="danger"
        onClick={() => dispatch({type: actionTypes.deleteRow, payload: {row}})}
        size="small"
        text="Delete Row"
      />
    </div>
  );
}

Footer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  row: CustomPropTypes.row.isRequired,
};
