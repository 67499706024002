import React, {useContext} from "react";
import PropTypes from "prop-types";

import {Icon} from "@blueprintjs/core";
import Button from "components/Button";

import {AppContext} from "../../../AppContext";

import styles from "./styles.module.scss";

export default function AccountingDisconnected({onClose, position}) {
  const {companies} = useContext(AppContext);

  const provider = companies.find((company) => !company.connected && company.confirmed && company.enabled)?.provider ?? "accounting";
  const formattedProvider = provider === "QUICKBOOKS" ? "QuickBooks" : provider === "XERO" ? "Xero" : provider;

  return (
    <div className={[styles.accountingDisconnected, styles[`position${position}`]].join(" ")}>
      <div className={styles.text}>
        <strong>Reconnect {formattedProvider}.&nbsp;</strong>
        <span>Your authorization to {formattedProvider} has expired. Your actuals won't update until you reconnect.</span>
        <Button link="/company/integrations" size="small" text="Reconnect" />
      </div>

      <Icon
        className={styles.close}
        icon="small-cross"
        iconSize={20}
        onClick={onClose}
      />
    </div>
  );
}

AccountingDisconnected.propTypes = {
  onClose: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
};

export function shouldShowAccountingDisconnected({companies, user}) {
  let accountingConnected = true;
  for(const company of companies) {
    const autopilotDone = !!user.tenant.onboarding?.autopilotDone;
    if(!autopilotDone) return false;

    if(!company.connected && company.enabled) {
      // Temporarily disable this for Xero customers and always disable for CSV
      if(company.provider !== "XERO" && company.provider !== "CSV") {
        accountingConnected = false;
        break;
      }
    }
  }

  if(accountingConnected || !["ADMIN", "SUPER"].includes(user.role)) return false;

  return true;
}
