import {gql} from "@apollo/client";
import {ACCOUNT_FRAGMENT} from "../../../shared/graphql/fragments";

export const ALL_ACCOUNTING_ACCOUNTS_QUERY = gql`
  query AllAccountingAccountsQuery {
    accountingAccounts(includeParent: true, includeEntries: false) {
      id
      name
      type
      statement_type
      company_id
      integration_id
      parent
      detail_type
      children
    }
  }
`;

export const createAccount = gql`
  mutation createAccount($account: JSON!) {
    createAccount(account: $account) {
      ...AccountProps
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const updateAccount = gql`
  mutation updateAccount($account: JSON!) {
    updateAccount(account: $account) {
      ...AccountProps
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const removeAccount = gql`
  mutation removeAccount($id: String!) {
    removeAccount(id: $id)
  }
`;
