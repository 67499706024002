import React, { useContext, useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useOutletContext } from "react-router-dom";

import PropTypes from "prop-types";

import { useQuery, useMutation } from "@apollo/client";
import { CSSTransition } from "react-transition-group";

import { Classes, Dialog, Spinner } from "@blueprintjs/core";
import Button from "components/Button";
import CustomButton from "components/Button";
import RenderError from "components/RenderError";
import Errors from "components/Errors";

import Header from "./Header";
import SidebarColumn from "./sidebar/Column";
import SidebarComparisonColumn from "./sidebar/ComparisonColumn";
import SidebarRow from "./sidebar/Row";
import Table from "./Table";
import EmptyTableMessage from "./EmptyTableMessage";

import { createInitialDateRange } from "shared/utilities/date-utilities";
import { resolveAccounts } from "shared/utilities/account-utilities";
import { AppContext } from "../../../AppContext";
import { cleanTableData, getDefaultData } from "./table-utilities";

import { withHooks } from "shared/hooks/hoc";
import { flightpathAccountsByIdVar } from "apollo-config/local-state/accounts";
import { DASHBOARD_QUERY } from "views/dashboard/grid/graphql";
import { ALL_SCENARIOS_QUERY } from "../../../graphql";
import { ALL_SNAPSHOTS_QUERY } from "views/dashboard/chart-builder/graphql";
import { CREATE_TABLE_MUTATION, TABLE_QUERY, UPDATE_TABLE_MUTATION } from "./graphql";

import templates from "views/operating-model/templates";

import styles from "./TableBuilder.module.scss";

function TableBuilder(props) {
  const context = useContext(AppContext);
  const { dashboardId, editedTable, onClose } = useOutletContext();
  const pathOperation = !!location.pathname.match(/\/create/g) ? "create" : "update";

  const [createTable] = useMutation(CREATE_TABLE_MUTATION);
  const [updateTable] = useMutation(UPDATE_TABLE_MUTATION);
  const { data: allScenariosQuery, loading: allScenariosLoading } = useQuery(
    ALL_SCENARIOS_QUERY
  );
  const { data: allSnapshotsQuery, loading: allSnapshotsLoading } = useQuery(
    ALL_SNAPSHOTS_QUERY
  );
  const { data: tableQuery, loading: tableLoading } = useQuery(TABLE_QUERY, {
    variables: { id: props.routerParams.id },
    skip: (editedTable || pathOperation === "create"),
  })

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [currentlyEditing, setCurrentlyEditing] = useState({
    type: null,
    index: null,
  });
  const [editMode, setEditMode] = useState(false);
  const [errors, setErrors] = useState([]);
  const [previousDateSettings, setPreviousDateSettings] = useState(null);
  const [saving, setSaving] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState(getDefaultData("table")({
    forecastStartDate: context.forecastStartDate,
    scenarioId: allScenariosQuery?.scenarios?.[0]?.id,
  }));

  useEffect(() => {
    if (!editMode && (editedTable || tableQuery?.table)) {
      const passedTable = (editedTable || tableQuery?.table);

      let table;
      if (!passedTable.daterange?.dates?.start?.year) {
        table = {
          ...passedTable,
          daterange: {
            dates: createInitialDateRange(context.forecastStartDate, "THIS_YEAR"),
            monthsAgo: 3,
            monthsAhead: 3
          },
        };
      } else {
        table = structuredClone(passedTable);
      }


      const rows = table.rows;
      const columns = table.columns;
      delete table.rows;
      delete table.columns;
      setRows(rows || [])
      setColumns(columns || []);
      setTableData(table);
      setEditMode(true);
    }
  }, [editedTable, tableQuery]);

  const add = (type, index) => (evt) => {
    evt.stopPropagation();

    const pluralType = type === "row" ? rows : columns;
    const pluralSet = type === "row" ? setRows : setColumns;

    const revenueAccount = props.allFlightpathAccounts.find((account) => account.id === "1");

    const newItem = getDefaultData(type)({
      account: revenueAccount || props.allFlightpathAccounts[0],
      forecastStartDate: context.forecastStartDate,
      indentation: type === "row" && rows.length ? rows[(index ? index : rows.length) - 1].formatting.indentation || 0 : null,
      index: (pluralType || []).length + 1,
      scenarioId: allScenariosQuery?.scenarios?.[0]?.id,
    });

    // If it's a column and we previously saved date settings in the state, use that
    if (type === "column" && previousDateSettings) {
      newItem.column_type = previousDateSettings.column_type;
      newItem.daterange = previousDateSettings.daterange;
      newItem.report_period = previousDateSettings.report_period;
    }

    const newArray = [...(!!pluralType ? pluralType : [])];
    let newSelectedIndex = !!pluralType ? pluralType.length : 0;

    if (typeof index === "undefined") {
      newArray.push(newItem);
    } else {
      newArray.splice(index, 0, newItem);
      newSelectedIndex = index;
    }

    setSidebarOpen(true);
    setCurrentlyEditing({
      index: newSelectedIndex,
      type,
    });
    pluralSet(newArray);
  }

  const onTableChange = (key, value) => {
    const { forecastStartDate } = context;

    let newTableData = {
      ...tableData,
      [key]: value,
    };

    // Change date range if report period changes or if column type changes
    if ((key === "report_period" && value !== "CUSTOM") || key === "column_type") {
      const latestReportPeriod = (key === "report_period") ? value : newTableData["report_period"];
      newTableData["daterange"] = {
        ...newTableData["daterange"],
        dates: createInitialDateRange(
          forecastStartDate,
          latestReportPeriod,
          newTableData["daterange"].monthsAgo,
          newTableData["daterange"].monthsAhead,
        ),
      };

      // Change column type if report period is LAST_MONTH or MONTHS_FORECAST
      if (key === "report_period" && (value === "LAST_MONTH" || value === "MONTHS_FORECAST")) {
        newTableData["column_type"] = "MONTHS";
      }
    }

    // Remove the reference snapshots from existing rows
    if (key === "type" && value === "ADVANCED" && tableData.type === "SIMPLE") {
      let changedRows = false;
      let newRows = [...rows];

      for (let i = 0; i < newRows.length; i++) {
        if (newRows[i].reference_snapshot_id) {
          changedRows = true;
          const account = flightpathAccountsByIdVar()[newRows[i].account_id];

          newRows[i] = {
            ...newRows[i],
            account_id: account.reference_id,
            reference_snapshot_id: null,
          };
        }
      }

      if (changedRows) setRows(newRows);
    }

    setTableData(newTableData);
  }

  const onMove = (type) => (dragIndex, hoverIndex) => {
    const pluralType = type === "row" ? rows : columns;
    const pluralSet = type === "row" ? setRows : setColumns;

    const itemsClone = Array.from(pluralType);

    const draggedItem = itemsClone.splice(dragIndex, 1)[0];
    itemsClone.splice(hoverIndex, 0, draggedItem);

    if (currentlyEditing.type === type && currentlyEditing.index !== null) {
      const selectedId = pluralType[currentlyEditing.index].id;
      const newIndex = itemsClone.findIndex((item) => item.id === selectedId);

      setSidebarOpen(true);
      setCurrentlyEditing({
        type,
        index: newIndex,
      });
    }

    pluralSet(itemsClone);
  }

  const onChangeRowColumn = (type, index) => (changes) => {
    const pluralType = type === "row" ? rows : columns;
    const pluralSet = type === "row" ? setRows : setColumns;

    if (!Array.isArray(changes)) {
      changes = [changes];
    }

    const newItem = {
      ...pluralType[index],
    };

    for (const { key, value } of changes) {
      newItem[key] = value;
    }

    const newArray = Array.from(pluralType);
    newArray[index] = newItem;

    pluralSet(newArray);

    // If it's a column, save the date range settings for use in new columns
    if (type === "column") {
      setPreviousDateSettings({
        column_type: newItem.column_type,
        daterange: newItem.daterange,
        report_period: newItem.report_period,
      });
    }
  }

  const onChangeComparisonColumn = (evt) => {
    const stateMutation = {
      tableData: {
        ...tableData,
        options: {
          ...tableData.options,
          [evt.target.name]: evt.target.value,
        }
        ,
      },
    };

    setTableData(stateMutation);
  }

  const validateData = (table) => {
    let newErrors = [...errors];
    let foundError = false;

    if (!table.name.length && !newErrors.find((error) => error.name === "name")) {
      foundError = true;
      newErrors.push({
        name: "name",
        message: "Table name must not be empty",
      });
    }

    if (foundError) {
      setErrors(newErrors);
    }

    return !foundError;
  }

  const onSave = ({ close }) => () => {
    const rawTable = {
      ...tableData,
      rows: rows,
      columns: columns,
    };

    const operation = rawTable.id ? "update" : pathOperation;

    const table = cleanTableData(operation, rawTable);

    if (!validateData(table)) return;

    setSaving(true);

    const mutateFn = operation === "create" ? createTable : updateTable;

    mutateFn({
      variables: { table, dashboardId },
      update: (proxy, { data: { createTable, updateTable } }) => {
        const queryData = proxy.readQuery({ query: DASHBOARD_QUERY, variables: { id: dashboardId } });
        const stateMutation = { saving: false };

        const data = {
          ...queryData,
          dashboard: {
            ...queryData.dashboard,
            tables: [...queryData.dashboard.tables],
          },
        };

        if (createTable) {
          stateMutation.tableData = {
            ...tableData,
            id: createTable.table.id,
          };
          data.dashboard.tables.push(createTable.table);
          data.dashboard.layout = createTable.layout;
        } else {
          const index = data.dashboard.tables.findIndex((table) => updateTable.id === table.id);
          data.dashboard.tables[index] = updateTable;
        }
        proxy.writeQuery({ query: DASHBOARD_QUERY, variables: { id: dashboardId }, data });
        if (close) onClose();
      },
    });

    // TODO: useMutation
    // props[`${operation}Table`]({
    //   variables: { table, dashboardId: props.dashboardId },
    //   update: (proxy, { data: { createTable, updateTable } }) => {
    //     const queryData = proxy.readQuery({ query: DASHBOARD_QUERY, variables: { id: props.dashboardId } });
    //     const stateMutation = { saving: false };
    //
    //     const data = {
    //       ...queryData,
    //       dashboard: {
    //         ...queryData.dashboard,
    //         tables: [...queryData.dashboard.tables],
    //       },
    //     };
    //
    //     if (createTable) {
    //       stateMutation.tableData = {
    //         ...tableData,
    //         id: createTable.table.id,
    //       };
    //       data.dashboard.tables.push(createTable.table);
    //       data.dashboard.layout = createTable.layout;
    //     } else {
    //       const index = data.dashboard.tables.findIndex((table) => updateTable.id === table.id);
    //       data.dashboard.tables[index] = updateTable;
    //     }
    //     proxy.writeQuery({ query: DASHBOARD_QUERY, variables: { id: props.dashboardId }, data });
    //     if (close) onClose();
    //   },
    // });
  }

  const onEdit = (type, index) => () => {
    setSidebarOpen(true);
    setCurrentlyEditing({
      index,
      type,
    });
  }

  const fillFromPnl = () => {
    const rows = [];

    const { sections } = resolveAccounts(props.allFlightpathAccounts, templates["profit-and-loss"], allScenariosQuery.scenarios);

    for (const section of sections) {
      const row = getDefaultData("row")({
        account: section.account,
      });

      if (section.options.collapsible) row.account_id = null;
      if (section.options.indentation) row.formatting.indentation = section.options.indentation;
      if (section.options.isTotal) {
        row.formatting.borderTop = true;
        row.formatting.bold = true;
      }

      rows.push(row);
    }

    setRows(rows);
  }

  const onCloseSidebar = () => {
    setSidebarOpen(false);
  }

  const confirmCloseSidebar = () => {
    onEdit(null)(null);
  }

  const onDeleteRowColumn = (type, deletedRowIndex) => () => {
    const pluralGet = type === "row" ? rows : columns;
    const pluralSet = type === "row" ? setRows : setColumns;

    const newArray = [...pluralGet];
    newArray.splice(deletedRowIndex, 1);

    pluralSet(newArray);
    setCurrentlyEditing({
      index: null,
      type: null,
    });
  }

  const onClosePre = () => {
    setRows([]);
    setColumns([]);
    setErrors([]);
    setCurrentlyEditing({ type: null, index: null });
    setEditMode(false);
    setSidebarOpen(false);
    setSaving(false);
    setTableData(getDefaultData("table")({
      forecastStartDate: context.forecastStartDate,
      scenarioId: allScenariosQuery?.scenarios?.[0]?.id,
    }));
    onClose();
  }

  const { allFlightpathAccounts } = props;
  const saveDisabled = (!rows.length || saving || (tableData.type === "ADVANCED" && !columns.length));

  return (
    <Dialog
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      className={styles.tableBuilderContainer}
      icon="th"
      inline
      isCloseButtonShown={currentlyEditing.index === null}
      isOpen
      lazy
      onClose={onClosePre}
      title="Table Editor"
      usePortal
    >
      <ErrorBoundary
        fallback={
          <div className={styles.dialogBody}>
            <RenderError
              buttonCallback={onClosePre}
              buttonText="Back to Dashboard"
              message={`Chances are we are working on fixing this, but if it keeps happening, please don't hesitate to <a href="mailto:support@flightpathfinance.com">contact support</a>.`}
              title="The Table Editor encountered an error"
            />
          </div>
        }
        onError={(err) => console.error(err)}
      >
        <div className={styles.dialogBody}>
          {tableLoading ? (
            <Spinner />
          ) : (
            <>
              <Errors messages={errors.map((error) => error.message)} />
              {!!pathOperation ? (
                <>
                  <Header
                    columns={columns}
                    onChange={onTableChange}
                    table={tableData}
                  />
                  {(
                    <>
                      {(tableData.type === "SIMPLE" && !rows.length) || (tableData.type === "ADVANCED" && (!rows.length || !columns || !columns.length)) ? (
                        <EmptyTableMessage
                          elementClass={styles.emptyTable}
                          numCols={columns ? columns.length : 0}
                          numRows={rows.length}
                          type={tableData.type}
                        />
                      ) : (
                        <Table
                          columns={columns}
                          currentlyEditing={currentlyEditing}
                          onAdd={add}
                          onEdit={onEdit}
                          onMove={onMove}
                          rows={rows}
                          snapshots={allSnapshotsLoading ? [] : allSnapshotsQuery.snapshotsWithAccountsAndScenarios}
                          snapshotsLoading={allSnapshotsLoading}
                          table={tableData}
                        />
                      )}
                      <CSSTransition
                        classNames={{
                          enter: styles.itemsEnter,
                          enterActive: styles.itemsEnterActive,
                          exit: styles.itemsExit,
                        }}
                        in={sidebarOpen && currentlyEditing.type !== null}
                        mountOnEnter
                        onExited={confirmCloseSidebar}
                        timeout={250}
                        unmountOnExit
                      >
                        {currentlyEditing.type === "row" ? (
                          <SidebarRow
                            onChange={onChangeRowColumn("row", currentlyEditing.index)}
                            onCloseSidebar={onCloseSidebar}
                            onDelete={onDeleteRowColumn("row", currentlyEditing.index)}
                            row={rows[currentlyEditing.index]}
                            snapshots={allSnapshotsLoading ? [] : allSnapshotsQuery.snapshotsWithAccountsAndScenarios}
                            snapshotsLoading={allSnapshotsLoading}
                            table={tableData}
                          />
                        ) : currentlyEditing.type === "column" ? (
                          <SidebarColumn
                            columns={columns}
                            index={currentlyEditing.index}
                            onChange={onChangeRowColumn("column", currentlyEditing.index)}
                            onCloseSidebar={onCloseSidebar}
                            onDelete={onDeleteRowColumn("column", currentlyEditing.index)}
                            snapshots={allSnapshotsLoading ? [] : allSnapshotsQuery.snapshotsWithAccountsAndScenarios}
                            snapshotsLoading={allSnapshotsLoading}
                            table={tableData}
                          />
                        ) : [`valueDeltaColumn`, `percentageDeltaColumn`].includes(currentlyEditing.type) ? (
                          <SidebarComparisonColumn
                            onChange={onChangeComparisonColumn}
                            onCloseSidebar={onCloseSidebar}
                            table={tableData}
                            type={currentlyEditing.type.split("Delta")[0]}
                          />
                        ) : <div />}
                      </CSSTransition>
                    </>
                  )}

                  <div className={[Classes.DIALOG_FOOTER, styles.tableBuilderFooter, sidebarOpen && currentlyEditing.type !== null ? styles.sidebarOpen : undefined].join(" ")}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <div className={styles.leftButtons}>
                        <Button
                          className={styles.addNewRowButton}
                          intent="success"
                          loading={!allFlightpathAccounts || allScenariosLoading}
                          onClick={add("row")}
                        >
                          Add a Row
                        </Button>
                        {tableData.type === "ADVANCED" ? (
                          <Button
                            className={styles.addNewRowButton}
                            intent="success"
                            large
                            loading={allScenariosLoading}
                            onClick={add("column")}
                          >
                            Add a Column
                          </Button>
                        ) : null}
                        {!rows?.length ? (
                          <Button
                            className={styles.addNewRowButton}
                            loading={!allFlightpathAccounts || allScenariosLoading}
                            minimal
                            onClick={fillFromPnl}
                          >
                            Add Entire Profit and Loss
                          </Button>
                        ) : null}
                      </div>
                      <div className={styles.rightButtons}>
                        <Button large onClick={onClosePre} text="Cancel" />
                        <Button
                          disabled={saveDisabled}
                          onClick={onSave({ close: false })}
                          text="Save"
                        />
                        <Button
                          disabled={saveDisabled}
                          intent="success"
                          loading={saving}
                          onClick={onSave({ close: true })}
                          text="Save and Close"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </ErrorBoundary>
    </Dialog>
  );

}

export default withHooks(TableBuilder, ["useAllFlightpathAccounts"]);

export function AddATableButton({ onCreateTable }) {
  return (
    <CustomButton
      icon="add"
      intent="success"
      onClick={onCreateTable}
    >
      Add a Table
    </CustomButton>
  );
}

AddATableButton.propTypes = {
  onCreateTable: PropTypes.func.isRequired,
};

