import { sha256 } from "crypto-hash";

import {
  ApolloClient,
  split,
  HttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
} from "@apollo/client";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { getMainDefinition } from "@apollo/client/utilities";

import typePolicies from "./typePolicies";

const cache = new InMemoryCache({
  typePolicies,
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case "Worksheet": {
        return `Worksheet:${object.slug}`;
      }
      default: return defaultDataIdFromObject(object);
    }
  },
});

const isSecure = window.location.protocol === "https:";

const wsLink = new GraphQLWsLink(createClient({
  url: `ws${isSecure ? "s" : ""}://${window.location.host}/api/graphql`,
}));

const httpLink = createPersistedQueryLink({ sha256 }).concat(new HttpLink({
  uri: "/api/graphql",
  credentials: "include",
}));

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({
  assumeImmutableResults: true,
  freezeResults: true,
  link,
  cache,
});
