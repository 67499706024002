import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";

import Errors from "components/Errors";
import Button from "components/Button";
import {ControlGroup, InputGroup, Tag, Dialog} from "@blueprintjs/core";

import CompanySettingsTeamsDelete from "./CompanySettingsTeamsDelete";
import FormFieldAccount from "../../dashboard/shared/fields/account";
import {ALL_TEAMS_QUERY} from "./graphql";

import styles from "./CompanySettingsEmployees.module.scss";

class CompanySettingsTeamsCreateUpdate extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    operation: PropTypes.string,
    payrollAccounts: PropTypes.array,
    team: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = this.createTeamState(false);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.team === null &&
      this.props.team !== null &&
      this.props.operation !== "delete") {
      const state = this.createTeamState(true);
      this.setState(state);
    }
  }

  createTeamState = (isOpen) => {
    const {team} = this.props;
    const state = {
      id: (team && team.id) ? team.id : null,
      isOpen,
      name: (team && team.name) ? team.name : "",
      benefits_account_id: (team && team.benefits_account) ? team.benefits_account.id : null,
      payroll_taxes_account_id: (team && team.payroll_taxes_account) ? team.payroll_taxes_account.id : null,
      wages_account_id: (team && team.wages_account) ? team.wages_account.id : null,
      benefits: (team && team.benefits) ? team.benefits : 0,
      benefits_period: (team && team.benefits_period) ? team.benefits_period : "MONTH",
      payroll_tax: (team && team.payroll_tax) ? team.payroll_tax : 0,
      teamToDelete: null,
      deleteTeam: false,
      operationActive: false,
    };
    return state;
  }

  changeTeamField = (field, value, valueType, eventType) => {
    if(valueType === "number") {
      const numeralValue = numeral(value).value();

      if(eventType === "blur") value = numeralValue || 0;

      if(!isNaN(numeralValue) || value === "-" || value === "." || value === "") {
        const state = {
          ...this.state,
          [field]: value,
        };
        this.setState(state);
      }
    } else {
      const state = {
        ...this.state,
        [field]: value,
      };
      this.setState(state);
    }
  }

  toggleDialog = () => {
    this.props.onClose();
    this.setState({isOpen: !this.state.isOpen});
  }

  toggleOperationActive = () => this.setState({operationActive: !this.state.operationActive});

  operation = () => {
    const operation = (this.props.operation === "create") ? this.props.onCreate : this.props.onUpdate;
    const team = {...this.state};
    const variables = (this.props.operation === "create") ? {team} : {id: this.state.id, update: team};
    this.toggleOperationActive();
    operation({
      variables,
    })
    .then(() => {
      this.toggleOperationActive();
      this.props.onClose();
      this.setState({errors: [], isOpen: !this.state.isOpen});
    })
    .catch((error) => {
      console.log(error);
      this.toggleOperationActive();
      this.setState({errors: ["The team could not be created, please check all required fields and try again."]});
    });
  }

  deleteTeam = (teamToDelete) => () => {this.setState({teamToDelete, deleteTeam: true});}

  onCancelDelete = () => {this.setState({teamToDelete: null, deleteTeam: false, errors: []});}

  onSuccessDelete = () => {
    this.props.onClose();
    const state = this.createTeamState(false);
    this.setState(state);
  }

  highlightAll = () => {
    setTimeout(() => {
      document.execCommand("selectAll", false, null);
    }, 0);
  }

  render() {
    const title = (this.props.operation === "create") ? "Create Team" : "Update Team";
    const icon = (this.props.operation === "create") ? "add" : "annotation";
    const accounts = this.props.payrollAccounts;
    const topLevelAccounts = accounts.filter((account) => !account.id || account.parent?.id === "2" || account.parent?.id === "3");
    const periods = [
      {value: "YEAR", name: "per year"},
      {value: "MONTH", name: "per month"},
    ];

    return (
      <>
        <Dialog
          canOutsideClickClose={false}
          className="bp5-large"
          icon={icon}
          inline
          isOpen={this.state.isOpen}
          onClose={this.toggleDialog}
          title={title}
        >
          <div className="bp5-dialog-body company-settings-dialog">
            <Errors messages={this.state.errors} />
            <div className="bp5-form-group">
              <h4 className="bp5-heading">Team Name <span className="bp5-text-muted" /></h4>
              <input
                autoComplete="off"
                className="bp5-input bp5-large .modifier"
                dir="auto"
                onChange={(event) => this.changeTeamField("name", event.target.value)}
                placeholder="Team Name"
                type="text"
                value={this.state.name}
              />
            </div>

            <div className="bp5-form-group m-t">
              <h4 className="bp5-heading">Wages Account <span className="bp5-text-muted" /></h4>
              <div className="bp5-form-content">
                <div className="bp5-large bp5-fill">
                  <FormFieldAccount
                    accounts={accounts}
                    fill
                    onChange={({target: {value}}) => this.changeTeamField("wages_account_id", value)}
                    selectedAccountId={this.state.wages_account_id}
                    topLevelAccounts={topLevelAccounts}
                  />
                </div>
              </div>
            </div>

            <div className="bp5-form-group m-t">
              <h4 className="bp5-heading">Benefits Account <span className="bp5-text-muted">(optional)</span></h4>
              <div className="bp5-form-content">
                <div className="bp5-large bp5-fill">
                  <FormFieldAccount
                    accounts={accounts}
                    fill
                    onChange={({target: {value}}) => this.changeTeamField("benefits_account_id", value)}
                    selectedAccountId={this.state.benefits_account_id}
                    topLevelAccounts={topLevelAccounts}
                  />
                </div>
              </div>
            </div>

            <div className="bp5-form-group m-t">
              <h4 className="bp5-heading">Payroll Taxes Account <span className="bp5-text-muted" /></h4>
              <div className="bp5-form-content">
                <div className="bp5-large bp5-fill">
                  <FormFieldAccount
                    accounts={accounts}
                    fill
                    onChange={({target: {value}}) => this.changeTeamField("payroll_taxes_account_id", value)}
                    selectedAccountId={this.state.payroll_taxes_account_id}
                    topLevelAccounts={topLevelAccounts}
                  />
                </div>
              </div>
            </div>

            <div className="bp5-form-group m-t">
              <h4 className="bp5-heading">Default Payroll Tax %</h4>
              <div className="bp5-form-content">
                <ControlGroup fill vertical={false}>
                  <InputGroup
                    autoComplete="off"
                    fill
                    id="payroll-tax"
                    large
                    onBlur={(event) => this.changeTeamField("payroll_tax", event.target.value, "number", "blur")}
                    onChange={(event) => this.changeTeamField("payroll_tax", event.target.value, "number", "focus")}
                    onFocus={this.highlightAll}
                    placeholder="Payroll Tax"
                    // rightElement={<Tag
                    //   icon="percentage"
                    // />}
                    value={this.state.payroll_tax}
                  />
                  <Tag
                    className={`${styles.teamPercentageTag}`}
                    icon="percentage"
                    minimal
                  />
                </ControlGroup>
              </div>
            </div>

            <div className="bp5-form-group m-t">
              <h4 className="bp5-heading">Default Benefits</h4>
              <div className="bp5-form-content">
                <InputGroup
                  autoComplete="off"
                  fill
                  id="benefits"
                  leftIcon="dollar"
                  onBlur={(event) => this.changeTeamField("benefits", event.target.value, "number", "blur")}
                  onChange={(event) => this.changeTeamField("benefits", event.target.value, "number", "focus")}
                  onFocus={this.highlightAll}
                  placeholder="Benefits"
                  value={this.state.benefits}
                />
              </div>
            </div>

            <div className="bp5-form-group m-t">
              <h4 className="bp5-heading">Default Benefits Period</h4>
              <div className="bp5-form-content">
                <div className="bp5-select bp5-large bp5-fill">
                  <select
                    onChange={(event) => this.changeTeamField("benefits_period", event.target.value)}
                    value={this.state.benefits_period}
                  >
                    {periods.map((period, index) => (
                      <option key={index} value={period.value}>{period.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="bp5-dialog-footer">
            <div className="bp5-dialog-footer-actions">
              {(this.props.operation === "update") ? (
                <Button
                  className={`${styles.footerDeleteButton} m-l-0`}
                  disabled={this.state.operationActive}
                  intent="danger"
                  onClick={this.deleteTeam({id: this.state.id, name: this.state.name})}
                  text="Delete"
                />
              ) : null}
              <Button
                disabled={this.state.operationActive}
                onClick={this.toggleDialog}
                text="Cancel"
              />
              <Button
                disabled={this.state.operationActive}
                intent="success"
                onClick={this.operation}
                text="Save"
              />
            </div>
          </div>
        </Dialog>
        <CompanySettingsTeamsDelete
          deleteTeam={this.state.deleteTeam}
          onCancel={this.onCancelDelete}
          onRemove={this.props.onRemove}
          onSuccess={this.onSuccessDelete}
          team={this.state.teamToDelete}
        />
      </>
    );
  }
}

export default CompanySettingsTeamsCreateUpdate;
