import React, {useContext, useState} from "react";
import PropTypes from "prop-types";

import {useQuery} from "@apollo/client";

import Table from "components/Table";
import Loading from "components/Loading";
import TableHeader, {sortFn} from "components/TableHeader";

import PlanRow from "./PlanRow";
import PlanGroupRow from "./PlanGroupRow";
import {AppContext} from "../../../AppContext";

import {ALL_PLAN_GROUPS_QUERY} from "../forecast/graphql";

import styles from "./styles.module.scss";

const columns = [{
  key: "name",
  text: "Name",
}, {
  key: "amount",
  text: "Price",
}, {
  key: "interval_count",
  text: "Interval count",
}];

export default function ProductTable({product}) {
  const [sortAsc, setSortAsc] = useState(true);
  const [sortKey, setSortKey] = useState("name");

  const {user} = useContext(AppContext);

  const {loading, data} = useQuery(ALL_PLAN_GROUPS_QUERY);

  // console.log(data, product);

  const changeSort = (key) => () => {
    if(key === sortKey) {
      setSortAsc(!sortAsc);
    } else {
      setSortAsc(true);
      setSortKey(key);
    }
  };

  if(loading || !product) return <Loading />;

  const plans = (product && product.plans) ? product.plans : [];

  return (
    <Table className={styles.plansTable} hover={user.role !== "VIEW"}>
      <thead>
        <tr>
          {columns.map((column) => (
            <TableHeader
              className={`${styles.name}`}
              id={column.key}
              key={column.key}
              onClick={changeSort(column.key)}
              sortAsc={sortAsc}
              sortKey={sortKey}
            >
              {column.text}
            </TableHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {(product.id !== 0) ? data.plan_groups.filter((planGroup) => planGroup.product_id === product.id).map((planGroup) => (
          <>
            <PlanGroupRow
              key={`planGroup::${planGroup.id}`}
              planGroup={planGroup}
            />
            {plans.filter((plan) => plan.interval === planGroup.interval).sort(sortFn(sortAsc, sortKey)).map((plan) => (
              <PlanRow
                key={`plan::${plan.id}`}
                plan={plan}
              />
            ))}
          </>
        )) : plans.map((plan) => (
          <PlanRow
            key={`plan::${plan.id}`}
            plan={plan}
          />
        ))}
      </tbody>
    </Table>
  );
}

ProductTable.propTypes = {
  product: PropTypes.object.isRequired,
};
