import React from "react";
import PropTypes from "prop-types";

import Button from "components/Button";
import {Classes, Dialog} from "@blueprintjs/core";

export default class TypedConfirmation extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    confirmButtonIntent: PropTypes.string,
    confirmButtonText: PropTypes.string,
    confirmText: PropTypes.string,
    deleting: PropTypes.bool,
    icon: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = {text: ""};
  }

  handleConfirm = () => {
    const confirmText = this.props.confirmText || "delete";
    if(this.state.text.toLowerCase() === confirmText) {
      this.setState({text: ""});
      this.props.onConfirm();
    }
  }

  handleCancel = () => {
    this.setState({text: ""});
    this.props.onCancel();
  }

  onTextChange = (evt) => {
    this.setState({text: evt.target.value});
  }

  onKeyPress = (evt) => {
    if(evt.key === "Enter") this.handleConfirm();
  }

  render() {
    const {
      children,
      confirmButtonIntent,
      confirmButtonText,
      confirmText,
      deleting,
      icon,
      isOpen,
      title,
    } = this.props;
    const {text} = this.state;

    const computedConfirmText = confirmText || "delete";
    const isButtonDisabled = text.toLowerCase() !== computedConfirmText;

    return (
      <Dialog
        canOutsideClickClose={false}
        className="bp5-large"
        icon={icon || "delete"}
        isOpen={isOpen}
        onClose={this.handleCancel}
        title={title || "Delete confirmation"}
      >
        <div className={Classes.DIALOG_BODY}>
          {children}
          <div className="bp5-form-group m-t">
            <input
              autoComplete="off"
              autoFocus
              className="bp5-input bp5-medium"
              dir="auto"
              disabled={deleting}
              onChange={this.onTextChange}
              onKeyPress={this.onKeyPress}
              type="text"
              value={this.state.text}
            />
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={this.handleCancel} text="Cancel" />
            <Button
              disabled={isButtonDisabled}
              intent={confirmButtonIntent || "danger"}
              loading={deleting}
              onClick={this.handleConfirm}
              text={confirmButtonText || "Delete"}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}
