import React from "react";
import PropTypes from "prop-types";

import {FormGroup, HTMLSelect, Spinner} from "@blueprintjs/core";

function FormFieldSnapshot(props) {
  const options = [];
  if(!props.loading) options.push(...props.snapshots.map((snapshot) => ({label: snapshot.name, value: snapshot.id, disabled: !!snapshot.disabled})));

  function onChange(evt) {
    props.onChange({
      target: {
        name: "reference_snapshot_id",
        value: evt.target.value,
      },
    });
  }

  const actualSelect = (
    <HTMLSelect
      disabled={props.loading}
      fill
      name="reference_snapshot_id"
      onChange={onChange}
      options={[
        {label: "Current", value: 0},
        ...options,
      ]}
      value={props.value}
    />
  );

  return (
    <>
      {props.label ? (
        <FormGroup
          className={props.formGroupClass}
          label={props.label}
          labelFor="name"
          labelInfo="*"
        >
          {actualSelect}
          {props.loading ? <Spinner size={20} /> : null}
        </FormGroup>
      ) : actualSelect}
    </>
  );
}

FormFieldSnapshot.propTypes = {
  formGroupClass: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  snapshots: PropTypes.array.isRequired,
  value: PropTypes.string,
};

export default FormFieldSnapshot;
