import React from "react";
import PropTypes from "prop-types";

import {useNavigate} from "react-router-dom";
import {find} from "lodash";

import {Callout, Classes, Intent} from "@blueprintjs/core";

import styles from "./SanityChecks.module.scss";

export default function SanityChecksUnlinkedAccounts({errors, companies}) {
  const navigate = useNavigate();

  const navigateToCOA = () => navigate(`/company/accounts`);

  const formatCompanyName = (error, companies) => {
    const company = find(companies, (company) => company.id === error.company_id);
    return (company) ? company.name : "";
  };

  return (errors.length > 0) ? (
    <table className={[Classes.HTML_TABLE, Classes.INTERACTIVE, styles.sanityChecksTable].join(" ")}>
      <thead>
        <tr>
          <th>Account Name</th>
          <th>Statement</th>
          <th>Company</th>
        </tr>
      </thead>
      <tbody>
        {errors.map((error, index) => (
          <tr key={index} onClick={navigateToCOA}>
            <td>{error.name}</td>
            <td>{error.statement_type}</td>
            <td>{formatCompanyName(error, companies)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <Callout intent={Intent.SUCCESS}>
      No errors found.
    </Callout>
  );
}

SanityChecksUnlinkedAccounts.propTypes = {
  companies: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
};
