import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";

import { useQuery } from "@apollo/client";
import compose from "lodash.flowright";

import Card from "components/Card";
import Loading from "components/Loading";
import { IS_EMAIL_LOGIN_ENABLED_QUERY } from "views/shared/PasswordChange/graphql";

import MFA from "./MFA";
import PasswordSettings from "./PasswordSettings";
import AppSumoStatus from "./AppSumoStatus.jsx";

import styles from "./styles.module.scss";

const UserSettings = (props) => {
  const { user } = useContext(AppContext);
  const isEmailLoginEnabledQuery = useQuery(IS_EMAIL_LOGIN_ENABLED_QUERY);

  if (isEmailLoginEnabledQuery.loading) return <Loading />;

  const { isEmailLoginEnabled } = isEmailLoginEnabledQuery.data;

  return (
    <div className="company-settings">
      <div className="main-content">
        <Card className={[styles.card, styles.passwordOptions].join(" ")}>
          <PasswordSettings isEmailLoginEnabled={isEmailLoginEnabled} user={user} />
        </Card>
        {isEmailLoginEnabled ? (
          <Card className={styles.card}>
            <MFA user={user} />
          </Card>
        ) : null}
        {!!user.appsumo ? (
          <Card className={styles.card}>
            <AppSumoStatus user={user} />
          </Card>
        ) : null}
      </div>
    </div>
  );
};

export default UserSettings;
