import React, { useContext } from "react";
import PropTypes from "prop-types";

import { getEntryForMonth } from "shared/utilities/entry-utilities";
import { isCurrentMonth } from "shared/utilities/forecast-utilities";
import { getFormattedTableCellValue } from "views/dashboard/table-builder/table-utilities"; // Could be extrated to a generic shared function at some point

import { AppContext } from "../../../AppContext";

import { months, isCellHighlighted } from "./utilities";

import styles from "./styles.module.scss";

export default function RowReferenceValues({ account, highlightedCells = {}, onCellClick, row, scenarioId, year }) {
  const { currency, forecastStartDate } = useContext(AppContext);

  const entries = {};
  for (const month of months) {
    entries[month] = (!account) ? null : getEntryForMonth({
      account,
      forecastStartDate,
      formulaType: row.useParentValues ? "mixed" : "regular",
      scenarioId,
      month,
      year,
    });
  }

  return months.map((month) => {
    const cellIsHighlighted = isCellHighlighted({ month, year, highlightedCells, row });
    const isCurrentMonthColumn = isCurrentMonth(forecastStartDate, `${year}-${month.toString().padStart(2, "0")}`);

    const decimals = row.formatting?.decimals || 0;
    const currencySymbol = row.formatting?.dollar ? currency : null;
    const percentage = row.formatting?.percentage;

    const cellClasses = [];
    if (cellIsHighlighted) cellClasses.push(styles.highlighted);
    if (isCurrentMonthColumn) cellClasses.push(styles.currentMonth);

    const formattedEntry = getFormattedTableCellValue(entries[month], {
      decimals,
      currencySymbol,
      percentage,
    });

    return (
      <td
        className={cellClasses.join(" ")}
        key={`${account?.id ?? "new-metric"}::${year}::${month}`}
        onClick={onCellClick(row, forecastStartDate > `${year}-${month.toString().padStart(2, "0")}`)}
      >{formattedEntry}{/*month === 12 ? deleteRowButton : null*/}</td>
    );
  });
}

RowReferenceValues.propTypes = {
  account: PropTypes.object,
  deleteRowButton: PropTypes.node.isRequired,
  onCellClick: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  scenarioId: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
};
