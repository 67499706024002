import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import {Label, HTMLSelect, Classes as BP} from "@blueprintjs/core";
import Button from "components/Button";
import {Spinner, spinnerService} from "components/Spinner";
import Confirmation from "components/Confirmation";
import CSVReader from "components/CSVReader";

import {AppContext} from "../../../AppContext";
import ServerLink from "../../../ServerLink";
import {createYearsArrays} from "views/company-settings/snapshots/forecast-settings-utilities";
import Step from "./Step";

import {updateOnboarding, updateAutoPilot} from "./graphql";
import {DISCONNECT_COMPANY, UPDATE_COMPANY, UPDATE_CSV_COMPANY} from "../../company-settings/integrations/graphql";
import {ALL_SCENARIOS_QUERY, INITIAL_LOAD_QUERY, updateTenant} from "../../../graphql";

import styles from "./styles.module.scss";

import analytics from "segment";

const CSV_ERROR = "There was an issue processing your accounting report.";

const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
const calendarMonths = moment.months();

class Onboarding extends React.Component {
  static propTypes = {
    disconnectCompany: PropTypes.func.isRequired,
    forecastStartDateSet: PropTypes.bool.isRequired,
    setupComplete: PropTypes.bool.isRequired,
    updateAutoPilot: PropTypes.func.isRequired,
    updateCSVCompany: PropTypes.func.isRequired,
    updateCompany: PropTypes.func.isRequired,
    updateOnboarding: PropTypes.func.isRequired,
    updateTenant: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props);
    this.state = {
      company_name: context.companies[0]?.name ?? null,
      corGrowth: 0,
      corRange: 3,
      expGrowth: 0,
      expRange: 3,
      forecastStartMonth: moment().month(),
      forecastStartYear: moment().year(),
      loading: false,
      manuallyClosedConfirmModal: false,
      revGrowth: 0,
      revRange: 3,
      showSkipModal: false,
      yearsArray: createYearsArrays(context.user, context.companies[0]?.provider).forecastStartYearArray,
    };
  }

  componentDidMount() {
    analytics.page("Onboarding");
  }

  static contextType = AppContext

  csvOptions = {
    dynamicTyping: true,
    header: true,
    skipEmptyLines: "greedy",
  };

  handleChange = (e) => this.setState({[e.target.name]: parseInt(e.target.value, 10)});

  updateForecastStartDate = () => {
    const historicalsStartDate = moment({years: this.state.forecastStartYear, months: this.state.forecastStartMonth}).startOf("year").subtract(1, "year").format("YYYY-MM-DD");
    const forecastStartDate = moment({years: this.state.forecastStartYear, months: this.state.forecastStartMonth}).endOf("month").format("YYYY-MM-DD");
    const forecastEndDate = moment(forecastStartDate).add(1, "year").endOf("year").format("YYYY-MM-DD");

    this.props.updateTenant({
      variables: {
        tenant: {
          options: {
            forecastEndDate,
            historicalsStartDate,
            forecastStartDate,
          },
        },
      },
      optimisticResponse: {
        updateTenant: {
          name: "Flightpath",
          options: {},
          __typename: "Tenant",
        },
      },
      update: (cache) => {
        const queryData = cache.readQuery({query: INITIAL_LOAD_QUERY});
        const data = {
          ...queryData,
          currentUser: {
            ...queryData.currentUser,
            tenant: {
              ...queryData.currentUser.tenant,
              options: {
                ...(queryData.currentUser.tenant.options || {}),
                historicalsStartDate,
                forecastStartDate,
              },
            },
          },
        };
        cache.writeQuery({query: INITIAL_LOAD_QUERY, data});
      },
    });
  }

  nextAutopilot = async () => {
    this.setState({loading: true});
    spinnerService.show("ONBOARDING");
    const {updateAutoPilot} = this.props;
    await updateAutoPilot({
      variables: {
        onboarding: {autopilotDone: true, accountConfirmed: true},
        forecastData: {
          revenue: {
            numMonthsAvg: this.state.revRange,
            growAmount: this.state.revGrowth || 0,
          },
          costOfRevenue: {
            numMonthsAvg: this.state.corRange,
            growAmount: this.state.corGrowth || 0,
          },
          expenses: {
            numMonthsAvg: this.state.expRange,
            growAmount: this.state.expGrowth || 0,
          },
        },
      },
      optimisticResponse: {
        updateAutopilot: {
          updateOnboarding: {
            name: "Flightpath",
            onboarding: {},
            __typename: "Tenant",
          },
          updateOnboardingForecasts: true,
        },
      },
      update: (proxy) => {
        const queryData = proxy.readQuery({query: INITIAL_LOAD_QUERY});
        const data = {
          ...queryData,
          currentUser: {
            ...queryData.currentUser,
            tenant: {
              ...queryData.currentUser.tenant,
              onboarding: {
                ...(queryData.currentUser.tenant.onboarding || {}),
                autopilotDone: true,
              },
            },
          },
        };
        proxy.writeQuery({query: INITIAL_LOAD_QUERY, data});
      },
    });
    spinnerService.hide("ONBOARDING");
    this.setState({loading: false});
  }

  changeField = (event, field) => {
    const value = (field === "forecastStartMonth") ? parseInt(event.target.value) : event.target.value;
    const state = {...this.state, [field]: value};
    this.setState(state);
  }

  confirmRemove = () => {
    this.props.disconnectCompany({
      variables: {id: this.context.companies[0].id},
      refetchQueries: [{query: INITIAL_LOAD_QUERY}],
    });
  }

  refreshAccounting = () => {
    const {companies} = this.context;
    this.setState({manuallyClosedConfirmModal: true, loading: true});
    this.props.updateCompany({
      variables: {
        id: companies[0].id,
        confirm: true,
        transferAccounts: true,
      },
      refetchQueries: [{query: ALL_SCENARIOS_QUERY}, {query: INITIAL_LOAD_QUERY}],
    }).then(() => {
      // spinnerService.hide("INTEGRATIONS");
      this.setState({loading: false, errors: []});
    }).catch((error) => {
      // eslint-disable-next-line
      console.error("There was an error pulling from Accounting.", error);
      // spinnerService.hide("INTEGRATIONS");
    });
  }

  uploadCSV = (results) => {
    if(results?.errors?.length === 0) {
      this.setState({loading: true});
      this.props.updateCSVCompany({
        variables: {id: this.context.companies?.[0]?.id ?? null, csv: results, transferAccounts: true},
        refetchQueries: [{query: ALL_SCENARIOS_QUERY}, {query: INITIAL_LOAD_QUERY}],
      }).then(() => {
        this.setState({loading: false, errors: []});
      }).catch((error) => {
        // eslint-disable-next-line
        console.error("There was an error parsing CSV.", error);
        this.setState({errors: [CSV_ERROR]});
      });
    } else {
      this.setState({errors: [CSV_ERROR]});
    }
  }

  showSkipModal = () => {
    this.setState({showSkipModal: true});
  }

  hideSkipModal = () => {
    this.setState({showSkipModal: false});
  }

  skipOnboarding = () => {
    // Use Defaults
    this.setState({
      forecastStartMonth: moment().month(),
      forecastStartYear: moment().year(),
      corGrowth: 0,
      corRange: 3,
      expGrowth: 0,
      expRange: 3,
      revGrowth: 0,
      revRange: 3,
    });
    this.updateForecastStartDate();
    this.nextAutopilot();
  }

  render() {
    const {forecastStartDateSet, setupComplete} = this.props;
    const {company_name, loading, manuallyClosedConfirmModal} = this.state;
    const {companies} = this.context;
    const accountingConnected = companies?.[0]?.connected ?? false;
    const accountingConfirmed = companies?.[0]?.confirmed ?? false;

    let intuitUrl = `${window.location.protocol}//${import.meta.env.VITE_LOGIN_HOST}/api/auth/intuit?onboarding=true&tenant=${window.location.hostname.split(".")[0]}`;
    let xeroUrl = `${window.location.protocol}//${import.meta.env.VITE_LOGIN_HOST}/api/connect/xero?onboarding=true&tenant=${window.location.hostname.split(".")[0]}`;
    let baremetricsUrl = `${window.location.protocol}//${import.meta.env.VITE_LOGIN_HOST}/api/auth/baremetrics?onboarding=true&tenant=${window.location.hostname.split(".")[0]}`;

    if(companies?.[0]) {
      intuitUrl += `&company_id=${companies[0].id}`;
      xeroUrl += `&company_id=${companies[0].id}`;
      baremetricsUrl += `&company_id=${companies[0].id}`;
    }

    if(setupComplete) {
      return (
        <div>
          <Spinner
            group="ONBOARDING"
            icon="airplane"
            message="Updating your Flightpath..."
            name="ONBOARDING"
            show
          />
          <Spinner
            group="INTEGRATIONS"
            icon="refresh"
            message="Refreshing forecasts with latest data..."
            name="INTEGRATIONS"
          />
        </div>
      );
    }

    return (
      <div className={styles.onboarding}>
        <Spinner
          group="ONBOARDING" icon="airplane" message="Updating your Flightpath..."
          name="ONBOARDING"
        />
        <Spinner
          group="INTEGRATIONS"
          icon="refresh"
          message="Refreshing forecasts with latest data..."
          name="INTEGRATIONS"
        />
        <Confirmation
          cancelButtonText="Cancel"
          confirmButtonText={`Connect ${company_name}`}
          isOpen={!manuallyClosedConfirmModal && accountingConnected && !accountingConfirmed}
          onCancel={this.confirmRemove}
          onConfirm={this.refreshAccounting}
          title="QuickBooks connection confirmation"
        >
          <p>You are about to import QuickBooks data from <strong>{company_name}</strong> into your Flightpath.</p>
          <p>If this is not the company you want to use, you need to log out of QuickBooks in a separate tab and try again.</p>

        </Confirmation>
        <h1>Get Started</h1>
        <>
          <Step
            active={!forecastStartDateSet}
            done={forecastStartDateSet}
            loading={loading}
            next={this.updateForecastStartDate}
            text="When do you want your forecast to start?"
          >
            <>
              <p>This is typically one month after the last updated month in your accounting system.</p>
              <h4 className={BP.HEADING}>
                <label className={`${BP.LABEL} ${BP.INLINE} .modifier`}>
                  <div className={`${BP.HTML_SELECT} m-l-0`}>
                    <select defaultValue={this.state.forecastStartMonth} onChange={(event) => this.changeField(event, "forecastStartMonth")}>
                      {calendarMonths.map((month, index) => (
                        <option key={index + month} value={index}>{month}</option>
                      ))}
                    </select>
                  </div>
                  <div className={BP.HTML_SELECT}>
                    <select defaultValue={this.state.forecastStartYear} onChange={(event) => this.changeField(event, "forecastStartYear")}>
                      {this.state.yearsArray.map((year, index) => (
                        <option key={index + year} value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                </label>
              </h4>
            </>
          </Step>
          <Step
            active={forecastStartDateSet && (!accountingConnected || !accountingConfirmed)}
            button={(
              <>
                <ServerLink to={`${intuitUrl}`}>
                  <Button
                    fill
                    intent="success"
                  >
                    Connect QuickBooks
                  </Button>
                </ServerLink>
                <ServerLink to={xeroUrl}>
                  <Button
                    fill
                    intent="primary"
                  >
                    Connect Xero
                  </Button>
                </ServerLink>
                <span style={{marginTop: "8px"}}>
                  <CSVReader
                    configOptions={this.csvOptions}
                    onFileLoaded={this.uploadCSV}
                  />
                </span>
              </>
            )}
            done={accountingConnected && accountingConfirmed}
            loading={loading}
            sideBySide
            text="Connect your accounting provider"
          >
            <p>Flightpath will only read your accounting data and will never write or make any changes to your accounting system.</p>
          </Step>
          <Step
            active={accountingConnected && accountingConfirmed}
            done={false}
            loading={loading}
            next={this.nextAutopilot}
            text="Create your Baseline forecast with Autopilot"
          >
            <table className={styles.onboardingTable}>
              <thead>
                <tr>
                  <th />
                  <th>
                    Starting Value
                  </th>
                  <th/>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Label style={{marginBottom: 0}}>
                      Revenue
                    </Label>
                  </td>
                  <td>
                    <HTMLSelect
                      name="revRange"
                      onChange={this.handleChange}
                      value={this.state.revRange}
                    >
                      {months.map((month) => (
                        <option key={month} value={month}>{month}-Month Average</option>
                      ))}
                    </HTMLSelect>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label
                      style={{marginBottom: 0}}
                    >
                      Cost of Revenue
                    </Label>
                  </td>
                  <td>
                    <HTMLSelect
                      name="corRange"
                      onChange={this.handleChange}
                      value={this.state.corRange}
                    >
                      {months.map((month) => (
                        <option key={month} value={month}>{month}-Month Average</option>
                      ))}
                    </HTMLSelect>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label style={{marginBottom: 0}}>
                      Expenses
                    </Label>
                  </td>
                  <td>
                    <HTMLSelect
                      name="expRange"
                      onChange={this.handleChange}
                      value={this.state.expRange}
                    >
                      {months.map((month) => (
                        <option key={month} value={month}>{month}-Month Average</option>
                      ))}
                    </HTMLSelect>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p>Balance Sheet will use the previous month's value going forward.</p>
          </Step>
          <Button fill onClick={this.showSkipModal}>Skip</Button>
          <Confirmation
            isOpen={this.state.showSkipModal}
            onCancel={this.hideSkipModal}
            onConfirm={this.skipOnboarding}
            title="Skip Onboarding"
          >
            <p>Are you sure?</p>
            <p>This will revert forecast dates and autopilot values to their defaults; you can always change these settings later.</p>
          </Confirmation>
        </>
      </div>
    );
  }
}

export default compose(
  graphql(updateOnboarding, {name: "updateOnboarding"}),
  graphql(updateAutoPilot, {name: "updateAutoPilot"}),
  graphql(updateTenant, {name: "updateTenant"}),
  graphql(DISCONNECT_COMPANY, {name: "disconnectCompany"}),
  graphql(UPDATE_COMPANY, {name: "updateCompany"}),
  graphql(UPDATE_CSV_COMPANY, {name: "updateCSVCompany"}),
)(Onboarding);
