import {useSubscription} from "@apollo/client";

import {client} from "./apollo-config";

import {mergeDeltaFromSubscription as mergeModelsDeltaFromSubscription} from "./apollo-config/local-state/models";
import {mergeDeltaFromSubscription as mergeEntriesDeltaFromSubscription} from "./apollo-config/local-state/entries";
import {mergeDeltaFromSubscription as mergeAccountsDeltaFromSubscription} from "./shared/utilities/account-utilities";

import {evictAllDashboardsFromCache} from "./shared/graphql/apolloCacheEviction";

import {UNIVERSAL_UPDATES_SUBSCRIPTION} from "./graphql";

export default function SubscriptionHandler() {
  useSubscription(UNIVERSAL_UPDATES_SUBSCRIPTION, {onData: ({ data }) => {
    (import.meta.env.DEV) && console.debug("Received subscription data:", data);
    const { universalUpdates } = data.data;
    if(universalUpdates.entries) mergeEntriesDeltaFromSubscription(universalUpdates.entries, universalUpdates.overwrite === true);
    if(universalUpdates.models) mergeModelsDeltaFromSubscription({models: universalUpdates.models, googleSheetsModels: universalUpdates.google_sheets_models, overwrite: universalUpdates.overwrite});
    if(universalUpdates.accounts) mergeAccountsDeltaFromSubscription(universalUpdates.accounts, universalUpdates.overwrite);
    if(universalUpdates.overwrite === true) evictAllDashboardsFromCache(client.cache);
  }});

  return null;
}
