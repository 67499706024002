import React from "react";
import PropTypes from "prop-types";

import moment from "moment";

import {ControlGroup, FormGroup, HTMLSelect} from "@blueprintjs/core";

import {createDateObject, createInitialDateRange} from "shared/utilities/date-utilities";
import {AppContext} from "../../../../AppContext";

const periodsMapping = {
  months: [
    {value: 0, displayName: "January"},
    {value: 1, displayName: "February"},
    {value: 2, displayName: "March"},
    {value: 3, displayName: "April"},
    {value: 4, displayName: "May"},
    {value: 5, displayName: "June"},
    {value: 6, displayName: "July"},
    {value: 7, displayName: "August"},
    {value: 8, displayName: "September"},
    {value: 9, displayName: "October"},
    {value: 10, displayName: "November"},
    {value: 11, displayName: "December"},
  ],
  quarters: [
    {value: 1, displayName: "Q1"},
    {value: 2, displayName: "Q2"},
    {value: 3, displayName: "Q3"},
    {value: 4, displayName: "Q4"},
  ],
};

const monthsAgo = [
  {value: 0, displayName: "0 Months Ago"},
  {value: 1, displayName: "Last Month of Actuals"},
  {value: 2, displayName: "2 Months Ago"},
  {value: 3, displayName: "3 Months Ago"},
  {value: 4, displayName: "4 Months Ago"},
  {value: 5, displayName: "5 Months Ago"},
  {value: 6, displayName: "6 Months Ago"},
  {value: 7, displayName: "7 Months Ago"},
  {value: 8, displayName: "8 Months Ago"},
  {value: 9, displayName: "9 Months Ago"},
  {value: 10, displayName: "10 Months Ago"},
  {value: 11, displayName: "11 Months Ago"},
  {value: 12, displayName: "12 Months Ago"},
  {value: 13, displayName: "13 Months Ago"},
  {value: 14, displayName: "14 Months Ago"},
  {value: 15, displayName: "15 Months Ago"},
  {value: 16, displayName: "16 Months Ago"},
  {value: 17, displayName: "17 Months Ago"},
  {value: 18, displayName: "18 Months Ago"},
  {value: 19, displayName: "19 Months Ago"},
  {value: 20, displayName: "20 Months Ago"},
  {value: 21, displayName: "21 Months Ago"},
  {value: 22, displayName: "22 Months Ago"},
  {value: 23, displayName: "23 Months Ago"},
  {value: 24, displayName: "24 Months Ago"},
];

const monthsAhead = [
  {value: 0, displayName: "0 Months Ahead"},
  {value: 1, displayName: "1 Month Ahead"},
  {value: 2, displayName: "2 Months Ahead"},
  {value: 3, displayName: "3 Months Ahead"},
  {value: 4, displayName: "4 Months Ahead"},
  {value: 5, displayName: "5 Months Ahead"},
  {value: 6, displayName: "6 Months Ahead"},
  {value: 7, displayName: "7 Months Ahead"},
  {value: 8, displayName: "8 Months Ahead"},
  {value: 9, displayName: "9 Months Ahead"},
  {value: 10, displayName: "10 Months Ahead"},
  {value: 11, displayName: "11 Months Ahead"},
  {value: 12, displayName: "12 Months Ahead"},
  {value: 13, displayName: "13 Months Ahead"},
  {value: 14, displayName: "14 Months Ahead"},
  {value: 15, displayName: "15 Months Ahead"},
  {value: 16, displayName: "16 Months Ahead"},
  {value: 17, displayName: "17 Months Ahead"},
  {value: 18, displayName: "18 Months Ahead"},
  {value: 19, displayName: "19 Months Ahead"},
  {value: 20, displayName: "20 Months Ahead"},
  {value: 21, displayName: "21 Months Ahead"},
  {value: 22, displayName: "22 Months Ahead"},
  {value: 23, displayName: "23 Months Ahead"},
  {value: 24, displayName: "24 Months Ahead"},
];

class FormFieldDateRange extends React.Component {
  static propTypes = {
    changeDateRange: PropTypes.func,
    column_type: PropTypes.string,
    daterange: PropTypes.object,
    direction: PropTypes.string,
    formGroupClass: PropTypes.string,
    onChange: PropTypes.func,
    report_period: PropTypes.string,
    years: PropTypes.array.isRequired,
  }

  static contextType = AppContext

  // Type = start or end.
  changeValue = (report_period, type, unit) => (evt) => {
    const {changeDateRange, column_type, daterange} = this.props;
    const {forecastStartDate} = this.context;

    const value = parseInt(evt.target.value);
    const monthsAgo = (type === "monthsAgo") ? value : daterange.monthsAgo;
    const monthsAhead = (type === "monthsAhead") ? value : daterange.monthsAhead;
    let dates;

    if(report_period === "MONTHS_FORECAST") {
      dates = createInitialDateRange(forecastStartDate, report_period, monthsAgo, monthsAhead);
    } else {
      dates = {
        start: (type === "start") ? createDateObject(column_type, daterange.dates.start, type, unit, value) : daterange.dates.start,
        end: (type === "end") ? createDateObject(column_type, daterange.dates.end, type, unit, value) : daterange.dates.end,
      };
    }

    changeDateRange({dates, monthsAgo, monthsAhead});
  }

  // type is start or end. unit is month, quarter, year. value is actual value
  isDateDisabled = (dates, type, unit, value) => {
    const newDates = {
      ...dates,
      [type]: {
        ...dates[type],
        [unit]: parseInt(value),
      },
    };

    if(newDates.start.year > newDates.end.year) return true;
    if(newDates.start.year === newDates.end.year) {
      if(newDates.start[unit] > newDates.end[unit]) return true;
      if(unit === "year" && newDates.start.month > newDates.end.month) return true;
    }
    return false;
  }

  getDynamicDates = () => {
    const {report_period, daterange} = this.props;
    const {forecastStartDate} = this.context;

    const momentForecastStartDate = moment(forecastStartDate);

    let start = null;
    let end = null;

    if(report_period === "LAST_MONTH") {
      start = moment(momentForecastStartDate).subtract(1, "month");
      end = moment(start);
    } else if(report_period === "THIS_YEAR") {
      start = moment(momentForecastStartDate).subtract(1, "month").startOf("year");
      end = moment(momentForecastStartDate).subtract(1, "month").endOf("year");
    } else if(report_period === "THIS_YEAR_TO_DATE") {
      start = moment(momentForecastStartDate).subtract(1, "month").startOf("year");
      end = moment(momentForecastStartDate).subtract(1, "month");
    } else {
      return daterange.dates;
    }

    const dates = {
      start: {
        month: start.month(),
        quarter: start.format("Q"),
        year: start.year(),
      },
      end: {
        month: end.month(),
        quarter: end.format("Q"),
        year: end.year(),
      },
    };

    return dates;
  }

  render() {
    const {
      column_type,
      daterange,
      direction,
      formGroupClass,
      report_period,
      years,
    } = this.props;

    const {forecastStartDate, forecastEndDate, historicalsStartDate} = this.context;

    const dates = this.getDynamicDates();

    return (
      <FormGroup
        className={formGroupClass}
        label="Date Range"
        labelFor="daterange"
        labelInfo="*"
      >
        {(report_period === "MONTHS_FORECAST") ? (
          <>
            <HTMLSelect
              fill
              name="monthsAgo"
              onChange={this.changeValue(report_period, "monthsAgo", null)}
              value={daterange.monthsAgo}
            >
              {monthsAgo.filter((date) => moment(forecastStartDate).subtract(date.value, "months").endOf("month").format("YYYY-MM") >= moment(historicalsStartDate).endOf("month").format("YYYY-MM")).map((date) => (<option key={date.value} value={date.value}>{date.displayName}</option>))}
            </HTMLSelect>
            <HTMLSelect
              fill
              name="monthsAhead"
              onChange={this.changeValue(report_period, "monthsAhead", null)}
              value={daterange.monthsAhead}
            >
              {monthsAhead.filter((date) => moment(forecastStartDate).add(date.value, "months").endOf("month").format("YYYY-MM") <= moment(forecastEndDate).endOf("month").format("YYYY-MM")).map((date) => (<option key={date.value} value={date.value}>{date.displayName}</option>))}
            </HTMLSelect>
          </>
        ) : null}

        {(report_period !== "MONTHS_FORECAST") ? (
          <ControlGroup>
            {["start", "end"].map((type) => (
              <div key={type}>
                {direction === "VERTICAL" ? (
                  <span>{type === "start" ? "From:" : "To:"}&nbsp;</span>
                ) : null}
                {column_type === "MONTHS" ? (
                  <HTMLSelect
                    disabled={(report_period !== "CUSTOM")}
                    name="month"
                    onChange={this.changeValue(report_period, type, "month")}
                    value={dates[type].month}
                  >
                    {periodsMapping["months"].map((month, i) => (<option disabled={this.isDateDisabled(daterange.dates, type, "month", month.value)} key={i} value={month.value}>{month.displayName}</option>))}
                  </HTMLSelect>
                ) : null}
                {column_type === "QUARTERS" ? (
                  <HTMLSelect
                    disabled={(report_period !== "CUSTOM")}
                    name="quarter"
                    onChange={this.changeValue(report_period, type, "quarter")}
                    value={dates[type].quarter}
                  >
                    {periodsMapping["quarters"].map((quarter, i) => (<option disabled={this.isDateDisabled(daterange.dates, type, "quarter", quarter.value)} key={i + 1} value={quarter.value}>{quarter.displayName}</option>))}
                  </HTMLSelect>
                ) : null}
                <HTMLSelect
                  disabled={(report_period !== "CUSTOM")}
                  name="year"
                  onChange={this.changeValue(report_period, type, "year")}
                  value={dates[type].year}
                >
                  {years.map((year) => (<option disabled={this.isDateDisabled(daterange.dates, type, "year", year)} key={year} value={year}>{year}</option>))}
                </HTMLSelect>
                {direction !== "VERTICAL" && type === "start" ? (
                  <span> To&nbsp;</span>
                ) : <br />}
              </div>
            ))}
          </ControlGroup>
        ) : null}
      </FormGroup>
    );
  }
}

export default FormFieldDateRange;
