import React, { useEffect, useState } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useScrollbarSize from "react-scrollbar-size";
import Cookies from "js-cookie";

import * as Sentry from "@sentry/browser";

import { useQuery, useSubscription } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";

import { Outlet, useLocation } from "react-router-dom";

import { INITIAL_LOAD_QUERY, TENANT_UPDATED_SUBSCRIPTION, ALL_SCENARIOS_QUERY } from "./graphql";

import { setFromInitialLoadQuery as setModelsCacheFromInitialLoadQuery } from "./apollo-config/local-state/models";
import { setFromInitialLoadQuery as setEntriesCacheFromInitialLoadQuery } from "./apollo-config/local-state/entries";
import { setFromInitialLoadQuery as setUserFromInitialLoadQuery } from "./apollo-config/local-state/user";
import { setFromInitialLoadQuery as setAccountsFromInitialLoadQuery } from "./apollo-config/local-state/accounts";

import Toaster from "./components/Toaster";
import Loading from "./components/Loading";
import Blocker from "./components/Blocker";

import SidebarNav from "./views/navigation/Sidebar";
import Maintenance from "./views/Maintenance";
import AccountingDisconnected, { shouldShowAccountingDisconnected } from "./views/shared/AccountingDisconnected";
import GeneralMessage, { shouldShowGeneralMessage } from "./views/shared/GeneralMessage";

import AppContextProvider from "./AppContext";
import SubscriptionHandler from "./SubscriptionHandler";

import styles from "./App.module.scss";

import "./styles/bp3-overrides.css";
import "./styles/utilities.css";
import "./lib/highcharts.js";

import { FocusStyleManager, Icon } from "@blueprintjs/core";
FocusStyleManager.onlyShowFocusOnTabs();

if (!window.location.href.includes("3000")) {
  Sentry.init({ dsn: "https://c3c4434718a44ee0b5e53cae23bd2644@sentry.io/1410657" });
}

if(import.meta.env.DEV) {
  loadDevMessages();
  loadErrorMessages();
}

const ongoingMaintenance = !Cookies.get("hide_maintenance") && false;

const App = () => {
  const location = useLocation();
  const [childrenLoading, setChildrenLoading] = useState(false);
  const [globalQueriesLoaded, setGlobalQueriesLoaded] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const { width: scrollbarWidth } = useScrollbarSize();
  const [toasts, setToasts] = useState({});
  const [manuallyClosedTopBars, setManuallyClosedTopBars] = useState({
    accountingDisconnected: false,
    generalMessage: false,
  });
  const [temporarilyHideTopBars, setTemporarilyHideTopBars] = useState(false);

  const initialLoadQuery = useQuery(INITIAL_LOAD_QUERY);
  const allScenariosQuery = useQuery(ALL_SCENARIOS_QUERY);
  const _tenantUpdatedSubscription = useSubscription(TENANT_UPDATED_SUBSCRIPTION);

  useEffect(() => {
    if (initialLoadQuery.error) {
      // Redirect to login page in case user is logged out
      if (initialLoadQuery.error.toString().includes("Authentication denied")) {
        window.location.href = `https://${import.meta.env.VITE_LOGIN_HOST}`;
      } else {
        console.error(initialLoadQuery.error);
        return;
      }
    }

    if (!initialLoadDone && initialLoadQuery.data && !allScenariosQuery.loading && allScenariosQuery.data) {
      setEntriesCacheFromInitialLoadQuery(initialLoadQuery.data);
      setModelsCacheFromInitialLoadQuery(initialLoadQuery.data);
      setUserFromInitialLoadQuery(initialLoadQuery.data);
      setAccountsFromInitialLoadQuery(initialLoadQuery.data);
      setLoadingUser(false);

      if (!globalQueriesLoaded) {
        setGlobalQueriesLoaded(true);
        setInitialLoadDone(true);
      }
    }
  }, [initialLoadQuery.loading, allScenariosQuery.loading]);

  const addToast = (name, options) => {
    setToasts(t => ({ ...t, [name]: options }));
    if (options.progress === 100 || !Object.hasOwn(options, "progress")) {
      setTimeout(() => removeToast(name), 5000);
    }
  }

  const removeToast = (name) => {
    let newToasts = { ...toasts };
    delete newToasts[name];
    setToasts(ts => newToasts);
  }

  const handleCloseTopBar = (key) => () => {
    setManuallyClosedTopBars({ ...manuallyClosedTopBars, [key]: true });
  }

  const handleTemporarilyhideTopBars = () => {
    setTemporarilyHideTopBars(true);
  }

  const { currentUser: user = {}, companies, tenantAuthority } = initialLoadQuery?.data || {};
  const tenant = user?.tenant ?? {};

  // TODO: Make this a boolean column on "tenant" instead.
  // We should be able to disable accounts at will, and the backend should respond accordingly.
  const isAccountDisabled = tenantAuthority?.appsumo?.license_status === "deactivated";

  let topPaddingStyle = null;
  const accountingDisconnectedShown = globalQueriesLoaded && !childrenLoading && !temporarilyHideTopBars && !manuallyClosedTopBars.accountingDisconnected && shouldShowAccountingDisconnected({ companies, user });
  const generalMessageShown = globalQueriesLoaded && !childrenLoading && !temporarilyHideTopBars && !manuallyClosedTopBars.generalMessage && shouldShowGeneralMessage(user);

  if (accountingDisconnectedShown && generalMessageShown) {
    topPaddingStyle = styles.double;
  } else if (accountingDisconnectedShown || generalMessageShown) {
    topPaddingStyle = styles.simple;
  }

  if (ongoingMaintenance) return (<Maintenance />);

  let pageTitle = tenant?.name ?? "Loading...";

  if (!import.meta.env.PROD) pageTitle = `[${import.meta.env.MODE}] ${pageTitle}`;

  useEffect(() => {
    document.title = pageTitle;
  }, [tenant]);

  return (
    <div className={["App"]}>
      <Toaster toasts={toasts} />
      <DndProvider backend={HTML5Backend}>
        <div
          className={[
            styles.appBody,
            (accountingDisconnectedShown || generalMessageShown) ? styles.topBarShown : undefined,
            topPaddingStyle,
          ].join(" ")}
        >
          {(!globalQueriesLoaded || childrenLoading) ? (
            <div className={styles.initialLoadingContainer}>
              <Loading
                initial
                text="Loading your Flightpath forecasts..."
                unmounted={globalQueriesLoaded && !childrenLoading}
              />
            </div>
          ) : null}

          {(!loadingUser && !initialLoadQuery.loading) ? (
            <AppContextProvider
              addToast={addToast}
              companies={companies}
              initialLoadDone={initialLoadDone}
              onTemporarilyhideTopBars={handleTemporarilyhideTopBars}
              removeToast={removeToast}
              scrollbarWidth={scrollbarWidth}
              setChildrenLoading={setChildrenLoading}
              user={user}
              tenantAuthority={tenantAuthority}
            >
              <SubscriptionHandler />

              {accountingDisconnectedShown ? (<AccountingDisconnected onClose={handleCloseTopBar("accountingDisconnected")} position={2} />) : null}
              {generalMessageShown ? (<GeneralMessage onClose={handleCloseTopBar("generalMessage")} position={accountingDisconnectedShown ? 1 : 2} />) : null}

              <SidebarNav />

              {
                (isAccountDisabled && location.pathname !== "/user-settings")
                  ? <Blocker text="Your account is currently deactivated." />
                  : <Outlet />
              }
            </AppContextProvider>
          ) : null}
        </div>
      </DndProvider>
    </div>
  );
}

export default App;
