import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import {spinnerService} from "components/Spinner";
import Errors from "components/Errors";
import Card from "components/Card";
import Button from "components/Button";
import SettingsHeader from "components/SettingsHeader";

import {INITIAL_LOAD_QUERY} from "../../../graphql";

import CompanySettingsGoogle from "./Google";
import Company from "./Company";
import NewCompany from "./NewCompany";

import {AppContext} from "../../../AppContext";

import styles from "./styles.module.scss";

export const INTUIT_URL = `${window.location.protocol}//${import.meta.env.VITE_LOGIN_HOST}/api/auth/intuit?tenant=${window.location.hostname.split(".")[0]}`;
export const XERO_URL = `${window.location.protocol}//${import.meta.env.VITE_LOGIN_HOST}/api/connect/xero?tenant=${window.location.hostname.split(".")[0]}`;
export const BAREMETRICS_URL = `${window.location.protocol}//${import.meta.env.VITE_LOGIN_HOST}/api/auth/baremetrics?tenant=${window.location.hostname.split(".")[0]}`;

export const CSV_ERROR = "There was an issue processing your accounting report.";
export const QUICKBOOKS_AUTH_ERROR = "Flightpath's authorization to your QuickBooks has expired or your QuickBooks account's privileges have changed. Please click Reconnect below to restore access.";
export const XERO_AUTH_ERROR = "Flightpath's authorization to your Xero account has expired or your Xero account's privileges have changed. Please click Reconnect to restore access. Xero access is curently in Beta. Flightpath's authorization will only last 30 minutes and then you must reconnect.";
export const BAREMETRICS_AUTH_ERROR = "Flightpath's authorization to your Baremetrics account has expired or your Baremetrics account's privileges have changed. Please click Reconnect to restore access.";

class CompanySettingsIntegrations extends React.Component {
  static propTypes = {
    initialLoadQuery: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      saving: false,
      showNewCompany: false,
    };
  }

  componentWillUnmount() {
    this.context.removeToast("refreshAccounting");
  }

  static contextType = AppContext

  openIntuitLoginPopUp = () => {
    const name = "intuit_login";
    const specs = "width=600,height=840";
    document.openWindow(INTUIT_URL, name, specs);
  }

  toggleLoader = () => {
    if(spinnerService.isShowing("INTEGRATIONS")) {
      spinnerService.hide("INTEGRATIONS");
    } else {
      spinnerService.show("INTEGRATIONS");
    }
  }

  setErrors = (errors) => {
    this.setState({errors});
  }

  setSaving = (saving) => {
    this.setState({saving});
  }

  setShowNewCompany = (showNewCompany) => () => {
    this.setState({showNewCompany});
  }

  render() {
    const {initialLoadQuery: {companies}} = this.props;
    const {user} = this.context;

    return (
      <>
        <Card className={styles.integrationsMain}>
          <SettingsHeader subtitle text="Accounting" />
          <p>
            Connect your accounting software to pull data into your Operating Model.<br />
            We don't make changes to your books.
          </p>
          <Errors messages={this.state.errors}/>

          {companies.map((company) => (
            <Company
              company={company}
              hideNewCompany={this.setShowNewCompany(false)}
              key={`company-${company.id}`}
              saving={this.state.saving}
              setErrors={this.setErrors}
              setSaving={this.setSaving}
            />
          ))}
          {this.state.showNewCompany ? (
            <NewCompany
              hideNewCompany={this.setShowNewCompany(false)}
              saving={this.state.saving}
              setErrors={this.setErrors}
              setSaving={this.setSaving}
            />
          ) : (
            <Button
              border
              icon="plus"
              minimal
              onClick={this.setShowNewCompany(true)}
              size="small"
              text="Connect New Company (advanced)"
            />
          )}
        </Card>
        <Card>
          <SettingsHeader subtitle text="Google" />
          <CompanySettingsGoogle toggleLoader={this.toggleLoader} user={user} />
        </Card>
      </>
    );
  }
}

const CompanySettingsIntegrationsWithData = compose(
  graphql(INITIAL_LOAD_QUERY, {name: "initialLoadQuery"}),
)(CompanySettingsIntegrations);


export default CompanySettingsIntegrationsWithData;
