import {useContext, useState} from "react";
import PropTypes from "prop-types";

import {useMutation} from "@apollo/client";

import {Alert, Intent, Radio, RadioGroup} from "@blueprintjs/core";
import {Spinner} from "components/Spinner";
import Button from "components/Button";
import Confirmation from "components/Confirmation";
import TypedConfirmation from "components/TypedConfirmation";

import ServerLink from "../../../ServerLink";
import CSVButton from "./CSVButton";

import {DISCONNECT_COMPANY, UPDATE_COMPANY, REMOVE_COMPANY} from "./graphql";
import {INITIAL_LOAD_QUERY} from "../../../graphql";

import {
  QUICKBOOKS_AUTH_ERROR,
  XERO_AUTH_ERROR,
  BAREMETRICS_AUTH_ERROR,
  INTUIT_URL,
  XERO_URL,
  BAREMETRICS_URL
} from "./index";

import styles from "./styles.module.scss";

import {AppContext} from "../../../AppContext";

export default function Company({
  company,
  hideNewCompany,
  saving,
  setErrors,
  setSaving,
}) {
  const {addToast, removeToast} = useContext(AppContext);
  const [showAlert, setShowAlert] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [accountingMethod, setAcountingMethod] = useState(company.accounting_method || "Accrual");
  const [confirmationRequired, setConfirmationRequired] = useState(company.provider === "QUICKBOOKS" && company.connected && !company.confirmed);
  const providerCapitalized = (company.provider) ? company.provider.charAt(0).toUpperCase() + company.provider.slice(1).toLowerCase() : null;

  const [disconnectCompany] = useMutation(DISCONNECT_COMPANY);
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const [removeCompany] = useMutation(REMOVE_COMPANY);

  const confirmDisable = (isAccountingConfirmation) => () => {
    setConfirmationRequired(false);
    disconnectCompany({variables: {id: company.id}});

    if(!isAccountingConfirmation) setShowAlert(!showAlert);
  };

  const confirmDelete = () => {
    setSaving(true);
    removeCompany({
      variables: {id: company.id},
      refetchQueries: [{query: INITIAL_LOAD_QUERY}],
    }).then(() => setSaving(false));
  };

  const refreshAccounting = (confirm) => () => {
    setConfirmationRequired(false);

    addToast("refreshAccounting", {
      icon: "airplane",
      message: "Pulling fresh data from your accounting...",
      progress: 99,
    });

    updateCompany({
      variables: {id: company.id, confirm},
      refetchQueries: [{query: INITIAL_LOAD_QUERY}],
    }).then(() => {
      addToast("refreshAccounting", {
        icon: "airplane",
        message: "Done pulling fresh data from your accounting!",
        progress: 100,
      });
      setErrors([]);
    }).catch((error) => {
      // eslint-disable-next-line
      console.log("There was an error pulling from Accounting.", error);
      removeToast("refreshAccounting");
      setErrors([
        (company.provider === "QUICKBOOKS")
        ? QUICKBOOKS_AUTH_ERROR
        : (company.provider === "XERO")
        ? XERO_AUTH_ERROR
        : BAREMETRICS_AUTH_ERROR
      ]);
    });
  };

  const changeAccountingMethod = ({target: {value: accountingMethod}}) => {
    if(accountingMethod === "Cash" || accountingMethod === "Accrual") {
      setSaving(true);
      setAcountingMethod(accountingMethod);
      updateCompany({
        variables: {
          id: company.id,
          accountingMethod,
        },
      }).then(() => {
        setErrors([]);
        setSaving(false);
        window.location.reload();
      }).catch((error) => {
        setErrors([]);
        setSaving(false);
        // eslint-disable-next-line
        console.log("Error saving accounting method.", error);
      });
    }
  };

  return (
    <>
      <Spinner
        group="INTEGRATIONS"
        icon="refresh"
        message="Refreshing forecasts with latest data..."
        name="INTEGRATIONS"
      />
      <div className={styles.companyContainer}>
        <div className={styles.providerIcon}>
          <img alt="provider-icon" src={`/${company.provider.toLowerCase()}-icon.png`} />
        </div>
        <div className={styles.providerInfo}>
          <div className={styles.providerName}>
            <span>{company.provider === "QUICKBOOKS" ? "QuickBooks Online" : company.provider === "XERO" ? "Xero (beta)" : company.provider === "BAREMETRICS" ? "Baremetrics" : "CSV Import"}</span>
          </div>
          <div className={styles.companyNameContainer}>
            <span>Company:</span>
            <span className={styles.companyName}>{company.name}</span>
            <span>({company.connected ? "connected" : "disconnected"})</span>
          </div>
          {company.connect ? (
            <div className={styles.accountingMethodContainer}>
              <span>Accounting Method:</span>
              <RadioGroup
                inline
                onChange={changeAccountingMethod}
                selectedValue={accountingMethod}
              >
                <Radio label="Accrual" value="Accrual" />
                <Radio label="Cash" value="Cash" />
              </RadioGroup>
            </div>
          ) : null}
        </div>
        <div className={styles.actionButtons}>
          {company.enabled ? (
            <Button
              border
              disabled={saving}
              icon="disable"
              intent="danger"
              minimal
              onClick={() => setShowAlert(!showAlert)}
              width={200}
            >
              Disable
            </Button>
          ) : (
            <Button
              border
              disabled={saving}
              icon="delete"
              intent="danger"
              minimal
              onClick={() => setShowDeleteConfirmation(true)}
              width={200}
            >
              Delete
            </Button>
          )}
          <ServerLink to={`${(company.provider === "QUICKBOOKS") ? INTUIT_URL : (company.provider === "XERO") ? XERO_URL : BAREMETRICS_URL}&company_id=${company.id}`}>
            <Button
              disabled={saving}
              icon="add"
              width={200}
            >
              Reconnect
            </Button>
          </ServerLink>
          {company.connected && company.provider !== "CSV" ? (
            <Button
              disabled={saving}
              icon="refresh"
              intent="success"
              onClick={refreshAccounting(false)}
              width={200}
            >
              Refresh
            </Button>
          ) : company.connected ? (
            <CSVButton
              company={company}
              hideNewCompany={hideNewCompany}
              saving={saving}
              setErrors={setErrors}
              setSaving={setSaving}
            />
          ) : null}
        </div>
      </div>
      <Alert
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Disconnect"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={showAlert}
        onCancel={() => setShowAlert(!showAlert)}
        onClose={() => setShowAlert(!showAlert)}
        onConfirm={confirmDisable()}
      >
        <p>
          Are you sure you want to disconnect your {providerCapitalized} account? Flightpath will no longer be able to access {providerCapitalized}.
          Your existing accounts, models, and data inside Flightpath will not be affected.
        </p>
      </Alert>
      <Confirmation
        cancelButtonText="Cancel"
        confirmButtonText={`Connect ${company.name}`}
        isOpen={confirmationRequired}
        onCancel={confirmDisable(true)}
        onConfirm={refreshAccounting(true)}
        title="QuickBooks connection confirmation"
      >
        <p>You are about to import QuickBooks data from <strong>{company.name}</strong> into your Flightpath.</p>
        <p>If this is not the company you want to use, you need to log out of QuickBooks in a separate tab and try again.</p>
      </Confirmation>
      <TypedConfirmation
        deleting={saving}
        isOpen={showDeleteConfirmation}
        onCancel={() => setShowDeleteConfirmation(false)}
        onConfirm={confirmDelete}
      >
        <p>Are you sure that you want to remove this company? This is irreversible and will delete all the data that was pulled from this company.</p>
        <p>
          Please type 'delete' in the input field then click <strong>Delete</strong> below if you are sure
          that you would like this company to be removed.
        </p>
      </TypedConfirmation>
    </>
  );
}

Company.propTypes = {
  company: PropTypes.object.isRequired,
  hideNewCompany: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  setErrors: PropTypes.func.isRequired,
  setSaving: PropTypes.func.isRequired,
};
