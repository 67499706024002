import { Icon, Classes as BP } from "@blueprintjs/core";

export default function(currency) {
  switch(currency) {
    case "dollar": {
      return <Icon icon="dollar" />;
    }
    case "euro": {
      return <Icon icon="euro" />;
    }
    case "poundSterling": {
      return (<span className={BP.ICON}>£</span>);
    }
  }
}
