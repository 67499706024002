import React from "react";

import SettingsHeader from "components/SettingsHeader";
import Card from "components/Card";
import {Tab, Tabs} from "@blueprintjs/core";

import SnapshotsTable from "./SnapshotsTable";
import Modal from "./SnapshotsCreateUpdate";

import styles from "./styles.module.scss";

class Snapshots extends React.Component {
  state = {
    modalOpen: false,
  }

  createSnapshot = () => {
    this.setState({modalOpen: true});
  }

  onCloseModal = () => {
    this.setState({modalOpen: false});
  }

  render() {
    const {modalOpen} = this.state;

    return (
      <>
        <Card className={styles.snapshotsCard}>
          <SettingsHeader
            buttonAction={this.createSnapshot}
            buttonText="New"
            subtitle
            text="Snapshots"
          />
          <Tabs
            animate
            className={styles.snapshotTabs}
            id="snapshots-tabs"
            large
            onChange={this.handleTabChange}
            selectedTabId={this.state.tabId}
          >
            <Tab
              id="all-snapshots"
              panel={<SnapshotsTable />}
              title="Available Snapshots"
            />
            <Tab
              id="archived-snapshots"
              panel={<SnapshotsTable archived />}
              title="Archived Snapshots"
            />
          </Tabs>
        </Card>
        {modalOpen && <Modal onClose={this.onCloseModal} />}
      </>
    );
  }
}

export default Snapshots;
