import {gql} from "@apollo/client";
import {ACCOUNT_FRAGMENT} from "../../shared/graphql/fragments";

export const REVENUE_MRR_ACCOUNTS = gql`
  query RevenueMRRAccounts {
    revenueMRRAccounts {
      ...AccountProps
      display_name
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const UPDATE_ACCOUNT_FORECAST = gql`
  mutation updateAccountForecast($account: JSON!, $models: JSON!, $notes: JSON, $scenarioId: String) {
    updateAccountForecast(account: $account, models: $models, notes: $notes, scenarioId: $scenarioId)
  }
`;
