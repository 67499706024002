import React from "react";
import PropTypes from "prop-types";

import moment from "moment";

import { Callout, FormGroup, InputGroup, Intent, Classes as BP } from "@blueprintjs/core";
import { DateInput3 as DateInput } from "@blueprintjs/datetime2";

import { createGoogleSheetsUrl } from "./google-sheets-utilities";

import styles from "./ForecastSidebar.module.scss";

import { AppContext } from "../../AppContext";

export default class GoogleSheet extends React.Component {
  static propTypes = {
    changeGoogleSheet: PropTypes.func.isRequired,
    changeModelField: PropTypes.func.isRequired,
    errors: PropTypes.array,
    models: PropTypes.object,
    scenario: PropTypes.string,
    scenarioFormatted: PropTypes.string,
  }

  constructor(props, context) {
    super(props);

    const currentYear = new Date().getFullYear();
    const fromDate = new Date(currentYear - 3, 0);
    const toDate = new Date(currentYear + 3, 11);
    this.state = this.createGoogleSheetsState(props, context);
    this.state.fromDate = fromDate;
    this.state.toDate = toDate;
  }

  static contextType = AppContext;

  createGoogleSheetsState = (props, context) => {
    const state = {};
    const workbookLinks = [];
    const googleSheetsUrl = context.user.tenant?.options?.googleSheetsUrl;
    if (typeof googleSheetsUrl === "string" || !googleSheetsUrl) {
      workbookLinks.push({ name: "Workbook 1", sheet_id: 0, url: googleSheetsUrl });
    } else if (googleSheetsUrl) {
      for (let i = 0; i < googleSheetsUrl.length; i++) {
        const link = googleSheetsUrl[i];
        if (link?.url !== "") {
          // Set starting google sheet
          const name = (link.name) ? link.name : `Workbook ${i + 1}`;
          workbookLinks.push({ name, url: link.url, sheet_id: i });
        };
      }
    }
    state.workbookLinks = workbookLinks;
    // Set new sheet_id
    const model = props.models?.[props.scenarioFormatted]?.google ?? {};
    const workbookLink = workbookLinks.find((link) => (link.sheet_id === model.sheet_id));
    if (!workbookLink && workbookLinks.length > 0) props.changeModelField("google", "sheet_id", "change", workbookLinks[0].sheet_id);
    if (workbookLink?.url && model.workbook_id) {
      state.googleSheetsUrl = createGoogleSheetsUrl(workbookLink.url, model.workbook_id);
    } else state.googleSheetsUrl = (workbookLink?.url) ? workbookLink.url : workbookLinks[0].url;
    props.changeGoogleSheet((state.googleSheetsUrl) ? state.googleSheetsUrl : null);
    return state;
  }

  changeSheetId = ({ target: { value } }) => {
    const selectedId = parseInt(value, 10);
    const workbookLink = this.state.workbookLinks.find((link) => (link.sheet_id === selectedId));
    this.setState({ googleSheetsUrl: workbookLink.url });
    this.props.changeGoogleSheet(workbookLink.url);
    this.props.changeModelField("google", "sheet_id", "change", selectedId);
  }

  render() {
    const { models, scenarioFormatted } = this.props;
    const { fromDate, toDate } = this.state;
    const model = models?.[scenarioFormatted]?.google ?? {};

    return (
      <div className="m-t">
        {!this.state.googleSheetsUrl ? (
          <Callout intent={Intent.PRIMARY}>
            Set the Google Sheets URL in your company customization settings to view your workbook.
          </Callout>
        ) : (
          <div>
            {(this.props.errors?.length) ? (
              <Callout intent={Intent.WARNING} style={{ marginBottom: "1em" }}>
                {this.props.errors.map((e) => `${e} `)}
              </Callout>
            ) : null}
            <div className={`${styles.googleSheetControls}`}>
              <FormGroup
                inline
                label="Start Date"
                labelFor="start-date"
              >
                <DateInput
                  formatDate={(date) => {
                    if (date) return moment(date).format("MM/DD/YYYY");
                    else return date;
                  }}
                  id="start-date"
                  // inputProps={{className: `${styles.googleStartDate}`}}
                  maxDate={toDate}
                  minDate={fromDate}
                  onChange={(value) => this.props.changeModelField("google", "range_start_date", "change", moment(value).format("YYYY-MM-DD"))}
                  parseDate={(str) => moment(str, "MM/DD/YYYY").toDate()}
                  placeholder={"Range Start Date"}
                  showActionsBar
                  // style={{marginRight: "1em"}}
                  value={(model.range_start_date) ? moment(model.range_start_date, "YYYY-MM-DD").format("MM/DD/YYYY") : null}
                />
              </FormGroup>
              <FormGroup
                className="m-t"
                inline
                label="From"
                labelFor="from"
              >
                <InputGroup
                  autoComplete="off"
                  fill
                  id="from"
                  onChange={(event) => this.props.changeModelField("google", "range_start", "event", event)}
                  placeholder="Range Start (D2)"
                  type="text"
                  value={model.range_start}
                />
              </FormGroup>
              <FormGroup
                className="m-t"
                inline
                label="To"
                labelFor="to"
              >
                <InputGroup
                  autoComplete="off"
                  fill
                  id="to"
                  onChange={(event) => this.props.changeModelField("google", "range_end", "event", event)}
                  placeholder="Range End (Q2)"
                  type="text"
                  value={model.range_end}
                />
              </FormGroup>
              <FormGroup
                className="m-t"
                inline
                label="Worksheet"
                labelFor="worksheet"
              >
                <InputGroup
                  autoComplete="off"
                  fill
                  id="worksheet"
                  onChange={(event) => this.props.changeModelField("google", "workbook", "event", event)}
                  placeholder="Worksheet"
                  type="text"
                  value={model.workbook}
                />
              </FormGroup>
              <FormGroup
                className="m-t"
                inline
                label="Workbook"
                labelFor="worksheet"
              >
                <div className={[BP.HTML_SELECT, BP.FILL].join(" ")}>
                  <select
                    onChange={this.changeSheetId}
                    value={model.sheet_id}
                  >
                    {this.state.workbookLinks.map((link, index) => (
                      <option key={index} value={link.sheet_id}>{link.name}</option>
                    ))}
                  </select>
                </div>
              </FormGroup>
            </div>
          </div>
        )}
      </div>
    );
  }
}
