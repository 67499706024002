import {gql} from "@apollo/client";

export const SNAPSHOT_INFOS_QUERY = gql `
  query snapshotInfosQuery($id: String!) {
    snapshot(id: $id) {
      id
      name
      description
      created_date
      forecast_start_date
      forecast_end_date
      infos {
        accounts {
          id
          name
        }
        scenarios {
          id
          name
        }
      }
    }
  }
`;
