import React from "react";
import PropTypes from "prop-types";

import {Button, ButtonGroup, FormGroup, Icon} from "@blueprintjs/core";

import {indentDecrease, indentIncrease, decimalsDecrease, decimalsIncrease, poundSterling} from "./icons.jsx";

class FormattingOptions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    currency: PropTypes.string.isRequired,
    formGroupClass: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string.isRequired,
    value: PropTypes.object,
  }

  onIndentOrDecimalsClick = (type, direction) => () => {
    const {value: formatting} = this.props;

    const value = typeof formatting[type] !== "undefined" ? formatting[type] : 0;
    const newValue = direction === "left" ? value - 1 : value + 1;

    this.sendSyntheticEvent({
      ...formatting,
      [type]: newValue,
    });
  }

  onToggleClick = (name) => () => {
    const {value: formatting} = this.props;

    const patch = {
      [name]: !formatting[name],
    };

    // If percentage is on and we enable dollar, toggle off percentage (or vice versa). We only want max one formatting
    if(["dollar", "percentage"].includes(name)) {
      const otherOptionName = name === "dollar" ? "percentage" : "dollar";
      if(patch[name] === true) patch[otherOptionName] = false;
    }

    this.sendSyntheticEvent(({
      ...formatting,
      ...patch,
    }));
  }

  sendSyntheticEvent = (value) => {
    this.props.onChange({
      target: {
        name: this.props.name || "formatting",
        value,
      },
    });
  }

  render() {
    const {className, currency, formGroupClass, type, value: formatting} = this.props;

    const disableDecimalsDecrease = formatting.percentage && !formatting.decimals;
    const disableDecimalsIncrease = formatting.decimals >= 5;

    const classes = [];
    if(className) classes.push(className);
    if(formGroupClass) classes.push(formGroupClass);

    let currencyIcon = currency;
    if(currency === "poundSterling") currencyIcon = poundSterling();

    return (
      <FormGroup
        className={classes.join(" ")}
        label="Formatting options"
      >
        <ButtonGroup minimal>
          <Button active={formatting.bold} icon="bold" onClick={this.onToggleClick("bold")} />
          <Button active={formatting.percentage} icon="percentage" onClick={this.onToggleClick("percentage")} />
          <Button active={formatting.dollar} icon={currencyIcon} onClick={this.onToggleClick("dollar")} />
          <Button disabled={disableDecimalsDecrease} icon={<Icon icon={decimalsDecrease(disableDecimalsDecrease ? "rgba(92, 112, 128, 0.5)" : "#5c7080", 20)} />} onClick={this.onIndentOrDecimalsClick("decimals", "left")} />
          <Button disabled={disableDecimalsIncrease} icon={<Icon icon={decimalsIncrease(disableDecimalsIncrease ? "rgba(92, 112, 128, 0.5)" : "#5c7080", 20)} />} onClick={this.onIndentOrDecimalsClick("decimals", "right")} />
          {type === "row" ? (
            <>
              <Button disabled={!formatting.indentation} icon={<Icon icon={indentDecrease(!formatting.indentation ? "rgba(92, 112, 128, 0.5)" : "#5c7080", 20)} />} onClick={this.onIndentOrDecimalsClick("indentation", "left")} />
              <Button disabled={formatting.indentation >= 5} icon={<Icon icon={indentIncrease(formatting.indentation >= 5 ? "rgba(92, 112, 128, 0.5)" : "#5c7080", 20)} />} onClick={this.onIndentOrDecimalsClick("indentation", "right")} />
              <Button active={formatting.borderTop} icon="minus" onClick={this.onToggleClick("borderTop")} />
            </>
          ) : (
            <Button
              active={formatting.borderLeft}
              icon="minus"
              onClick={this.onToggleClick("borderLeft")}
              style={{transform: "rotate(90deg)"}}
            />
          )}
        </ButtonGroup>
      </FormGroup>
    );
  }
}

export default FormattingOptions;
