import React from "react";
import PropTypes from "prop-types";

import {useNavigate} from "react-router-dom";

import {Callout, Classes, Intent} from "@blueprintjs/core";

import styles from "./SanityChecks.module.scss";

export default function SanityChecksCyclesTable({errors, scenarios}) {
  const navigate = useNavigate();

  const navigateToWorkstation = () => navigate(`/worksheets`);

  const findScenarioName = (scenarios, scenarioId) => {
    const scenario = scenarios.find((scenario) => scenario.id === scenarioId);
    return scenario.name;
  };

  return (errors && errors.length > 0) ? (
    <table className={[Classes.HTML_TABLE, Classes.INTERACTIVE, styles.sanityChecksTable].join(" ")}>
      <thead>
        <tr>
          <th>Account Name</th>
          <th>Statement</th>
          <th>Scenario</th>
          <th>Formula</th>
        </tr>
      </thead>
      <tbody>
        {errors.map((error, index) => (
          <tr key={index} onClick={navigateToWorkstation}>
            <td>{error.name}</td>
            <td>{error.statement_type}</td>
            <td>{(error.scenario_id) ? findScenarioName(scenarios, error.scenario_id) : "Actuals"}</td>
            <td>{error.formula}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <Callout intent={Intent.SUCCESS}>
      No errors found.
    </Callout>
  );
}

SanityChecksCyclesTable.propTypes = {
  errors: PropTypes.array.isRequired,
  scenarios: PropTypes.array.isRequired,
};
