import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Button from "components/Button";
import {TextArea, Dialog} from "@blueprintjs/core";

import Errors from "components/Errors";

import {ALL_SNAPSHOTS_QUERY, createSnapshot, updateSnapshot} from "./graphql";
import {ALL_SNAPSHOTS_QUERY as TABLE_SNAPSHOTS_QUERY} from "../../dashboard/chart-builder/graphql";

class CompanySettingsSnapshotsCreateUpdate extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    snapshot: PropTypes.object,
  }

  state = {
    modalOpen: true,
    operation: this.props.snapshot ? "update" : "create",
    snapshot: this.props.snapshot || this.getDefaultSnapshotData(),
  }

  getDefaultSnapshotData() {
    return {
      description: "",
      name: "",
    };
  }

  handleSubmit = () => {
    const {operation, snapshot} = this.state;

    const variables = {
      snapshot: {
        name: snapshot.name,
        description: snapshot.description,
      },
    };

    if(operation === "update") variables.snapshot.id = snapshot.id;

    this.props[`${operation}Snapshot`]({
      variables,
      optimisticResponse: {
        __typename: "Mutation",
        createSnapshot: {
          __typename: "Snapshot",
          ...variables.snapshot,
          id: 0,
          created_date: Date.now(),
          archive: false,
          chart_lines: [],
          table_rows: [],
          table_columns: [],
        },
        updateSnapshot: {
          __typename: "Snapshot",
          ...snapshot,
        },
      },
      update: (proxy, {data: {createSnapshot, updateSnapshot}}) => {
        if(createSnapshot) {
          const queryData = proxy.readQuery({query: ALL_SNAPSHOTS_QUERY});
          const data = {
            ...queryData,
            snapshots: [
              createSnapshot,
              ...queryData.snapshots,
            ],
          };
          proxy.writeQuery({query: ALL_SNAPSHOTS_QUERY, data});
        } else {
          const queryData = proxy.readQuery({query: ALL_SNAPSHOTS_QUERY});
          const index = queryData.snapshots.findIndex((snapshot) => updateSnapshot.id === snapshot.id);
          const data = {
            ...queryData,
            snapshots: [...queryData.snapshots],
          };
          data.snapshots[index] = updateSnapshot;
          proxy.writeQuery({query: ALL_SNAPSHOTS_QUERY, data});
        }
      },
      refetchQueries: [{query: TABLE_SNAPSHOTS_QUERY}],
    });

    this.onClose();
  }

  handleFieldChange = (evt) => {
    this.setState({
      snapshot: {
        ...this.state.snapshot,
        [evt.target.name]: evt.target.value,
      },
    });
  }

  onClose = () => {
    const {onClose} = this.props;
    this.setState({
      modalOpen: false,
    });
    setTimeout(onClose, 300); // Blueprint dialog transition duration seems to be 300ms
  }

  render() {
    const {modalOpen, operation, snapshot} = this.state;

    const title = (operation === "create") ? "Create Snapshot" : "Update Snapshot";
    const icon = (operation === "create") ? "add" : "annotation";
    return (
      <Dialog
        canOutsideClickClose
        className="bp5-large"
        icon={icon}
        inline
        isOpen={modalOpen}
        onClose={this.onClose}
        title={title}
      >
        <div className="bp5-dialog-body">
          <Errors messages={this.state.errors} />
          <div className="bp5-form-group">
            <h4 className="bp5-heading">Name <span className="bp5-text-muted">(required)</span></h4>
            <input
              autoComplete="off"
              className="bp5-input bp5-large"
              name="name"
              onChange={this.handleFieldChange}
              placeholder="Snapshot Name"
              type="text"
              value={snapshot.name}
            />
          </div>
          <div className="bp5-form-group m-t">
            <h4 className="bp5-heading">Description</h4>
            <TextArea
              large
              name="description"
              onChange={this.handleFieldChange}
              placeholder="Snapshot Description"
              style={{height: "100px"}}
              value={snapshot.description}
            />
          </div>
        </div>
        <div className="bp5-dialog-footer">
          <div className="bp5-dialog-footer-actions">
            <Button onClick={this.onClose} text="Cancel" />
            <Button
              disabled={snapshot.name.length < 1}
              intent="success"
              onClick={this.handleSubmit}
              text="Save"
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default compose(
  graphql(createSnapshot, {name: "createSnapshot"}),
  graphql(updateSnapshot, {name: "updateSnapshot"}),
)(CompanySettingsSnapshotsCreateUpdate);
