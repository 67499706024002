import {gql} from "@apollo/client";

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee($employee: JSON!, $payrollModels: JSON!) {
    createEmployee(employee: $employee, payrollModels: $payrollModels) {
      id
    }
  }
`;

export const DUPLICATE_EMPLOYEE = gql`
  mutation duplicateEmployee($id: String!) {
    duplicateEmployee(id: $id) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($employee: JSON!, $payrollModels: JSON!) {
    updateEmployee(employee: $employee, payrollModels: $payrollModels) {
      id
    }
  }
`;

export const ARCHIVE_EMPLOYEE = gql`
  mutation archiveEmployee($id: String!) {
    archiveEmployee(id: $id) {
      id
    }
  }
`;
export const UNARCHIVE_EMPLOYEE = gql`
  mutation unarchiveEmployee($id: String!) {
    unarchiveEmployee(id: $id) {
      id
    }
  }
`;

export const REMOVE_EMPLOYEE = gql`
  mutation removeEmployee($id: String!) {
    removeEmployee(id: $id)
  }
`;

export const ALL_EMPLOYEES_QUERY = gql`
  query AllEmployeesQuery {
    employees {
      id
      name
      title
      archived
      team
      payroll_models
    }
  }
`;

export const ARCHIVED_EMPLOYEES_QUERY = gql`
  query ArchivedEmployeesQuery {
    employees(archived: true) {
      id
      name
      title
      archived
      team
      payroll_models
    }
  }
`;

export const ALL_TEAMS_QUERY = gql`
  query AllTeamsQuery {
    teams {
      id
      name
      wages_account {
        id
        name
      }
      benefits_account {
        id
        name
      }
      payroll_taxes_account {
        id
        name
      }
      benefits
      benefits_period
      payroll_tax
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateDepartmnet($id: String!, $update: JSON!) {
    updateTeam(id: $id, update: $update) {
      name
    }
  }
`;

export const REMOVE_TEAM = gql`
  mutation removeTeam($id: String!) {
    removeTeam(id: $id)
  }
`;

export const CREATE_TEAM = gql`
  mutation createTeam($team: JSON!) {
    createTeam(team: $team) {
      name
    }
  }
`;

export const ALL_TAX_RATES_QUERY = gql`
  query AllTaxRatesQuery {
    taxRates {
      id
      name
      value
    }
  }
`;

export const CREATE_TAX_RATE = gql`
  mutation createTaxRate($taxRate: JSON!) {
    createTaxRate(taxRate: $taxRate) {
      name
      value
    }
  }
`;

export const UPDATE_TAX_RATE = gql`
  mutation updateTaxRate($taxRate: JSON!) {
    updateTaxRate(taxRate: $taxRate) {
      name
      value
    }
  }
`;

export const REMOVE_TAX_RATE = gql`
  mutation removeTaxRate($id: String!) {
    removeTaxRate(id: $id)
  }
`;
