import { useEffect, useState } from "react";
import { useNavigate, redirect } from "react-router-dom";

import "./AppSumo.scss";

import Errors from "components/Errors";

import {
  Button,
  Card,
  FormGroup,
  InputGroup,
  H2,
} from "@blueprintjs/core";

import { useQuery, useMutation, gql } from "@apollo/client";
import { CURRENT_USER_QUERY } from "../../auth/graphql";

const SIGN_UP = gql`
mutation publicSignup($user: JSON!) {
  appSumoSignup(user: $user) {
    id
  }
}
`

const AppSumoLanding = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [signup, { loading: signupLoading, data: signupData, error: signupError }] = useMutation(SIGN_UP);

  const { loading: currentUserLoading, data: { currentUser } = {} } = useQuery(CURRENT_USER_QUERY);

  const inputsValid = () => (
    name.length && email.length && password.length && company.length
  );

  const handleSignup = () => {
    if (inputsValid()) {
      signup({
        variables: {
          user: {
            name,
            email,
            password,
            company,
            licenseKey: currentUser?.appsumo?.license_key
          }
        }
      });
    }
  };

  useEffect(() => {
    if (signupData) {
      fetch("/api/auth/local", {
        method: "POST",
        body: JSON.stringify({
          username: email,
          password: password,
        }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      }).then(async (response) => {
        const data = await response.json();
        if (data.error) {
          console.error(data.error);
        }
        window.location = "/";
      });
    }
  }, [signupData]);

  if (!currentUser) { return null; }

  if (currentUser?.appsumo?.setup_complete) {
    window.location = "/";
    return null;
  }

  return (
    <div className="mainContainer">
      <Card className="card">
        <H2>
          Hey, Sumo-ling!
        </H2>
        {
          signupError
            ? <Errors
              messages={
                signupError.message.split("\n")
              }
            />
            : null
        }
        <>
          <FormGroup
            label="Name"
            labelFor="name"
            helperText={name.length ? null : "Name is required."}
            intent={!name.length ? "danger" : null}
          >
            <InputGroup
              id="name"
              placeholder="Your Name"
              value={name}
              onValueChange={setName}
            />
          </FormGroup>

          <FormGroup
            label="Subdomain"
            labelFor="company"
            subLabel={`eg. "AppSumo" -> "appsumo.flightpathfinance.com"`}
            helperText={
              company.length
                ? null
                : "Company is required."
            }
            intent={!company.length ? "danger" : null}
          >
            <InputGroup
              id="company"
              placeholder="Your Company"
              value={company}
              onValueChange={setCompany}
            />
          </FormGroup>

          <FormGroup
            label="Email"
            labelFor="email"
            helperText={email.length ? null : "Email is required."}
            intent={!email.length ? "danger" : null}
          >
            <InputGroup
              id="email"
              placeholder="your.email@domain.com"
              value={email}
              onValueChange={setEmail}
            />
          </FormGroup>

          <FormGroup
            label="Password"
            labelFor="password"
            helperText={password.length ? null : "Password is required."}
            intent={!password.length ? "danger" : null}
          >
            <InputGroup
              id="password"
              placeholder="Password"
              value={password}
              type="password"
              onValueChange={setPassword}
            />
          </FormGroup>
          <Button text="Let's Go!" disabled={!inputsValid()} onClick={handleSignup} loading={signupLoading} />
        </>
      </Card>
    </div >
  );
};

export default AppSumoLanding;
