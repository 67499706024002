import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Errors from "components/Errors";
import Loading from "components/Loading";
import Confirmation from "components/Confirmation";
import TypedConfirmation from "components/TypedConfirmation";
import ErrorMessage from "components/ErrorMessage";
import SettingsHeader from "components/SettingsHeader";
import Card from "components/Card";
import Button from "components/Button";

import CompanySettingsScenariosCreateUpdate from "./CreateUpdate";
import {evictAllDashboardsFromCache} from "shared/graphql/apolloCacheEviction";

import {copyScenario, createScenario, removeScenario, updateScenario, ALL_SCENARIOS_QUERY} from "./graphql";
import {ALL_EMPLOYEES_QUERY} from "../payroll/graphql";
import {ALL_SCENARIOS_QUERY as APP_SCENARIOS_QUERY} from "../../../graphql";

import styles from "./styles.module.scss";

class CompanySettingsScenarios extends React.Component {
  static propTypes = {
    allScenariosQuery: PropTypes.object.isRequired,
    copyScenario: PropTypes.func.isRequired,
    createScenario: PropTypes.func.isRequired,
    removeScenario: PropTypes.func.isRequired,
    updateScenario: PropTypes.func.isRequired,
  }

  state = {
    deleting: false,
    scenario: null,
    operation: "create",
    deleteModalOpen: false,
    copyModalOpen: false,
    operationActive: false,
  };

  editScenario = (scenarioToEdit) => () => {
    const scenario = (scenarioToEdit) ? scenarioToEdit : {};
    const operation = (scenarioToEdit) ? "update" : "create";
    this.setState({scenario, operation});
  }

  removeScenario = (scenarioToRemove) => () => {
    this.setState({scenario: scenarioToRemove, operation: "delete", deleteModalOpen: true});
  }

  removeScenarioOperation = () => {
    this.setState({deleting: true});
    this.props.removeScenario({
      variables: {id: this.state.scenario.id},
      refetchQueries: [
        {query: ALL_SCENARIOS_QUERY},
        {query: APP_SCENARIOS_QUERY},
        {query: ALL_EMPLOYEES_QUERY},
      ],
      update: evictAllDashboardsFromCache,
    }).then(() => {
      this.setState({deleting: false});
      this.onClose();
    }).catch((error) => {
      this.setState({deleting: false});
      const errorMsg = error.message.replace("GraphQL error: ", "");
      // TODO: have a resolver on the frontend too to map error names to relevant messages for the user
      const msg = ErrorMessage.resolve(errorMsg === "REMOVE_SCENARIO_MODELS_CONFLICT" ? errorMsg : "REMOVE_SCENARIO_UNKNOWN_ERROR");
      this.setState({scenario: null, errors: [msg]});
      // eslint-disable-next-line
      console.log("There was an error sending the query", error);
    });
  }

  onClose = () => {
    this.setState({scenario: null, operation: "create", deleteModalOpen: false, copyModalOpen: false, errors: []});
  }

  toggleDeleteModal = () => {
    this.setState({deleteModalOpen: !this.state.deleteModalOpen});
  }

  toggleCopyModal = () => {
    this.setState({copyModalOpen: !this.state.copyModalOpen});
  }

  copyScenario = (scenarioToCopy) => () => {
    this.setState({scenario: scenarioToCopy, operation: "copy", copyModalOpen: true});
  }

  toggleOperationActive = () => {
    this.setState({operationActive: !this.state.operationActive});
  }

  copyScenarioOperation = () => {
    this.toggleOperationActive();
    return this.props.copyScenario({
      variables: {id: this.state.scenario.id, name: this.state.scenario.name},
      refetchQueries: [
        {query: ALL_SCENARIOS_QUERY},
        {query: APP_SCENARIOS_QUERY},
        {query: ALL_EMPLOYEES_QUERY},
      ],
    })
    .then(() => {
      this.toggleOperationActive();
      this.onClose();
    }).catch((error) => {
      const errorMsg = error.message.replace("GraphQL error: ", "");
      this.setState({scenario: null, errors: [errorMsg]});
      // eslint-disable-next-line
      console.log("There was an error sending the query", error);
      this.toggleOperationActive();
    });
  }

  render() {
    if(this.props.allScenariosQuery?.loading) {
      return <Loading />;
    }

    const scenarios = this.props.allScenariosQuery.scenariosWithChartLinesAndTables || [];
    return (
      <>
        <Errors messages={this.state.errors}/>
        <Card>
          <SettingsHeader
            buttonAction={this.editScenario(null)}
            buttonText="New"
            subtitle
            text="Scenarios"
          />
          <table className={styles.table}>
            <tbody>
              {(scenarios.length === 0) ? (
                <tr>
                  <td colSpan="2">No scenarios to show.</td>
                </tr>
              ) : scenarios.map((scenario) => (
                <tr key={scenario.id}>
                  <td>{scenario.name}</td>
                  <td className={styles.buttons}>
                    <Button
                      icon="edit"
                      onClick={this.editScenario(scenario)}
                      size="small"
                    >
                      Edit
                    </Button>
                    <Button
                      icon="duplicate"
                      onClick={this.copyScenario(scenario)}
                      size="small"
                    >
                      Copy
                    </Button>
                    <Button
                      icon="delete"
                      intent="danger"
                      onClick={this.removeScenario(scenario)}
                      size="small"
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        <Confirmation
          isOpen={this.state.copyModalOpen}
          onCancel={this.onClose}
          onConfirm={this.copyScenarioOperation}
          operationActive={this.state.operationActive}
        >
          <p>
            Do you want to create a duplicate scenario of <strong>{(this.state.scenario?.name) ? this.state.scenario.name : "" }</strong>?
            All models and forecasts associated with this scenario will be copied over.
          </p>
        </Confirmation>
        <TypedConfirmation
          deleting={this.state.deleting}
          isOpen={this.state.deleteModalOpen}
          onCancel={this.onClose}
          onConfirm={this.removeScenarioOperation}
        >
          <p>
            Are you sure that you want to remove the scenario <strong>{(this.state.scenario?.name) ? this.state.scenario.name : "" }</strong>?
            All models and forecasts associated with this scenario will be removed as well.
          </p>
          {this.state.scenario?.chart_lines?.length ? (
            <span>
              Keep in mind that this scenario is used in the following chart{this.state.scenario.chart_lines.length > 1 ? "s" : null}:&nbsp;
              <strong>{this.state.scenario.chart_lines.map((line) => line.chart.name).join(", ")}</strong>. Data referencing this scenario will be permanently deleted from {this.state.scenario.chart_lines.length > 1 ? "these" : "this"} chart{this.state.scenario.chart_lines.length > 1 ? "s" : null}.
              <br />
            </span>
          ) : null}
          <p>
            Please type "delete" in the input field then click <strong>Delete</strong> below if you are sure
            that you would like this scenario removed.
          </p>
        </TypedConfirmation>
        <CompanySettingsScenariosCreateUpdate
          onClose={this.onClose}
          onCreate={this.props.createScenario}
          onUpdate={this.props.updateScenario}
          operation={this.state.operation}
          scenario={this.state.scenario}
        />
      </>
    );
  }
}

const withData = compose(
  graphql(ALL_SCENARIOS_QUERY, {name: "allScenariosQuery"}),
  graphql(updateScenario, {name: "updateScenario"}),
  graphql(createScenario, {name: "createScenario"}),
  graphql(removeScenario, {name: "removeScenario"}),
  graphql(copyScenario, {name: "copyScenario"}),
)(CompanySettingsScenarios);

export default withData;
