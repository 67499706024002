import React from "react";
import PropTypes from "prop-types";
import { Alignment, Navbar, Button, Text, Icon, Classes as BP } from "@blueprintjs/core";
import { NavLink } from "react-router-dom";
import Client from "../Client";
import "../styles/TopBar.css";

class TopBar extends React.Component {
	static propTypes = {
		isLoggedOut: PropTypes.func,
		user: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.logout = this.logout.bind(this);
	}

	logout() {
		const { isLoggedOut } = this.props;
		Client.logout(() => {
			isLoggedOut();
		});
	}

	render() {
		const { user } = this.props;
		return (
			<Navbar className="topbar" fixedToTop>
				<Navbar.Group align={Alignment.LEFT}>
					<Navbar.Heading>
						<NavLink
							className={({ isActive }) => ["client-name", isActive ? BP.ACTIVE : ""].join(" ")}
							to="/"
						>
							Flightpath Finance
						</NavLink>
					</Navbar.Heading>
				</Navbar.Group>
				{user ? (
					<div>
						<Navbar.Group align={Alignment.RIGHT}>
							<div className="info-group">
								<Icon icon="user" />
								{user.name} ({user.email})
							</div>
							<Navbar.Divider />
							<Button icon="log-out" onClick={this.logout} intent="danger">
								Log Out
							</Button>
						</Navbar.Group>
					</div>
				) : (
					<Navbar.Group align={Alignment.RIGHT} />
				)}
			</Navbar>
		);
	}
}

export default TopBar;
