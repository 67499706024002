import React from "react";
import PropTypes from "prop-types";

import {FormGroup} from "@blueprintjs/core";
import memoize from "memoize-one";
import FancySelect from "components/FancySelect";

import {orderTopLevelAccounts} from "shared/utilities/account-utilities";

import {getPlanGroupName} from "views/revenue-model/utilities";

class FormFieldAccount extends React.Component {
  static propTypes = {
    accounts: PropTypes.array.isRequired,
    fill: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    planGroups: PropTypes.array,
    products: PropTypes.array,
    selectedAccountId: PropTypes.string,
    teams: PropTypes.array,
    topLevelAccounts: PropTypes.array,
  }

  onChangeLocal = (data) => {
    const {onChange, name} = this.props;

    // Add synthetic target info so standard event handlers can process this event
    data.target = {
      name: name || "account_id",
      value: data.key,
      text: data.text,
      label: data.label,
    };

    onChange(data);
  }

  filterAccount = (query, account) => `${account.name.toLowerCase()} ${account.statement_type}`.indexOf(query.toLowerCase()) >= 0;

  findAllChildren = (accounts, account, level = 0) => {
    const {planGroups, products, teams} = this.props;
    let label = account.statement_type === "MET" ? "WORK" : account.statement_type;
    let text = account.name;

    if(account.statement_type === "REV") {
      if(planGroups && products && !planGroups.length && !products.length) return [];
      if(text === "ARPC") text = account.type;

      // Label for revenue model accounts
      if(planGroups && products && (account.product_id || account.plan_group_id)) {
        if(account.plan_group_id && planGroups?.length) {
          const planGroup = planGroups.find((planGroup) => planGroup.id === account.plan_group_id);
          if(planGroup) label = getPlanGroupName(planGroup);
        } else if(products?.length) {
          const product = products.find((product) => product.id === account.product_id);
          if(product) label = product.name;
        }
      }
    }

    if(teams?.length && account.statement_type === "MET" && ["Employees", "New Employees"].includes(account.type)) {
      label = account.team_id ? teams.find((team) => team.id === account.team_id)?.name : "All Teams";
    }

    let orderedAccounts = [{
      key: account.id,
      text,
      label,
      level,
    }];
    if(account.children) {
      for(const child of account.children) {
        const foundAccount = accounts.find((a) => a.id === child.id);
        if(foundAccount) {
          orderedAccounts = orderedAccounts.concat(this.findAllChildren(accounts, foundAccount, level + 1));
        }
      }
    }
    return orderedAccounts;
  }

  orderAccounts = (accounts) => {
    const orderedTopLevelAccounts = this.props.topLevelAccounts?.length ? this.props.topLevelAccounts : orderTopLevelAccounts(accounts);
    const orderedAccounts = [];
    for(const account of orderedTopLevelAccounts) {
      orderedAccounts.push(...this.findAllChildren(accounts, account));
    }
    return orderedAccounts;
  }

  orderAccountsMemo = memoize(
    (accounts, teams, plan_groups, products) => this.orderAccounts(accounts, teams, plan_groups, products),
  );

  render() {
    const {accounts, fill = true, label, selectedAccountId, teams, planGroups, products} = this.props;

    // const selectedAccount = accounts.find((account) => account.id === selectedAccountId) || accounts[0];

    const orderedAccounts = this.orderAccountsMemo(accounts, teams, planGroups, products);
    const actualSelect = (
      <FancySelect
        fill={fill}
        items={orderedAccounts}
        onSelect={this.onChangeLocal}
        value={selectedAccountId}
      />
    );

    return (
      <>
        {label ? (
          <FormGroup label={label}>
            {actualSelect}
          </FormGroup>
        ) : actualSelect}
      </>
    );
  }
}

export default FormFieldAccount;

