import {gql} from "@apollo/client";

export const DISCONNECT_COMPANY = gql`
  mutation disconnectCompany($id: String!) {
    disconnectCompany(id: $id) {
      id
      confirmed
      connected
      enabled
    }
  }
`;

export const REFRESH_GOOGLE_SHEETS_MODELS = gql`
  mutation refreshGoogleSheetsModels {
    refreshGoogleSheetsModels
  }
`;

export const UPDATE_ACCOUNTING = gql`
  mutation updateAccounting {
    updateAccounting
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($id: String!, $confirm: Boolean, $transferAccounts: Boolean, $accountingMethod: String) {
    updateCompany(id: $id, confirm: $confirm, transferAccounts: $transferAccounts, accountingMethod: $accountingMethod) {
      id
      provider
      name
      confirmed
      connected
      enabled
      accounting_method
    }
  }
`;

export const REMOVE_COMPANY = gql`
  mutation removeCompany($id: String!) {
    removeCompany(id: $id)
  }
`;

export const UPDATE_CSV_COMPANY = gql`
  mutation updateCSVCompany($id: String, $csv: JSON!, $transferAccounts: Boolean) {
    updateCSVCompany(id: $id, csv: $csv, transferAccounts: $transferAccounts) {
      id
      provider
      name
      confirmed
      connected
      enabled
      accounting_method
    }
  }
`;
