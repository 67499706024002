import { useState, useEffect, useContext } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import PropTypes from "prop-types";

import { useQuery, useMutation } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import compose from "lodash.flowright";

import { cloneDeep, pick } from "lodash";

import { client } from "apollo-config";

import Button from "components/Button";
import Errors from "components/Errors";
import { Classes, Dialog, Spinner } from "@blueprintjs/core";

import ChartLines from "./ChartLines";
import FormFieldName from "../shared/fields/name";
import FormFieldChartType, { chartTypes } from "./fields/chartType";
import FormFieldReportPeriod, { reportPeriods } from "../shared/fields/reportPeriod";
import FormFieldDateRange from "../shared/fields/dateRange";
import FormFieldStripedForecast from "./fields/forecastFormatting";
import FormFieldYAxisType from "./fields/yAxisType";
import FormFieldYAxisRange from "./fields/yAxisRange";
import FormFieldYAxisUnits from "./fields/yAxisUnits";
import FormFieldDisplayColumn, { columnTypes } from "../shared/fields/displayColumns";
import FormFieldPrecision from "./fields/precision";

import { createInitialDateRange } from "shared/utilities/date-utilities";
import { AppContext } from "../../../AppContext";
import { cleanChartData } from "./chart-utilities";

import { withHooks } from "shared/hooks/hoc";

import { ALL_SNAPSHOTS_QUERY, CHART_QUERY, CREATE_CHART_MUTATION, UPDATE_CHART_MUTATION } from "./graphql";
import { ALL_COLORS_QUERY } from "views/company-settings/customization/colors/graphql";
import { DASHBOARD_QUERY } from "../grid/graphql";
import { ALL_SCENARIOS_QUERY } from "../../../graphql";
import { ALL_PLAN_GROUPS_QUERY } from "views/revenue-model/forecast/graphql";
import { ALL_PRODUCTS_QUERY } from "views/revenue-model/chart-of-plans/graphql";
import { ALL_TEAMS_QUERY } from "views/company-settings/payroll/graphql";

import styles from "./ChartCreationModal.module.scss";

const buttonLabelMapping = {
  LINE: "Add a Line",
  BAR: "Add a Bar",
  AREA: "Add an Area",
  COMBO: "Add a Bar / Line",
  PIE: "Add a Slice",
  AREARANGE: "Add a Line",
};

const getDefaultFormData = (context) => {
  return {
    column_type: columnTypes[0].value,
    name: "",
    type: chartTypes[0].value,
    report_period: reportPeriods[0].value,
    daterange: { dates: createInitialDateRange(context.forecastStartDate, reportPeriods[3].value), monthsAgo: 3, monthsAhead: 12 },
    lines: [],
    options: {
      forecastFormatting: "SOLID",
    },
  };
}

const ChartCreationModal = (props) => {
  const context = useContext(AppContext);
  const params = useParams();
  const { dashboardId, editedChart, onClose, forecastStartDate } = useOutletContext();

  const isCreate = !Object.hasOwn(params, "id");

  const [currentlyEditing, setCurrentlyEditing] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState(getDefaultFormData(context));
  const [saving, setSaving] = useState(false);

  const [createChart] = useMutation(CREATE_CHART_MUTATION);
  const [updateChart] = useMutation(UPDATE_CHART_MUTATION);

  const allColorsQuery = useQuery(ALL_COLORS_QUERY);
  const allPlanGroupsQuery = useQuery(ALL_PLAN_GROUPS_QUERY);
  const allProductsQuery = useQuery(ALL_PRODUCTS_QUERY);
  const allScenariosQuery = useQuery(ALL_SCENARIOS_QUERY);
  const allTeamsQuery = useQuery(ALL_TEAMS_QUERY);
  const chartQuery = useQuery(CHART_QUERY, {
    variables: {
      id: params.id
    },
    skip: editedChart || isCreate,
  });

  useEffect(() => {
    if (!currentlyEditing && (editedChart || (chartQuery.data?.chart))) {
      const passedChart = editedChart || chartQuery.data?.chart;
      let chart;

      if (passedChart.daterange?.dates?.start?.year) {
        chart = cloneDeep(passedChart);
      } else {
        chart = {
          ...cloneDeep(passedChart),
          daterange: {
            dates: createInitialDateRange(forecastStartDate, "THIS_YEAR"),
            monthsAgo: 3,
            monthsAhead: 3
          },
        }
      }

      setFormData(chart);
      setCurrentlyEditing(true);
    }
  }, [chartQuery.loading]);

  const { allFlightpathAccounts } = props;
  const { years } = context;

  const title = isCreate ? "Create chart" : "Edit chart";
  const icon = isCreate ? "add" : "annotation";

  const customColors = allColorsQuery?.data?.colors ? allColorsQuery.data.colors : [];
  const accounts = allProductsQuery?.products?.length ? allFlightpathAccounts : allFlightpathAccounts?.filter((account) => account.statement_type !== "REV") ?? [];

  const changeFormField = (evt) => {
    const newState = {
      formData: {
        ...formData,
        [evt.target.name]: evt.target.value,
      },
    };

    // Change date range if report period changes or if column type changes
    if ((evt.target.name === "report_period" && evt.target.value !== "CUSTOM") || evt.target.name === "column_type") {
      const latestReportPeriod = (evt.target.name === "report_period") ? evt.target.value : newState.formData["report_period"];
      newState.formData["daterange"] = {
        ...newState.formData["daterange"],
        dates: createInitialDateRange(
          forecastStartDate,
          latestReportPeriod,
          newState.formData["daterange"].monthsAgo,
          newState.formData["daterange"].monthsAhead,
        ),
      };

      // Change column type if report period is LAST_MONTH or MONTHS_FORECAST
      if (evt.target.name === "report_period" && (evt.target.value === "LAST_MONTH" || evt.target.value === "MONTHS_FORECAST")) {
        newState.formData["column_type"] = "MONTHS";
      }
    }

    const errorMatchingName = errors.find((error) => error.name === evt.target.name);
    if (errorMatchingName) {
      // TODO: maybe keep other errors here if there are multiple errors being displayed?
      newState.errors = [];
      setErrors(newState.errors);
    }

    setFormData(newState.formData);
  }

  const changeDateRange = (daterange) => {
    setFormData({
      ...formData,
      daterange
    });
  }

  const getDefaultLineData = () => {
    const { data: { scenarios } = {} } = allScenariosQuery;
    const revenueAccount = allFlightpathAccounts.find((account) => account.id === "1") || allFlightpathAccounts[0];

    return {
      name: revenueAccount.name,
      snapshot: "",
      color_id: null,
      account_id: revenueAccount.id,
      scenario_id: scenarios[0].id,
      reference_snapshot_id: null,
    };
  }

  const addLine = () => {
    const { lines } = formData;

    const linesClone = Array.from(lines || []);
    const lineIndex = (lines || []).length;
    linesClone.push(getDefaultLineData(lineIndex));

    updateLines(linesClone);
  }

  const updateLines = (lines) => {
    const newState = {
      formData: {
        ...formData,
        lines,
      },
    };

    if (errors.find((error) => error.name === "lines")) {
      // TODO: maybe keep other errors here if there are multiple errors being displayed?
      setErrors([])
    }

    for (const line of newState.formData.lines) {
      if (newState.formData.type === "COMBO" && line.type === null) {
        line.type = "LINE";
      }
    }

    setFormData(newState.formData);
  }

  const saveChart = () => {
    if (validateData()) {
      const chart = cleanChartData(formData);
      setSaving(true);

      const opCb = isCreate ? createChart : updateChart;
      opCb({
        variables: { chart, dashboardId },
        refetchQueries: ["dashboardQuery"],
        onCompleted: (data, opts) => {
          onClose();
        },
      });
    }
  }

  const validateData = () => {
    let newErrors = [...errors];
    let foundError = false;

    if (!formData.name.length && !errors.find((error) => error.name === "name")) {
      foundError = true;
      newErrors.push({
        name: "name",
        message: "Chart name must not be empty",
      });
    }

    if (!formData.lines.length && !errors.find((error) => error.name === "lines")) {
      foundError = true;
      newErrors.push({
        name: "lines",
        message: "You must add at least one line to your chart",
      });
    }

    if (formData.options.yAxisType === "CUSTOM") {
      if (Number.isNaN(Number(formData.options.yAxisMin))) {
        foundError = true;
        newErrors.push({
          name: "options",
          message: "Minimum value must be a number",
        });
      } else if (formData.options.yAxisMin < -100000000) {
        foundError = true;
        newErrors.push({
          name: "options",
          message: "Minimum value cannot be below negative 100 million",
        });
      }
      if (Number.isNaN(Number(formData.options.yAxisMax))) {
        foundError = true;
        newErrors.push({
          name: "options",
          message: "Maximum value must be a number",
        });
      } else if (formData.options.yAxisMax > 100000000) {
        foundError = true;
        newErrors.push({
          name: "options",
          message: "Maximum value cannot be above 100 million",
        });
      }
      if (Number.isNaN(Number(formData.options.yAxisInterval))) {
        foundError = true;
        newErrors.push({
          name: "options",
          message: "Interval must be a number",
        });
      } else if (formData.options.yAxisInterval < 0) {
        foundError = true;
        newErrors.push({
          name: "options",
          message: "Interval cannot be negative",
        });
      }
    }

    if (formData.options.precision !== undefined) {
      const precision = parseInt(formData.options.precision);

      if (Number.isNaN(precision)) {
        foundError = true;
        newErrors.push({
          name: "precision",
          message: "Precision must be a number",
        });
      } else if (precision < 0) {
        foundError = true;
        newErrors.push({
          name: "precision",
          message: "Precision cannot be negative",
        });
      } else if (precision > 5) {
        foundError = true;
        newErrors.push({
          name: "precision",
          message: "Precision cannot exceed 5 decimal places",
        });
      }
    }

    if (foundError) {
      setErrors(newErrors)
    }

    return !foundError;
  }

  const getCleanFormData = () => pick(formData, [
    "id",
    "account_id",
    "scenario_id",
    "reference_snapshot_id",
    "name",
  ]);

  return (
    <Dialog
      canOutsideClickClose={false}
      className={styles.chartCreationModal}
      icon={icon}
      inline
      onClose={onClose}
      isOpen={true}
      title={title}
    >
      {(
        (chartQuery?.loading) ||
        (allPlanGroupsQuery?.loading) ||
        (allProductsQuery?.loading) ||
        (allScenariosQuery?.loading)
      ) ? (
        <Spinner />
      ) : (
        <>
          <div className={[Classes.DIALOG_BODY, styles.chartCreationForm].join(" ")}>
            <Errors messages={errors.map((error) => error.message)} />
            <form autoComplete="off" onSubmit={(e) => { e.preventDefault() }}>
              <div className={styles.formRow}>
                <FormFieldName
                  label="Chart Name"
                  onChange={changeFormField}
                  value={formData.name}
                />
                <FormFieldChartType
                  onChange={changeFormField}
                  options={formData.options}
                  value={formData.type}
                />
              </div>
              <div className={styles.formRow}>
                <FormFieldReportPeriod onChange={changeFormField} value={formData.report_period} />
                <FormFieldDateRange
                  changeDateRange={changeDateRange}
                  column_type={formData.column_type}
                  daterange={formData.daterange}
                  onChange={changeFormField}
                  report_period={formData.report_period}
                  years={years}
                />
              </div>
              <div className={styles.formRow}>
                <FormFieldDisplayColumn onChange={changeFormField} report_period={formData.report_period} value={formData.column_type} />
                {["COMBO", "BAR"].includes(formData.type) ? <FormFieldStripedForecast onChange={changeFormField} value={formData.options} /> : null}
              </div>
              {formData.type !== "PIE" ? (
                <div className={styles.formRow}>
                  <FormFieldYAxisType onChange={changeFormField} value={formData.options} />
                  {formData.options?.yAxisType === "CUSTOM" ? <FormFieldYAxisRange onChange={changeFormField} value={formData.options} /> : null}
                </div>
              ) : null}
              <div className={styles.formRow}>
                <FormFieldYAxisUnits onChange={changeFormField} value={formData.options} />
              </div>
              <div className={styles.formRow}>
                <FormFieldPrecision onChange={changeFormField} value={formData.options} />
              </div>
              <Query query={ALL_SNAPSHOTS_QUERY}>
                {({
                  data,
                  loading,
                }) => loading ? (
                  <Spinner />
                ) : (
                    <ChartLines
                      accounts={accounts}
                      customColors={customColors}
                      lines={formData.lines || []}
                      planGroups={allPlanGroupsQuery.data.plan_groups}
                      products={allProductsQuery.data.products}
                      scenarios={allScenariosQuery.data.scenarios}
                      snapshots={data.snapshotsWithAccountsAndScenarios || []}
                      teams={allTeamsQuery.data.teams}
                      type={formData.type}
                      updateLines={updateLines}
                    />
                  )}
              </Query>
            </form>
          </div>
          <div className={[Classes.DIALOG_FOOTER, styles.footer].join(" ")}>
            <div className={styles.actionsLeft}>
              <Button
                disabled={(formData.lines || []).length >= 20}
                icon="symbol-cross"
                intent="success"
                onClick={addLine}
              >
                {buttonLabelMapping[formData.type]}
              </Button>
              {(formData.lines || []).length >= 20 ? <span className={styles.linesLimitReached}>Number of lines limit reached (20)</span> : null}
            </div>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                disabled={saving}
                onClick={onClose}
                text="Cancel"
              />
              <Button
                intent="success"
                loading={saving}
                onClick={saveChart}
                text="Save"
              />
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
};

export default withHooks(ChartCreationModal, ["useAllFlightpathAccounts"]);
