import getUniqueId from "shared/unique-id";
import { getSlug, AppliesToOptions } from "./Sidebar/utilities";
import { getNewAccount, transformWorksheetOnLoad, arrayMove, findInvalidModels, ModelPeriod } from "./utilities";

export const actionTypes = {
  addRow: "ADD_ROW",
  autocompleteHighlight: "AUTOCOMPLETE_HIGHLIGHT",
  changeScenarioId: "CHANGE_SCENARIO_ID",
  changeYear: "CHANGE_YEAR",
  closeSidebar: "CLOSE_SIDEBAR",
  deleteRow: "DELETE_ROW",
  moveRow: "MOVE_ROW",
  cellClick: "CELL_CLICK",
  updateRow: "UPDATE_ROW",
  updateProperties: "UPDATE_PROPERTIES",
  updateWorksheet: "UPDATE_WORKSHEET",
  hideSheetsFrame: "HIDE_SHEETS_FRAME",
  showSheetsFrame: "SHOW_SHEETS_FRAME",
  updateSheetsUrl: "UPDATE_SHEETS_URL",
};

export function initialState({
  scenarioId = null,
  sheetsFrameVisible = false,
  worksheet = null,
  year = new Date().getFullYear().toString(),
}) {
  return {
    actualsClicked: false,
    autocompleteHighlight: null,
    editingRow: null,
    invalidModels: worksheet ? findInvalidModels(worksheet) : false,
    scenarioId,
    year,
    sheetsFrameVisible, // Show Google Sheets IFrame?
    sheetsUrl: "", // Google Sheets IFrame URL
    worksheet: transformWorksheetOnLoad(worksheet) || {
      id: null,
      name: "",
      slug: null,
      rows: [],
    },
  };
};

export function reducer(state, { type: actionType, payload }) {
  switch (actionType) {
    case actionTypes.autocompleteHighlight: {
      return {
        ...state,
        autocompleteHighlight: payload.slug,
      };
    }
    case actionTypes.updateWorksheet: {
      return {
        ...state,
        worksheet: payload.worksheet,
      };
    }
    case actionTypes.updateProperties: {
      return {
        ...state,
        worksheet: {
          ...state.worksheet,
          ...payload.properties,
        },
      };
    }
    case actionTypes.changeScenarioId: {
      const stateMutation = {
        ...state,
        scenarioId: payload.scenarioId,
      };

      // If we switch scenario AND the current selected "applies to" is "current scenario" AND there is no model for that scenario, create one
      /*if(state.editingRow?.forecastAppliesTo === AppliesToOptions.CURRENT_SCENARIO && !state.editingRow.models?.find((model) => model.scenario_id === payload.scenarioId)) {
        stateMutation.editingRow = {
          ...stateMutation.editingRow,
          models: [
            ...stateMutation.editingRow.models,
            {
              frontendId: getUniqueId(),
              active: true,
              formula: "",
              scenario_id: payload.scenarioId,
              period: ModelPeriod.FORECASTS,
              variables: {},
            },
          ],
        };

        const indexInRows = state.worksheet.rows.indexOf(state.editingRow);
        const newRows = [...state.worksheet.rows];
        newRows[indexInRows] = stateMutation.editingRow;

        stateMutation.worksheet = {
          ...state.worksheet,
          rows: newRows,
        };
      }*/

      return stateMutation;
    }
    case actionTypes.changeYear: {
      return {
        ...state,
        year: payload.year,
      };
    }
    case actionTypes.closeSidebar:
    case actionTypes.cellClick: {
      return {
        ...state,
        actualsClicked: !!payload.row && payload.isActual === true,
        editingRow: payload.row,
      };
    }
    case actionTypes.updateRow: {
      //console.log("Update row", payload.initiator);
      const indexInRows = state.worksheet.rows.findIndex((item) => item.id === payload.row.id);
      const newRows = [...state.worksheet.rows];
      newRows[indexInRows] = payload.row;

      const newWorksheet = {
        ...state.worksheet,
        rows: newRows,
      };

      return {
        ...state,
        editingRow: state.editingRow?.id === payload.row.id ? payload.row : state.editingRow,
        invalidModels: findInvalidModels(newWorksheet),
        worksheet: newWorksheet,
      };
    }
    case actionTypes.addRow: {
      const rows = [...state.worksheet.rows];
      const prevRow = rows.length ? rows[rows.length - 1] : null;
      const newRow = {
        __typename: "WorksheetRow",
        id: getUniqueId(),
        type: payload.type,
        formatting: {
          indentation: prevRow?.formatting?.indentation ?? 0,
        },
      };

      if (newRow.type === "REFERENCE") {
        newRow.start = 0;
        newRow.end = 0;
        newRow.account_id = "1";
        newRow.name = "Revenue";
        newRow.slug = getSlug({ worksheet: state.worksheet, accountName: "Revenue", currentRowId: newRow.id });
      } else if (newRow.type === "METRIC") {
        newRow.account_id = null;
        newRow.name = "New Metric";
        newRow.slug = getSlug({ worksheet: state.worksheet, accountName: "new-metric", currentRowId: newRow.id });
        newRow.forecastAppliesTo = AppliesToOptions.ALL;
        newRow.models = [{
          frontendId: getUniqueId(),
          active: true,
          formula: "",
          scenario_id: null,
          period: ModelPeriod.ALL,
          variables: {},
        }];
      } else {
        newRow.name = "";
      }

      rows.push(newRow);

      return {
        ...state,
        actualsClicked: false,
        editingRow: newRow,
        worksheet: {
          ...state.worksheet,
          rows,
        },
      };
    }
    case actionTypes.deleteRow: {
      const rows = [...state.worksheet.rows];
      const index = rows.findIndex((row) => row.id === payload.row.id);
      rows.splice(index, 1);

      const newWorksheet = {
        ...state.worksheet,
        rows,
      };

      return {
        ...state,
        worksheet: newWorksheet,
        invalidModels: findInvalidModels(newWorksheet),
        editingRow: (!state.editingRow || state.editingRow.id === payload.row.id) ? null : state.editingRow,
      };
    }
    case actionTypes.moveRow: {
      const rows = [...state.worksheet.rows];
      return {
        ...state,
        worksheet: {
          ...state.worksheet,
          rows: arrayMove(rows, payload.index, payload.newIndex),
        },
      };
    }
    case actionTypes.showSheetsFrame: {
      return { ...state, sheetsFrameVisible: true };
    }
    case actionTypes.hideSheetsFrame: {
      return { ...state, sheetsFrameVisible: false };
    }
    case actionTypes.updateSheetsUrl: {
      const { sheetsUrl = "" } = payload;
      return { ...state, sheetsUrl };
    }
    default:
      return state;
  }
}
