import { useContext } from "react";
import { AppContext } from "./AppContext";

import { Icon } from "@blueprintjs/core";

import styles from "./styles/unauthorized.module.scss";

const appSumoTiers = {
  1: "Basic",
  2: "Professional",
  3: "Enterprise",
}

const isRequiredTier = (current = 0, required) => {
  return current >= required;
}

const Blocker = () => {
  return (
    <div className={styles.container}>
      <Icon icon="ban-circle" size={128} className={styles.errorIcon} />
      <p>You are not authorized to view this page.</p>
    </div>
  );
};

const AppSumoBlocker = ({ tier, requiredTier }) => {
  return (
    <div className={styles.container}>
      <Icon icon="ban-circle" size={128} className={styles.errorIcon} />
      <p>
        Hey Sumo-ling, you're currently on the "{tier}" tier.
      </p>
      <p>
        To access this functionality you'll need to activate the "{requiredTier}" tier.
      </p>
    </div>
  );
};

export const isSupport = (user) => {
  return (user && user.email && user.super);
};

export const withSupportPrivileges = (Component) => (props) => {
  const { user } = useContext(AppContext);

  return isSupport(user)
    ? <Component {...props} />
    : <Blocker />;
};

export const withPrivatePrivileges = (Component, opts = {}) => (props) => {
  const { user, tenantAuthority } = useContext(AppContext);
  const tier = tenantAuthority?.appsumo?.tier;

  if (Object.hasOwn(opts, "requiredTier") && tier && !isRequiredTier(tier, opts.requiredTier)) {
    return <AppSumoBlocker
      tier={appSumoTiers[tier]}
      requiredTier={appSumoTiers[opts.requiredTier]}
    />;
  }

  return (user?.email)
    ? <Component {...props} />
    : <Blocker />;
};

export const withAdminPrivileges = (Component, opts = {}) => (props) => {
  const { user, tenantAuthority } = useContext(AppContext);
  const role = user?.role;
  const tier = tenantAuthority?.appsumo?.tier;

  if (Object.hasOwn(opts, "requiredTier") && tier && !isRequiredTier(tier, opts.requiredTier)) {
    return <AppSumoBlocker
      tier={appSumoTiers[tier]}
      requiredTier={appSumoTiers[opts.requiredTier]}
    />;
  }

  return (user?.email && ((role === "ADMIN" || role === "SUPER") || isSupport(user)))
    ? <Component {...props} />
    : <Blocker />;
}
