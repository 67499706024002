import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import {Button, Classes, HTMLTable, HTMLSelect, Icon, InputGroup, Popover, Position} from "@blueprintjs/core";

import styles from "./ChartCreationModal.module.scss";

import {createEntriesCache, getEntriesCacheForAccount} from "shared/utilities/entry-utilities";
import {getAccountFromCache} from "shared/utilities/account-utilities";
import {entriesCacheVar, entriesByIdVar} from "apollo-config/local-state/entries";
import {flightpathAccountsByIdVar} from "apollo-config/local-state/accounts";

import {ACCOUNTS_MATCHING_IDS} from "../table-builder/graphql";

import {colors} from "lib/highcharts";
import FormFieldAccount from "../shared/fields/account";
import FormFieldSnapshot from "../shared/fields/snapshot";
import FormFieldScenario from "../shared/fields/scenario";

class ChartLines extends React.Component {
  static propTypes = {
    accounts: PropTypes.array.isRequired,
    customColors: PropTypes.array.isRequired,
    lines: PropTypes.array.isRequired,
    planGroups: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    scenarios: PropTypes.array.isRequired,
    snapshots: PropTypes.array.isRequired,
    teams: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    updateLines: PropTypes.func.isRequired,
  }

  changeLine = (idx) => (evt) => {
    const {accounts, lines, scenarios, snapshots, updateLines} = this.props;

    const linesClone = Array.from(lines);

    let value = evt.target.value === "0" ? null : evt.target.value;
    let name = linesClone[idx].name;
    let account_id = linesClone[idx].account_id;
    let scenario_id = linesClone[idx].scenario_id;

    // The accounts / scenarios belonging to the previously selected snapshot (or current forecast if it's the case)
    const previouslySelectedSnapshot = linesClone[idx].reference_snapshot_id ? snapshots.find((snapshot) => snapshot.id === linesClone[idx].reference_snapshot_id) : null;
    const previousAccounts = previouslySelectedSnapshot ? previouslySelectedSnapshot.accounts : accounts;
    const previousScenarios = previouslySelectedSnapshot ? previouslySelectedSnapshot.scenarios : scenarios;

    if(evt.target.name === "account_id") {
      value = evt.target.value;
      name = evt.target.text;
      if(evt.target.label && !["PNL", "BS", "CFS", "REV", "WORK"].includes(evt.target.label)) name += ` (${evt.target.label})`;
    } else if(evt.target.name === "reference_snapshot_id") {
      // The snapshot that has just been selected, and its accounts / scenarios
      const selectedSnapshot = !!value ? snapshots.find((snapshot) => snapshot.id === evt.target.value) : null;
      const newSnapshotAccounts = selectedSnapshot ? selectedSnapshot.accounts : accounts;
      const newSnapshotScenarios = selectedSnapshot ? selectedSnapshot.scenarios : scenarios;

      // The previously selected account / scenario
      const previouslySelectedAccount = previousAccounts.find((account) => account.id === linesClone[idx].account_id);
      const previouslySelectedScenario = previousScenarios.find((scenario) => scenario.id === linesClone[idx].scenario_id);
      const previouslySelectedAccountId = previouslySelectedSnapshot ? previouslySelectedAccount.reference_id : previouslySelectedAccount.id;
      const previouslySelectedScenarioId = previouslySelectedSnapshot ? previouslySelectedScenario.reference_id : previouslySelectedScenario.id;

      // Newly selected account id
      const matchingAccountInSnapshot = newSnapshotAccounts.find((account) => account.reference_id === previouslySelectedAccountId || account.id === previouslySelectedAccountId);
      const matchingScenarioInSnapshot = newSnapshotScenarios.find((scenario) => scenario.reference_id === previouslySelectedScenarioId || scenario.id === previouslySelectedScenarioId);

      account_id = matchingAccountInSnapshot ? matchingAccountInSnapshot.id : newSnapshotAccounts[0].id;
      scenario_id = matchingScenarioInSnapshot ? matchingScenarioInSnapshot.id : newSnapshotScenarios[0].id;
    }

    // Build the updated line
    linesClone[idx] = {
      ...linesClone[idx],
      account_id,
      scenario_id,
      name,
      [evt.target.name]: value,
    };

    updateLines(linesClone);
  }

  setLineColor = (idx, color_id) => () => {
    const {lines, updateLines} = this.props;

    const linesClone = Array.from(lines);
    linesClone[idx].color_id = color_id;

    updateLines(linesClone);
  }

  removeLine = (idx) => () => {
    const {lines, updateLines} = this.props;
    const linesClone = Array.from(lines);

    linesClone.splice(idx, 1);

    updateLines(linesClone);
  }

  handleAccountChange = (idx) => (event) => {
    this.changeLine(idx)(event);
  }

  render() {
    const {accounts, products, planGroups, customColors, lines, scenarios, snapshots, teams, type} = this.props;

    const allColors = customColors.length ? customColors.map((color) => color.color) : colors;

    const areaRangeOptions = [{label: "Line", value: "LINE"}];
    if(type === "AREARANGE") {
      const min = lines.find((line) => line.type === "MIN");
      const max = lines.find((line) => line.type === "MAX");

      areaRangeOptions.push({label: "Min", value: "MIN", disabled: !!min});
      areaRangeOptions.push({label: "Max", value: "MAX", disabled: !!max});
    }

    return (
      <div className={styles.chartCreationLines}>
        {lines.length ? (
          <HTMLTable className={[".modifier", styles.chartLinesTable].join(" ")}>
            <thead>
              <tr>
                <th>Account</th>
                <th>Name</th>
                <th>Snapshot</th>
                <th>Scenario</th>
                {type === "COMBO" || type === "AREARANGE" ? <th>Type</th> : null}
                <th>Color</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {lines.map((line, idx) => {
                const selectedSnapshot = line.reference_snapshot_id ? snapshots.find((snapshot) => snapshot.id === line.reference_snapshot_id) : null;

                const selectedAccounts = line.reference_snapshot_id ? selectedSnapshot.accounts : accounts.filter((account) => !account.snapshot_id);
                const selectedScenarios = line.reference_snapshot_id ? selectedSnapshot.scenarios : scenarios;
                const selectedPlanGroups = line.reference_snapshot_id ? selectedSnapshot.plan_groups : planGroups;
                const selectedProducts = line.reference_snapshot_id ? selectedSnapshot.products : products;
                const selectedTeams = line.reference_snapshot_id ? selectedSnapshot.teams : teams;

                return (
                  <tr key={idx}>
                    <td>
                      <FormFieldAccount
                        accounts={selectedAccounts}
                        onChange={this.handleAccountChange(idx)}
                        planGroups={selectedPlanGroups}
                        products={selectedProducts}
                        selectedAccountId={line.account_id}
                        teams={selectedTeams}
                      />
                    </td>
                    <td className={styles.nameCell}>
                      <div className={styles.nameWrapper}>
                        <InputGroup
                          autoComplete="off"
                          name="name"
                          onChange={this.changeLine(idx)}
                          placeholder="Line name"
                          value={line.name}
                        />
                      </div>
                    </td>
                    <td>
                      <FormFieldSnapshot
                        onChange={this.changeLine(idx)}
                        snapshots={snapshots}
                        value={line.reference_snapshot_id === null ? "0" : line.reference_snapshot_id}
                      />
                    </td>
                    <td>
                      <FormFieldScenario
                        formGroupClass={styles.formGroup}
                        onChange={this.changeLine(idx)}
                        scenarios={selectedScenarios}
                        value={line.scenario_id === null ? 0 : line.scenario_id}
                      />
                    </td>
                    {type === "COMBO" ? (
                      <td>
                        <HTMLSelect
                          fill
                          name="type"
                          onChange={this.changeLine(idx)}
                          options={["Line", "Bar"].map((type) => ({label: type, value: type.toUpperCase()}))}
                          value={line.type === null ? "LINE" : line.type}
                        />
                      </td>
                    ) : type === "AREARANGE" ? (
                      <td>
                        <HTMLSelect
                          fill
                          name="type"
                          onChange={this.changeLine(idx)}
                          options={areaRangeOptions}
                          value={line.type === null ? "LINE" : line.type}
                        />
                      </td>
                    ) : null}
                    <td className={styles.colorCell}>
                      <Popover
                        content={
                          <ul className={styles.colorListContainer}>
                            {customColors.map((color) => (
                              <li className={[Classes.POPOVER_DISMISS, styles[color.id === line.color_id ? "active" : "inactive"]].join(" ")} key={color.id} onClick={this.setLineColor(idx, color.id)}>
                                <div className={styles.colorPreview} style={{backgroundColor: color.color}} />
                                <span className={styles.colorName}>{color.name}</span>
                              </li>
                            ))}
                          </ul>
                        }
                        disabled={!customColors.length}
                        minimal
                        position={Position.BOTTOM_RIGHT}
                      >
                        <Button
                          disabled={!customColors.length}
                        >
                          <div className={styles.colorPreview} style={{backgroundColor: line.color_id ? customColors.find((color) => color.id === line.color_id).color : allColors[idx % allColors.length]}} />
                        </Button>
                      </Popover>
                    </td>
                    <td className={styles.trashCell}>
                      <div className={styles.trashIconContainer}>
                        <Icon icon="trash" onClick={this.removeLine(idx)} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </HTMLTable>
        ) : null}
      </div>
    );
  }
}

export default compose(
  graphql(ACCOUNTS_MATCHING_IDS, {
    name: "tableRowAccountsQuery",
    options: (props) => {
      const {lines} = props;

      const missingIds = [];
      for(const accountId of lines.map((line) => line.account_id)) {
        const matchingAccount = getAccountFromCache(accountId);
        if(!matchingAccount || !Object.keys(getEntriesCacheForAccount(matchingAccount)).length) {
          missingIds.push(accountId);
        }
      }

      return {
        variables: {
          ids: missingIds,
        },
        onCompleted({accountsMatchingIds}) {
          const accountsById = {};
          const entries = [];
          const entriesById = {};

          for(const account of accountsMatchingIds) {
            if(account.entries) {
              for(const entry of account.entries) {
                const entryWithAccountId = {
                  ...entry,
                  account_id: account.id,
                };
                entriesById[entry.id] = entryWithAccountId;
                entries.push(entryWithAccountId);
              }
            }

            const accountClone = {...account};
            if(accountClone.entries) delete accountClone.entries;
            accountsById[account.id] = accountClone;
          };

          // Add everything to the cache
          entriesCacheVar({
            ...entriesCacheVar(),
            ...createEntriesCache(entries).entriesCache,
          });
          entriesByIdVar({
            ...entriesByIdVar(),
            ...entriesById,
          });
          flightpathAccountsByIdVar({
            ...flightpathAccountsByIdVar(),
            ...accountsById,
          });
        },
      };
    },
    skip: (props) => {
      const {lines} = props;

      let allFound = true;
      for(const accountId of lines.map((line) => line.account_id)) {
        const matchingAccount = getAccountFromCache(accountId);

        if(!matchingAccount || !Object.keys(getEntriesCacheForAccount(matchingAccount)).length) {
          allFound = false;
          break;
        }
      }

      return allFound;
    },
  }),
)(ChartLines);
