import {gql} from "@apollo/client";

export const ALL_COLORS_QUERY = gql`
  query AllColorsQuery {
    colors {
      id
      name
      color
      index
    }
  }
`;

export const updateColor = gql`
  mutation updateColor($color: JSON!) {
    updateColor(color: $color) {
      id
      name
      color
      index
    }
  }
`;

export const removeColor = gql`
  mutation removeColor($id: String!) {
    removeColor(id: $id) {
      id
    }
  }
`;

export const createColor = gql`
  mutation createColor($color: JSON!) {
    createColor(color: $color) {
      id
      name
      color
      index
    }
  }
`;
