import { useContext, useState } from "react";
import { useQuery } from "@apollo/client";

import moment from "moment";

import { Callout, Intent } from "@blueprintjs/core";
import AccountsTable from "components/AccountsTable";
import HorizontalNav from "components/HorizontalNav";

import OperatingModelHeader from "./OperatingModelHeader";

import templates from "./templates";
import { resolveAccounts } from "shared/utilities/account-utilities";
import { useAllFlightpathAccounts } from "shared/hooks/account-hooks";
import { useParams } from "react-router-dom";

import { ALL_SCENARIOS_QUERY } from "../../graphql";

import { AppContext } from "../../AppContext";

import styles from "./styles.module.scss";

const menuItems = [
  { link: "/operating-model/profit-and-loss", text: "Profit & Loss" },
  { link: "/operating-model/balance-sheet", text: "Balance Sheet" },
  { link: "/operating-model/cash-flow", text: "Cash Flow Statement" },
];

export default function OperatingModel() {
  const context = useContext(AppContext);
  const { user } = context;
  const { statement } = useParams();
  const accounts = useAllFlightpathAccounts();
  const allScenariosQuery = useQuery(ALL_SCENARIOS_QUERY);

  const [scenarioId, setScenarioId] = useState(
    allScenariosQuery.data?.scenarios?.[0]?.id ?? null
  );

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [verticalScrollbar, setVerticalScrollbar] = useState(false);
  const [year, setYear] = useState(moment(context.forecastStartDate).format("YYYY"));
  const [extended, setExtended] = useState(false);
  const [collapseAll, setCollapseAll] = useState(false);

  if (!accounts || !allScenariosQuery.data?.scenarios) {
    return null;
  }

  const scenarios = allScenariosQuery.data.scenarios;
  const selectedScenario = scenarios.find((s) => s.id === scenarioId);
  const resolvedTemplate = resolveAccounts(accounts, templates[statement], scenarios);

  return (
    <div className={[styles.operatingModel, sidebarOpen ? styles.sidebarOpen : styles.sidebarClosed].join(" ")}>
      {!scenarios.length ? (
        <Callout className={styles.noScenario} intent={Intent.PRIMARY}>
          Please connect your accounting and create a forecast scenario in company settings before you start forecasting.
        </Callout>
      ) : (
        <>
          <OperatingModelHeader
            additionalPaddingOnWindows={verticalScrollbar}
            className={styles.headerWrapper}
            leftSection={(<HorizontalNav menuItems={menuItems} userRole={user.role} />)}
            onScenarioChange={setScenarioId}
            onYearChange={setYear}
            onExtendedChange={setExtended}
            scenarioId={scenarioId}
            year={year}
            extended={extended}
            collapseAll={collapseAll}
            setCollapseAll={setCollapseAll}
          />
          <AccountsTable
            onVerticalScrollbarChange={setVerticalScrollbar}
            resolvedTemplate={resolvedTemplate}
            selectedScenario={selectedScenario}
            setSidebarOpen={setSidebarOpen}
            year={year}
            extended={extended}
            collapseAll={collapseAll}
          />
        </>
      )}
    </div>
  );
}
