import moment from "moment";

import {getEntriesCacheForAccount, grabEntryValueFromCache} from "shared/utilities/entry-utilities";
import {getAccountFromCache, shouldAddValues} from "shared/utilities/account-utilities";

function generateForecastIndex(
  column_type,
  forecastMonthsDiff,
  monthsDiff,
) {
  let initialIndex;
  switch(column_type) {
    case "QUARTERS":
      initialIndex = Math.floor((forecastMonthsDiff / 3));
      if(initialIndex === 0 && monthsDiff < 3) return null;
      else return initialIndex;
    case "YEARS":
      initialIndex = Math.floor((forecastMonthsDiff / 12));
      if(initialIndex === 0 && monthsDiff < 12) return null;
      else return initialIndex;
    default:
    case "MONTHS":
      return forecastMonthsDiff;
  }
}

export function generateDataSeries({
  account,
  column_type,
  daterange = {},
  forecastStartDate,
  forecastStartDateSnapshot,
  reference_snapshot_id,
  report_period,
  scenarioId,
}) {
  const data = [];
  const isSnapshot = !!reference_snapshot_id && column_type !== "CUSTOM";
  const entriesCache = getEntriesCacheForAccount(account);

  let startDate, endDate;
  if(report_period === "MONTHS_FORECAST") {
    startDate = moment(forecastStartDate).startOf("month").subtract(daterange.monthsAgo, "months");
    endDate = moment(forecastStartDate).startOf("month").add(daterange.monthsAhead - 1, "months");
  } else if(report_period === "THIS_YEAR_TO_DATE") {
    startDate = moment(forecastStartDate).subtract(1, "months").startOf("year");
    endDate = moment(forecastStartDate).subtract(1, "months").endOf("month");
  } else if(report_period === "LAST_MONTH") {
    startDate = moment(forecastStartDate).startOf("month").subtract(1, "months");
    endDate = startDate.clone();
  } else {
    startDate = moment({year: daterange.dates.start.year, month: daterange.dates.start.month}).startOf("month");
    endDate = moment({year: daterange.dates.end.year, month: daterange.dates.end.month}).endOf("month");
  }

  const monthsDiff = endDate.diff(startDate, "months") + 1;

  const momentForecastStartDate = moment(forecastStartDateSnapshot || forecastStartDate);
  const forecastMonthsDiff = momentForecastStartDate.diff(startDate, "months");
  const firstForecastedIndex = (forecastMonthsDiff < 0) ? 0 : generateForecastIndex(column_type, forecastMonthsDiff, monthsDiff);

  let cursorDate = startDate.clone();
  let currentTotal = 0;
  let remainder = 0; // This is the number of data points accumulated for which we have not yet generated a total
  let lastMonthValue = null;

  for(let i = 0; i < monthsDiff; i++) {
    const monthValue = grabEntryValueFromCache(entriesCache, scenarioId || null, cursorDate.format("YYYY-MM"), null, momentForecastStartDate, isSnapshot);
    lastMonthValue = monthValue;
    currentTotal += monthValue;
    remainder++;
    if(column_type === "QUARTERS" && ((i + 1) % 3) === 0) {
      data.push(shouldAddValues(account) ? currentTotal : monthValue); // If it's a balance sheet, use the last one instead of the total
      currentTotal = 0;
      remainder = 0;
    }

    if(column_type === "MONTHS") {
      data.push(monthValue);
    }

    if(column_type === "YEARS" && ((i + 1) % 12) === 0) {
      data.push(shouldAddValues(account) ? currentTotal : monthValue);
      currentTotal = 0;
      remainder = 0;
    }

    cursorDate = cursorDate.add(1, "months").endOf("month");
  };

  // Add remainder here to show if on QUARTERS or YEARS
  if(remainder > 0 && column_type !== "MONTHS") {
    data.push(shouldAddValues(account) ? currentTotal : lastMonthValue);
  }

  return {data, firstForecastedIndex};
}

export function generateRowValues({
  columns = [],
  forecastStartDate,
  row,
  table,
}) {
  columns = table.columns?.length ? table.columns : columns;

  if(table.type === "ADVANCED" && columns.length) {
    const data = [];
    const categories = [];

    const accounts = [];
    // If table type is ADVANCED and we have a row without account_ids, it means we just switched from SIMPLE to ADVANCED.
    // It's safe to assume the only account_id needed is the account id of the row
    if(!row.account_ids) row.account_ids = [row.account_id];

    for(const id of row.account_ids) {
      const account = getAccountFromCache(id);
      if(account) {
        accounts.push(account);
      } else {
        console.warn(`Tried to get account #${id} from cache but it was not found`);
      }
    }

    for(const column of columns) {
      let account = null;
      let scenarioId = column.scenario_id;

      if(column.reference_snapshot_id) {
        account = accounts.find((item) => item.reference_id === row.account_id && item.snapshot_id === column.reference_snapshot_id);
      } else {
        account = accounts.find((item) => item.id === row.account_id);
        if(row.reference_snapshot_id) {
          const matchingScenario = table.scenarios.find((scenario) => scenario.reference_id === column.scenario_id && scenario.snapshot_id === row.reference_snapshot_id);
          if(!matchingScenario) {
            //console.log("Warning! No matching scenario found...");
            return {data: [], categories: []};
          }
          scenarioId = matchingScenario.id;
        }
      }

      if(!account) {
        // If this account was not found in that snapshot
        data.push(0);
        categories.push(column.name);
        continue;
      }

      const {data: [total]} = generateDataSeries({
        account,
        column_type: "CUSTOM",
        daterange: column.daterange,
        forecastStartDate,
        forecastStartDateSnapshot: column.reference_snapshot_id ? column.reference_snapshot.forecast_start_date : null,
        reference_snapshot_id: column.reference_snapshot_id ? column.reference_snapshot_id : row.reference_snapshot_id ? row.reference_snapshot_id : null,
        report_period: column.report_period,
        scenarioId,
      });

      let finalTotal = total;

      if(row.formatting.pctOfRevenue) {
        let revenueAccount = null;
        if(!column.reference_snapshot_id) {
          revenueAccount = accounts.find((account) => account.id === "1");
        } else {
          revenueAccount = accounts.find((account) => account.reference_id === "1" && account.snapshot_id === column.reference_snapshot_id);
        }

        const {data: [revenueTotal]} = generateDataSeries({
          account: revenueAccount,
          column_type: "CUSTOM",
          daterange: column.daterange,
          forecastStartDate,
          forecastStartDateSnapshot: column.reference_snapshot_id ? column.reference_snapshot.forecast_start_date : null,
          reference_snapshot_id: column.reference_snapshot_id ? column.reference_snapshot_id : row.reference_snapshot_id ? row.reference_snapshot_id : null,
          report_period: column.report_period,
          scenarioId,
        });

        if(!total || !revenueTotal) {
          finalTotal = null;
        } else {
          finalTotal = total / revenueTotal;
        }
      }

      data.push(finalTotal);
      categories.push(column.name);
    }

    return {
      data,
      categories,
    };
  } else {
    const account = getAccountFromCache(row.account_id);
    if(!account) {
      console.warn(`Account ${row.account_id} was not found in table ${table.name}`);
      return {data: []};
    }
    let revenueAccount = null;
    if(row.formatting.pctOfRevenue) {
      let revenueAccountId = "1";
      // In a simple table, we should only have one account id in the row, unless the row is a snapshot, then we have two. We can assume the other one is the revenue account id
      if(row.reference_snapshot_id) revenueAccountId = row.account_ids.find((id) => id !== account.id);
      revenueAccount = getAccountFromCache(revenueAccountId); // TODO: scope this to the snapshot
      if(!revenueAccount) {
        console.warn(`Revenue account ${revenueAccountId} was not found in table ${table.name}`);
        return {data: []};
      }
    }

    let scenarioId = table.scenario_id;
    if(row.reference_snapshot_id) {
      if(row.reference_scenario_id) {
        scenarioId = row.reference_scenario_id;
      } else if(table.scenarios) {
        for(const {id, reference_id, snapshot_id} of table.scenarios) {
          if(snapshot_id === row.reference_snapshot_id && reference_id === table.scenario_id) scenarioId = id;
        }
      }
    }

    const result = generateDataSeries({
      account,
      column_type: table.column_type,
      daterange: table.daterange,
      forecastStartDate,
      forecastStartDateSnapshot: row.reference_snapshot_id ? row.reference_snapshot.forecast_start_date : null,
      reference_snapshot_id: row.reference_snapshot_id,
      report_period: table.report_period,
      scenarioId,
    });

    if(row.formatting.pctOfRevenue) {
      const revenueResult = generateDataSeries({
        account: revenueAccount,
        column_type: table.column_type,
        daterange: table.daterange,
        forecastStartDate,
        forecastStartDateSnapshot: row.reference_snapshot_id ? row.reference_snapshot.forecast_start_date : null,
        reference_snapshot_id: row.reference_snapshot_id,
        report_period: table.report_period,
        scenarioId,
      });

      for(let i = 0; i < revenueResult.data.length; i++) {
        if(!result.data[i] || !revenueResult.data[i]) {
          result.data[i] = null;
        } else {
          const percentageOfRevenue = result.data[i] / revenueResult.data[i];
          result.data[i] = percentageOfRevenue;
        }
      }
    }

    return result;
  }
}

export function getTableHeaders({
  columns = [],
  forecastStartDate,
  table,
}) {
  let labels = [];
  const {daterange, column_type, report_period} = table;
  if(table.type === "SIMPLE") {
    labels = getDaterangeLabels({daterange, column_type, forecastStartDate, report_period});
  } else {
    labels = (columns || []).map((column) => column.name);
  }

  return labels;
}

export function getDaterangeLabels({
  daterange,
  column_type,
  forecastStartDate,
  report_period,
}) {
  const labels = [];
  let startDate, endDate;
  if(report_period === "MONTHS_FORECAST") {
    startDate = moment(forecastStartDate).startOf("month").subtract(daterange.monthsAgo, "months");
    endDate = moment(forecastStartDate).endOf("month").add(daterange.monthsAhead - 1, "months");
  } else if(report_period === "LAST_MONTH") {
    startDate = moment(forecastStartDate).startOf("month").subtract(1, "month");
    endDate = startDate.clone();
  } else if(report_period === "THIS_YEAR_TO_DATE") {
    startDate = moment(forecastStartDate).subtract(1, "month").startOf("year");
    endDate = moment(forecastStartDate).subtract(1, "month");
  } else {
    startDate = moment({year: daterange.dates.start.year, month: daterange.dates.start.month}).startOf("month");
    endDate = moment({year: daterange.dates.end.year, month: daterange.dates.end.month}).endOf("month");
  }
  const monthsDiff = endDate.diff(startDate, "months") + 1;

  let cursorDate = startDate.clone();
  let remainder = 0;
  for(let i = 0; i < monthsDiff; i++) {
    remainder++;
    if(column_type === "QUARTERS" && ((i + 1) % 3) === 0) {
      labels.push(`Q${cursorDate.format("Q YYYY")}`);
      remainder = 0;
    }

    if(column_type === "MONTHS") {
      labels.push(cursorDate.format("MMM-YY"));
    }

    if(column_type === "YEARS" && ((i + 1) % 12) === 0) {
      labels.push(cursorDate.format("YYYY"));
      remainder = 0;
    }

    cursorDate = cursorDate.add(1, "months").endOf("month");
  }

  if(remainder > 0 && column_type !== "MONTHS") {
    const startDate = cursorDate.clone().subtract(1, "months");
    const endDate = cursorDate.clone().subtract(1, "months");
    const label = `${startDate.startOf(column_type === "QUARTERS" ? "quarter" : "year").format("MMM YYYY")}-${endDate.endOf("month").format("MMM YYYY")}`;
    labels.push(label);
  }

  return labels;
}
