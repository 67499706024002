import numeral from "numeral";
import memoize from "fast-memoize";

import dayjs from "dayjs";

export const formatYear = (year) => `${year}`.substring(2, 4);

export const currencyToSymbolMapping = {
  "dollar": "$",
  "poundSterling": "£",
  "euro": "€",
};

const transformNum = (num, currencySymbol, isPercentage, decimals) => {
  let decimalsStr = "(0,)";
  if (decimals > 0) {
    decimalsStr += ".";
    for (let i = 0; i < decimals; i++) {
      decimalsStr += "0";
    }
    //decimalsStr += "]";
  } else if (decimals < 0) {
    let dividedBy = "1";
    for (let i = 0; i < Math.abs(decimals); i++) {
      dividedBy += "0";
    }
    num = Math.round(num / parseInt(dividedBy));
  }

  if (isPercentage) {
    return `${numeral(num).format(decimalsStr)}%`;
  } else if (currencySymbol) {
    return `${currencyToSymbolMapping[currencySymbol]}${numeral(num).format(decimalsStr)}`;
  } else {
    return numeral(num).format(decimalsStr);
  }
};

export const formatValue = memoize(({ value, currencySymbol, isPercentage, decimals, noZero, shorthand }) => {
  let newValue;
  if (noZero && !value) return " ";
  value = parseFloat(typeof value !== "undefined" && value !== null ? value : 0);

  // If the template section says this value is a percentage, multiply by 100 to display a real percentage and not a decimal number
  if (isPercentage) value = value * 100;

  if (value === 0 || value === null) {
    newValue = " ";
  } else {
    if (shorthand && Math.abs(value) > 1000000) {
      newValue = transformNum(value / 1000000, currencySymbol, isPercentage, 1) + "M";
    } else if (shorthand && Math.abs(value) > 1000) {
      newValue = transformNum(value / 1000, currencySymbol, isPercentage, 1) + "K";
    } else {
      newValue = transformNum(value, currencySymbol, isPercentage, decimals);
    }
  }
  return newValue;
}, {
  strategy: memoize.strategies.monadic,
});

export const isLessThan = (date1, date2) => {
  const isGreaterThan = false;
  const year1 = parseInt(date1.year, 10);
  const year2 = parseInt(date2.year, 10);
  if (year1 < year2) return true;
  else if (year1 === year2) {
    if (date1.month < date2.month) return true;
  }
  return isGreaterThan;
};

// The date should come in format {year: string, month: int}
// It can not handle month changes of more than 12
export const dateOperation = (date, amount) => {
  const multiplier = (amount > 0) ? (Math.floor(amount / 12)) : (Math.ceil(amount / 12));
  date.month = (amount - (12 * multiplier)) + date.month;
  if (date.month > 11) {
    date.month = date.month - 12;
    date.year = (parseInt(date.year, 10) + (multiplier + 1)).toString();
  } else if (date.month < 0) {
    date.month = 12 + date.month;
    date.year = (parseInt(date.year, 10) + (multiplier - 1)).toString();
  } else {
    date.year = (parseInt(date.year, 10) + multiplier).toString();
  }
  return date;
};

export const getCurrentMonthFromForecastStartDate = memoize((forecastStartDate) => {
  if (!forecastStartDate) return forecastStartDate;
  const [year, month] = forecastStartDate.split("-");
  let newMonth = parseInt(month) - 1;
  let newYear = parseInt(year);

  if (month === "01") {
    newMonth = 12;
    newYear = newYear - 1;
  }

  return `${newYear}-${newMonth.toString().padStart(2, "0")}`;
});

export const isCurrentMonth = memoize((forecastStartDate, date) => getCurrentMonthFromForecastStartDate(forecastStartDate) === date);

export const isAfterCurrentMonth = memoize(({ forecastStartDate, date, year, month }) => {
  if (!date && !!year && !!month) date = `${year}-${month.toString().padStart(2, "0")}`;
  if (date > getCurrentMonthFromForecastStartDate(forecastStartDate)) return true;
  else return false;
}, {
  strategy: memoize.strategies.monadic,
});

export const isAfter = memoize((dateA, dateB) => {
  return dayjs(dateA).isAfter(dayjs(dateB), 'month');
});

export const isSame = memoize((dateA, dateB) => {
  return dayjs(dateA).isSame(dayjs(dateB), 'month');
});
