import React, {useContext} from "react";
import PropTypes from "prop-types";

import moment from "moment";

import Input from "components/Input";
import {Icon, Position} from "@blueprintjs/core";
import {DateInput3 as DateInput} from "@blueprintjs/datetime2";

import {AppContext} from "../../../../AppContext";

import styles from "./styles.module.scss";

export default function RaiseScheduleTab({
  onRaiseDelete,
  onRaiseUpdate,
  period,
  raises,
}) {
  const {forecastEndDate, forecastStartDate} = useContext(AppContext);

  return (
    <>
      <p>
        {period === "PAST" ? (
          <span>Below are the past company-wide raises. DO NOT EDIT unless you fully understand the impacts this will have on your hiring plan.</span>
        ) : (
          <span>Schedule your upcoming company-wide pay raises below.</span>
        )}
      </p>
      <div className={styles.raisesList}>
        <div className={styles.header}>
          <div>Raise %</div>
          <div>Date</div>
        </div>
        {raises.length ? raises.map((raise, i) => (
          <div className={styles.raise} key={i}>
            <Input
              name="amount"
              onChange={onRaiseUpdate(i)}
              value={typeof raise.amount === "undefined" || raise.amount === null ? "" : raise.amount}
            />
            <span className={styles.percentage}>%</span>
            <DateInput
              formatDate={(date) => date ? moment(date).format("MM/DD/YYYY") : date}
              maxDate={i === raises.length - 1 ? moment(forecastEndDate).toDate() : moment(raises[i + 1].date).subtract(1, "day").toDate()}
              minDate={i === 0 ? moment(forecastStartDate).toDate() : moment(raises[i - 1].date).add(1, "day").toDate()}
              onChange={(value) => onRaiseUpdate(i)({target: {name: "date", value}})}
              parseDate={(str) => moment(str, "MM/DD/YYYY").toDate()}
              placeholder={"mm/dd/yyyy"}
              popoverProps={{position: Position.BOTTOM}}
              showActionsBar
              value={!!raise.date ? moment(raise.date).format("MM/DD/YYYY") : null}
            />
            <div className={styles.deleteButton}>
              <Icon icon="delete" iconSize={18} onClick={onRaiseDelete(i)} />
            </div>
          </div>
        )) : (
          <div className={styles.noRaises}>No raises to display</div>
        )}
      </div>
    </>
  );
}

RaiseScheduleTab.propTypes = {
  onRaiseDelete: PropTypes.func.isRequired,
  onRaiseUpdate: PropTypes.func.isRequired,
  period: PropTypes.string.isRequired,
  raises: PropTypes.array.isRequired,
};
