import { useContext } from "react";

import Card from "components/Card";
import SettingsHeader from "components/SettingsHeader";
import { AppContext } from "../../AppContext";

import { HTMLTable } from "@blueprintjs/core";

export default function AppSumo() {
  const context = useContext(AppContext);

  let tiers = [
    ["Essential", "Custom Dashboard, Autopilot Forecasting, Default Worksheets, Forecast to Actuals (variance analysis)"],
    ["Professional", "All features of Essential plus: Scenario Planning, Detailed Hiring Plan, Revenue Forecast Model"],
    ["Enterprise", "All features of Professional plus: Create Custom Worksheets for You (3), Create Custom Google Sheets (1) for You, Handholding Onboarding, Extended Dedicated Account Manager support (120 mins)"],
  ];

  tiers = tiers.map(([tier, description]) => (
    <tr>
      <td>{tier}</td>
      <td>{description}</td>
    </tr>
  ));

  return (
    <Card>
      <SettingsHeader subtitle text="AppSumo" />

      <span>Current tier: {context.user.appsumo.tier}</span>

      <HTMLTable bordered style={{ width: "100%", marginTop: "1rem" }}>
        <thead>
          <tr>
            <th>License Tier</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {tiers}
        </tbody>
      </HTMLTable>
    </Card>
  );
}
