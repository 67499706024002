import React, {useContext} from "react";
import PropTypes from "prop-types";

import {generateRowValues} from "../../shared/data-utilities";
import {getFormattedTableCellValue} from "../../table-builder/table-utilities";

import styles from "./CustomTable.module.scss";
import formattingStyles from "components/FormattingOptions/formattingStyles.module.scss";

import {AppContext} from "../../../../AppContext";

export default function TableRowForDisplay({
  categories,
  forecastStartDate,
  row,
  table,
}) {
  const {currency} = useContext(AppContext);
  const rowWithAccountIds = {...row};

  const values = row.account_id ? generateRowValues({
    forecastStartDate,
    row: rowWithAccountIds,
    table,
  }) : {};

  if(!values.data) {
    values.data = categories.map(() => "");
  }

  const columns = table.type === "ADVANCED" && table.columns?.length ? table.columns : null;

  const rowStyles = [];

  if(row.formatting.bold) rowStyles.push(formattingStyles.bold);
  if(row.formatting.borderTop) rowStyles.push(formattingStyles.borderTop);
  if(row.formatting.percentage) rowStyles.push(formattingStyles.percentage);
  if(row.formatting.dollar) rowStyles.push(formattingStyles.dollar);
  if(row.formatting.indentation > 0) rowStyles.push(formattingStyles[`indent${row.formatting.indentation}`]);

  const comparisonColumns = table.type === "ADVANCED" && columns.length === 2 && table.options?.showComparison;

  const deltas = {};
  if(comparisonColumns) {
    if(table.options.valueDeltaColumn) deltas.value = values.data[1] - values.data[0];
    if(table.options.percentageDeltaColumn) deltas.percentage = (!values.data[0] || (!values.data[0] && !values.data[1])) ? null : ((values.data[1] - values.data[0]) / Math.abs(values.data[0]));
  }

  // Keep track of the index of the delta columns
  let deltaIndex = 0;

  return (
    <tr className={rowStyles.join(" ")}>
      <td>{row.name}</td>
      {values.data.map((value, i) => {
        const decimals = (!!columns?.[i].formatting.decimals ? columns[i].formatting.decimals : row.formatting.decimals) || 0;
        const currencySymbol = row.formatting.dollar || (columns?.[i].formatting.dollar) ? currency : null;
        const percentage = row.formatting.percentage || (columns?.[i].formatting.percentage);
        const borderLeft = row.formatting.borderLeft || (columns?.[i].formatting.borderLeft);

        const cellClasses = [
          columns?.[i].formatting.bold ? styles.bold : undefined,
          borderLeft ? styles.borderLeft : "",
        ];

        return (
          <td className={cellClasses.join(" ")} key={`${table.id}-${categories[i]}`}>
            {getFormattedTableCellValue(values.data[i], {
              decimals,
              currencySymbol,
              percentage,
              emptyRow: !row.account_id,
              pctOfRevenue: row.formatting.pctOfRevenue,
            })}
          </td>
        );
      })}
      {comparisonColumns ? ["value", "percentage"].map((type) => {
        if(!table.options[`${type}DeltaColumn`]) return null;

        let currencySymbol = false;
        let percentage = false;
        let borderLeft = false;
        let decimals = 0;

        const formattingOptions = table.options[`${type}DeltaFormattingOptions`];
        if(formattingOptions) {
          currencySymbol = typeof formattingOptions.dollar !== "undefined" && formattingOptions.dollar ? currency : null;
          percentage = typeof formattingOptions.percentage !== "undefined" ? formattingOptions.percentage : false;
          borderLeft = typeof formattingOptions.borderLeft !== "undefined" ? formattingOptions.borderLeft : false;
          decimals = !!formattingOptions.decimals ? formattingOptions.decimals : 0;
        }

        const cellClasses = [
          formattingOptions?.bold ? styles.bold : undefined,
          borderLeft ? styles.borderLeft : "",
        ];

        return (typeof deltas[type] !== "undefined" && ++deltaIndex) ? (
          <td className={cellClasses.join(" ")} key={type}>
            {!row.account_id ? (
              <span />
            ) : (
              <span>
                {getFormattedTableCellValue(deltas[type], {
                  decimals,
                  deltaType: type,
                  currencySymbol,
                  percentage,
                  emptyRow: !row.account_id,
                  pctOfRevenue: row.formatting.pctOfRevenue,
                })}
              </span>
            )}
          </td>
        ) : null;
      }) : null}
    </tr>
  );
}

TableRowForDisplay.propTypes = {
  categories: PropTypes.array.isRequired,
  forecastStartDate: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    account_id: PropTypes.string,
    formatting: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
  }),
  table: PropTypes.object.isRequired,
};
