import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { graphql } from "@apollo/client/react/hoc";
import compose from "lodash.flowright";
import { gql } from "@apollo/client";

import { Callout, Intent } from "@blueprintjs/core";
import Errors from "components/Errors";
import Button from "components/Button";
import SettingsHeader from "components/SettingsHeader";

import { INITIAL_LOAD_QUERY, ALL_SCENARIOS_QUERY } from "../../../graphql";
import { FORECAST_START_DATE_CHANGE_STATUS } from "./graphql";
import { ALL_SNAPSHOTS_QUERY } from "../snapshots/graphql";
import { ALL_SNAPSHOTS_QUERY as TABLE_SNAPSHOTS_QUERY } from "../../dashboard/chart-builder/graphql";
import { createYearsArrays } from "../snapshots/forecast-settings-utilities";
import { start, end } from "../../../lib/client-performance";

import { AppContext } from "../../../AppContext";

import styles from "./styles.module.scss";

const months = moment.months();

const ACCOUNTING_AUTH_ERROR = "Flightpath's authorization to your accounting has expired or your accounting provider account's privileges have changed.";

class ModelVersions extends React.Component {
  static propTypes = {
    forecastStartDateChangeStatus: PropTypes.object,
    mutate: PropTypes.func,
  }

  constructor(props, context) {
    super(props);

    const momentForecastStartDate = moment(context.forecastStartDate, "YYYY-MM-DD");

    // Calc forecast end date year
    const forecastEndYear = moment(context.forecastEndDate, "YYYY-MM-DD").format("YYYY");
    const yearsArrays = createYearsArrays(context.user);
    const currentDate = moment();
    const forecastStartYear = momentForecastStartDate.year();

    const historicalsStartMoment = moment(context.historicalsStartDate, "YYYY-MM-DD");
    const historicalsStartMonth = historicalsStartMoment.month();
    const historicalsStartYear = historicalsStartMoment.year().toString();

    const fiscalYearStart = moment(context.fiscalYearStart, "YYYY-MM-DD");
    const fiscalYearStartMonth = fiscalYearStart.month();

    this.state = {
      currentMonth: currentDate.month(),
      currentYear: currentDate.format("YYYY"),
      fiscalYearStartMonth,
      forecastEndYear,
      forecastEndYearArray: yearsArrays.forecastEndYearArray,
      forecastStartMonth: momentForecastStartDate.month(),
      forecastStartYear,
      historicalsStartDate: context.historicalsStartDate,
      historicalsStartMonth,
      historicalsStartYear,
      modelSettingsYearArray: yearsArrays.modelSettingsYearArray,
      operationActive: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.forecastStartDateChangeStatus && this.props.forecastStartDateChangeStatus) {
      this.handleSubscriptionUpdate(this.props.forecastStartDateChangeStatus);
    } else if (prevProps.forecastStartDateChangeStatus && this.props.forecastStartDateChangeStatus) {
      if ((prevProps.forecastStartDateChangeStatus.status !== this.props.forecastStartDateChangeStatus.status) || (prevProps.forecastStartDateChangeStatus.progress !== this.props.forecastStartDateChangeStatus.progress)) {
        this.handleSubscriptionUpdate(this.props.forecastStartDateChangeStatus);
      }
    }
  }

  componentWillUnmount() {
    this.context.removeToast("forecastStartDateChange");
  }

  static contextType = AppContext

  changeField = (event, field) => {
    const value = (field === "forecastStartMonth" || field === "historicalsStartMonth" || field === "fiscalYearStartMonth") ? parseInt(event.target.value) : event.target.value;
    const state = { ...this.state, [field]: value };
    this.setState(state);
  }

  checkIfMonthEnabled = (month) => {
    if (this.state.forecastStartYear >= this.state.currentYear) {
      if (month > this.state.currentMonth) return true;
    }
    return false;
  }

  isDateDisabled = (field, value) => {
    const newState = {
      ...this.state,
      [field]: value,
    };

    if (newState.historicalsStartYear > newState.forecastStartYear) return true;
    if (newState.historicalsStartYear === newState.forecastStartYear) {
      if (newState.historicalsStartMonth >= newState.forecastStartMonth) return true;
    }
    return false;
  }

  hasIntegratedAccounting = () => this.context.accountingConnected && this.context.accountingConfirmed;

  saveDates = () => {
    // Convert dates
    const dates = {
      forecastStartDate: moment({ years: this.state.forecastStartYear, months: this.state.forecastStartMonth }).endOf("month").format("YYYY-MM-DD"),
      forecastEndDate: `${(parseInt(this.state.forecastEndYear, 10)).toString()}-12-31`,
      historicalsStartDate: moment({ years: this.state.historicalsStartYear, months: this.state.historicalsStartMonth }).endOf("month").format("YYYY-MM-DD"),
      fiscalYearStartDate: moment({ months: this.state.fiscalYearStartMonth }).endOf("month").format("YYYY-MM-DD"),
    };
    this.toggleOperationActive();
    this.context.addToast("forecastStartDateChange", {
      icon: "airplane",
      message: "Updating your Flightpath models...",
      progress: 5,
    });
    start("Forecast Dates Change");
    this.props.mutate({
      variables: { update: dates },
      refetchQueries: [{ query: ALL_SCENARIOS_QUERY }, { query: INITIAL_LOAD_QUERY }, { query: ALL_SNAPSHOTS_QUERY }, { query: TABLE_SNAPSHOTS_QUERY }],
    })
      .then(() => {
        end();
        this.toggleOperationActive();
        this.setState({ errors: [] });
      }).catch((error) => {
        end();
        this.context.removeToast("forecastStartDateChange");
        // eslint-disable-next-line
        console.log("There was an error sending the query", error);
        this.toggleOperationActive();
        if (error.message.includes("DISCONNECTED") || error.message.includes("invalid_grant")) this.setState({ errors: [ACCOUNTING_AUTH_ERROR] });
      });
  }

  toggleOperationActive = () => {
    this.setState({ operationActive: !this.state.operationActive });
  }

  handleSubscriptionUpdate = (forecastStartDateChangeStatus) => {
    this.context.addToast("forecastStartDateChange", {
      icon: "airplane",
      message: forecastStartDateChangeStatus.status,
      progress: forecastStartDateChangeStatus.progress,
    });
  }

  render() {
    return (
      <>
        <SettingsHeader subtitle text="Forecast Start Date" />
        {(this.state.errors?.includes(ACCOUNTING_AUTH_ERROR)) ? (
          <div className={`bp5-callout bp5-intent-danger m-b ${styles.sideBySide}`}>
            <div className={styles.leftSide}>
              <div className={styles.stepTitle}>{ACCOUNTING_AUTH_ERROR}</div>
            </div>
            <div className={styles.rightSide}>
              <Button link="/company/integrations" size="small" text="Reconnect" />
            </div>
          </div>
        ) : (
          <Errors messages={this.state.errors} />
        )}

        {(this.hasIntegratedAccounting()) ? (
          <div className="m-t-md">
            <h4 className="bp5-heading">
              <label className="bp5-label bp5-inline .modifier">
                Actuals Start:
                <div className="bp5-select">
                  <select defaultValue={this.state.historicalsStartMonth} onChange={(event) => { this.changeField(event, "historicalsStartMonth"); }}>
                    {months.map((month, index) => (
                      <option disabled={this.isDateDisabled("historicalsStartMonth", index)} key={index + month} value={index}>{month}</option>
                    ))}
                  </select>
                </div>
                <div className="bp5-select">
                  <select defaultValue={this.state.historicalsStartYear} onChange={(event) => { this.changeField(event, "historicalsStartYear"); }}>
                    {this.state.modelSettingsYearArray.map((year, index) => (
                      <option disabled={this.isDateDisabled("historicalsStartYear", year)} key={index + year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
              </label>
            </h4>
            <h4 className="bp5-heading">
              <label className="bp5-label bp5-inline .modifier">
                Forecast Start:
                <div className="bp5-select">
                  <select defaultValue={this.state.forecastStartMonth} onChange={(event) => { this.changeField(event, "forecastStartMonth"); }}>
                    {months.map((month, index) => (
                      <option disabled={this.isDateDisabled("forecastStartMonth", index)} key={index + month} value={index}>{month}</option>
                    ))}
                  </select>
                </div>
                <div className="bp5-select">
                  <select defaultValue={this.state.forecastStartYear} onChange={(event) => { this.changeField(event, "forecastStartYear"); }}>
                    {this.state.modelSettingsYearArray.map((year, index) => (
                      <option disabled={this.isDateDisabled("forecastStartYear", year)} key={index + year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
              </label>
            </h4>
            <h4 className="bp5-heading">
              <label className="bp5-label bp5-inline .modifier">
                Forecast End:
                <div className="bp5-select">
                  <select defaultValue={this.state.forecastEndYear} onChange={(event) => { this.changeField(event, "forecastEndYear"); }}>
                    {this.state.forecastEndYearArray.map((year, index) => (
                      <option key={index + year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <br />
              </label>
            </h4>
            <h4 className="bp5-heading">
              <label className="bp5-label bp5-inline .modifier">
                Fiscal Year Start:
                <div className="bp5-select">
                  <select defaultValue={this.state.fiscalYearStartMonth} onChange={(event) => { this.changeField(event, "fiscalYearStartMonth"); }}>
                    {months.map((month, index) => (
                      <option key={index + month} value={index}>{month}</option>
                    ))}
                  </select>
                </div>
                <br />
              </label>
            </h4>
            <Button
              className="m-t"
              disabled={this.state.operationActive}
              intent="success"
              loading={this.state.operationActive}
              onClick={this.saveDates}
            >
              Save and Refresh
            </Button>
          </div>
        ) : (
          <Callout intent={Intent.PRIMARY}>
            Please connect with an <a href="/company/integrations">accounting system</a> in order to edit forecast settings.
          </Callout>
        )}
      </>
    );
  }
}

const updateForecastDates = gql`
  mutation updateForecastDates($update: JSON!) {
    updateForecastDates(update: $update)
  }
`;

const ModelVersionsWithData = compose(
  graphql(updateForecastDates),
  graphql(FORECAST_START_DATE_CHANGE_STATUS, {
    props: ({ data: { forecastStartDateChangeStatus } }) => {
      if (forecastStartDateChangeStatus) {
        return { forecastStartDateChangeStatus };
      }
    },
  }),
)(ModelVersions);

export default ModelVersionsWithData;
