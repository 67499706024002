import React, {useEffect} from "react";
import PropTypes from "prop-types";

import {useQuery} from "@apollo/client";

import ForecastSidebar from "./ForecastSidebar";

import {ALL_SCENARIOS_QUERY} from "../../graphql";

import {resetLocalEntriesCacheForAccount} from "apollo-config/local-state/entries";
import {resetLocalModelsCacheForAccount} from "apollo-config/local-state/models";
import {useEntriesCacheForAccount} from "shared/hooks/entry-hooks";
import {useModelsCacheForAccount} from "shared/hooks/model-hooks";

import {createModelsCache} from "shared/utilities/model-utilities";

export default function ForecastSidebarWrapper({
  account,
  ...props
}) {
  const {data: {scenarios}} = useQuery(ALL_SCENARIOS_QUERY);

  const accountEntriesCache = useEntriesCacheForAccount(account?.id);
  let accountModelsCache = useModelsCacheForAccount(account?.id);

  if(account && !Object.keys(accountModelsCache).length) accountModelsCache = createModelsCache({account, scenarios});

  useEffect(() => () => {
    if(account) {
      resetLocalEntriesCacheForAccount({id: account.id});
      resetLocalModelsCacheForAccount({id: account.id});
    }
  }, [account]);


  return (
    <ForecastSidebar
      account={account}
      entriesCache={accountEntriesCache}
      modelsCache={accountModelsCache}
      {...props}
    />
  );
}

ForecastSidebarWrapper.propTypes = {
  account: PropTypes.object,
};
