import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useOutletContext, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Classes as BP } from "@blueprintjs/core";
import Button from "components/Button";
import Input from "components/Input";

import { resetPassword as RESET_PASSWORD } from "./graphql";

import PasswordChange, { passwordRequirementsText } from "views/shared/PasswordChange";

import styles from "./styles/Login.module.scss";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { email: ctxEmail, setStep } = useOutletContext();
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState(ctxEmail);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const onKeyPress = (evt) => {
    if (evt.key === "Enter") {
      onSubmit();
    }
  }

  const onChange = (evt) => {
    setEmail(evt.target.value);
    setShowConfirmation(false);
  }

  const onSubmit = (evt) => {
    evt.preventDefault();

    setLoading(true);
    setShowConfirmation(false);

    resetPassword({ variables: { email } }).then(() => {
      setLoading(false);
      setShowConfirmation(true);
    });
  }

  const goBack = () => {
    navigate("/login");
    setStep("signin");
  }

  const token = searchParams.get("token");

  return (
    <div className={[styles.passwordReset, token ? styles.hasToken : undefined].join(" ")}>
      <h3 className={BP.HEADING}>Password Reset</h3>

      <p className={[BP.TEXT_MUTED, BP.RUNNING_TEXT, styles.mfaText].join(" ")}>
        {!token ? (
          <span>If you can't remember your password, enter your email below and click "Reset". We will send you an email with instructions on how to choose a new one.</span>
        ) : (
          <span>
            Enter your new password below to reset it.<br />
            {passwordRequirementsText}
          </span>
        )}
      </p>
      {!token ? (
        <>
          <form>
            <div>
              <Input
                autoComplete="off"
                disabled={loading}
                id="emailReset"
                key="emailReset"
                name="emailReset"
                onChange={onChange}
                onKeyPress={onKeyPress}
                placeholder="Email"
                type="text"
                value={email}
              />
            </div>
            <div>
              <Button
                disabled={!email || email.length < 5 || !email.includes("@") || !email.includes(".")}
                fill
                icon="refresh"
                intent="success"
                loading={loading}
                onClick={onSubmit}
              >
                Reset password
              </Button>
            </div>
          </form>
          {showConfirmation ? (
            <p className={[BP.TEXT_MUTED, BP.RUNNING_TEXT, styles.confirmation].join(" ")}>
              An email has been sent to <strong>{email}</strong>. Please follow the instructions included in this email to complete the password reset process.
            </p>
          ) : null}
        </>
      ) : (
        <div className={styles.passwordChange}>
          <PasswordChange
            askForCurrentPassword={false}
            loginLink
            resetToken={token}
            successMessage="Your password has been successfully changed!"
          />
        </div>
      )}
      <Button
        className={styles.back}
        disabled={loading}
        icon="arrow-left"
        onClick={goBack}
      >
        Back to login
      </Button>
    </div>
  );
}
