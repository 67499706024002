import React, {useContext} from "react";
import PropTypes from "prop-types";

import {getFormattedTableCellValue} from "./table-utilities";

import {AppContext} from "../../../AppContext";

export default function TableRowComparisonColumns({
  active,
  deltas,
  isEmptyRow,
  isPercentageOfRevenue,
  styles,
  table,
  type,
}) {
  const {currency} = useContext(AppContext);
  let currencySymbol = false;
  let percentage = false;
  let borderLeft = false;
  let decimals = 0;

  const formattingOptions = table.options[`${type}DeltaFormattingOptions`];
  if(formattingOptions) {
    currencySymbol = typeof formattingOptions.dollar !== "undefined" && formattingOptions.dollar ? currency : null;
    percentage = typeof formattingOptions.percentage !== "undefined" ? formattingOptions.percentage : false;
    borderLeft = typeof formattingOptions.borderLeft !== "undefined" ? formattingOptions.borderLeft : false;
    decimals = !!formattingOptions.decimals ? formattingOptions.decimals : 0;
  }

  return (table.options[`${type}DeltaColumn`]) ? (
    <td
      className={[
        styles.loaded,
        formattingOptions?.bold ? styles.bold : "",
        formattingOptions && borderLeft ? styles.borderLeft : "",
        active ? styles.active : "",
      ].join(" ")}
      key={type}
    >
      {!isEmptyRow ? (
        <span>
          {getFormattedTableCellValue(deltas[type], {
            decimals,
            deltaType: type,
            currencySymbol,
            percentage,
            emptyRow: isEmptyRow,
            pctOfRevenue: isPercentageOfRevenue,
          })}
        </span>
      ) : (
        <span />
      )}
    </td>
  ) : null;
}

TableRowComparisonColumns.propTypes = {
  active: PropTypes.bool.isRequired,
  deltas: PropTypes.object.isRequired,
  isEmptyRow: PropTypes.bool.isRequired,
  isPercentageOfRevenue: PropTypes.bool.isRequired,
  styles: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};
