import React from "react";
import PropTypes from "prop-types";

import {isEqual} from "lodash";

import {colors} from "lib/highcharts";
import {createCustomChartSeries} from "./chart-utilities";
import {getDaterangeLabels} from "views/dashboard/shared/data-utilities";
import {getConfig} from "../chart-config";
import {AppContext} from "../../../../AppContext";
import ActualChart from "./ActualChart";

class CustomChart extends React.Component {
  static propTypes = {
    chart: PropTypes.object.isRequired,
    customColors: PropTypes.array.isRequired,
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.chart, this.props.chart);
  }

  static contextType = AppContext

  render() {
    const {customColors = [], chart} = this.props;
    const {currency, forecastStartDate, selectedScenarioId} = this.context;

    const allColors = customColors.length ? customColors.map((color) => color.color) : colors;

    const series = (chart.lines || []).map((line) => createCustomChartSeries({
      account: line.account,
      account_id: line.account_id,
      column_type: chart.column_type,
      daterange: chart.daterange,
      forecastStartDate,
      forecastStartDateSnapshot: line.reference_snapshot ? line.reference_snapshot.forecast_start_date : null,
      name: line.name,
      reference_snapshot_id: line.reference_snapshot_id,
      report_period: chart.report_period,
      scenarioId: line.scenario_id || selectedScenarioId,
      type: ["AREARANGE", "COMBO"].includes(chart.type) ? line.type : null,
    }));

    const categories = getDaterangeLabels({forecastStartDate, daterange: chart.daterange, column_type: chart.column_type, report_period: chart.report_period});

    series.forEach((serie, i) => {
      if(chart.type !== "LINE" && serie.dashStyle) delete serie.dashStyle;
      serie.color = customColors.length && chart.lines[i].color_id ? customColors.find((color) => color.id === chart.lines[i].color_id).color : allColors[i % allColors.length];
    });

    const config = getConfig({
      currency,
      categories,
      colors: allColors,
      column_type: chart.column_type,
      options: chart.options,
      series,
      type: chart.type.toLowerCase(),
    });

    return !!config ? (
      <ActualChart
        chart={chart}
        config={config}
      />
    ) : null;
  }
}

export default CustomChart;


