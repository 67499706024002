import React from "react";
import PropTypes from "prop-types";

import {useQuery} from "@apollo/client";

import Select from "components/Select";

import {AppliesToOptions} from "./utilities";
import {ModelPeriod} from "../utilities";

import {ALL_SCENARIOS_QUERY} from "../../../../graphql";

export default function AppliesToSelector({
  onChange,
  scenarioId, // This is the active scenario in the global selector, not the actual scenario that's saved on the model
  value,
}) {
  const {data: {scenarios}} = useQuery(ALL_SCENARIOS_QUERY);

  const options = [{
    period: ModelPeriod.ALL,
    scenarioId: null,
    text: "Actuals + Forecast",
    value: AppliesToOptions.ALL,
  }, {
    period: ModelPeriod.FORECASTS,
    scenarioId: null,
    text: "Forecast (all scenarios)",
    value: AppliesToOptions.FORECAST,
  }, {
    period: ModelPeriod.FORECASTS,
    scenarioId,
    text: `Current Scenario (${scenarios.find((scenario) => scenario.id === scenarioId).name})`,
    value: AppliesToOptions.CURRENT_SCENARIO,
  }];

  const handleChange = ({target: {value}}) => {
    const selectedOption = options.find((option) => option.value === value);
    onChange((({period, value}) => ({period, value}))(selectedOption));
  };

  return (
    <Select
      label="Applies to"
      name="period"
      onChange={handleChange}
      options={options}
      value={value}
    />
  );
}

AppliesToSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  scenarioId: PropTypes.string,
  value: PropTypes.string.isRequired,
};
