import {gql} from "@apollo/client";

export const changePassword = gql`
  mutation changePassword($current: String, $new: String!, $resetToken: String, $inviteToken: String) {
    changePassword(current: $current, new: $new, resetToken: $resetToken, inviteToken: $inviteToken)
  }
`;

export const IS_EMAIL_LOGIN_ENABLED_QUERY = gql `
  query isEmailLoginEnabled {
    isEmailLoginEnabled
  }
`;
