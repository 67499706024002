import {gql} from "@apollo/client";

export const ALL_SNAPSHOTS_QUERY = gql`
  query AllSnapshotsQuery {
    snapshots {
      id
      name
      description
      archive
      created_date
      table_rows {
        id
        table {
          id
          name
        }
      }
      table_columns {
        id
        table {
          id
          name
        }
      }
      chart_lines {
        id
        chart {
          id
          name
        }
      }
    }
  }
`;

export const updateSnapshot = gql`
  mutation updateSnapshot($snapshot: JSON!) {
    updateSnapshot(snapshot: $snapshot) {
      id
      name
      description
      archive
      created_date
      table_rows {
        id
        table {
          id
          name
        }
      }
      table_columns {
        id
        table {
          id
          name
        }
      }
      chart_lines {
        id
        chart {
          id
          name
        }
      }
    }
  }
`;

export const archiveSnapshot = gql`
  mutation archiveSnapshot($id: String!) {
    archiveSnapshot(id: $id) {
      id
    }
  }
`;

export const unarchiveSnapshot = gql`
  mutation unarchiveSnapshot($id: String!) {
    unarchiveSnapshot(id: $id) {
      id
    }
  }
`;

export const removeSnapshot = gql`
  mutation removeSnapshot($id: String!) {
    removeSnapshot(id: $id) {
      id
    }
  }
`;

export const createSnapshot = gql`
  mutation createSnapshot($snapshot: JSON!) {
    createSnapshot(snapshot: $snapshot) {
      id
      name
      description
      archive
      created_date
      table_rows {
        id
        table {
          id
          name
        }
      }
      table_columns {
        id
        table {
          id
          name
        }
      }
      chart_lines {
        id
        chart {
          id
          name
        }
      }
    }
  }
`;
