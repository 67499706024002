import React, { useContext, useReducer, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Card from "components/Card";
import Loading from "components/Loading";
import TableHeader from "components/AccountsTable/TableHeader";

import Header from "../../operating-model/OperatingModelHeader";
import Properties from "./Properties";
import Rows from "./Rows";
import ActionsFooter from "./ActionsFooter";
import { actionTypes, initialState, reducer } from "./reducer";
import { transformWorksheetOnLoad, usePrevious } from "./utilities";

import { AppContext } from "../../../AppContext";

import { ALL_SCENARIOS_QUERY } from "../../../graphql";
import { WORKSHEET_QUERY } from "./graphql";

import Sidebar from "./Sidebar";

import styles from "./styles.module.scss";

export default function Worksheet() {
  const { slug } = useParams();
  const { data: { scenarios } } = useQuery(ALL_SCENARIOS_QUERY);
  const { forecastStartDate, user } = useContext(AppContext);
  const { loading: worksheetLoading, data: { worksheet } = {} } = useQuery(WORKSHEET_QUERY, { variables: { slug }, skip: !slug });
  const prevWorksheet = usePrevious(worksheet);
  const prevSlug = usePrevious(slug);
  const [state, dispatch] = useReducer(reducer, initialState({
    scenarioId: scenarios[0].id,
    worksheet,
    year: forecastStartDate.split("-").shift(),
  }));

  useEffect(() => {
    if ((!prevWorksheet || prevSlug !== slug || worksheet !== prevWorksheet) && worksheet) dispatch({ type: actionTypes.updateWorksheet, payload: { worksheet: transformWorksheetOnLoad(worksheet) } });
  }, [prevWorksheet, worksheet, prevSlug, slug]);

  if (worksheetLoading) return <Loading />;

  const handleAutocompleteHighlight = (slug) => dispatch({ type: actionTypes.autocompleteHighlight, payload: { slug } });
  const handleRowChange = (row, initiator) => dispatch({ type: actionTypes.updateRow, payload: { row, initiator } });
  const handlePropertyChange = (properties) => dispatch({ type: actionTypes.updateProperties, payload: { properties } });
  const handleMoveRow = (index, newIndex) => dispatch({ type: actionTypes.moveRow, payload: { index, newIndex } });

  const classes = [styles.worksheet];
  if (state.editingRow) classes.push(styles.sidebarOpen);

  return (
    <div className={classes.join(" ")}>
      <header className={styles.headerWrapper}>
        <Header
          leftSection={(<Properties onChange={handlePropertyChange} worksheet={state.worksheet} />)}
          onScenarioChange={(scenarioId) => dispatch({ type: actionTypes.changeScenarioId, payload: { scenarioId } })}
          onYearChange={(year) => dispatch({ type: actionTypes.changeYear, payload: { year } })}
          scenarioId={state.scenarioId}
          showChecks={false}
          showRefreshOptions={false}
          year={state.year}
        />
      </header>
      <Card className={styles.tableContainer}>
        <div className={styles.viewWrapper}>
          <TableHeader
            noShade
            selectedScenario={scenarios.find((scenario) => scenario.id === state.scenarioId)}
            sticky={false}
            year={state.year}
          />
          <Rows
            autocompleteHighlight={state.autocompleteHighlight}
            dispatch={dispatch}
            editingRow={state.editingRow}
            onCellClick={(row, isActual) => () => user.role !== "VIEW" ? dispatch({ type: actionTypes.cellClick, payload: { row, isActual } }) : null}
            onMoveRow={handleMoveRow}
            onRowChange={handleRowChange}
            scenarioId={state.scenarioId}
            worksheet={state.worksheet}
            year={state.year}
          />
        </div>
      </Card>
      <ActionsFooter
        dispatch={dispatch}
        modelsInvalid={!!state.invalidModels.length}
        worksheet={state.worksheet}
      />
      {!!state.editingRow ? (
        <Sidebar
          actualsClicked={state.actualsClicked === true}
          dispatch={dispatch}
          onAutocompleteHighlight={handleAutocompleteHighlight}
          onRowChange={handleRowChange}
          row={state.editingRow}
          scenarioId={state.scenarioId}
          worksheet={state.worksheet}
        />
      ) : null}
    </div>
  );
}
