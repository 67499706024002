import React, {useContext, useState} from "react";
import PropTypes from "prop-types";

import moment from "moment";

import {useMutation} from "@apollo/client";

import Button from "components/Button";
import {Classes, Dialog, Tab, Tabs} from "@blueprintjs/core";

import RaisesScheduleTab from "./RaiseScheduleTab";

import {INITIAL_LOAD_QUERY, updateTenant} from "../../../../graphql";

import {AppContext} from "../../../../AppContext";

import styles from "./styles.module.scss";

export default function RaiseSchedule({
  isOpen,
  onClose,
}) {
  const {forecastStartDate, user} = useContext(AppContext);
  const [saving, setSaving] = useState(false);
  const [raises, setRaises] = useState(user.tenant.hiring || []);
  const [selectedTab, setSeclectedTab] = useState("UPCOMING");
  const [updateTenantMutation] = useMutation(updateTenant);

  const raiseDateMoments = raises.map((raise) => moment(raise.date, "YYYY-MM-DD"));
  const mostRecentRaiseDate = raiseDateMoments.length ? moment.max(raiseDateMoments) : moment(forecastStartDate);

  const saveRaiseSchedule = () => {
    setSaving(true);
    const newTenant = {
      ...user.tenant,
      hiring: raises,
    };
    updateTenantMutation({
      variables: {tenant: newTenant},
      refetchQueries: [{query: INITIAL_LOAD_QUERY}],
    }).then(() => {
      setSaving(false);
      onClose();
    });
  };

  const addRaise = () => {
    setRaises([
      ...raises,
      {
        amount: 0,
        date: mostRecentRaiseDate.add(1, "year").format("YYYY-MM-DD"),
      },
    ]);
  };

  const handleRaiseUpdate = (index) => ({target: {name, value}}) => {
    if(value !== null) {
      const newRaises = [...raises];
      newRaises[index] = {
        ...raises[index],
        [name]: value,
      };
      setRaises(newRaises);
    }
  };

  const handleRaiseDelete = (index) => () => {
    const newRaises = [...raises];
    newRaises.splice(index, 1);
    setRaises(newRaises);
  };

  const handleClose = () => {
    setRaises(user.tenant.hiring || []);
    onClose();
  };

  const upcomingRaises = raises.filter((raise) => moment(raise.date, "YYYY-MM-DD").isSameOrAfter(moment(forecastStartDate, "YYYY-MM")));
  const pastRaises = raises.filter((raise) => moment(raise.date, "YYYY-MM-DD").isBefore(moment(forecastStartDate, "YYYY-MM")));

  return (
    <Dialog
      canOutsideClickClose={false}
      className={Classes.LARGE}
      icon="th"
      inline
      isOpen={isOpen}
      onClose={handleClose}
      title="Schedule Raises"
    >
      <div className={Classes.DIALOG_BODY}>
        <Tabs
          animate
          className={styles.tabs}
          id="raises-tabs"
          large
          onChange={setSeclectedTab}
          selectedTabId={selectedTab}
        >
          <Tab
            id="UPCOMING"
            panel={<RaisesScheduleTab
              onRaiseDelete={handleRaiseDelete}
              onRaiseUpdate={handleRaiseUpdate}
              period="UPCOMING"
              raises={upcomingRaises}
            />}
            title="Upcoming Raises"
          />
          <Tab
            id="PAST"
            panel={<RaisesScheduleTab
              onRaiseDelete={handleRaiseDelete}
              onRaiseUpdate={handleRaiseUpdate}
              period="PAST"
              raises={pastRaises}
            />}
            title="Past Raises"
          />
        </Tabs>
        <Button
          border
          minimal
          onClick={addRaise}
          size="small"
          text="Add raise..."
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            disabled={saving}
            onClick={handleClose}
            text="Cancel"
          />
          <Button
            intent="success"
            loading={saving}
            onClick={saveRaiseSchedule}
            text="Save And Close"
          />
        </div>
      </div>
    </Dialog>
  );
}

RaiseSchedule.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
