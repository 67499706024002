import { Icon } from "@blueprintjs/core";

import styles from "./styles.module.scss";

const Blocker = (props) => {
	const { text, children } = props;

  return (
    <div className={styles.container}>
      <Icon icon="ban-circle" size={128} className={styles.errorIcon} />
      { !!text && <p>{text}</p> }
      { props.children }
    </div>
  );
};

export default Blocker;
