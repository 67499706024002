import React from "react";
import PropTypes from "prop-types";

import {FormGroup} from "@blueprintjs/core";

import FormFieldSwitch from "../../shared/fields/switch";

const defaultNames = {
  valueDeltaColumnName: "$",
  percentageDeltaColumnName: "%",
};

export default function ComparisonColumn({formGroupClass, onChange, switchClass, value}) {
  let {showComparison} = value;
  if(typeof showComparison === "undefined") showComparison = false;

  const generateOnChangeEvent = (evt) => {
    onChange({
      target: {
        name: "options",
        value: {
          ...value,
          showComparison: evt.target.value,
          valueDeltaColumn: value.valueDeltaColumn || true,
          percentageDeltaColumn: value.percentageDeltaColumn || true,
          valueDeltaColumnName: value.valueDeltaColumnName || defaultNames.valueDeltaColumnName,
          percentageDeltaColumnName: value.percentageDeltaColumnName || defaultNames.percentageDeltaColumnName,
          valueDeltaFormattingOptions: value.valueDeltaFormattingOptions || {},
          percentageDeltaFormattingOptions: value.percentageDeltaFormattingOptions || {percentage: true},
        },
      },
    });
  };

  return (
    <FormGroup
      className={formGroupClass}
      label="Comparison"
      labelFor="showComparison"
    >
      <FormFieldSwitch
        checked={showComparison}
        className={switchClass}
        label="Show Comparison"
        name="showComparison"
        onChange={generateOnChangeEvent}
      />
    </FormGroup>
  );
}

ComparisonColumn.propTypes = {
  formGroupClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  switchClass: PropTypes.string,
  value: PropTypes.object.isRequired,
};
