import React from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import {Dialog, Icon, Position, Switch, Tooltip} from "@blueprintjs/core";
import FancySelect from "components/FancySelect";
import Errors from "components/Errors";
import Button from "components/Button";

import {createAccount, updateAccount} from "./graphql";

import {INITIAL_LOAD_QUERY} from "../../../graphql";
import Delete from "./Delete";
import {FLIGHTPATH_CHOOSABLE, FLIGHTPATH_PAYROLL_CHOOSABLE, qbToFFTypesHash} from "shared/utilities/types";
import {findAccountByType} from "shared/utilities/account-utilities";
import {createDetailTypesArray, findAccountByIntegrationId, getAllChildrenOfAccount} from "./coa-utilities";
import {addAccountToCache, updateAccountInCache} from "apollo-config/local-state/accounts";
import {withHooks} from "shared/hooks/hoc";

import styles from "./styles.module.scss";

const memoizedFunctions = {
  createDetailTypesArray: memoize((type) => createDetailTypesArray(type)),
  findAccountByIntegrationId: memoize((accounts, integration_id, company_id) => findAccountByIntegrationId(accounts, integration_id, company_id)),
  findAccountByType: memoize((accounts, type) => findAccountByType(accounts, type)),
  getAllChildrenOfAccount: memoize((accounts, referenceAccount, accountId, childrenArray) => getAllChildrenOfAccount(accounts, referenceAccount, accountId, childrenArray)),
};

class ChartOfAccountsCreateUpdate extends React.Component {
  static createAccountState = ({account, allFlightpathAccounts, operation}) => {
    const type = (account?.type) ? account.type : "Expenses";
    const typeAccount = memoizedFunctions.findAccountByType(allFlightpathAccounts, type);
    const parentAccountId = (operation === "update" && account?.parent) ? account.parent.id : typeAccount.id;
    return {
      accounting_account: (account?.accounting_account) ? account.accounting_account.integration_id : "None",
      accounting_account_company_id: (account?.accounting_account) ? account.accounting_account.company_id : null,
      description: (account?.description) ? account.description : "",
      detail_type: (account?.detail_type) ? account.detail_type : "None",
      negative: (account && typeof account.negative === "boolean") ? account.negative : false,
      ignore: (account && typeof account.ignore === "boolean") ? account.ignore : false,
      errors: [],
      id: (account?.id) ? account.id : null,
      isOpen: account !== null,
      name: (account?.name) ? account.name : "",
      parent: parentAccountId,
      saving: false,
      showRemoveModal: false,
      type,
      typeAccount,
    };
  }

  static propTypes = {
    account: PropTypes.object,
    accountingAccounts: PropTypes.array.isRequired,
    allFlightpathAccounts: PropTypes.array.isRequired,
    createAccount: PropTypes.func.isRequired,
    initialLoadQuery: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    operation: PropTypes.string,
    toggleOperationActive: PropTypes.func.isRequired,
    updateAccount: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if((!prevState.isOpen && !!nextProps.account && nextProps.operation !== "delete") || (prevState.isOpen && !nextProps.account)) {
      return ChartOfAccountsCreateUpdate.createAccountState(nextProps);
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = ChartOfAccountsCreateUpdate.createAccountState(props);
  }

  changeAccountName = (event) => this.setState({name: event.target.value})

  typeChange = (event) => {
    const type = event.target.value;
    const parentAccount = memoizedFunctions.findAccountByType(this.props.allFlightpathAccounts, type);
    const newState = {
      type,
      typeAccount: parentAccount,
      parent: parentAccount.id,
    };

    if(this.state.detail_type !== "Payroll Account") newState.detail_type = "None";

    this.setState(newState);
  }

  detailTypeChange = (event) => this.setState({detail_type: event.target.value})

  actualDataChange = (event) => this.setState({negative: event.target.value})

  handleIgnoredChange = () => this.setState({ignore: !this.state.ignore})

  parentChange = (value) => this.setState({parent: value.key})

  changeAccountingAccount = ({key: integration_id, company_id, text}) => {
    const {initialLoadQuery: {companies}} = this.props;
    const company = companies.find((item) => item.id === company_id);
    const stateMutation = {
      accounting_account: integration_id,
      accounting_account_company_id: company_id,
      name: (text !== "None") ? text : this.state.name,
    };

    const provider = company?.provider ?? "QUICKBOOKS";
    if(text !== "None" && stateMutation.accounting_account && !this.state.id) {
      // Grab accounting account
      const accountingAccount = memoizedFunctions.findAccountByIntegrationId(this.props.accountingAccounts, integration_id, company_id);
      // Grab accounting type and change type
      if(accountingAccount?.type) {
        const type = (provider === "QUICKBOOKS") ? qbToFFTypesHash[accountingAccount.type] : accountingAccount.type;

        const parentAccount = memoizedFunctions.findAccountByType(this.props.allFlightpathAccounts, type);

        stateMutation.type = type;
        stateMutation.typeAccount = parentAccount;
        stateMutation.detail_type = "None";
        stateMutation.parent = parentAccount.id;
      }
    }
    this.setState(stateMutation);
  }

  changeAccountDescription = (event) => this.setState({description: event.target.value});

  onOperation = () => {
    this.setState({saving: true});
    const account = {
      name: this.state.name,
      type: this.state.type,
      parent_id: this.state.parent,
      negative: ((this.state.negative === "true" || this.state.negative) && this.state.negative !== "false"),
      ignore: this.state.ignore === "true" || this.state.ignore === true,
    };

    account.detail_type = (this.state.detail_type !== "None") ? this.state.detail_type : null;
    if(this.state.id) account.id = this.state.id;
    account.integration_id = (this.state.accounting_account !== "None") ? this.state.accounting_account : null;
    account.company_id = (this.state.accounting_account !== "None") ? this.state.accounting_account_company_id : null;
    account.description = this.state.description.length > 1 ? this.state.description : null;

    this.props.toggleOperationActive();
    const operation = (this.props.operation === "create") ? this.props.createAccount : this.props.updateAccount;

    operation({
      variables: {account},
    })
    .then(({data: {createAccount, updateAccount}}) => {
      if(this.props.operation === "create") {
        addAccountToCache(createAccount);
      } else {
        updateAccountInCache(updateAccount);
      }
      const stateMutation = {saving: false};
      this.setState(stateMutation);
      this.props.onClose();
      this.props.toggleOperationActive();
    }).catch((error) => {
      console.log(error);
      this.setState({saving: false});
      // console.log("There was an error with the query", error);
      // TO:DO update server code to start handling error messages more gracefully
      let errorMessage = error.message.replace("GraphQL error: ", "");
      if(errorMessage !== "Cannot remove detail type Payroll Account because this account is in use by a Team.") {
        errorMessage = "Account saving failed. Check required fields.";
      }
      this.props.toggleOperationActive();
      this.setState({errors: [errorMessage]});
    });
  }

  toggleRemoveModal = () => this.setState({showRemoveModal: !this.state.showRemoveModal});

  setErrorMessage = (errorMessage) => this.setState({errors: [errorMessage]});

  render() {
    const {account: passedAccount, accountingAccounts, allFlightpathAccounts, onClose, operation, toggleOperationActive, initialLoadQuery: {companies}} = this.props;

    const companiesById = {};
    for(const company of companies) {
      companiesById[company.id] = company;
    }

    const title = (operation === "create") ? "Create Account" : "Update Account";
    const icon = (operation === "create") ? "add" : "annotation";

    const account = passedAccount || {accounting_account: null};
    const isParent = (account.children?.length > 0);
    const types = (account.detail_type === "Payroll Account") ? FLIGHTPATH_PAYROLL_CHOOSABLE : FLIGHTPATH_CHOOSABLE;
    const children = (this.state.typeAccount) ? getAllChildrenOfAccount(allFlightpathAccounts, account, this.state.typeAccount.id, []) : [];
    const detailTypes = memoizedFunctions.createDetailTypesArray(this.state.type);

    return (
      <Dialog
        canOutsideClickClose={false}
        className={styles.modal}
        icon={icon}
        isOpen={!!passedAccount}
        onClose={onClose}
        title={title}
      >
        <div className="bp5-dialog-body company-settings-dialog">
          <Errors messages={this.state.errors}/>

          <div className="bp5-form-group">
            <h4 className="bp5-heading">Mapped Account</h4>
            <div className="bp5-form-content">
              <div className="bp5-large bp5-fill">
                <FancySelect
                  disabled={this.state.ignore}
                  fill
                  items={
                    [{text: "None", key: "None", company_id: null}]
                    .concat(account.accounting_account ? [{
                      text: account.accounting_account.name,
                      label: `${companiesById[account.accounting_account.company_id].name ?? ""}`,
                      key: account.accounting_account.integration_id,
                      company_id: account.accounting_account.company_id,
                    }] : [])
                    .concat(accountingAccounts.map((account) => ({
                      text: account.name,
                      label: `${companiesById[account.company_id].name ?? ""}`,
                      key: account.integration_id,
                      company_id: account.company_id,
                    })))
                  }
                  onSelect={this.changeAccountingAccount}
                  value={this.state.accounting_account}
                />
              </div>
            </div>
          </div>

          <div className="bp5-form-group m-t">
            <h4 className="bp5-heading">Name <span className={`${styles.notBold} bp5-text-muted`}>(required)</span></h4>
            <input
              autoComplete="off"
              className="bp5-input bp5-large .modifier"
              dir="auto"
              onChange={this.changeAccountName}
              placeholder="Name"
              type="text"
              value={this.state.name}
            />
          </div>

          <div className="bp5-form-group m-t">
            <div className={styles.typeHeading}>
              <h4 className={`${styles.accountType} bp5-heading`}>Account Type <span className={`${styles.notBold} bp5-text-muted`}>(required)</span></h4>
              {(isParent) ? (
                <Tooltip
                  content={<><p>If you need to change the account type for a parent account, </p><p>move all of the account's children under a different account.</p></>}
                  position={Position.BOTTOM}
                >
                  <Icon className={`${styles.helpIcon}`} icon="help" iconSize={16} />
                </Tooltip>
              ) : null}
            </div>

            <div className="bp5-form-content">
              <div className={`bp5-select bp5-large bp5-fill ${(isParent) ? "bp5-disabled" : ""}`}>
                <select
                  disabled={isParent || this.state.ignore}
                  onChange={this.typeChange}
                  value={this.state.type}
                >
                  {types.map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="bp5-form-group m-t">
            <h4 className="bp5-heading">Account Detail Type</h4>
            <div className="bp5-form-content">
              <div className={`bp5-select bp5-large bp5-fill ${(account.type !== "Bank Accounts") ? "bp5-disabled" : ""}`}>
                <select
                  disabled={this.state.ignore || (account.type !== "Bank Accounts" && this.state.type !== "Bank Accounts")}
                  onChange={this.detailTypeChange}
                  value={this.state.detail_type}
                >
                  {detailTypes.map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="bp5-form-group m-t">
            <h4 className="bp5-heading">Account Parent <span className={`${styles.notBold} bp5-text-muted`}>(required)</span></h4>
            <div className="bp5-form-content">
              <div className="bp5-large bp5-fill">
                <FancySelect
                  disabled={this.state.ignore}
                  fill
                  items={children.map((c) => ({
                    key: c.id,
                    text: c.name,
                    label: c.statement_type,
                    level: c.level,
                  }))}
                  onSelect={this.parentChange}
                  value={this.state.parent}
                />
              </div>
            </div>
          </div>

          <div className="bp5-form-group m-t">
            <h4 className="bp5-heading">Description</h4>
            <input
              autoComplete="off"
              className="bp5-input bp5-large .modifier"
              dir="auto"
              onChange={this.changeAccountDescription}
              placeholder="Account Description"
              type="text"
              value={this.state.description}
            />
          </div>

          <div className={`${styles.advancedSettings} m-t-md`}>
            <div className={styles.advancedSettingsTitleContainer}>
              <h5 className={`${styles.advancedSettingsTitle} bp5-heading`}>Advanced Settings</h5>
            </div>
          </div>

          <div className="bp5-form-group m-t">
            <div className={styles.typeHeading}>
              <h4 className="bp5-heading">Actual Data</h4>
              <Tooltip
                content={<>Multiply the actual data for this account by -1. This is useful if you need<br />to represent an expense in your books as (negative) revenue, or vice-versa.</>}
                position={Position.BOTTOM}
              >
                <Icon className={`${styles.helpIcon}`} icon="help" iconSize={16} />
              </Tooltip>
            </div>
            <div className="bp5-form-content">
              <div className={`bp5-select bp5-large bp5-fill`}>
                <select
                  disabled={this.state.ignore}
                  onChange={this.actualDataChange}
                  value={this.state.negative}
                >
                  <option value={false}>From Accounting Sync</option>
                  <option value>From Accounting Sync x (-1)</option>
                </select>
              </div>
            </div>
            <div className={`${styles.ignoreAccount} ${styles.typeHeading}`}>
              <h4 className="bp5-heading">Ignore Account</h4>
              <Switch
                checked={this.state.ignore}
                onChange={this.handleIgnoredChange}
              />
              <Tooltip
                content="If turned on, this setting will make this account ignored everywhere in the app."
                position={Position.BOTTOM}
              >
                <Icon className={`${styles.helpIcon}`} icon="help" iconSize={16} />
              </Tooltip>
            </div>
          </div>

        </div>

        <div className="bp5-dialog-footer">
          <div className={["bp5-dialog-footer-actions", styles.bottomButtons].join(" ")}>
            <div className={styles.left}>
              {(operation === "update" && !isParent) ? (
                <Button
                  intent="danger"
                  onClick={this.toggleRemoveModal}
                  text="Delete Account"
                />
              ) : null}
            </div>
            <div className={styles.right}>
              <Button
                onClick={onClose}
                text="Cancel"
              />
              <Button
                disabled={this.state.saving}
                intent="success"
                loading={this.state.saving}
                onClick={this.onOperation}
                text="Save"
              />
            </div>
          </div>
        </div>
        {this.state.showRemoveModal ? (
          <Delete
            account={passedAccount}
            onClose={this.toggleRemoveModal}
            onRemoved={onClose}
            setErrorMessage={this.setErrorMessage}
            toggleOperationActive={toggleOperationActive}
          />
        ) : null}
      </Dialog>
    );
  }
}

const ChartOfAccountsCreateUpdateWithQueries = compose(
  graphql(INITIAL_LOAD_QUERY, {name: "initialLoadQuery"}),
  graphql(createAccount, {name: "createAccount"}),
  graphql(updateAccount, {name: "updateAccount"}),
)(ChartOfAccountsCreateUpdate);

export default withHooks(ChartOfAccountsCreateUpdateWithQueries, ["useAllFlightpathAccounts"]);
