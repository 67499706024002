import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";

import Button from "components/Button";
import Loading from "components/Loading";
import Sidebar from "components/Sidebar";
import FormattingOptions from "components/FormattingOptions";


import FormFieldName from "../../shared/fields/name";
import FormFieldSnapshot from "../../shared/fields/snapshot";
import FormFieldScenario from "../../shared/fields/scenario";
import FormFieldReportPeriod from "../../shared/fields/reportPeriod";
import FormFieldDateRange from "../../shared/fields/dateRange";
import SnapshotInfosPopover from "./SnapshotInfosPopover";

import {AppContext} from "../../../../AppContext";

import {ALL_SCENARIOS_QUERY} from "../../../../graphql";

import {createInitialDateRange} from "shared/utilities/date-utilities";

import styles from "./styles.module.scss";

class SidebarColumn extends React.Component {
  static propTypes = {
    allScenariosQuery: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onCloseSidebar: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    snapshots: PropTypes.array.isRequired,
    snapshotsLoading: PropTypes.bool.isRequired,
    table: PropTypes.object.isRequired,
  }

  static contextType = AppContext

  onChangeLocal = (evt) => {
    const {
      allScenariosQuery: {scenarios},
      columns,
      index,
      onChange,
      snapshots,
    } = this.props;

    let value;
    if(evt.target.value === "0") {
      value = null;
    } else {
      value = evt.target.value;
    }

    const changes = [{
      key: evt.target.name,
      value,
    }];

    if(evt.target.name === "reference_snapshot_id") {
      const columnClone = {
        ...columns[index],
      };

      if(changes[0].value) changes[0].value = changes[0].value;
      // The snapshot that has just been selected, and its accounts
      const selectedSnapshot = !!value ? snapshots.find((snapshot) => snapshot.id === evt.target.value) : null;
      const newSnapshotScenarios = selectedSnapshot ? selectedSnapshot.scenarios : scenarios;

      // The scenarios belonging to the previously selected snapshot (or current forecast if it's the case)
      const previouslySelectedSnapshot = columnClone.reference_snapshot_id ? snapshots.find((snapshot) => snapshot.id === columnClone.reference_snapshot_id) : null;
      const previousScenarios = previouslySelectedSnapshot ? previouslySelectedSnapshot.scenarios : scenarios;

      // The previously selected scenario
      const previouslySelectedScenario = previousScenarios.find((scenario) => scenario.id === columnClone.scenario_id);
      const previouslySelectedScenarioId = previouslySelectedSnapshot ? previouslySelectedScenario.reference_id : previouslySelectedScenario.id;

      // Newly selected scenario id
      const newlySelectedScenario = newSnapshotScenarios.find((scenario) => scenario.reference_id === previouslySelectedScenarioId || scenario.id === previouslySelectedScenarioId);
      const scenario_id = (newlySelectedScenario) ? newlySelectedScenario.id : null;
      changes.push({
        key: "scenario_id",
        value: scenario_id,
      });
    }

    // Change date range if report period changes or if column type changes
    if((evt.target.name === "report_period" && value !== "CUSTOM")) {
      const {forecastStartDate} = this.context;

      const latestReportPeriod = value;
      const mutation = {
        ...columns[index].daterange,
        dates: createInitialDateRange(
          forecastStartDate,
          latestReportPeriod,
          columns[index].daterange.monthsAgo,
          columns[index].daterange.monthsAhead,
        ),
      };

      changes.push({
        key: "daterange",
        value: mutation,
      });
    }

    onChange(changes);
  }

  changeDateRange = (daterange) => {
    const {onChange} = this.props;
    onChange([{
      key: "daterange",
      value: daterange,
    }]);
  }

  render() {
    const {allScenariosQuery: {scenarios}, index, columns, onCloseSidebar, onDelete, snapshots, snapshotsLoading} = this.props;
    const {currency, years} = this.context;
    const column = columns[index];

    const selectedScenarios = column.reference_snapshot_id ? snapshots.find((snapshot) => snapshot.id === column.reference_snapshot_id).scenarios : scenarios;

    return (
      <Sidebar className={styles.columnSidebar} onClose={onCloseSidebar} title="Column Properties">
        {!scenarios ? (
          <Loading size={100} />
        ) : (
          <form className={styles.sidebarForm}>
            <div className={[styles.formGroupWithInfosWrapper, styles.hidden].join(" ")}>
              <FormFieldName
                formGroupClass={styles.formGroup}
                label="Column Name"
                onChange={this.onChangeLocal}
                value={column.name}
              />
            </div>
            <div className={styles.formGroupWithInfosWrapper}>
              <FormFieldSnapshot
                label="Snapshot"
                loading={snapshotsLoading}
                onChange={this.onChangeLocal}
                snapshots={snapshots}
                value={column.reference_snapshot_id === null ? "0" : column.reference_snapshot_id}
              />
              <SnapshotInfosPopover snapshotId={column.reference_snapshot_id} />
            </div>
            <div className={[styles.formGroupWithInfosWrapper, styles.hidden].join(" ")}>
              <FormFieldScenario
                formGroupClass={styles.formGroup}
                label="Scenario"
                onChange={this.onChangeLocal}
                scenarios={selectedScenarios}
                value={column.scenario_id === null ? 0 : column.scenario_id}
              />
            </div>
            <div className={[styles.formGroupWithInfosWrapper, styles.hidden].join(" ")}>
              <FormFieldReportPeriod
                formGroupClass={styles.formGroup}
                onChange={this.onChangeLocal}
                value={column.report_period}
              />
            </div>
            <FormFieldDateRange
              changeDateRange={this.changeDateRange}
              column_type="MONTHS"
              daterange={column.daterange}
              direction="VERTICAL"
              formGroupClass={styles.dateRangePicker}
              onChange={this.onChangeLocal}
              report_period={column.report_period}
              years={years}
            />
            <FormattingOptions
              currency={currency}
              label="Formatting Options"
              name="formatting"
              onChange={this.onChangeLocal}
              type="column"
              value={column.formatting}
            />
          </form>
        )}
        <div className={styles.sidebarButtons}>
          <Button
            intent="danger"
            onClick={onDelete}
            size="small"
            text="Delete Column"
          />
          <Button
            onClick={onCloseSidebar}
            size="small"
            text="Close"
          />
        </div>
      </Sidebar>
    );
  }
}

export default compose(
  graphql(ALL_SCENARIOS_QUERY, {name: "allScenariosQuery"}),
)(SidebarColumn);
