import {gql} from "@apollo/client";
import {ACCOUNT_FRAGMENT} from "../../../shared/graphql/fragments";

export const CREATE_CHART_MUTATION = gql`
  mutation createChart($chart: JSON!, $dashboardId: String!) {
    createChart(chart: $chart, dashboardId: $dashboardId) {
      layout
      chart {
        id
        name
        daterange
        column_type
        type
        options
        report_period
        lines {
          entries
          name
          account_id
          color_id
          reference_snapshot_id
          scenario_id
          type
          reference_snapshot {
            id
            name
            forecast_start_date
          }
          account {
            ...AccountProps
          }
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const COPY_CHART_MUTATION = gql`
  mutation copyChart($id: String!, $dashboardId: String!) {
    copyChart(id: $id, dashboardId: $dashboardId) {
      layout
      chart {
        id
        name
        daterange
        column_type
        type
        options
        report_period
        lines {
          id
          entries
          name
          account_id
          color_id
          reference_snapshot_id
          scenario_id
          type
          reference_snapshot {
            id
            name
            forecast_start_date
          }
          account {
            ...AccountProps
          }
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const UPDATE_CHART_MUTATION = gql`
  mutation updateChart($chart: JSON!) {
    updateChart(chart: $chart) {
      id
      name
      daterange
      column_type
      type
      options
      report_period
      lines {
        id
        entries
        name
        account_id
        color_id
        reference_snapshot_id
        scenario_id
        type
        reference_snapshot {
          id
          name
          forecast_start_date
        }
        account {
          ...AccountProps
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const REMOVE_CHART_MUTATION = gql`
  mutation removeChart($id: String!) {
    removeChart(id: $id)
  }
`;

export const CHART_QUERY = gql`
  query chartQuery($id: String!) {
    chart(id: $id) {
      id
      name
      daterange
      column_type
      type
      options
      report_period
      lines {
        id
        entries
        name
        account_id
        color_id
        reference_snapshot_id
        scenario_id
        type
        reference_snapshot {
          id
          name
          forecast_start_date
        }
        account {
          ...AccountProps
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const ALL_SNAPSHOTS_QUERY = gql`
  query allSnapshotsQuery {
    snapshotsWithAccountsAndScenarios {
      id
      name
      description
      archive
      forecast_start_date
      plan_groups {
        id
        interval
        product_id
        product {
          id
          name
        }
      }
      products {
        id
        name
      }
      scenarios {
        id
        name
        reference_id
      }
      teams {
        id
        name
      }
      accounts {
        id
        name
        statement_type
        snapshot_id
        reference_id
        children
        product_id
        plan_group_id
        type
        parent
      }
      created_date
    }
  }
`;
