import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import Button from "components/Button";
import Card from "components/Card";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { CURRENT_USER_QUERY, removeTenant, resetTenant } from "./graphql";

import NewTenantDialog from "./NewTenantDialog";
import TypedConfirmation from "../components/TypedConfirmation";
import Login from "./Login";
import TopBar from "./TopBar";

import styles from "./styles/Auth.module.scss";

function Auth() {
	const {
		loading: userLoading,
		data: userData,
		refetch: userRefetch,
		error: userError,
	} = useQuery(CURRENT_USER_QUERY);

	const [removeTenantMutation] = useMutation(removeTenant);
	const [resetTenantMutation] = useMutation(resetTenant);

	const [createTenantDialogOpen, setCreateTenantDialogOpen] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [resetting, setResetting] = useState(false);
	const [tenantToDelete, setTenantToDelete] = useState(null);
	const [tenantToReset, setTenantToReset] = useState(null);

	const toggleDeleteModal = (tenantToDelete) => () => {
		setTenantToDelete(tenantToDelete);
	};

	const toggleResetModal = (tenantToReset) => () => {
		setTenantToReset(tenantToReset);
	};

	const remove = () => {
		setDeleting(true);
		removeTenantMutation({
			variables: { name: tenantToDelete },
			update: (cache) => {
				const data = cache.readQuery({ query: CURRENT_USER_QUERY });
				const index = data.currentUser.tenants.findIndex(
					(tenant) => tenant.tenant.name === tenantToDelete
				);
				const updatedCache = {
					...data,
					currentUser: {
						...data.currentUser,
						tenants: [...data.currentUser.tenants],
					},
				};
				updatedCache.currentUser.tenants.splice(index, 1);
				cache.writeQuery({ query: CURRENT_USER_QUERY, data: updatedCache });
				setDeleting(false);
				setTenantToDelete(null);
			},
		});
	};

	const reset = () => {
		setResetting(true);
		resetTenantMutation({
			variables: {
				name: tenantToReset,
				displayName: data.currentUser.tenants.find(
					(tenant) => tenant.tenant.name === tenantToReset
				).displayName,
			},
		}).then(() => {
			setResetting(false);
			setTenantToReset(null);
		});
	};

	const openCreateTenantDialog = () => {
		setCreateTenantDialogOpen(true);
	};

	const closeCreateTenantDialog = () => {
		setCreateTenantDialogOpen(false);
	};

	if (userLoading) {
		return (
			<div className={styles.Auth}>
				<TopBar tenant={{ name: "" }} user={{}} />
			</div>
		);
	} else if (userData?.currentUser) {
		const user = userData.currentUser;

		const tenantToDeleteObject = !tenantToDelete
			? null
			: user.tenants.find(
				(tenant) => tenant.tenant.name === tenantToDelete
			);

		const tenantToResetObject = !tenantToReset
			? null
			: user.tenants.find(
				(tenant) => tenant.tenant.name === tenantToReset
			);

		const protocol = window.location.protocol;
		const suffix = import.meta.env.VITE_HOST_SUFFIX;

		// if (user.tenants?.length === 1) {
		// 	const t = user.tenants[0];
		// 	window.location = `${protocol}//${t.tenant.name}${suffix}`;
		// 	return null;
		// }

		return (
			<div className={styles.Auth}>
				<TopBar
					isLoggedOut={userRefetch}
					tenant={{ name: "" }}
					user={user}
				/>
				<div className={styles.authBody}>
					<h1>Welcome, {user.name}</h1>
					<div className={styles.authInner}>
						<div className={styles.authHeader}>
							<h2>Your companies</h2>
							{user.super ? (
								<Button
									className={styles.addTenantButton}
									icon={IconNames.ADD}
									intent="success"
									onClick={openCreateTenantDialog}
								>
									Create a new company
								</Button>
							) : null}
						</div>
						<div className={styles.companyList}>
							{(user.tenants || []).map((t) => (
								<Card className={styles.company} key={t.tenant.name}>
									{user.super ? (
										<div className={styles.buttons}>
											<Icon
												icon={IconNames.TRASH}
												onClick={toggleDeleteModal(t.tenant.name)}
											/>
											<Icon
												icon={IconNames.ERASER}
												onClick={toggleResetModal(t.tenant.name)}
											/>
										</div>
									) : null}
									<h3>
										<a href={`${protocol}//${t.tenant.name}${suffix}`}>
											{t.tenant.displayName}
										</a>
									</h3>
									<span className={styles.role}>
										<Icon icon={IconNames.USER} />
										&nbsp;
										{t.role}
									</span>
								</Card>
							))}
						</div>
					</div>
					{createTenantDialogOpen && (
						<NewTenantDialog onClose={closeCreateTenantDialog} />
					)}
					{!!tenantToDelete ? (
						<TypedConfirmation
							deleting={deleting}
							isOpen
							onCancel={toggleDeleteModal(null)}
							onConfirm={remove}
						>
							<p>
								Are you sure that you want to remove{" "}
								<span className={styles.dialogRedTenantName}>
									{tenantToDeleteObject.tenant.displayName}
								</span>
								?
							</p>
							<p>All their data will be completely erased.</p>
							<p>
								Please type 'delete' and click <strong>Delete</strong> to
								confirm.
							</p>
						</TypedConfirmation>
					) : null}
					{!!tenantToReset ? (
						<TypedConfirmation
							confirmButtonText="Reset"
							confirmText="reset"
							deleting={resetting}
							isOpen
							onCancel={toggleResetModal(null)}
							onConfirm={reset}
						>
							<p>
								Are you sure that you want to reset{" "}
								<span className={styles.dialogRedTenantName}>
									{tenantToResetObject.tenant.displayName}
								</span>
								?
							</p>
							<p>All their data will be completely erased.</p>
							<p>
								Please type 'reset' and click <strong>Reset</strong> to
								confirm.
							</p>
						</TypedConfirmation>
					) : null}
				</div>
			</div>
		);
	} else {
		return (
			<div className={styles.Auth}>
				<TopBar
					isLoggedOut={userRefetch}
					tenant={{ name: "" }}
				/>
				<div className={styles.authLogin}>
					<Login />
				</div>
			</div>
		);
	}
}

export default Auth;
