import React from "react";
import PropTypes from "prop-types";

import {graphql} from "@apollo/client/react/hoc";
import compose from "lodash.flowright";
import {Link} from "react-router-dom";

import Button from "components/Button";
import Input from "components/Input";

import {Icon} from "@blueprintjs/core";

import {changePassword, IS_EMAIL_LOGIN_ENABLED_QUERY} from "./graphql";
import {AppContext} from "../../../AppContext";

import styles from "./styles.module.scss";

class PasswordChange extends React.Component {
  static propTypes = {
    askForCurrentPassword: PropTypes.bool.isRequired,
    changePassword: PropTypes.func.isRequired,
    inviteToken: PropTypes.string,
    loginLink: PropTypes.bool,
    onBack: PropTypes.func,
    onSuccess: PropTypes.func,
    resetToken: PropTypes.string,
    successMessage: PropTypes.string.isRequired,
  }

  state = {
    confirmation: "",
    confirm: "",
    current: "",
    error: "",
    loading: false,
    new: "",
    passwordsMatch: false,
  }

  static contextType = AppContext

  validatePassword(password) {
    // Minimum one uppercase, one lowercase, one digit
    // Allows pretty much all standard special characters, including spaces
    // Minimum 8 characters long, maximum 512 characters (just to avoid insanely big passwords, not really necessary otherwise with bcrypt)
    return password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*_\-()[\]{}<> ?+=/\\¤£€”¨~‘:|´`'",;.]{8,512}$/);
  }

  onChange = (evt) => {
    const {askForCurrentPassword} = this.props;

    const stateMutation = {
      ...this.state,
      error: "",
      confirmation: "",
      [evt.target.name]: evt.target.value,
    };

    let passwordsMatch = false;
    if(stateMutation.new === stateMutation.confirm && this.validatePassword(stateMutation.new)) {
      passwordsMatch = askForCurrentPassword ? stateMutation.new.length : true;
    }

    stateMutation.passwordsMatch = passwordsMatch;

    this.setState(stateMutation);
  }

  onConfirm = () => {
    const {changePassword, askForCurrentPassword, successMessage, resetToken, inviteToken, onSuccess} = this.props;
    const variables = {
      new: this.state.new,
    };
    if(askForCurrentPassword) variables.current = this.state.current;
    if(resetToken) variables.resetToken = resetToken;
    if(inviteToken) variables.inviteToken = inviteToken;

    this.setState({loading: true});
    changePassword({
      variables,
      update: (proxy) => {
        proxy.writeQuery({query: IS_EMAIL_LOGIN_ENABLED_QUERY, data: {isEmailLoginEnabled: true}});
      },
    }).then(() => {
      this.setState({
        confirm: "",
        confirmation: successMessage,
        current: "",
        error: "",
        loading: false,
        new: "",
        passwordsMatch: false,
      });
      if(onSuccess) onSuccess();
    }).catch((e) => {
      const error = e.message.replace("GraphQL error: ", "");
      this.setState({error, loading: false});
    });
  }

  render() {
    const {askForCurrentPassword, loginLink, onBack, resetToken} = this.props;
    const {confirm, new: newPassword, current, confirmation, error, loading, passwordsMatch} = this.state;

    return (
      <div className={styles.main}>
        {error.length ? <div className={styles.error}>{error}</div> : null}
        <form className={styles.form}>
          {askForCurrentPassword ? (
            <Input
              disabled={loading}
              id="currentPassword"
              name="current"
              onChange={this.onChange}
              placeholder="Current Password"
              type="password"
              value={current}
            />
          ) : null}
          <Input
            disabled={loading || !!confirmation.length}
            id="newPassword"
            name="new"
            onChange={this.onChange}
            placeholder="New Password"
            type="password"
            value={newPassword}
          />
          <Input
            disabled={loading || !!confirmation.length}
            id="confirmNewPassword"
            name="confirm"
            onChange={this.onChange}
            placeholder="Confirm New Password"
            type="password"
            value={confirm}
          />
        </form>
        <div className={styles.submitContainer}>
          {!!onBack ? (
            <Button
              className={styles.back}
              disabled={loading}
              icon="arrow-left"
              onClick={onBack}
            >
              Back
            </Button>
          ) : null}
          <Button
            disabled={!passwordsMatch || !!confirmation.length}
            intent="success"
            loading = {this.state.loading}
            onClick={this.onConfirm}
            text={askForCurrentPassword || resetToken ? "Change Password" : "Set Password"}
          />
          {confirmation.length ? (
            <div className={styles.confirmation}>
              <div className={styles.checkmark}>
                <Icon icon="tick" iconSize={20} />
              </div>
              <span>{confirmation} {loginLink ? (<Link to="/login" reloadDocument>Login</Link>) : null}</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(changePassword, {name: "changePassword"}),
)(PasswordChange);

export const passwordRequirementsText = "Please note that your password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter and one number.";
