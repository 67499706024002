import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

export default function Tabs({
  activeTab,
  actualsTabEnabled,
  onChange,
}) {

  return (
    <div className={styles.tabs}>
      <div
        className={[
          styles.tab,
          activeTab === "actuals" ? styles.active : undefined,
          !actualsTabEnabled ? styles.disabled : undefined,
        ].join(" ")}
        onClick={activeTab !== "actuals" && actualsTabEnabled ? () => onChange("actuals") : null}
      >
        Actuals
      </div>
      <div
        className={[styles.tab, activeTab === "forecast" ? styles.active : undefined].join(" ")}
        onClick={activeTab !== "forecast" ? () => onChange("forecast") : null}
      >
        Forecast
      </div>
    </div>
  );
}

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  actualsTabEnabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
