import {gql} from "@apollo/client";

export const ALL_PRODUCTS_QUERY = gql`
  query AllProductsQuery($includePlans: Boolean) {
    products(includePlans: $includePlans) {
      id
      name
      plans {
        id
        identifier
        name
        interval
        interval_count
        origin
        amount
        product_id
      }
    }
  }
`;

export const createProduct = gql`
  mutation createProduct($product: JSON!) {
    createProduct(product: $product) {
      id
      name
      description
    }
  }
`;

export const updateProduct = gql`
  mutation updateProduct($product: JSON!) {
    updateProduct(product: $product) {
      id
      name
      description
    }
  }
`;

export const REMOVE_PRODUCT = gql`
  mutation removeProduct($id: String!) {
    removeProduct(id: $id) {
      id
    }
  }
`;

export const createPlan = gql`
  mutation createPlan($plan: JSON!) {
    createPlan(plan: $plan) {
      id
      name
      identifier
      interval
      interval_count
      amount
      origin
      product_id
    }
  }
`;

export const updatePlan = gql`
  mutation updatePlan($plan: JSON!, $csv: JSON) {
    updatePlan(plan: $plan, csv: $csv) {
      id
      name
      identifier
      interval
      interval_count
      amount
      origin
      product_id
    }
  }
`;

export const removePlan = gql`
  mutation removePlan($id: String!) {
    removePlan(id: $id) {
      id
    }
  }
`;
