import React from "react";
import PropTypes from "prop-types";

import { graphql } from "@apollo/client/react/hoc";
import compose from "lodash.flowright";
import { sortBy } from "lodash";

import { AppContext } from "../../AppContext";

import { Classes as BP, Dialog, Tab, Tabs } from "@blueprintjs/core";
import Button from "components/Button";

import { ErrorType } from "./index";
import SanityChecksErrorsTable from "./SanityChecksErrorsTable";
import SanityChecksUnlinkedAccounts from "./SanityChecksUnlinkedAccounts";
import SanityChecksCyclesTable from "./SanityChecksCyclesTable";

import { ALL_ERRORS_QUERY } from "./graphql";

import styles from "./SanityChecks.module.scss";

class SanityCheckErrors extends React.Component {
  static propTypes = {
    allErrorsQuery: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    scenarios: PropTypes.array.isRequired,
    selectedCheck: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = this.createSanityCheckErrorsState(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen &&
      this.props.isOpen &&
      !this.state.isOpen &&
      !prevState.isOpen
    ) {
      const state = this.createSanityCheckErrorsState(true);
      this.setState(state);
    }
  }

  static contextType = AppContext;

  createSanityCheckErrorsState = (isOpen) => {
    const { allErrorsQuery, selectedCheck } = this.props;
    if (allErrorsQuery) allErrorsQuery.refetch();
    const selectedTabId = (selectedCheck && selectedCheck.id === "accountsLinked") ? "unlinked_accounts" : "errors";
    return {
      isOpen,
      selectedTabId,
    };
  }

  handleTabChange = (id) => this.setState({ selectedTabId: id });

  toggleDialog = () => {
    this.props.onClose();
    this.setState({ isOpen: !this.state.isOpen });
  }

  sortErrors = (errors, isMultipleCompanies) => {
    const disallowedMultipleCompaniesErrorTypes = [ErrorType.NET_CASH, ErrorType.BANK_NET_CASH];
    const sortedErrors = {
      errors: errors.filter((error) => {
        if (error.date) {
          if (isMultipleCompanies && disallowedMultipleCompaniesErrorTypes.includes(error.description)) return false;
          else return true;
        } else return false;
      }),
      cycleErrors: errors.filter((error) => error.description === ErrorType.CYCLE),
      unlinkedAccounts: errors.filter((error) => error.description === ErrorType.ACCOUNTS_LINKED),
    };
    sortedErrors.cycleErrors = sortBy(sortedErrors.cycleErrors, ["name", "statement_type", "scenario"]);
    return sortedErrors;
  };

  render() {
    const { allErrorsQuery, scenarios } = this.props;
    const { companies } = this.context;
    const isMultipleCompanies = (companies && companies.length > 1);
    const errors = allErrorsQuery?.errors ? allErrorsQuery.errors : [];
    const sortedErrors = this.sortErrors(errors, isMultipleCompanies);
    return (
      <Dialog
        canOutsideClickClose={false}
        className={[BP.LARGE, styles.sanityChecksDialog].join(" ")}
        icon="properties"
        inline
        isOpen={this.state.isOpen}
        lazy={false}
        onClose={this.toggleDialog}
        portalClassName={styles.sanityChecksDialogPortal}
        title="Details"
      >
        <div className={[BP.DIALOG_BODY, styles.sanityChecksDialogBody].join(" ")}>
          <Tabs large onChange={this.handleTabChange} selectedTabId={this.state.selectedTabId}>
            <Tab id="errors" panel={<SanityChecksErrorsTable errors={sortedErrors.errors} scenarios={scenarios} />} title="Errors" />
            <Tab id="unlinked_accounts" panel={<SanityChecksUnlinkedAccounts companies={this.context.companies} errors={sortedErrors.unlinkedAccounts} />} title="Unlinked Accounts" />
            <Tab id="cycles" panel={<SanityChecksCyclesTable errors={sortedErrors.cycleErrors} scenarios={scenarios} />} title="Circular References" />
          </Tabs>
        </div>
        <div className={[BP.DIALOG_FOOTER, styles.sanityChecksDialogFooter].join(" ")}>
          <div className={BP.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.toggleDialog}
              text="Close"
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default compose(
  graphql(ALL_ERRORS_QUERY, {
    name: "allErrorsQuery",
    skip: (props) => !props.isOpen,
  }),
)(SanityCheckErrors);
