import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";

import styles from "./styles.module.scss";

function HorizontalNav({ menuItems, userRole }) {
  return (
    <ul className={styles.horizontalNavContainer}>
      {menuItems.map((item) => !item.super || userRole === "SUPER" ? (
        <li key={item.link}>
          <NavLink className={({ isActive }) => isActive ? styles.active : null} to={item.link}>
            {item.text}
          </NavLink>
        </li>
      ) : null)}
    </ul>
  );
}

HorizontalNav.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string.isRequired,
    super: PropTypes.bool,
    text: PropTypes.string.isRequired,
  })),
  userRole: PropTypes.string.isRequired,
};

export default HorizontalNav;
