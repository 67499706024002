import React from "react";
import PropTypes from "prop-types";

import Select from "components/Select";

const options = [{
  text: "Reference another account",
  value: "reference",
}, {
  text: "Custom",
  value: "custom",
}];

export default function ActualsTypeSelector({
  onChange,
  actualsType = "custom",
}) {
  return (
    <Select
      label="Type of actuals"
      name="actualsType"
      onChange={({target: {value}}) => onChange(value)}
      options={options}
      value={actualsType}
    />
  );
}

ActualsTypeSelector.propTypes = {
  actualsType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
