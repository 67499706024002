import {gql} from "@apollo/client";

export const generateMfaSecret = gql`
  mutation generateMfaSecret {
    generateMfaSecret {
      id
      mfa {
        enabled
        secret
        otp
      }
    }
  }
`;

export const enableMfa = gql`
  mutation enableMfa($otp: String!, $secret: String!, $code: String!) {
    enableMfa(otp: $otp, secret: $secret, code: $code) {
      id
      mfa {
        enabled
        secret
        otp
      }
    }
  }
`;

export const checkMfaCode = gql`
  query checkMfaCode($code: String!) {
    checkMfaCode(code: $code)
  }
`;

export const disableMfa = gql`
  mutation disableMfa($code: String!) {
    disableMfa(code: $code) {
      id
      mfa {
        enabled
        secret
        otp
      }
    }
  }
`;

