import React, {useContext, useState} from "react";
import PropTypes from "prop-types";

import Table from "components/Table";
import Loading from "components/Loading";
import TableHeader, {sortFn} from "components/TableHeader";

import Row from "./Row";
import {AppContext} from "../../../AppContext";

import styles from "./styles.module.scss";

const sortableColumns = [{
  key: "name",
  text: "Name",
}];

export default function WorksheetsList({worksheets}) {
  const [sortAsc, setSortAsc] = useState(true);
  const [sortKey, setSortKey] = useState("name");

  const {user} = useContext(AppContext);

  const changeSort = (key) => () => {
    if(key === sortKey) {
      setSortAsc(!sortAsc);
    } else {
      setSortAsc(true);
      setSortKey(key);
    }
  };

  if(!worksheets) return <Loading />;

  return (
    <Table className={styles.list} hover={user.role !== "VIEW"}>
      <thead>
        <tr>
          {sortableColumns.map((column) => (
            <TableHeader
              className={`${styles.name}`}
              id={column.key}
              key={column.key}
              onClick={changeSort(column.key)}
              sortAsc={sortAsc}
              sortKey={sortKey}
            >
              {column.text}
            </TableHeader>
          ))}
          <th>Metrics in this worksheet</th>
        </tr>
      </thead>
      <tbody>
        {[...worksheets].sort(sortFn(sortAsc, sortKey)).map((worksheet) => ( // Had to make a copy of the array here to avoid mutating the apollo cache
          <Row
            key={`worksheet::${worksheet.id}`}
            worksheet={worksheet}
          />
        ))}
      </tbody>
    </Table>
  );
}

WorksheetsList.propTypes = {
  worksheets: PropTypes.array.isRequired,
};
