import React from "react";
import PropTypes from "prop-types";
import {FormGroup, ControlGroup, InputGroup, Button} from "@blueprintjs/core";
import {DateInput3 as DateInput} from "@blueprintjs/datetime2";
import moment from "moment";
import styles from "./CompanySettingsEmployees.module.scss";

import getCurrencyIcon from "views/shared/currency-icon";

import {AppContext} from "../../../AppContext";

const CHANGE_LIMIT = 7;

function generateLabels(compensationType) {
  let labels = null;
  switch(compensationType) {
    case ("benefits_changes"):
      labels = {
        main: "Benefit Change",
        sub: "Change",
      };
      break;
    case ("single_payments"):
      labels = {
        main: "One-Time Payments",
        sub: "One-Time Bonus or Severance",
        info: "",
      };
      break;
    default:
    case ("raises"):
      labels = {
        main: "New Salary",
        sub: "New Salary",
      };
      break;
  }
  return labels;
}

function findMinDate(array, index, fromMonth) {
  if(index === 0) return fromMonth;

  return (array[index - 1].date) ? moment(array[index - 1].date, "YYYY-MM-DD").toDate() : null;
}

class PayrollModelArray extends React.Component {
  static propTypes = {
    array: PropTypes.array.isRequired,
    compensationType: PropTypes.string.isRequired,
    forecastEndDate: PropTypes.object.isRequired,
    fromMonth: PropTypes.object.isRequired,
    terminationDate: PropTypes.object.isRequired,
    toMonth: PropTypes.object.isRequired,
    updateModelArray: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = AppContext;

  highlightAll = () => {
    setTimeout(() => {
      document.execCommand("selectAll", false, null);
    }, 0);
  }

  render() {
    const {array, compensationType, forecastEndDate, terminationDate, fromMonth} = this.props;
    const {currency} = this.context;
    const labels = generateLabels(compensationType);
    const lastIndex = (array?.length) ? array.length - 1 : 0;
    const addEnabled = (array?.length) ? ((array[lastIndex].amount !== 0 && array[lastIndex].date && array.length <= CHANGE_LIMIT)) : false;

    return (
      <div>
        {array.map((item, index) => (
          <FormGroup
            className={styles.payrollFormGroup}
            key={index}
            label={(index === 0) ? labels.main : ""}
          >
            <ControlGroup fill vertical={false}>
              <InputGroup
                autoComplete="off"
                className={styles.payrollModelArrayInputGroup}
                fill
                leftIcon={getCurrencyIcon(currency)}
                onBlur={(event) => this.props.updateModelArray(compensationType, index, "amount", event.target.value, false, false, "number", "blur")}
                onChange={(event) => this.props.updateModelArray(compensationType, index, "amount", event.target.value, false, false, "number", "focus")}
                onFocus={this.highlightAll}
                placeholder="Amount"
                value={array[index].amount}
              />
              <DateInput
                formatDate={(date) => date ? moment(date).format("MM/DD/YYYY") : findMinDate(array, index, fromMonth)}
                maxDate={(compensationType !== "single_payments") ? terminationDate : forecastEndDate}
                minDate={findMinDate(array, index, fromMonth)}
                onChange={(newDate) => this.props.updateModelArray(compensationType, index, "date", (newDate) ? moment(newDate).format("YYYY-MM-DD") : null, null, null, "date")}
                parseDate={(str) => moment(str, "MM/DD/YYYY").toDate()}
                placeholder={"mm/dd/yyyy"}
                showActionsBar
                value={(array[index].date) ? moment(array[index].date, "YYYY-MM-DD").format("MM/DD/YYYY") : null}
              />
              {(index === lastIndex) ? (
                <Button
                  icon="cross"
                  onClick={() => this.props.updateModelArray(compensationType, index, "amount", 0, false, true)}
                  small
                />
              ) : (
                <Button disabled icon="cross" small/>
              )}
            </ControlGroup>
          </FormGroup>
        ))}
        <Button
          className={`${styles.payrollModelAdd} m-t-0`}
          disabled={!addEnabled}
          icon="small-plus"
          minimal
          onClick={() => this.props.updateModelArray(compensationType, 0, "amount", 0, true)}
          small
        >
          Add {labels.sub}
        </Button>
      </div>
    );
  }

}

export default PayrollModelArray;
