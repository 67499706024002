import React from "react";
import PropTypes from "prop-types";

import TextInput from "components/TextInput";

import CustomPropTypes from "../../propTypes";

export default function EmptyRowOptions({
  onRowChange,
  row,
}) {
  return (
    <TextInput
      label="Metric Name"
      name="name"
      onChange={(evt) => onRowChange({...row, name: evt.target?.value ?? row.name})}
      value={row.name}
    />
  );
}

EmptyRowOptions.propTypes = {
  onRowChange: PropTypes.func.isRequired,
  row: CustomPropTypes.row.isRequired,
};
