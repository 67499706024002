export function getRowIcon(account, models, scenarioId) {
  let modelIcon = "edit";

  if(account.detail_type === "Payroll Account") {
    return "people";
  }

  if(!models || !scenarioId || !models[scenarioId]) return modelIcon;

  if(Object.keys(models[scenarioId]).length) {
    modelIcon = "manually-entered-data";
    for(const modelType of Object.keys(models[scenarioId])) {
      if(models[scenarioId][modelType].active) {
        switch(modelType) {
          case "autopilot": {
            modelIcon = "airplane";
            break;
          }
          case "google": {
            modelIcon = "google";
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }
  return modelIcon;
}
