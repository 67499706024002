import React, {useContext} from "react";
import PropTypes from "prop-types";

import Button from "components/Button";
import Sidebar from "components/Sidebar";
import FormattingOptions from "components/FormattingOptions";

import FormFieldName from "../../shared/fields/name";

import styles from "../TableBuilder.module.scss";

import {AppContext} from "../../../../AppContext";

export default function SidebarComparisonColumn({onChange, onCloseSidebar, table, type}) {
  const {currency} = useContext(AppContext);

  return (
    <Sidebar onClose={onCloseSidebar} title="Column Properties">
      <form className={styles.sidebarForm}>
        <div className={[styles.formGroupWithInfosWrapper, styles.hidden].join(" ")}>
          <FormFieldName
            formGroupClass={styles.formGroup}
            label="Column Name"
            name={`${type}DeltaColumnName`}
            onChange={onChange}
            value={table.options[`${type}DeltaColumnName`] || ""}
          />
        </div>
        <FormattingOptions
          currency={currency}
          label="Formatting Options"
          name={`${type}DeltaFormattingOptions`}
          onChange={onChange}
          type="comparisonColumn"
          value={table.options[`${type}DeltaFormattingOptions`] || {}}
        />
      </form>
      <div className={styles.sidebarButtons}>
        <Button
          onClick={onCloseSidebar}
          size="small"
          text="Close"
        />
      </div>
    </Sidebar>
  );
}

SidebarComparisonColumn.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCloseSidebar: PropTypes.func.isRequired,
  table: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};
