import {gql} from "@apollo/client";

export const ALL_USERS_QUERY = gql`
  query AllUsersQuery {
    allCompanyUsers {
      id
      displayName
      name
      email
      role
      google_id
      hasPassword
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($id: String!, $user: JSON!) {
    updateUser(id: $id, update: $user) {
      email
    }
  }
`;

export const removeUser = gql`
  mutation removeUser($id: String!) {
    removeUser(id: $id)
  }
`;

export const createUser = gql`
  mutation createUser($user: JSON!) {
    createUser(user: $user) {
      email
    }
  }
`;
