import React from "react";
import PropTypes from "prop-types";

import Button from "components/Button";

import styles from "./styles.module.scss";

function SettingsHeader({text, buttonText, buttonAction, buttonDisabled, subtitle, switchElement}) {
  const classes = [styles.heading];
  if(subtitle) classes.push(styles.subtitle);
  if(!!switchElement) classes.push(styles.switch);
  const TitleTag = subtitle ? `h2` : `h1`;
  return (
    <TitleTag className={classes.join(" ")}>
      <span>{text}</span>
      {!!switchElement ? switchElement : null}
      {buttonText && buttonText.length ? (
        <Button
          disabled={buttonDisabled}
          icon="symbol-cross"
          intent="success"
          onClick={buttonAction}
          size="small"
        >
          {buttonText}
        </Button>
      ) : null}
    </TitleTag>
  );
}

SettingsHeader.propTypes = {
  buttonAction: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  buttonText: PropTypes.string,
  subtitle: PropTypes.bool,
  switchElement: PropTypes.node,
  text: PropTypes.string.isRequired,
};

export default SettingsHeader;
