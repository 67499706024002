import React from "react";
import PropTypes from "prop-types";

import {Icon, Radio, RadioGroup} from "@blueprintjs/core";

import styles from "./ForecastSidebar.module.scss";

export default function ForecastType({disabledOptions = [], hiddenOptions = [], onChange, forecastType, label = "Forecast", name = "forecastType"}) {
  return (
    <RadioGroup
      label={label}
      name={name}
      onChange={onChange}
      selectedValue={forecastType}
    >
      {!hiddenOptions.includes("autopilot") ? (<Radio disabled={disabledOptions.includes("autopilot")} labelElement={(<span className={styles.forecastTypeItem}><Icon icon="airplane" />Autopilot</span>)} value="autopilot" />) : null}
      {!hiddenOptions.includes("manual") ? (<Radio disabled={disabledOptions.includes("manual")} labelElement={(<span className={styles.forecastTypeItem}><Icon icon="manually-entered-data" />Manual</span>)} value="manual" />) : null}
      {!hiddenOptions.includes("google") ? (<Radio disabled={disabledOptions.includes("google")} labelElement={(<span className={styles.forecastTypeItem}><Icon icon="join-table" />Google Sheets</span>)} value="google" />) : null}
      {!hiddenOptions.includes("formula") ? (<Radio disabled={disabledOptions.includes("formula")} labelElement={<span className={styles.forecastTypeItem}><Icon icon="function" />Formula</span>} value="formula" />) : null}
    </RadioGroup>
  );
}

ForecastType.propTypes = {
  disabledOptions: PropTypes.array,
  forecastType: PropTypes.string.isRequired,
  hiddenOptions: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
