import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useQuery, useMutation } from "@apollo/client";

import { Callout, Intent } from "@blueprintjs/core";
import Loading from "components/Loading";
import Button from "components/Button";
import Switcher from "components/Switcher";

import { AppContext } from "../../../AppContext";
import CompanySettingsEmployeesCreateUpdate from "./CompanySettingsEmployeesCreateUpdate";
import CompanySettingsEmployeesAll from "./CompanySettingsEmployeesAll";
import CompanySettingsTeamsCreateUpdate from "./CompanySettingsTeamsCreateUpdate";
import CompanySettingsTaxRatesCreateUpdate from "./CompanySettingsTaxRateCreateUpdate";
import CompanySettingsTaxRatesAll from "./CompanySettingsTaxRatesAll";
import RaiseSchedule from "./RaiseSchedule";
import styles from "./CompanySettingsEmployees.module.scss";

import {
  ALL_TEAMS_QUERY,
  ALL_EMPLOYEES_QUERY,
  ALL_TAX_RATES_QUERY,
  ARCHIVED_EMPLOYEES_QUERY,
  UPDATE_TEAM,
  CREATE_TEAM,
  REMOVE_TEAM,
  CREATE_TAX_RATE,
  UPDATE_TAX_RATE,
  REMOVE_TAX_RATE,
  CREATE_EMPLOYEE,
  DUPLICATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  ARCHIVE_EMPLOYEE,
  UNARCHIVE_EMPLOYEE,
  REMOVE_EMPLOYEE,
} from "./graphql";

import { ALL_SCENARIOS_QUERY } from "../../../graphql";
import { withHooks } from "shared/hooks/hoc";

import { filterAccountsByPayroll, updateEmployees } from "./employee-utilities";

const EMPLOYEE_STATUSES = [{ value: 0, name: "Active" }, { value: 1, name: "Archived" }];

function FormSelector({ array, field, label, onChange, value }) {
  if (array.length <= 0) return null;

  return (
    <div className={`${styles.scenariosFormGroup}`}>
      <label>{label}</label>
      <div>
        <select
          onChange={onChange}
          value={value}
        >
          {array.map((item) => (
            <option key={item[field]} value={item[field]}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

FormSelector.propTypes = {
  array: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

const CompanySettingsEmployees = (props) => {
  const { user } = useContext(AppContext);

  const [employee, setEmployee] = useState(null);
  const [employeeStatus, setEmployeeStatus] = useState(0);
  const [operation, setOperation] = useState("create");
  const [raiseScheduleOpen, setRaiseScheduleOpen] = useState(false);
  const [scenario, setScenario] = useState("");
  const [team, setTeam] = useState(null);
  const [teamOperation, setTeamOperation] = useState("create");
  const [teamToEdit, setTeamToEdit] = useState(null);
  const [taxRateOperation, setTaxRateOperation] = useState("create");
  const [taxRateToEdit, setTaxRateToEdit] = useState(null);
  const [updatedArchivedEmployees, setUpdatedArchivedEmployees] = useState([]);
  const [updatedEmployees, setUpdatedEmployees] = useState([]);

  const allScenariosQuery = useQuery(ALL_SCENARIOS_QUERY);
  const scenarios = allScenariosQuery.data?.scenarios;

  const allTeamsQuery = useQuery(ALL_TEAMS_QUERY, { skip: !scenarios });
  const teams = allTeamsQuery.data?.teams;

  const allEmployeesQuery = useQuery(ALL_EMPLOYEES_QUERY, { skip: !teams });
  const archivedEmployeesQuery = useQuery(ARCHIVED_EMPLOYEES_QUERY, { skip: !teams });

  const allTaxRatesQuery = useQuery(ALL_TAX_RATES_QUERY);
  const taxRates = allTaxRatesQuery.data?.taxRates;

  const [updateTeam] = useMutation(UPDATE_TEAM, {
    refetchQueries: [ALL_TEAMS_QUERY]
  });
  const [createTeam] = useMutation(CREATE_TEAM, {
    refetchQueries: [ALL_TEAMS_QUERY]
  });
  const [removeTeam] = useMutation(REMOVE_TEAM, {
    refetchQueries: [ALL_TEAMS_QUERY]
  });
  const [updateTaxRate] = useMutation(UPDATE_TAX_RATE, {
    refetchQueries: [ALL_TAX_RATES_QUERY]
  });
  const [createTaxRate] = useMutation(CREATE_TAX_RATE, {
    refetchQueries: [ALL_TAX_RATES_QUERY]
  });
  const [removeTaxRate] = useMutation(REMOVE_TAX_RATE, {
    refetchQueries: [ALL_TAX_RATES_QUERY]
  });
  const [createEmployee] = useMutation(CREATE_EMPLOYEE, {
    refetchQueries: [ALL_EMPLOYEES_QUERY]
  });
  const [duplicateEmployee] = useMutation(DUPLICATE_EMPLOYEE, {
    refetchQueries: [ALL_EMPLOYEES_QUERY]
  });
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE, {
    refetchQueries: [ALL_EMPLOYEES_QUERY],
  });
  const [removeEmployee] = useMutation(REMOVE_EMPLOYEE, {
    refetchQueries: [ALL_EMPLOYEES_QUERY]
  });
  const [archiveEmployee] = useMutation(ARCHIVE_EMPLOYEE, {
    refetchQueries: [ALL_EMPLOYEES_QUERY, ARCHIVED_EMPLOYEES_QUERY]
  });
  const [unarchiveEmployee] = useMutation(UNARCHIVE_EMPLOYEE, {
    refetchQueries: [ALL_EMPLOYEES_QUERY, ARCHIVED_EMPLOYEES_QUERY]
  });

  useEffect(() => {
    if (!scenario && scenarios?.length) setScenario(scenarios[0].id);
  }, [allScenariosQuery]);

  useEffect(() => {
    if (allEmployeesQuery.data?.employees?.length) {
      let employees = allEmployeesQuery.data.employees;
      employees = updateEmployees(user?.tenant, allTeamsQuery.data.teams, employees, scenarios)
      setUpdatedEmployees(employees);
    }
  }, [allEmployeesQuery])

  useEffect(() => {
    if (archivedEmployeesQuery.data?.employees?.length) {
      let employees = archivedEmployeesQuery.data.employees;
      employees = updateEmployees(user?.tenant, allTeamsQuery.data.teams, employees, scenarios);
      setUpdatedArchivedEmployees(employees);
    }
  }, [archivedEmployeesQuery])

  const toggleRaiseScheduleModal = () => {
    setRaiseScheduleOpen(!raiseScheduleOpen);
  }

  const editEmployee = (employeeToEdit, team) => () => {
    const employee = (employeeToEdit) ? employeeToEdit : {};
    const operation = (employeeToEdit) ? "update" : "create";
    setEmployee(employee);
    setOperation(operation);
    setTeam(team);
  }

  const onClose = () => {
    setEmployee(null);
    setOperation("create");
  }

  const editTeam = (teamToEdit) => () => {
    const team = (teamToEdit) ? teamToEdit : {};
    const operation = (teamToEdit) ? "update" : "create";
    setTeamToEdit(team);
    setTeamOperation(operation);
  }

  const onCloseTeam = () => {
    setTeamToEdit(null);
    setTeamOperation("create");
  }

  const editTaxRate = (taxRateEdit) => () => {
    const taxRate = (taxRateEdit) ? taxRateEdit : {};
    const operation = (taxRateEdit) ? "update" : "create";
    setTaxRateToEdit(taxRate);
    setTaxRateOperation(operation);
  };

  const onCloseTaxRate = () => {
    setTaxRateToEdit(null);
    setTaxRateOperation("create");
  }

  const scenarioChange = (event) => {
    setScenario(event.target.value);
  }

  const statusChange = (event) => {
    setEmployeeStatus(parseInt(event.target.value, 10));
  };

  if (allTeamsQuery.loading || allEmployeesQuery.loading || allTaxRatesQuery.loading || allScenariosQuery.loading || archivedEmployeesQuery.loading) {
    return <Loading />;
  }

  const payrollAccounts = (props.allFlightpathAccounts) ? filterAccountsByPayroll(props.allFlightpathAccounts) : [];

  return (
    <div className={styles.employees}>
      {(scenarios.length < 1) ? (
        <Callout intent={Intent.PRIMARY}>
          Please connect your accounting and create a forecast scenario in company settings before you start forecasting.
        </Callout>
      ) : (
        <>
          <div className={styles.teamNav}>
            <Switcher
              label="Scenario"
              onChange={scenarioChange}
              options={scenarios.map((item) => ({ name: item.name, value: item.id }))}
              selectedValue={scenario}
            />
            <Switcher
              label="Employee Status"
              onChange={statusChange}
              options={EMPLOYEE_STATUSES}
              selectedValue={employeeStatus}
            />
            <Button
              icon="symbol-cross"
              intent="success"
              onClick={editTeam(null)}
              text="New Team"
            />
            <Button
              onClick={toggleRaiseScheduleModal}
              text="Schedule Raises"
            />
          </div>
          <CompanySettingsTaxRatesAll
            editTaxRate={editTaxRate}
            removeTaxRate={removeTaxRate}
            taxRates={taxRates}
          />
          {(employeeStatus === 0) ? (
            <CompanySettingsEmployeesAll
              archiveEmployee={archiveEmployee}
              duplicateEmployee={duplicateEmployee}
              editEmployee={editEmployee}
              editTeam={editTeam}
              employees={updatedEmployees}
              forecastStartDate={user.tenant.options.forecastStartDate}
              removeEmployee={removeEmployee}
              scenario={scenario}
              scenarios={scenarios}
              teams={teams}
            />
          ) : (
            <CompanySettingsEmployeesAll
              duplicateEmployee={duplicateEmployee}
              editEmployee={editEmployee}
              editTeam={editTeam}
              employees={updatedArchivedEmployees}
              forecastStartDate={user.tenant.options.forecastStartDate}
              removeEmployee={removeEmployee}
              scenario={scenario}
              scenarios={scenarios}
              teams={teams}
              unarchiveEmployee={unarchiveEmployee}
            />
          )}
        </>
      )}
      <CompanySettingsEmployeesCreateUpdate
        archiveEmployee={archiveEmployee}
        duplicateEmployee={duplicateEmployee}
        editEmployee={editEmployee}
        employee={employee}
        forecastStartDate={user.tenant.options.forecastStartDate}
        onClose={onClose}
        onCreate={createEmployee}
        onRemove={removeEmployee}
        onUpdate={updateEmployee}
        operation={operation}
        scenario={scenario}
        scenarios={scenarios}
        team={team}
        teams={teams}
        taxRates={taxRates}
        unarchiveEmployee={unarchiveEmployee}
        user={user}
      />
      <CompanySettingsTeamsCreateUpdate
        onClose={onCloseTeam}
        onCreate={createTeam}
        onRemove={removeTeam}
        onUpdate={updateTeam}
        operation={teamOperation}
        payrollAccounts={payrollAccounts}
        team={teamToEdit}
      />
      <CompanySettingsTaxRatesCreateUpdate
        onClose={onCloseTaxRate}
        onCreate={createTaxRate}
        onRemove={removeTaxRate}
        onUpdate={updateTaxRate}
        operation={taxRateOperation}
        taxRate={taxRateToEdit}
      />
      <RaiseSchedule
        isOpen={raiseScheduleOpen}
        onClose={toggleRaiseScheduleModal}
      />
    </div>
  );
};

export default withHooks(CompanySettingsEmployees, ["useAllFlightpathAccounts"]);
