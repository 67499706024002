import {useReactiveVar} from "@apollo/client";

import {entriesCacheVar, localEntriesCacheVar} from "apollo-config/local-state/entries";

export function useEntriesCacheForAccount(account) {
  const entriesCache = useReactiveVar(entriesCacheVar);
  const localEntriesCache = useReactiveVar(localEntriesCacheVar);

  if(!account) return {};
  const id = typeof account === "string" ? account : account.id;

  const localEntriesCacheForAccount = localEntriesCache[id];
  if(localEntriesCacheForAccount) {
    //console.log(`Local entriesCache has been found for account ${id}. Returning it.`);
    return localEntriesCacheForAccount;
  }


  const entriesCacheForAccount = entriesCache[id];

  return entriesCacheForAccount || {};
}
