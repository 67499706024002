export default {
  Query: {
    fields: {
      account: (existingDataFromCache, {args, toReference}) => existingDataFromCache || toReference({__typename: "Account", id: args.id}), // Account:1
      worksheet: (existingDataFromCache, {args, toReference}) => existingDataFromCache || toReference({__typename: "Worksheet", id: args.slug}),
      pendingChanges: {
        merge: (existing, incoming) => incoming,
      },
      scenarios: {
        merge: (existing, incoming) => incoming,
      },
    },
  },
  Dashboard: {
    fields: {
      layout: {
        merge: (existing, incoming) => incoming,
      },
    },
  },
  Account: {
    fields: {
      children: {
        merge: (existing, incoming) => incoming,
      },
    },
  },
  Worksheet: {
    fields: {
      rows: {
        merge: (existing, incoming) => incoming,
      },
    },
  },
  Chart: {
    fields: {
      lines: {
        merge: (existing, incoming) => incoming,
      },
    },
  },
  Subscription: {
    fields: {
      pendingChangesUpdated: {
        merge: (existing, incoming) => incoming,
      },
      allAccounts: {
        merge: (existing, incoming) => incoming,
      },
    },
  },
};
