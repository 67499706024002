export default {
  expansion: [{
    type: "account",
    accountType: "Expansion %",
    options: {
      percentage: true,
      useTotalOverFormula: false,
    },
  }],
  churn: [{
    type: "account",
    accountType: "Churn %",
    options: {
      percentage: true,
      useTotalOverFormula: false,
    },
  }],
  contraction: [{
    type: "account",
    accountType: "Contraction %",
    options: {
      percentage: true,
      useTotalOverFormula: false,
    },
  }],
};
