import React, {useContext, useReducer} from "react";
import PropTypes from "prop-types";

import PlanCreateUpdate from "./PlanCreateUpdate";
import {AppContext} from "../../../AppContext";

const reducer = (state) => ({planDialogOpen: !state.planDialogOpen});

export default function PlanRow({plan}) {
  const {user} = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, {planDialogOpen: false});

  return (
    <>
      {state.planDialogOpen ? <PlanCreateUpdate onClose={dispatch} plan={plan} /> : null}
      <tr onClick={user.role !== "VIEW" ? dispatch : null}>
        <td>{plan.name || ""}</td>
        <td>{plan.amount || ""}</td>
        {/*<td>{plan.interval}</td>*/}
        <td>{plan.interval_count}</td>
      </tr>
    </>
  );
}

PlanRow.propTypes = {
  plan: PropTypes.object.isRequired,
};
