import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { cloneDeep } from "lodash";
import shortid from "shortid";

import { useQuery } from "@apollo/client";

import CustomPropTypes from "../../propTypes";

import TextInput from "components/TextInput";

import { AppliesToOptions, clearEntriesCacheForPeriod, getCurrentModelAppliesToValue, getModelInSidebar, getSlug, isActualsTabEnabled, updateOtherModelsIfNecessary } from "./utilities";
import { ModelPeriod } from "../utilities";
import { getEntriesCacheForAccount, fillManualEntriesCache } from "shared/utilities/entry-utilities";

import { AppContext } from "../../../../AppContext";

import { ALL_SCENARIOS_QUERY } from "../../../../graphql";
import { useAllFlightpathAccounts } from "shared/hooks/account-hooks";

import ForecastType from "../../../forecast-sidebar/ForecastType";
import FormulaBuilder from "../FormulaBuilder";
import ManualOptions from "./ManualOptions";
import AppliesToSelector from "./AppliesToSelector";
import ActualsTypeSelector from "./ActualsTypeSelector";
import BalanceOptions from "./BalanceOptions";
import AccountSelector from "./AccountSelector";
import Tabs from "./Tabs";

import styles from "./styles.module.scss";
import { actionTypes } from "../reducer";

export default function MetricOptions({
  actualsClicked,
  onAutocompleteHighlight,
  onRowChange,
  row,
  scenarioId,
  worksheet,
  dispatch,
}) {
  const actualsTabEnabled = isActualsTabEnabled(row.models);
  const [activeTab, setActiveTab] = useState(actualsClicked && actualsTabEnabled && row.forecastAppliesTo && row.forecastAppliesTo !== AppliesToOptions.ALL ? "actuals" : "forecast");
  const [actualsType, setActualsType] = useState(row.actualsAccountId ? "reference" : "custom");
  const { forecastEndDate, forecastStartDate, historicalsStartDate } = useContext(AppContext);

  const { data: { scenarios } } = useQuery(ALL_SCENARIOS_QUERY);
  const accounts = useAllFlightpathAccounts();

  // In case it's a new row, reset the actuals type
  useEffect(() => {
    setActualsType(row.actualsAccountId ? "reference" : "custom");
  }, [row.id, row.actualsAccountId]);

  // In case it's a new row or actualsClicked has changed, set the active tab
  useEffect(() => {
    setActiveTab(actualsClicked && actualsTabEnabled && row.forecastAppliesTo !== AppliesToOptions.ALL ? "actuals" : "forecast");
  }, [actualsClicked, row.id, actualsTabEnabled, row.forecastAppliesTo]);

  const { model, modelIndex } = getModelInSidebar({ row, scenarioId, tab: activeTab });

  // Hackish way of selecting the currently selected appliesTo value. This should be driver by the model
  let appliesToValue = row.forecastAppliesTo;
  if (!appliesToValue) {
    if (model) {
      appliesToValue = getCurrentModelAppliesToValue({ scenarioId: model.scenario_id, period: model.period });
    } else {
      // If there's already an ACTUALS model in there, set the appliesTo value to FORECAST
      if (row.models.find((rowModel) => rowModel.period === ModelPeriod.ACTUALS)) {
        appliesToValue = AppliesToOptions.FORECAST;
      } else {
        appliesToValue = AppliesToOptions.ALL;
      }
    }
  }

  let forecastType = null;
  if (!model) {
    forecastType = "manual";
  } else {
    // TODO: handle google sheets here by looking at model properties
    forecastType = "formula";
  }

  const handleCopyAcross = ({ fill, grow, interval }) => {
    let entriesCache = row.entriesCache;
    if (!entriesCache && row.account_id) {
      const account = accounts?.find((acct) => acct.id === row.account_id);
      entriesCache = cloneDeep(getEntriesCacheForAccount(account));
    }

    const updatedEntriesCache = fillManualEntriesCache({
      entriesCache,
      fill,
      forecastEndDate,
      forecastStartDate,
      grow,
      historicalsStartDate,
      period: activeTab === "actuals" ? ModelPeriod.ACTUALS : [AppliesToOptions.FORECAST, AppliesToOptions.CURRENT_SCENARIO].includes(appliesToValue) ? ModelPeriod.FORECASTS : ModelPeriod.ALL,
      scenarioId: row.forecastAppliesTo === AppliesToOptions.CURRENT_SCENARIO ? scenarioId : null,
      scenarios,
      interval,
    });

    onRowChange({
      ...row,
      entriesCache: updatedEntriesCache,
    }, "[sidebar] handleFormulaChange");
  };

  const handleFormulaChange = ({ formula, varNameToSlugMapping, formulaValid, variables }) => {
    const newModels = [...row.models];
    // If there was no model before, create one
    if (modelIndex === -1) {
      newModels.push({
        frontendId: shortid(),
        active: true,
        scenario_id: row.AppliesToOptions === AppliesToOptions.CURRENT_SCENARIO ? scenarioId : null,
        period: activeTab === "actuals" ? ModelPeriod.ACTUALS : [AppliesToOptions.FORECAST, AppliesToOptions.CURRENT_SCENARIO].includes(row.forecastAppliesTo) ? ModelPeriod.FORECASTS : ModelPeriod.ALL,
        formula,
        formulaValid,
        varNameToSlugMapping,
        variables,
      });
    } else { // else, update it
      newModels[modelIndex] = {
        ...newModels[modelIndex],
        formula,
        formulaValid,
        varNameToSlugMapping,
        variables,
      };
    }

    onRowChange({
      ...row,
      models: newModels,
    }, "[sidebar] handleFormulaChange");
  };

  const handleRowPropertyChange = ({ target: { name, value } }) => {
    const stateMutation = { ...row };
    if (name === "name") {
      stateMutation.slug = getSlug({ worksheet, accountName: value, currentRowId: row.id });
      stateMutation.name = value;

      const updatedModels = updateOtherModelsIfNecessary(worksheet, row, name, value, stateMutation.slug);

      if (updatedModels.length) {
        // If multiple models are updated for the same row, make sure we don't overwrite our first changes in subsequent iterations
        const modelsByRowIndex = {};
        for (const { rowIndex, modelIndex, model } of updatedModels) {
          const rowClone = {
            ...worksheet.rows[rowIndex],
            models: [...(modelsByRowIndex[rowIndex] || worksheet.rows[rowIndex].models)],
          };
          rowClone.models[modelIndex] = model;
          onRowChange(rowClone, "[sidebar] handleRowPropertyChange in loop");

          // Set modelsByRowIndex for that row to the last version of the models that we know of for that row
          modelsByRowIndex[rowIndex] = rowClone.models;

          // If the updated row is the same as the current one, make sure the new models are used in the save function below or else changes are overwritten
          if (rowClone.id === row.id) stateMutation.models = rowClone.models;
        }
      }
    } else {
      stateMutation[name] = value;
    }

    onRowChange(stateMutation, "[sidebar] handleRowPropertyChange final");
  };

  const handleForecastTypeChange = ({ target: { value: newForecastType } }) => {
    const rowClone = { ...row };
    rowClone.models = [...rowClone.models];

    // Resolve the model and index again including inactive ones
    const { modelIndex } = getModelInSidebar({ row, scenarioId, tab: activeTab, lookForInactive: true });
    let period = null;

    if (modelIndex !== -1) {
      const isAutoType = ["formula", "google"].includes(newForecastType);

      rowClone.models[modelIndex] = {
        ...rowClone.models[modelIndex],
        active: isAutoType, // TODO: handle that to allow multiple models besides only formula
      };

      // Handle case where formula is invalid but user switches to manual.
      // In the future, we might want to keep it while still allowing save but for now we clear it
      if (newForecastType === "manual" && rowClone.models[modelIndex].formulaValid === false) {
        rowClone.models[modelIndex].formula = "";
        rowClone.models[modelIndex].formulaValid = true;
      }

      if (isAutoType) {
        period = [AppliesToOptions.FORECAST, AppliesToOptions.CURRENT_SCENARIO].includes(row.forecastAppliesTo) ? ModelPeriod.FORECASTS : ModelPeriod.ALL;
        if (rowClone.models[modelIndex].period === ModelPeriod.ACTUALS) period = ModelPeriod.ACTUALS;
        rowClone.models[modelIndex].period = period;
      }
    } else if (newForecastType === "formula") {
      let period = null;
      let scenario_id = null;
      if (activeTab === "actuals") {
        period = ModelPeriod.ACTUALS;
      } else {
        if (row.forecastAppliesTo === AppliesToOptions.FORECAST) {
          period = ModelPeriod.FORECASTS;
        } else if (row.forecastAppliesTo === AppliesToOptions.CURRENT_SCENARIO) {
          period = ModelPeriod.FORECASTS;
          scenario_id = scenarioId;
        } else {
          period = ModelPeriod.ALL;
        }
      }
      rowClone.models.push({
        frontendId: shortid(),
        formula: "",
        scenario_id,
        period,
        active: true,
        variables: {},
      });
    }

    switch (newForecastType) {
      case "manual": {
        if (!rowClone.entriesCache && rowClone.account_id) {
          const account = accounts?.find((acct) => acct.id === row.account_id);
          rowClone.entriesCache = cloneDeep(getEntriesCacheForAccount(account));
        } else if (!rowClone.entriesCache) {
          rowClone.entriesCache = {};
        }

        break;
      }
      default: {
        if (rowClone.entriesCache) {
          rowClone.entriesCache = clearEntriesCacheForPeriod({ entriesCache: rowClone.entriesCache, appliesTo: period === ModelPeriod.ACTUALS ? null : row.forecastAppliesTo, period, scenarioId });
        }
      }
    }
    onRowChange(rowClone, "[sidebar] handleForecastTypeChange");
  };

  const handleAppliesToChange = ({ period, value }) => {
    const rowClone = {
      ...row,
      forecastAppliesTo: value,
    };

    rowClone.models = [...rowClone.models];

    // If we set the applies to to "ALL", make sure we disable the "ACTUALS" model before (if there's one active)
    if (value === AppliesToOptions.ALL) {
      for (let i = 0; i < rowClone.models.length; i++) {
        if (rowClone.models[i].active && rowClone.models[i].period === ModelPeriod.ACTUALS) {
          rowClone.models[i] = {
            ...rowClone.models[i],
            active: false,
          };
        }
      }
    } else {
      // If we set it to something else but an "ALL" model exists, but is not active, we still need to update it to avoid issues
      for (let i = 0; i < rowClone.models.length; i++) {
        if (rowClone.models[i].period === ModelPeriod.ALL) {
          rowClone.models[i] = {
            ...rowClone.models[i],
            period: value === AppliesToOptions.FORECAST ? ModelPeriod.FORECASTS : ModelPeriod.CURRENT_SCENARIO,
            scenario_id: value === AppliesToOptions.FORECAST ? null : scenarioId,
          };
        }
      }

      if (value === AppliesToOptions.FORECAST && row.models[modelIndex]?.scenario_id) {
        for (const scenario of scenarios) {
          const existingModelIndex = rowClone.models.findIndex((item) => item.scenario_id === scenario.id);
          if (existingModelIndex > -1) {
            rowClone.models.splice(existingModelIndex, 1);
          }
        }
      }
    }

    if (forecastType === "formula") {
      const modelClone = {
        ...row.models[modelIndex],
        period,
        scenario_id: scenarioId || null,
      };

      rowClone.models[modelIndex] = modelClone;
    } else {
      // If it's a manual forecast and we go from CURRENT to either ALL or FORECAST, copy over this scenario's values to the other ones
      if ([AppliesToOptions.ALL, AppliesToOptions.FORECAST].includes(value)) {
        if (row.entriesCache) {
          const newEntriesCache = { ...row.entriesCache };
          for (const { id: scenarioKey } of scenarios.filter((item) => item.id !== scenarioId)) {
            // For every scenario except the current one, clone the current scenario over
            newEntriesCache[scenarioKey] = cloneDeep(row.entriesCache[scenarioId]);
            for (const dateKey of Object.keys(newEntriesCache[scenarioKey])) {
              if (newEntriesCache[scenarioKey][dateKey]?.FORMULA?.id) delete newEntriesCache[scenarioKey][dateKey].FORMULA.id;
            }
          }
          rowClone.entriesCache = newEntriesCache;
        }
      }
    }

    onRowChange(rowClone, "[sidebar] handleAppliesToChange");
  };

  const handleActualsAccountChange = ({ target: { value: accountId } }) => {
    const rowClone = {
      ...row,
      actualsAccountId: accountId,
      models: [],
    };

    let actualsModelIndex = null;
    for (let i = 0; i < row.models.length; i++) {
      if (row.models[i].period === ModelPeriod.ACTUALS) {
        if (actualsModelIndex === null) {
          rowClone.models.push(row.models[i]);
          actualsModelIndex = i;
        }
      } else {
        rowClone.models.push(row.models[i]);
      }
    }

    const modelClone = {
      ...row.models[actualsModelIndex],
      period: ModelPeriod.ACTUALS,
      scenarioId: null,
      active: true,
      formula: "actuals_reference",
      variables: {
        actuals_reference: {
          id: accountId,
          start: 0,
          end: 0,
        },
      },
    };

    rowClone.models[actualsModelIndex] = modelClone;

    onRowChange(rowClone, "[sidebar] handleActualsAccountChange");
  };

  const handleActualsTypeChange = (actualsType) => {
    const rowClone = { ...row };

    rowClone.models = [...rowClone.models];

    let modelClone = null;
    if (modelIndex === -1) {
      modelClone = {
        frontendId: shortid(),
        period: ModelPeriod.ACTUALS,
        scenarioId: null,
        active: true,
        formula: "",
        variables: {},
      };
    } else {
      modelClone = {
        ...row.models[modelIndex],
        period: ModelPeriod.ACTUALS,
        scenarioId: null,
        active: true,
      };
    }

    if (actualsType === "custom") {
      modelClone.formula = "";
      modelClone.variables = {};
    } else {
      // Create model with formula only referencing the account
      modelClone.formula = "actuals_reference";
      modelClone.variables = {
        actuals_reference: {
          id: 1,
          start: 0,
          end: 0,
        },
      };

      if (rowClone.entriesCache) {
        rowClone.entriesCache = clearEntriesCacheForPeriod({ entriesCache: rowClone.entriesCache, period: ModelPeriod.ACTUALS });
      }
    }

    if (modelIndex === -1) {
      rowClone.models.push(modelClone);
    } else {
      rowClone.models[modelIndex] = modelClone;
    }
    onRowChange(rowClone, "[sidebar] handleActualsTypeChange");

    setActualsType(actualsType);
  };

  const handleTabChange = (tab) => {
    const { model } = getModelInSidebar({ row, scenarioId, tab });

    if (tab === "actuals" && !model) {
      const rowClone = { ...row };
      rowClone.models = [...rowClone.models];

      const { model: inactiveModel } = getModelInSidebar({ row, scenarioId, tab, lookForInactive: true });
      if (inactiveModel) {
        /*rowClone.models[rowClone.models.indexOf(inactiveModel)] = {
          ...inactiveModel,
          active: true,
        };*/
      } else {
        /*
        rowClone.models.push({
          frontendId: shortid(),
          period: ModelPeriod.ACTUALS,
          scenarioId: null,
          active: true,
          formula: "",
          variables: {},
        });
        */
        // Default is now manual when switching over to actuals for the first time, so handle creating the entriesCache for the row
        if (!rowClone.entriesCache && rowClone.account_id) {
          const account = accounts?.find((acct) => acct.id === row.account_id);
          rowClone.entriesCache = cloneDeep(getEntriesCacheForAccount(account));
        } else if (!rowClone.entriesCache) {
          rowClone.entriesCache = {};
        }
      }

      onRowChange(rowClone, "[sidebar] handleTabChange");
    }

    setActiveTab(tab);
  };

  return (
    <>
      <Tabs
        activeTab={activeTab}
        actualsTabEnabled={actualsTabEnabled}
        onChange={handleTabChange}
      />
      <TextInput
        label="Metric Name"
        name="name"
        onChange={handleRowPropertyChange}
        value={row.name}
      />
      <div className={styles.slugText}>{row.slug}</div>
      {activeTab === "forecast" ? (
        <AppliesToSelector
          onChange={handleAppliesToChange}
          scenarioId={scenarioId}
          value={appliesToValue}
        />
      ) : (
        <ActualsTypeSelector actualsType={actualsType} onChange={handleActualsTypeChange} />
      )}
      {activeTab === "actuals" && actualsType === "reference" ? (
        <AccountSelector accountId={row.actualsAccountId} onChange={handleActualsAccountChange} />
      ) : (
        <>
          <ForecastType
            forecastType={forecastType}
            hiddenOptions={["autopilot", "google"]}
            label={null}
            onChange={handleForecastTypeChange}
            showFormula
          />
          {
            model && model.formula !== null
              ? (
                <FormulaBuilder
                  model={model}
                  onAutocompleteHighlight={onAutocompleteHighlight}
                  onChange={handleFormulaChange}
                  onRowChange={onRowChange}
                  references={worksheet.rows}
                  scenarioId={scenarioId}
                />
              )
              : (<ManualOptions onCopyAcross={handleCopyAcross} />)
          }
        </>
      )}
      <BalanceOptions onChange={handleRowPropertyChange} row={row} />
    </>
  );
}

MetricOptions.propTypes = {
  actualsClicked: PropTypes.bool.isRequired,
  onAutocompleteHighlight: PropTypes.func.isRequired,
  onRowChange: PropTypes.func.isRequired,
  row: CustomPropTypes.row.isRequired,
  scenarioId: PropTypes.string.isRequired,
  worksheet: PropTypes.object.isRequired,
};
