import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import moment from "moment";
import { get } from "lodash";

import Card from "components/Card";
import Button from "components/Button";

import Errors from "components/Errors";
import styles from "./CompanySettingsEmployees.module.scss";
import { formatPayrollModelDate } from "./employee-utilities";

import { Classes as BP } from "@blueprintjs/core";

function EmployeeRow({ employee, editEmployee, scenario, team }) {
  const payrollModel = (employee.payrollModels && employee.payrollModels[scenario]) ? employee.payrollModels[scenario] : {};
  const display = (payrollModel.display) ? payrollModel.display : {};

  return (
    <tr onClick={editEmployee(employee, team)}>
      <td className="name">{employee.name || ""}</td>
      <td className="title">{employee.title || ""}</td>
      <td className="compensation">{display.compensation}</td>
      <td className="benefits">{display.next_raise_date}</td>
      <td className="benefits">{display.next_raise}</td>
      <td className="hire_date">{formatPayrollModelDate(payrollModel.hire_date)}</td>
      <td className={["termination_date", !formatPayrollModelDate(payrollModel.termination_date) ? "centered" : null].join(" ")}>{formatPayrollModelDate(payrollModel.termination_date) || "-"}</td>
      <td className="payroll_tax">{display.payroll_tax}</td>
      <td className="benefits">{display.benefits}</td>
      <td className="fully_loaded">{display.fully_loaded}</td>
    </tr>
  );
}

EmployeeRow.propTypes = {
  editEmployee: PropTypes.func,
  employee: PropTypes.object.isRequired,
  scenario: PropTypes.string,
  team: PropTypes.object.isRequired,
};


function TableHeader({ sortKey, sortAsc, id, children, className, ...otherProps }) {
  const myClassName = classnames(className, {
    sort: sortKey === id,
    asc: sortKey === id && sortAsc,
    desc: sortKey === id && !sortAsc,
  });
  return (
    <th
      className={myClassName}
      id={id}
      {...otherProps}
    >
      {children}
    </th>
  );
}

TableHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  sortAsc: PropTypes.bool,
  sortKey: PropTypes.string,
};

class CompanySettingsEmployeesAll extends React.Component {
  static propTypes = {
    archiveEmployee: PropTypes.func,
    duplicateEmployee: PropTypes.func,
    editEmployee: PropTypes.func,
    editTeam: PropTypes.func,
    employees: PropTypes.array.isRequired,
    forecastStartDate: PropTypes.string,
    removeEmployee: PropTypes.func,
    scenario: PropTypes.string,
    teams: PropTypes.array.isRequired,
    unarchiveEmployee: PropTypes.func,
  }

  state = {
    sortKey: "name",
    sortAsc: true,
    operationActive: false,
  }

  changeSort = (e) => {
    const sortKey = e.target.id;
    if (!sortKey) return;
    let sortAsc = this.state.sortAsc;
    if (this.state.sortKey === sortKey) {
      sortAsc = !sortAsc;
    }
    this.setState({ sortKey, sortAsc });
  }

  sortFn = (a, b) => {
    const { sortKey, sortAsc } = this.state;
    let valueA = get(a, sortKey);
    let valueB = get(b, sortKey);

    if (sortKey.includes("date")) {
      if (sortKey.includes("next_raise_date")) {
        valueA = moment(valueA || "12/31/9999", "MM-DD-YYYY").unix();
        valueB = moment(valueB || "12/31/9999", "MM-DD-YYYY").unix();
      } else {
        valueA = moment(valueA || "9999-12-31", "YYYY-MM-DD").unix();
        valueB = moment(valueB || "9999-12-31", "YYYY-MM-DD").unix();
      }
    }
    if (sortKey.includes("display.compensation")) {
      valueA = parseInt(valueA.replace(/\D+/g, "")) || 0;
      valueB = parseInt(valueB.replace(/\D+/g, "")) || 0;
    }

    if (sortAsc) {
      return valueA > valueB ? 1 : -1;
    } else {
      return valueA <= valueB ? 1 : -1;
    }
  }

  toggleOperationActive = () => this.setState({ operationActive: !this.state.operationActive })

  render() {
    const { teams, editEmployee, editTeam, employees, scenario, unarchiveEmployee } = this.props;
    const { sortKey, sortAsc } = this.state;

    return (
      <div>
        <Errors messages={this.state.errors} />
        {teams.length > 0 ?
          teams.map((team) => {
            const teamEmployees = employees.filter((e) => e.team.id === team.id);
            return (
              <Card className={styles.teamContainer} key={team.id}>
                <h3 className={`${BP.HEADING} ${styles.teamName}`}>
                  {team.name}
                  {editEmployee ? (
                    <span className={styles.teamButtons}>
                      <Button
                        className={styles.employeesCount}
                        icon="person"
                        size="small"
                      >
                        {teamEmployees.length}
                      </Button>
                      <Button
                        intent="success"
                        onClick={editTeam(team)}
                        size="small"
                      >
                        Edit Team
                      </Button>
                      <Button
                        intent="success"
                        onClick={editEmployee(null, team)}
                        size="small"
                      >
                        Add Employee
                      </Button>
                    </span>
                  ) : null}
                </h3>
                {
                  (teamEmployees.length > 0) ? (
                    <table className={`${BP.HTML_TABLE} ${BP.INTERACTIVE} ${styles.employeesTable}`}>
                      <thead>
                        <tr onClick={this.changeSort}>
                          <TableHeader
                            className={`${styles.name}`}
                            id="name"
                            sortAsc={sortAsc} sortKey={sortKey}
                          >
                            Name
                          </TableHeader>
                          <TableHeader
                            className={`${styles.title}`}
                            id="title"
                            sortAsc={sortAsc} sortKey={sortKey}
                          >
                            Title
                          </TableHeader>
                          <TableHeader
                            className={`${styles.compensation}`}
                            id={`payrollModels.${scenario}.display.compensation`}
                            sortAsc={sortAsc} sortKey={sortKey}
                          >
                            Salary
                          </TableHeader>
                          <TableHeader
                            className={`${styles.nextRaiseDate}`}
                            id={`payrollModels.${scenario}.display.next_raise_date`}
                            sortAsc={sortAsc} sortKey={sortKey}
                          >
                            New Salary Date
                          </TableHeader>
                          <TableHeader
                            className={`${styles.nextRaise}`}
                            id={`payrollModels.${scenario}.display.next_raise`}
                            sortAsc={sortAsc} sortKey={sortKey}
                          >
                            New Salary
                          </TableHeader>
                          <TableHeader
                            className={`${styles.hirDate}`}
                            id={`payrollModels.${scenario}.hire_date`}
                            sortAsc={sortAsc} sortKey={sortKey}
                          >
                            Start Date
                          </TableHeader>
                          <TableHeader
                            className={`${styles.enDate}`}
                            id={`payrollModels.${scenario}.termination_date`}
                            sortAsc={sortAsc} sortKey={sortKey}
                          >
                            Term Date
                          </TableHeader>
                          <TableHeader
                            className={`${styles.payrollTax}`}
                            id={`payrollModels.${scenario}.payroll_tax`}
                            sortAsc={sortAsc} sortKey={sortKey}
                          >
                            Taxes
                          </TableHeader>
                          <TableHeader
                            className={`${styles.benefits}`}
                            id={`payrollModels.${scenario}.benefits`}
                            sortAsc={sortAsc} sortKey={sortKey}
                          >
                            Benefits
                          </TableHeader>
                          <TableHeader
                            className={`${styles.fullyLoaded}`}
                            id={`payrollModels.${scenario}.display.fully_loaded`}
                            sortAsc={sortAsc} sortKey={sortKey}
                          >
                            Fully Loaded
                          </TableHeader>
                        </tr>
                      </thead>
                      <tbody>
                        {teamEmployees.sort(this.sortFn).map((employee) => (
                          <EmployeeRow
                            editEmployee={editEmployee}
                            employee={employee}
                            key={employee.id}
                            scenario={scenario}
                            team={team}
                          />
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h4 className={BP.HEADING}>No employees {unarchiveEmployee ? "archived" : "added"} yet.</h4>
                  )
                }
              </Card>
            );
          }) : (
            <Card className={styles.teamContainer}>
              <h3 className={[BP.HEADING, styles.teamName].join(' ')}>Teams</h3>
              <h4 className={BP.HEADING}>No teams added yet.</h4>
            </Card>
          )
        }
      </div>
    );
  }
}

export default CompanySettingsEmployeesAll;
