import React from "react";
import PropTypes from "prop-types";

import { useReactiveVar } from "@apollo/client";

import { useQuery } from "@apollo/client";
import compose from "lodash.flowright";

import { useDrag, useDrop } from "react-dnd";
import { omit } from "lodash";

import { Spinner, Icon } from "@blueprintjs/core";

import { entriesCacheVar, entriesByIdVar, localEntriesCacheVar } from "apollo-config/local-state/entries";
import { flightpathAccountsByIdVar } from "apollo-config/local-state/accounts";
import { createEntriesCache, getEntriesCacheForAccount } from "shared/utilities/entry-utilities";
import { getAccountFromCache } from "shared/utilities/account-utilities";
import { getAllAccountIdsFromRow } from "./table-utilities";

import { ACCOUNTS_MATCHING_IDS } from "./graphql";

import styles from "./TableBuilder.module.scss";
import formattingStyles from "components/FormattingOptions/formattingStyles.module.scss";

import TableRowValues from "./TableRowValues";

const TableRow = (props) => {
  const {
    categories,
    columns,
    connectDragSource,
    connectDropTarget,
    currentlyEditing,
    index,
    isDragging,
    isOver,
    onAdd,
    onEdit,
    row,
    rows,
    snapshots,
    table,
  } = props;

  useReactiveVar(entriesCacheVar);
  useReactiveVar(localEntriesCacheVar);

  const shouldSkip = () => {
    const { table, row, columns, snapshots } = props;

    if (!row.account_id) return true;

    const uniqueAccountIds = getAllAccountIdsFromRow({ table, row, columns, snapshots });

    let allFound = true;
    for (const accountId of uniqueAccountIds) {
      const matchingAccount = getAccountFromCache(accountId);

      if (!matchingAccount || !Object.keys(getEntriesCacheForAccount(matchingAccount)).length) {
        allFound = false;
        break;
      }
    }

    return allFound || !props.row.account_id;
  }

  const getMissingIds = () => {
    const { table, row, columns, snapshots } = props;

    const uniqueAccountIds = getAllAccountIdsFromRow({ table, row, columns, snapshots });

    const missingIds = [];
    for (const accountId of uniqueAccountIds) {
      const matchingAccount = getAccountFromCache(accountId);
      if (!matchingAccount || !Object.keys(getEntriesCacheForAccount(matchingAccount)).length) {
        missingIds.push(accountId);
      }
    }

    return missingIds;
  }

  const tableRowAccountsQuery = useQuery(ACCOUNTS_MATCHING_IDS, {
    variables: { ids: getMissingIds() },
    onCompleted: ({ accountsMatchingIds }) => {
      const accountsById = {};
      const entries = [];
      const entriesById = {};
      for (const account of accountsMatchingIds) {
        if (account.entries) {
          for (const entry of account.entries) {
            const entryWithAccountId = {
              ...entry,
              account_id: account.id,
            };
            entriesById[entry.id] = entryWithAccountId;
            entries.push(entryWithAccountId);
          }
        }

        const accountClone = { ...account };
        if (accountClone.entries) delete accountClone.entries;
        accountsById[account.id] = accountClone;
      };

      // Add everything to the cache
      entriesCacheVar({
        ...entriesCacheVar(),
        ...createEntriesCache(entries).entriesCache,
      });
      entriesByIdVar({
        ...entriesByIdVar(),
        ...entriesById,
      });
      flightpathAccountsByIdVar({
        ...flightpathAccountsByIdVar(),
        ...accountsById,
      });
    },
    skip: shouldSkip(),
  });

  const opacity = isDragging ? 1 : 1;

  const loading = !!(tableRowAccountsQuery && tableRowAccountsQuery.loading);

  const rowWithAccountIds = { ...row };
  rowWithAccountIds.account_ids = [];

  const rowStyles = [styles.tableRow];

  if (isOver) rowStyles.push(styles.isOver);
  if (currentlyEditing && currentlyEditing.type === "row" && currentlyEditing.index === index) rowStyles.push(styles.active);
  if (row.formatting.bold) rowStyles.push(formattingStyles.bold);
  if (row.formatting.borderTop) rowStyles.push(formattingStyles.borderTop);
  if (row.formatting.percentage) rowStyles.push(formattingStyles.percentage);
  if (row.formatting.dollar) rowStyles.push(formattingStyles.dollar);
  if (row.formatting.indentation && row.formatting.indentation > 0) rowStyles.push(formattingStyles[`indent${row.formatting.indentation}`]);

  const [, dragRef] = useDrag(() => ({
    type: "row",
    item: { index }
  }), []);

  const [, dropRef] = useDrop({
    accept: "row",
    hover: (_item, monitor) => {
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;

      if (dragIndex !== hoverIndex) props.moveRow(dragIndex, hoverIndex);

      monitor.getItem().index = hoverIndex;
    },
  }, []);

  return (
    <tr
      className={rowStyles.join(" ")}
      onClick={onEdit}
      style={{ opacity }}
      ref={(el) => { dragRef(el); dropRef(el); }}
    >
      <td>
        <div className={styles.addIcon} onClick={onAdd}><Icon icon="add" /></div>
        {row.name || <em>New row</em>}
        {loading ? <Spinner className={styles.spinner} size={20} /> : null}
      </td>
      <TableRowValues
        accountsMatchingIds={tableRowAccountsQuery ? tableRowAccountsQuery.accountsMatchingIds : null}
        categories={categories}
        columns={columns ? columns.map((col) => ({ ...omit(col, ["name"]) })) : []}
        currentlyEditing={currentlyEditing}
        loading={loading}
        row={omit(row, ["name"])}
        rows={rows}
        snapshots={snapshots}
        table={table}
      />
    </tr>
  );
};

export default TableRow;
