import React, { useContext } from "react";
import PropTypes from "prop-types";

import Card from "components/Card";
import { HTMLTable, Icon } from "@blueprintjs/core";

import { formatYear, isCurrentMonth, isAfterCurrentMonth, isSame, isAfter } from "shared/utilities/forecast-utilities";

import styles from "./styles.module.scss";
import { AppContext } from "../../AppContext";
import { parseInt } from "lodash";

import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
dayjs.extend(objectSupport);

export default function TableHeader({ noShade = false, selectedScenario, sticky, year, extended }) {
  const { forecastStartDate, forecastEndDate, fiscalYearStart } = useContext(AppContext);
  const fiscalStartMonth = dayjs(fiscalYearStart).month();

  const dates = Array.from({ length: extended ? 24 : 12 }, (_, i) => {
    const mo = fiscalStartMonth + i;
    const yr = mo >= 12 ? parseInt(year) + Math.floor(mo / 12) : parseInt(year);
    return dayjs({ year: yr, month: mo % 12 }).endOf("month");
  });

  const headerRow = () => {
    let headers;

    headers = dates.map((day) => {
      const key = day.format("YYYY-MM");
      return (
        <th
          className={isCurrentMonth(forecastStartDate, key) ? styles.forecastStart : undefined}
          key={key}
        >
          {day.format("MMM YY")}
        </th>
      );
    });

    return headers;
  }

  const subHeaderRow = () => {
    let headers;

    headers = dates.map((day) => {
      const key = day.format("YYYY-MM");
      return (
        <td
          className={isCurrentMonth(forecastStartDate, key) ? styles.forecastStart : undefined}
          key={key}
        >
          {
            isAfter(key, forecastEndDate)
              ? <Icon icon="cross" />
              : extended
                ? (isAfterCurrentMonth({ forecastStartDate, date: key })
                  ? <Icon icon="airplane" />
                  : <Icon icon="history" />)
                : (isAfterCurrentMonth({ forecastStartDate, date: key })
                  ? "Forecast"
                  : "Actual")
          }
        </td>
      )
    });

    return headers;
  }

  return (
    <div className={[styles.header, styles.sticky, sticky ? styles.visible : undefined].join(" ")}>
      <Card className={styles.headerTableContainer} noShade={noShade}>
        <HTMLTable
          className={styles.table}
          small="true"
        >
          <thead>
            <tr>
              <th>{(selectedScenario) ? selectedScenario.name.toUpperCase() : ""}</th>
              {headerRow()}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td />
              {subHeaderRow()}
            </tr>
          </tbody>
        </HTMLTable>
      </Card>
    </div>
  );
}

TableHeader.propTypes = {
  noShade: PropTypes.bool,
  selectedScenario: PropTypes.object.isRequired,
  sticky: PropTypes.bool.isRequired,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  extended: PropTypes.bool,
};
