import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting"

exporting(Highcharts);

const colors = [
  "#48b888",
  "#00366a",
  "#d5dadf",
  "#486d85",
  "#65a9d0",
];

export { colors };

Highcharts.theme = {
  colors,
  chart: {
    animation: false,
    height: 434,
    //width: 630,
    backgroundColor: "#fff",
    borderColor: "#fff",
    borderRadius: 0,
    borderWidth: 0,
    //plotBackgroundColor: '#f5f8fa',
    plotBackgroundColor: "#fff",
    style: {
      fontFamily: "Avenir, Inter, \"Open Sans\", \"Helvetica Neue\", Helvetica, Arial, sans-serif", // default font
      fontSize: "14px",
      color: "#1e3948",
    },
  },
  labels: {
    style: {
      color: "#40586C",
      fontSize: "14px",
    },
  },
  title: {
    align: "left",
    margin: 0,
    style: {
      color: "#3097D1",
      fontSize: "20px",
      fontWeight: "bold",
      border: "5px solid black",
    },
  },
  subtitle: {
    style: {
      color: "#1e3948",
    },
  },
  xAxis: {
    tickmarkPlacement: "on",
    title: {
      enabled: false,
    },
    labels: {
      style: {
        color: "#40586C",
        fontSize: "14px",
      },
    },
  },
  yAxis: {
    allowDecimals: false,
    gridLineWidth: 0,
    lineWidth: 1,
    tickColor: "black",
    tickPosition: "outside",
    title: {enabled: false},
    showFirstLabel: false,
  },
  tooltip: {
    shared: true,
    valuePrefix: "$",
    valueDecimals: 0,
  },
  plotOptions: {
    series: {
      animation: false,
      marker: {
        enabled: false,
      },
    },
  },
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
};

// Apply the theme
Highcharts.setOptions(Highcharts.theme);
