import React, {useContext} from "react";

import {useQuery} from "@apollo/client";

import Loading from "components/Loading";
import Card from "components/Card";

import {AppContext} from "../../../AppContext";

import {WORKSHEETS_QUERY} from "./graphql";

import Header from "./Header";
import WorksheetsTable from "./Table";

import styles from "./styles.module.scss";

export default function Overview() {
  const {loading: worksheetsLoading, data: {worksheets} = {}} = useQuery(WORKSHEETS_QUERY);
  const {user} = useContext(AppContext);

  if(worksheetsLoading) return (<Loading />);

  return (
    <div className={styles.main}>
      {user.role !== "VIEW" ? (<Header />) : null}
      <Card>
        <WorksheetsTable worksheets={worksheets} />
      </Card>
    </div>
  );
}
