import React from "react";
import PropTypes from "prop-types";

import {Button, ButtonGroup, FormGroup} from "@blueprintjs/core";

export default function YAxisType({onChange, value}) {
  const {yAxisType} = value;

  const generateOnChangeEvent = (yAxisType) => () => {
    onChange({
      target: {
        name: "options",
        value: {
          ...value,
          yAxisType,
        },
      },
    });
  };

  return (
    <FormGroup
      label="Y-Axis Range"
      labelInfo="*"
    >
      <ButtonGroup>
        <Button
          active={!yAxisType || yAxisType === "AUTO"}
          onClick={generateOnChangeEvent("AUTO")}
          text="Dynamic"
        />
        <Button
          active={yAxisType === "CUSTOM"}
          onClick={generateOnChangeEvent("CUSTOM")}
          text="Manual"
        />
      </ButtonGroup>
    </FormGroup>
  );
}

YAxisType.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};
