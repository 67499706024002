import React from "react";
import PropTypes from "prop-types";

import RoundCharacter from "components/RoundCharacter";

import styles from "./styles.module.scss";

function StepTitle({number, title, subtext}) {
  return (
    <h3>
      <RoundCharacter>{number}</RoundCharacter>
      <div className={styles.textContainer}>
        <span className={styles.text}>{title}</span>
        <span className={styles.subtext}>{subtext}</span>
      </div>
    </h3>
  );
}

StepTitle.propTypes = {
  number: PropTypes.string,
  subtext: PropTypes.string,
  title: PropTypes.string,
};

export default StepTitle;
