import React, {useContext} from "react";
import PropTypes from "prop-types";

import Sidebar from "components/Sidebar";

import ReferenceOptions from "./ReferenceOptions";
import MetricOptions from "./MetricOptions";
import EmptyRowOptions from "./EmptyRowOptions";
import FormattingOptions from "components/FormattingOptions";
import Footer from "./Footer";

import {actionTypes} from "../reducer";
import CustomPropTypes from "../../propTypes";

import {RowType} from "../utilities";

import {AppContext} from "../../../../AppContext";

import styles from "./styles.module.scss";

export default function WorkstationSidebar({
  dispatch,
  onRowChange,
  row,
  ...props
}) {
  const {currency} = useContext(AppContext);

  return (
    <Sidebar
      className={styles.worksheetSidebar}
      onClose={() => dispatch({type: actionTypes.closeSidebar, payload: {row: null}})}
    >
      {row.type === RowType.REFERENCE ? (
        <ReferenceOptions
          dispatch={dispatch}
          onRowChange={onRowChange}
          row={row}
          {...props}
        />
      ) : row.type === RowType.METRIC ? (
        <MetricOptions
          dispatch={dispatch}
          onRowChange={onRowChange}
          row={row}
          {...props}
        />
      ) : (
        <EmptyRowOptions onRowChange={onRowChange} row={row} />
      )}
      <FormattingOptions
        className={styles.formattingOptions}
        currency={currency}
        label="Formatting Options"
        name="formatting"
        onChange={({target: {name, value}}) => onRowChange({...row, [name]: value})}
        type="row"
        value={row.formatting || {}}
      />
      <Footer dispatch={dispatch} row={row} />
    </Sidebar>
  );
}

WorkstationSidebar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onRowChange: PropTypes.func.isRequired,
  row: CustomPropTypes.row.isRequired,
};
