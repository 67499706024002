import React from "react";
import PropTypes from "prop-types";

import isEqual from "react-fast-compare";

import {Spinner} from "@blueprintjs/core";
import FFTable from "components/Table";
import Card from "components/Card";

import styles from "./TableBuilder.module.scss";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import {AppContext} from "../../../AppContext";

import {getTableHeaders} from "../shared/data-utilities";

export default class Table extends React.Component {
  static propTypes = {
    columns: PropTypes.array,
    currentlyEditing: PropTypes.object,
    onAdd: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onMove: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    snapshots: PropTypes.array,
    snapshotsLoading: PropTypes.bool.isRequired,
    table: PropTypes.object.isRequired,
  }

  shouldComponentUpdate(nextProps) {
    return (
      !isEqual(this.props.currentlyEditing, nextProps.currentlyEditing) ||
      !isEqual(this.props.table, nextProps.table) ||
      !isEqual(this.props.rows, nextProps.rows) ||
      !isEqual(this.props.columns, nextProps.columns) ||
      this.props.snapshotsLoading !== nextProps.snapshotsLoading
    );
  }

  static contextType = AppContext

  render() {
    const {columns, currentlyEditing, onAdd, onMove, onEdit, rows, snapshots, snapshotsLoading, table} = this.props;
    const {forecastStartDate} = this.context;

    let categories = table.type === "SIMPLE" ? [] : columns.map((col) => col.name);
    if(rows?.length && table.type === "SIMPLE") {
      for(let i = 0; i < rows.length; i++) {
        if(rows[i].account_id) {
          categories = getTableHeaders({
            columns,
            forecastStartDate,
            table,
          });
          break;
        }
      }
    }

    return (
      <Card className={styles.tableBuilderTableContainer}>
        {rows.length && !snapshotsLoading ? (
          <FFTable className={[styles.tableBuilderTable, styles[table.type.toLowerCase()]].join(" ")}>
            <TableHeader
              categories={categories}
              columns={columns}
              currentlyEditing={currentlyEditing}
              numberOfColumns={columns.length}
              onEdit={onEdit}
              table={table}
            />
            <tbody>
              {rows.map((row, idx) => (
                <TableRow
                  categories={categories}
                  columns={columns}
                  currentlyEditing={currentlyEditing}
                  index={idx}
                  key={idx}
                  moveRow={onMove("row")}
                  onAdd={onAdd("row", idx + 1)}
                  onEdit={onEdit("row", idx)}
                  row={row}
                  rows={rows}
                  snapshots={snapshots}
                  table={table}
                />
              ))}
            </tbody>
          </FFTable>
        ) : null}
        {snapshotsLoading ? <Spinner /> : null}
      </Card>
    );
  }
}
