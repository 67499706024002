import { Fragment, useCallback, useEffect, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../AppContext";

import { HTMLTable } from "@blueprintjs/core";
import AccountRow from "./AccountRow";
import TitleRow from "./TitleRow";

import styles from "./styles.module.scss";

import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
dayjs.extend(objectSupport);

function ActualTable({
  activeAccount,
  onEditAccount,
  resolvedTemplate,
  scenario,
  stickyHeaderVisible,
  year,
  extended,
  collapseAll,
}) {
  const { fiscalYearStart } = useContext(AppContext);
  const fiscalStartMonth = dayjs(fiscalYearStart).month();
  const [collapsedAccounts, setCollapsedAccounts] = useState({});

  const [rootAccounts, parentMap] = useMemo(() => {
    const ra = new Set();
    const pm = {};

    for (const section of resolvedTemplate.sections) {
      if (!Object.hasOwn(section, "account")) continue;
      const { id, children, detail_type } = section.account;

      if (!children?.length) continue;
      if (detail_type === "root") ra.add(id);

      for (const child of children) {
        pm[child.id] = id;
      }
    }

    return [ra, pm];
  }, [resolvedTemplate]);

  const isParentCollapsed = (id) => {
    let tmp = id;
    while (Object.hasOwn(parentMap, tmp)) {
      let p = parentMap[tmp];
      if (Object.hasOwn(collapsedAccounts, p)) return true;
      tmp = p;
    }
    return false;
  }

  const toggleCollapsed = (ids) => {
    let copy = { ...collapsedAccounts };

    const toggle = (id) => {
      Object.hasOwn(copy, id)
        ? delete copy[id]
        : copy[id] = true;
    }

    Array.isArray(ids)
      ? ids.forEach(toggle)
      : toggle(ids);

    setCollapsedAccounts(_ => copy);
  };

  useEffect(() => {
    toggleCollapsed(
      [...rootAccounts]
        .filter(a =>
          collapseAll
            ? !Object.hasOwn(collapsedAccounts, a)
            : Object.hasOwn(collapsedAccounts, a)
        )
    );
  }, [collapseAll]);

  return useMemo(() => {
    if (!resolvedTemplate) return null;

    // Will be used to pull values from entry cache in AccountRow
    const cacheKeys = Array.from({ length: extended ? 24 : 12 }, (_, i) => {
      const mo = fiscalStartMonth + i;
      const yr = mo >= 12 ? parseInt(year) + Math.floor(mo / 12) : parseInt(year);
      return dayjs({ year: yr, month: mo % 12 }).endOf("month").format("YYYY-MM");
    });

    return (
      <HTMLTable
        className={[styles.table, stickyHeaderVisible ? styles.stickyHeaderVisible : undefined].join(" ")}
        small="true"
      >
        <tbody>
          {
            resolvedTemplate.sections.map((section, i) => {
              const isAccount = section.type === "account";
              const key = (isAccount) ? `${section.account.id}-${i++}` : `section-${i++}`;

              const collapsed = isAccount && Object.hasOwn(collapsedAccounts, section.account.id);
              const hidden = isAccount && isParentCollapsed(section.account.id);

              return (
                <Fragment key={key}>
                  {
                    isAccount ? (
                      <AccountRow
                        account={section.account}
                        active={activeAccount?.id === section.account.id}
                        onEditAccount={onEditAccount}
                        scenario={scenario}
                        templateSection={section}
                        year={year}
                        extended={extended}
                        cacheKeys={cacheKeys}
                        toggleCollapsed={toggleCollapsed}
                        collapsed={collapsed}
                        hidden={hidden}
                      />
                    ) : (
                      <TitleRow
                        templateSection={section}
                        year={year}
                        extended={extended}
                      />
                    )
                  }
                </Fragment>
              );
            })
          }
        </tbody>
      </HTMLTable>
    );
  }, [
    activeAccount,
    onEditAccount,
    resolvedTemplate,
    scenario,
    stickyHeaderVisible,
    year,
    extended,
    collapsedAccounts,
  ]);
}

ActualTable.propTypes = {
  activeAccount: PropTypes.object,
  onEditAccount: PropTypes.func.isRequired,
  resolvedTemplate: PropTypes.object.isRequired,
  scenario: PropTypes.object.isRequired,
  stickyHeaderVisible: PropTypes.bool.isRequired,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  extended: PropTypes.bool.isRequired,
};

export default ActualTable;
