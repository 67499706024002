import React from "react";
import PropTypes from "prop-types";

import {intervalMapping} from "../utilities";

import styles from "./styles.module.scss";

export default function PlanGroupRow({planGroup}) {
  return (
    <tr className={styles.planGroupRow}>
      <td colSpan={4}>{intervalMapping[planGroup.interval]}</td>
    </tr>
  );
}

PlanGroupRow.propTypes = {
  planGroup: PropTypes.object.isRequired,
};
